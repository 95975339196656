import React from 'react';
import { ButtonNew } from '@bibitid/uikit-v1';
import { Modal } from '@bibitid/uikit-v1';
import { Text } from 'features/common/components';

export interface Props {
  showModal: boolean;
  onCloseModal: () => void;
  onHideBanner: () => void;
}

const JagoHideBannerModal: React.FC<React.PropsWithChildren<Props>> = ({
  showModal,
  onCloseModal,
  onHideBanner,
}) => {
  return (
    <Modal visible={showModal} onClose={onCloseModal}>
      <Modal.Header
        title={'Sembunyikan Banner'}
        showClose={true}
        showBackButton={false}
      />
      <Modal.Body>
        <div className='bit-jago-home-banner-modal-wrapper'>
          <Text type='body1'>
            Kamu tetap bisa akses melalui halaman profil untuk aktivasi Jago.
          </Text>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={'Batal'}
        onPrimaryClick={onCloseModal}
        secondaryButton={
          <ButtonNew
            content='Sembunyikan'
            variant={'secondary'}
            colorScheme={'green'}
            testId='jago-hide-banner-modal-btn-test'
            onClick={onHideBanner}
          />
        }
      />
    </Modal>
  );
};

export default JagoHideBannerModal;
