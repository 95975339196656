import LazyModal from 'features/common/LazyModal';
import React from 'react';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

const ChooseOnboardingIconModalLazy = React.lazy(
  () => import('./ChooseOnboardingIconModal')
);

const ChooseOnboardingIconModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'choose_icon';

  return (
    <LazyModal component={ChooseOnboardingIconModalLazy} visible={visible} />
  );
};

export default ChooseOnboardingIconModal;
