import React from 'react';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import getSafely from 'utils/safely';
import { Text } from '@bibitid/uikit-v1';
import classNames from 'classnames';
import { ReksaDana } from 'entities/reksadana.reducer';
import { numberToPercentagePointChangeFormat } from 'utils/stringHelper';
import { decideTextColor } from 'features/reksadana/utils/decideTextColor';

interface CardProps {
  fundData: ReksaDana;
  isComparing?: boolean;
  sortPeriod?: string;
  selected?: boolean;
  onClick: (roboCategory: string, symbol: string) => void;
}

const ReksaDanaMaxDrawdownCard: React.FC<
  React.PropsWithChildren<CardProps>
> = ({ fundData, isComparing, selected, sortPeriod, onClick }) => {
  if (!sortPeriod) return null;
  const { name, type, maxdrawdown_adjusted, investment_manager } = fundData;

  let maxDrawdownNumber: number =
    getSafely([`${sortPeriod}`], maxdrawdown_adjusted, 0) || 0;

  const decideColor = decideTextColor(
    Number(
      numberToPercentagePointChangeFormat(maxDrawdownNumber).replace(/%/g, '')
    )
  );

  const handleClick = () => {
    const { robocategory, symbol } = fundData;
    onClick(robocategory, symbol);
  };

  return (
    <div
      className={classNames({
        'bit-filter-result-row': true,
        'bit-filter-drawdown': true,
        'card-selected': selected,
        'card-reksa-shake': isComparing,
      })}
    >
      <div onClick={handleClick}>
        <div className='bit-filter-reksa'>
          <div className='bit-filter-reksa-pic'>
            <InvestmentCompanyLogo
              type='investment-manager'
              size='square'
              ojkCode={getSafely(['ojkCode'], investment_manager, '')}
            />
          </div>
          <div>
            <h3>{name}</h3>
            <p>{type}</p>
          </div>
        </div>
        <div className={classNames('bit-filter-right-result minus-drawdown')}>
          <Text htmlTag='p' color={decideColor}>
            {numberToPercentagePointChangeFormat(maxDrawdownNumber)}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ReksaDanaMaxDrawdownCard;
