const TIMER = 2000;

/**
 * Handling callback for double click
 *
 * @param oneClick Callback when user click
 * @param doubleClick Callback when user double click in 2000ms
 * @returns
 */
export const doubleClick = (oneClick: () => void, doubleClick: () => void) => {
  let counter = 0;

  const handleClick = () => {
    counter += 1;
    let timeout: NodeJS.Timeout | undefined;

    if (counter < 2) {
      oneClick();
      timeout = setTimeout(() => {
        counter = 0;
      }, TIMER);
    } else {
      doubleClick();
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  };

  return handleClick;
};
