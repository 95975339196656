import React from 'react';
import { useLocation } from 'react-router-dom';
import * as Parent from 'core/Parent';
import { isInsideNativeContainer } from './validator';

/**
 * Get query url:
 * ex: ?hello=world
 */
export function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Handle Open link to browser
 */
export function openLink(href: string, target?: string, features?: string) {
  const isWebview = isInsideNativeContainer();

  if (isWebview) {
    return Parent.postData('openLink', { fn: 'openLink', data: href });
  }

  return window.open(href, target, features);
}
