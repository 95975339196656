import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFlag } from './FeatureFlagProvider';
import { FlagKeys } from './flagKeys';

/**
 * Feature flag wrapper props
 */
type Props = {
  /**
   * Flag key
   */
  flagKey: FlagKeys;
  /**
   * Handle redirect to specific pathname if feature flag toggle is `off`,
   * use these props if wrapping the whole page
   */
  redirectTo?: string;
  /**
   * A fallback react tree to show when a feature flag toggle is `off`
   */
  fallback?: ReactElement<any, any> | null;
  /**
   * React element to show when a feature flag toggle is `on`
   */
  children: ReactElement<any, any> | null;
};

/**
 * Feature flag wrapper for page / component,
 */
const FeatureFlagWrapper = ({
  children,
  flagKey,
  redirectTo,
  fallback = null,
}: Props) => {
  const history = useHistory();
  /**
   * toggle flag status
   */
  const toggleIsOn: boolean = useFlag(flagKey);

  useEffect(() => {
    // if toggle flag is off and has redirect path then redirect to specific path
    if (!toggleIsOn && redirectTo) {
      history.replace(redirectTo || '/');
    }
  }, [toggleIsOn, redirectTo, history]);

  return toggleIsOn ? children : fallback;
};

export default FeatureFlagWrapper;
