import * as React from 'react';
import { NavBar } from 'antd-mobile';

type NavbarProps = React.ComponentPropsWithoutRef<typeof NavBar>;

type AppNavbarProps = Omit<NavbarProps, 'title'> & {
  title?: React.ReactNode;
};

const AppNavbar = ({ title = '', ...rest }: AppNavbarProps) => {
  return (
    <NavBar mode='light' {...rest} data-testid='appnavbar'>
      {title}
    </NavBar>
  );
};

export default AppNavbar;
