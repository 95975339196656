import * as Parent from 'core/Parent';
import { randomString } from 'utils/stringHelper';
import { isJSON } from 'utils/validator';

export type DeviceInfo = {
  /** api level (android only) */
  API_LEVEL?: number;
  /** app build number */
  BUILD_NUMBER?: number;
  /** static device id */
  DEVICE_ID?: string;
  /** timestamp when user install app */
  FIRST_INSTALL_TIME?: string;
  /** dynamic device id, changed when reinstalling app */
  INSTALLATION_ID?: string;
  /** timestamp when user update app */
  LAST_UPDATE_TIME?: string;
  /** manufacturer device, eg Apple, Samsung etc */
  MANUFACTURER?: string;
  /** device model */
  MODEL?: string;
  /** app version */
  READABLE_VERSION?: string;
  /** os name */
  SYSTEM_NAME?: string;
  /** os version */
  SYSTEM_VERSION?: string;
  /** timezone (eg `GMT+7`) */
  TIMEZONE?: string;
  /** data user agent (app, os, vendor etc) */
  USERAGENT?: string;
  /** app version */
  VERSION?: string;
};

/**
 * Get device info and installed Bibit App info
 * @example 
  API_LEVEL: "-",
  BUILD_NUMBER: "1",
  DEVICE_ID: "F5F856BE-2D65-4F30-A162-18BA4BC02986",
  INSTALLATION_ID?: "F5F856BE-2D65-4F30-A162-18BA4BC02986",
  FIRST_INSTALL_TIME: 1655797492,
  LAST_UPDATE_TIME: 1655797492,
  MANUFACTURER: "APPLE",
  MODEL: "Simulator iPhone14,6",
  READABLE_VERSION: "4.29.2",
  SYSTEM_NAME: "ios",
  SYSTEM_VERSION: "15.4",
  TIMEZONE: "GMT+7",
  USERAGENT: "Mozilla/5.0 (iPhone; CPU iPhone OS 13_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko)",
  VERSION: "4.29.2",
 */
export const getDeviceInfo = () => {
  return new Promise<DeviceInfo | null>((resolve, reject) => {
    return Parent.postData(
      'getDevInfo_' + randomString(),
      {
        fn: 'getDevInfo',
        timeout: 1500,
      },
      (error, data) => {
        if (error || !data) {
          return resolve(null);
        }

        const deviceInfo = isJSON(data) ? JSON.parse(data) : data;
        return resolve(deviceInfo);
      }
    );
  });
};

/** get device info based on system name */
export const getDeviceId = (deviceInfo?: DeviceInfo | null) => {
  if (deviceInfo?.SYSTEM_NAME === 'ios') {
    return deviceInfo?.DEVICE_ID || '';
  }

  return deviceInfo?.INSTALLATION_ID || deviceInfo?.DEVICE_ID || '';
};
