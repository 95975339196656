import React from 'react';
import LazyModal from 'features/common/LazyModal';
import InformationCreatingRoboModal from './InformationCreatingRoboModal';
import ChooseInvestmentTargetModal from './ChooseInvestmentTargetModal';
import { useCreatePortoModal } from 'features/portfolio/modals/CreatePortfolioRoboModal/context/CreateProtfolioModalProvider';

const InformationCreatingRoboModalLazy = (props: any) => {
  const { createPortfolioRoboModalVisible, step } = useCreatePortoModal();
  return (
    <LazyModal
      component={InformationCreatingRoboModal}
      visible={createPortfolioRoboModalVisible && step === 'information'}
      {...props}
    />
  );
};

const ChooseInvestmentTargetModalLazy = (props: any) => {
  const { createPortfolioRoboModalVisible, step } = useCreatePortoModal();

  return (
    <LazyModal
      component={ChooseInvestmentTargetModal}
      visible={createPortfolioRoboModalVisible && step === 'choose'}
      {...props}
    />
  );
};

export {
  InformationCreatingRoboModalLazy as InformationCreatingRoboModal,
  ChooseInvestmentTargetModalLazy as ChooseInvestmentTargetModal,
};
