import { apiDelete, get, post, patch, apiInstance } from './http';

export { apiInstance };

const http = {
  delete: apiDelete,
  get,
  post,
  patch,
};

export default http;
