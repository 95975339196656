import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

// Initial State
const defaultState = {
  byId: {},
  allIds: [],
};

// Actions
const [
  addOrderEntry,
  orderSetNewPaymentReceipt,
  addMultipleOrderEntry,
  addOrderId,
] = [
  'ADD_ORDER_ENTRY',
  'ORDER_SET_NEW_PAYMENT_RECEIPT',
  'ADD_MULTIPLE_ORDER_ENTRY',
  'ADD_ORDER_ID',
].map(createAction);

// Reducer
const orders = createReducer(
  {
    // Adding a mutual fund data to the fund data catalog
    [addOrderEntry]: (state, payload) => {
      const keyId = payload.payment?.invoice_number ?? payload.code;

      if (payload?.method === 'sell' && payload?.is_sbn === 1) {
        return update(state, {
          byId: {
            $set: {
              ...state.byId,
              [payload.code]: {
                ...payload,
              },
            },
          },
        });
      }
      return update(state, {
        byId: {
          $set: {
            ...state.byId,
            [keyId]: {
              ...payload,
            },
          },
        },
      });
    },

    // Set new payment receipt image
    [orderSetNewPaymentReceipt]: (state, payload) => {
      const updatedPortfoliosIndex = state.byId[
        payload.invoice_number
      ].portfolios.findIndex((el) => el.portfolio_id === payload.portfolio_id);

      return update(state, {
        byId: {
          [payload.invoice_number]: {
            portfolios: {
              [updatedPortfoliosIndex]: {
                items: {
                  $set: state.byId[payload.invoice_number].portfolios[
                    updatedPortfoliosIndex
                  ].items.map((item) => {
                    if (item.code !== payload.code) return item;
                    return {
                      ...item,
                      payment_receipt: payload.url,
                    };
                  }),
                },
              },
            },
          },
        },
      });
    },

    // Adding multiple mutual fund data
    [addMultipleOrderEntry]: (state, payload) => {
      const orderEntries = payload.reduce((data, currValue) => {
        if (currValue.robogroup) {
          return {
            ...data,
            [currValue.robogroup]: currValue,
          };
        }
        return {
          ...data,
          [currValue.code]: currValue,
        };
      }, {});
      return update(state, {
        byId: {
          $set: {
            ...state.byId,
            ...orderEntries,
          },
        },
      });
    },

    // Adding new id to fund data catalog
    [addOrderId]: (state, payload) => {
      return update(state, {
        allIds: [...state.allIds, payload.symbol],
      });
    },
  },
  defaultState
);

export {
  addOrderEntry,
  addMultipleOrderEntry,
  addOrderId,
  orderSetNewPaymentReceipt,
};

export default orders;
