import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import styles from './RouteApp.module.css';
import IndexHome from 'features/home';
import { PublicRoute } from 'routes/components';
import LazyLoadWrapper from 'features/common/lazyload/LazyLoadWrapper';

/** Lazy Route App, All Route Excep Home */
const LazyRouteApp = lazy(() => import('./LazyRouteApp'));

const LazyRouteAppWrapper = () => {
  return (
    <LazyLoadWrapper>
      <LazyRouteApp />
    </LazyLoadWrapper>
  );
};

/**
 * All routes of our App
 */
const RouteApp: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className={styles['bibit-main-content']}>
      <Switch>
        <PublicRoute
          exact
          path='/'
          component={IndexHome}
          defaultStatusBar={false}
        />
        <PublicRoute exact path='/home' component={IndexHome} />
        <LazyRouteAppWrapper />
      </Switch>
    </div>
  );
};

export default RouteApp;
