import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  FC,
  useState,
} from 'react';

interface QuickAccessContextInteface {
  /**
   * state for open More modal quick access
   */
  openQuickAccessMoreModal: boolean;
  openReachedMaxPortoModal: boolean;

  /**
   * set open or close More modal quick access
   */
  setOpenQuickAccessMoreModal: Dispatch<SetStateAction<boolean>>;
  setOpenReachedMaxPortoModal: Dispatch<SetStateAction<boolean>>;
}

const QuickAccessContext = createContext<
  QuickAccessContextInteface | undefined
>(undefined);

const QuickAccessProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [openQuickAccessMoreModal, setOpenQuickAccessMoreModal] = useState(
    false
  );

  const [openReachedMaxPortoModal, setOpenReachedMaxPortoModal] = useState(
    false
  );

  return (
    <QuickAccessContext.Provider
      value={{
        openQuickAccessMoreModal,
        openReachedMaxPortoModal,
        setOpenQuickAccessMoreModal,
        setOpenReachedMaxPortoModal,
      }}
    >
      {children}
    </QuickAccessContext.Provider>
  );
};

export const useQuickAccess = () => {
  const context = useContext(QuickAccessContext);

  if (context === undefined) {
    throw new Error('useQuickAccess must be used within a QuickAccessProvider');
  }

  return context;
};

export default QuickAccessProvider;
