import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useSelector } from 'react-redux';
import { TRootReducers } from 'store/root';

const PortfolioGoalSettingsModalsLazy = React.lazy(
  () => import('./PortfolioGoalSettingsModals')
);

const PortfolioGoalSettingsModals: React.FC<React.PropsWithChildren<unknown>> = ({ ...rest }) => {
  const portoGoalState = useSelector(
    (state: TRootReducers) => state?.goalSettings
  );

  /**
   * Determine should show visible flow modal or not.
   */
  const visibleModal =
    portoGoalState?.chooseIconVisible ||
    portoGoalState?.createRoboPortoVisible ||
    portoGoalState?.createNonRoboPortoVisible ||
    portoGoalState?.editPortfolioVisible ||
    portoGoalState?.deletePortfolioVisible ||
    portoGoalState?.confirmationVisible ||
    portoGoalState?.createPensionFundGoalVisible;

  return (
    <LazyModal
      visible={visibleModal}
      component={PortfolioGoalSettingsModalsLazy}
      {...rest}
    />
  );
};

export default PortfolioGoalSettingsModals;
