import React, { useState, Dispatch, SetStateAction, useContext } from 'react';

/**
 * Currently supported chart period
 * You can edit what periods our charts supported from this array
 */
export const supportedChartPeriod = [
  '1d',
  '1m',
  '3m',
  'ytd',
  '1y',
  '3y',
  '5y',
  '10y',
  'all',
] as const;
export type ChartPeriod = (typeof supportedChartPeriod)[number];

export const rdChartPeriodOptions = {
  '1d': ['1d', 'ytd', 'all'],
  '1m': ['1d', '1m', 'ytd', 'all'],
  '3m': ['1d', '1m', '3m', 'ytd', 'all'],
  '1y': ['1d', '1m', '3m', 'ytd', '1y', 'all'],
  '3y': ['1d', '1m', '3m', 'ytd', '1y', '3y', 'all'],
  '5y': ['1d', '1m', '3m', 'ytd', '1y', '3y', '5y', 'all'],
  '10y': ['1d', '1m', '3m', 'ytd', '1y', '3y', '5y', '10y', 'all'],
};

export const rdChartPeriodOptionsKey = [
  '1d',
  '1m',
  '3m',
  '1y',
  '3y',
  '5y',
  '10y',
] as const;
export type ChartPeriodOptions = (typeof rdChartPeriodOptionsKey)[number];

const PeriodStateContext = React.createContext<ChartPeriod | undefined>(
  undefined
);
const PeriodSetStateContext = React.createContext<
  Dispatch<SetStateAction<ChartPeriod>> | undefined
>(undefined);

const PeriodContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [chartPeriod, setChartPeriod] = useState<ChartPeriod>('1y');

  return (
    <PeriodStateContext.Provider value={chartPeriod}>
      <PeriodSetStateContext.Provider value={setChartPeriod}>
        {children}
      </PeriodSetStateContext.Provider>
    </PeriodStateContext.Provider>
  );
};

/**
 * Hook containing chart period data, and method to change current chart period selected
 */
const usePeriodState = () => {
  const periodStateContext = useContext(PeriodStateContext);
  const periodSetStateContext = useContext(PeriodSetStateContext);
  if (!periodStateContext || !periodSetStateContext) {
    throw new Error('usePeriodState must be used inside PeriodContextProvider');
  }
  return {
    chartPeriod: periodStateContext,
    setChartPeriod: periodSetStateContext,
  };
};

export { PeriodContextProvider, usePeriodState };
