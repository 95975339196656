/**
 * Re-check if MessagePort is ready (miliseconds)
 */
export const RETRY_NATIVEPORT: number = 100;

/**
 * Timeout from 'postMessage' (miliseconds)
 */
export const TIMEOUT_NATIVEPORT: number = 5000;

/**
 * Error Type list
 */
export const ERROR_TYPE = {
  nativeTimeout: 'error_native_timeout',
};
