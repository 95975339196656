import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { isEmptyObject } from 'utils';
import { postRequestChangeBank } from 'services/user';
import Analytics from 'utils/Analytics';
import { addMinutes } from 'utils/date';

const defaultState = {
  is_fetching: false,
  err: '',
  errType: '',
  step: 0,
  resendTimer: 0,
};

const [reset, incrementStep, request, failure, success, resetTimer] = [
  'RESET_SETTINGBANKACCOUNT',
  'INCREMENT_SETTINGBANKACCOUNT_STEP',
  'LOAD_SETTINGBANKACCOUNT_REQUEST',
  'LOAD_SETTINGBANKACCOUNT_FAILURE',
  'LOAD_SETTINGBANKACCOUNT_SUCCESS',
  'RESET_SETTINGBANKACCOUNT_TIMER',
].map(createAction);

const reducer = createReducer(
  {
    [reset]: (state) => {
      const { is_fetching, err, errType, step } = defaultState;
      return update(state, {
        is_fetching: { $set: is_fetching },
        err: { $set: err },
        errType: { $set: errType },
        step: { $set: step },
      });
    },
    [incrementStep]: (state) => {
      const { step } = state;
      return update(state, {
        step: { $set: step + 1 },
      });
    },
    [request]: (state) => {
      return update(state, {
        is_fetching: { $set: true },
        err: { $set: '' },
        errType: { $set: '' },
      });
    },
    [failure]: (state, payload) => {
      const { err, errType } = payload;
      return update(state, {
        is_fetching: { $set: false },
        err: { $set: err },
        errType: { $set: errType },
      });
    },
    [success]: (state) => {
      const countdownTimer = addMinutes(new Date(), 3);
      return update(state, {
        is_fetching: { $set: false },
        resendTimer: { $set: countdownTimer },
      });
    },
    [resetTimer]: (state) => {
      const { resendTimer } = defaultState;
      return update(state, {
        resendTimer: { $set: resendTimer },
      });
    },
  },
  defaultState
);

export { reset, resetTimer };

export function postSendEmail() {
  return (dispatch) => {
    dispatch(request());
    Analytics.logEventAction({
      eventName: 'bank_account_action',
      parameter: {
        action: 'send_verify_email',
        context: 'change_bank.list_bank_account',
        trigger: 'click',
      },
    });

    postRequestChangeBank()
      .then((payload) => {
        if (isEmptyObject(payload)) {
          return dispatch(failure({ err: 'Something is wrong', errType: '' }));
        }
        dispatch(success());
        dispatch(incrementStep());
      })
      .catch((err) => {
        let errMessage = 'Something is wrong',
          errType = 'unknown_error';

        if (!!err && !!err.response?.data) {
          const { message, type } = err.response.data;

          if (type === 'forbidden_require_pin') {
            return;
          }

          if (!!message) errMessage = message;
          if (!!type) errType = type;
        }
        return dispatch(failure({ err: errMessage, errType }));
      });
  };
}

export default reducer;
