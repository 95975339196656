import * as Parent from 'core/Parent';
import {
  NativeChallengeReply,
  NativePinChallengeReply,
} from 'features/biometric/type.d';
import {
  ErrorType,
  nativeTimeout,
  nativeTimeoutLong,
} from 'features/biometric/constants';
import { openToastTop } from 'features/common/toast/toast.reducer';
import { openToastMessage } from 'features/common/toast/toast.reducer';
import { ProfileDataContent } from 'features/profile/types';
import Analytics from 'utils/Analytics';
import { getDeviceInfo } from 'core/DeviceInfo/deviceInfo';
import Storage from 'core/Storage';
import queryClient from 'network/queryClient';
import { taskGetIsWebview } from 'utils/is-webview';
import getSafely from 'utils/safely';

/**
 * Remove this constant only after refactor code biometric timeout module tobe dependent to postData timeout.
 * This code ignoring timeout from postData.
 */
const IGNORE_POSTDATA_TIMEOUT = 0;

/**
 * Check capability of handphone
 * ------------------
 * Is Phone have biometric capability or not
 */
export const checkWindowHasBiometric = () => {
  const has = window.hasBiometric || window.document.hasBiometric;
  return !!has;
};

/**
 * Check type of biometric --> "face" or "touch"
 */
export const checkWindowBiometricType = () => {
  return window.biometricType || window.document.biometricType;
};

/**
 * Check value biometricSetup with postmessage native
 */
export const postNativeBiometricSetup = (userId: number): Promise<0 | 1> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      return reject(`${ErrorType.nativeTimeout} postNativeBiometricSetup`);
    }, nativeTimeout);

    return Parent.postData(
      'checkBiometricSetup',
      {
        fn: 'checkBiometricSetup',
        data: { userId: String(userId) },
        timeout: IGNORE_POSTDATA_TIMEOUT,
      },
      (err: any, data: 0 | 1) => {
        clearTimeout(timeout);
        if (err) return reject(err);
        return resolve(data);
      }
    );
  });
};

/**
 * Processing enroll with postMesage to native
 * @param secureKey string
 * @param userid number
 * @param bearerToken string
 */
export const postNativeEnrollBiometric = (
  secureKey: string,
  userid: number,
  bearerToken: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(async () => {
      try {
        await postNativeDismissBiometricDialog();
        return resolve({
          error: 'Aktivasi Biometrik Gagal',
        });
      } catch (error) {
        return reject(`${ErrorType.nativeTimeout} postNativeEnrollBiometric`);
      }
    }, nativeTimeoutLong);
    Analytics.logEventDebug({
      eventName: 'send_postmessage_debug',
      parameter: {
        trigger: 'call',
        context: 'postNativeEnrollBiometric',
        data: {
          postmessage_id: 'enrollBiometric',
          postmessage_fn: 'enrollBiometric',
          postmessage_timeout: IGNORE_POSTDATA_TIMEOUT,
        },
      },
    });

    return Parent.postData(
      'enrollBiometric',
      {
        fn: 'enrollBiometric',
        data: { secureKey, userId: userid, bearerToken },
        timeout: IGNORE_POSTDATA_TIMEOUT,
        check_expiration: true,
      },
      (err: any, data: any) => {
        clearTimeout(timeout);
        if (err) {
          Analytics.logEventDebug({
            eventName: 'receive_postmessage_error_debug',
            parameter: {
              trigger: 'call',
              context: 'postNativeEnrollBiometric',
              data: {
                postmessage_id: 'enrollBiometric',
                postmessage_error: err,
              },
            },
          });
          return reject(err);
        }
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        Analytics.logEventDebug({
          eventName: 'receive_postmessage_error_debug',
          parameter: {
            trigger: 'call',
            context: 'postNativeEnrollBiometric',
            data: {
              postmessage_id: 'enrollBiometric',
              postmessage_error: err,
            },
          },
        });
        return resolve(data);
      }
    );
  });
};

/**
 * Canceling biometric setup with postMessage to native
 */
export const postNativeCancelBiometric = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      return reject(`${ErrorType.nativeTimeout} postNativeCancelBiometric`);
    }, nativeTimeout);
    Analytics.logEventDebug({
      eventName: 'send_postmessage_debug',
      parameter: {
        trigger: 'call',
        context: 'postNativeCancelBiometric',
        data: {
          postmessage_id: 'cancelBiometric',
          postmessage_fn: 'cancelBiometric',
          postmessage_timeout: IGNORE_POSTDATA_TIMEOUT,
        },
      },
    });

    return Parent.postData(
      'cancelBiometric',
      { fn: 'cancelBiometric', timeout: IGNORE_POSTDATA_TIMEOUT },
      (err: any) => {
        clearTimeout(timeout);
        if (err) {
          Analytics.logEventDebug({
            eventName: 'receive_postmessage_error_debug',
            parameter: {
              trigger: 'call',
              context: 'postNativeCancelBiometric',
              data: {
                postmessage_id: 'cancelBiometric',
                postmessage_error: err,
              },
            },
          });
          return reject(err);
        }
        Analytics.logEventDebug({
          eventName: 'receive_postmessage_debug',
          parameter: {
            trigger: 'call',
            context: 'postNativeCancelBiometric',
            data: {
              postmessage_id: 'cancelBiometric',
            },
          },
        });
        return resolve(true);
      }
    );
  });
};

/**
 * Challenge biometric with postMessage to Native
 * @param bearerToken string
 */
export const postNativeChallengeBiometric = (
  bearerToken: string
): Promise<NativeChallengeReply> => {
  return new Promise(async (resolve, reject) => {
    Analytics.logEventDebug({
      eventName: 'send_postmessage_debug',
      parameter: {
        trigger: 'call',
        context: 'postNativeChallengeBiometric',
        data: {
          postmessage_id: 'challengeBiometric',
          postmessage_fn: 'challengeBiometric',
          postmessage_timeout: IGNORE_POSTDATA_TIMEOUT,
        },
      },
    });

    let timeout: NodeJS.Timeout | undefined = undefined;

    const deviceInfo = await getDeviceInfo();

    const platform = deviceInfo?.SYSTEM_NAME;

    if (platform !== 'ios') {
      timeout = setTimeout(async () => {
        try {
          await postNativeDismissBiometricDialog();
          return resolve({
            error: 'Biometrik kamu gagal. Silakan gunakan PIN.',
            key: '',
          });
        } catch (error) {
          return reject(
            `${ErrorType.nativeTimeout} postNativeChallengeBiometric`
          );
        }
      }, nativeTimeoutLong);
    }

    return Parent.postData(
      'challengeBiometric',
      {
        fn: 'challengeBiometric',
        data: { bearerToken },
        timeout: IGNORE_POSTDATA_TIMEOUT,
      },
      (err: any, data: NativeChallengeReply) => {
        if (timeout) {
          clearTimeout(timeout);
        }

        if (err) {
          Analytics.logEventDebug({
            eventName: 'receive_postmessage_error_debug',
            parameter: {
              trigger: 'call',
              context: 'postNativeChallengeBiometric',
              data: {
                postmessage_id: 'challengeBiometric',
                postmessage_error: err,
              },
            },
          });
          return reject(err);
        }
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        Analytics.logEventDebug({
          eventName: 'receive_postmessage_debug',
          parameter: {
            trigger: 'call',
            context: 'postNativeChallengeBiometric',
            data: {
              postmessage_id: 'challengeBiometric',
            },
          },
        });
        return resolve(data);
      }
    );
  });
};

/**
 * To dissmis challenge biometric, sometimes user wont use biometric (maybe user used mask)
 */
export const postNativeDismissBiometricDialog = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    Analytics.logEventDebug({
      eventName: 'send_postmessage_debug',
      parameter: {
        trigger: 'call',
        context: 'postNativeDismissBiometricDialog',
        data: {
          postmessage_id: 'dismissBiometricDialog',
          postmessage_fn: 'dismissBiometricDialog',
          postmessage_timeout: nativeTimeout,
        },
      },
    });
    const timeout = setTimeout(() => {
      Analytics.logEventDebug({
        eventName: 'receive_postmessage_debug',
        parameter: {
          trigger: 'call',
          context: 'postNativeDismissBiometricDialog',
          data: {
            postmessage_id: 'dismissBiometricDialog',
          },
        },
      });
      return resolve(true); // we are not expected reply from Native
    }, nativeTimeout);

    return Parent.postData(
      'dismissBiometricDialog',
      { fn: 'dismissBiometricDialog', timeout: IGNORE_POSTDATA_TIMEOUT },
      (err: any) => {
        clearTimeout(timeout);
        if (err) return reject(err);
        return resolve(true);
      }
    );
  });
};

/**
 * Challenge Biometric for non authenticated user (Login with PIN)
 * @param device_token string
 * @param user_id string | null
 */
export const postNativePinChallengeBiometric = (
  device_token: string,
  user_id: string | null
): Promise<NativePinChallengeReply> => {
  return new Promise(async (resolve, reject) => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    const deviceInfo = await getDeviceInfo();

    const platform = deviceInfo?.SYSTEM_NAME;

    if (platform !== 'ios') {
      timeout = setTimeout(async () => {
        try {
          await postNativeDismissBiometricDialog();
          return resolve({
            error: 'Biometrik kamu gagal. Silakan gunakan PIN.',
          });
        } catch (error) {
          return reject(
            `${ErrorType.nativeTimeout} postNativePinChallengeBiometric`
          );
        }
      }, nativeTimeoutLong);
    }

    return Parent.postData(
      'pinChallengeBiometric',
      {
        fn: 'pinChallengeBiometric',
        data: { device_token, user_id },
        timeout: IGNORE_POSTDATA_TIMEOUT,
        check_expiration: true,
      },
      (err: any, data: NativePinChallengeReply) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        if (err) {
          return reject(err);
        }
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        return resolve(data);
      }
    );
  });
};

/**
 * Check wether device has biometric or not by using postMessage.
 * It's some sort of `checkWindowHasBiometric` alternative, but more
 * reliable since its updated to the latest state of the device.
 * Useful to detect if user deleted biometric on the device while
 * bibit app is running.
 */
export const postNativeDetectBiometric = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      return reject(`${ErrorType.nativeTimeout} postNativeDetectBiometric`);
    }, nativeTimeout);

    return Parent.postData(
      'detectBiometric',
      {
        fn: 'detectBiometric',
        timeout: IGNORE_POSTDATA_TIMEOUT,
      },
      (err: any, data: true | false) => {
        clearTimeout(timeout);
        if (err) return reject(err);
        return resolve(data);
      }
    );
  });
};

/**
 * Trigger biometric challenge
 */
export const runBiometric = async (): Promise<NativeChallengeReply> => {
  try {
    const token = (await Storage.getAccessToken()) || '';
    const dataNative = await postNativeChallengeBiometric(token);
    return dataNative;
  } catch (err) {
    return {
      error: 'Biometrik kamu gagal. Silakan gunakan PIN.',
      key: '',
    };
  }
};

export const isWebviewANDHasBiometric = async () => {
  try {
    const isWebview = await taskGetIsWebview();
    const hasBiometric = checkWindowHasBiometric();
    if (!isWebview || !hasBiometric) return false;
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Activate smart login with biometric when:
 * 1. isWebview true
 * 2. biometric is available in user device
 * 3. biometricSetup is null or 0
 */
export const shouldActivateWithBiometric = async (userid: number) => {
  try {
    const webviewAndHasBiometric = await isWebviewANDHasBiometric();

    const bioSetup = await postNativeBiometricSetup(userid);
    if (!webviewAndHasBiometric || Number(bioSetup) === 1) return false; // bukan webview, tidak bisa biometric, atau sudah setup biometric di device terkait
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Biometric active when:
 * 1. isWebview true
 * 2. biometric is available in user device
 * 3. biometricSetup is 1
 *
 * Note that this function may be invoked multiple times by `shouldPinChallengeShow`,
 * and timeout error may occured as well.
 *
 * To fix that, We will cache the value in react query. So when `timeout` error happens,
 * we will return the previous value from cache.
 *
 * Why previous value?, because `timeout` error is not actually responsible
 * for the biometric-setup value, so we will ignore it.
 *
 */
export const isBiometricActive = async (userid: number) => {
  try {
    const webviewAndHasBiometric = await isWebviewANDHasBiometric();
    if (!webviewAndHasBiometric) {
      return false;
    }

    // User has activated biometric in current device
    const bioSetup = await postNativeBiometricSetup(userid);
    const isActive = Number(bioSetup) === 1;

    queryClient.setQueryData('Biometric Setup', isActive);
    return isActive;
  } catch (error) {
    // Check for any kind of 'timeout' error
    if (error === 'error_native_timeout postNativeBiometricSetup') {
      // If the typeof error is timout,
      // we'll return the previous value that is stored in react query cache
      let previousValue = queryClient.getQueryData<boolean>('Biometric Setup');

      // Check if previous cache is exist, we return from react query cache
      if (typeof previousValue !== 'undefined') {
        return previousValue;
      }

      // If cache is undefined, we'll return false
      return false;
    }

    queryClient.setQueryData('Biometric Setup', false);
    return false;
  }
};

interface EnrollBiometricInterface {
  dispatch: any;
  secureKey: string;
  profileDetail?: ProfileDataContent;
  setBiometricToggleActive?: React.Dispatch<React.SetStateAction<boolean>>;
  setNativeToggleIdleTimer?: (timer: boolean) => void;
}

/**
 * Processing when user want to enroll biometric setup
 */
export const enrollBiometricProcessing = async (
  enrollBiometricData: EnrollBiometricInterface
) => {
  const {
    dispatch,
    secureKey,
    profileDetail,
    setBiometricToggleActive,
    setNativeToggleIdleTimer,
  } = enrollBiometricData;

  try {
    const userid = profileDetail?.user?.id ?? 0;

    const token = (await Storage.getAccessToken()) || '';
    const dataNative = await postNativeEnrollBiometric(
      secureKey,
      userid,
      token
    );

    const error = getSafely(['error'], dataNative);
    if (error && error !== 'error_message_no_show') {
      dispatch(openToastTop(error, 'red'));
    }
    if (error) {
      return false;
    }

    // Open black toast from bottom with Biometrik text
    dispatch(
      openToastMessage(
        'Aktivasi Biometrik Berhasil',
        'blacksuccess',
        true,
        '',
        '',
        true
      )
    );

    if (setBiometricToggleActive) {
      setBiometricToggleActive(true);
    }

    // Turn ON idle timer
    if (setNativeToggleIdleTimer) {
      setNativeToggleIdleTimer(true);
    }
    return true;
  } catch (error) {
    dispatch(openToastTop('Aktivasi Biometrik Gagal', 'red'));
    return false;
  }
};

/**
 * Show modal ask biometric in home:
 * 1. biometric is avaliable in device
 * 2. biometric setup in device = null or 0
 * 3. pinChallengeStatus config = 1
 * 4. biometric config = 1
 * 5. is_need_upload_document config = 0
 * 6. user status = 4
 *
 */
export const shouldAskBiometricInHome = async (
  profileDetail: ProfileDataContent
) => {
  const isWebview = await taskGetIsWebview();

  // Make sure app is being opened in native
  if (!isWebview) return false;

  const preference = profileDetail?.preference;

  const biometricStatus = preference?.biometric;

  const pinChallengeStatus = preference?.pinChallengeStatus;

  const isSuccessBibitUpgrade = !!preference?.is_success_bibit_upgrade;

  // Make sure user has pin
  if (pinChallengeStatus === 0) return false;

  const needUploadDocument = profileDetail?.is_need_upload_document ?? 0;

  const userStatus = profileDetail?.user?.status ?? 0;

  const userid = profileDetail?.user?.id ?? 0;

  const validemail = profileDetail?.validemail ?? 0;

  const bibitPlusUpgradeStatus = profileDetail.upgrade_status;

  /** A flag when a user has just finished Bibit plus */
  const justFinishedBibitPlus =
    bibitPlusUpgradeStatus === 4 && !isSuccessBibitUpgrade;

  // Check if biometric is not active OR not available
  const biometricUnavailableOrInactive =
    (await shouldActivateWithBiometric(userid)) === false;

  // Check if user ever denied smart login activation (biometric = 0)
  const hasUserDeniedBiometric = await Storage.getObject('userDeniedBiometric');
  const bioActivationIsRejected =
    (biometricStatus === 0 && pinChallengeStatus === 1) ||
    !!hasUserDeniedBiometric;

  // Check if user has not finished registration or document upload yet or email not valid
  const userNotEligible =
    userStatus !== 4 || needUploadDocument === 1 || !validemail;

  // We don't show modal for these rules
  if (
    biometricUnavailableOrInactive ||
    bioActivationIsRejected ||
    userNotEligible ||
    justFinishedBibitPlus
  )
    return false;

  return true;
};
