import { UserPreference } from 'features/profile/types';
import { AxiosPromise } from 'axios';
import http from 'core/http';

export const endpoints = {
  userPreferenceUpdate: '/user/preference/update',
};

interface UpdatePreferenceResponse {
  preference: UserPreference;
}

// TODO: Split user preference data into two categories, one which controls
// what user truly interact (known by users), and one which stores value for
// interaction tracking purpose (unknown by user).

/**
 * Instruct API to save user's preference data. Preference is an
 * arbitrary key value paired with user's data which we can use
 * to control views. Users know when these values are being set.
 */
export function postUserUpdatePreference(
  preferenceData: UserPreference
): AxiosPromise<UpdatePreferenceResponse> {
  return http.post(endpoints.userPreferenceUpdate, preferenceData);
}

export * from './resolvers';
