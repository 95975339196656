import React, {
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
} from 'react';
import { useHistory } from 'react-router-dom';

import Storage from 'core/Storage';
import { removeTrailingSlash } from 'utils/stringHelper';
import { parseISO, differenceInYears } from 'utils/date';
import {
  isValidDate,
  stringContainsOnlyDigits,
  validateEmail,
} from 'utils/validator';
import { postCheckEmail, postCheckNIK } from 'services/user';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';

import { checkStringWithEmoji, isValidName } from 'features/registration/utils';
import {
  mapErrorMsg,
  mapInvalidErrorMsg,
  mapMinimumLengthMsg,
  MIN_AGE_USER,
} from 'features/register-account/constants';

export interface ErrorObjectContext {
  message: string;
  status: boolean;
}

export interface RegisterAccountState {
  sso_token?: string;
  sso_provider?: string;
  email: string;
  education: number;
  income: number;
  income_source: number;
  income_source_other: string;
  bank: any;
  bank_number: string;
  bank_owner: string;
  nik: string;
  full_name: string;
  birth_date: string;
  ktp_url: string;
  ktp_url_fallback: string;
  edd_url: string;
  edd_url_fallback: string;
  signature_url: string;
  job_id: string;
  [key: string]: any;
}

export const defaultState: RegisterAccountState = {
  sso_token: undefined,
  sso_provider: undefined,
  email: '',
  education: 0,
  income: 0,
  income_source: 0,
  income_source_other: '',
  bank: -1,
  bank_number: '',
  bank_owner: '',
  nik: '',
  full_name: '',
  birth_date: '',
  ktp_url: '',
  ktp_url_fallback: '', // flag upload old native
  edd_url: '',
  edd_url_fallback: '', // flag after upload old native
  signature_url: '',
  job_id: '',
};

export const RegisterAccountContext = React.createContext<
  | {
      listStepper: typeof registerAccountSteps;
      handleNextStep(): void;
      handlePreviousStep(): void;
      [key: string]: any;
    }
  | undefined
>(undefined);

const KYC = 'kyc';

export const registerAccountSteps = [
  {
    name: 'Ketahui Profil Risiko',
    page: '/profiling?new-user=1&from-regis=true',
  },
  {
    name: 'Isi Data Diri',
    page: '/register-account',
  },
  {
    name: 'Isi Email',
    page: '/register-account/email',
  },
  {
    name: 'Foto KTP',
    page: '/register-account/upload-identity',
  },
  {
    name: 'Foto Selfie dengan KTP',
    page: '/register-account/upload-edd',
  },
  {
    name: 'Isi Data KTP',
    page: '/register-account/identity',
  },
  {
    name: 'Isi Data Bank',
    page: '/register-account/bank',
  },
  {
    name: 'Tanda Tangan Digital',
    page: '/register-account/signature',
  },
];

export const RegisterAccountProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const history = useHistory();

  const { isLogin } = useAuth();
  const { data: dataProfile, isLoading } = useProfileData(isLogin);

  // Access user profile data
  const profileDetail = dataProfile?.data?.data;
  const roboId = profileDetail?.roboid;
  const bank = profileDetail?.user?.bank;
  const bankAccountName = profileDetail?.user?.bank_account_name;
  const bankAccountNumber = profileDetail?.user?.bank_account_number;
  const fullname = profileDetail?.user?.fullname;
  const income = profileDetail?.user?.income;
  const isRegisteredAsInstiAccountOnly =
    !!profileDetail?.preference?.is_registered_as_institutional_account_only;

  // Determine wether users already registered their email before
  const emailIsVerified = profileDetail?.validemail === 1;
  const privateEmail = profileDetail?.user?.is_private_email;
  const alreadyHaveEmail = !!emailIsVerified || privateEmail;
  const userEmail = profileDetail?.user?.email;

  /**
   * Skip email step for individual normal account when user already have verified email
   *
   * for user individual with flag `registered as insti only`, user email already registered in backoffice
   * and in flow registration individual we expect user can change their email account
   */
  const shouldSkipEmailStep =
    !isRegisteredAsInstiAccountOnly && !!userEmail && alreadyHaveEmail;

  // check if user not registered bank account and not completed kyc, user status is unBank
  const isUnbank = !bank && !bankAccountName && !bankAccountNumber;

  // check if user not registered bank account and completed kyc, user status is unBank
  // - flagging already submit kyc for user registered as insti only is Boolean(income)
  // - flagging already submit kyc for normal account is Boolean(fullname)
  const checkUserIsUnbank = isRegisteredAsInstiAccountOnly
    ? isUnbank && Boolean(income)
    : isUnbank && Boolean(fullname);

  // Context State
  const [state, setState] = useState({ ...defaultState });
  const [error, setError] = useState({});
  const [loadingStep, setLoadingStep] = useState(false);
  const [latestStep, setLatestStep] = useState(1);
  const [activeStep, setActiveStep] = useState(latestStep);
  const [listStepper, setListStepper] = useState(registerAccountSteps);

  const removeStateError = (key: string) => {
    const updatedState: { [key: string]: ErrorObjectContext } = {
      [key]: {
        message: '',
        status: false,
      },
    };
    setError((error: any) => {
      return {
        ...error,
        ...updatedState,
      };
    });
  };

  const setStateForm = useCallback((key: string, value: any) => {
    const updatedState = { [key]: value };
    setState((state: any) => {
      return { ...state, ...updatedState };
    });
    removeStateError(key);
  }, []);

  const saveStateToStorage = async (value: any) => {
    const kycData = await Storage.getObject(KYC);
    Storage.setObject(KYC, { ...kycData, ...value });
  };

  // Skip email step if user already has email (from SSO registration)
  useEffect(() => {
    if (shouldSkipEmailStep) {
      // Save email field
      setStateForm('email', userEmail);
      saveStateToStorage({ email: userEmail });

      // Filter step
      setListStepper((prev) =>
        prev.filter((s) => s.page !== '/register-account/email')
      );
    }
  }, [shouldSkipEmailStep, setStateForm, userEmail]);

  // set local storage here and set useEffect when componentDidMount to setState
  const setStateFromLocalStorage = useCallback(async () => {
    const kycData = await Storage.getObject(KYC);

    if (kycData) {
      const { birth_date } = kycData;
      setState((state: any) => {
        return {
          ...state,
          ...kycData,
          birth_date: birth_date
            ? parseISO(kycData.birth_date)
            : state.birth_date,
        };
      });
    }
  }, []);

  const saveStepperActiveStep = useCallback(async (value: any) => {
    Storage.setObject('registration-step-active', value);
  }, []);

  const setPrefillForm = (prefill: any) => {
    const { full_name = '', identity = '', birth_date = '' } = prefill;
    const defaultDate = '1990-01-01';
    const birthDate = isValidDate(birth_date) ? birth_date : defaultDate;

    full_name && !state['full_name'] && setStateForm('full_name', full_name);
    identity && !state['nik'] && setStateForm('nik', identity);
    birth_date &&
      !state['birth_date'] &&
      setStateForm('birth_date', parseISO(birthDate));
  };

  const setStateError = (key: string, message: string) => {
    const updatedState: { [key: string]: ErrorObjectContext } = {
      [key]: {
        message: message,
        status: true,
      },
    };
    setError((error: any) => {
      return {
        ...error,
        ...updatedState,
      };
    });
  };

  const resetError = () => {
    setError((state: any) => {
      return {};
    });
  };

  const validateEmailRegistration = () => {
    resetError();

    let hasError = false;

    let emailValid = validateEmail(state['email']);

    if (!emailValid) {
      hasError = true;
      setStateError(
        'email',
        'Format Email tidak sesuai dan pastikan tidak ada spasi yang terinput'
      );
    }

    return hasError;
  };

  const validateRegisterAccount = async (): Promise<number> => {
    resetError();

    return new Promise((resolve, reject) => {
      const minimum: any = {};

      let count = 0;

      const personalIdentityKeys = ['education', 'income', 'income_source'];

      /**
       * Empty validate check
       */
      personalIdentityKeys.forEach((name: string) => {
        if (defaultState[name] === state[name]) {
          count = count + 1;
          setStateError(name, mapErrorMsg[name]);
        }
      });

      /**
       * Minimum validate length
       */
      personalIdentityKeys.forEach((name: string) => {
        const minLength: number = minimum[name];
        if (state[name].length < minLength) {
          count = count + 1;
          const createMessage: Function = mapMinimumLengthMsg[name];
          setStateError(name, createMessage(minLength));
        }
      });

      // Should check optional input only if income_source is 'Lainnya'
      if (state['income_source'] === 10) {
        const key = 'income_source_other';

        if (state[key] === '') {
          count = count + 1;
          setStateError(key, mapErrorMsg[key]);
        }

        /**
         * validate string have emoji or not
         */
        if (checkStringWithEmoji(state[key])) {
          count = count + 1;
          setStateError(
            key,
            'Sumber penghasilan lain harus berupa alfanumerik'
          );
        }
      }

      return resolve(count);
    });
  };

  const validateRegisterBankAccount = (): number => {
    resetError();

    const key = ['bank', 'bank_number', 'bank_owner'];

    const minimum: any = {
      bank_number: 5,
      bank_owner: 1,
    };

    let count = 0;

    /**
     * Empty validate check
     */
    key
      .filter((name: string) => {
        if (defaultState[name] === state[name]) {
          count = count + 1;
          setStateError(name, mapErrorMsg[name]);
          return false;
        }
        return true;
      })

      /**
       * Minimum validate length
       */
      .filter((name: string) => {
        const minLength: number = minimum[name];
        if (state[name].length < minLength) {
          count = count + 1;
          const createMessage: Function = mapMinimumLengthMsg[name];
          setStateError(name, createMessage(minLength));
          return false;
        }
        return true;
      });

    if (
      !!state['bank_number'] &&
      !stringContainsOnlyDigits(state['bank_number'])
    ) {
      count = count + 1;
      setStateError(
        'bank_number',
        'Nomor Rekening harus berupa angka dan pastikan tidak ada spasi yang terinput'
      );
    }

    /**
     * validate string have emoji or not
     */
    if (checkStringWithEmoji(state['bank_owner'])) {
      count = count + 1;
      setStateError(
        'bank_owner',
        'Nama pemilik rekening harus berupa alfanumerik'
      );
    }

    if (!isValidName(state['bank_owner'])) {
      count = count + 1;
      setStateError('bank_owner', 'Format Nama pemilik rekening tidak valid');
    }

    return count;
  };

  const validateRegisterAccountIdentity = (): number => {
    resetError();

    const key = ['nik', 'full_name', 'birth_date'];
    const minimum: any = {
      nik: 16,
      full_name: 1,
    };

    let count = 0;

    /**
     * Empty validate check
     */
    key
      .filter((name: string) => {
        if (defaultState[name] === state[name]) {
          count = count + 1;
          setStateError(name, mapErrorMsg[name]);
          return false;
        }
        return true;
      })

      /**
       * Minimum validate length
       */
      .filter((name: string) => {
        const minLength: number = minimum[name];
        if (state[name].length < minLength) {
          count = count + 1;
          const createMessage: Function = mapMinimumLengthMsg[name];
          setStateError(name, createMessage(minLength));
          return false;
        }
        return true;
      });

    /**
     * NIK validate string only contains digits
     */
    if (!!state['nik'] && !stringContainsOnlyDigits(state['nik'])) {
      count = count + 1;
      setStateError('nik', 'Format NIK tidak tepat.');
    }

    /**
     * validate string have emoji or not
     */
    if (checkStringWithEmoji(state['full_name'])) {
      count = count + 1;
      setStateError('full_name', 'Nama lengkap harus berupa alfanumerik');
    }

    if (!isValidName(state['full_name'])) {
      count = count + 1;
      setStateError('full_name', 'Format Nama lengkap tidak valid');
    }

    /**
     * Birth date validation with minimum age is `MIN_AGE_USER`
     */
    if (
      !!state['birth_date'] &&
      differenceInYears(new Date(), new Date(state['birth_date'])) <
        MIN_AGE_USER
    ) {
      count = count + 1;
      setStateError(
        'birth_date',
        mapInvalidErrorMsg.birth_date_min_age(MIN_AGE_USER)
      );
    }

    return count;
  };

  const validateEmailIsExists = async (): Promise<boolean> => {
    resetError();

    const { email } = state;

    try {
      const response = await postCheckEmail(email).then(
        (res) => res.data?.data
      );

      if (!!response) {
        const { is_email_exists } = response;

        if (!!is_email_exists) {
          setStateError('email', 'Email sudah digunakan.');
          return false;
        }
      }

      return true;

      //
    } catch (error) {
      let errorMessage = error?.message;
      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      if (error?.response?.data?.errors) {
        errorMessage = error?.response.data.errors.email.msg;
      }
      setStateError('email', errorMessage);
      return false;
    }
  };

  const validateNIK = async (): Promise<{
    is_identity_exists: boolean;
    phone_number: string;
    is_error?: boolean;
  }> => {
    resetError();

    const { nik } = state;

    try {
      const response = await postCheckNIK(nik);

      const {
        data: {
          data: { is_identity_exists, phone_number },
        },
      } = response;

      if (!!is_identity_exists) {
        setStateError('nik', 'NIK kamu sudah terdaftar, Coba cek kembali');
        setStateError('phone_number', phone_number);
      }

      return { is_identity_exists, phone_number };
    } catch (error) {
      let errorMessage = error?.message;
      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      setStateError('nik', errorMessage);

      return { is_identity_exists: false, phone_number: '', is_error: true };
    }
  };

  useEffect(() => {
    setStateFromLocalStorage();
  }, [setStateFromLocalStorage]);

  const validateCurrentUrlWithActivePage = useCallback(async () => {
    if (isLoading) {
      return;
    }

    // if there is no registration-step-active in local storage,
    // we set the default value, if user has robo then go to step 2 else step 1
    const registrationStepActive =
      (await Storage.getObject('registration-step-active')) ??
      (!!roboId ? 2 : 1);

    const historyPathname = removeTrailingSlash(history.location.pathname);

    // is current url is in the listStepper ? if not, do nothing.
    const isUrlInListStepper = listStepper.find(
      (t) => t.page === historyPathname
    );

    if (!isUrlInListStepper) return;

    const pathname = listStepper[Number(registrationStepActive) - 1];

    if (!pathname) return; // do nothing

    if (historyPathname !== pathname.page) {
      if (!isLogin) {
        return history.replace('/');
      }
      history.replace(pathname.page);
    }
  }, [isLoading, roboId, history, listStepper, isLogin]);

  useEffect(() => {
    validateCurrentUrlWithActivePage();
  }, [validateCurrentUrlWithActivePage]);

  // Handle go to next step
  const handleNextStep = useCallback(() => {
    let nextStep = activeStep + 1;

    let nextPage = listStepper.find((s, i) => i + 1 === nextStep)?.page;

    if (!!nextPage) {
      Storage.setObject('registration-step-active', nextStep);
      setActiveStep(nextStep);
      setLatestStep(nextStep);
      history.replace(nextPage);
    }
  }, [activeStep, history, listStepper]);

  // Handle go to previous step
  const handlePreviousStep = useCallback(() => {
    let prevStep = activeStep - 1;

    if (prevStep <= 1) {
      setActiveStep(latestStep);
      return history.replace('/step-registration');
    }

    setActiveStep(prevStep);

    let prevPage = listStepper.find((s, i) => i + 1 === prevStep)?.page;

    if (prevPage) {
      history.replace(prevPage);
    } else {
      history.replace('/');
    }
  }, [latestStep, activeStep, history, listStepper]);

  // Get latest unfinished step
  const getCurrentStep = useCallback(async () => {
    setLoadingStep(true);

    const registrationStepActive = await Storage.getObject(
      'registration-step-active'
    );

    if (!registrationStepActive && roboId) {
      // set step active and finished
      setActiveStep(2);
      setLatestStep(2);
    }

    if (registrationStepActive) {
      setActiveStep(registrationStepActive);
      setLatestStep(registrationStepActive);
    }

    setLoadingStep(false);
  }, [roboId]);

  useEffect(() => {
    getCurrentStep();
  }, [getCurrentStep]);

  return (
    <RegisterAccountContext.Provider
      value={{
        listStepper,
        stateForm: state,
        setStateForm,
        saveStateToStorage,
        saveStepperActiveStep,
        setStateFromLocalStorage,
        setPrefillForm,
        validateEmailRegistration,
        validateRegisterAccount,
        validateRegisterAccountIdentity,
        validateRegisterBankAccount,
        validateNIK,
        validateEmailIsExists,
        errorForm: error,
        resetError,
        isUnbank,
        checkUserIsUnbank,
        profileDetail,
        removeStateError,
        handleNextStep,
        handlePreviousStep,
        activeStep,
        latestStep,
        loadingStep,
      }}
    >
      {children}
    </RegisterAccountContext.Provider>
  );
};

export const useRegisterAccountContext = () => {
  const context = React.useContext(RegisterAccountContext);

  if (context === undefined) {
    throw new Error(
      'useRegisterAccountContext must be used within a RegisterAccountContextProvider'
    );
  }

  return context;
};
