import React, { SyntheticEvent, useRef, useState, useEffect } from 'react';
import { numberToGeneralValueFormat } from 'utils/stringHelper';
import { Text } from '@bibitid/uikit-v1';
import {
  ToogleAmountBlurred,
  Tooltip,
} from 'features/common/AmountBlurred/components';
import DotComponent from 'features/home/components/DotComponent';
import { useAmountBlurred } from 'features/common/AmountBlurred/provider/AmountBlurredProvider';
import Show from 'features/common/components/Show/Show';

import classnames from 'classnames';
import styles from './AmountPortofolio.module.css';

interface IAmountPorotfolio {
  amount: number;
  premium?: boolean;
  showTooltip?: boolean;
  handleBlurredAmount: (event: SyntheticEvent<any>) => void;
  onCloseTooltip?: () => void;
}

const AmountPortofolio: React.FC<IAmountPorotfolio> = ({
  amount,
  premium,
  showTooltip,
  handleBlurredAmount,
  onCloseTooltip,
}) => {
  const amountRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [margin, setMargin] = useState(0);
  const [switchAnimation, setSwitchAnimation] = useState(false);

  const { isAmountBlurred, isShowAmount, isHideAmount } = useAmountBlurred();

  useEffect(() => {
    const widthAmount = amountRef.current?.getBoundingClientRect().width || 0;

    setWidth(widthAmount);

    if (widthAmount < 111) {
      setSwitchAnimation(true);
    } else {
      setSwitchAnimation(false);
    }
  }, [amount]);

  useEffect(() => {
    if (isAmountBlurred) {
      setMargin(143);
    } else {
      setMargin(width + 32);
    }
  }, [isAmountBlurred, width]);

  const handleClick = (event: React.SyntheticEvent<any, Event>) => {
    handleBlurredAmount(event);
  };

  return (
    <div className={styles['wrapper']}>
      <div onClick={handleClick} className={styles['amount-wrapper']}>
        <div
          style={{
            //@ts-ignore
            '--amountWidth': `${width}px`,
          }}
          className={classnames(styles['container'], {
            [styles['container-blurred']]: isAmountBlurred,
            [styles['container-unblurred']]: !isAmountBlurred,
            [styles['container-hide']]: isHideAmount && !switchAnimation,
            [styles['container-show']]: isShowAmount && !switchAnimation,
            [styles['bouncy-right-to-left']]: isHideAmount && switchAnimation,
            [styles['bouncy-left-to-right']]: isShowAmount && switchAnimation,
          })}
        >
          <div
            className={classnames(styles['nominal-container'], {
              [styles['hide']]: isAmountBlurred,
              [styles['show']]: !isAmountBlurred,
              [styles['animation-hide']]: isHideAmount,
              [styles['animation-show']]: isShowAmount,
            })}
          >
            <div ref={amountRef} className={styles['nominal-wrapper']}>
              <Text
                htmlTag='span'
                type='title1'
                className={styles['margin-right-4']}
              >
                Rp
              </Text>
              <Text type='headline1' htmlTag='span'>
                {numberToGeneralValueFormat(amount, {
                  mantissa: 0,
                })}
              </Text>
            </div>
          </div>
          <div
            className={classnames(styles['blurred-container'], {
              [styles['hide']]: !isAmountBlurred,
              [styles['show']]: isAmountBlurred,
              [styles['animation-hide']]: isShowAmount,
              [styles['animation-show']]: isHideAmount,
            })}
          >
            <DotComponent dotAmount={7} />
          </div>
        </div>
      </div>
      <div className={styles['icon-margin']}>
        <ToogleAmountBlurred
          premium={premium}
          tooltipKey={'portfolio'}
          showTooltip={showTooltip || false}
          handleBlurredAmount={handleClick}
        />
      </div>

      <Show when={(showTooltip && width > 0) || false}>
        <Tooltip marginLockIcon={margin} onClose={onCloseTooltip} />
      </Show>
    </div>
  );
};

export default AmountPortofolio;
