/**
 * Check if the value is a number
 */
export function isNumber(value: any): boolean {
  return !isNaN(parseFloat(value)) && !isNaN(value - 0);
}

/**
 * round down decimal
 */
export function floorWithDecimal(value: number, digits: number = 0): number {
  const power = Math.pow(10, digits);
  return Math.floor(value * power) / power;
}
