import list from './index';
import http from 'core/http';
import queryClient from 'network/queryClient';
import { generalLogError } from 'utils/Analytics';

/** Picker data */

export const getIncomeSourceData = () => {
  return http.get(list.incomeSource);
};

export const getIncomeData = () => {
  return http.get(list.income);
};

export const getOccupationData = () => {
  return http.get(list.occupations);
};

export const getRelationshipData = () => {
  return http.get(list.relationshipList);
};

/** API */

export const getNotificationData = () => {
  return http.get(list.notifications, {
    type: 2,
    is_read: 0,
    limit: 10,
    page: 1,
  });
};

export const getFormData = () => {
  return http.get(list.pengkinianFormData);
};

export const getSettingPreference = () => {
  return http.get(list.pengkinianSettingPref);
};

export const postRenewalData = (params: Record<string, any>) => {
  return http.post(list.userProfileUpdate, params);
};

export const postCheckNIK = (params: Record<string, any>) => {
  return http.post(list.checkNIK, params);
};

export const getBOAllowRequest = (identity: string) => {
  return http.get(list.pengkinianBOAllowRequest, { bo_identity: identity });
};

export function postRenewalReadNotification(id: number) {
  return http.post(list.pengkinianReadNotification(id), {
    is_read: 1,
    is_show_modal: 0,
  });
}

export function postRenewalReadNotifBulk(params: number[]) {
  let finalObj = {};
  const finalParam = params.map((item) => {
    return {
      unotif_id: item,
      is_show_banner: 0,
      is_show_modal: 0,
      is_read: 1,
    };
  });
  finalObj = {
    notifications: finalParam,
  };
  return http.post(list.pengkinianReadNotifBulk, finalObj).then(() => {
    queryClient
      .invalidateQueries(['notificationsProviderData', '/notifications'])
      .catch(() => {
        generalLogError(
          'Back End Error: Error Pengkinian Data Notifications Read Bulk',
          {
            file: 'Pengkinian data helpers index',
            case: 'error_read_notification_api_bulk',
            notifID: finalParam,
          }
        );
      });
  });
}

export const postSetBeneficialOwner = (id: number, action: string) => {
  return http.post(list.pengkinianBOSet, {
    user_beneficial_id: id,
    is_approve: action === 'approve' ? true : false,
  });
};
