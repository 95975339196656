import React, { FC } from 'react';
import { Flex } from '@bibitid/uikit';
import style from './InfoProductList.module.css';
import IconLamp from 'assets/images/home/onboarding-modal-flow/icon-lamp/IconLamp';

interface InfoProductListProps {
  infoMessage: string;
}
const InfoProductList: FC<React.PropsWithChildren<InfoProductListProps>> = ({
  infoMessage,
}) => {
  return (
    <Flex justify='start' className={style['bibit-product-info-container']}>
      <Flex.Item className={style['bibit-product-info-icon']}>
        <IconLamp />
      </Flex.Item>
      <Flex.Item className={style['bibit-product-info-text']}>
        {infoMessage}
      </Flex.Item>
    </Flex>
  );
};

export default InfoProductList;
