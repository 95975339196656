import React, { FC, useState } from 'react';
import { ButtonNew, Modal } from '@bibitid/uikit-v1';
import styles from './ProductListByCategoryModal.module.css';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';
import ReksadanaProductProvider from 'features/product/reksadana/context/ReksadanaProductContext';
import { ReksadanaProductListWithoutTabBarHOC } from 'features/product/reksadana/ReksadanaProductList';
import Show from 'features/common/components/Show/Show';
import CommonBestTab from 'features/explore/pages/ExploreBestPage/Tabs/CommonBestTab';

interface ProductListByCategoryModalProps {
  isOpen: boolean;
  category: string;
  title: JSX.Element;
  selectedProduct?: string;
  onClose?: () => void;
  handleContinueCreatePortfolio?: (params: any) => void;
  onProductSelected?: (
    roboCategory: string,
    symbol: string,
    additional: any
  ) => void;
  onCustomNavigateMutualFundDetail?: (symbol: string) => void;
  showLink?: boolean;
  isBibitDarurat?: boolean;
  onConfirmCompare?: () => void;
}
const ProductListByCategoryModal: FC<
  React.PropsWithChildren<ProductListByCategoryModalProps>
> = ({
  isOpen,
  category,
  title,
  selectedProduct,
  onClose = () => {},
  onProductSelected = () => {},
  handleContinueCreatePortfolio = () => {},
  onCustomNavigateMutualFundDetail = () => {},
  isBibitDarurat,
  onConfirmCompare = () => {},
}) => {
  const { onboardingModalVisible } = useHomeOnboardingContext();

  /**
   * notes: when `category` is `best`, the UI is Tab Version. and default would be `moneymarket`
   *
   * The tabs is: Pasar Uang | Obligasi | Saham | Syariah
   */
  const [currentCategoryTab, setCurrentCategoryTab] = useState('moneymarket');

  return (
    <Modal visible={isOpen} onClickBack={onClose} showMask={false} full={true}>
      <Modal.Header
        showClose={false}
        showBackButton={true}
        title={title}
        centerTitle
      />
      <Modal.Body
        className={
          onboardingModalVisible
            ? 'product-list-category-modal with-footer'
            : 'product-list-category-modal'
        }
        style={{ padding: '0px' }}
      >
        <div>
          <Show when={isOpen && category !== 'best'}>
            <ReksadanaProductProvider
              pageType={
                // If Bibit Darurat, set sort_by to 8
                isBibitDarurat ? 'darurat' : 'discover'
              }
            >
              <ReksadanaProductListWithoutTabBarHOC
                pageType='discover'
                category={category}
                onCardClick={onProductSelected}
                selected={selectedProduct}
                ableToSelectProduct={true}
                showPullToRefresh={false}
                onConfirmCompare={onConfirmCompare}
                showLink={onboardingModalVisible}
                onCustomNavigateMutualFundDetail={
                  onCustomNavigateMutualFundDetail
                }
                showBanner={false}
                showHeader={false}
                enabledSetStatusBarColor={false}
                listWrapperClassName={
                  styles['bit-product-modal-list-with-padding']
                }
                cleanQueryParams
                inModal={true}
                showOnlyTopProduct={category === 'best'}
              />
            </ReksadanaProductProvider>
          </Show>

          <Show when={isOpen && category === 'best'}>
            <CommonBestTab
              onChangeTab={(data) => {
                setCurrentCategoryTab(data.type);
              }}
            >
              <ReksadanaProductProvider
                pageType={
                  // If Bibit Darurat, set sort_by to 8
                  isBibitDarurat ? 'darurat' : 'discover'
                }
              >
                <ReksadanaProductListWithoutTabBarHOC
                  pageType='discover'
                  category={currentCategoryTab}
                  onCardClick={onProductSelected}
                  selected={selectedProduct}
                  ableToSelectProduct={true}
                  showPullToRefresh={false}
                  onConfirmCompare={onConfirmCompare}
                  showLink={onboardingModalVisible}
                  onCustomNavigateMutualFundDetail={
                    onCustomNavigateMutualFundDetail
                  }
                  showBanner={false}
                  showHeader={false}
                  enabledSetStatusBarColor={false}
                  listWrapperClassName={`height-children`}
                  removePullerBox
                  cleanQueryParams
                  inModal={true}
                  showOnlyTopProduct
                  showCompareButton={false}
                  classNameBox='best-explore-box-porto'
                />
              </ReksadanaProductProvider>
            </CommonBestTab>
          </Show>
        </div>
      </Modal.Body>

      {/* Fixed button */}
      {onboardingModalVisible && (
        <Modal.Footer
          primaryButton={
            <ButtonNew
              variant={'primary'}
              content={'Lanjut'}
              disabled={!selectedProduct}
              onClick={handleContinueCreatePortfolio}
              testId='product-list-modal-btn-test'
            />
          }
        />
      )}
    </Modal>
  );
};

export default ProductListByCategoryModal;
