import { setNativeToggleIdleTimer } from 'features/login/networks/services';
import React from 'react';
import { isInsideNativeContainer } from 'utils/validator';
import useAuth from './useAuth';
import useProfileData from './useProfileData';

/**
 * This hook will set device doesn't sleep on a page
 * Action:
 * - Set postmessage false when user is landed on this page
 * - Set true again when user exits from this page
 *
 * Usual case: Pin page, Page with videos
 */
const useNativeIdleTimerToggle = () => {
  const { isLogin: isLoggedIn } = useAuth();

  // Get profile data from hook
  const { data: dataProfile } = useProfileData(isLoggedIn);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  // tell us is pin user Active
  const pinUserActive = !!profileDetail?.preference?.pinChallengeStatus;

  React.useEffect(
    function handleNativeIdleTimerToggle() {
      if (isInsideNativeContainer() && pinUserActive) {
        // Will set timer to OFF to native
        setNativeToggleIdleTimer(false);

        return () => {
          // When exit from a page/component
          // Will set timer back to ON to native
          setNativeToggleIdleTimer(true);
        };
      }
    },
    [pinUserActive]
  );
};

export default useNativeIdleTimerToggle;
