import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

interface DataOpenGopay {
  url: string;
  invoice: string;
}

interface GopayReducerState {
  openGopay: {
    visible: boolean;
    mobileUrl: string;
    orderInvoice: string;
  };
  modalPaymentSuccess: boolean;
}

const defaultState: GopayReducerState = {
  openGopay: {
    visible: false,
    mobileUrl: '',
    orderInvoice: '',
  },
  modalPaymentSuccess: false,
};

export const setDataOpenGopay = createAction<DataOpenGopay>(
  'GOPAY/SET_OPEN_MODAL_OPEN_GOPAY'
);

export const setToggleModalOpenGopay = createAction<boolean>(
  'GOPAY/SET_CLOSE_MODAL_OPEN_GOPAY'
);

export const setToggleModalGopayPaymentSuccess = createAction<boolean>(
  'GOPAY/SET_TOGGLE_MODAL_SUCCESS_PAYMENT_GOPAY'
);

const gopayReducer = createReducer<typeof defaultState>({}, defaultState);

gopayReducer.on(setDataOpenGopay, (state, payload: DataOpenGopay) => {
  return update(state, {
    openGopay: {
      mobileUrl: { $set: payload.url },
      orderInvoice: { $set: payload.invoice },
      visible: { $set: true },
    },
  });
});

gopayReducer.on(setToggleModalOpenGopay, (state, payload: boolean) => {
  return update(state, {
    openGopay: {
      visible: { $set: payload },
    },
  });
});

gopayReducer.on(
  setToggleModalGopayPaymentSuccess,
  (state, payload: boolean) => {
    return update(state, {
      modalPaymentSuccess: { $set: payload },
    });
  }
);

export default gopayReducer;
