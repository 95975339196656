const now = new Date();

/** Next Month from now Date */
export const nextMonth: Date = new Date(
  now.getFullYear(),
  now.getMonth() + 1,
  1
);

/** Next century from now */
export const nextCentury: Date = new Date(
  now.getFullYear() + 100,
  now.getMonth() + 1,
  1
);
