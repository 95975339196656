import React from 'react';
import LazyModal from 'features/common/LazyModal';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

import BestProductListModal from './BestProductListModal';

const ProductListByCategoryModalLazy = React.lazy(
  () => import('./ProductListByCategoryModal')
);

const ProductListByCategoryModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'product_list_best_of';

  return (
    <LazyModal component={ProductListByCategoryModalLazy} visible={visible} />
  );
};

const ExploreProductModalLazy = React.lazy(
  () => import('./ExploreProductOnboardingModal')
);

const ExploreProductOnboardingModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'explore_product';

  return <LazyModal component={ExploreProductModalLazy} visible={visible} />;
};

export {
  ProductListByCategoryModal,
  BestProductListModal,
  ExploreProductOnboardingModal,
};
