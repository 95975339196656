import { useContext } from 'react';
import { ThemeContext } from '../ThemeProvider';
import { mappedColors } from '../mapped-color';

export const useThemeColor = () => {
  const { theme } = useContext(ThemeContext);
  // picked color in mapped colors
  const color = mappedColors[theme];

  return color;
};
