import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

const defaultState = {
  step: 0,
  form: [],
  fromLinkExternal: false,
  is_fetching: false,
  err: '',
};

const [resetSettingValue, increment, change, request, failure, success] = [
  'DEFAULT_SETTINGINDEX_VALUE',
  'INCREMENT_SETTINGINDEX_STEP',
  'CHANGE_SETTINGINDEX',
  'LOAD_SETTINGINDEX_REQUEST',
  'LOAD_SETTINGINDEX_FAILURE',
  'LOAD_SETTINGINDEX_SUCCESS',
].map(createAction);

const reducer = createReducer(
  {
    [resetSettingValue]: (state) => {
      const { step, form, fromLinkExternal, is_fetching, err } = defaultState;
      return update(state, {
        step: { $set: step },
        form: { $set: form },
        fromLinkExternal: { $set: fromLinkExternal },
        is_fetching: { $set: is_fetching },
        err: { $set: err },
      });
    },
    [increment]: (state) => {
      const { step } = state;
      return update(state, {
        step: step + 1,
      });
    },
    [change]: (state, payload) => {
      const { step, form } = state;
      const { key, value } = payload;
      const alreadyExists = form[step];

      let newState = state;

      if (!alreadyExists) {
        newState.form = update(state.form, {
          [step]: { $set: {} },
        });
      }

      return update(newState, {
        form: {
          [step]: {
            [key]: { $set: value },
          },
        },
      });
    },
    [request]: (state) => {
      return update(state, {
        is_fetching: { $set: true },
        err: { $set: '' },
      });
    },
    [failure]: (state, payload) => {
      const { err } = payload;
      return update(state, {
        is_fetching: { $set: false },
        err: { $set: err },
      });
    },
    [success]: (state, payload) => {
      const { data } = payload;
      return update(state, {
        is_fetching: { $set: false },
        data: { $set: data },
      });
    },
  },
  defaultState
);

export { resetSettingValue, change, increment };

export default reducer;
