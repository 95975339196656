import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useAppModalGlobalStateContext } from 'features/common/modals/AppModalGlobalContext';

const AppOnlyModal = React.lazy(() => import('./AppOnlyModal'));

const IndexAppOnlyModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { appOnlyModalVisible } = useAppModalGlobalStateContext();

  return <LazyModal component={AppOnlyModal} visible={appOnlyModalVisible} />;
};

export default IndexAppOnlyModal;
