import http from 'core/http';
import { endpoints } from './endpoints';
import { AxiosResponse } from 'axios';
import { openThirdPartyWebView } from 'utils/thirdPartyWebview';

type WebviewResponse = {
  data?: {
    data?: {
      token: string;
    };
    message: string;
  };
};

/**
 * Submit user's registration data
 */
export function postSubmitRegister(payload: Record<string, any>) {
  return http.post(endpoints.registerSubmit, payload);
}

/**
 *  check stockbit data when user register
 */
export const getStockbitDataRegister = () => {
  return http.get<
    AxiosResponse<{
      phone?: string;
      email?: string;
    }>
  >(endpoints.stockbitStats);
};

/**
 *  get url stockbit then open stockbit webview
 */
export const postAuthStockbit = () => {
  return new Promise<WebviewResponse>((resolve, reject) => {
    return http
      .post(endpoints.authStockbit)
      .then(async (res) => {
        const { url } = res?.data?.data;

        const webviewRes = await openThirdPartyWebView({
          url,
          exit_url: ['.*/register-stockbit-data.*'],
        });

        if (webviewRes.data?.data?.isProcessFinished) {
          const urlSearch = new URL(webviewRes?.data?.data?.exit_url).search;
          const params = new URLSearchParams(urlSearch);
          const token = params.get('token');

          if (!token) {
            return reject({
              response: {
                data: {
                  message: 'Token not found',
                  type: 'token_not_found',
                },
              },
            });
          }

          return resolve({
            data: {
              data: {
                token: token || '',
              },
              message: webviewRes.data?.message,
            },
          });
        }

        return reject({
          response: {
            data: {
              message: 'Exit webview stockbit',
              type: 'exit_webview_stockbit',
            },
          },
        });
      })
      .catch((err) => reject(err));
  });
};
