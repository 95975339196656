export const endpoints = {
  portfolio: '/institutions/portfolios',
  portfolioCategoryID: (categoryId: string) =>
    `/institutions/portfolios/${categoryId}`,
  profileDetail: '/institutions/profile',
  profile: '/institutions',
  notification: '/notifications/stats',
  paymentMethod: '/institutions/payments/channel',
  buy: '/institutions/orders/buy',
  uploadPaymentReceipt: (orderCode: string) =>
    `/institutions/orders/payments/${orderCode}/upload`,
  confirmPaymentReceipt: (orderCode: string) =>
    `/institutions/orders/payments/${orderCode}/confirm`,
  pendingOrder: '/institutions/orders/requests/stats',
  realizedProfitOrderDetails: (profitLossId: string) =>
    `/institutions/portfolios/performances/realized-profits/${profitLossId}`,
  institutionLeads: '/institutions/leads',
  earlyAccessStats: '/institutions/leads/stats',
  adminInvitations: '/institutions/admins/invitations',

  // Institution Registration
  wealthSpecialist: '/institutions/leads/wealth-specialists',
  companyTypes: '/institutions/options/types',
  companyCharacteristics: '/institutions/options/characteristics',
  companyIncomeLevels: '/institutions/options/income-levels',
  companyRiskProfiles: '/institutions/options/risk-profiles',
  companyGoals: '/institutions/options/investment-goals',
  companySourceIncomes: '/institutions/options/income-sources',
  companyAssets: '/institutions/options/assets',
  companyProfits: '/institutions/options/profits',
  companyIndustries: '/institutions/options/company-industries',
  createInstitutionData: '/institutions/register?v=2',
  updateInstitutionData: (form_name: string) =>
    `/institutions/register/${form_name}`,
  institutionStats: '/institutions/register/stats',
  rejectedList: '/institutions/register/rejected-reason',
  checkCompanyEmail: '/institutions/register/check/email',
};
