import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useSelector } from 'react-redux';
import { TRootReducers } from 'store/root';
import './GopayPaymentSuccess.css';

const GojekPaymentSuccess = React.lazy(() => import('./GojekPaymentSuccess'));

const LazyGojekPaymentSuccess = (props: any) => {
  const visible = useSelector(
    (state: TRootReducers) => state.gopay.modalPaymentSuccess
  );

  return (
    <LazyModal component={GojekPaymentSuccess} visible={visible} {...props} />
  );
};

export default LazyGojekPaymentSuccess;
