import React from 'react';
import style from './DotComponent.module.css';

const DotComponent = ({ dotAmount }: { dotAmount: number }) => {
  return (
    <div className={style['dot-componnent']}>
      {[...Array(dotAmount)].map((_, i) => {
        return <span key={i} />;
      })}
    </div>
  );
};

export default DotComponent;
