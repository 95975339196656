import React, { useEffect, useMemo, useState } from 'react';
import { Text, Button } from '@bibitid/uikit';
import { Modal, ButtonNew } from '@bibitid/uikit-v1';
import classNames from 'classnames';
import Analytics from 'utils/Analytics';

import {
  SortByEnum,
  fundManagementOptions,
  redemptionOptions,
  topCompanyOptions,
} from 'features/common/reksadana/constants';
import ChooseCustodianBankModal from './components/ChooseCustodianBankModal/ChooseCustodianBankModal';
import { useQueryParams } from 'utils/routeHelper';
import {
  useGetProductsFilter,
  useGetSpecificRedemptionBank,
} from 'features/common/reksadana/networks/resolver';
import { GetProductsFilterParams } from 'features/common/reksadana/types';

import style from './FilterReksaDanaModal.module.css';
import { useUserInInstitution } from 'features/institution/hooks';
import { getAnalyticData } from 'features/common/reksadana/utils/filterAnalytic';
import { ChevronDown } from 'react-feather';

/**
 * Filter reksadana modal props
 */
export interface Props {
  /** Modal visibility */
  visibility: boolean;
  /** On modal close */
  onClose: () => void;
  /** On click reset button */
  onClickReset?: () => void;
  /** On click Terapkan button */
  onClickFilter: (data: GetProductsFilterParams) => void;
  /** Maximum buy filter */
  maxBuy?: number;
  /** Investment manager filter */
  im?: string;
  /** Meta filter */
  meta?: string;
  /** Type of RD filter */
  type?: string;
  /** Type of currency filter */
  currency?: string;
  /** Is user syariah toggle on */
  showSyariah?: boolean;
  /** Extra params for filter */
  extraParams?: Object;
  /** Category of page */
  categoryType?: string;
  /** Sort by type */
  sortBy?: string;
  /** Show Top Product Section
   *  default = true
   */
  showTopProductSection?: boolean;
  /** Sort type (ASC/DESC) */
  sort?: string;
  /** Filter period */
  period?: string;
  /** To set selected bank name (for analytic purpose) */
  setSelectedBankName: (selectedBankName: string) => void;
}

/**
 * Modal for filtering reksadana by
 * - fund management type
 * - redemption type
 * - bank custodian
 */
const FilterReksaDanaModal: React.FC<Props> = ({
  visibility,
  onClose,
  onClickReset,
  onClickFilter,
  maxBuy,
  im,
  meta,
  type,
  currency,
  showSyariah = true,
  showTopProductSection = true,
  extraParams = {},
  categoryType,
  sortBy = '',
  sort = '',
  period = '',
  setSelectedBankName,
}) => {
  const query = useQueryParams();

  const urlPath = window.location.pathname;
  const isRoboPage = urlPath.includes('/profiling-select/');

  const analyticData: any = getAnalyticData(isRoboPage, categoryType, im);

  const syariahQuery = query.get('is_syariah');
  const isInstantRedemptionQuery = query.get('is_instant_redemption');
  const isTopCompanyQuery = query.get('is_top_product');
  const bankIdQuery = query.get('bank_id');
  const institution = useUserInInstitution();

  const syariahString = useMemo(() => {
    return !!syariahQuery ? Number(syariahQuery) : undefined;
  }, [syariahQuery]);

  const instantString = useMemo(() => {
    return !!isInstantRedemptionQuery
      ? Number(isInstantRedemptionQuery)
      : undefined;
  }, [isInstantRedemptionQuery]);

  const topCompanyString = useMemo(() => {
    return !!isTopCompanyQuery ? Number(isTopCompanyQuery) : undefined;
  }, [isTopCompanyQuery]);

  const bankString = useMemo(() => {
    return !!bankIdQuery ? Number(bankIdQuery) : undefined;
  }, [bankIdQuery]);

  const [fundManagementSelected, setFundManagementSelected] =
    useState(syariahString);

  const [topCompanySelected, setTopCompanySelected] =
    useState(topCompanyString);

  const [redemptionSelected, setRedemptionSelected] = useState(instantString);

  const [bankSelected, setBankSelected] = useState(bankString);

  useEffect(() => {
    setFundManagementSelected(syariahString);
  }, [syariahString]);

  useEffect(() => {
    setRedemptionSelected(instantString);
  }, [instantString]);

  useEffect(() => {
    setTopCompanySelected(topCompanyString);
  }, [topCompanyString]);

  useEffect(() => {
    setBankSelected(bankString);
  }, [bankString]);

  const [visibilityChooseBankModal, setVisibilityChooseBankModal] =
    useState(false);

  const { data: dataSpecificRedemptionBank } = useGetSpecificRedemptionBank(
    Number(bankSelected),
    !!bankSelected && visibility
  );

  /** Bank name selected for rendering text on section bank */
  const bankNameSelected =
    dataSpecificRedemptionBank?.data?.data?.name ?? 'Semua Bank';

  const {
    data: dataProductsFilter,
    isFetching: fetchingProductsFilter,
    isLoading: loadingProductsFilter,
  } = useGetProductsFilter(
    {
      limit: 1,
      tradable: 1,
      bank_id: bankSelected,
      syariah: fundManagementSelected,
      is_instant_redemption: redemptionSelected,
      is_top_product: topCompanySelected,
      ...(meta ? { meta } : {}),
      ...(im ? { im } : {}),
      ...(maxBuy ? { max_buy: maxBuy } : {}),
      ...(type ? { type } : {}),
      /** hide usd products when sort by minimum buy */
      ...(currency ? { currency: sortBy !== '6' ? currency : 'idr' } : {}),
      /** if in usd category override currency to usd */
      ...(currency && currency === 'usd' ? { currency } : {}),
      ...extraParams,
    },
    visibility
  );

  useEffect(() => {
    const query = {
      limit: 1,
      tradable: 1,
      bank_id: bankSelected,
      syariah: fundManagementSelected,
      is_instant_redemption: redemptionSelected,
      is_top_product: topCompanySelected,
      ...(meta ? { meta } : {}),
      ...(im ? { im } : {}),
      ...(maxBuy ? { max_buy: maxBuy } : {}),
      ...(type ? { type } : {}),
      /** hide usd products when sort by minimum buy */
      ...(currency ? { currency: sortBy !== '6' ? currency : 'idr' } : {}),
      /** if in usd category override currency to usd */
      ...(currency && currency === 'usd' ? { currency } : {}),
      ...extraParams,
    };
    /** Params without undefined value */
    const queryFormatting: any = {};
    Object.entries(query).forEach(([key, value]) => {
      if (value !== undefined) queryFormatting[key] = value;
    });

    if (institution) {
      queryFormatting.currency = 'idr';
    }

    if (!loadingProductsFilter) {
      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'result',
          context: 'filter_sort.filter_modal',
          trigger: 'api',
          data: {
            query: queryFormatting,
          },
        },
      });
      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'result',
          context: '',
          trigger: 'api',
          data: {
            query: queryFormatting,
          },
        },
      });
    }
  }, [
    loadingProductsFilter,
    bankSelected,
    currency,
    extraParams,
    fundManagementSelected,
    im,
    institution,
    maxBuy,
    meta,
    redemptionSelected,
    sortBy,
    topCompanySelected,
    type,
  ]);

  /** Total products filtered for rendering text on submit button */
  /** If sort by minimum pembelian, hide usd */
  const totalProductsFiltered = dataProductsFilter?.data?.meta?.total_rows ?? 0;

  const disabledResetButton =
    (fundManagementSelected === undefined &&
      !redemptionSelected &&
      !topCompanySelected &&
      !bankSelected) ||
    fetchingProductsFilter;

  /** Set form into query params values */
  const setFormIntoQueryValues = () => {
    setTopCompanySelected(topCompanyString);
    setFundManagementSelected(syariahString);
    setRedemptionSelected(instantString);
    setBankSelected(bankString);
  };

  const handleClickTopCompanyOption = (
    event: React.SyntheticEvent<HTMLDivElement>
  ) => {
    const id = !!event.currentTarget.dataset?.id
      ? Number(event.currentTarget.dataset?.id)
      : undefined;

    setTopCompanySelected(id);

    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'top_product',
        context: 'filter_sort.filter_modal',
        trigger: 'click',
        data: {
          top: id === undefined ? 'all' : 'top',
        },
      },
    });
  };

  const handleClickFundManagementOption = (
    event: React.SyntheticEvent<HTMLDivElement>
  ) => {
    const id = !!event.currentTarget.dataset?.id
      ? Number(event.currentTarget.dataset?.id)
      : undefined;

    setFundManagementSelected(id);

    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'fund_type',
        context: 'filter_sort.filter_modal',
        trigger: 'click',
        data: {
          ...analyticData,
          fund:
            id === undefined ? 'all' : id === 0 ? 'conventional' : 'syariah',
        },
      },
    });
  };

  const handleClickRedemptionOption = (
    event: React.SyntheticEvent<HTMLDivElement>
  ) => {
    const id = !!event.currentTarget.dataset?.id
      ? Number(event.currentTarget.dataset?.id)
      : undefined;

    setRedemptionSelected(id);

    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'redemption_type',
        context: 'filter_sort.filter_modal',
        trigger: 'click',
        data: {
          ...analyticData,
          instant: id === undefined ? 'all' : 'instant',
        },
      },
    });
  };

  const handleClickResetButton = () => {
    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'reset_filter',
        context: 'filter_sort.filter_modal',
        trigger: 'click',
        data: {
          ...analyticData,
          fund:
            fundManagementSelected === undefined
              ? 'all'
              : fundManagementSelected === 0
              ? 'conventional'
              : 'syariah',
          instant: redemptionSelected === undefined ? 'all' : 'instant',
          bank: bankNameSelected,
        },
      },
    });

    setTopCompanySelected(undefined);
    setFundManagementSelected(undefined);
    setRedemptionSelected(undefined);
    setBankSelected(undefined);
    if (!!onClickReset) onClickReset();
  };

  const handleSubmitFilter = () => {
    const newParams = {
      bank_id: bankSelected,
      syariah: fundManagementSelected,
      is_instant_redemption: redemptionSelected,
      is_top_product: topCompanySelected,
    };

    // for analytic purpose
    setSelectedBankName(bankNameSelected);

    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'apply_filter',
        context: 'filter_sort.filter_modal',
        trigger: 'click',
        data: {
          ...analyticData,
          fund:
            fundManagementSelected === undefined
              ? 'all'
              : fundManagementSelected === 0
              ? 'conventional'
              : 'syariah',
          instant: redemptionSelected === undefined ? 'all' : 'instant',
          bank: bankNameSelected,
          top: topCompanySelected === undefined ? 'all' : 'top',
          sort_by: SortByEnum?.[sortBy],
          sort,
          period,
        },
      },
    });

    onClickFilter(newParams);
  };

  const handleCloseModal = () => {
    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'close_modal',
        context: 'filter_sort.filter_modal',
        trigger: 'click',
        data: {
          screen: 'filter_modal',
        },
      },
    });

    setFormIntoQueryValues();
    onClose();
  };

  const toggleChooseBankModal = () => {
    if (!visibilityChooseBankModal) {
      Analytics.logEventAction({
        eventName: 'filter_sort_action',
        parameter: {
          action: 'fund_bank',
          context: 'filter_sort.filter_modal',
          trigger: 'click',
          data: analyticData,
        },
      });
    }

    setVisibilityChooseBankModal(!visibilityChooseBankModal);
  };

  const handleSubmitChooseBankModal = (id: number | undefined) => {
    setBankSelected(id);
    toggleChooseBankModal();
  };

  useEffect(() => {
    if (!visibility) return;

    const data: any = getAnalyticData(isRoboPage, categoryType, im);

    data.syariah =
      showSyariah && categoryType !== 'syariah' && topCompanySelected !== 1
        ? 'Off'
        : 'On';

    Analytics.logEventNavigation({
      eventName: 'navigate',
      parameter: {
        page: 'filter_sort',
        view: 'filter_modal',
        data,
      },
    });
  }, [
    visibility,
    isRoboPage,
    showSyariah,
    categoryType,
    topCompanySelected,
    im,
  ]);

  return (
    <>
      <Modal
        className={style['bit-filter-rd']}
        visible={visibility}
        onClose={handleCloseModal}
      >
        <Modal.Header
          title='Filter Berdasarkan'
          showBackButton={false}
          showClose={true}
        />
        <Modal.Body>
          <div className={style['bit-filter-rd__content']}>
            {/* {Top Reksa Dana Filter} */}
            {!!showTopProductSection && (
              <div className={style['fund_management_type']}>
                <Text className={style['fund_management_type__title']}>
                  Pilihan Reksa Dana
                </Text>
                <div className={style['fund_management_type__chips']}>
                  {topCompanyOptions.map((item, index) => {
                    return (
                      <div
                        key={`management-type-${index}`}
                        data-id={item.id}
                        className={classNames(style['chip'], {
                          [style['chip__selected']]:
                            item.id === topCompanySelected,
                        })}
                        onClick={handleClickTopCompanyOption}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* Hide when top company is selected, because of Back Office reasons 
            (They got Top Syariah in Reksadana and Top Syariah (Reksadana) which 
            creates multiple Top Product (more than 5 top product)). */}
            {showSyariah && categoryType !== 'syariah' && (
              <div className={style['fund_management_type']}>
                <Text className={style['fund_management_type__title']}>
                  Tipe Pengelolaan Dana
                </Text>
                <div className={style['fund_management_type__chips']}>
                  {fundManagementOptions.map((item, index) => {
                    return (
                      <div
                        key={`management-type-${index}`}
                        data-id={item.id}
                        className={classNames(style['chip'], {
                          [style['chip__selected']]:
                            item.id === fundManagementSelected,
                        })}
                        onClick={handleClickFundManagementOption}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* Hide Redemption type if institution, because there's only instant redemption */}
            {!institution && categoryType !== 'instant' && (
              <div className={style['redemption_type']}>
                <Text className={style['redemption_type__title']}>
                  Tipe Pencairan
                </Text>
                <div className={style['redemption_type__chips']}>
                  {redemptionOptions.map((item, index) => {
                    return (
                      <div
                        key={`redemption-type-${index}`}
                        data-id={item.id}
                        className={classNames(style['chip'], {
                          [style['chip__selected']]:
                            item.id === redemptionSelected,
                        })}
                        onClick={handleClickRedemptionOption}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className={style['custodian_bank']}>
              <Text className={style['custodian_bank__title']}>
                Bank Penampung
              </Text>
              <div
                className={`${style['custodian_bank__button']} ${
                  style[bankNameSelected !== 'Semua Bank' ? 'selected' : '']
                }`}
              >
                <Button onClick={toggleChooseBankModal}>
                  <div
                    className={style['custodian_bank__button__left_content']}
                  >
                    <span>{bankNameSelected}</span>
                  </div>
                  <ChevronDown
                    color={
                      bankNameSelected !== 'Semua Bank'
                        ? 'var(--green-default)'
                        : 'var(--icon-default)'
                    }
                    width={14}
                  />
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={style['filter-reksa-footer']}
          primaryButton={
            <ButtonNew
              content='Reset'
              variant='secondary'
              colorScheme='green'
              disabled={disabledResetButton}
              onClick={handleClickResetButton}
              testId='filter-reksa-btn-tst'
              className={style['filter-btn-primary']}
            />
          }
          secondaryButton={
            <ButtonNew
              // eslint-disable-next-line no-template-curly-in-string
              content={`Tampilkan ${
                totalProductsFiltered === 0 && fetchingProductsFilter
                  ? ''
                  : totalProductsFiltered
              } Produk`}
              disabled={fetchingProductsFilter}
              onClick={handleSubmitFilter}
              variant='primary'
              testId='filter-reksa-btn-tst'
              className={style['filter-btn-secondary']}
            />
          }
        />
      </Modal>

      <ChooseCustodianBankModal
        bankId={bankSelected}
        visibility={visibilityChooseBankModal}
        isRoboPage={isRoboPage}
        categoryType={categoryType}
        im={im}
        onClose={toggleChooseBankModal}
        onClickBack={toggleChooseBankModal}
        onSubmit={handleSubmitChooseBankModal}
      />
    </>
  );
};

export default FilterReksaDanaModal;
