import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

const defaultState = {
  byId: {},
  allIds: [],
};

const [addCustodianEntry, addCustodianId] = [
  'ADD_CUSTODIAN_ENTRY',
  'ADD_CUSTODIAN_ID',
].map(createAction);

const custodian = createReducer(
  {
    [addCustodianEntry]: (state, payload) => {
      return update(state, {
        byId: {
          $set: {
            ...state.byId,
            [payload.ojkCode]: {
              ...payload,
            },
          },
        },
      });
    },
    [addCustodianId]: (state, payload) => {
      return update(state, {
        allIds: [...state.allIds, payload.ojkCode],
      });
    },
  },
  defaultState
);

export { addCustodianEntry, addCustodianId };

export default custodian;
