import list from './index';
import http from 'core/http';

/**
 * Call API to edit goal
 * @param {String} portfolioId - Portfolio category ID
 */
export function fetchGoalDetail(portfolioId) {
  return http.get(list.portfolioGoalDetail(portfolioId));
}

/**
 * Change portfolio name and icon
 * @param {String} portfolioId - Portfolio ID
 * @param {Object} portfolioData - Portfolio data changes
 */
export function postPortfolioChangeIcon(portfolioId, portfolioData) {
  return http.post(
    list.portfolioCategoryChangeIcon(portfolioId),
    portfolioData
  );
}

/**
 * GET data portfolio user
 *
 * @returns {import('./portfolio.type').ResponsePortfolio}
 */
export function fetchDataPortfolio(cancelToken = undefined) {
  return http.get(
    list.portfolio,
    {},
    {
      cancelToken,
    }
  );
}

/**
 * GET: Portfolio by category
 * @param {string | number} categoryId
 */
export function getPortfolioByCategory(categoryId, cancelToken = undefined) {
  return http.get(
    list.portfolioCategory(categoryId),
    {},
    {
      cancelToken,
    }
  );
}

/**
 * GET: Portfolio Category
 * data: {
 *  robo_type 0 | 1 [0 = Single, 1 = Robo]
 *  instrument_group 0 | 1 [0 = Reksadana, 1 = SBN]
 *  company_symbol string ex: 'RD01'
 * }
 */
export function getPortfolioCategory2(data) {
  return http.get(list.portfolioCategory2, data);
}
