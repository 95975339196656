import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { openErrorModal } from '../features/errors/errorModal.reducer';
import {
  fetchAllRobo,
  fetchScoringMap,
  postRoboScoreUpdateById,
  postRoboRecommendationUpdate,
  fetchRoboRecommendationById,
} from '../services/robo';
import { handleResponseGeneral } from 'utils/http-response';
import getSafely from '../utils/safely';
import { addMultipleReksadanaEntry } from './reksadana.reducer';
import queryClient from 'network/queryClient';
import { setRobo } from './user.reducer';

const categoryMap = {
  moneymarket: 'Pasar Uang',
  fixincome: 'Obligasi',
  equity: 'Saham',
};

const defaultState = {
  recommendations: {
    byId: {},
    byCategoryId: {},
    allIds: [],
  },
  isLoadingRoboRecommendation: true,
  scoringmap: [],
  scoringIsLoading: false,
  roboRecommendationEditLoading: false,
  loadingRoboRecommenById: false,
};

// Actions
const [
  roboRecommendationLoaded,
  roboScoringmapLoaded,
  roboUpdateScore,
  loadSingleRoboRecommendation,
  setRoboRecommendationEditLoading,
  setToggleScorringLoading,
  setLoadingRoboRecommendation,
  setLoadingRoboRecommendationById,
] = [
  'ROBO_RECOMMENDATION_LOADED',
  'ROBO_SCORINGMAP_LOADED',
  'ROBO_UPDATE_SCORE',
  'LOAD_SINGLE_ROBO_RECOMMENDATION',
  'SET_ROBO_RECOMMENDATION_EDIT_LOADING',
  'SET_TOGGLE_LOADING_SCORRING',
  'SET_LOADING_ROBO_RECOMMENDATION',
  'SET_LOADING_ROBO_RECOMMENDATION_BYID',
].map(createAction);

const robo = createReducer(
  {
    [roboRecommendationLoaded]: (state, payload) => {
      return update(state, {
        recommendations: {
          byId: {
            $set: payload.reduce(
              (currState, currItem) => ({
                ...currState,
                [getSafely(['roboid'], currItem)]: {
                  ...currItem,
                  result: {
                    ...currItem.result,
                    recommendation: getSafely(
                      ['result', 'sequence'],
                      currItem,
                      []
                    ).map((keyword) => {
                      const selectedItemIndex = currItem.result.recommendation[
                        keyword
                      ].items.findIndex(
                        (element) =>
                          element.symbol ===
                          currItem.result.recommendation[keyword].selected
                      );
                      const selectedItem =
                        currItem.result.recommendation[keyword].items[
                          selectedItemIndex
                        ];

                      return {
                        ...currItem.result.recommendation[keyword],
                        keyword,
                        type: categoryMap[keyword],
                        selectedProduct: selectedItem,
                        percentage: Number(
                          currItem.result.recommendation[keyword].percentage
                        ),
                      };
                    }),
                  },
                },
              }),
              {}
            ),
          },
          byCategoryId: {
            $set: payload.reduce(
              (currState, currItem) => ({
                ...currState,
                [getSafely(['categoryid'], currItem)]: getSafely(
                  ['roboid'],
                  currItem
                ),
              }),
              {}
            ),
          },
          allIds: {
            $set: payload.map((roboData) => getSafely(['roboid'], roboData)),
          },
        },
        isLoadingRoboRecommendation: { $set: false },
      });
    },
    [loadSingleRoboRecommendation]: (state, payload) => {
      const newState = {
        recommendations: {
          byId: {
            [getSafely(['roboid'], payload)]: {
              $set: {
                ...payload,
                result: {
                  ...payload.result,
                  recommendation: getSafely(
                    ['result', 'sequence'],
                    payload,
                    []
                  ).map((keyword) => {
                    const selectedItemIndex = payload.result.recommendation[
                      keyword
                    ].items.findIndex(
                      (element) =>
                        element.symbol ===
                        getSafely(
                          ['result', 'recommendation', keyword, 'selected'],
                          payload
                        )
                    );
                    const selectedItem = getSafely(
                      [
                        'result',
                        'recommendation',
                        keyword,
                        'items',
                        selectedItemIndex,
                      ],
                      payload
                    );
                    return {
                      ...payload.result.recommendation[keyword],
                      keyword,
                      type: categoryMap[keyword],
                      selectedProduct: selectedItem,
                      percentage: Number(
                        payload.result.recommendation[keyword].percentage
                      ),
                    };
                  }),
                },
              },
            },
          },
          byCategoryId: {
            [getSafely(['categoryid'], payload)]: {
              $set: getSafely(['roboid'], payload),
            },
          },
        },
      };

      if (
        state.recommendations.allIds.findIndex(
          (id) => id === getSafely(['roboid'], payload)
        ) < 0
      ) {
        newState.allIds = {
          $set: [
            ...getSafely(['recommendations', 'allIds'], state, []).filter(
              (item) => item !== getSafely(['roboid'], payload)
            ),
            getSafely(['roboid'], payload),
          ],
        };
      }
      return update(state, newState);
    },
    [roboScoringmapLoaded]: (state, payload) => {
      return update(state, {
        scoringmap: { $set: payload },
      });
    },
    [roboUpdateScore]: (state, payload) => {
      return update(state, {
        recommendations: {
          byId: (byId) =>
            update(byId || {}, {
              [payload.roboid]: (data) =>
                update(data || {}, {
                  result: (result) =>
                    update(result || {}, {
                      score: { $set: payload.score },
                    }),
                }),
            }),
        },
      });
    },
    [setRoboRecommendationEditLoading]: (state, payload) =>
      update(state, {
        roboRecommendationEditLoading: { $set: payload },
      }),
    [setToggleScorringLoading]: (state, payload) => {
      return update(state, {
        scoringIsLoading: { $set: payload },
      });
    },
    [setLoadingRoboRecommendation]: (state, payload) =>
      update(state, {
        isLoadingRoboRecommendation: { $set: payload },
      }),
    [setLoadingRoboRecommendationById]: (state, payload) => {
      return update(state, {
        loadingRoboRecommenById: { $set: payload },
      });
    },
  },
  defaultState
);

export {
  roboUpdateScore,
  loadSingleRoboRecommendation,
  setLoadingRoboRecommendation,
  setLoadingRoboRecommendationById,
};

// Selector

/**
 * Get all robo recommendations
 * @param {Object} state - Redux state tree
 */
export const getCachedRecommendations = (state) => {
  const roboIdList = getSafely(
    ['entities', 'robo', 'recommendations', 'allIds'],
    state
  );

  if (Array.isArray(roboIdList) && roboIdList.length > 0) {
    return getSafely(
      ['entities', 'robo', 'recommendations', 'allIds'],
      state
    ).map((id) =>
      getSafely(['entities', 'robo', 'recommendations', 'byId', id], state)
    );
  }

  return null;
};

/**
 * Get robo detail by roboid
 * @param {Object} state - Redux state tree
 */
export const getRoboDetail = (state, roboid) =>
  getSafely(
    ['entities', 'robo', 'recommendations', 'byId', roboid],
    state,
    null
  );

/**
 * Get robo detail by categoryId
 * @param {Object} state - Redux state tree
 */
export const getRoboDetailByCategoryId = (state, categoryid) => {
  const roboId = getSafely(
    ['entities', 'robo', 'recommendations', 'byCategoryId', categoryid],
    state,
    null
  );
  return getRoboDetail(state, roboId);
};

// Action creators

/**
 * Get robo scoring map
 */
export function getRoboScoringMap() {
  return async (dispatch, getState) => {
    const isLoading = getSafely(
      ['entities', 'robo', 'scoringIsLoading'],
      getState()
    );

    // prevent duplicate calling when on the fly
    if (isLoading) return;

    try {
      // set loading scoring map
      dispatch(setToggleScorringLoading(true));

      // fetching data through API
      const fetchScoringResponse = await fetchScoringMap();

      // set loading false
      dispatch(setToggleScorringLoading(false));

      // Save scoring map data to redux
      const parsedScoringMapResponse =
        handleResponseGeneral(fetchScoringResponse);
      const parsedScoringMap = getSafely(['data'], parsedScoringMapResponse);
      dispatch(roboScoringmapLoaded(parsedScoringMap));
    } catch (error) {
      // set loading false
      dispatch(setToggleScorringLoading(false));
      // Show error modal
      const errorModalObj = {
        type: error.raw ? error.raw.type : error.message,
        message: error.error || error.message,
      };
      dispatch(openErrorModal(errorModalObj));
    }
  };
}

/**
 * Get all robo recommendations
 */
export function getRoboRecommendations() {
  return async (dispatch) => {
    try {
      dispatch(setLoadingRoboRecommendation(true));
      const [fetchRecommendationResponse] = await Promise.all([
        fetchAllRobo(),
        dispatch(getRoboScoringMap()),
      ]);

      // Save recommendation to redux
      const parsedRecommendationResponse = handleResponseGeneral(
        fetchRecommendationResponse
      );
      const systemRecommendations = getSafely(
        ['data'],
        parsedRecommendationResponse
      );

      // TODO: Find efficient way to load recommendation item into mutual fund entities data
      if (
        Array.isArray(systemRecommendations) &&
        systemRecommendations.length > 0
      ) {
        systemRecommendations.forEach((recommendation) => {
          const equityRecommendations = getSafely(
            ['result', 'recommendation', 'equity', 'items'],
            recommendation
          );
          const fixIncomeRecommendations = getSafely(
            ['result', 'recommendation', 'fixincome', 'items'],
            recommendation
          );
          const moneyMarketRecommendations = getSafely(
            ['result', 'recommendation', 'moneymarket', 'items'],
            recommendation
          );
          dispatch(addMultipleReksadanaEntry(equityRecommendations));
          dispatch(addMultipleReksadanaEntry(fixIncomeRecommendations));
          dispatch(addMultipleReksadanaEntry(moneyMarketRecommendations));
        });
      }

      dispatch(roboRecommendationLoaded(systemRecommendations));
    } catch (error) {
      // Show error modal
      const errorModalObj = {
        type: error.raw ? error.raw.type : error.message,
        message: error.error || error.message,
      };
      dispatch(openErrorModal(errorModalObj));
    } finally {
      dispatch(setLoadingRoboRecommendation(false));
    }
  };
}

/**
 * Get robo detail data by roboid
 * @param {String} roboId
 */
export function getRoboDetailDataByRoboId(roboId) {
  return async (dispatch) => {
    try {
      dispatch(setLoadingRoboRecommendationById(true));
      const fetchRecommendationResponse = await fetchRoboRecommendationById(
        roboId
      );

      // Save recommendation to redux
      const parsedRecommendationResponse = handleResponseGeneral(
        fetchRecommendationResponse
      );

      const parsedData = getSafely(['data'], parsedRecommendationResponse);
      dispatch(loadSingleRoboRecommendation(parsedData));
    } catch (error) {
      // Show error modal
      const errorModalObj = {
        type: error.raw ? error.raw.type : error.message,
        message: error.error || error.message,
      };
      dispatch(openErrorModal(errorModalObj));
    } finally {
      dispatch(setLoadingRoboRecommendationById(false));
    }
  };
}

/**
 * Change robo score and portfolio allocation
 */
export function changeRoboScore(riskScore, roboid) {
  return (dispatch) => {
    dispatch(roboUpdateScore({ roboid, score: riskScore }));
  };
}

/**
 * Save robo risk score changes
 * @param {Object}          updateData        - Robo score update data
 * @param {String | Number} updateData.score  - Robo risk score
 * @param {String}          updateData.roboid - Robo ID
 * @param {String}          updateData.portfolioId - Portfolio ID
 * @param {Object}          history           - Browser history object
 */
export function saveRoboScoreUpdate(updateData, history) {
  return async (dispatch, getState) => {
    try {
      // This action also considered as possibility of altering robo recommendation
      dispatch(setRoboRecommendationEditLoading(true));

      const { score, roboid, portfolioId } = updateData;
      const fetchResponse = await postRoboScoreUpdateById(roboid, { score });

      /** Invalidate porto details */
      queryClient.invalidateQueries(['Portfolio Category Detail', portfolioId]);
      queryClient.invalidateQueries(['Portfolio Goal Detail', portfolioId]);

      const parsedResponse = handleResponseGeneral(fetchResponse);
      const responseData = getSafely(['data'], parsedResponse);

      const roboidInProfile = getSafely(
        ['entities', 'user', 'robo', 'roboid'],
        getState()
      );

      // if updated roboid is the same as roboid in profile, then update in entities user
      if (roboid === roboidInProfile) {
        // update data robo in entities.user
        dispatch(
          setRobo({
            robo: responseData,
          })
        );
      }

      // Consider editing done after getting robo detail data
      dispatch(setRoboRecommendationEditLoading(false));

      // Load new robo recommendation
      dispatch(getRoboDetailDataByRoboId(roboid));

      // Redirect user to home page, so they can see the change
      if (history) {
        history.push('/');
      }

      return responseData;
    } catch (error) {
      // Show error modal
      const errorModalObj = {
        type: error.raw ? error.raw.type : error.message,
        message: error?.response?.data?.message || error.message,
      };
      dispatch(openErrorModal(errorModalObj));
      return;
    }
  };
}

/**
 * Update robo recommendation item
 * @param {Object} updateData - Update robo recommendation item data
 * @param {String} roboid     - User's robo id
 * @param {String} type       - Mutual fund category being updated
 * @param {String} symbol     - Mutual fund code
 * @returns void
 */
export const updateRoboRecommendationItem = ({
  roboid,
  type = 'moneymarket',
  symbol = '',
}) => {
  return async (dispatch, getState) => {
    try {
      // Prevent double submit
      if (
        getSafely(
          ['entities', 'robo', 'roboRecommendationEditLoading'],
          getState(),
          false
        )
      ) {
        return;
      }

      // Handling when no symbol is given
      if (!symbol) {
        return;
      }

      dispatch(setRoboRecommendationEditLoading(true));

      const resp = await postRoboRecommendationUpdate(roboid, {
        [type]: symbol,
      });
      queryClient.invalidateQueries(['Robo Recommendation', roboid]);
      const respData = resp.data?.data;

      dispatch(loadSingleRoboRecommendation(respData));

      // Load new robo recommendation
      await dispatch(getRoboRecommendations());
      dispatch(setRoboRecommendationEditLoading(false));
    } catch (error) {
      dispatch(setRoboRecommendationEditLoading(false));
      // Show error modal
      const errorModalObj = {
        type: error.raw ? error.raw.type : error.message,
        message: error.error || error.message,
      };
      dispatch(openErrorModal(errorModalObj));
    }
  };
};

export default robo;
