import LZString from 'lz-string';

import { generalLogError } from 'utils/Analytics';
import {
  setStorageNative,
  getKeyStorageNative,
  removeKeyStorageNative,
} from './bridgeNativeHandler';

import { taskGetIsWebview } from 'utils/is-webview';
import { isJSON } from 'utils/validator';

const disableCompressString = () =>
  !!(window.isNotCompressString || window.document.isNotCompressString);

export async function setObject(key, value, stringify) {
  const hashValue = JSON.stringify(value);

  const _hashValue = disableCompressString()
    ? hashValue
    : LZString.compressToUTF16(hashValue);

  const isWebview = await taskGetIsWebview();
  if (isWebview) {
    return await setStorageNative(key, _hashValue);
  } else {
    localStorage.setItem(key, _hashValue);
  }
  return true;
}

export async function getObject(key, _key = '') {
  let hashValue = '';
  const isWebview = await taskGetIsWebview();
  if (isWebview) {
    hashValue = await getKeyStorageNative(key);
  } else {
    hashValue = localStorage.getItem(key);
  }
  if (!hashValue && typeof hashValue !== 'boolean') return null;
  try {
    const _hashValue = disableCompressString()
      ? hashValue
      : LZString.decompressFromUTF16(hashValue);

    if (typeof _hashValue === 'object' && _key) {
      return _hashValue[_key];
    }

    if (typeof _hashValue === 'object' || !isJSON(_hashValue)) {
      return _hashValue;
    }

    const __hashValue = JSON.parse(_hashValue);
    if (_key) return __hashValue[_key];
    return __hashValue;
  } catch (error) {
    const extraData = {
      file: 'Storage.js',
      function: 'getObject',
      key: key,
      hashValue: hashValue,
      error: error && error.toString && error.toString(),
    };
    generalLogError('JSON.parse catch', extraData);
    return null;
  }
}
export async function removeObject(key) {
  const isWebview = await taskGetIsWebview();
  if (isWebview) {
    await removeKeyStorageNative(key);
  } else {
    localStorage.removeItem(key);
  }
}
