import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { addMultipleReksadanaEntry } from './reksadana.reducer';
import { isEmptyObject } from './../utils';
import { goTo } from 'utils/History';
import {
  handleResponseRobo,
  handleResponseErrorCase,
} from 'utils/http-response';
import Storage from 'core/Storage';
import getSafely from './../utils/safely';
import { setLoadingRoboRecommendationById } from './robo.reducer';
import { fetchRoboRecommendationById } from 'services/robo';

const defaultState = {
  err_robo: '',
  err_roborec: '',
  robo: null,
  robo_temp: null,
};

const [requestroborec, failureroborec, successroborec] = [
  'LOAD_ROBOREC_REQUEST',
  'LOAD_ROBOREC_FAILURE',
  'LOAD_ROBOREC_SUCCESS',
].map(createAction);

const roborecReducer = {
  [requestroborec]: (state) => {
    return update(state, {
      err_roborec: { $set: '' },
    });
  },
  [failureroborec]: (state, payload) => {
    const { err } = payload;
    return update(state, {
      err_roborec: { $set: err },
    });
  },
  [successroborec]: (state, payload) => {
    const { robo } = payload;
    return update(state, {
      robo: { $set: robo },
    });
  },
};

const [updaterobo, requestrobo, failurerobo, successrobo] = [
  'UPDATE_ROBO_DATA',
  'REQUEST_USER_ROBO',
  'FAILURE_USER_ROBO',
  'SUCCESS_USER_ROBO',
].map(createAction);

const roboReducer = {
  [updaterobo]: (state, payload) => {
    const { robo: newRobo } = payload;
    return update(state, {
      robo: (currentRobo) =>
        update(currentRobo || {}, {
          result: (currentResult) =>
            update(currentResult || {}, {
              recommendation: (currentRecommendation) =>
                update(currentRecommendation || {}, {
                  $set:
                    newRobo.result && newRobo.result.recommendation
                      ? newRobo.result.recommendation
                      : currentRecommendation,
                }),
              score: (currentScore) =>
                update(currentScore || 0, {
                  $set:
                    newRobo.result && newRobo.result.score
                      ? newRobo.result.score
                      : currentScore,
                }),
              sequence: (currentSequence) =>
                update(currentSequence || [], {
                  $set:
                    newRobo.result && newRobo.result.sequence
                      ? newRobo.result.sequence
                      : currentSequence,
                }),
            }),
          roboid: (currentRoboId) =>
            update(currentRoboId || '', {
              $set: newRobo.roboid || currentRoboId,
            }),
        }),
    });
  },
  [requestrobo]: (state) => {
    return update(state, {
      err_robo: { $set: '' },
    });
  },
  [failurerobo]: (state, payload) => {
    const { err } = payload;
    return update(state, {
      err_robo: { $set: err },
    });
  },
  [successrobo]: (state, payload) => {
    const { robo } = payload;
    return update(state, {
      robo: { $set: robo },
    });
  },
};

export { successrobo };

const mainReducer = Object.assign(roboReducer, roborecReducer);
const reducer = createReducer(mainReducer, defaultState);

/**
 * Set robo data to redux for whole session
 * Save robo ID to storage
 * @param {Object} data Data which will be parsed
 * @param {Object} data.robo User's robo data
 * @returns {AppThunk<void>}
 */
export const setRobo = ({ robo }) => {
  return (dispatch) => {
    let roboid = '';
    if (robo && robo.roboid) roboid = robo.roboid;
    Storage.setObject('rb', roboid);
    return dispatch(updaterobo({ robo }));
  };
};

export const restoreRobo = () => {
  return async (dispatch) => {
    const idRobo = await Storage.getObject('rb');
    dispatch(requestrobo());
    if (!idRobo) return dispatch(failurerobo({ err: '' }));

    // make it loading
    dispatch(setLoadingRoboRecommendationById(true));

    const req = fetchRoboRecommendationById(idRobo).then((payload) => {
      dispatch(setLoadingRoboRecommendationById(false));

      if (isEmptyObject(payload)) {
        return dispatch(failurerobo({ err: 'Something is wrong' }));
      }

      const roboId = getSafely(['data', 'data', 'roboid'], payload, '');
      if (!!!roboId)
        setTimeout(
          () =>
            goTo({
              pathname: '/profiling',
              search: '?back=false',
            }),
          100
        );

      const dataResponse = handleResponseRobo(payload);

      dispatch(
        addMultipleReksadanaEntry(
          dataResponse.result.recommendation.equity.items
        )
      );
      dispatch(
        addMultipleReksadanaEntry(
          dataResponse.result.recommendation.fixincome.items
        )
      );
      dispatch(
        addMultipleReksadanaEntry(
          dataResponse.result.recommendation.moneymarket.items
        )
      );

      dispatch(successrobo({ robo: dataResponse }));

      // Register fetched mutual fund so other page can get preloaded data
    });
    handleResponseErrorCase(req, (errObj) => {
      dispatch(setLoadingRoboRecommendationById(false));
      const {
        err, // message from error case
      } = errObj;
      return dispatch(failurerobo({ err }));
    });
  };
};

export default reducer;
