import React, { ComponentType } from 'react';
import { useFlag } from './FeatureFlagProvider';
import { FlagKeys } from './flagKeys';

export type FeatureFlagType = {
  featureFlag: Record<string, boolean>;
};

/**
 * enum flag keys
 */
type FlagKeysHOC = FlagKeys | FlagKeys[];

/**
 * HOC to provide feature flag status selected by flag key
 * @param flagKey feature flag key(s)
 * @param WrappedComponent Wrapped component
 */
export const withFeatureFlag = (flagKey: FlagKeysHOC) => <P extends object>(
  WrappedComponent: ComponentType<React.PropsWithChildren<P & FeatureFlagType>>
) => {
  const featureFlag: FeatureFlagType['featureFlag'] = {};
  return (props: P) => {
    // assign to object directly if flag key is string
    if (typeof flagKey === 'string') {
      const flagIsActive: boolean = useFlag(flagKey);
      featureFlag[flagKey] = flagIsActive;
    }

    // mapping first before assign to object if key is array
    if (Array.isArray(flagKey)) {
      flagKey.map((key) => {
        const flagIsActive: boolean = useFlag(key);
        return (featureFlag[key] = flagIsActive);
      });
    }

    return <WrappedComponent {...props} featureFlag={featureFlag} />;
  };
};

export default withFeatureFlag;
