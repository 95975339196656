export const colorObject = {
  // Green colors
  green80: '#00764A',
  green60: '#008F59',
  green50: '#00AB6B',
  green30: '#8DD4B6',
  green20: '#C6EADB',
  green10: '#E6F7F0',

  // cobalts
  cobalt70: '#1B5B88',
  cobalt60: '#1E6699',
  cobalt50: '#2479B4',
  cobalt30: '#66ADE1',
  cobalt10: '#AAD2EE',

  // violets
  violet70: '#5B257E',
  violet60: '#712E9E',
  violet50: '#9343C8',
  violet30: '#BF90DF',
  violet10: '#DBC0ED',

  // olive
  olive70: '#64772C',
  olive60: '#7D9537',
  olive50: '#94B041',
  olive30: '#B8CD79',
  olive10: '#D0DEA6',

  // blue
  blue70: '#2378A9',
  blue60: '#2A90CB',
  blue50: '#5BAEDD',
  blue30: '#AAD5EE',
  blue10: '#DDEEF8',

  // peach
  peach70: '#DC2F18',
  peach60: '#E94A35',
  peach50: '#ED7161',
  peach30: '#F5ADA3',
  peach10: '#FBDEDA',

  // brown
  brown70: '#6F5234',
  brown60: '#8B6641',
  brown50: '#A97C50',
  brown30: '#D1B89E',
  brown10: '#F2EBE3',

  // neutrals
  black: '#333',
  white: '#fff',

  grey60: '#949494',
  grey50: '#B5B5B5',
  grey40: '#969696',
  grey30: '#858585',
  grey20: '#EDEDED',
  grey10: '#F9F9F9',

  // alert colors
  orange50: '#F5931B',
  red50: '#EE4A49',
};

export type BibitColor = keyof typeof colorObject;

// Green colors
export const green80 = '#00764A';
export const green60 = '#008F59';
export const green50 = '#00AB6B';
export const green30 = '#8DD4B6';
export const green20 = '#C6EADB';
export const green10 = '#E6F7F0';

// cobalts
export const cobalt70 = '#1B5B88';
export const cobalt60 = '#1E6699';
export const cobalt50 = '#2479B4';
export const cobalt30 = '#66ADE1';
export const cobalt10 = '#AAD2EE';

// violets
export const violet70 = '#5B257E';
export const violet60 = '#712E9E';
export const violet50 = '#9343C8';
export const violet30 = '#BF90DF';
export const violet10 = '#DBC0ED';

// olive
export const olive70 = '#64772C';
export const olive60 = '#7D9537';
export const olive50 = '#94B041';
export const olive30 = '#B8CD79';
export const olive10 = '#D0DEA6';

// blue
export const blue70 = '#2378A9';
export const blue60 = '#2A90CB';
export const blue50 = '#5BAEDD';
export const blue30 = '#AAD5EE';
export const blue10 = '#DDEEF8';

// peach
export const peach70 = '#DC2F18';
export const peach60 = '#E94A35';
export const peach50 = '#ED7161';
export const peach30 = '#F5ADA3';
export const peach10 = '#FBDEDA';

// brown
export const brown70 = '#6F5234';
export const brown60 = '#8B6641';
export const brown50 = '#A97C50';
export const brown30 = '#D1B89E';
export const brown10 = '#F2EBE3';

// neutrals
export const black = '#333';
export const white = '#fff';

export const grey60 = '#949494';
export const grey50 = '#B5B5B5';
export const grey30 = '#858585';
export const grey20 = '#EDEDED';
export const grey10 = '#F9F9F9';

// alert colors
export const orange50 = '#F5931B';
export const red50 = '#EE4A49';

export default colorObject;
