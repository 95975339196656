import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useQuery, useQueryClient } from 'react-query';

const AutoLogoutLazy = React.lazy(() => import('./AutoLogout'));

const AutoLogout = () => {
  const rqClient = useQueryClient();

  const { data: userKickOut } = useQuery('user_kickedout', () => {
    return rqClient.getQueryData('user_kickedout') || (false as boolean);
  });

  return (
    <LazyModal component={AutoLogoutLazy} visible={userKickOut === true} />
  );
};

export default AutoLogout;
