import {
  ProductItem,
  BestType,
} from 'features/home/components/Modals/onboarding-modal-flow/types';
import endpoints from './endPoints';
import { AxiosResponse } from 'axios';
import { BibitApiSuccessResponse } from 'services';
import http from 'core/http';

type ProductBestCategoryQueryParamType = {
  category: BestType;
  sort_by?: 'instant' | null;
  syariah?: 0 | 1;
};

/**
 * GET best product by category
 * @param type type of category
 */
export function getBestProductByCategory({
  category,
  sort_by,
  syariah,
}: ProductBestCategoryQueryParamType): Promise<
  AxiosResponse<BibitApiSuccessResponse<ProductItem[]>>
> {
  let queryParams: ProductBestCategoryQueryParamType = {
    category,
    ...(typeof syariah === 'undefined' ? {} : { syariah }),
  };

  if (sort_by) {
    queryParams.sort_by = sort_by;
  }
  return http.get(endpoints.productBestCategory, queryParams);
}
