import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useAppModalGlobalStateContext } from '../AppModalGlobalContext';
import './ContinueRegistrationModal.css';

const ContinueRegistrationModal = React.lazy(
  () => import('./ContinueRegistrationModal')
);

const LazyContinueRegistrationModal = (props: any) => {
  const { continueRegisModalOpen } = useAppModalGlobalStateContext();

  return (
    <LazyModal
      component={ContinueRegistrationModal}
      visible={continueRegisModalOpen}
      {...props}
    />
  );
};

export default LazyContinueRegistrationModal;
