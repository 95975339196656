import { taskGetIsWebview } from 'utils/is-webview';
import {
  setStorageNative,
  getKeyStorageNative,
  removeKeyStorageNative,
} from './bridgeNativeHandler';

export async function set(key, value) {
  const isWebview = await taskGetIsWebview();
  if (isWebview) {
    return await setStorageNative(key, value);
  } else {
    localStorage.setItem(key, value);
  }
  return true;
}

export async function get(key) {
  const isWebview = await taskGetIsWebview();
  let value = '';
  if (isWebview) {
    value = await getKeyStorageNative(key);
  } else {
    value = localStorage.getItem(key);
  }
  return value;
}

export async function remove(key) {
  const isWebview = await taskGetIsWebview();
  if (isWebview) {
    await removeKeyStorageNative(key);
  } else {
    localStorage.removeItem(key);
  }
  return key;
}

export async function reset() {
  localStorage.clear();
  return true;
}
