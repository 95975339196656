import {
  checkTokenExpiration,
  handleRefreshTokenAxios,
} from 'core/refresh-token';
import { AxiosRequestConfig } from 'axios';
import { sendToDebugger } from 'devtools/components/tools/NativeDebugger/NativeDebuggerProvider';

const requestInterceptors = async (config: AxiosRequestConfig<any>) => {
  // current actoken
  const acToken = (config.headers?.common as any)?.Authorization;

  // check token expiration every request come out
  const tokenExp = checkTokenExpiration(acToken);

  // if token expired and url not `/auth/token`
  if (tokenExp && config.url !== '/auth/token') {
    // then do refresh token
    await handleRefreshTokenAxios(config);
  }

  /** Set networkID: [GET] /portfolio */
  const url = config.url || '';
  const method = config.method || '';
  const networkID = `[${method.toUpperCase()}] ${url}`;

  /** Set network request to native debugger */
  if (config) {
    sendToDebugger({
      idevent: networkID,
      status: 'waiting',
      response: undefined,
      request: config,
      type: 'network',
    });
  }
};

export default requestInterceptors;
