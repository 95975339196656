export const showSortPeriod = (sortBy) => {
  if (sortBy === '2' || sortBy === '3' || sortBy === '6') {
    return false;
  }
  return true;
};

export const getSortingPeriod = (sortPeriod) => {
  switch (sortPeriod) {
    case '1y':
      return '1 Year';
    case '3y':
      return '3 Year';
    case '5y':
      return '5 Year';
    case '3m':
      return '3 Month';
    case '1m':
      return '1 Month';
    case '1d':
      return '1 Day';
    case 'ytd':
      return 'Year to Date';
    default:
      break;
  }
};

export const getSortingChoosen = (sort) => {
  switch (sort) {
    case '2':
      return 'Total AUM';
    case '5':
      return 'Return';
    case '3':
      return 'Expense Ratio';
    case '4':
      return 'Max Drawdown';
    case '6':
      return 'Minimum Pembelian';
    default:
      break;
  }
};
