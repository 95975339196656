/** Endpoints api for feature E-Statement */
const endpoints = {
  taxRequest: '/user/report/tax',
  transRequest: '/user/report/trans',
  taxYearData: '/tools/list/tax-period',
  ownershipSBN: '/sbn/proof-of-ownership',
  proofOwnershipSBN: (symbol: string) => `/sbn/proof-of-ownership/${symbol}`,
};

export default endpoints;
