import React, { useState } from 'react';

import Analytics from 'utils/Analytics';
import withoutTabBar from 'withoutTabBar';
import PinForm from 'features/common/PinForm';
import { useAuth, useProfileData, useToggle } from 'hooks';
import useRestoreInitialUser from 'hooks/useRestoreInitialUser';

import { SetPinBody } from 'features/register-account/pages/SetupPin/types';
import { useSetupPin } from 'features/register-account/pages/SetupPin/networks';

import SetupPinInfoModal from './components/modals/SetupPinInfoModal/SetupPinInfoModal';

export interface Props {
  /**
   * Loading request verify pin
   */
  loadingVerifyPin: boolean;

  /**
   * Error request verify pin
   */
  errorVerifyPin?: string;

  /**
   * Request verify pin challenge after successful setup pin
   */
  onReqVerifyPinAfterSubmit: (pin: string) => Promise<void>;
}

/**
 * PinSetupPage will paired with `PinChallengePage` for handling this condition like this
 * - user institution `registered as institution account only` need to access `admin management` page but doesn't have pin
 *
 * After setup pin successful, it will be request verify pin then render children component
 */
const PinSetupPage: React.FC<Props> = ({
  loadingVerifyPin,
  errorVerifyPin,
  onReqVerifyPinAfterSubmit,
}) => {
  const { isLogin } = useAuth();
  const { isFetching: loadingProfile, refetch: refetchProfile } =
    useProfileData(isLogin);
  const { checkUserLoggedInStatus } = useRestoreInitialUser();

  const [step, setStep] = useState(0);

  const [pin, setPin] = useState('');

  const [pinConfirmError, setPinConfirmError] = useState('');

  const { active: visibilityInfoModal, toggleActive: toggleInfoModal } =
    useToggle(true);

  const handleNextStep = () => setStep(step + 1);

  const handlePrevStep = () => {
    setPinConfirmError('');
    setStep(step - 1);
  };

  const handleClickSupport = () => {
    Analytics.logEventAction({
      eventName: 'pin_setup_action',
      parameter: {
        action: 'help',
        context: 'pin_setup.create_new_pin',
        trigger: 'click',
      },
    });
  };

  const handleTapPin = () => {
    setPinConfirmError('');
  };

  const handleSubmitPin = (pin: string) => {
    Analytics.logEventAction({
      eventName: 'pin_setup_action',
      parameter: {
        action: 'num_pad',
        context: 'pin_setup.create_new_pin',
        trigger: 'click',
        data: {
          pin: 'setup',
        },
      },
    });
    setPin(pin);
    handleNextStep();
  };

  const { mutateAsync: setupPin, isLoading: setupPinLoading } = useSetupPin();

  const handleSubmitPinConfirm = async (pinConfirm: string) => {
    Analytics.logEventAction({
      eventName: 'pin_setup_action',
      parameter: {
        action: 'num_pad',
        context: 'pin_setup.create_new_pin',
        trigger: 'click',
        data: {
          pin: 'confirmation',
        },
      },
    });

    /**
     * checkl if newpin and newpin confirm doesn't match
     */
    if (pinConfirm !== pin) {
      return setPinConfirmError('PIN Konfirmasi Tidak Sama');
    }

    const body: SetPinBody = { pin: pin, pin_confirm: pinConfirm };

    try {
      // request setup pin
      await setupPin(body);

      // request pin challenge after setup pin success
      await onReqVerifyPinAfterSubmit(pin);

      // refetch profile for updating cache
      await refetchProfile().then((res) => {
        checkUserLoggedInStatus(res?.data?.data?.data);
      });
    } catch (error) {
      const err: any = error;
      const errorMessage = err?.response?.data?.message;
      setPinConfirmError(errorMessage);
    }
  };

  return (
    <>
      {step === 0 && (
        <>
          <PinForm
            title={'Setup PIN'}
            headerText={'Buat PIN Kamu'}
            forceRenderPinForm
            showSupport
            onSubmitPin={handleSubmitPin}
            onClickSupport={handleClickSupport}
            max={6}
          />
          <SetupPinInfoModal
            visibility={visibilityInfoModal}
            toggleModal={toggleInfoModal}
          />
        </>
      )}
      {step === 1 && (
        <PinForm
          title={'Setup PIN'}
          headerText={'Konfirmasi PIN'}
          forceRenderPinForm
          showSupport
          onTapPin={handleTapPin}
          onSubmitPin={handleSubmitPinConfirm}
          onClickSupport={handleClickSupport}
          loading={setupPinLoading || loadingVerifyPin || loadingProfile}
          renderOnBack
          onBack={handlePrevStep}
          errorMessage={pinConfirmError || errorVerifyPin}
          max={6}
        />
      )}
    </>
  );
};

export default withoutTabBar(PinSetupPage);
