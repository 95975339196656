/**
 * Parse value's object to string
 */
const parseObjectToString = (value: any) => {
  const newValue: any = {};

  Object.keys(value).forEach((key) => {
    if (typeof value[key] === 'object') {
      newValue[key] = JSON.stringify(value[key], null, 2);
      return;
    }

    newValue[key] = value[key];
  });

  return newValue;
};

const dbConnection = (
  type: 'add' | 'getAll' | 'get' | 'update' | 'clear',
  value?: Object | string
) => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('InjectApi', 1);

    request.onerror = (event) => {
      console.error(event);
      reject('request error');
    };

    request.onupgradeneeded = () => {
      const db = request.result;
      const store = db.createObjectStore('apiPaths', {
        keyPath: 'id',
      });
      store.createIndex('requestUrl', ['requestUrl'], { unique: true });
    };

    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction('apiPaths', 'readwrite');
      const store = transaction.objectStore('apiPaths');

      if (type === 'add') {
        store.put(parseObjectToString(value));
        resolve(value);
      }

      if (type === 'getAll') {
        const idQuery = store.getAll();

        idQuery.onerror = () => {
          reject('transaction error');
        };

        idQuery.onsuccess = () => {
          db.close();
          resolve(idQuery.result);
        };
      }

      if (type === 'update') {
        const update = store.put(parseObjectToString(value));

        update.onsuccess = () => {
          resolve(update.result);
        };
      }

      if (type === 'get') {
        const requestUrlQuery = store.get(value as string);
        requestUrlQuery.onerror = () => {
          reject('transaction error');
        };

        requestUrlQuery.onsuccess = () => {
          db.close();
          resolve(requestUrlQuery.result);
        };
      }

      if (type === 'clear') {
        const value = store.clear();
        resolve(value);
      }

      transaction.onerror = (error) => {
        console.error({ error });
        reject('transaction error');
      };

      transaction.oncomplete = () => {
        db.close();
      };
    };
  });
};

const indexedDB = {
  add: async (value: Object) => {
    return await dbConnection('add', value);
  },
  getAll: async () => {
    return await dbConnection('getAll');
  },
  get: async (query: string) => {
    return await dbConnection('get', query);
  },
  update: async (value: Object) => {
    return await dbConnection('update', value);
  },
  clear: async () => {
    return await dbConnection('clear');
  },
};

export { indexedDB };
