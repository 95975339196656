/**
 * Login Flow Reducer
 *
 * This file illustrates all states needed for login flow in Bibit.
 * These states will be shared across all login flow.
 */

import { createAction, createReducer } from 'redux-act';
import update from 'immutability-helper';

import Analytics from 'utils/Analytics';
import { AppThunk } from 'utils/redux';
import { taskGetIsWebview } from 'utils/is-webview';
import { taskGetPlayerId } from 'utils/is-webview';
import Storage from 'core/Storage';
import {
  postLoginNumber as postLoginPhoneNumber,
  checkTrustedDevice,
  verifyPinLogin,
} from 'features/login/networks/services';
import {
  IUserAccess,
  IAuthLoginData,
  IVerifyPinPayload,
  IVerifyPinResponse,
  ITrustedDevicePayload,
  ITrustedDeviceResponse,
} from 'services/auth.type';
import { apiInstance } from 'core/http';
import { getDeviceId, getDeviceInfo } from 'core/DeviceInfo/deviceInfo';
import {
  PhoneInputState,
  OTPVerificationState,
  PinVerificationState,
  UserDeactiveState,
  RequestOTPSuccessfulResponse,
} from './types';

type TDefaultState = PhoneInputState &
  OTPVerificationState &
  PinVerificationState &
  UserDeactiveState;

const defaultState: TDefaultState = {
  selectedOTPMethod: '',
  phoneNumber: '',
  nationalNumber: '',
  countryCallingCode: '',
  otp: '',
  dummyOtp: '',
  resend: true,
  nextPossibleResendTime: 0,
  missedCallCallerNumber: '',
  smartLoginModalVisible: false,
  deactiveModalVisible: false,
};

const login = createReducer<typeof defaultState>({}, defaultState);

/**
 * Action fired when typing phone number on login page
 */
export const typePhoneNumber = createAction<PhoneInputState>(
  'LOGIN/TYPE_PHONE_NUMBER'
);

/**
 * Action fired when user select OTP sending method
 */
export const selectOTPMethod = createAction<string>('LOGIN/SELECT_OTP_METHOD');

/**
 * Action fired when user is typing OTP
 */
export const typeOTP = createAction<string>('LOGIN/TYPE_OTP');

/**
 * Action fired when user has received OTP response from our system
 */
export const receiveRequestOTPResponse = createAction<IAuthLoginData>(
  'LOGIN/RECEIVE_REQUEST_OTP_RESPONSE'
);

/**
 * Action fired to check trusted device info from server
 * (Login with PIN feature)
 */
export const checkLatestLogin = createAction('LOGIN/CHECK_LATEST_LOGIN');

/**
 * Action fired to show smart login activation modal
 * (Login with PIN feature)
 */
export const setShowSmartLoginModal = createAction<boolean>(
  'LOGIN/SHOW_SMART_LOGIN_MODAL'
);

/**
 * Action fired to show smart login activation modal
 * (Login with PIN feature)
 */
export const setUserDeactiveModal = createAction<boolean>(
  'LOGIN/SHOW_USER_DEACTIVATE_MODAL'
);
/**
 * Save typed phone number, parsed from login page input
 */
login.on(typePhoneNumber, (state, payload: PhoneInputState) => {
  return update(state, {
    phoneNumber: { $set: payload.phoneNumber },
    nationalNumber: { $set: payload.nationalNumber },
    countryCallingCode: { $set: payload.countryCallingCode },
  });
});

/**
 * Save selected OTP method, for determining info shown on verification page
 */
login.on(selectOTPMethod, (state, payload) => {
  return update(state, {
    selectedOTPMethod: { $set: payload },
  });
});

/**
 * This saves what users type on keypad as OTP
 */
login.on(typeOTP, (state, payload: string) => {
  return update(state, {
    otp: { $set: payload },
  });
});

/**
 * Saves caller number for missed call and next possible resend timestamp
 */
login.on(receiveRequestOTPResponse, (state, payload: IAuthLoginData) => {
  return update(state, {
    missedCallCallerNumber: { $set: payload?.caller || '' },
    dummyOtp: { $set: payload?.otp || '' },
    resend: { $set: payload?.resend || true },
    nextPossibleResendTime: { $set: payload?.resendtime },
  });
});

/** Show smart login modal */
login.on(setShowSmartLoginModal, (state, payload: boolean) => {
  return update(state, {
    smartLoginModalVisible: { $set: payload },
  });
});

/** Show user deactive modal */
login.on(setUserDeactiveModal, (state, payload: boolean) => {
  return update(state, {
    deactiveModalVisible: { $set: payload },
  });
});

/**
 * Request API system to send OTP to user
 */
export const requestOTP =
  (): AppThunk<Promise<RequestOTPSuccessfulResponse>> =>
  async (dispatch, getState) => {
    // Reset OTP inputs before requesting new OTP
    dispatch(typeOTP(''));
    const countryCallingCode = getState().login.countryCallingCode;
    const nationalNumber = getState().login.nationalNumber;
    const selectedOTPMethod = getState().login.selectedOTPMethod;

    try {
      const data = {
        code: countryCallingCode,
        phone: nationalNumber,
        via: selectedOTPMethod,
      };

      const response = await postLoginPhoneNumber(data);

      // Save important datas from response
      dispatch(receiveRequestOTPResponse(response?.data?.data));

      return response;
    } catch (error) {
      throw error;
    }
  };

/**
 *  Request latest login info from API (Login with PIN feature)
 */
export const verifyLatestLogin =
  (): AppThunk<Promise<ITrustedDeviceResponse | void>> =>
  async (dispatch, getState) => {
    const countryCode = getState().login.countryCallingCode as string;
    const phoneNumber = getState().login.nationalNumber as string;

    const deviceInfo = await getDeviceInfo();

    /** api need id that changes every reinstall app, and will be provided by `INSTALLATION_ID` */
    const device_id = getDeviceId(deviceInfo);

    const payload: ITrustedDevicePayload = {
      code: countryCode,
      phone: phoneNumber,
      device_id,
    };

    // Get user's onesignal player id
    const isWebview = await taskGetIsWebview();

    if (isWebview) {
      let playerid = await taskGetPlayerId();
      if (playerid) {
        payload['notifid'] = playerid;
      }
    }

    // Send action to redux (just for debugging purpose)
    dispatch(checkLatestLogin());

    try {
      const response = await checkTrustedDevice(payload).then(
        (res) => res?.data?.data
      );

      return response;
    } catch (error) {
      throw error;
    }
  };

/**
 *  Authenticate user using PIN (Login with PIN feature)
 */
export const loginWithPin =
  (payload: IVerifyPinPayload): AppThunk<Promise<IVerifyPinResponse | void>> =>
  async (dispatch, getState) => {
    try {
      const response = await verifyPinLogin(payload).then(
        (res) => res.data?.data
      );

      const { user_access } = response;

      await dispatch(setUserLogin(user_access));

      return response;
    } catch (error) {
      throw error;
    }
  };

/**
 *  Set user login handler (Login with PIN feature)
 */
export const setUserLogin =
  (user_access: IUserAccess): AppThunk<Promise<void>> =>
  async () => {
    const { token, roboid } = user_access;

    // Set has logged in
    await Storage.setObject('hasLoggedIn', true);

    // Set manually
    await Storage.removeObject('hasManuallyLogout');

    // set token header default
    apiInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token.access_token}`;

    // Set access token to localstorage
    await Storage.setTokenToLocalStorage(token);

    // Set robo id to localstorage
    if (roboid) {
      await Storage.setObject('rb', roboid);
    }

    /** Send Appsflyer tracker when submit otp success */
    Analytics.logEventThirdParty({
      eventName: 'af_login',
    });
  };

export default login;
