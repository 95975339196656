import LazyModal from 'features/common/LazyModal';
import React from 'react';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

const SafestProductModalLazy = React.lazy(() => import('./SafestProductModal'));

const SafestProductModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'safest_product';

  return <LazyModal component={SafestProductModalLazy} visible={visible} />;
};

export default SafestProductModal;
