import React, { useState, useContext } from 'react';

interface ExistingProductModalInterface {
  visible: boolean;
  portoId: string;
  isShared?: boolean;
  isBibitDarurat: boolean;
  stockBibitBenefit?: boolean;
  flow?: string;
  isShowExploreModal: boolean;
  showModal: (params: showModalParams) => void;
  closeModal: () => void;
  setIsShowExploreModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModalWithExistingData: () => void;
}

type showModalParams = {
  portoId: string;
  isBibitDarurat: boolean;
  isShared?: boolean;
  stockBibitBenefit?: boolean;
  flow?: string;
};

const ExistingProductModalContext = React.createContext<
  ExistingProductModalInterface | undefined
>(undefined);

const ExistingProductModalProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [portoId, setPortoId] = useState<string>('');
  const [isShared, setIsShared] = useState<boolean | undefined>(false);
  const [isBibitDarurat, setIsBibitDarurat] = useState<boolean>(false);
  const [stockBibitBenefit, setStockBibitBenefit] = useState<
    boolean | undefined
  >(false);
  const [flow, setFlow] = useState<string | undefined>('');

  const [isShowExploreModal, setIsShowExploreModal] = useState<boolean>(false);

  const showModal = ({
    portoId,
    isBibitDarurat,
    isShared,
    stockBibitBenefit,
    flow,
  }: showModalParams) => {
    setPortoId(portoId);

    setIsShared(isShared);

    setIsBibitDarurat(isBibitDarurat);

    setStockBibitBenefit(stockBibitBenefit);

    setFlow(flow);

    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    setIsShowExploreModal(false);
  };

  const showModalWithExistingData = () => {
    showModal({
      portoId,
      isShared,
      isBibitDarurat,
      stockBibitBenefit,
      flow,
    });
  };

  const value = {
    visible,
    portoId,
    isShared,
    isBibitDarurat,
    stockBibitBenefit,
    flow,
    isShowExploreModal,
    showModal,
    closeModal,
    setIsShowExploreModal,
    showModalWithExistingData,
  };

  return (
    <ExistingProductModalContext.Provider value={value}>
      {children}
    </ExistingProductModalContext.Provider>
  );
};

export const useExistingProductModal = () => {
  const existingProductModal = useContext(ExistingProductModalContext);

  if (existingProductModal === undefined) {
    throw new Error(
      'useExistingProductModal must be used inside ExistingProductModalProvider'
    );
  }
  return existingProductModal;
};

export default ExistingProductModalProvider;
