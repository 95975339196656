import * as React from 'react';
import instantLight from './instant-light.svg';
import instantDark from './instant-dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: instantLight,
  dark: instantDark,
  default: instantLight,
};

export const InstantLogoOriginal: React.FC<
  React.HTMLProps<HTMLPictureElement>
> = (props) => {
  return (
    <ThemedImage imageMap={themeImageMapping} alt={'instant-logo'} {...props} />
  );
};

export default InstantLogoOriginal;
