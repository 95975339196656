import * as Sentry from '@sentry/browser';
import { isNumber } from 'utils/number';
import { isJSON } from 'utils/validator';

export function getNativeVariable(key: string) {
  // For Android. Check for injected object from native, if available then
  // return the value
  if (window.NativeInjection && key) {
    const stringifiedVars = window?.NativeInjection?.injectVariable();
    try {
      const injectedVars = JSON.parse(stringifiedVars);
      return injectedVars[key];
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag('domain', 'windowVariableInjection');
        Sentry.captureException(error);
      });
    }
  } else {
    return window[key];
  }
}

/**
 * Get data from window.PARSER_VERSION ||
 * if window.VERSION fail will return number 1
 */
export function getParserVersion() {
  const v =
    getNativeVariable('ParserVersion') ||
    getNativeVariable('document.ParserVersion') ||
    getNativeVariable('PARSER_VERSION') ||
    getNativeVariable('document.PARSER_VERSION');

  return isNumber(v) ? Number(v) : 1;
}

interface InjectionVariable {
  [key: string]: any;
}

/**
 * Injection variable to `window.*` into browser
 *
 * This function only called in Android WebView through `window.NativeInjection`
 */
export const injectingWindowBrowser = (objStr: string) => {
  if (!isJSON(objStr)) return;

  const injectionVariable: InjectionVariable = JSON.parse(objStr);

  // attach to window.*
  // we need to specialize value from `PARSER_VERSION` just make it number type
  Object.keys(injectionVariable).forEach((key: string) => {
    window[key] =
      key === 'PARSER_VERSION'
        ? Number(injectionVariable[key])
        : injectionVariable[key];
  });
};
