import { getDeviceInfo } from 'core/DeviceInfo/deviceInfo';
import { useQuery } from 'react-query';

/**
 * Hooks to get device info and installed Bibit App info
 */
const useDeviceInfo = () => {
  return useQuery(['getDeviceInfo'], getDeviceInfo);
};

export default useDeviceInfo;
