import { generalLogError, generalLogInfo } from 'utils/Analytics';
import { getInstance } from '../CallbackMapper';
import { ERROR_TYPE, RETRY_NATIVEPORT, TIMEOUT_NATIVEPORT } from '../constant';

import type { Mapper } from '../CallbackMapper';
import type {
  CallbackType,
  LogMessageType,
  DataParamPostDataType,
  PrePostDataPayloadV2Type,
  PostDataPayloadV2Type,
} from '../types';
import { requestRefreshToken } from 'core/refresh-token';
import { sendToDebugger } from 'devtools/components/tools/NativeDebugger/NativeDebuggerProvider';

const callbackMapper: Mapper = getInstance();

const ANDROID_NATIVE_OBJECT = 'NativeInjection';
const IOS_NATIVE_OBJECT = 'postMessageListener';

/**
 * Reformat prepostdata.data to become consistent object type
 *
 * @param {PrePostDataPayloadV2Type} prepostdata unformatted data
 * @returns {PostDataPayloadV2Type} formatted data
 */
function parsePayloadData(
  prepostdata: PrePostDataPayloadV2Type
): PostDataPayloadV2Type {
  let output: PostDataPayloadV2Type = {
    id: '',
    data: {},
    fn: '',
  };

  if (typeof prepostdata.data === 'undefined') {
    output = {
      ...prepostdata,
      data: {},
    };
  } else if (typeof prepostdata.data !== 'object') {
    output = {
      ...prepostdata,
      data: {
        value: prepostdata.data,
      },
    };
  } else {
    output = {
      ...prepostdata,
      data: prepostdata.data,
    };
  }

  return output;
}

/**
 * Validate if string is valid json
 *
 * @param {string} jsonString string to validate
 */
function tryParseJSON(jsonString: string) {
  try {
    let o: object = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (err) {
    const extraData: LogMessageType = {
      file: 'Sender.v3.tsx',
      function: 'tryParseJSON',
      version: 'v3',
      data: jsonString,
      error: err && err.toString && err.toString(),
    };

    generalLogInfo('JSON.parse catch', extraData);
  }

  return false;
}

/**
 * Callback executed when certain condition is ready,
 * otherwise it will retry until it's ready (every native type should have unique object injection)
 * if it's web, it shouldn't reach this code.
 *
 * @param {function} cb function to be called if it's ready
 */
function whenNativePostMessageReady(cb: Function) {
  const isReady = [
    !!window?.webkit?.messageHandlers[IOS_NATIVE_OBJECT],
    !!window[ANDROID_NATIVE_OBJECT],
  ].some((i) => i);

  if (isReady) {
    cb();
  } else {
    setTimeout(function () {
      whenNativePostMessageReady(cb);
    }, RETRY_NATIVEPORT || 300);
  }
}

/**
 * Wrapper bridge for 'postMessage' v1, 'window.parent' or 'window.ReactNativeWebview'
 *
 * @param {string} datastring string to send
 * @param {any} opt second optional argument in 'postMessage' spec
 */
export function basePostMessage(datastring: string, opt?: any): boolean {
  if (!tryParseJSON(datastring)) {
    throw new Error('JSON string is not valid');
  }

  try {
    whenNativePostMessageReady(() => {
      try {
        if (window?.webkit?.messageHandlers) {
          window?.webkit?.messageHandlers[IOS_NATIVE_OBJECT]?.postMessage(
            datastring
          );
        } else if (!!window[ANDROID_NATIVE_OBJECT]) {
          window[ANDROID_NATIVE_OBJECT]?.sendFromWeb(datastring);
        } else {
          throw new Error('No native bridge defined');
        }
      } catch (error) {
        generalLogError('Catch Error when sending PostMessage', {
          desc: 'Try to sending postmessage but we got an error',
          data_postmessage: datastring,
          error_detail: error,
        });
      }
    });
  } catch (error) {
    generalLogError('Catch Error whenNativePostMessageReady', {
      desc: 'Trying to Ping!! wheter postMessage is ready or not before we sending actual postMessage',
      data_postmessage: datastring,
      error_detail: error,
    });
  }

  return true;
}

/**
 * Common interface to interact with native
 *
 * @param {string} id task id (random string)
 * @param {DataParamPostDataType} data formatted data object, with key 'data' to send and key 'fn' which function to execute
 * @param {CallbackType} cb (optional) callback executed after it success or error
 */
export async function postData(
  id: string,
  data: DataParamPostDataType,
  cb?: CallbackType
) {
  // @ Check expiration token
  if (data?.check_expiration) {
    await requestRefreshToken(id, data, cb);
  }

  let callbackTimeout: number =
    typeof data.timeout !== 'undefined' ? data.timeout : TIMEOUT_NATIVEPORT;

  const predatapayload: PrePostDataPayloadV2Type = {
    id: id,
    ...data,
  };

  const datapayload: PostDataPayloadV2Type = parsePayloadData(predatapayload);

  const datastring: string = JSON.stringify(datapayload);

  // Send data to debugger
  sendToDebugger({
    idevent: id,
    status: 'waiting',
    response: undefined,
    request: predatapayload,
    type: 'event',
  });

  if (typeof cb === 'function') {
    let task: ReturnType<typeof setTimeout> | undefined;

    // Only use 'callbackTimeout' if value is not less than equal 0
    if (callbackTimeout > 0)
      task = setTimeout(
        () => {
          const error = new Error(`${ERROR_TYPE.nativeTimeout} : ${id}`);

          cb(error);

          // Send data to debugger
          sendToDebugger({
            idevent: id,
            status: 'timeout',
            response: undefined,
            request: predatapayload,
            type: 'event',
          });

          callbackMapper.remove(id);
        },

        callbackTimeout
      );

    callbackMapper.set(id, (err?: any, data?: any) => {
      if (typeof task !== 'undefined') clearTimeout(task);

      cb(err, data);

      // Send data to debugger
      sendToDebugger({
        idevent: id,
        status: 'success',
        response: data,
        request: predatapayload,
        type: 'event',
      });

      callbackMapper.remove(id);
    }); // enqueue callback
  }

  //react native accepts strings only
  basePostMessage(datastring, '*');
}

/**
 * Common interface to interact with native
 *
 * @param {string} id task id (random string)
 * @param {DataParamPostDataType} data formatted data object, with key 'data' to send and key 'fn' which function to execute
 * @returns {Promise} promise resolved|rejected after it success or error
 */
export function postDataPromise(
  id: string,
  data: DataParamPostDataType
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    postData(id, data, (err: any, data: any) => {
      if (err) return reject(err);

      return resolve(data);
    });
  });
}
