import LazyModal from 'features/common/LazyModal';
import React, { lazy } from 'react';
import { DeactivateProps } from './BankJagoDeactivateModal';

const Modal = lazy(() => import('./BankJagoDeactivateModal'));

const BankJagoDeactivateModal = (props: DeactivateProps) => {
  return <LazyModal component={Modal} visible={props.showModal} {...props} />;
};

export default BankJagoDeactivateModal;
