import * as React from 'react';
import Light from './animated-instant-logo.svg';
import Dark from './animated-instant-logo-dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: Light,
  dark: Dark,
  default: Light,
};

export const AnimatedInstantLogo: React.FC<
  React.HTMLProps<HTMLPictureElement>
> = (props) => {
  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default AnimatedInstantLogo;
