import cacheLayerFunction from './js-caching';
import * as Parent from 'core/Parent';
import * as stringHelper from './stringHelper';

function taskGetIsWebview() {
  return new Promise((resolve) => {
    if (window.isWebview || window.document.isWebview)
      return resolve(!!(window.isWebview || window.document.isWebview));
    else if (typeof Parent.postData === 'function') {
      Parent.postData(
        `taskGetIsWebview_${stringHelper.randomString()}`,
        { fn: 'isThisWebview' },
        (err, data) => {
          if (err) {
            return resolve(false);
          }
          resolve(data);
        }
      );
    }
    setTimeout(() => {
      resolve(false);
    }, 400);
  });
}

async function taskGetPlayerId() {
  const isWebview = await taskGetIsWebview();

  if (!isWebview) return '';

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      resolve('');
    }, 5000);
    Parent.postData('taskGetPlayerId', { fn: 'getPlayerId' }, (err, data) => {
      clearTimeout(timeout);
      if (err) return reject(err);
      resolve(data);
    });
  });
}

const taskGetIsWebviewCache = cacheLayerFunction(taskGetIsWebview);

const taskGetPlayerIdCache = cacheLayerFunction(
  taskGetPlayerId,
  (value) => !!value && typeof value === 'string'
);

export {
  taskGetPlayerIdCache as taskGetPlayerId,
  taskGetIsWebviewCache as taskGetIsWebview,
};
