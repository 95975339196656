import numbro from 'numbro';
import { customAlphabet } from 'nanoid';

/**
 * Convert any string to first letter capitalized words
 * example: faisal bicara bakteri -> Faisal Bicara Bakteri
 * @param {string} str - String you want to convert
 * @return {string} - Formatted string
 */
export function toTitleCase(str) {
  if (str === null || str === undefined) return '';
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/**
 * Convert number to usual NAV value format
 * @param {number=} num - The number to be converted
 * @param {Object=} opt - Numbro.js options if you want specific config
 * @return {string}
 */
export function numberToGeneralValueFormat(num, opt) {
  if (typeof num === 'undefined' || num === null) {
    return '-';
  }

  if (isNaN(num)) {
    return 0;
  }

  return numbro(num).format({
    thousandSeparated: true,
    mantissa: 0,
    ...opt,
  });
}

/**
 * Convert number to usual NAV value format, but rounded to smallest integer
 * @param {number} num - The number to be converted
 * @param {Object=} opt - Numbro.js options if you want specific config
 * @return {string}
 */
export function numberToGeneralValueFormatFloor(num, opt) {
  if (typeof num === 'undefined' || num === null) {
    return '-';
  }

  if (isNaN(num)) {
    return 0;
  }

  return numbro(Math.floor(num)).format({
    thousandSeparated: true,
    mantissa: 0,
    ...opt,
  });
}

/**
 * Convert number to be string with averaged letter (K, M, B, T)
 * @param {Number=} num - The number to be converted
 * @param {boolean=} upperCased - Whether the letters should be uppercased
 * @param {Object=} opt - Numbro.js options if you want specific config
 * @return {string}
 */
export function numberToAveragedLetter(num, upperCased = false, opt = {}) {
  if (typeof num === 'undefined' || num === null) {
    return '-';
  }
  const text = numbro(num).format({
    average: true,
    spaceSeparated: false,
    ...opt,
  });
  return upperCased ? text.toUpperCase() : text;
}

/**
 * Convert number to have sign in front of the number, formatted with specified decimal.
 * Useful for point changes just like in stock charts
 * @param {number} num - The number to be converted
 * @param {Object=} opt - Numbro.js options if you want specific config
 * @return {string}
 */
export function numberToPointChangeFormat(num, opt = {}) {
  if (typeof num === 'undefined' || num === null) {
    return '-';
  }

  return numbro(num).format({
    thousandSeparated: true,
    forceSign: num !== 0,
    mantissa: 2,
    ...opt,
  });
}

/**
 * Round down and format number with specified decimal.
 * @param {number} num - The number to be converted
 * @param {Object=} opt - Numbro.js options if you want specific config
 * @return {string}
 */
export function numberToPointChangeFormatFloor(num, opt = {}) {
  if (typeof num === 'undefined' || num === null) {
    return '-';
  }

  return numbro(Math.floor(num * 100) / 100).format({
    thousandSeparated: true,
    mantissa: 2,
    ...opt,
  });
}

/**
 * Convert number to be percentage with sign in front of the number, formatted with specified decimal.
 * Useful for percentage changes just like in stock charts
 * @param {Number=} num - The number to be converted
 * @param {Object=} opt - Numbro.js options if you want specific config
 * @return {string}
 */
export function numberToPercentagePointChangeFormat(num, opt = {}) {
  if (typeof num === 'undefined' || num === null || num === Infinity) {
    return '-';
  }

  return numbro(num).format({
    thousandSeparated: true,
    forceSign: num !== 0,
    output: 'percent',
    spaceSeparated: false,
    mantissa: 2,
    ...opt,
  });
}

/**
 * Convert capitalized currency to its currency letter
 * @param {string} curr - Currency in 3 capitalized letter (ex: IDR)
 */
export function determineCurrencyLetter(curr) {
  if (typeof curr !== 'string') return '';
  switch (curr) {
    case 'IDR':
      return 'Rp';
    case 'USD':
      return '$';
    default:
      return 'Rp';
  }
}

/**
 * Remove trailing slash from a url
 * @param {string} url - The url to be cleaned
 * @return {string} New url without trailing slash
 */
export function removeTrailingSlash(url) {
  if (typeof url !== 'string')
    throw new Error('Parameter passed must be string');
  return url.replace(/\/$/, '');
}

/**
 * Parse voucher data and return the voucher amount based on type
 * @param {Voucher} voucher - Voucher object
 */
export const getDiscountValue = (voucher) => {
  if (voucher.voucherType === 'PERCENT') {
    return `${voucher.amountDiscount}%`;
  }
  if (voucher.voucherType === 'AMOUNT') {
    const { amountDiscount } = voucher;
    let del3digit = amountDiscount.slice(0, -3);
    return `${del3digit}K`;
  }
  return null;
};

/**
 * Lower case a file name extension
 * @param {string} fileName - The filename, complete with extension
 */
export const lowerCaseExtension = (fileName) => {
  if (typeof fileName !== 'string') return '';
  const extension = fileName.split('.').pop();
  return fileName.replace(/\.[0-9a-z]+$/i, '.' + extension.toLowerCase());
};

/**
 * Trim string with what ever length you want.
 * default length is 25
 * @param {string} strVal
 * @param {number} lengthWantToShow default value = 25
 */
export const trimString = (strVal, lengthWantToShow = 25) => {
  if (typeof strVal === 'string') {
    const subs = strVal.substr(0, lengthWantToShow);
    return strVal.length > lengthWantToShow ? `${subs}..` : strVal;
  }
  return '';
};

/**
 * Round number to nearest decimal amount
 * @param {number} number - The number you want to round
 * @param {number} decimalAmount - Decimal amount
 */
export const roundNumber = (number, decimalAmount) => {
  const decimalDivider = Math.pow(10, decimalAmount);
  return (
    Math.round((number + Number.EPSILON) * decimalDivider) / decimalDivider
  );
};

/**
 * Generate random string by length
 *
 * @param {number} length
 * @param {('hex'|'numeric')=} type
 * @returns {string}
 */
export function randomString(length = 10, type = 'hex') {
  const types = {
    hex: '0123456789abcdef',
    numeric: '0123456789',
  };
  const nanoid = customAlphabet(types[type], 10);
  return nanoid(length);
}

/**
 * Generate number with Indonesian nominal
 * @param {number} number ex: 10000 | 10000000
 * @param {boolean} withSpace ex: 1milyar -> 1 milyar
 * @param {boolean} withShorten ex: 14000 -> 14rb
 * @returns {string} Number with its nominal ex: 10k | 10juta
 */
export const nominalNumberFormatter = (
  number,
  withSpace = false,
  withShorten = false
) => {
  let symbols = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: withShorten ? 'rb' : 'k' },
    { value: 1e6, symbol: withShorten ? 'jt' : 'juta' },
    { value: 1e9, symbol: withShorten ? 'M' : 'milyar' },
  ];
  let i;
  for (i = symbols.length - 1; i > 0; i--) {
    if (number >= symbols[i].value) {
      break;
    }
  }
  return number / symbols[i].value + (withSpace ? ' ' : '') + symbols[i].symbol;
};

/**
 * Remove whitespace in a text
 * @param {string} text Ex: Input : " test@gmail.com "
 * @returns {string} Ex: Output : "test@gmail.com"
 */
export const removeWhiteSpace = (text) => {
  return text.replace(/ /g, '');
};

/**
 * Filter a text to contain only numbers
 * It is usually used for NIK and Nomor Rekening Forms
 * @param {string} text ex: '765-868-46836-2gfdgdf4-78   '
 * @returns {string} ex: "765868468362478"
 */
export const filterNumbersOnly = (text) => {
  return text.replace(/\D/g, '');
};

export function maskingText(puretext = '') {
  if (!!!puretext) return '';
  let mask = '';
  let len = puretext.length;
  let percentageTrim = Math.floor(len * 0.2);
  let first4 = puretext.substring(0, percentageTrim);
  len = len - first4.length;
  let last5 = puretext.substring(puretext.length - percentageTrim);
  len = len - last5.length;
  mask = '*'.repeat(len);

  return first4 + mask + last5;
}

export function maskingTextWithIndex(
  puretext = '',
  firstLength = 2,
  lastLength = 5,
  maskSymbol = '*'
) {
  if (!!!puretext || firstLength + lastLength > puretext.length) return '';
  let first, mask, last, len;
  try {
    mask = '';
    len = puretext.length;
    first = puretext.substring(0, firstLength);
    len = len - first.length;
    last = puretext.substring(puretext.length - lastLength);
    len = len - last.length;
    mask = maskSymbol.repeat(len);
  } catch (er) {
    first = '';
    mask = '';
    last = '';
  }

  return first + mask + last;
}

export const unicodeToEmoji = (str) => {
  return str.replace(/\\u[\dA-Fa-f]{4}/g, (match) => {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
  });
};

export default Object.assign(
  {},
  {
    unicodeToEmoji,
    maskingText,
    maskingTextWithIndex,
    toTitleCase,
    numberToAveragedLetter,
    numberToPointChangeFormat,
    numberToPercentagePointChangeFormat,
    removeTrailingSlash,
    getDiscountValue,
    roundNumber,
    removeWhiteSpace,
    filterNumbersOnly,
  }
);
