import * as React from 'react';

import { postJagoCheckCustomer } from 'features/bankjago/networks/services';
import getSafely from 'utils/safely';
import { taskGetIsWebview } from 'utils/is-webview';
import { useQueryParams } from 'utils/routeHelper';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';

const JagoCheckDifferentLoggedInPromptContext = React.createContext<{
  toggleJagoDifferentLoginModal: () => void;
  isDifferentLoggedIn: boolean;
}>({
  toggleJagoDifferentLoginModal: () => {},
  isDifferentLoggedIn: false,
});
const JagoCheckDifferentLoggedInServiceContext = React.createContext<
  () => Promise<void>
>(async () => {});

export const JagoCheckDifferentLoggedInProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  // Get auth context from hook
  const { isLogin: isLoggedIn } = useAuth();

  const { data: dataProfile } = useProfileData(isLoggedIn);

  const [isDifferentLoggedIn, setIsDifferentLoggedIn] =
    React.useState<boolean>(false);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  /**
   * Bibit User ID
   */
  const userId: number = profileDetail?.user?.id ?? 0;

  const query = useQueryParams();
  const partnerToken = query.get('partner_token');

  const toggleJagoDifferentLoginModal = () => {
    setIsDifferentLoggedIn(!setIsDifferentLoggedIn);
  };

  /**
   * Check login validation of customer if they redirected from jago app
   */
  const checkRedirectFromJagoApp = React.useCallback(async () => {
    const isWebView = await taskGetIsWebview();

    /**
     * if user logged in, at home, with webview and has partner token
     */
    if (partnerToken && isWebView && isLoggedIn) {
      try {
        const response = await postJagoCheckCustomer(partnerToken);
        const responseIsDifferentLoggedIn = getSafely(
          ['data', 'data', 'different_account_login'],
          response,
          false
        );
        setIsDifferentLoggedIn(responseIsDifferentLoggedIn);
      } catch (error) {
        // no need to do anything when error.
      }
    }
  }, [isLoggedIn, partnerToken]);

  /**
   * Calling checkRedirectFromJagoApp immedietly when App having userId
   */
  React.useEffect(() => {
    if (!userId) return;

    checkRedirectFromJagoApp();
  }, [checkRedirectFromJagoApp, userId]);

  return (
    <JagoCheckDifferentLoggedInPromptContext.Provider
      value={{
        isDifferentLoggedIn,
        toggleJagoDifferentLoginModal,
      }}
    >
      <JagoCheckDifferentLoggedInServiceContext.Provider
        value={checkRedirectFromJagoApp}
      >
        {children}
      </JagoCheckDifferentLoggedInServiceContext.Provider>
    </JagoCheckDifferentLoggedInPromptContext.Provider>
  );
};

export const useJagoCheckDifferentLoggedIn = () => {
  const { isDifferentLoggedIn, toggleJagoDifferentLoginModal } =
    React.useContext(JagoCheckDifferentLoggedInPromptContext);
  const checkJagoDifferentLoggedIn = React.useContext(
    JagoCheckDifferentLoggedInServiceContext
  );

  return {
    isDifferentLoggedIn,
    checkJagoDifferentLoggedIn,
    toggleJagoDifferentLoginModal,
  };
};

export default JagoCheckDifferentLoggedInProvider;
