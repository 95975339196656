import { useMemo } from 'react';
import { usePreferenceMutation } from 'features/profile/network';
import { useAuth, useProfileData } from 'hooks';
import rqClient from 'network/queryClient';
import { useQuery } from 'react-query';

export const useSyariahDialog = () => {
  const { data: visible } = useQuery('syariahDialog', () => {
    return rqClient.getQueryData<boolean>('syariahDialog');
  });

  const { isLogin: isLoggedIn } = useAuth();
  const { data: profileData } = useProfileData(isLoggedIn);
  const { mutateAsync: preferenceMutationAsync } = usePreferenceMutation();

  const profileDetail = profileData?.data?.data;

  const showDialog = () => {
    rqClient.setQueryData('syariahDialog', true);
  };

  const closeDialog = () => {
    rqClient.setQueryData('syariahDialog', false);

    preferenceMutationAsync({
      hasShownSyariahDialog: 1,
    });
  };

  const shouldRecommendSyariah = useMemo(() => {
    /** Is User Muslim ? */
    const isMuslimReligion = profileDetail?.user?.religion === 1;

    if (profileDetail?.preference) {
      const hasShownSyariahDialog =
        profileDetail?.preference?.hasShownSyariahDialog;
      if (!hasShownSyariahDialog && isMuslimReligion) {
        return true;
      }
    } else if (!profileDetail?.preference && isMuslimReligion) {
      return true;
    }
    return false;
  }, [profileDetail?.preference, profileDetail?.user?.religion]);

  return {
    visible,
    showDialog,
    closeDialog,
    shouldRecommendSyariah,
  };
};
