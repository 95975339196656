import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { addMultipleReksadanaEntry } from './reksadana.reducer';
import { getPortfolioByCategory } from 'services/portfolio';

// Initial State
const defaultState = {
  categories: {
    byId: {},
    allIds: [],
    loading: false,
  },
  items: {
    byId: {},
    allIds: [],
  },
  robo: {
    byId: {},
    allIds: [],
  },
  pending: {
    byId: {},
    allIds: [],
  },
};

// Actions
const [
  addPortfolioItemEntry,
  addMultiplePortfolioItemEntry,
  addRoboPortfolioEntry,
  addPortfolioItemId,
  addPortfolioCategoriesEntry,
  addMultiplePortfolioCategoriesEntry,
  addPortfolioCategoriesId,
  addMultiplePortfolioPendingItemEntry,
  setLoadingPortoCategories,
  addMultiplePortfolioItemEntryFromCategories,
] = [
  'ADD_PORTFOLIO_ITEM_ENTRY',
  'ADD_MULTIPLE_PORTFOLIO_ITEM_ENTRY',
  'ADD_ROBO_PORTFOLIO_ENTRY',
  'ADD_PORTFOLIO_ITEM_ID',
  'ADD_PORTFOLIO_CATEGORIES_ENTRY',
  'ADD_MULTIPLE_PORTFOLIO_CATEGORIES_ENTRY',
  'ADD_PORTFOLIO_CATEGORIES_ID',
  'ADD_MULTIPLE_PORTFOLIO_PENDING_ITEM_ENTRY',
  'SET_LOADING_PORTO_CATEGORIES',
  'ADD_MULTIPLE_PORTFOLIO_ITEM_ENTRY_FROM_CATEGORIES',
].map(createAction);

// Reducer
const portfolio = createReducer(
  {
    // Adding a mutual fund data to the fund data catalog
    [addPortfolioItemEntry]: (state, payload) => {
      return update(state, {
        items: {
          byId: {
            $set: {
              ...state.items.byId,
              [payload.product.symbol]: {
                ...payload,
              },
            },
          },
        },
      });
    },

    [addMultiplePortfolioItemEntryFromCategories]: (state, payload) => {
      const portfolioEntries = payload.reduce((acc, curr) => {
        acc[curr.robocategory] = curr;
        return acc;
      }, {});

      const portfolioEntriesId = Object.keys(portfolioEntries).reduce(
        (acc, curr) => {
          portfolioEntries[curr].items.map((item) => {
            if (item && item.product) acc[item.product.symbol] = item;
            return item;
          });
          return acc;
        },
        {}
      );

      return update(state, {
        items: {
          byId: {
            $set: portfolioEntriesId,
          },
        },
      });
    },

    // Adding multiple mutual fund data
    [addMultiplePortfolioItemEntry]: (state, payload) => {
      const portfolioEntries = payload
        .filter((item) => !!item)
        .reduce((data, currValue) => {
          const updated = { ...data };

          const { product: { symbol = '' } = {} } = currValue;

          if (symbol) updated[symbol] = currValue;

          return updated;
        }, {});

      return update(state, {
        items: {
          byId: {
            $set: {
              ...state.items.byId,
              ...portfolioEntries,
            },
          },
        },
      });
    },

    // Add new robo portfolio entry
    [addRoboPortfolioEntry]: (state, payload) => {
      const portoItemEntries = payload.data.results
        .reduce((data, currValue) => data.concat(currValue.items), [])
        .reduce(
          (data, currValue) => ({
            ...data,
            [currValue.product.symbol]: currValue,
          }),
          {}
        );
      return update(state, {
        robo: {
          byId: {
            $set: {
              ...state.robo.byId,
              [payload.categoryId]: {
                ...payload.data,
              },
            },
          },
        },
        items: {
          byId: {
            $set: {
              ...state.items.byId,
              ...portoItemEntries,
            },
          },
        },
      });
    },

    // Adding new id to fund data catalog
    [addPortfolioItemId]: (state, payload) => {
      return update(state, {
        items: {
          allIds: [...state.allIds, payload.symbol],
        },
      });
    },

    // Adding a mutual fund data to the fund data catalog
    [addPortfolioCategoriesEntry]: (state, payload) => {
      return update(state, {
        categories: {
          byId: {
            $set: {
              ...state.categories.byId,
              [payload.id]: {
                ...payload,
              },
            },
          },
        },
      });
    },

    // Adding multiple mutual fund data
    [addMultiplePortfolioCategoriesEntry]: (state, payload) => {
      const portfolioEntries = payload.reduce(
        (data, currValue) => ({
          ...data,
          [currValue.id]: currValue,
        }),
        {}
      );
      return update(state, {
        categories: {
          byId: {
            $set: {
              ...state.categories.byId,
              ...portfolioEntries,
            },
          },
          allIds: { $set: payload.map((item) => item.id) },
        },
      });
    },

    // Adding new id to fund data catalog
    [addPortfolioCategoriesId]: (state, payload) => {
      return update(state, {
        categories: {
          allIds: [...state.allIds, payload.symbol],
        },
      });
    },

    // Adding pending portfolio item data to the store
    [addMultiplePortfolioPendingItemEntry]: (state, payload) => {
      const pendingEntries = payload.reduce(
        (data, currValue) => ({
          ...data,
          [currValue.id]: currValue,
        }),
        {}
      );
      return update(state, {
        pending: {
          byId: {
            $set: {
              ...state.categories.byId,
              ...pendingEntries,
            },
          },
        },
      });
    },

    [setLoadingPortoCategories]: (state, payload) => {
      return update(state, {
        categories: {
          loading: { $set: payload },
        },
      });
    },
  },
  defaultState
);

export {
  addPortfolioItemEntry,
  addMultiplePortfolioItemEntry,
  addRoboPortfolioEntry,
  addPortfolioItemId,
  addPortfolioCategoriesEntry,
  addMultiplePortfolioCategoriesEntry,
  addPortfolioCategoriesId,
  addMultiplePortfolioPendingItemEntry,
  addMultiplePortfolioItemEntryFromCategories,
};

export const getPortfolioCategoriesData = (state) =>
  state.entities.portfolio.categories.allIds.map(
    (item) => state.entities.portfolio.categories.byId[item]
  );

/**
 * Record portfolio items
 */
export function getPortfolioItemEntry(categoryId) {
  return (dispatch) => {
    if (!categoryId) {
      // On no fundid given, return empty
      return Promise.resolve({});
    }

    return getPortfolioByCategory(categoryId)
      .then((response) => {
        const portfolioItemData = response.data.data;

        // Robo specific portfolio category handling
        if (portfolioItemData.robo) {
          dispatch(
            addRoboPortfolioEntry({
              categoryId: categoryId,
              data: portfolioItemData,
            })
          );
          return dispatch(
            addMultipleReksadanaEntry(
              portfolioItemData.results.reduce(
                (data, currValue) =>
                  data.concat(
                    currValue.items.map((portoItem) => portoItem.product)
                  ),
                []
              )
            )
          );
        }

        // Non robo portfolio category handling
        dispatch(addMultiplePortfolioItemEntry(portfolioItemData.results));
        return dispatch(
          addMultipleReksadanaEntry(
            portfolioItemData.results.map((item) => item.product)
          )
        );
      })
      .catch((err) => console.error(err));
  };
}

export default portfolio;
