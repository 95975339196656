import React from 'react';
import { Modal } from '../components';

const ModalPlaceHolder: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Modal visible={true} showClose={false} showMask={false}>
      <div
        className='animated-background recomend-loader'
        style={{ width: '100%' }}
      />
    </Modal>
  );
};

export default ModalPlaceHolder;
