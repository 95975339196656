import LazyModal from 'features/common/LazyModal';
import React from 'react';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

const SyariahOptionModalLazy = React.lazy(() => import('./SyariahOptionModal'));

const SyariahOptionModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'syariah_option';

  return <LazyModal component={SyariahOptionModalLazy} visible={visible} />;
};

export default SyariahOptionModal;
