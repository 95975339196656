import list from './index';
import http from 'core/http';

/**
 * Call API to get the goal template list
 */
export function fetchGoalTemplate() {
  return http.get(list.portfolioGoalTemplate);
}

/**
 * Recalculate recurring payment amount for reaching goal
 * @param {String} categoryId - Goal portfolio ID
 */
export function fetchGoalOnTrackStatus(categoryId) {
  return http.get(list.portfolioGoalOnTrack(categoryId));
}

/**
 * Get chart data for seeing goal progress
 * @param {String} categoryId - Goal portfolio ID
 */
export function fetchGoalChartProgress(categoryId) {
  return http.get(list.portfolioGoalChart(categoryId));
}

/**
 * Call API to show new goal progress preview
 * @param {Object} previewData
 * @param {String} previewData.portfolio_template - Portfolio template id
 * @param {String} previewData.target_amount      - Goal target amount
 * @param {String} previewData.target_date        - Goal target date
 * @param {Number=} previewData.is_robo
 * @param {Number=} previewData.robo_score       - Score Robo
 */
export function postCreateGoalPreview(previewData) {
  return http.post(list.portfolioGoalPreview, previewData);
}

/**
 * Submit data to create new goal
 * @param {Object} createGoalData
 */
export function postCreateGoal(createGoalData) {
  return http.post(list.portfolioGoalCreate, createGoalData);
}

/**
 * Submit data to create new goal
 * @param {String} categoryId - Portfolio category ID
 * @param {Object} createGoalData - Data needed to create goal setting
 * @param {String} createGoalData.target_amount - Goal saving target
 */
export function postApplyGoalByCategoryId(categoryId, createGoalData) {
  return http.post(
    list.portfolioGoalApplyByCategoryId(categoryId),
    createGoalData
  );
}

/**
 * Submit data to create new goal
 * @param {Number} categoryId - Portfolio category ID
 * @param {Object} createGoalData - Data needed to create goal setting
 * @param {String} createGoalData.target_amount - Goal saving target
 */
export function postPreviewGoalByCategoryId(categoryId, previewGoalData) {
  return http.post(
    list.portfolioGoalPreviewByCategoryId(categoryId),
    previewGoalData
  );
}

/**
 * Call API to edit goal
 * @param {Object} updateData                - Edit goal data
 * @param {String} updateData.portfolio_name - Portfolio name
 * @param {String} updateData.target_amount  - Goal target amount
 * @param {String} updateData.target_date    - Goal target date
 * @param {String} updateData.category_id    - Portfolio category id
 */
export function postUpdateGoal(updateData) {
  return http.post(list.portfolioGoalUpdate, updateData);
}

/**
 * Call API to edit goal
 * @param {Object} deleteData                - Edit goal data
 * @param {String} deleteData.categoryid    - Portfolio category id
 * @param {boolean} deleteData.force        - Force delete portfolio and cart inside the portfolio
 */
export function postPortfolioCategoryDelete(deleteData) {
  return http.post(list.portfolioCategoryDelete, deleteData);
}

/**
 * Recalculate recurring payment amount for reaching goal
 * @param {String} portfolioId - Goal portfolio ID
 */
export function postGoalRecalculateAmount(portfolioId, goalData) {
  return http.post(list.portfolioGoalRecalculate(portfolioId), goalData);
}
