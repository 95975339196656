import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { GET_PROFILE_QUERY_KEY } from 'hooks/useProfileData';
import { postUserUpdatePreference } from './index';
import { ProfileDataResponse } from '../types';

/**
 * A `react-query` mutation to edit user preference data
 */
export function usePreferenceMutation(shouldRefetchProfile = true) {
  const queryClient = useQueryClient();

  return useMutation(postUserUpdatePreference, {
    onMutate: async (preferenceData) => {
      /**
       * Cancel any other outgoing refetch queries to prevent this optimistic
       * update being replaced with other results
       * @see https://react-query.tanstack.com/guides/optimistic-updates
       */
      await queryClient.cancelQueries(GET_PROFILE_QUERY_KEY);

      /** Save previous user profile data */
      const previousUserData = queryClient.getQueryData<
        AxiosResponse<ProfileDataResponse>
      >(GET_PROFILE_QUERY_KEY);

      // Optimistically update to the new value
      queryClient.setQueryData<AxiosResponse<ProfileDataResponse> | undefined>(
        GET_PROFILE_QUERY_KEY,
        (userData) => {
          if (!userData) {
            return userData;
          }

          return {
            ...userData,
            data: {
              ...userData.data,
              data: {
                ...userData.data.data,
                preference: {
                  ...userData.data.data.preference,
                  ...preferenceData,
                },
              },
            },
          };
        }
      );

      // Return a context object with the snapshotted value
      return { previousUserData };
    },
    onError: (
      err,
      newUserPreferenceData,
      context:
        | { previousUserData?: AxiosResponse<ProfileDataResponse> }
        | undefined
    ) => {
      // Reset user data if error happens
      if (context && context.previousUserData) {
        queryClient.setQueryData(
          GET_PROFILE_QUERY_KEY,
          context.previousUserData
        );
      }
    },
    onSettled: (response, error, variables) => {
      if (shouldRefetchProfile) {
        queryClient.invalidateQueries(GET_PROFILE_QUERY_KEY);
      }
    },
  });
}
