import {
  fetchGoalTemplate,
  postCreateGoalPreview,
} from 'services/goalSettings';
import { getPortfolioTypes } from '../networks/services';
import { useMutation, useQuery } from 'react-query';
import queryClient from 'network/queryClient';

/** A key to SET and GET goal preview data */
export const GOAL_PREVIEW_DATA = 'GOAL_PREVIEW_DATA';

export const usePortfolioTemplate = (enabled = false) => {
  return useQuery('Portfolio Template', fetchGoalTemplate, { enabled });
};

export const useSubmitGoalPreview = () => {
  return useMutation(postCreateGoalPreview, {
    onSuccess(data) {
      queryClient.setQueryData(GOAL_PREVIEW_DATA, data.data?.data);
    },
  });
};

export const usePortfolioTypes = (enabled = false) => {
  return useQuery('Portfolio Types', getPortfolioTypes, { enabled });
};
