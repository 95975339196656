import React, { useContext } from 'react';
import useWindowSize from 'hooks/useWindowSize';

type HeightSizeType = {
  initialHeight: number | string | undefined;
  initialWidth: number | string | undefined;
};

export const HeightSizeContext = React.createContext<HeightSizeType>({
  initialHeight: 0,
  initialWidth: 0,
});

/**
 * Context Provider to give an initial Value of height based on window.innerHeight
 * in many android device if Keyboard showing up the viewport of height will be distract, and bomb... user get blank screen.
 *
 */
export const HeightSizeProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const size = useWindowSize();

  return (
    <HeightSizeContext.Provider
      value={{
        initialHeight: size.height,
        initialWidth: size.width,
      }}
    >
      {children}
    </HeightSizeContext.Provider>
  );
};

export const useHeightSizeProvider = () => {
  const ctx = useContext(HeightSizeContext);
  return ctx;
};
