import LazyModal from 'features/common/LazyModal';
import React from 'react';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

const PortfolioOptionOnboardingModalLazy = React.lazy(
  () => import('./PortfolioOptionOnboardingModal')
);

const PortfolioOptionOnboardingModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'portfolio_option';

  return (
    <LazyModal
      component={PortfolioOptionOnboardingModalLazy}
      visible={visible}
    />
  );
};

export default PortfolioOptionOnboardingModal;
