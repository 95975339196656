import React, { useEffect } from 'react';
import { Text } from '@bibitid/uikit-v1';
import BibitNotCompleteIllustration from 'assets/images/portfolio/bibit-not-complete.svg';
import { Modal } from '@bibitid/uikit-v1';
import { Button } from '@bibitid/uikit';
import { BIBIT_PLUS_NAME } from 'constants/common';
import useBibitPlusUpgradeStatus from 'features/bibitplus/hooks/useBibitPlusUpgradeStatus';
import { useHistory } from 'react-router-dom';
import BibitPlusUnregisteredIllustration from 'assets/images/portfolio/upgrade-modal/BibitPlusUnregisteredIllustration/BibitPlusUnregisteredIllustration';
import BibitPlusNotCompleteRegllustration from 'assets/images/portfolio/upgrade-modal/BibitPlusNotCompleteRegllustration/BibitPlusNotCompleteRegllustration';
import {
  BibitPlusDescription,
  BibitPlusImage,
  Container,
} from './BibitPlusUpgradeModal.styled';
import Analytics from 'utils/Analytics';

const TYPE = {
  stock: {
    title: 'Belum Bisa Transaksi Saham',
    description: `Transaksi saham dapat dilakukan setelah upgrade ${BIBIT_PLUS_NAME} kamu berhasil.`,
  },
  sbn: {
    title: 'Belum Bisa Transaksi SBN',
    description: `Transaksi SBN dapat dilakukan setelah upgrade ${BIBIT_PLUS_NAME} kamu berhasil.`,
  },
  fr: {
    title: 'Belum Bisa Transaksi Obligasi FR',
    description: `Kamu bisa membeli SBN setelah upgrade Bibit Plus berhasil.`,
  },
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  source: 'stock' | 'sbn' | 'fr' | 'cash';
}

const UpgradeBibitPlusModal = (props: Props) => {
  const history = useHistory();
  const { isOpen, onClose, source } = props;
  const { upgradeStatus, userHasBeenVerifiedBibit, isLoading } =
    useBibitPlusUpgradeStatus();

  //If user has verified (bibit) and not upgraded to bibit+
  let title = `Upgrade ke ${BIBIT_PLUS_NAME} Dulu Yuk!`,
    description = `Dengan upgrade ke ${BIBIT_PLUS_NAME}, kamu bisa akses halaman saldo
          cash RDN Wallet, investasi saham & SBN, dan nikmati fitur lainnya.`,
    buttonText = `Upgrade Sekarang`,
    buttonAction = () => {
      if (source === 'cash') {
        Analytics.logEventAction({
          eventName: 'upgrade_bibit+_action',
          parameter: {
            action: 'upgrade_bibit+',
            context: 'portfolio.asset_allocation_group',
            trigger: 'click',
          },
        });
      } else {
        Analytics.logEventAction({
          eventName: 'bibitplus_regist_action',
          parameter: {
            action: 'upgrade_now',
            trigger: 'click',
            context: 'regist_bitplus.upgrade_entry',
          },
        });
      }

      history.push('/bibitplus-coregist');
    };

  let image: any = (
    <BibitPlusUnregisteredIllustration alt='upgrade bibit plus' />
  );

  if (source === 'cash') {
    //If user drop, waiting for verification or rejected when upgrade bibit+
    if (!upgradeStatus.notUpgraded) {
      title = `Upgrade ${BIBIT_PLUS_NAME} Belum Berhasil`;
      description = `Upgrade ${BIBIT_PLUS_NAME} untuk akses halaman saldo cash RDN Wallet, investasi saham, SBN dan obligasi FR.`;
      buttonText = `Saya Mengerti`;
      buttonAction = () => {
        Analytics.logEventAction({
          eventName: 'unregistered_bibitplus_action',
          parameter: {
            action: 'oke',
            context: 'portfolio.asset_allocation_group',
            trigger: 'click',
          },
        });

        onClose();
      };
      image = <BibitPlusNotCompleteRegllustration />;
    }
  } else {
    if (!upgradeStatus.notUpgraded) {
      title = TYPE[source].title;
      description = TYPE[source].description;
      buttonText = `Lihat Status ${BIBIT_PLUS_NAME}`;
      buttonAction = () => {
        history.push('/bibitplus-list');
      };
      image = (
        <BibitPlusNotCompleteRegllustration alt='upgrade belum berhasil' />
      );
    }
  }

  //If user has not verified by bibit
  if (!userHasBeenVerifiedBibit) {
    title = `Registrasi Bibit Belum Selesai`;
    description = `Selesaikan registrasi agar kamu bisa akses halaman ini dan fitur lainnya.`;
    buttonText = `Saya Mengerti`;
    buttonAction = () => {
      Analytics.logEventAction({
        eventName: 'unregistered_bibitplus_action',
        parameter: {
          action: 'oke',
          context: 'portfolio.asset_allocation_group',
          trigger: 'click',
        },
      });

      onClose();
    };
    image = BibitNotCompleteIllustration;
  }

  useEffect(() => {
    if (isOpen && source !== 'cash' && !isLoading) {
      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'regist_bitplus',
          view: 'upgrade_entry',
        },
      });
    }
  }, [isOpen, source, isLoading]);

  useEffect(() => {
    if (isOpen && source === 'cash' && !isLoading) {
      let view = '';
      if (!userHasBeenVerifiedBibit) {
        view = 'regisbibit_unfinished';
      } else {
        if (upgradeStatus.notUpgraded) {
          view = 'upgrade_bibitplus';
        } else {
          view = 'upgrade_unfinished';
        }
      }

      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'portfolio',
          view,
        },
      });
    }
  }, [isOpen, userHasBeenVerifiedBibit, source, isLoading, upgradeStatus]);

  return (
    <Modal visible={isOpen} onClose={onClose}>
      <Modal.Header title='' showBackButton={false} showClose />
      <Modal.Body>
        <Container>
          <BibitPlusImage>
            {typeof image === 'string' ? (
              <img src={image} alt='Upgrade bibit plus BoBit' />
            ) : (
              image
            )}
          </BibitPlusImage>
          <Text type='subtitle2'>{title}</Text>
          <BibitPlusDescription type='body1'>
            {description}
          </BibitPlusDescription>
        </Container>
      </Modal.Body>
      <Modal.Footer
        primaryButton={
          <Button onClick={buttonAction}>
            <b>{buttonText}</b>
          </Button>
        }
      />
    </Modal>
  );
};

export default UpgradeBibitPlusModal;
