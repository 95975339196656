import { createReducer, createAction } from 'redux-act';
import update from 'immutability-helper';

interface DefaultRecurringState {
  modalRestrictRecurring: boolean;
}

const defaultState: DefaultRecurringState = {
  modalRestrictRecurring: false,
};

export const toggleModalRestrictRecurring = createAction<boolean>(
  'RECURRING_TOGGLE_RESTRICT_RECURRING'
);

const recurring = createReducer<typeof defaultState>({}, defaultState);

recurring.on(toggleModalRestrictRecurring, (state, payload) => {
  return update(state, {
    modalRestrictRecurring: { $set: payload },
  });
});

export default recurring;
