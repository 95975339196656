import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { AppThunk } from 'utils/redux';
import {
  ToastDefaultState,
  PayloadToggleToastBottom,
  PayloadToggleToastTop,
} from './type.d';

const defaultState: ToastDefaultState = {
  visibleToast: false,
  textMessage: '',
  type: 'success',
  bottomPosition: '',
  withIcon: true,
  withCloseButton: false,
  redirectLink: '',
  redirectButtonMessage: 'Lihat',
  toastTop: {
    visibleToast: false,
    textMessage: '',
    theme: 'green',
  },
  onClose: () => {},
};

export const toggleToastBottom = createAction<PayloadToggleToastBottom>(
  'TOAST/TOGGLE_TOAST_BOTTOM'
);
export const toggleToastTop = createAction<PayloadToggleToastTop>(
  'TOAST/TOGGLE_TOAST_TOP'
);

const toastReducer = createReducer<typeof defaultState>({}, defaultState);

toastReducer.on(
  toggleToastBottom,
  (state, payload: PayloadToggleToastBottom) => {
    return update(state, {
      visibleToast: { $set: payload.visible },
      textMessage: { $set: payload.textMessage },
      type: { $set: payload.type },
      bottomPosition: { $set: payload.bottomPosition },
      withIcon: { $set: payload.withIcon },
      withCloseButton: { $set: payload.withCloseButton },
      redirectLink: { $set: payload.redirectLink },
      redirectButtonMessage: { $set: payload.redirectButtonMessage },
      onClose: { $set: payload.onClose },
    });
  }
);

toastReducer.on(toggleToastTop, (state, payload: PayloadToggleToastTop) => {
  return update(state, {
    toastTop: {
      visibleToast: { $set: payload.visibleToast },
      textMessage: { $set: payload.textMessage },
      theme: { $set: payload.theme },
    },
  });
});

export const openToastMessage =
  (
    msg: React.ReactNode,
    type: 'success' | 'error' | 'lightgreensuccess' | 'blacksuccess',
    withIcon: boolean = true,
    redirectLink: string = '',
    redirectButtonMessage: string = '',
    withCloseButton: boolean = false,
    onClose: () => void = () => {},
    bottomPosition?: string
  ): AppThunk<void> =>
  (dispatch) => {
    dispatch(
      toggleToastBottom({
        visible: true,
        type,
        textMessage: msg,
        bottomPosition,
        withIcon,
        withCloseButton,
        redirectLink,
        redirectButtonMessage,
        onClose,
      })
    );

    setTimeout(() => {
      dispatch(
        toggleToastBottom({
          visible: false,
          type,
          textMessage: msg,
          bottomPosition,
          withIcon,
          withCloseButton,
          redirectLink: '',
          redirectButtonMessage,
          onClose,
        })
      );
    }, 2500);
  };

export const openToastTop =
  (msg: string, theme: 'green' | 'red'): AppThunk<void> =>
  (dispatch) => {
    dispatch(toggleToastTop({ visibleToast: true, theme, textMessage: msg }));

    setTimeout(() => {
      dispatch(
        toggleToastTop({ visibleToast: false, theme, textMessage: msg })
      );
    }, 2500);
  };

export default toastReducer;
