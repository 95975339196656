import classNames from 'classnames';
import React from 'react';
import style from './loader.module.css';

interface Props {
  /** width of simmer loading, default = 120px */
  width?: number | string;
  /** heigth of simmer loading, default = 8px */
  height?: number | string;
  /** Additional classname custom */
  className?: string;
}

const LoaderSimmerLine: React.FC<React.PropsWithChildren<Props>> = ({
  width = 120,
  height = 8,
  className = '',
}) => {
  return (
    <div
      className={classNames(style['loader-simmer-line'], {
        [className]: !!className,
      })}
      style={{ width, height }}
    />
  );
};

export default LoaderSimmerLine;
