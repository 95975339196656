import list from './index';
import { AxiosResponse } from 'axios';
import { BibitApiSuccessResponse } from 'services';
import { PaymentMethod } from './payment.type';
import http from 'core/http';

export interface ParamGetPayment {
  amount: number;
  product_symbol?: string;
  robo_id?: string;
  recurring?: number;
  stamp_duty?: number;
}

/**
 *
 * use 'getPaymentMethod' instead for more processing
 * @param {object} data
 */
export function getPaymentMethodRaw(
  data: ParamGetPayment
): Promise<AxiosResponse<BibitApiSuccessResponse<PaymentMethod[]>>> {
  return http.get(list.getPaymentMethod, { ...data });
}

export interface ParamChargeChannel {
  is_stamp_duty: boolean;
}

/**
 * POST: Gopay charge
 * @param {string} invoiceNumber
 * @param {string} paymentKey
 */
export function postRdnCharge(invoiceNumber: string, paymentKey: string) {
  return http.post(list.paymentRdnCharge(invoiceNumber, paymentKey));
}

/**
 * POST: Gopay charge
 * @param {string} invoiceNumber
 * @param {object} data
 */
export function postGopayCharge(
  invoiceNumber: string,
  data: ParamChargeChannel
) {
  return http.post(list.paymentGopayCharge(invoiceNumber), { ...data });
}

/**
 * POST: ShopeePay Charge/recharge
 * @param invoice
 * @param {object} data
 */
export function postShopeeCharge(invoice: string, data: ParamChargeChannel) {
  return http.post(list.paymentShopeeCharge(invoice), { ...data });
}

/**
 * POST: LinkAja charge
 * @param {string} invoiceNumber
 * @param {object} data
 */
export function postLinkAjaCharge(
  invoiceNumber: string,
  data: ParamChargeChannel
) {
  return http.post(list.paymentLinkAjaCharge(invoiceNumber), { ...data });
}

/**
 * POST: LinkAja applink charge
 * @param {string} invoiceNumber
 * @param {object} data
 */
export function postLinkAjaApplinkCharge(
  invoiceNumber: string,
  data: ParamChargeChannel
) {
  return http.post(list.paymentLinkAjaApplinkCharge(invoiceNumber), {
    ...data,
  });
}
