import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';

/**
 * User status = 11 means user has already requested to deactivate their account
 * @returns boolean
 */

export const useUserDeactivateStatus = () => {
  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  const userDeactivateStatus = profileDetail?.user?.status ?? 0;

  return Boolean(userDeactivateStatus === 11);
};
