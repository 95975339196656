import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import compareVersions from 'compare-versions';

import Storage from 'core/Storage';
import * as Parent from 'core/Parent';
import { postData } from 'core/Parent';

import { isAfter, isSameDay, addDays } from 'utils/date';
import { useCheckFeatureVersion } from 'utils/feature-version';
import { isInsideNativeContainer } from 'utils/validator';
import { useRemoteConfigValue } from 'core/remoteConfig';
import useDeviceInfo from 'hooks/useDeviceInfo';

import {
  DeviceUpdateCategoryProps,
  UpdateConfigProps,
  ModalContextType,
} from '../ModalUpdateReminderTypes';

export const ModalContext = createContext<ModalContextType>({
  visible: false,
  currentVersion: '',
  isForceUpdate: false,
  isDoneCollectData: false,
  handleUpdateReminder: () => {},
  handleCloseReminder: () => {},
  handleUpdateApp: () => {},
});

/**
 * Represent user device is already reminded / not
 * @param reminderType - User device belong to the rule type
 * @param nextUpdateReminderTime - Next update schedule, get from device storage (Only PERIODIC type)
 * @param updateAlreadyRemindedVersion - The version that has been reminded, get from device storage (Only ONCE type)
 * @param currentVersion - Latest app version
 * @returns {boolean} - If true: User already reminded, if false: User get reminder popup
 */
const checkAlreadyReminded = (
  reminderType: DeviceUpdateCategoryProps,
  nextUpdateReminderTime: string,
  updateAlreadyRemindedVersion: string,
  currentVersion: string
): boolean => {
  if (reminderType === 'FORCE') return false; // always show popup reminder

  if (reminderType === 'ONCE') {
    const isCorrectVersion =
      compareVersions.validate(updateAlreadyRemindedVersion) &&
      compareVersions.validate(currentVersion);
    /** Compare device storage and current version */
    const isLocalDeviceSmallerThanCurrent =
      isCorrectVersion &&
      compareVersions.compare(
        updateAlreadyRemindedVersion,
        currentVersion,
        '<'
      );
    if (!updateAlreadyRemindedVersion || isLocalDeviceSmallerThanCurrent) {
      return false;
    }
  }

  if (reminderType === 'PERIODIC') {
    const today = new Date();
    /** Check next reminder schedule */
    const isNextReminderIsToday =
      isSameDay(new Date(nextUpdateReminderTime), today) ||
      isAfter(today, new Date(nextUpdateReminderTime));
    if (!nextUpdateReminderTime || isNextReminderIsToday) return false;
  }

  return true;
};

/**
 * Modal Update Reminder Provider
 *
 * @todo cleanup `updateRulesConfig.json` from files & gitlab ci
 */
const ModalUpdateReminderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isNative = isInsideNativeContainer();

  // Get device info
  const { data: deviceInfo } = useDeviceInfo();

  // Derived device data
  const SYSTEM_NAME = deviceInfo?.SYSTEM_NAME || '';
  const VERSION = deviceInfo?.VERSION || '';
  const MANUFACTURER = deviceInfo?.MANUFACTURER || '';
  const userDevice = SYSTEM_NAME.toLowerCase();
  const userVersion = VERSION.toLowerCase();

  // Check wether current device is eligible for using remote config (latest version)
  const { data: remoteConfigAvailable, isFetched } =
    useCheckFeatureVersion('Remote Config');

  // Get update rules from remote config
  const updateConfigRaw = useRemoteConfigValue('update_app_reminder');

  // Parsed update config rules
  const updateConfig = useMemo(() => {
    if (isFetched && remoteConfigAvailable && updateConfigRaw) {
      return JSON.parse(updateConfigRaw) as UpdateConfigProps;
    }
  }, [isFetched, remoteConfigAvailable, updateConfigRaw]);

  // Local state
  const [userReminderType, setUserReminderType] =
    useState<DeviceUpdateCategoryProps>('NONE');
  const [nextUpdateReminderTime, setNextUpdateReminderTime] = useState('');
  const [updateAlreadyRemindedVersion, setUpdateAlreadyRemindedVersion] =
    useState('');
  const [alreadyReminded, setAlreadyReminded] = useState(true);
  const [intervalReminder, setIntervalReminder] = useState(0);
  const [isDoneCollectData, setIsDoneCollectData] = useState(false);

  // Force user to update if remote config feature is not available.
  useEffect(() => {
    if (isNative && isFetched && !remoteConfigAvailable) {
      setUserReminderType('FORCE');
      setAlreadyReminded(false);
    }
  }, [isNative, isFetched, remoteConfigAvailable]);

  // Bibit latest app version
  const currentVersion = updateConfig?.currentVersion || '';

  /**
   * Bibit application store
   */
  const userStoreUrl =
    MANUFACTURER.toLowerCase() === 'huawei'
      ? updateConfig?.updateUrl?.huawei
      : updateConfig?.updateUrl?.default;

  /**
   * Check Force update status type
   */
  const isForceUpdate = userReminderType === 'FORCE';

  /**
   * Get `hasInAppUpdate` from window
   */
  const hasInAppUpdate =
    window.document.hasInAppUpdate || window.hasInAppUpdate;

  /**
   * Check android in app update usage
   */
  const isUseAndroidInAppUpdate =
    userDevice === 'android' &&
    hasInAppUpdate &&
    MANUFACTURER.toLowerCase() !== 'huawei';

  /**
   * Set New data reminder to device storage on close popup
   */
  const handleCloseReminder = () => {
    // only close if reminder type not FORCE
    if (userReminderType !== 'FORCE') {
      setAlreadyReminded(true);
    }
    if (userReminderType === 'ONCE') {
      // set current version from config to device storage
      Storage.set('updateAlreadyRemindedVersion', currentVersion);
    }
    if (userReminderType === 'PERIODIC' && intervalReminder) {
      // set next update reminder time based on H + interval (days)
      const nextUpdateTime = addDays(new Date(), intervalReminder);
      Storage.set('nextUpdateReminderTime', nextUpdateTime);
    }
  };

  /**
   * Event click to device store and close modal reminder
   */
  const handleUpdateReminder = () => {
    Parent.postData('openStore', { data: userStoreUrl, fn: 'openLink' });
    handleCloseReminder();
  };

  /**
   * Handle update app
   */
  const handleUpdateApp = () => {
    Parent.postData('openStore', { data: userStoreUrl, fn: 'openLink' });
  };

  useEffect(() => {
    /**
     * Get data from device storage and set to state
     */
    const getNextUpdateReminderTime = async () => {
      try {
        /**
         * Represent date of next update reminder
         */
        const resultNextUpdateReminderTime = await Storage.get(
          'nextUpdateReminderTime'
        );
        setNextUpdateReminderTime(resultNextUpdateReminderTime);
      } catch (err) {
        setNextUpdateReminderTime('');
      }
    };

    getNextUpdateReminderTime();
  }, []);

  useEffect(() => {
    /**
     * Get data from device storage and set to state
     */
    const getUpdateAlreadyReminderVersion = async () => {
      try {
        /**
         * Represent latest app version that has been reminded
         */
        const resultuUdateAlreadyRemindedVersion = await Storage.get(
          'updateAlreadyRemindedVersion'
        );
        setUpdateAlreadyRemindedVersion(resultuUdateAlreadyRemindedVersion);
      } catch (err) {
        setUpdateAlreadyRemindedVersion('');
      }
    };

    getUpdateAlreadyReminderVersion();
  }, []);

  useEffect(() => {
    // Access remote config and set update reminder rules
    if (userDevice && updateConfig) {
      // Check user device and roles config
      const isCorrectVersion =
        compareVersions.validate(userVersion) &&
        compareVersions.validate(currentVersion);

      /** Compare device version vs current version, if smaller than current version then run reminder checker */
      const isUserVersionNotLatest =
        isCorrectVersion &&
        compareVersions.compare(userVersion, currentVersion, '<');

      /** Find Mathed Rule based on device version */
      const matchedRule = (updateConfig?.updateRules || []).find((rule) => {
        /**
         * Check validity of version
         */
        const isCorrectVersionCompareGreaterThanEqualMin =
          compareVersions.validate(userVersion) &&
          compareVersions.validate(rule?.minVersion);

        /**
         * Compare between user app version and rule min version
         */
        const isGreaterThanEqualMin =
          isCorrectVersionCompareGreaterThanEqualMin &&
          compareVersions.compare(userVersion, rule?.minVersion, '>=');

        /**
         * Check validity of version
         */
        const isCorrectVersionCompareSmallerThanEqualMax =
          compareVersions.validate(userVersion) &&
          compareVersions.validate(rule?.maxVersion);

        /**
         * Compare between user app version and rule max version
         */
        const isSmallerThanEqualMax =
          isCorrectVersionCompareSmallerThanEqualMax &&
          compareVersions.compare(userVersion, rule?.maxVersion, '<=');

        return isGreaterThanEqualMin && isSmallerThanEqualMax;
      });

      /** Check if device version not latest and matched rule */
      if (isUserVersionNotLatest && matchedRule) {
        /** Check Already Reminded based on Matched Rule */
        const isReminded = checkAlreadyReminded(
          matchedRule?.type,
          nextUpdateReminderTime,
          updateAlreadyRemindedVersion,
          currentVersion
        );

        if (isUseAndroidInAppUpdate && !isReminded) {
          postData('showInAppUpdate', {
            fn: 'showInAppUpdate',
            data: {
              updateType: matchedRule?.type,
              interval: matchedRule?.interval ?? 0,
              remindedVersion: currentVersion,
            },
          });
        }

        if (!isUseAndroidInAppUpdate) {
          setAlreadyReminded(isReminded);
          setUserReminderType(matchedRule?.type);
        }

        /** Set Interval Only Matched type is PERIODIC */
        if (!isUseAndroidInAppUpdate && matchedRule?.type === 'PERIODIC') {
          setIntervalReminder(matchedRule?.interval ?? 0);
        }
      }

      // done collect all data
      setIsDoneCollectData(true);
    }
  }, [
    userDevice,
    updateConfig,
    nextUpdateReminderTime,
    updateAlreadyRemindedVersion,
    userVersion,
    currentVersion,
    isUseAndroidInAppUpdate,
  ]);

  return (
    <ModalContext.Provider
      value={{
        visible: !alreadyReminded,
        currentVersion,
        isForceUpdate,
        isDoneCollectData,
        handleCloseReminder,
        handleUpdateReminder,
        handleUpdateApp,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalUpdateReminder = () => {
  return useContext(ModalContext);
};

export default ModalUpdateReminderProvider;
