import { useContext } from 'react';
import { JagoFunctionContext } from 'features/bankjago/contexts/JagoProvider';

/** Hook to use bank jago function in context */
const useJagoContextFunction = () => {
  const context = useContext(JagoFunctionContext);
  if (context === undefined) {
    throw new Error(
      'useJagoContextFunction must be used within a JagoProvider'
    );
  }
  return context;
};

export default useJagoContextFunction;
