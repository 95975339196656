import * as React from 'react';
import { useDispatch } from 'react-redux';
import Storage from 'core/Storage';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';
import useRestoreInitialUser from 'hooks/useRestoreInitialUser';
import { restoreRobo } from 'entities/user.reducer';
import { useQueryClient } from 'react-query';
import { GET_PROFILE_QUERY_KEY } from 'hooks/useProfileData';
import { getRoboRecommendations } from './entities/robo.reducer';
import { setPortfolioCategoryData } from 'features/portfolio/portfolioPage.reducer';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import { AxiosResponse } from 'axios';
import { ProfileDataResponse } from 'features/profile/types';
import { getCurrentInstitution } from 'features/institution/utils';
import useSettingPreference from 'features/periodic-data-update/hooks/useSettingPreference';
import { useFetchPortfolio } from 'features/portfolio/networks';

/**
 * This container will load our app only when it is ready. It will render
 * splash screen while waiting for app state to be initialized / ready
 *
 * Logic for rendering ui on first booted
 * - fetch `/user/profile`
 * - after success fetch `/user/profile`, call `newRestoreInitialUser` > request `/user/preference/update`
 * - set `newFetchIsReady` > ui will be rendered
 * - after that we will fetch another data in onSuccess and data in child components
 */
export const AppLoader: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const dispatch = useDispatch<any>();
  const appContainer = React.useRef<HTMLDivElement | null>(null);
  const queryClient = useQueryClient();
  const { refetch: refetchSettingPreference } = useSettingPreference(false);

  // Local state
  const [profileAccessStatus, setProfileAccessStatus] = React.useState<
    'initial' | 'done'
  >('initial');

  // Access auth state for repetual usage
  const { isLogin, loginPromiseHasSettled } = useAuth();

  // Access restore initial user hooks
  const { restoreInitialUser: newRestoreInitialUser } = useRestoreInitialUser();

  // get institution account
  const institution = getCurrentInstitution();

  // get portfolio data when individual account loggedin
  const { data: portfolioData, isFetching: portfolioLoading } =
    useFetchPortfolio(isLogin && !institution);

  // checkpoint for set reducer portfolio
  const [alreadySetPortfolioData, setAlreadySetPortfolioData] =
    React.useState(false);

  // set reducer portfolio from react-query data
  // TODO: We can remove this effect after removing getPortfolioCategoryData and setPortfolioCategoryData
  React.useEffect(() => {
    if (
      alreadySetPortfolioData ||
      portfolioLoading ||
      !isLogin ||
      institution
    ) {
      return;
    }

    setAlreadySetPortfolioData(true);
    dispatch(setPortfolioCategoryData(portfolioData || {}));
  }, [
    alreadySetPortfolioData,
    dispatch,
    institution,
    isLogin,
    portfolioData,
    portfolioLoading,
  ]);

  /**
   * This is the non-login useEffect
   * need to wait for the Storage.getAccessToken promise to settle
   */
  React.useEffect(() => {
    if (loginPromiseHasSettled && !isLogin) {
      dispatch(restoreRobo());
    }
  }, [dispatch, isLogin, loginPromiseHasSettled]);

  /**
   * Fetch `/profile` data
   *
   * If profile data is available from react-query query
   * pass the data to restoreInitialUser so it doesn't refetch
   * also there are changes inside restoreInitialUser to adjust to this apprach
   */
  useProfileData(isLogin, {
    onSuccess: async (res: AxiosResponse<ProfileDataResponse>) => {
      /**
       * Make sure we execute restoreInitialUser function only on the first try.
       * Otherwise, we shall manually execute restoreInitialUser in component if needed.
       *
       * @note This also prevents multiple update being send while using usePreferenceMutation
       * @see - {@link usePreferenceMutation in features/profile/network }
       */
      if (profileAccessStatus !== 'initial') return;

      // New restoreInitialUser Function
      await newRestoreInitialUser(res);
      setProfileAccessStatus('done');

      refetchSettingPreference();

      // fetch data robo recomendation and porto only in Non Institution
      if (!institution) {
        // fetch another data after completed restore initial user and rendered ui
        dispatch(getRoboRecommendations());
      }
    },
    onError: async (err: any) => {
      const errorStatus = err?.response?.status;
      const errorType = err?.response?.data?.type;

      setProfileAccessStatus('done');

      // Handling error when type != 503
      if (errorStatus !== 503 && errorType !== 'user_suspended') {
        return dispatch(
          openErrorModal({
            type: 'FAILED_PROFILE',
            message: 'Gagal ambil profil data. Silakan coba beberapa saat lagi',
          })
        );
      }
    },
  });

  // Attach logged-in class to our app top container
  React.useEffect(() => {
    if (isLogin) {
      if (
        appContainer &&
        appContainer.current &&
        appContainer.current instanceof HTMLDivElement
      ) {
        appContainer.current.classList.add('logged-in');
      }
    } else {
      if (
        appContainer &&
        appContainer.current &&
        appContainer.current instanceof HTMLDivElement
      ) {
        appContainer.current.classList.remove('logged-in');
      }
    }
  }, [dispatch, isLogin]);

  // Reset Onboarding Modal State
  React.useEffect(() => {
    Storage.removeObject('userOnboarding');
  }, []);

  /**
   * This inject function into window object, to handle notif click
   * if notification type = 18 (notif tx finished CMIIW)
   * TODO: if there's any better place to put this kind of functions,
   * TODO: - feel free to suggest
   */
  React.useEffect(() => {
    (window.document as any).notifClick = function notifClick() {
      Storage.getAccessToken().then((token) => {
        if (token) {
          // we need to refetch profile data because stale time query is infinity
          queryClient.refetchQueries(GET_PROFILE_QUERY_KEY);
        }
      });
    };
  }, [queryClient]);

  return (
    <div className='App fullheight' ref={appContainer}>
      {children}
    </div>
  );
};

export default AppLoader;
