export type DataPaginationResponse<ResponseAPI = unknown> = ResponseAPI & {
  next_page: number;
};

export type FnRequest<T = unknown> = () => Promise<T>;

/**
 * Add `next_page` in response from API. we just increment `page` param with +1
 *
 */
export const requestInfinitePagination = async <ResponseAPI = unknown>(
  page: number,
  fnRequest: FnRequest<ResponseAPI>
): Promise<DataPaginationResponse<ResponseAPI>> => {
  const incrementPage = page + 1;

  try {
    const res = await fnRequest();
    const dataModify: DataPaginationResponse<ResponseAPI> = {
      next_page: incrementPage,
      ...res,
    };
    return dataModify;
  } catch (err) {
    throw err;
  }
};
