import { AxiosResponse } from 'axios';
import endpoints from './endpoints';
import { BibitApiSuccessResponse } from 'services';

import {
  BodyDeleteMember,
  BodyFindContact,
  BodyInviteMember,
  BodySharedPortfolioHistory,
  MemberList,
  SharedPortfolioInvitation,
  SharedPortfolioMemberContribution,
  SharedProtfolioMemberHistory,
  SharedProtfolioReason,
  BodyLeaveSharedPortfolio,
  BodySwitchAll,
  AvailablePortfolio,
} from '../types';
import http from 'core/http';

/**
 * POST: create shared portfolio
 *  * @param {number} categoryId
 */
export function postCreateSharedPortfolio(categoryId: number) {
  return http.post(endpoints.sharedPortfolioCreate(`${categoryId}`));
}

/**
 * POST: reverse shared portfolio
 *  * @param {number} sharedPortfolioId
 */
export function postReverseSharedPortfolio(sharedPortfolioId: number) {
  return http.post(endpoints.sharedPortfolioReverse(`${sharedPortfolioId}`));
}

/**
 * POST: get bibit contact list
 * @param {BodyFindContact} body
 */
export function postContactList(body: BodyFindContact) {
  return http.post(endpoints.findContact, body);
}

/**
 * POST: invite member shared portfolio
 * @param {BodyInviteMember} body
 * @param {number} sharedPortfolioId
 */
export function postInviteMember(body: BodyInviteMember) {
  return http.post(
    endpoints.sharedPortfolioInvite(`${body.shared_porto_id}`),
    body
  );
}

/**
 * POST: cancel invitation shared portfolio
 * @param {invitationId} number
 * @param {sharedPortfolioId} number
 */
export function postCancelInvitation(
  sharedPortfolioId: number,
  invitationId: number
) {
  return http.post(
    endpoints.sharedPortfolioCancelInvitation(
      `${sharedPortfolioId}`,
      `${invitationId}`
    )
  );
}

/**
 * POST: delete shared portfolio member
 * @param {BodyDeleteMember} body
 */
export function postDeleteMember(body: BodyDeleteMember) {
  return http.post(
    endpoints.sharedPortfolioDeleteMember(
      `${body.shared_porto_id}`,
      `${body.member_id}`
    ),
    body
  );
}

/**
 * POST: accept shared portfolio member
 * @param {invitationId} number
 * @param {sharedPortfolioId} number
 */
export function postAcceptInvitation(
  sharedPortfolioId: number,
  invitationId: number
) {
  return http.post(
    endpoints.sharedPortfolioAcceptInvitation(
      `${sharedPortfolioId}`,
      `${invitationId}`
    )
  );
}

/**
 * POST: reject shared portfolio member
 * @param {invitationId} number
 * @param {sharedPortfolioId} number
 */
export function postRejectInvitation(
  sharedPortfolioId: number,
  invitationId: number
) {
  return http.post(
    endpoints.sharedPortfolioRejectInvitation(
      `${sharedPortfolioId}`,
      `${invitationId}`
    )
  );
}

/**
 * GET: list member shared portfolio
 * @param {string} sharedPortfolioId
 */
export function getMemberList(
  sharedPortfolioId: string
): Promise<AxiosResponse<BibitApiSuccessResponse<MemberList>>> {
  return http.get(endpoints.sharedPortfolioMember(sharedPortfolioId));
}

/**
 * GET: list shared portfolio invitation
 */
export function getInvitation(): Promise<
  AxiosResponse<BibitApiSuccessResponse<SharedPortfolioInvitation[]>>
> {
  return http.get(endpoints.sharedPortfolioGetInvitation);
}

/**
 * GET: shared portfolio member contributions
 * @param {string} sharedPortfolioId
 */
export function getMemberContribution(
  sharedPortfolioId: string
): Promise<
  AxiosResponse<BibitApiSuccessResponse<SharedPortfolioMemberContribution>>
> {
  return http.get(endpoints.sharedPortfolioContribution(sharedPortfolioId));
}

/**
 * POST: shared portfolio member history
 * @param {BodySharedPortfolioHistory} body
 */
export function getSharedProtfolioHistory(
  sharedPortfolioId: string,
  body: BodySharedPortfolioHistory,
  cancelToken?: any
): Promise<
  AxiosResponse<BibitApiSuccessResponse<SharedProtfolioMemberHistory>>
> {
  return http.get(
    endpoints.sharedPortfolioHistory(sharedPortfolioId),
    { ...body },
    {
      cancelToken,
    }
  );
}

/**
 * GET: shared portfolio reason
 * @param {string} reason
 */
export function getSharedPortfolioReason(
  type: string
): Promise<AxiosResponse<BibitApiSuccessResponse<SharedProtfolioReason[]>>> {
  return http.get(endpoints.sharedPortfolioReason(type));
}

/**
 * POST: leave shared portfolio
 * @param {BodyLeaveSharedPortfolio} body
 */
export function postLeaveSharedProtfolio(body: BodyLeaveSharedPortfolio) {
  return http.post(
    endpoints.sharedPortfolioLeave(`${body.shared_porto_id}`),
    body
  );
}

/**
 * POST: leave shared portfolio
 * @param {BodySwitchAll} body
 */
export function sharedPortfolioSwitchAll(body: BodySwitchAll) {
  return http.post(
    endpoints.sharedPortfolioSwitchAll(`${body.shared_porto_id}`),
    body
  );
}

/**
 * List all portfolio available for switch-all shared portfolio
 * @param {string} sharedPortfolioId
 */
export function getAvailableSwitchAllPorto(
  sharedPortfolioId: string
): Promise<AxiosResponse<BibitApiSuccessResponse<AvailablePortfolio[]>>> {
  return http.get(endpoints.sharedPortfolioAvailablePorto(sharedPortfolioId));
}
