import React, { useContext, useState } from 'react';
import { BuySbnPreview } from 'features/sbn/type.d';

export type TopUpSbn =
  | 'input_amount'
  | 'confirm_topup'
  | 'portfolio_picker'
  | 'topup_tnc';
interface State {
  /** Determine step modal Top Up SBN */
  stepTopUpSbn?: TopUpSbn;
  /** What portfolio user choose */
  portfolioId?: number;
  /** product id that user want to topup */
  productId: string;
  /** tell us how much user want to topup */
  amount?: number;
  /** Data preview from API */
  dataPreview?: BuySbnPreview;
  /** To Show Modal, When buy SBN and pending,
   * waiting confirmation from KEMENKEU
   * */
  openPendingTimeout?: boolean;

  /** For show modal, when quota national is 0 or empty */
  openEmptyQuotaNational?: boolean;
}

interface FunctionState {
  setStepTopUpSbn: (step?: TopUpSbn) => void;
  setPortfolioId: (id?: number) => void;
  setProductId: (id: string) => void;
  setAmount: (amount?: number) => void;
  setDataPreview: (data?: BuySbnPreview) => void;
  setOpenPendingTimeout: (pendingTimeout: boolean) => void;
  setOpenEmptyQuotaNational: (emptyQuota: boolean) => void;
}

const TopUpSbnContextState = React.createContext<State | undefined>(undefined);

const TopUpSbnContextFunction = React.createContext<FunctionState | undefined>(
  undefined
);

const TopUpSbnContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [stepTopUpSbn, setStepTopUpSbn] = useState<TopUpSbn | undefined>();
  const [portfolioId, setPortfolioId] = useState<number | undefined>();
  const [productId, setProductId] = useState('');
  const [amount, setAmount] = useState<number | undefined>();
  const [dataPreview, setDataPreview] = useState<BuySbnPreview | undefined>(
    undefined
  );
  /** This State will be use, when user buy SBN,
   * to checking when kemenkeu approve the transaction
   */
  const [openPendingTimeout, setOpenPendingTimeout] = useState<
    boolean | undefined
  >();

  const [openEmptyQuotaNational, setOpenEmptyQuotaNational] = useState<
    boolean | undefined
  >();

  return (
    <TopUpSbnContextState.Provider
      value={{
        stepTopUpSbn,
        portfolioId,
        productId,
        amount,
        dataPreview,
        openPendingTimeout,
        openEmptyQuotaNational,
      }}
    >
      <TopUpSbnContextFunction.Provider
        value={{
          setStepTopUpSbn,
          setPortfolioId,
          setProductId,
          setAmount,
          setDataPreview,
          setOpenPendingTimeout,
          setOpenEmptyQuotaNational,
        }}
      >
        {children}
      </TopUpSbnContextFunction.Provider>
    </TopUpSbnContextState.Provider>
  );
};

export default TopUpSbnContextProvider;

export const useTopUpSbnState = () => {
  const ctx = useContext(TopUpSbnContextState);

  if (!ctx) {
    throw new Error('TopUpSbnContextState out of boundary');
  }

  return ctx;
};

export const useTopUpSbnFunctionState = () => {
  const ctx = useContext(TopUpSbnContextFunction);

  if (!ctx) {
    throw new Error('TopUpSbnContextFunction out of boundary');
  }

  return ctx;
};
