/**
 * @file
 *
 * When we hit perform request new token to Backend, in the next request would be store in pooling
 *
 * and after we success refresh token, we pull out all request from pooling to continue the request
 */

interface PendingReqest {
  // Callback function called when refresh-token is resolved
  (token: string): void;
}

/**
 * list of pending request XHR in the middle  refresh-token
 */
let pendingRequest: PendingReqest[] = [];

/**
 * list of pending postMessage in the middle of refresh-token
 */
let pendingPostMesasge: PendingReqest[] = [];

/**
 * determine is refresh token on the flight or not
 */
let loadingRefreshToken = false;

/**
 * updater `loadingRefreshToken`
 */
export const setLoadingRfToken = (loading: boolean) =>
  (loadingRefreshToken = loading);

/**
 * get value from `loadingRefreshToken`
 */
export const getLoadingRfToken = () => loadingRefreshToken;

/**
 * clear data array in `pendingRequest`
 */
export const clearPendingRequest = () => {
  pendingRequest = [];
};

/**
 * add item to `pendingRequest` properties
 */
export const addPendingRequest = (retry: PendingReqest) => {
  pendingRequest.push(retry);
};

/**
 * handling continue pending request after successful get new token
 */
export const continuePendingRequest = (token: string) => {
  pendingRequest.forEach((retry) => retry(token));
};

/**
 * add item to `pendingPostMesasge`
 */
export const addPendingPostMessage = (retry: PendingReqest) => {
  pendingPostMesasge.push(retry);
};

/**
 * clear data array in `pendingRequest`
 */
export const clearPendingPostMessage = () => {
  pendingPostMesasge = [];
};

/**
 * handling continue pending request after successful get new token
 */
export const continuePendingPostMessage = (token: string) => {
  pendingPostMesasge.forEach((retry) => retry(token));
};
