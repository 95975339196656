import React, { FC, MouseEvent, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonNew, Modal } from '@bibitid/uikit-v1';
import { Text } from '@bibitid/uikit-v1';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import { X } from 'react-feather';
import getSafely from 'utils/safely';
import { ReksaDana } from 'entities/reksadana.reducer';
import IconIdea from 'assets/images/categories/IconIdea';

interface Props {
  compareSelected: boolean;
  cancelCompare: () => void;
  comparedProducts: ReksaDana[];
  removeCompareProduct: (event: MouseEvent<HTMLOrSVGElement>) => void;
  confirmCompare: () => void;
}

const ModalCompareReksaStyled = styled(Modal)`
  position: fixed;
  z-index: 1000;
  min-height: unset;

  @media only screen and (min-width: 1030px) {
    & > .modal-content {
      max-height: 100%;
      box-shadow: 0px -12px 10px 0 rgba(0, 0, 0, 0.05);
    }
  }
  & > .modal-content {
    border: 1px solid var(--modal-border);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    position: relative;
  }
`;

const ModalCompareReksaDana: FC<React.PropsWithChildren<Props>> = ({
  compareSelected,
  cancelCompare,
  comparedProducts = [],
  removeCompareProduct,
  confirmCompare,
}) => {
  useEffect(() => {
    if (compareSelected) document.body.style.removeProperty('overflow');
  }, [compareSelected]);

  return (
    <ModalCompareReksaStyled
      className='bit-modal-compare modal-compare-radius desktop-page'
      visible={compareSelected}
      onClose={cancelCompare}
      showMask={false}
    >
      {comparedProducts.length < 2 && compareSelected && (
        <div
          className='bit-compare-attention'
          style={{ top: '-12px', position: 'relative' }}
        >
          <IconIdea
            width={14}
            height={14}
            color='var(--modal-violet-default)'
            className='compare-product-icon'
          />
          <Text type='caption1' htmlTag='p' color='modalVioletDefault'>
            Pilih minimum 2 produk untuk di bandingkan
          </Text>
        </div>
      )}

      <Modal.Header
        showClose={true}
        showBackButton={false}
        title={'Bandingkan Produk'}
      />
      <Modal.Body>
        <div className='bit-compare-modal-reksa'>
          <div className='bit-compare-holder'>
            <div
              className='bit-compare-wrapper'
              style={{ marginLeft: '0px', width: '100%' }}
            >
              {comparedProducts.length > 0 &&
                comparedProducts.map((item) => (
                  <div key={item.symbol}>
                    <X
                      size='15'
                      color='#D9D8D8'
                      data-symbol={item.symbol}
                      data-id={item.id}
                      className='bit-compare-close-show'
                      onClick={removeCompareProduct}
                    />
                    <div className='bit-compare-logo'>
                      <InvestmentCompanyLogo
                        ojkCode={getSafely(
                          ['investment_manager', 'ojkCode'],
                          item
                        )}
                      />
                    </div>
                    <Text htmlTag='p' className='bit-compare-item-name'>
                      {item.name}
                    </Text>
                  </div>
                ))}
              {comparedProducts.length < 3 &&
                [...Array(3 - comparedProducts.length)].map((_, index) => (
                  <div key={index}>
                    <X size='15' color='#D9D8D8' />
                    {/* add class "bit-compare-close-show" 
										inside this to show up the compare modal  */}
                    <div className='bit-compare-logo' />
                    <p>Pilih Reksadana</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={
          <ButtonNew
            content='Bandingkan'
            disabled={comparedProducts.length < 2}
            onClick={confirmCompare}
          />
        }
      />
    </ModalCompareReksaStyled>
  );
};

export default ModalCompareReksaDana;
