import { Button } from '@bibitid/uikit';
import { Text } from '@bibitid/uikit-v1';
import { useHistory } from 'react-router-dom';
import React from 'react';
import styles from './EmptyStatePortfolio.module.css';
import EmptyStatePortofolioIllustration from 'assets/images/portfolio/empty-state/EmptyStatePortofolioIllustration/EmptyStatePortofolioIllustration';
import GoalEmptyIllustration from 'assets/images/profile-asset/GoalEmptyIllustration/GoalEmptyIllustration';
import classNames from 'classnames';
import Analytics from 'utils/Analytics';
import { PortfolioAllocationData } from 'features/portfolio/types';

export interface EmptyStatePortfolioProps {
  title: string;
  description: string;
  to?: string;
  action?: () => void;
  imageType?: 'portfolio' | 'allocation';
  buttonText?: string;
  descriptionClassName?: string;
  prefixAnalytics?: 'RDPU' | 'RDO' | 'RDS' | 'Campuran' | 'Cash' | 'index';
  data?: PortfolioAllocationData;
}

const EmptyStatePortfolio = ({
  imageType = 'portfolio',
  description,
  to,
  action,
  title,
  buttonText = 'Investasi Sekarang',
  descriptionClassName = '',
  prefixAnalytics,
  data,
}: EmptyStatePortfolioProps) => {
  const history = useHistory();

  const image = () => {
    switch (imageType) {
      case 'portfolio':
        return <GoalEmptyIllustration />;
      default:
        return <EmptyStatePortofolioIllustration />;
    }
  };

  const handleClick = () => {
    Analytics.logEventAction({
      eventName: 'start_invest_action',
      parameter: {
        action: 'start_invest',
        context: 'portfolio.asset_allocation_group',
        trigger: 'click',
        data: {
          asset: prefixAnalytics,
        },
      },
    });

    if (data?.total_portfolio === 0 && prefixAnalytics === 'Cash') {
      Analytics.logEventAction({
        eventName: 'deposit_rdn_action',
        parameter: {
          action: 'deposit_rdn',
          context: 'portfolio.asset_allocation_group',
          trigger: 'click',
        },
      });
    }

    if (prefixAnalytics === 'index') {
      Analytics.logEventAction({
        eventName: 'topup_empty_action',
        parameter: {
          action: 'topup_empty_portfolio',
          context: 'portfolio.asset_allocation_group',
          trigger: 'click',
        },
      });
    }

    if (to) {
      return history.push(to);
    }
    if (action) {
      action();
    }
  };

  return (
    <div className={styles['empty-state-portfolio']}>
      <div className={styles['image']}>{image()}</div>
      <Text type='heading16b'>{title}</Text>
      <Text
        type='body1'
        className={classNames(styles['description'], descriptionClassName)}
      >
        {description}
      </Text>
      <Button onClick={handleClick} className={styles['button']}>
        {buttonText}
      </Button>
    </div>
  );
};

export default EmptyStatePortfolio;
