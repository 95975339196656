export const endpoints = {
  // Registration Page & Verification
  registerOtp: '/auth/register/otp',
  registerPhone: '/auth/register/phone',
  loginPhone: '/auth/login/phone',

  // Referral
  referralCheck: '/referral/check',

  // Account Registration
  registerSubmit: '/auth/register/submit?v=2',

  // Register with Stockbit data
  stockbitStats: '/auth/stockbit/stats',
  authStockbit: '/auth/stockbit',
  verifyStockbitData: '/auth/stockbit/verify',
  registerStockbit: '/auth/stockbit/register',
};
