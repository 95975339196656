import { LocaleProvider } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import '@bibitid/uikit/dist/bibitid-uikit.css';
import enUS from 'antd-mobile/lib/locale-provider/en_US';
import 'font-proxima-nova/style.css';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from 'utils/History';
import './App.css';
import ConnectedAppLoader from './AppLoader';
import './Bibit.css';
import './Component.css';

import ChatSupport from 'core/Crisp/ChatSupport';
import RootErrorBoundaries from './RootErrorBoundaries';
import { MainRoute } from './routes';

import store from './store/Store';
import queryClient from 'network/queryClient';
import { StatusBarContextProvider } from 'core/statusbar/context';
import { QueryClientProvider } from 'react-query';
import ThemeProvider from 'core/theme/ThemeProvider';
import GlobalContextProvider from 'context/GlobalContextProvider';
import PostMessageProvider from 'core/Parser/context/PostMessage.context';
import AuthProvider from 'context/AuthProvider';
import InAppNotificationsProvider from 'features/in-app-notifications/context/InAppNotificationsProvider.context';
import combineProviders from 'context/combineProviders';
import { PinProtection } from 'features/pin-protection';
import AppModals from 'features/common/modals/AppModals';
import { FeatureFlagProvider } from 'utils/feature-flag';

// Due recurring.css been using in several place such as:
// - ChangeBankWarningModal.tsx
// - SingleGiftCard.tsx
// - IndexPortoSwitch.tsx
// Please consider to refactor these component. Makesure they have dedicated css classname
import 'features/recurring/styles/recurring.css';
import RemoteConfigProvider from 'core/remoteConfig/context';
import { GoogleReCaptchaV3Provider } from 'features/common/GoogleCaptchaV3';

// Disable browser alert temporarily, due to crisp error
window.alert = function () {};

/**
 * Combine Provider
 */
const Providers = combineProviders([
  ThemeProvider,
  StatusBarContextProvider,
  PostMessageProvider,
  GoogleReCaptchaV3Provider,
  [QueryClientProvider, { client: queryClient }],
  [ReduxProvider, { store }],
  [Router, { history }],
  AuthProvider,
  FeatureFlagProvider,
  RemoteConfigProvider,
  GlobalContextProvider,
  ConnectedAppLoader,
  ChatSupport,
  PinProtection,
  // @ts-ignore
  [LocaleProvider, { locale: enUS }],
  InAppNotificationsProvider,
]);

/**
 * Representation of our app structure in general.
 *
 * Please don't add any complex logic at this component, only new component
 * render representing our app layer
 */
export function App() {
  return (
    <RootErrorBoundaries>
      <Providers>
        <div className='router'>
          <MainRoute />
          <AppModals />
        </div>
      </Providers>
    </RootErrorBoundaries>
  );
}

export default App;
