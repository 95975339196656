import React from 'react';
import { Redirect, RouteProps } from 'react-router';
import SentryRoute from '../SentryRoute';
import useAuth from 'hooks/useAuth';
import { PinProtectionRoute } from './components';
import withStatusBar from 'core/statusbar/hoc/withStatusBar';

interface Props extends RouteProps {
  component?: any;
  /** Set true if you want to show PIN immedietly if user go to some route */
  forceShowPin?: boolean;
  /** Props to determine whether to reset status bar on certain routes */
  defaultStatusBar?: boolean;
  /**
   * @deprecated Use hooks withoutTabBar instead
   * Props to determine whether to show or hide tabbar
   */
  showTabBar?: boolean;
}

/**
 * `PrivateRoute` Component is wrapper for `Route` Component which can only be accessed if the user is logged in.
 * If not login, the user will be redirect to `/login`
 */
const PrivateRoute: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { path, forceShowPin, component: Component, ...defaultProps } = props;
  const pathname = props.location?.pathname;
  const search = props.location?.search;
  const hash = props.location?.hash;
  const redirectPath = props.location ? `${pathname}${search}${hash}` : '';

  /**
   * Get User login status
   */
  const { isLogin } = useAuth();

  return (
    <SentryRoute
      {...defaultProps}
      render={(routerProps: any) => {
        return isLogin ? (
          <PinProtectionRoute forceShowPin={forceShowPin}>
            <Component {...routerProps} />
          </PinProtectionRoute>
        ) : (
          <Redirect
            to={{
              pathname: `/login`,
              state: {
                redirectPath,
              },
            }}
          />
        );
      }}
    />
  );
};

export default withStatusBar(PrivateRoute);
