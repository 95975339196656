import { isInsideNativeContainer } from 'utils/validator';
import { BANK_JAGO_ID, BANK_JAGO_SYARIAH_ID } from 'constants/common';
import { TransactionType } from 'features/topup-modal/topupmodal.type';
import { BankUser } from 'entities/bankAccounts.reducer';

/**
 * Determine if bankId is Bank Jago or Bank Jago Syariah
 * */
export const jagoOrJagoSyariahId = (bankId: string | number): boolean => {
  return [BANK_JAGO_ID, BANK_JAGO_SYARIAH_ID].includes(Number(bankId));
};

/**
 * Determine if Jago or Jago Syariah supports for Instant Redemption
 * */
export const isJagoInstantRedemptionAvailable = (bank: BankUser): boolean => {
  const isBankJagoOrJagoSyariah = jagoOrJagoSyariahId(bank?.bank_id ?? '');
  return isBankJagoOrJagoSyariah && !bank?.is_rdn;
};

export const isBankJagoNativeSupported = (): boolean => {
  const isSupported =
    window.document.isBankJagoNativeSupported ||
    window.isBankJagoNativeSupported;
  return !!isSupported;
};

/**
 * For cart transactions, we need to also validate if PM is
 * supported by native
 */
export const isBankJagoPaymentMethodSupported = (
  transactionType: TransactionType
): boolean => {
  const isSupported =
    window.document.isBankJagoNativeSupported ||
    window.isBankJagoNativeSupported;
  if (transactionType !== 'cart') {
    return !!isSupported;
  }

  const isCartSupported =
    window.document.isBankJagoCartV2Supported ||
    window.isBankJagoCartV2Supported;
  return !!isSupported && !!isCartSupported;
};

/**
 * Paying gift card with bank jago is supported or not
 * Only support when it's on native and supported by bank jago (isGiftCardJagoSupported)
 * @returns boolean
 */
export const isGiftCardJagoSupported = (): boolean => {
  const isSupported =
    window.document.isGiftCardJagoSupported || window.isGiftCardJagoSupported;
  return !!isSupported;
};

/**
 * Handle Bank Jago Recurring Content (partial or full).
 * Partial = Jago Autodebet recurring only show in `Nabung Rutin` pages.
 * Full = Jago Autodebet recurring show in `Nabung Rutin` & order process
 */
export const isBankJagoRecurringPartial = (): boolean => {
  const isJagoRecurringPartial =
    window.document.isBankJagoRecurringPartial ||
    window.isBankJagoRecurringPartial;
  return !!isJagoRecurringPartial;
};

/**
 * Handle bank jago race condition when success create order
 * but process from jago side not finished yet
 */
export const jagoOrderConsideredFinished = (order: any): boolean => {
  if (!order) return false;
  const preRequisite =
    order?.payment?.payment_method === 'jago' &&
    order?.payment?.payment_jago_recurring_status === 'on_process' &&
    !order?.autodebet_status &&
    !order?.suggest_create_recurring;
  if (order?.portfolios && Array.isArray(order.portfolios)) {
    return (
      preRequisite &&
      order?.portfolios
        .map((porto: any) => {
          return porto.items.some(
            (item: any) => item?.status === 'wait_payment'
          );
        })
        .some((item: any) => item)
    );
  }
  return (
    preRequisite &&
    (order?.status === 'wait_payment' ||
      (order?.robogroup &&
        order?.items.some((item: any) => item?.status === 'wait_payment')))
  );
};

/**
 * To show bank jago content,
 * will appear if bibit webview is accessed from a native
 * that is already supported by Bank Jago feature
 * @param {boolean | undefined} visible Additional visible state from consumer
 * @returns boolean
 */
export const isBankJagoContentVisible = (visible?: boolean): boolean => {
  /**
   * set additional visible param `true` if undefined
   */
  const additionalVisible = typeof visible === 'undefined' ? true : visible;

  return (
    isBankJagoNativeSupported() &&
    isInsideNativeContainer() &&
    additionalVisible
  );
};

/**
 * A flag to determine user can instant redemption or not
 * by detect mobile app version of user
 * older app version and web get false boolean
 * @returns boolean
 */
export const isSupportJagoInstantRedemption = () => {
  return !!window.isSupportJagoInstantRedemption || false;
};

/**
 * A flag to determine user's device supports instant redemption
 * Native only
 * @returns boolean
 */
export const isNativeSupportJagoInstantRedemption = () => {
  return isSupportJagoInstantRedemption() && isBankJagoContentVisible();
};

/**
 * Normalize Raw data to Picker data
 */
export const convertToPickerData = (data: any = []): any => {
  return data.map((item: any) => ({
    label: item?.name,
    value: item.id,
  }));
};
