import React from 'react';
import { ChevronLeft } from 'react-feather';
import { Button } from '@bibitid/uikit';
import { Props } from 'features/common/PinForm/types';
import { useHistory } from 'react-router-dom';
import IconSupport from 'features/common/IconSupport';
import ContentBox from 'features/common/ContentBox';
import useProfileData from 'hooks/useProfileData';
import useAuth from 'hooks/useAuth';
import { useNativeIdleTimerToggle } from 'hooks';
import { Text, TopBar } from '@bibitid/uikit-v1';
import PinLockIllustration from '../../assets/PinLockIllustration/PinLockIllustration';

/**
 * Showing when PIN user get banned.
 */
const PinExceed: React.FC<React.PropsWithChildren<Props>> = ({
  onBack,
  renderOnBack,
  callbackForgotPin,
  onClickFogetPin,
}) => {
  const history = useHistory();

  // Get data profile user
  const { isLogin } = useAuth();
  const { data } = useProfileData(isLogin);
  const userDetail = data?.data?.data;

  const email: string | undefined = userDetail?.user?.email;

  const handleClickChangePin = () => {
    if (onClickFogetPin) {
      onClickFogetPin();
      return;
    }

    if (callbackForgotPin) {
      callbackForgotPin();
      return;
    }

    history.push('/profile/settings/pin');
  };

  useNativeIdleTimerToggle();

  return (
    <div className='content-wrapper'>
      <div className='content-header'>
        <TopBar
          leftContent={
            renderOnBack && (
              <div
                className='backBigger'
                onClick={() => (!!onBack ? onBack() : history.goBack())}
              >
                <ChevronLeft color='var(--icon-default)' size={20} />
              </div>
            )
          }
          rightContent={<IconSupport size={14} />}
        />
      </div>

      <ContentBox headerHeight={45} style={{ padding: '0px' }}>
        <div className='bit-pin-exceed'>
          <div>
            <div className='login-register-icon'>
              <PinLockIllustration alt='icon secure' />
            </div>
            <Text type='body1'>
              Kamu telah melampaui batas maksimal <br />
              percobaan pin
            </Text>

            {!email && (
              <div>
                <br /> Untuk mengubah pin, silakan masuk melalui aplikasi Bibit
              </div>
            )}

            {!!email && (
              <Button
                onClick={handleClickChangePin}
                className='rounded-btn bit-recurring-btn am-button am-button-primary'
              >
                Ubah Pin
              </Button>
            )}
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export default PinExceed;
