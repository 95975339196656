import { AxiosResponse } from 'axios';
import { endpoints } from './index';
import { VerificationTimeline } from '../types';
import http from 'core/http';
import { UserBankList } from 'features/changebank/hooks';
import { RestAPICore } from 'core/http/http.type';

type GetVerificationResponse = {
  message: string;
  data: VerificationTimeline;
};

type PostUpgradeBibitPlusResponse = {
  message: string;
  data: {
    is_finished?: boolean;
  };
};

/**
 * get current verification status
 */
export const getVerificationStatus = (): Promise<
  AxiosResponse<GetVerificationResponse>
> => {
  return http.get(endpoints.getVerificationStatus);
};

export const postUpgradeBibitPlus = (deviceId: string) => {
  const body = {
    device_id: deviceId,
  };

  return http.post<PostUpgradeBibitPlusResponse>(
    endpoints.postUpgradeBibitPlus,
    body
  );
};

export const getBcaBankList = (): Promise<
  AxiosResponse<RestAPICore<UserBankList[]>>
> => {
  return http.get(endpoints.bcaBankList);
};

export const initiateBibitPlus = () => {
  return http.post(endpoints.initiateBibitPlus);
};

export const updateUserSelectionRdn = (bank_id: 31 | 72) => {
  return http.patch(endpoints.updateUserSelectionRdn, {
    bank_id,
  });
};

interface BankRdnPayload {
  bank_slot?: number;
  bank_account_name?: string;
  bank_account_number?: string;
}

export const submitRdn = (payload: BankRdnPayload) => {
  return http.patch(endpoints.submitRdn, payload);
};
