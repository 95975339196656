import { generalLogInfo } from 'utils/Analytics';
import { getInstance } from '../CallbackMapper';

import type { Mapper } from '../CallbackMapper';
import type { LogMessageType, MessagePayloadType } from '../types';

interface CustomMessageEvent extends Event {
  detail?: string;
}

const callbackMapper: Mapper = getInstance();

/**
 * onMessage handler to execute callback in callback-mapper instance
 *
 * common steps:
 * - filter message response
 * - use key - callback mapper to retrieve target callback
 * - execute callback
 *
 * @param {CustomMessageEvent} resp object custom MessageEvent from Native postMessage
 */
export function onMessageReceiver(resp: CustomMessageEvent) {
  const { detail } = resp;

  // prevent parse when detail is not string
  if (typeof detail !== 'string') return detail;

  try {
    let dataobj: MessagePayloadType = JSON.parse(detail);

    let { id, data } = dataobj;

    const cb: Function = callbackMapper.get(id);

    cb(null, data);

    callbackMapper.remove(id); // remove function from stackFunction
  } catch (error) {
    const extraData: LogMessageType = {
      file: 'Receiver.v3.js',
      function: 'onMessageReceiver',
      version: 'v1',
      data: detail,
      error: error && error.toString && error.toString(),
    };

    generalLogInfo('JSON.parse catch', extraData);
  }
}

/**
 * IMPORTANT, EXECUTE IN APP BOOTING !
 * Start listener Native through 'Window' and 'Document'
 */
export function start() {
  /**
   * Attach listener
   */
  window.addEventListener('bibitnativebridge', onMessageReceiver);
}

export function removeStart() {
  /**
   * Remove listener
   */
  window.removeEventListener('bibitnativebridge', onMessageReceiver);
}
