const acceptValue: Array<'dark' | 'light'> = ['dark', 'light'];

/**
 * Get value theme from localStorage `bibit-theme`
 */
export const getTheme = (): 'dark' | 'light' => {
  const themeStorage = window.localStorage.getItem('bibit-theme') as
    | null
    | 'dark'
    | 'light';

  if (!themeStorage) return 'light';

  if (acceptValue.includes(themeStorage)) return themeStorage;

  return 'light';
};
