import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useJagoRegistrationValidation } from 'features/bankjago/contexts/JagoUpdateOSModalProvider';

const BankJagoUpdateOS = React.lazy(() => import('./BankJagoUpdateOS'));

const BankJagoUpdateOSLazy = (props: any) => {
  const { updateOSModalVisible } = useJagoRegistrationValidation();

  return (
    <LazyModal
      component={BankJagoUpdateOS}
      visible={updateOSModalVisible}
      {...props}
    />
  );
};

export default BankJagoUpdateOSLazy;
