import React, { createContext, useEffect } from 'react';
import {
  ConnectType,
  BannerTimelineStatus,
  JagoStatus,
} from 'features/bankjago/types';
import { taskGetIsWebview } from 'utils/is-webview';
import Storage from 'core/Storage';
import { isJSON } from 'utils/validator';
import { useFlag } from 'utils/feature-flag';
import { getBankJagoRegisterStatus } from '../networks/services';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';
import { postUserUpdatePreference } from 'features/profile/network';
import { addDays, isPast, parse } from 'date-fns';

type JagoStatusProvider = JagoStatus | '';
export interface JagoStateType {
  /** Linking Status Bibit x Jago */
  linkingStatus: JagoStatusProvider;

  /** TODO: Change this variable, isMainBank true --> unbank true */
  isMainBank: boolean;

  /** Loading when get getJagoStatus post message*/
  loading: boolean;

  /** Linking error message */
  linkingStatusError: string;

  /** Modal Jago Connect Prompt Visibility Status */
  jagoModalConnectVisible: boolean;

  /** Connect Type to show different modal text */
  connectType: ConnectType;

  /** User Bibit has been registered AND verified by Bibit kyc */
  userHasBeenVerifiedBibit: boolean;

  /** User Bibit has registered bibit kyc but NOT verified yet*/
  userHasBeenRegisteredBibit: boolean;

  /** Show jago home banner */
  homeBannerVisible: boolean;

  /** Step Jago registration status (completed_form, vkyc) */
  bannerTimelineStatus: BannerTimelineStatus | null;

  /** A boolean value by post message flag value to solve race condition */
  registrationFinished: boolean;

  /** Is already register Jago ?*/
  isAlreadyRegistered: boolean;

  /** Feature Flag of Jago linking */
  jagoConnectVisibleByFF: boolean;

  /** Feature Flag of Jago Coregistration */
  jagoCoregistVisibleByFF: boolean;

  /** Indicate which jago type
   * 1 = conven
   * 2 = syariah
   */
  jagoType?: 1 | 2;

  /** Indicate if jago account is still active */
  isRegistrationBlocked: boolean;

  /** Indicate if user should go to Jago Webview
   * before proceed to upgrade bibit plus
   */
  isRequireRelinking: boolean;

  /**
   * datetime | only show when jago status: 3
   */
  verifiedAt: string;

  /**
   * Indicate jago account is active
   */
  isAccountActive: boolean;

  /**
   * status show / hide jago banner from user preference
   */
  showJagoBannerFromPreference: boolean;
}

export interface JagoFunctionType {
  /** TODO: Change this variable, isMainBank true --> unbank true */
  setMainBank: (isMainBank: boolean) => void;

  /** Get Bibit - Bank Jago linking status */
  getJagoLinkingStatus: () => Promise<void>;

  /** Handle Toggle Jago Connect Modal */
  toggleJagoConnectModal: (connectType?: ConnectType) => void;

  /** Hide Banner and Save to Local Storage*/
  handleCloseHomeBanner: () => Promise<void>;

  /** Update value of registration status from post message flag */
  setRegistrationFinished: (registrationFinished: boolean) => void;
}

export const JagoStateContext = createContext<JagoStateType | undefined>(
  undefined
);

export const JagoFunctionContext = createContext<JagoFunctionType | undefined>(
  undefined
);

/** Bank Jago Context Provider */
const BankJagoProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);

  /**
   * These are states needed by our Jago banner in home
   */
  const [loading, setLoading] = React.useState(false);
  const [isMainBank, setMainBank] = React.useState(false);
  const [linkingStatus, setRegistrationStatus] =
    React.useState<JagoStatusProvider>('');
  const [bannerTimelineStatus, setBannerTimelineStatus] =
    React.useState<BannerTimelineStatus | null>(null);
  const [linkingStatusError, setLinkingStatusError] = React.useState('');
  const [bannerVisible, setHomeBannerVisible] = React.useState<boolean>(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] =
    React.useState<boolean>(false);
  const [jagoType, setJagoType] = React.useState<1 | 2 | undefined>();
  const [isRegistrationBlocked, setIsRegistrationBlocked] =
    React.useState(false);
  const [isRequireRelinking, setIsRequireRelinking] = React.useState(false);

  const [jagoModalConnectVisible, setJagoModalConnectVisible] =
    React.useState<boolean>(false);

  const [registrationFinished, setRegistrationFinished] =
    React.useState<boolean>(false);

  const [connectType, setConnectType] = React.useState<ConnectType>('default');

  /**
   * datetime | only show when jago status: 3
   */
  const [verifiedAt, setVerifiedAt] = React.useState<string>('');

  /**
   * if account active -> true, else false
   */
  const [isAccountActive, setIsAccountActive] = React.useState<boolean>(false);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  const showJagoBannerFromPreference =
    profileDetail?.preference?.show_jago_banner === 1;

  /**
   * Bibit Email Verification Status
   */
  const usersEmailIsVerified: boolean =
    Boolean(profileDetail?.validemail) ?? false;

  /**
   * Bibit Registration Status
   * 0 = User baru daftar nomor telepon
   * 1 = User submit KYC
   * 2 = KYC sedang di proses BO (data KYC uda ditandain BO)
   * 3 = Import ke S-Invest
   * 4 = Di-approve S-Invest / active
   * 9/10 = rejected
   */
  const usersRegistrationStatus: number = profileDetail?.active ?? 0;

  /**
   * Bibit User ID
   */
  const userId: number = profileDetail?.user?.id ?? 0;

  /**
   * User Bibit has been registered AND verified by Bibit kyc
   */
  const userHasBeenVerifiedBibit: boolean =
    usersEmailIsVerified && usersRegistrationStatus === 4;

  /**
   * User Bibit has registered bibit kyc (ACTIVE = 1 or 2 or 3)
   * But NOT verified yet
   */
  const userHasBeenRegisteredBibit: boolean = [1, 2, 3].includes(
    usersRegistrationStatus
  );

  /**
   * List of jago status (Handle to hide depend on feature flag)
   */
  const jagoOnProcessStatus = [
    'NOT_CONNECTED',
    'ON_REGISTRATION',
    'LINKING_PROCESS',
    'REJECTED',
    '',
  ].includes(linkingStatus);

  const jagoConnectFlag = useFlag('web_jago_linking');
  const jagoCoregistFlag = useFlag('web_jago_coregist');
  /**
   * jago connect visible by feature flag
   */
  const jagoConnectVisibleByFF = jagoOnProcessStatus ? jagoConnectFlag : true;
  /**
   * jago coregist visible by feature flag
   */
  const jagoCoregistVisibleByFF = jagoOnProcessStatus ? jagoCoregistFlag : true;

  /**
   * Handle Hide Banner and Save to Local Storage
   */
  const handleCloseHomeBanner = async () => {
    await Storage.set(
      'hiddenJagoHomeBanner',
      JSON.stringify({
        linkingStatus,
        hasCompleteRegistrationBibit: userHasBeenVerifiedBibit,
        userId,
      })
    );

    await postUserUpdatePreference({ show_jago_banner: 0 });

    setHomeBannerVisible(false);
  };

  const toggleJagoConnectModal = (connectType: ConnectType = 'default') => {
    setConnectType(connectType);
    setJagoModalConnectVisible(!jagoModalConnectVisible);
  };

  /**
   * Get Bibit - Bank Jago linking status
   */
  const getJagoLinkingStatus = React.useCallback(async () => {
    const isWebView = await taskGetIsWebview();

    /**
     * `hiddenJagoHomeBanner` data
     */
    if (isWebView) {
      try {
        setLinkingStatusError('');
        setLoading(true);

        const response = await getBankJagoRegisterStatus();

        const status = response.data?.data?.status;
        const timeline = response.data?.data?.timeline;
        const mainBank = response.data?.data?.main_bank_on_regis;
        const alreadyRegistered = response.data?.data?.already_register;
        const customerType = response.data?.data?.customer_type;
        const isBlocked = !!response.data?.data?.is_registration_blocked;
        const shouldRelink = !!response.data?.data?.is_require_relinking;
        const accountVerifiedAt = response.data?.data?.verified_at || '';
        const accountIsActive = response.data?.data?.is_account_active || false;

        setJagoType(customerType);
        setIsRegistrationBlocked(isBlocked);
        setIsRequireRelinking(shouldRelink);
        setVerifiedAt(accountVerifiedAt);
        setIsAccountActive(accountIsActive);

        if (status) {
          setRegistrationStatus(status);
        }

        if (mainBank) {
          setMainBank(mainBank);
        }

        if (timeline) {
          setBannerTimelineStatus(timeline);
        }

        setIsAlreadyRegistered(!!alreadyRegistered);
      } catch (error) {
        const message = (error as Error).message;
        setLinkingStatusError(message);
      } finally {
        setLoading(false);
      }
    }
  }, [setRegistrationStatus]);

  /**
   * Calling getJagoLinkingStatus immedietly when App having userId
   */
  useEffect(() => {
    if (!userId) return;

    getJagoLinkingStatus();
  }, [getJagoLinkingStatus, userId]);

  /**
   * Check if user has ever closed this banner. Never show this banner again
   * if users have closed this banner.
   */
  useEffect(() => {
    /**
     * Get Data Banner From Local Storage
     */
    const getBannerVisible = async () => {
      let isHideBanner = !showJagoBannerFromPreference;

      try {
        /**
         * Get Data `hiddenJagoHomeBanner` from local storage
         */
        const data = await Storage.get('hiddenJagoHomeBanner');

        if (linkingStatus === 'NOT_CONNECTED' && isAccountActive) {
          /**
           * `hiddenJagoHomeBanner` data
           */
          const hiddenJagoHomeBannerData = isJSON(data)
            ? JSON.parse(data)
            : data;

          isHideBanner =
            hiddenJagoHomeBannerData?.linkingStatus === 'NOT_CONNECTED' &&
            hiddenJagoHomeBannerData?.hasCompleteRegistrationBibit ===
              userHasBeenVerifiedBibit;
        }

        if (linkingStatus === 'ACTIVE' && !!verifiedAt.length) {
          try {
            // formated_date(yyyy-MM-dd HH:mm:ss) date format not working on safari
            // to handle this issue parsed use date-fns
            const verifiedDate = parse(
              verifiedAt,
              'yyyy-MM-dd HH:mm:ss',
              new Date()
            );

            const showBannerUntil = addDays(verifiedDate, 1);
            if (isPast(showBannerUntil)) {
              isHideBanner = true;
            }
          } catch {}
        }
      } catch (error) {
        console.error(error);
      }

      /**
       * Set State after get data banner from local storage
       */
      setHomeBannerVisible(!isHideBanner);
    };

    /**
     * Check Data Banner from Local Storage
     */
    getBannerVisible();
  }, [
    linkingStatus,
    userHasBeenVerifiedBibit,
    isAccountActive,
    verifiedAt,
    showJagoBannerFromPreference,
  ]);

  return (
    <JagoStateContext.Provider
      value={{
        linkingStatus,
        isMainBank,
        loading,
        linkingStatusError,
        jagoModalConnectVisible,
        connectType,
        userHasBeenVerifiedBibit,
        userHasBeenRegisteredBibit,
        homeBannerVisible: bannerVisible,
        bannerTimelineStatus,
        registrationFinished,
        isAlreadyRegistered,
        jagoConnectVisibleByFF,
        jagoCoregistVisibleByFF,
        jagoType,
        isRegistrationBlocked,
        isRequireRelinking,
        verifiedAt,
        isAccountActive,
        showJagoBannerFromPreference,
      }}
    >
      <JagoFunctionContext.Provider
        value={{
          setMainBank,
          getJagoLinkingStatus,
          toggleJagoConnectModal,
          handleCloseHomeBanner,
          setRegistrationFinished,
        }}
      >
        {children}
      </JagoFunctionContext.Provider>
    </JagoStateContext.Provider>
  );
};

export default BankJagoProvider;
