import { serializeParam } from 'core/http/utils';
import { useUserInInstitution } from 'features/institution/hooks';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryParams } from 'utils/routeHelper';
import {
  BANK_ID_PARAM_KEY,
  IM_PARAM_KEY,
  IS_INSTANT_REDEMPTION_ENUM,
  IS_INSTANT_REDEMPTION_PARAM_KEY,
  IS_TOP_COMPANY_PARAM_KEY,
  IS_SYARIAH_ENUM,
  IS_SYARIAH_PARAM_KEY,
  MAX_BUY_PARAM_KEY,
  META_PARAM_KEY,
  SORT_BY_ENUM,
  SORT_BY_PARAM_KEY,
  SORT_ORDER_ENUM,
  SORT_ORDER_PARAM_KEY,
  SORT_PERIOD_ENUM,
  SORT_PERIOD_PARAM_KEY,
} from '../constants';
import useFilterProduct from '../hooks/useFilterProduct';
import {
  onApplyParams,
  ReksadanaProductFunction,
  ReksadanaProductState,
  ReksadanaProviderProps,
} from '../types';
import isEmpty from 'utils/isEmpty';

/**
 * Initiate Context State
 */
const ReksadanaProductContextState = createContext<
  undefined | ReksadanaProductState
>(undefined);

/**
 * Initiate Context State-Function
 */
const ReksadanaProductContextFunction = createContext<
  undefined | ReksadanaProductFunction
>(undefined);

/**
 * Context Provider for ReksadanaProduct
 *
 * Contain state and state-function that `set` value of state.
 */
const ReksadanaProductProvider: React.FC<ReksadanaProviderProps> = ({
  children,
  pageType,
  defaultValue,
}) => {
  const {
    filterType: defaultType = [],
    filterCurrency: defaultCurrency = 'all',
    filterSortOrder: defaultSortOrder,
    filterSortBy: defaultSortBy,
    filterSortPeriod: defaultSortPeriod,
    filterIsInstantRedemption: defaultIsInstantRedemption,
    filterBankId: defaultBankId,
    filterIsSyariah: defaultIsSyariah,
    filterMeta: defaultMeta = '',
    filterIm: defaultIm = '',
    filterMaxBuy: defaultMaxBuy = 0,
    filterFixedParams: defaultFixedParams = {},
    filterFixedParamsShown: defaultFixedParamsShown = {},
    filterIsTopCompany: defaultIsTopCompany,
  } = defaultValue ?? {};

  const query = useQueryParams();

  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);
  const profileDetail = dataProfile?.data?.data;
  const syariahUser = profileDetail?.preference?.syariah ?? 0;
  const isUserSyariah = syariahUser === 1;

  const isInstitution = useUserInInstitution();

  const SORT_BY_DEFAULT = pageType === 'darurat' ? 8 : 2;
  const SORT_PERIOD_DEFAULT = '1y';
  const SORT_ORDER_DEFAULT = 'desc';

  const IS_INSTANT_REDEMPTION_DEFAULT = false;
  const IS_TOP_COMPANY_DEFAULT = false;
  const IS_SYARIAH_DEFAULT = undefined;

  const QUERY_LIMIT_DEFAULT = 20;

  /**
   * Extra params from page type and user type to be included in query
   */
  const extraParams: Record<string, any> = {
    ...(pageType === 'watchlist' ? { is_favorited: 1 } : {}),
    ...(syariahUser && !isInstitution ? { syariah: 1 } : {}),
  };

  /**
   * Extra params to be included in query and shown in url query string
   */
  const extraParamsShownOnURL = {};

  const [filterIsSorting, setFilterIsSorting] = useState(false);
  const [filterIsFiltering, setFilterIsFiltering] = useState(false);

  const [filterType, setFilterType] = useState<Array<number>>(defaultType);
  const [filterCurrency, setFilterCurrency] = useState(defaultCurrency);

  const [filterSortBy, setFilterSortBy] = useState(
    defaultSortBy || SORT_BY_DEFAULT
  );
  const [filterSortOrder, setFilterSortOrder] = useState(
    defaultSortOrder || SORT_ORDER_DEFAULT
  );
  const [filterSortPeriod, setFilterSortPeriod] = useState(
    defaultSortPeriod || SORT_PERIOD_DEFAULT
  );

  const [filterIsInstantRedemption, setFilterIsInstantRedemption] = useState(
    defaultIsInstantRedemption ?? IS_INSTANT_REDEMPTION_DEFAULT
  );
  const [filterIsTopCompany, setFilterIsTopCompany] = useState<boolean>(
    defaultIsTopCompany ?? IS_TOP_COMPANY_DEFAULT
  );
  const [filterMeta, setFilterMeta] = useState(defaultMeta);
  const [filterIm, setFilterIm] = useState(defaultIm);
  const [filterMaxBuy, setFilterMaxBuy] = useState(defaultMaxBuy);
  const [filterIsSyariah, setFilterIsSyariah] = useState<boolean | undefined>(
    defaultIsSyariah ?? IS_SYARIAH_DEFAULT
  );
  const [filterBankId, setFilterBankId] = useState<number | undefined>(
    defaultBankId
  );

  const [filterFixedParams, setFilterFixedParams] =
    useState<Record<string, any>>(defaultFixedParams);
  const [filterFixedParamsShown, setFilterFixedParamsShown] = useState<
    Record<string, any>
  >(defaultFixedParamsShown);

  const [filterQueryLimit, setFilterQueryLimit] =
    useState<number>(QUERY_LIMIT_DEFAULT);

  const [comparedProducts, setComparedProducts] = useState<Array<string>>([]);

  const sortByQuery = query.get(SORT_BY_PARAM_KEY);
  const sortPeriodQuery = query.get(SORT_PERIOD_PARAM_KEY);
  const sortOrderQuery = query.get(SORT_ORDER_PARAM_KEY);

  const isInstantRedemptionQuery = query.get(IS_INSTANT_REDEMPTION_PARAM_KEY);
  const isTopCompanyQuery = query.get(IS_TOP_COMPANY_PARAM_KEY);
  const bankIdQuery = query.get(BANK_ID_PARAM_KEY);
  const isSyariahQuery = query.get(IS_SYARIAH_PARAM_KEY);
  const metaQuery = query.get(META_PARAM_KEY);
  const imQuery = query.get(IM_PARAM_KEY);
  const maxBuyQuery = query.get(MAX_BUY_PARAM_KEY);

  const enabledFetchFilterProduct =
    filterType?.length > 0 ||
    filterMeta !== '' ||
    filterCurrency === 'usd' ||
    filterIsTopCompany ||
    !!filterIsInstantRedemption ||
    !!filterIsSyariah ||
    !!filterIm ||
    pageType === 'watchlist' ||
    (!isEmpty(filterFixedParams) &&
      // check type on fixed params
      (filterFixedParams?.type?.length > 0 ||
        // check meta on fixed params
        filterFixedParams?.meta !== '' ||
        // check currency on fixed params
        filterFixedParams?.currency === 'usd' ||
        // check instant redemption on fixed params
        !!filterFixedParams?.is_instant_redemption ||
        // check syariah on fixed params
        !!filterFixedParams?.syariah ||
        // check im name on fixed params
        !!filterFixedParams?.im)) ||
    (!isEmpty(filterFixedParamsShown) && !!filterFixedParamsShown?.im);

  /** Get filtered product query */
  const productQuery = useFilterProduct(
    {
      type: filterType.join(','),
      tradable: 1,
      limit: filterQueryLimit,
      sort: filterSortOrder,
      currency: filterCurrency,
      // if syariah user, syariah always on from extraparams
      ...(typeof filterIsSyariah !== 'undefined' &&
      (!syariahUser || isInstitution)
        ? { syariah: filterIsSyariah ? 1 : 0 }
        : {}),
      ...(!!filterIsTopCompany ? { is_top_product: 1 } : {}),
      ...(!!filterSortBy ? { sort_by: filterSortBy } : {}),
      ...(!!filterSortPeriod ? { sort_period: filterSortPeriod } : {}),
      ...(!!filterIsInstantRedemption ? { is_instant_redemption: 1 } : {}),
      ...(!!filterMeta ? { meta: filterMeta } : {}),
      ...(!!filterIm ? { im: filterIm } : {}),
      ...(filterMaxBuy > 0 ? { max_buy: filterMaxBuy } : {}),
      ...(!!filterBankId ? { bank_id: filterBankId } : {}),
      // extra filter params for some pages
      ...extraParams,
      ...extraParamsShownOnURL,
      // fixed filter params for some pages
      ...filterFixedParams,
      ...filterFixedParamsShown,
    },
    enabledFetchFilterProduct
  );

  /**
   * Reset filter and sorting state
   */
  const resetAllState = useCallback(() => {
    setFilterType([]);
    setFilterCurrency('all');

    setFilterSortBy(SORT_BY_DEFAULT);
    setFilterSortOrder(SORT_ORDER_DEFAULT);
    setFilterSortPeriod(SORT_PERIOD_DEFAULT);

    setFilterIsInstantRedemption(IS_INSTANT_REDEMPTION_DEFAULT);
    setFilterMeta('');
    setFilterIm('');
    setFilterMaxBuy(0);
    setFilterBankId(undefined);
    setFilterIsSyariah(IS_SYARIAH_DEFAULT);

    setFilterIsFiltering(false);
    setFilterIsSorting(false);

    setFilterQueryLimit(QUERY_LIMIT_DEFAULT);
  }, [
    SORT_BY_DEFAULT,
    SORT_ORDER_DEFAULT,
    IS_INSTANT_REDEMPTION_DEFAULT,
    IS_SYARIAH_DEFAULT,
  ]);

  /**
   * Reset filtering state
   */
  const resetFilterState = useCallback(() => {
    setFilterType([]);
    setFilterCurrency('all');

    setFilterIsInstantRedemption(IS_INSTANT_REDEMPTION_DEFAULT);
    setFilterMeta('');
    setFilterIm('');
    setFilterMaxBuy(0);
    setFilterBankId(undefined);
    setFilterIsSyariah(IS_SYARIAH_DEFAULT);

    setFilterIsFiltering(false);
  }, [IS_SYARIAH_DEFAULT, IS_INSTANT_REDEMPTION_DEFAULT]);

  /**
   * Reset sorting state
   */
  const resetSortState = useCallback(() => {
    setFilterSortBy(SORT_BY_DEFAULT);
    setFilterSortOrder(SORT_ORDER_DEFAULT);
    setFilterSortPeriod(SORT_PERIOD_DEFAULT);

    setFilterIsSorting(false);
  }, [SORT_BY_DEFAULT, SORT_ORDER_DEFAULT]);

  /**
   * Accept filter and sort params, turn them into url query string
   * @param param0 Filter & sort params
   * @returns Url query string
   */
  const buildQueryParams = ({
    is_instant_redemption,
    sort,
    sort_period,
    sort_by,
    bank_id,
    is_syariah,
    is_top_product,
  }: onApplyParams) => {
    const params = {
      ...(sort_by ? { [SORT_BY_PARAM_KEY]: sort_by } : {}),
      ...(sort_period ? { [SORT_PERIOD_PARAM_KEY]: sort_period } : {}),
      ...(sort ? { [SORT_ORDER_PARAM_KEY]: sort } : {}),
      ...(is_instant_redemption
        ? { [IS_INSTANT_REDEMPTION_PARAM_KEY]: 1 }
        : {}),
      ...(bank_id !== undefined ? { [BANK_ID_PARAM_KEY]: bank_id } : {}),
      ...(is_syariah !== undefined
        ? { [IS_SYARIAH_PARAM_KEY]: is_syariah ? 1 : 0 }
        : {}),
      ...(!!is_top_product ? { [IS_TOP_COMPANY_PARAM_KEY]: 1 } : {}),
      ...extraParamsShownOnURL,
      ...filterFixedParamsShown,
    };

    // remove empty attributes and return
    return serializeParam(params);
  };

  /**
   * Return the number of active non-default filters
   */
  const numberOfActiveFilter = useMemo(() => {
    let res = 0;

    if (filterBankId !== undefined) res += 1;

    if (filterIsInstantRedemption) res += 1;

    if (filterIsSyariah !== undefined) res += 1;

    if (filterIsTopCompany) res += 1;

    if (filterMaxBuy > 0) res += 1;

    // Filter IM is fixed and not user selection, remove from counted to avoid number of active 1 in IM page
    if (filterIm) res += 0;

    if (filterMeta) res += 1;

    if (res === 0) setFilterIsFiltering(false);

    return res;
  }, [
    filterBankId,
    filterIsInstantRedemption,
    filterIsSyariah,
    filterIsTopCompany,
    filterMaxBuy,
    filterIm,
    filterMeta,
  ]);

  /**
   * Return the number of active non-default sorts
   */
  const numberOfActiveSort = useMemo(() => {
    let res = 0;

    if (filterSortBy !== SORT_BY_DEFAULT) res += 1;

    if (filterSortOrder !== SORT_ORDER_DEFAULT) res += 1;

    if (filterSortPeriod !== SORT_PERIOD_DEFAULT) res += 1;

    if (res === 0) setFilterIsSorting(false);

    return res;
  }, [
    filterSortBy,
    filterSortOrder,
    filterSortPeriod,
    SORT_BY_DEFAULT,
    SORT_ORDER_DEFAULT,
    SORT_PERIOD_DEFAULT,
  ]);

  /** Detect sort by query change and set to state if valid */
  useEffect(() => {
    if (sortByQuery && SORT_BY_ENUM.includes(sortByQuery)) {
      setFilterSortBy(Number(sortByQuery));
      setFilterIsSorting(true);
      return;
    }
    setFilterSortBy(SORT_BY_DEFAULT);
  }, [SORT_BY_DEFAULT, sortByQuery]);

  /** Detect sort period query change and set to state if valid */
  useEffect(() => {
    if (sortPeriodQuery && SORT_PERIOD_ENUM.includes(sortPeriodQuery)) {
      setFilterSortPeriod(sortPeriodQuery);
      setFilterIsSorting(true);
      return;
    }
    setFilterSortPeriod(SORT_PERIOD_DEFAULT);
  }, [sortPeriodQuery]);

  /** Detect sort order query change and set to state if valid */
  useEffect(() => {
    if (sortOrderQuery && SORT_ORDER_ENUM.includes(sortOrderQuery)) {
      setFilterSortOrder(sortOrderQuery);
      setFilterIsSorting(true);
      return;
    }
    setFilterSortOrder(SORT_ORDER_DEFAULT);
  }, [sortOrderQuery]);

  /** Detect instant redemption query change and set to state if valid */
  useEffect(() => {
    if (
      !isInstantRedemptionQuery ||
      !IS_INSTANT_REDEMPTION_ENUM.includes(isInstantRedemptionQuery)
    ) {
      return setFilterIsInstantRedemption(false);
    }

    setFilterIsInstantRedemption(isInstantRedemptionQuery === '1');
    setFilterIsFiltering(true);
  }, [isInstantRedemptionQuery]);

  /** Detect instant redemption query change and set to state if valid */
  useEffect(() => {
    if (!isTopCompanyQuery) {
      return setFilterIsTopCompany(false);
    }

    setFilterIsTopCompany(isTopCompanyQuery === '1');
    setFilterIsFiltering(true);
  }, [isTopCompanyQuery]);

  /** Detect bank id query change and set to state if bank id not undefined */
  useEffect(() => {
    const bankId = bankIdQuery !== null ? Number(bankIdQuery) : undefined;
    const isFiltering = bankId !== undefined;

    setFilterBankId(bankId);
    if (isFiltering) {
      setFilterIsFiltering(true);
    }
  }, [bankIdQuery]);

  /** Detect syariah query change and set to state if valid */
  useEffect(() => {
    if (!!!isSyariahQuery) {
      return setFilterIsSyariah(IS_SYARIAH_DEFAULT);
    }

    if (IS_SYARIAH_ENUM.includes(isSyariahQuery)) {
      setFilterIsSyariah(isSyariahQuery === '1');
      setFilterIsFiltering(true);
    }
  }, [isSyariahQuery, IS_SYARIAH_DEFAULT, isUserSyariah]);

  /** Detect meta query change and set to state if meta is not undefined */
  useEffect(() => {
    const meta = metaQuery || '';

    setFilterMeta(meta);
    if (meta) {
      setFilterIsFiltering(true);
    }
  }, [metaQuery]);

  /** Detect im query change and set to state if im is not undefined */
  useEffect(() => {
    const im = imQuery || '';

    setFilterIm(im);
    if (im) {
      setFilterIsFiltering(true);
    }
  }, [imQuery]);

  /** Detect max buy query change and set to state if max buy is not undefined */
  useEffect(() => {
    const maxBuy = Number(maxBuyQuery);

    setFilterMaxBuy(maxBuy);
    if (maxBuy) {
      setFilterIsFiltering(true);
    }
  }, [maxBuyQuery]);

  return (
    <ReksadanaProductContextState.Provider
      value={{
        filterType,
        filterCurrency,
        filterSortBy,
        filterSortOrder,
        filterSortPeriod,
        filterIsInstantRedemption,
        filterMeta,
        filterIm,
        filterMaxBuy,
        filterBankId,
        filterIsSyariah,
        filterIsTopCompany,
        filterIsSorting,
        filterIsFiltering,
        productQuery,
        comparedProducts,
        numberOfActiveFilter,
        numberOfActiveSort,
        isUserSyariah,
        extraParams,
        extraParamsShownOnURL,
        SORT_BY_DEFAULT,
        SORT_PERIOD_DEFAULT,
        SORT_ORDER_DEFAULT,
        filterFixedParams,
        filterFixedParamsShown,
        filterQueryLimit,
      }}
    >
      <ReksadanaProductContextFunction.Provider
        value={{
          setFilterType,
          setFilterCurrency,
          setFilterSortBy,
          setFilterSortOrder,
          setFilterSortPeriod,
          setFilterIsInstantRedemption,
          setFilterMeta,
          setFilterIm,
          setFilterMaxBuy,
          setFilterIsSyariah,
          resetFilterState,
          resetAllState,
          resetSortState,
          setFilterIsSorting,
          setComparedProducts,
          setFilterBankId,
          setFilterFixedParams,
          setFilterFixedParamsShown,
          buildQueryParams,
          setFilterQueryLimit,
        }}
      >
        {children}
      </ReksadanaProductContextFunction.Provider>
    </ReksadanaProductContextState.Provider>
  );
};

export default ReksadanaProductProvider;

/**
 * costum hook to consume ReksadanaProductContextState
 *
 */
export const useReksadanaProductStateContext = () => {
  const ctx = useContext(ReksadanaProductContextState);
  if (!ctx) {
    throw new Error('useReksadanaProductStateContext out of boundary');
  }
  return ctx;
};

/**
 * costum hook to consume ReksadanaProductContextFunction
 *
 */
export const useReksadanaProductFunctionContext = () => {
  const ctx = useContext(ReksadanaProductContextFunction);
  if (!ctx) {
    throw new Error('useReksadanaProductFunctionContext out of boundary');
  }

  return ctx;
};
