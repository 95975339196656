import React from 'react';
import { Modal, Text } from '@bibitid/uikit-v1';
import CreatePinIllustration from './assets/CreatePinIllustration/CreatePinIllustration';

export interface Props {
  visibility: boolean;
  toggleModal: () => void;
}

const CreatePinInfoModal: React.FC<Props> = ({ visibility, toggleModal }) => {
  return (
    <Modal visible={visibility} onClose={toggleModal}>
      <Modal.Header showClose={true} showBackButton={false} />
      <Modal.Body>
        <div className='bit-text-center'>
          <CreatePinIllustration alt='create-pin' />
          <br />
          <Text htmlTag='h3' type='subtitle2' style={{ marginBottom: 12 }}>
            Kamu Belum Buat PIN
          </Text>
          <Text htmlTag='p'>
            PIN diperlukan untuk keamanan dan kemudahan transaksi kamu.
          </Text>
        </div>
      </Modal.Body>
      <Modal.Footer primaryButton='Buat PIN' onPrimaryClick={toggleModal} />
    </Modal>
  );
};

export default CreatePinInfoModal;
