import { EnvConfig } from './env.type.d';

/**
 * Get all value from Env
 */
export const getEnv = (): EnvConfig => {
  const {
    REACT_APP_VERSION,
    REACT_APP_MAIN_URL,
    REACT_APP_API_URL,
    REACT_APP_BIBIT_ASSET_URL,
    REACT_APP_AWS_BIBIT_BUCKET,
    REACT_APP_BIBIT_ASSET,
    REACT_APP_WEBSITE_ID_CRISP,
    REACT_APP_GTM,
    REACT_APP_PAYMENT_WEBHOOK,
    REACT_APP_AWS_CLOUDFRONT_ID,
    REACT_APP_SENTRY_DSN,
    REACT_APP_SENTRY_ENV,
    REACT_APP_RECAPTCHA_V2_TOKEN,
    REACT_APP_RECAPTCHA_V3_TOKEN,
    REACT_APP_RECAPTCHA_DISABLE,
    REACT_APP_MAX_GOAL_PORTO,
    REACT_APP_UPDATE_RULES_BUCKET,
    REACT_APP_SHOW_ANALYTICS_LOG,
    REACT_APP_GIFTCARD_PREVIEW_URL,
    REACT_APP_GIFTCARD_THUMBNAIL,
    REACT_APP_TELEGRAM_URL,
    REACT_APP_JAGO_CASHBACK_AMOUNT,
    REACT_APP_JAGO_CASHBACK_AMOUNT_SYARIAH,
    REACT_APP_JAGO_CASHBACK_BLOG_URL,
    REACT_APP_JAGO_CASHBACK_BLOG_URL_SYARIAH,
    REACT_APP_JAGO_CASHBACK_BANNER_PERIODE,
    REACT_APP_JAGO_REFERRAL_AMOUNT,
    REACT_APP_JAGO_REFERRAL_PERIODE,
    REACT_APP_JAGO_CASHBACK_REFERRAL_BLOG_URL,
    REACT_APP_JAGO_CAMPAIGN_BANNER_PERIODE,
    REACT_APP_JAGO_RDN_CASHBACK_AMOUNT,
    REACT_APP_JAGO_RDN_CASHBACK_BANNER_PERIODE,
    REACT_APP_OJK_BIBIT_URL,
    REACT_APP_MAX_INSTANT_REDEMPTION_DAILY,
    REACT_APP_GOOGLE_LOYALTY_LOGIN_URL,
    REACT_APP_GOOGLE_LOYALTY_REGISTER_URL,
    REACT_APP_NATIVE_DEBUGGER,
    REACT_APP_DEVTOOLS,
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_FIREBASE_PROJECT_ID,
    REACT_APP_FIREBASE_APP_ID,
    REACT_APP_FIREBASE_MEASUREMENT_ID,
    REACT_APP_CLEVERTAP_PROJECT_ID,
    REACT_APP_UNLEASH_PROXY_URL,
    REACT_APP_UNLEASH_CLIENT_KEY,
    REACT_APP_UNLEASH_ENVIRONMENT,
    REACT_APP_WS_STOCKBIT_URL,
    REACT_APP_WS_STOCKBIT_INTERVAL,
    REACT_APP_MOCKS,
    REACT_APP_IDLE,
    REACT_APP_REFERRAL_WEB,
    REACT_APP_SHOW_REDUCER_LOG,
    REACT_APP_FORCE_APP_CHECK_RECAPTCHA,
    REACT_APP_SPLASH_SCREEN_TEMATIC,
    PUBLIC_URL,
    NODE_ENV,
  } = process.env;

  return {
    AppVersion: REACT_APP_VERSION,
    MainUrl: REACT_APP_MAIN_URL,
    ApiUrl: REACT_APP_API_URL,
    UrlMock: Number(REACT_APP_MOCKS),
    BibitAssetUrl: REACT_APP_BIBIT_ASSET_URL,
    BibitAsset: REACT_APP_BIBIT_ASSET,
    AwsBibitBucket: REACT_APP_AWS_BIBIT_BUCKET,
    WebCrispId: REACT_APP_WEBSITE_ID_CRISP,
    GtmId: REACT_APP_GTM,
    PaymentWebhook: REACT_APP_PAYMENT_WEBHOOK,
    AwsCloudFront: REACT_APP_AWS_CLOUDFRONT_ID,
    SentryDsn: REACT_APP_SENTRY_DSN,
    SentryEnv: REACT_APP_SENTRY_ENV,
    RecaptchaV2: REACT_APP_RECAPTCHA_V2_TOKEN,
    RecaptchaV3: REACT_APP_RECAPTCHA_V3_TOKEN,
    RecaptchaDisabled: REACT_APP_RECAPTCHA_DISABLE === 'true',
    MaxGoalPorto: Number(REACT_APP_MAX_GOAL_PORTO),
    UpdateRulesBucket: REACT_APP_UPDATE_RULES_BUCKET,
    ShowAnalyticsLog: REACT_APP_SHOW_ANALYTICS_LOG === 'true',
    GiftcardPreviewUrl: REACT_APP_GIFTCARD_PREVIEW_URL,
    GiftCardThumbnail: REACT_APP_GIFTCARD_THUMBNAIL || '',
    TelegarmUrl: REACT_APP_TELEGRAM_URL,
    JagoCashbackAmount: Number(REACT_APP_JAGO_CASHBACK_AMOUNT || '0'),
    JagoCashbackAmountSyariah: Number(
      REACT_APP_JAGO_CASHBACK_AMOUNT_SYARIAH || '0'
    ),
    JagoCashbackBlogUrl: REACT_APP_JAGO_CASHBACK_BLOG_URL,
    JagoCashbackBlogUrlSyariah: REACT_APP_JAGO_CASHBACK_BLOG_URL_SYARIAH,
    JagoCashbackBannerPeriod: REACT_APP_JAGO_CASHBACK_BANNER_PERIODE,
    JagoReferralAmount: Number(REACT_APP_JAGO_REFERRAL_AMOUNT || '0'),
    JagoReferralPeriod: REACT_APP_JAGO_REFERRAL_PERIODE,
    JagoCashbackReferralBlogUrl: REACT_APP_JAGO_CASHBACK_REFERRAL_BLOG_URL,
    JagoCampaignBannerPeriod: REACT_APP_JAGO_CAMPAIGN_BANNER_PERIODE,
    JagoRdnCashbackAmount: Number(REACT_APP_JAGO_RDN_CASHBACK_AMOUNT || '0'),
    JagoRdnCashbackBannerPeriod: REACT_APP_JAGO_RDN_CASHBACK_BANNER_PERIODE,
    OjkBibitUrl: REACT_APP_OJK_BIBIT_URL,
    MaxInstantRedemptionDaily: Number(REACT_APP_MAX_INSTANT_REDEMPTION_DAILY),
    GoogleLoyaltyLoginURL: REACT_APP_GOOGLE_LOYALTY_LOGIN_URL || '',
    GoogleLoyaltyRegisterURL: REACT_APP_GOOGLE_LOYALTY_REGISTER_URL || '',
    NativeDebugger: REACT_APP_NATIVE_DEBUGGER === 'true',
    DevTools: REACT_APP_DEVTOOLS === 'true',
    FirebaseApiKey: REACT_APP_FIREBASE_API_KEY || '',
    FirebaseProjectId: REACT_APP_FIREBASE_PROJECT_ID || '',
    FirebaseAppId: REACT_APP_FIREBASE_APP_ID || '',
    FirebaseMeasurementId: REACT_APP_FIREBASE_MEASUREMENT_ID || '',
    ClevertapProjectId: REACT_APP_CLEVERTAP_PROJECT_ID || '',
    UnleashProxyUrl: REACT_APP_UNLEASH_PROXY_URL,
    UnleashClientKey: REACT_APP_UNLEASH_CLIENT_KEY,
    UnleashEnv:
      REACT_APP_UNLEASH_ENVIRONMENT === 'production' ? 'production' : 'staging',
    WsStockbitInterval: Number(REACT_APP_WS_STOCKBIT_INTERVAL),
    WsStockbitUrl: REACT_APP_WS_STOCKBIT_URL,
    AppIddle: REACT_APP_IDLE === 'true',
    ReferralWebUrl: REACT_APP_REFERRAL_WEB,
    ReducerLogging: REACT_APP_SHOW_REDUCER_LOG === 'true',
    ForceAppCheckRecaptcha: REACT_APP_FORCE_APP_CHECK_RECAPTCHA === 'true',
    SplashScreenTematic: REACT_APP_SPLASH_SCREEN_TEMATIC || '',
    PublicUrl: PUBLIC_URL ?? '',
    NodeEnv: NODE_ENV ?? 'development',
  };
};
