import React, { useState, useEffect, UIEvent, TouchEvent } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { X, ChevronLeft } from 'react-feather';
import './Modal.css';

export interface ModalProps {
  /**
   * Modal title
   */
  title?: string | JSX.Element;

  /** title position */
  titlePosition?: 'left' | 'center';

  /**
   * Modal additional class
   */
  containerClass?: string;

  /**
   * Use animation when opening or closing modal
   */
  animated?: boolean;

  /**
   * Duration for slide animation (ms)
   */
  slideDuration?: number;

  /**
   * Control modal visibility
   */
  visible: boolean;

  /**
   * Show default close element
   */
  showClose?: boolean;

  /**
   * Callback called when the close button or mask is clicked
   */
  onClose?: Function;

  /**
   * Callback called after close animation is completed
   */
  afterClose?: () => void;

  /**
   * Whether the modal should show dark mask behind
   */
  showMask?: boolean;

  /**
   * Height configuration of the modal
   */
  height?: 'auto' | string;

  /**
   * Show default back button element
   */
  showBackButton?: boolean;

  /**
   * Callback called when the back button is clicked
   */
  onClickBack?: Function;

  /**
   * Whether the modal using padding in container or not
   */
  withPadding?: boolean;

  /**
   * Wether the modal using padding in header or not
   */
  headerWithPadding?: boolean;

  /**
   * Custom Element on top right modal
   */
  renderTopRightContent?: React.ReactNode | null;

  /**
   * Custom header classname
   */
  headerClassname?: string;

  /**
   * Custom body classname
   */
  bodyClassname?: string;

  /**
   * Custome style attach in modal wrapper
   */
  style?: React.CSSProperties;

  /*
   * Handling when component content scroll
   */
  onScroll?: (event: UIEvent<HTMLDivElement>) => void;

  /**
   * Handle touch start content
   */
  onTouchStart?: (event: TouchEvent<HTMLDivElement>) => void;

  /**
   * Handle touch end content
   */
  onTouchEnd?: (event: TouchEvent<HTMLDivElement>) => void;

  /** Handle modal scrolling
   * In case you need modal to scrolling,
   * Like at modal compareReksadana.
   */
  lockScrolling?: boolean;

  customDataTestId?: {
    title?: string;
    closeButton?: string;
  };
}

/**
 * Modal component
 *
 * This is generic modal used by our application. You can customise this modal
 * as needed per feature.
 */
const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  title,
  visible,
  containerClass,
  showMask = true,
  animated = true,
  slideDuration = 200,
  showClose = true,
  onClose,
  afterClose,
  height = 'auto',
  showBackButton,
  onClickBack,
  withPadding = true,
  headerWithPadding = false,
  renderTopRightContent = null,
  titlePosition = 'left',
  headerClassname = '',
  bodyClassname = '',
  onScroll,
  onTouchStart,
  onTouchEnd,
  style,
  lockScrolling = true,
  customDataTestId,
}) => {
  const [modalAnimation, setModalAnimation] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);

  // tell us where animation is at
  // this state only consider when `animated` props is true
  // 1: the modal not show in the screen. which mean `visible` props is false
  // 2: the modal Pop UP oN the Screen. which mean visible is true. then the MODAL ready to animating close
  const [animationStep, setAnimationStep] = useState<1 | 2>(1);
  const [isAnimating, setIsAnimating] = useState(false);

  /**
   * Handle modal close event
   */
  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    // Run this after animation completed
    if (afterClose) {
      setTimeout(() => afterClose(), slideDuration);
    }
  };

  /**
   * This useEffect block make sure that HTML inside this component will not render
   * on mount, and also let animation finish before unmounting this component
   */
  useEffect(() => {
    let timeout: any;
    setIsAnimating(true);
    // The `visible` variable used in this scope only refers to variable passed
    // from the parent component which use this component. It doesn't reflect
    // `visible` boolean value, which is why `setModalAnimation` inside the negative
    // `visible` block still use normal `visible`, not negated
    if (!visible) {
      // Set animation modal to false, and after animation finish set modal to false
      setModalAnimation(visible);

      // This if block is necessary, because I want the visibility to be set
      // immediately IF the component is not animated. Only wait for setTimeout
      // IF the component is animated
      if (animated && animationStep === 2) {
        timeout = setTimeout(() => {
          document.body.style.removeProperty('overflow');
          setVisibleModal(visible);

          // reset animation step to be 1 again
          setAnimationStep(1);
          setIsAnimating(false);
        }, slideDuration);
      } else {
        setVisibleModal(visible);
      }
      return;
    }

    // Set modal to true and then set animation to true after modal show
    setVisibleModal(visible);

    // This if logic works the same as above, only wait for setTimeout if this modal
    // is animated

    // Lock scrolling default true, if true we lock the scrolling.
    if (animated) {
      timeout = setTimeout(() => {
        if (lockScrolling) {
          document.body.style.overflow = 'hidden';
        }

        // set animating step to be 2
        setAnimationStep(2);
        setModalAnimation(visible);
        setIsAnimating(false);
      }, slideDuration);
    } else {
      setModalAnimation(visible);
    }

    // if this modal (or lets say, page/component that called this modal) is unmounted
    // without triggering 'close' state to this modal, set document.body overflow to normal
    return () => {
      document.body.style.removeProperty('overflow');
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [visible, animated, slideDuration, lockScrolling, animationStep]);

  const handleBackButton = () => {
    if (onClickBack) {
      onClickBack();
    }
  };

  // This will allow devs to opt out of animation if they don't need it
  const transitionStyle = animated
    ? { transition: `${slideDuration}ms ease-out`, height }
    : {};

  // Don't let this component render HTML unless the modal is ready
  if (!visibleModal) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classNames(
        {
          'bit-modal': true,
          'modal--visible': modalAnimation,
          'is-animating': isAnimating,
        },
        containerClass
      )}
      data-testid='modal-wrapper'
      onScroll={onScroll}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      style={style}
    >
      {!!showMask && (
        <div
          className='bit-modal-mask'
          style={transitionStyle}
          onClick={handleClose}
        />
      )}
      <div
        role='dialog'
        aria-modal='true'
        aria-labelledby='modal_title'
        className={classNames('bit-modal-body', {
          'padding-20': withPadding,
          [bodyClassname]: bodyClassname,
        })}
        style={transitionStyle}
      >
        <div
          className={classNames('bit-modal-header', {
            'padding-20': headerWithPadding,
            [headerClassname]: headerClassname,
          })}
        >
          {showBackButton && (
            <div onClick={handleBackButton} data-testid='back-button'>
              <ChevronLeft color='#666' size={18} />
            </div>
          )}
          {!!title && (
            <div
              data-testid={customDataTestId?.title}
              className='bit-modal-center nomargin'
              id='modal_title'
            >
              <div
                className={classNames('bit-title-text', {
                  'bit-align-left': titlePosition === 'left',
                  'text-center': titlePosition === 'center',
                })}
              >
                <h3>{title}</h3>
              </div>
            </div>
          )}
          {showClose && (
            <div
              data-testid={
                !!customDataTestId?.closeButton
                  ? customDataTestId.closeButton
                  : 'modal-close-button'
              }
              onClick={handleClose}
              className='bit-modal-body-close'
            >
              <X color='var(--icon-default)' size={20} />
            </div>
          )}
          {renderTopRightContent && (
            <div className='bit-modal-body-close'>{renderTopRightContent}</div>
          )}
        </div>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
