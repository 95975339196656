import React, { Component } from 'react';
import { HeightSizeContext } from 'features/common/containers/HeightSizeContext';
import classNames from 'classnames';

class ContentBox extends Component {
  handleScroll = (event) => {
    const { onScroll } = this.props;
    event.persist();
    if (onScroll) {
      onScroll(event);
    }
  };

  render() {
    const {
      id,
      children,
      style,
      className,
      bottomHeight,
      headerHeight,
      innerRef,
    } = this.props;

    /**
     * To solve dynamic height on android 6 (and all devices)
     * context.initialHeight = current window height
     * Ex: when keyboard is active (up), window height also changed
     */
    const totalHeight =
      this.context.initialHeight - (bottomHeight + headerHeight);

    return (
      <div
        ref={innerRef}
        className={classNames('content-box', { [className]: !!className })}
        style={{
          height: totalHeight || '100%',
          ...style,
        }}
        onScroll={this.handleScroll}
        id={id}
      >
        {children}
      </div>
    );
  }
}

ContentBox.defaultProps = {
  bottomHeight: 0,
  headerHeight: 45,
  style: {},
  id: '',
  onScroll: undefined,
};

ContentBox.contextType = HeightSizeContext;

export default ContentBox;
