import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

const defaultState = {
  byId: {},
  allIds: [],
};

const [addInvestmentManagerEntry, addInvestmentManagerId] = [
  'ADD_INVESTMENT_MANAGER_ENTRY',
  'ADD_INVESTMENT_MANAGER_ID',
].map(createAction);

const investmentManager = createReducer(
  {
    [addInvestmentManagerEntry]: (state, payload) => {
      return update(state, {
        byId: {
          $set: {
            ...state.byId,
            [payload.ojkCode]: {
              ...payload,
            },
          },
        },
      });
    },
    [addInvestmentManagerId]: (state, payload) => {
      return update(state, {
        allIds: [...state.allIds, payload.ojkCode],
      });
    },
  },
  defaultState
);

export { addInvestmentManagerEntry, addInvestmentManagerId };

export default investmentManager;
