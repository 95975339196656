import React, { useEffect } from 'react';
import { DEFAULT_STATUS_BAR_COLOR } from '../constants';
import { useStatusBarFunction } from '../context/StatusBarContext';

/**
 * HOC for resetting status Bar
 * @param WrappedComponent - component that need to be wrapped
 */
const withStatusBar = (WrappedComponent: any) => (props: any) => {
  const { defaultStatusBar = true } = props;
  const { onSetStatusBarColor } = useStatusBarFunction();

  useEffect(() => {
    if (defaultStatusBar) {
      onSetStatusBarColor({
        backgroundColor: DEFAULT_STATUS_BAR_COLOR().backgroundColor,
        foregroundColor: DEFAULT_STATUS_BAR_COLOR().foregroundColor,
      });
    }
  }, [onSetStatusBarColor, defaultStatusBar]);

  return <WrappedComponent {...props} />;
};

export default withStatusBar;
