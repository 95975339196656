/**
 * Endpoints related with connected devices feature
 */
export const endpoints = {
  /** Endpoint for listing user's connected devices */
  getConnectedDevices: '/auth/access',

  /** Endpoint for clearing user's connected devices */
  clearConnectedDevices: '/auth/access/clear',
};
export default endpoints;
