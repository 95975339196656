import LazyModal from 'features/common/LazyModal';
import React from 'react';
import { useSelector } from 'react-redux';
import { TRootReducers } from 'store/root';

const ModalRestrictRecurring = React.lazy(
  () => import('./ModalRestrictRecurring')
);

const LazyModalRestrictRecurring = (props: any) => {
  const visible = useSelector<TRootReducers, boolean>(
    (state) => state?.recurring?.modalRestrictRecurring
  );

  return (
    <LazyModal
      component={ModalRestrictRecurring}
      visible={visible}
      {...props}
    />
  );
};

export default LazyModalRestrictRecurring;
