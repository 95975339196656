import React from 'react';

import combineProviders from './combineProviders';
import FileAttachmentProvider from 'features/fileattachment/hook/FileAttachmentContext';
import { HeightSizeProvider } from 'features/common/containers/HeightSizeContext';
import TopupGlobalContextProvider from 'features/topup-modal/TopupGlobalContext';
import { RegisterAccountProvider } from 'features/register-account/context';
import { ReferralGlobalProvider } from 'features/referral-tracker/provider/ReferralGlobalProvider';
import AppModalGlobalContextProvider from 'features/common/modals/AppModalGlobalContext';
import ModalUpdateReminderProvider from 'features/common/modals/UpdateReminder/provider/ModalUpdateReminderProvider';
import {
  JagoCheckDifferentLoggedInProvider,
  JagoProvider,
  JagoUpdateOSModalProvider,
} from 'features/bankjago/contexts';
import {
  SharedPortfolioContextProvider,
  SharedInvitationContextProvider,
} from 'features/shared-portfolio';
import CompleteYourProfileModalProvider from 'features/common/modals/CompleteYourProfileModal/provider/CompleteYourProfileModalProvider';
import RecurringTopUpContextProvider from 'features/topup-modal/RecurringTopUpContext';
import { RoboProfilingGlobalProvider } from 'features/profiling/provider/global/RoboProfilingGlobalProvider';
import ModalDataRenewalProvider from 'features/periodic-data-update/context/ModalsDataRenewalProvider.context';
import { EarlyRedemptionProvider } from 'features/sbn';

import GiftCardProvider from 'features/giftcard/context/GiftCard.context';
import GlobalRecurringProvider from 'features/recurring/contexts/GlobalRecurringContext';
import QuickAccessProvider from 'features/home/components/quick-access/context/QuickAccessProvider';
import HomeOnboardingProvider from 'features/home/components/Modals/onboarding-modal-flow/contexts/HomeOnboardingProvider';
import CreateProtfolioModalProvider from 'features/portfolio/modals/CreatePortfolioRoboModal/context/CreateProtfolioModalProvider';
import RecurringContext from 'features/recurring/contexts/RecurringContext';
import { PremiumTransactionsModalsContextProvider } from 'features/premium/context';
import Devtools from 'devtools';
import CountDownReSentContext from 'features/email-status/context/CountDownContext';
import ExistingProductModalProvider from 'features/topup-modal/ExistingProductModal/context/ExistingProductModalProvider';
import { AmountBlurredProvider } from 'features/common/AmountBlurred/provider/AmountBlurredProvider';

/**
 * Combine Internal Custom Provider
 */
const GlobalProviders = combineProviders([
  ModalUpdateReminderProvider,
  HeightSizeProvider,
  AppModalGlobalContextProvider,
  ExistingProductModalProvider,
  RecurringTopUpContextProvider,
  TopupGlobalContextProvider,
  QuickAccessProvider,
  HomeOnboardingProvider,
  CreateProtfolioModalProvider,
  RegisterAccountProvider,
  FileAttachmentProvider,
  ReferralGlobalProvider,
  JagoProvider,
  SharedPortfolioContextProvider,
  CompleteYourProfileModalProvider,
  SharedInvitationContextProvider,
  RoboProfilingGlobalProvider,
  ModalDataRenewalProvider,
  EarlyRedemptionProvider,
  GiftCardProvider,
  GlobalRecurringProvider,
  JagoUpdateOSModalProvider,
  JagoCheckDifferentLoggedInProvider,
  RecurringContext,
  PremiumTransactionsModalsContextProvider,
  CountDownReSentContext,
  AmountBlurredProvider,
]);

/**
 * GlobalContextProvider is component like CombineReducer but for Context Provider;
 * @param
 */
const GlobalContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <GlobalProviders>
      {children}
      <Devtools />
    </GlobalProviders>
  );
};

export default GlobalContextProvider;
