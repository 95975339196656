import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { ChartPeriodContextProvider } from 'features/common/chart/providers/ChartPeriodContextProvider';
import { PeriodContextProvider } from 'features/common/ProductDetail/Chart/providers/PeriodContextProvider';

const MutualFundDetailModalLazy = React.lazy(
  () => import('./MutualFundDetailModal')
);

export interface MutualFundDetailModalProps {
  mutualFundDetailModalVisible: boolean;
  onClose: () => void;
  reksadanaId: string;
  onCloseExploreRdnModal?: () => void;
  onCustomNavigateMutualFundDetail?: (symbol: string) => void;
}

const IndexMutualFundDetailModal: React.FC<
  React.PropsWithChildren<MutualFundDetailModalProps>
> = (props) => {
  const { mutualFundDetailModalVisible } = props;

  return (
    <PeriodContextProvider>
      <ChartPeriodContextProvider>
        <LazyModal
          component={MutualFundDetailModalLazy}
          visible={mutualFundDetailModalVisible}
          {...props}
        />
      </ChartPeriodContextProvider>
    </PeriodContextProvider>
  );
};

export default IndexMutualFundDetailModal;
