import React, { useState } from 'react';

import {
  saveBuyGiftCardProperties,
  getLatestBuyGiftCardProperties,
  removeLatestBuyGiftCardProperties,
} from 'features/giftcard/services/giftcard';
import type { AvailableChannelType } from '../pages/GiftCardOrderPage/components/modals/GiftCardPaymentMethodModal/GiftCardPaymentMethodModal';

/** Type for step buy gift card */
type StepBuyGiftCardType =
  | 'DEFAULT'
  | 'NEW'
  | 'INPUT_FORM'
  | 'CHOOSE_PAYMENT_METHOD'
  | 'PAYMENT_CONFIRMATION'
  | 'PAYMENT_PROMPT';

/** Type for template gift card */
type TemplateGiftCardType = {
  id: string;
  order: number;
  name: string;
  path: string;
};

/** Type for buy gift card data */
type BuyGiftCardDataType = {
  invoice_number: string;
  payment_method: string;
  payment_status: number;
  ordertime: string;
  expiredtime: string;
  amount: number;
  category: number;
  template: number;
  sender: string;
  message: string;
  status: string;
  created: string;
  payment_detail: any;
} | null;

/** Type for buy gift card error */
type BuyGiftCardErrorType = {
  errors?: {
    sender?: {
      location?: string;
      msg?: string;
      param?: string;
      value?: string;
    };
  };
} | null;

/** Interface of step buy gift card to save current and prev step */
interface StepBuyGiftCard {
  current: StepBuyGiftCardType;
  prev: StepBuyGiftCardType;
}

/** Interface of selected payment method in payment method modal */
interface SelectedPaymentMethodChannel {
  key?: string;
  name?: string;
  icon?: string;
  channel?: AvailableChannelType;
}

/** Interface of gift card context */
export interface GiftCardProps {
  /** Limit maximum input amount gift card */
  LIMIT_AMOUNT_GIFTCARD: number;

  /** Limit minimum input amount gift card */
  MINIMUM_AMOUNT_GIFTCARD: number;

  /** Limit maximum input message gift card */
  LIMIT_MSG: number;

  /** Limit maximum input sender gift card */
  LIMIT_SENDER: number;

  /** Step buy gift card */
  stepBuyGiftCard: StepBuyGiftCard;

  /** Category key gift card */
  categoryKey: number | null;

  /** Category label gift card */
  categoryLabel: string;

  /** Template gift card */
  templateGiftCard: TemplateGiftCardType;

  /** Input amount in Nominal Gift Card field */
  amount: number;

  /** Input message in Tulis Ucapan field */
  message: string;

  /** Input sender in Pengirim field */
  sender?: string;

  /** Visibility gift card send payment method modal */
  isVisiblePaymentMethodModal: boolean;

  /** Detail channel from selected payment method */
  selectedPaymentMethodChannel: SelectedPaymentMethodChannel;

  /** Visibility gift card send payment confirmation modal */
  isVisiblePaymentConfirmationModal: boolean;

  /** Data from request service buy gift card */
  buyGiftCardData: BuyGiftCardDataType;

  /** Error from request service buy gift card */
  buyGiftCardError: BuyGiftCardErrorType;

  /** Loading from request service buy gift card */
  buyGiftCardLoading: boolean;

  /** Show of not Detail Modal */
  detailModalVisible: boolean;

  /** Set go to new step buy gift card */
  setGoToStepBuyGiftCard: (step: StepBuyGiftCardType) => void;

  /** Set category key gift card */
  setCategoryKey: (categoryKey: number | null) => void;

  /** Set category label gift card */
  setCategoryLabel: (categoryLabel: string) => void;

  /** Set template gift card */
  setTemplateGiftCard: (templateGiftCard: TemplateGiftCardType) => void;

  /** Set input amount in Nominal Gift Card field */
  setAmount: (amount: number) => void;

  /** Set input message in Tulis Ucapan field */
  setMessage: (message: string) => void;

  /** Set input sender in Pengirim field */
  setSender: (message: string) => void;

  /** Toggle visibility gift card send payment method modal */
  toggleGiftCardPaymentMethodModal: () => void;

  /** Set detail cahnnel from selected payment method */
  setSelectedPaymentMethodChannel: (
    channel: SelectedPaymentMethodChannel
  ) => void;

  /** Toggle visibility gift card send payment confirmation modal */
  toggleGiftCardPaymentConfirmationModal: () => void;

  /** Set data from request service buy gift card */
  setBuyGiftCardData: (data: BuyGiftCardDataType) => void;

  /** Set error from request service buy gift card */
  setBuyGiftCardError: (error: BuyGiftCardErrorType) => void;

  /** Set loading from request service buy gift card */
  setBuyGiftCardLoading: (isLoading: boolean) => void;

  /** Reset gift card state context into default value */
  resetGiftCardStateContext: () => void;

  /**
   * Persisting latest state Buy Gift Card into localStorage
   * if latest buy gift card step is CHOOSE_PAYMENT_METHOD
   */
  persistGiftCardStateWhenChoosePaymentMethodStep: () => void;

  /**
   * Restore latest state Buy Gift Card from locacStorage on Gift Card Send Page
   * if buy gift card step, back into CHOOSE_PAYMENT_METHOD
   */
  restorePersistGiftCardOnGiftCardSendPage: () => void;

  /** toogle modal detail */
  toggleDetailModal: () => void;
}

/** Limit Maximum input amount giftcard is 10,000,000 */
const LIMIT_AMOUNT_GIFTCARD = 10000000;

/** Limit Minimum input amount giftcard is 10,000 */
const MINIMUM_AMOUNT_GIFTCARD = 10000;

/** Limit Maximum input message giftcard is 300  */
const LIMIT_MSG = 300;

/** Limit Maximum input sender giftcard is 90  */
const LIMIT_SENDER = 90;

export const GiftCardContext = React.createContext<GiftCardProps | undefined>(
  undefined
);

const GiftCardProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [stepBuyGiftCard, setStepBuyGiftCard] = useState<StepBuyGiftCard>({
    current: 'DEFAULT',
    prev: 'DEFAULT',
  });
  const [categoryKey, setCategoryKey] = useState<number | null>(null);
  const [categoryLabel, setCategoryLabel] = useState<string>('');
  const [templateGiftCard, setTemplateGiftCard] =
    useState<TemplateGiftCardType>({
      id: '',
      order: 0,
      name: '',
      path: '',
    });
  const [amount, setAmount] = useState<number>(MINIMUM_AMOUNT_GIFTCARD);
  const [message, setMessage] = useState('');
  const [sender, setSender] = useState<string | undefined>(undefined);
  const [isVisiblePaymentMethodModal, setIsVisiblePaymentMethodModal] =
    useState(false);
  const [selectedPaymentMethodChannel, setSelectedPaymentMethodChannel] =
    useState<SelectedPaymentMethodChannel>({
      key: '',
      name: '',
      icon: '',
    });
  const [
    isVisiblePaymentConfirmationModal,
    setIsVisiblePaymentConfirmationModal,
  ] = useState(false);
  const [buyGiftCardData, setBuyGiftCardData] =
    useState<BuyGiftCardDataType>(null);
  const [buyGiftCardError, setBuyGiftCardError] =
    useState<BuyGiftCardErrorType>(null);
  const [buyGiftCardLoading, setBuyGiftCardLoading] = useState<boolean>(false);

  const [detailModalVisible, setDetailModalVisible] = useState(false);

  const setGoToStepBuyGiftCard = (step: StepBuyGiftCardType) => {
    setStepBuyGiftCard({
      current: step,
      prev: stepBuyGiftCard.current,
    });
  };

  const toggleGiftCardPaymentMethodModal = () => {
    setIsVisiblePaymentMethodModal(!isVisiblePaymentMethodModal);
  };

  const toggleGiftCardPaymentConfirmationModal = () => {
    setIsVisiblePaymentConfirmationModal(!isVisiblePaymentConfirmationModal);
  };

  const resetGiftCardStateContext = () => {
    setGoToStepBuyGiftCard('DEFAULT');
    setAmount(MINIMUM_AMOUNT_GIFTCARD);
    setMessage('');
    setSender(undefined);
    setIsVisiblePaymentMethodModal(false);
    setSelectedPaymentMethodChannel({
      key: '',
      name: '',
      icon: '',
    });
    setIsVisiblePaymentConfirmationModal(false);
    setBuyGiftCardData(null);
    setBuyGiftCardError(null);
    setBuyGiftCardLoading(false);
  };

  /** Persisting latest state Buy Gift Card into localStorage */
  const persistGiftCardState = () => {
    saveBuyGiftCardProperties({
      stepBuyGiftCard,
      amount,
      message,
      sender,
      selectedPaymentMethodChannel,
      isVisiblePaymentMethodModal,
      isVisiblePaymentConfirmationModal,
    });
  };

  /** Restore latest state Buy Gift Card from locacStorage */
  const restorePersistGiftCardState = async () => {
    const dataBuyGiftCard = await getLatestBuyGiftCardProperties();
    if (!dataBuyGiftCard) {
      return;
    }

    const {
      stepBuyGiftCard,
      amount,
      message,
      sender,
      selectedPaymentMethodChannel,
      isVisiblePaymentMethodModal,
      isVisiblePaymentConfirmationModal,
    } = dataBuyGiftCard;

    setStepBuyGiftCard(stepBuyGiftCard);
    setAmount(amount);
    setMessage(message);
    setSender(sender);
    setSelectedPaymentMethodChannel(selectedPaymentMethodChannel);
    setIsVisiblePaymentMethodModal(isVisiblePaymentMethodModal);
    setIsVisiblePaymentConfirmationModal(isVisiblePaymentConfirmationModal);
  };

  /** Remove state Buy Gift Card in locacStorage */
  const removePersistGiftCardState = async () => {
    removeLatestBuyGiftCardProperties();
  };

  /**
   * Persisting buy gift card state into localStorage if
   * - current step buy gift card is CHOOSE_PAYMENT_METHOD
   */
  const persistGiftCardStateWhenChoosePaymentMethodStep = () => {
    if (stepBuyGiftCard.current === 'CHOOSE_PAYMENT_METHOD') {
      persistGiftCardState();
    }
  };

  /**
   * Restore latest buy gift card state from localStorage if
   * - current step buy gift card is CHOOSE_PAYMENT_METHOD
   *
   * And by default remove persist buy gift card state
   */
  const restorePersistGiftCardOnGiftCardSendPage = async () => {
    if (stepBuyGiftCard.current === 'CHOOSE_PAYMENT_METHOD') {
      await restorePersistGiftCardState();
    }

    removePersistGiftCardState();
  };

  const toggleDetailModal = () => {
    setDetailModalVisible(!detailModalVisible);
  };

  return (
    <GiftCardContext.Provider
      value={{
        LIMIT_AMOUNT_GIFTCARD,
        MINIMUM_AMOUNT_GIFTCARD,
        LIMIT_MSG,
        LIMIT_SENDER,
        stepBuyGiftCard,
        categoryKey,
        categoryLabel,
        templateGiftCard,
        amount,
        message,
        sender,
        isVisiblePaymentMethodModal,
        selectedPaymentMethodChannel,
        isVisiblePaymentConfirmationModal,
        buyGiftCardData,
        buyGiftCardError,
        buyGiftCardLoading,
        setGoToStepBuyGiftCard,
        setCategoryKey,
        setCategoryLabel,
        setTemplateGiftCard,
        setAmount,
        setMessage,
        setSender,
        toggleGiftCardPaymentMethodModal,
        setSelectedPaymentMethodChannel,
        toggleGiftCardPaymentConfirmationModal,
        setBuyGiftCardData,
        setBuyGiftCardError,
        setBuyGiftCardLoading,
        resetGiftCardStateContext,
        persistGiftCardStateWhenChoosePaymentMethodStep,
        restorePersistGiftCardOnGiftCardSendPage,
        detailModalVisible,
        toggleDetailModal,
      }}
    >
      {children}
    </GiftCardContext.Provider>
  );
};

export default GiftCardProvider;

export const useGiftCardContext = () => {
  const context = React.useContext(GiftCardContext);
  if (context === undefined) {
    throw new Error(
      'useGiftCardContext must be used within a GiftCardProvider'
    );
  }
  return context;
};
