import React, { useState } from 'react';
import { SbnEarlyRedempInfo } from './type';

export type ErrorStepType = 'error_quota_max' | 'error_amount_not_enough';

export type StepEarlyRedemption =
  | 'input_amount'
  | 'info_amount_redemption'
  | 'info_max_redemption'
  | 'confirm'
  | 'success'
  | ErrorStepType;

export interface State {
  /**
   * determine step of Early Redemption Modal
   */
  step?: StepEarlyRedemption;
  amount?: number;
  productid?: string;
  earlyRedeemInfo?: SbnEarlyRedempInfo;
  orderCode?: string;
  /** Order Code response from API AFTER redemption success */
  orderCodeRedeem?: string;
  /** Redeem Bank At from API AFTER redemption success,
   *
   * this is tell us WHEN user will get their Money in their bank
   * */
  redeemBankAt?: string;

  /** To Show Modal, When Early Redeem SBN and pending,
   * waiting confirmation from KEMENKEU
   * */
  openPendingTimeout?: boolean;
}

export interface StateFunction {
  setStep: (step?: StepEarlyRedemption) => void;
  setAmount: (amount?: number) => void;
  setProductId: (prodId?: string) => void;
  setEarlyRedeemInfo: (data?: SbnEarlyRedempInfo) => void;
  setOrderCode: (code?: string) => void;
  setOrderCodeRedeem: (code?: string) => void;
  setRedeemBankAt: (at?: string) => void;
  setOpenPendingTimeout: (pendingTimeout: boolean) => void;
}

/**
 * State Context
 */
const EarlyRedemptionStateContext = React.createContext<undefined | State>(
  undefined
);

/**
 * State-Function Context
 */
const EarlyRedemptionFunctionContext = React.createContext<
  undefined | StateFunction
>(undefined);

/**
 * Provider Context
 */
const EarlyRedemptionProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [step, setStep] = useState<undefined | StepEarlyRedemption>();
  const [amount, setAmount] = useState<number | undefined>();
  const [productid, setProductId] = useState<undefined | string>();
  const [earlyRedeemInfo, setEarlyRedeemInfo] = useState<
    SbnEarlyRedempInfo | undefined
  >();
  const [orderCode, setOrderCode] = useState<undefined | string>();
  const [orderCodeRedeem, setOrderCodeRedeem] = useState<string | undefined>();
  const [redeemBankAt, setRedeemBankAt] = useState<string | undefined>();

  /** This State will be use, when user Early Redeem SBN */
  const [openPendingTimeout, setOpenPendingTimeout] = useState<
    boolean | undefined
  >();

  return (
    <EarlyRedemptionStateContext.Provider
      value={{
        step,
        amount,
        productid,
        earlyRedeemInfo,
        orderCode,
        orderCodeRedeem,
        redeemBankAt,
        openPendingTimeout,
      }}
    >
      <EarlyRedemptionFunctionContext.Provider
        value={{
          setStep,
          setAmount,
          setProductId,
          setEarlyRedeemInfo,
          setOrderCode,
          setOrderCodeRedeem,
          setRedeemBankAt,
          setOpenPendingTimeout,
        }}
      >
        {children}
      </EarlyRedemptionFunctionContext.Provider>
    </EarlyRedemptionStateContext.Provider>
  );
};

export default EarlyRedemptionProvider;

export const useContextEarlyRedemptionState = () => {
  const ctx = React.useContext(EarlyRedemptionStateContext);

  if (!ctx) {
    throw new Error('EarlyRedemptionStateContext out of boundary');
  }

  return ctx;
};

export const useContextEarlyRedemptionStateFunction = () => {
  const ctx = React.useContext(EarlyRedemptionFunctionContext);

  if (!ctx) {
    throw new Error('EarlyRedemptionFunctionContext out of boundary');
  }

  return ctx;
};
