import * as React from 'react';
import iconLight from './light.svg';
import iconDark from './dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: iconLight,
  dark: iconDark,
  default: iconLight,
};

export const IconUSD: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default IconUSD;
