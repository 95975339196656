import * as React from 'react';
import { ThemedImage } from '@bibitid/uikit-v1';
import light from './light.svg';
import dark from './dark.svg';

const themeImageMapping = {
  light: light,
  dark: dark,
  default: light,
};

export const ModalIlustration: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default ModalIlustration;
