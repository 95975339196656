import { useUserInInstitution } from 'features/institution/hooks';
import { requestInfinitePagination } from 'network/middleware';
import { useInfiniteQuery, useQuery } from 'react-query';
import { GetTotalProductsParams } from '../types';
import {
  getRedemptionBanks,
  getProductsFilter,
  getSpecificRedemptionBank,
} from './services';

const LIMIT_PAGE_REDEMPTION_BANKS = 10;

export const useGetRedemptionBanks = (
  page?: number,
  limit = LIMIT_PAGE_REDEMPTION_BANKS,
  enabled: boolean = true
) => {
  const fetch = ({ pageParam = 1 }) => {
    return requestInfinitePagination(pageParam, () =>
      getRedemptionBanks(pageParam, limit)
    );
  };

  return useInfiniteQuery(['Redemption Banks', limit, page], fetch, {
    enabled,
    getNextPageParam: (lastPage) => {
      const limitPerPage = limit;

      if (lastPage.data.data.length >= limitPerPage) {
        const nextPageNumber = lastPage?.next_page;
        return nextPageNumber;
      }
    },
  });
};

export const useGetSpecificRedemptionBank = (
  bankId: number,
  enable: boolean = true
) => {
  return useQuery(
    ['Specific Redemption Bank', bankId],
    () => getSpecificRedemptionBank(bankId),
    {
      enabled: enable,
    }
  );
};

export const useGetProductsFilter = (
  params: GetTotalProductsParams,
  enabled = true
) => {
  /** Params without undefined value */
  const paramsFormatting: any = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) paramsFormatting[key] = value;
  });

  const inInstitution = useUserInInstitution();

  if (inInstitution) {
    paramsFormatting.currency = 'idr';
  }

  return useQuery(
    ['Products Filter', paramsFormatting],
    () => getProductsFilter(paramsFormatting),
    {
      enabled,
    }
  );
};
