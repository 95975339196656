import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useSelector } from 'react-redux';
import { TRootReducers } from 'store/root';

const ErrorModal = React.lazy(() => import('./IndexErrorModal'));

/**
 * Lazying import Error Modal
 */
const LazyErrorModal: React.FC<React.PropsWithChildren<unknown>> = ({
  ...rest
}) => {
  const visible = useSelector(
    (state: TRootReducers) => state?.errorModal?.visible
  );

  return <LazyModal component={ErrorModal} visible={visible} {...rest} />;
};

export default LazyErrorModal;
