import classNames from 'classnames';
import React from 'react';
import './style.css';
import { SkeletonProps } from './type';

/**
 * @param width width of skeleton (default: "100%")
 * @param height height of skeleton (default: 9)
 * @param style additional CSS-in-JS properties if any
 * @param className additional classname if any
 * @example
 * <SkeletonLoading width={10} height={9} />
 * // will render '10px width' and '9px height' of skeleton
 *
 * <SkeletonLoading width={'90%'} height={10} />
 * // will render '90% width' and '10px height' of skeleton
 */
const SkeletonLoading: React.FC<React.PropsWithChildren<SkeletonProps>> = ({
  width = '100%',
  height = 9,
  style = {},
  className = '',
  borderRadius = 8,
}) => {
  const customSize: React.CSSProperties = {
    width: width,
    height: height,
    borderRadius,
  };

  return (
    <div
      className={classNames('bit-loading-skeleton', className)}
      style={{ ...customSize, ...style }}
      data-testid='loading-skeleton'
    />
  );
};

export default SkeletonLoading;
