/**
 * List of URL-pathname that we dont want to SHOW PIN in Level Application
 */
export const WHITELIST_URL = [
  '/change-pin',
  '/setup-pin',
  '/profile/settings/pin',
  '/profile/settings/change-pin',
  '/profile/settings/forget-pin',
  '/profile/settings/forget-pin/:code',
  '/giftcard/detail',
  '/bank-account-selfie',
  '/bank-account',
  '/orders/linkaja-verification',
  '/profile/settings/changebank/liveness-verification',
  '/profile/settings/changebank/liveness-results',
  '/auth/verification/:code',
  '/profile/settings/change-email/:code',
  '/profile/settings/change-phone/:code',
  '/profile/settings/change-bank/:code',
  '/profile/settings/changebank/upload/identity',
  '/profile/settings/changebank/unique/code',
  '/profile/settings/changebank/amend/form',
  '/giftcard/:code',
  '/forget-phone/verification/:code',
];

/**
 * @description handle urls that are affected by using `WHITELIST_URL`.
 * Case: if the route that has been created is the same level path as the dynamic route,
 * then it will also be considered a dynamic route when checking `WHITELIST_URL`
 *
 * Example:
 * - whitelisted url = /giftcard/:code
 * - created url = /giftcard/design
 *
 * `design` is considered part of the dynamic params `code`
 *
 * @todo this is temporary solution, need more discussion to handle this issue
 */
export const IGNORE_WHITELIST_URL: Record<string, string[]> = {
  '/giftcard/:code': [
    '/giftcard/design',
    '/giftcard/order',
    '/giftcard/detail',
  ],
};
