import React from 'react';
import numbro from 'numbro';
import * as stringHelper from 'utils/stringHelper';
import { ArrowRight } from 'react-feather';
import classNames from 'classnames';
import styles from './PercentageFormat.module.css';

interface PercentageFormatProps {
  value?: number;
  format?: numbro.Format;
}

const PercentageFormat = ({ value, format }: PercentageFormatProps) => {
  return (
    <div className={styles['percentage-format']}>
      <div
        className={classNames(styles['percentage'], {
          [styles['percentage-red']]: (value || 0) < 0,
          [styles['percentage-green']]: (value || 0) > 0,
          [styles['percentage-zero']]: (value || 0) === 0,
        })}
      >
        <ArrowRight size={12} />
      </div>
      <div>
        {value === undefined
          ? '-'
          : stringHelper.numberToGeneralValueFormat(Math.abs(value), {
              thousandSeparated: true,
              output: 'percent',
              spaceSeparated: false,
              mantissa: 2,
              ...format,
            })}
      </div>
    </div>
  );
};

export default PercentageFormat;
