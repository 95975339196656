import React from 'react';
import classNames from 'classnames';
import './InfoBanner.css';

interface InfoBannerProps {
  /** Info Banner Types */
  type: 'default' | 'warning';
  className?: string;
}

const InfoBanner: React.FC<React.PropsWithChildren<InfoBannerProps>> = ({
  type = 'default',
  className,
  children,
}) => {
  return (
    <div
      className={classNames('bit-banner-info', className, {
        warning: type === 'warning',
      })}
    >
      {children}
    </div>
  );
};

export default InfoBanner;
