import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

import * as Aws from 'core/Aws';
import Analytics from 'utils/Analytics';
import getSafely from 'utils/safely';

import queryClient from 'network/queryClient';
import { GET_PROFILE_QUERY_KEY } from 'hooks/useProfileData';
import { postUploadEDD } from 'services/user';

import { uploadToS3 } from 'utils/s3/upload';
import { base64ToFile } from 'utils/fileReader';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import { removeError } from 'features/registration/registerAccountForm.reducer';

const defaultState = {
  is_fetching_upload: false,
  is_fetching: false,
  err: '',
  err_upload: '',
  images: [],
  files: [],
  redirect: 0,
  output: '',
};

const [
  removeImage,
  changeOutput,
  changeImageFile,
  changeImage,
  request,
  failure,
  success,
  resetRedirectEDD,
] = [
  'REMOVE_EDD_IMAGE',
  'CHANGE_EDD_OUTPUT',
  'CHANGE_EDD_IMAGEFILE',
  'CHANGE_EDD_IMAGE',
  'LOAD_EDD_REQUEST',
  'LOAD_EDD_FAILURE',
  'LOAD_EDD_SUCCES',
  'RESET_EDD_STATUS',
].map(createAction);

const [requestUpload, failureUpload, successUpload] = [
  'UPLOAD_EDD_REQUEST',
  'UPLOAD_EDD_FAILURE',
  'UPLOAD_EDD_SUCCES',
].map(createAction);

const reducer1 = {
  [removeImage]: (state) => {
    return update(state, {
      images: { $set: [] },
      files: { $set: [] },
    });
  },
  [changeOutput]: (state, payload) => {
    const { url } = payload;
    return update(state, {
      output: { $set: url },
    });
  },
  [changeImageFile]: (state, payload) => {
    const { value } = payload;
    const { url } = value;
    const newState = update(state, {
      images: { $set: [url] },
      files: { $set: [value] },
    });
    return newState;
  },
  [changeImage]: (state, payload) => {
    const { key, value } = payload;
    return update(state, {
      images: {
        [key]: { $set: value },
      },
    });
  },
  [request]: (state) => {
    return update(state, {
      is_fetching: { $set: true },
      err: { $set: null },
    });
  },
  [failure]: (state, payload) => {
    const { err } = payload;
    return update(state, {
      is_fetching: { $set: false },
      err: { $set: err },
    });
  },
  [success]: (state, payload) => {
    const { output } = payload;
    return update(state, {
      is_fetching: { $set: false },
      redirect: { $set: 1 },
      output: { $set: output },
    });
  },
  [resetRedirectEDD]: (state) => {
    return update(state, {
      redirect: { $set: 0 },
    });
  },
};

const reducer2 = {
  [requestUpload]: (state) => {
    return update(state, {
      is_fetching_upload: { $set: true },
      err_upload: { $set: null },
    });
  },
  [failureUpload]: (state, payload) => {
    const { err } = payload;
    return update(state, {
      is_fetching_upload: { $set: false },
      err_upload: { $set: err },
    });
  },
  [successUpload]: (state) => {
    return update(state, {
      is_fetching_upload: { $set: false },
    });
  },
};

const mergeReducer = Object.assign(reducer1, reducer2);

const reducer = createReducer(mergeReducer, defaultState);

export {
  resetRedirectEDD,
  removeImage,
  changeImage,
  changeImageFile,
  changeOutput,
};

export function postEddImage(isUpdate = false, profileDetail) {
  return async (dispatch, getState) => {
    const active = profileDetail?.active;
    const images = getSafely(['edd', 'images'], getState());

    dispatch(requestUpload());

    Analytics.logEventAction({
      eventName: 'data_registration_action',
      parameter: {
        action: 'send_picture',
        context: 'register.index',
        trigger: 'click',
        data: {
          file: 'Selfie',
        },
      },
    });

    let base64Image = images[0];

    // Format file name
    const ext = Aws.getExtFromBase64(base64Image);
    let defaultFileName = 'edd' + ext;
    let fileName = Aws.formatUploadFileName(defaultFileName) || defaultFileName;

    // Convert base64 to File
    let rawFile = base64Image;
    let mimeType = base64Image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)?.[0];
    if (!mimeType) throw new Error('Unrecognized file type');

    const file = await base64ToFile(rawFile, fileName, mimeType);

    try {
      const uploaded = await uploadToS3(file, {
        fileName,
        uploadType: 'edd',
      }).then((res) => res.data?.data);

      let fileUrl = uploaded.file_url;

      dispatch(successUpload());
      dispatch(request());
      dispatch(removeError({ key: 'edd' }));

      // if isUpdate true, dont upload to service userEddUpload
      if (active !== 4 || isUpdate) {
        return dispatch(
          success({
            output: fileUrl,
          })
        );
      }

      await postUploadEDD({ url: fileUrl }).then(() => {
        queryClient.refetchQueries(GET_PROFILE_QUERY_KEY);
      });

      dispatch(success({ output: fileUrl }));
      //
    } catch (err) {
      let errMessage = err.response?.data?.message || 'Something went wrong...';

      if (!!err.request) {
        errMessage =
          'Koneksi kamu tidak stabil. Silakan coba beberapa saat lagi.';
      }

      dispatch(
        openErrorModal({
          type: 'EDD_PROCESSED',
          message: errMessage,
        })
      );

      return dispatch(failureUpload({ err: errMessage }));
    }
  };
}

export default reducer;
