import { dispatch } from 'store/redux';
import Crisp from 'core/Crisp/utils';
import { AxiosHTTPError } from './http.type';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { handleRefreshTokenAxios } from 'core/refresh-token';
import { forceLogoutUser } from 'core/refresh-token/utils';
import { forceLogoutSentryLogger } from './utils';
import history from 'utils/History';
import { findScopeByPathname } from 'core/token/UserAccessScope';
import { getEnv } from 'core/env';
import {
  getCurrentInstitution,
  removeCurrentInstitution,
} from 'features/institution/utils';
import { sendToDebugger } from 'devtools/components/tools/NativeDebugger/NativeDebuggerProvider';
import { generalLogInfo } from 'utils/Analytics';

const { PublicUrl } = getEnv();

/**
 * validate error response
 * 
 * // interceptors when we get response REST-API
   // for now we just handling when error occur > 400
 */
const responseErrorInterceptors = async (
  err: AxiosHTTPError
): Promise<void> => {
  // check is error come to Axios
  const errorAxios = axios.isAxiosError(err);

  // @ Handling error Javascript / Non-Axios
  if (!errorAxios) {
    // for now we just error
    return;
  }

  // @ Handling interceptors error from Axios

  /** If the request is cancelled, do nothing here and let axios reject the original error */
  if (err?.code === AxiosError.ERR_CANCELED) {
    return;
  }

  /** http response status code */
  const httpStatus = err?.response?.status;

  /** error message type generic from response API  */
  const errType = err?.response?.data?.type;

  /** http target url */
  const pathName = err?.config?.url;

  /** http config method */
  const method = err?.config?.method || '';

  /** Network error id */
  const networkErrID = `[${method?.toUpperCase()}] ${pathName}`;

  if (err) {
    sendToDebugger({
      idevent: networkErrID,
      status: 'timeout',
      statusNetwork: httpStatus,
      response: err?.response?.data,
      request: err?.config?.data,
      type: 'network',
    });
  }

  /** Error message generic from response API */
  const errMessage =
    err?.response?.data?.message || 'Terjadi kesalahan. Coba lagi kembali.';

  // @ Handling Maintenance Mode
  if (httpStatus === 503) {
    dispatch(
      openErrorModal({
        type: 'MAINTENANCE',
        message: 'Silakan coba beberapa saat lagi.',
      })
    );
    return;
  }

  // @ Handling system error >= 500
  if (httpStatus && httpStatus >= 500) {
    dispatch(
      openErrorModal({
        type: 'SYSTEM_ERROR',
        message: errMessage,
      })
    );

    return;
  }

  // @ Log sentry who get Froce Logged Out.
  forceLogoutSentryLogger(err);

  // @ when user has suspicious login activity.
  if (httpStatus === 422 && errType === 'suspicious_login_activities') {
    dispatch(
      openErrorModal({
        type: errType,
        message: errMessage,
        buttonConfig: {
          text: 'Hubungi Support',
          onClick: () => {
            Crisp.showLiveSupport();
          },
        },
      })
    );

    return;
  }

  // @ force logout when Core API response 422 in REST-API `/auth/token`
  //   refresh_token_failed --> ignore this error type, because we sure that we success refresh token in firstplace.
  if (
    httpStatus === 422 &&
    pathName === '/auth/token' &&
    errType !== 'refresh_token_failed'
  ) {
    await forceLogoutUser();
    return;
  }

  // handle unautorized user based on firebase app check
  if (
    httpStatus === 403 &&
    ['client_not_authorized', 'unknown_client_firebase_app_check'].includes(
      errType ?? ''
    )
  ) {
    return dispatch(
      openErrorModal({
        type: 'USER_UNAUTHORIZED',
      })
    );
  }

  // @ Handling User Suspended
  if (
    httpStatus === 403 &&
    !['token_inactive', 'forbidden_require_pin'].includes(errType ?? '')
  ) {
    dispatch(
      openErrorModal({
        type: 'USER_SUSPENDED',
        message: errMessage,
      })
    );

    return;
  }

  const userDefinedAccess = findScopeByPathname(history.location.pathname);

  if (
    httpStatus === 403 &&
    errType === 'forbidden_require_pin' &&
    history.location.pathname !== '/verify-pin'
  ) {
    if (!userDefinedAccess) {
      return dispatch(
        openErrorModal({
          type: errType,
          message: errMessage,
          buttonConfig: {
            text: 'Hubungi Support',
            onClick: () => {
              Crisp.showLiveSupport();
            },
          },
        })
      );
    }

    return history.push('/verify-pin', {
      endpoint: pathName,
    });
  }

  // @ Handling token invalid 401
  if (httpStatus === 401) {
    // Potential case where users who try to login with pin
    // might have trusted device token expired (after 10 minutes),
    // In this case, we'll redirect them to login page.
    if (history.location.pathname === '/login/verify-pin') {
      return history.replace('/login');
    }

    await handleRefreshTokenAxios(err.config, { refetch: true });
    return;
  }

  const institution = getCurrentInstitution();
  const isInstitutionAccount = !!institution?.institution_id;

  // @ Handling error institution `institution_id_incorrect`
  // Remove current institution storage and redirect into home when got error `institution_id_incorrect`.
  // This condition will be handled case like this :
  // - user loggedin as institution account
  // - admin institution deactived user account
  // - and then when user request api `/institutions/*`, api will be send error response `institution_id_incorrect`
  if (
    isInstitutionAccount &&
    httpStatus === 422 &&
    errType === 'institution_id_incorrect'
  ) {
    removeCurrentInstitution();
    return window.location.assign(`${PublicUrl}/`);
  }

  // @ Handling Timeout axios exceeed
  // ECONNABORTED --> aborted by Server Backend
  // ETIMEDOUT --> client exceed timeout
  if (
    [AxiosError.ECONNABORTED, AxiosError.ETIMEDOUT].includes(err.code ?? '')
  ) {
    generalLogInfo(`AxiosError_${err?.code ?? ''}`, err);
    return dispatch(
      openErrorModal({
        type: 'TIMEOUT',
        message: 'Kondisi Internet Kamu Sedang Tidak Stabil.',
      })
    );
  }

  // If code reach here. the error would handled by specific module
  // so we just reject the error object

  return;
};

export const responseInterceptors = async (
  res: AxiosResponse
): Promise<void> => {
  /** Set networkID: [GET] /portfolio */
  const url = res.config.url || '';
  const method = res.config.method || '';
  const status = res.status || 404;
  const networkID = `[${method.toUpperCase()}] ${url}`;

  /** Update network response to native debugger */
  sendToDebugger({
    idevent: networkID,
    status: 'success',
    statusNetwork: status,
    response: res?.data,
    request: res?.config?.data,
    type: 'network',
  });

  return;
};

export default responseErrorInterceptors;
