/**
 * Use this to cached value that rarely changed from native, this function will store it in-memory
 * @param {function} fn original function that return value
 * @param {function} fnValidateValue function to validate if value is valid and lock that value if 'true'
 */
function cacheLayerFunction(fn, fnValidateValue) {
  let cacheFlag = false;
  let cacheValue = '';

  let _fnValidateValue = (cacheValue) =>
    typeof cacheValue == 'boolean' && !!cacheValue;

  if (typeof fnValidateValue === 'function') _fnValidateValue = fnValidateValue;

  return async () => {
    if (!cacheFlag) {
      cacheValue = await fn();

      if (_fnValidateValue(cacheValue)) cacheFlag = true;
    }
    return cacheValue;
  };
}

export default cacheLayerFunction;
