import React, { useState } from 'react';

interface RecurringTopUpContextDefaultState {
  createRecurring: boolean;
  expiredDate?: Date;
  frequency: string;
  chosenDay: string;
  chosenDate: number[];
  visibleRecurringConnectModal: boolean;
}

interface RecurringTopUpContextDefaultFunction {
  setCreateRecurring: (recurring: boolean) => void;
  setExpiredDate: (date: Date | undefined) => void;
  setFrequency: (freq: string) => void;
  setChosenDay: (day: string) => void;
  setChosenDate: (date: number[]) => void;
  resetStateRecurringTopUp: () => void;
  setVisibleRecurringConnectModal: (
    visibleRecurringConnectModal: boolean
  ) => void;
}

const RecurringTopUpContextState = React.createContext<
  RecurringTopUpContextDefaultState | undefined
>(undefined);

const RecurringTopUpContextFunction = React.createContext<
  RecurringTopUpContextDefaultFunction | undefined
>(undefined);

/**
 * Context Provider related to Recurring IN Top UP Modal Only
 */
const RecurringTopUpContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [createRecurring, setCreateRecurring] = useState(false);
  const [expiredDate, setExpiredDate] = useState<Date | undefined>(undefined);
  const [frequency, setFrequency] = useState('Bulanan');
  const [chosenDate, setChosenDate] = useState([1]);
  const [chosenDay, setChosenDay] = useState('Senin');
  const [
    visibleRecurringConnectModal,
    setVisibleRecurringConnectModal,
  ] = useState<boolean>(false);

  /**
   * Handle Reset State execept createRecurring
   */
  const handleResetState = () => {
    setExpiredDate(undefined);
    setFrequency('Bulanan');
    setChosenDate([0]);
    setChosenDay('Senin');
    setVisibleRecurringConnectModal(false);
  };

  return (
    <RecurringTopUpContextState.Provider
      value={{
        createRecurring,
        expiredDate,
        frequency,
        chosenDate,
        chosenDay,
        visibleRecurringConnectModal,
      }}
    >
      <RecurringTopUpContextFunction.Provider
        value={{
          setCreateRecurring,
          setExpiredDate,
          setFrequency,
          setChosenDate,
          setChosenDay,
          resetStateRecurringTopUp: handleResetState,
          setVisibleRecurringConnectModal,
        }}
      >
        {children}
      </RecurringTopUpContextFunction.Provider>
    </RecurringTopUpContextState.Provider>
  );
};

export default RecurringTopUpContextProvider;

export const useRecurringTopUpContextState = () => {
  const ctx = React.useContext(RecurringTopUpContextState);
  if (!ctx) {
    throw new Error(
      'useRecurringTopUpContextState must be used within a TopUpGlobalContextProvider'
    );
  }
  return ctx;
};

export const useRecurringTopUpContextFunction = () => {
  const ctx = React.useContext(RecurringTopUpContextFunction);
  if (!ctx) {
    throw new Error(
      'RecurringTopUpContextFunction must be used within a TopUpGlobalContextProvider'
    );
  }
  return ctx;
};
