import clevertap from 'clevertap-web-sdk';
import {
  LogActionParameterTypes,
  LogDebugParameterTypes,
  LogNavigationParameterTypes,
} from 'utils/Analytics/types';

type EventProps = {
  eventName: string;
  parameter:
    | LogActionParameterTypes
    | LogNavigationParameterTypes
    | LogDebugParameterTypes;
};

/**
 * record user data on login
 */
export const clevertapRecordUser = (props: Record<string, any>) => {
  return clevertap.onUserLogin.push({
    Site: {
      ...props,
    },
  });
};

/**
 * logout user
 */
export const clevertapLogoutUser = () => {
  return clevertap.logout();
};

/**
 * track event
 */
export const clevertapTrackEvent = (
  _eventKey: 'navigate' | 'action' | 'debug',
  { eventName, parameter }: EventProps
) => {
  const parameterData =
    typeof parameter.data === 'object' ? parameter.data : {};

  let evtName = eventName;
  let additionalParameter: Record<string, any> = {};

  if (_eventKey === 'navigate') {
    const param = parameter as LogNavigationParameterTypes;
    evtName = `${eventName}_${param.page}_${param.view}`;
  }

  if (_eventKey === 'action') {
    const param = parameter as LogActionParameterTypes;
    additionalParameter.user_action = param.action;
  }

  const params = {
    ...parameter,
    ...parameterData,
    ...additionalParameter,
  };

  delete params.data;

  return clevertap.event.push(evtName, params);
};
