/**
 * Get object value safely if the object is deeply nested
 * @template {T}
 * @param {Array<string|number>} keys - Object key you want to traverse
 * @param {Object|null|undefined} object - The targeted object
 * @param {T=} defaultValue - Default value
 * @returns {T|null|undefined}
 */
export default function getSafely(keys, object, defaultValue) {
  return keys.reduce(
    (xs, x) =>
      xs && xs[x] !== undefined
        ? xs[x]
        : defaultValue !== undefined
        ? defaultValue
        : undefined,
    object
  );
}
