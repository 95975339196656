import { AxiosResponse } from 'axios';
import { BibitApiAxiosResponse, BibitApiSuccessResponse } from 'services';
import * as Parent from 'core/Parent';
import list from '../../../services/index';
import {
  ListRecurring,
  ListRecurringPorto,
  PortoRecurring,
  BodyRecurringMutable,
  DetailRecurring,
  ProductMaxBuy,
  RecurringExecDate,
  PromoRecurring,
} from '../../../services/recurring.type';
import { PaymentMethod } from '../../../services/payment.type';
import { endPoints } from './endpoint';
import http from 'core/http';

/**
 * GET: fetch list data recurring
 */
export function getPortoRecurring(): Promise<
  AxiosResponse<BibitApiSuccessResponse<ListRecurring[]>>
> {
  return http.get(endPoints.recurringPortfolio);
}

/**
 * GET: fetch list data recurring by portfolio
 * @param {string} portoId
 */
export function getRecurringListPorto(
  portoId: string
): Promise<AxiosResponse<BibitApiSuccessResponse<ListRecurringPorto>>> {
  return http.get(endPoints.recurringListByPorto(portoId));
}

/**
 * POST: Delete recurring
 */
export function postDeleteRecurring({
  deleteRecurringTarget,
  forceDelete = false,
}: {
  /**
   * recurring id
   */
  deleteRecurringTarget: number;
  /**
   * force delete when challenge is on going
   */
  forceDelete?: boolean;
}) {
  return http.post(endPoints.recurringDelete(deleteRecurringTarget), {
    is_force: forceDelete,
  });
}

/**
 * GET: fetch available porto which can add recurring
 */
export function getAvailablePortoRecurring(): Promise<
  AxiosResponse<BibitApiSuccessResponse<PortoRecurring[]>>
> {
  return http.get(endPoints.recurringAvailablePorto);
}

/**
 * POST: execute create recurring by data
 * @param {BodyRecurringMutable} body
 */
export function postCreateRecurring(body: BodyRecurringMutable) {
  return http.post(
    endPoints.recurringAdd,
    body,
    {},
    {
      headers: {
        'x-secure-key': body.secure_key ?? '',
      },
    }
  );
}

/**
 * POST: execute create recurring native by data
 * @param {BodyRecurringMutable} body
 */
export function postCreateRecurringNative(body: BodyRecurringMutable) {
  return new Promise((resolve, reject) => {
    return Parent.postData(
      'postCreateRecurringNative',
      {
        fn: 'postCreateRecurringNative',
        data: body,
        timeout: 0,
        check_expiration: true,
      },
      (error, data) => {
        // This block below somehow not run due to our current postmessage
        // architecture. The alternative is asking our native devs to
        // return error object as key in returned data
        // TODO: restructure post message so this error argument is useful
        if (error) {
          return reject(error);
        }

        if (data?.error) {
          /**
           * check error object type
           */
          const errorIsObject = typeof data?.error === 'object';

          /**
           * normalize error data to object
           */
          const errorData = errorIsObject
            ? data?.error
            : { message: data?.error };

          return reject({
            response: {
              data: errorData,
            },
          });
        }
        // We're mapping this to unify service response data structure,
        // as if it is from axios
        return resolve({
          data: {
            data,
            message: 'Success',
          },
        });
      }
    );
  });
}

/**
 * POST: execute create Overview recurring before confirmation
 * @param {BodyRecurringMutable} body
 */
export function postOverviewRecurring(
  body: BodyRecurringMutable
): BibitApiAxiosResponse<RecurringExecDate> {
  return http.post(endPoints.recurringOverview, body);
}

/**
 * GET: Recurring detail
 * @param {string} recurringId
 */
export function getRecurringDetail(
  recurringId: string
): Promise<AxiosResponse<BibitApiSuccessResponse<DetailRecurring>>> {
  return http.get(endPoints.recurringDetail(recurringId));
}

/**
 * POST: update recurring
 * @param {string} recurringId
 * @param {object} data
 */
export function postUpdateRecurring(
  recurringId: string,
  body: BodyRecurringMutable
) {
  return http.post(
    endPoints.recurringChange(recurringId),
    body,
    {},
    {
      headers: {
        'x-secure-key': body.secure_key ?? '',
      },
    }
  );
}

/**
 * POST: execute update recurring native by data
 * @param {string} recurringId
 * @param {object} data
 */
export function postUpdateRecurringNative(
  recurringId: string,
  body: BodyRecurringMutable
) {
  return new Promise((resolve, reject) => {
    return Parent.postData(
      'postUpdateRecurringNative',
      {
        fn: 'postUpdateRecurringNative',
        data: {
          recurringId,
          body,
        },
        timeout: 0,
        check_expiration: true,
      },
      (error, data) => {
        // This block below somehow not run due to our current postmessage
        // architecture. The alternative is asking our native devs to
        // return error object as key in returned data
        // TODO: restructure post message so this error argument is useful
        if (error) {
          return reject(error);
        }

        if (data?.error) {
          /**
           * check error object type
           */
          const errorIsObject = typeof data?.error === 'object';

          /**
           * normalize error data to object
           */
          const errorData = errorIsObject
            ? data?.error
            : { message: data?.error };

          return reject({
            response: {
              data: {
                ...errorData,
                message: 'Upss! Penjadwalan gagal dibuat. Mohon coba kembali',
              },
            },
          });
        }

        // We're mapping this to unify service response data structure,
        // as if it is from axios
        return resolve({
          data: {
            data,
            message: 'Success',
          },
        });
      }
    );
  });
}

export interface ParamPaymentRecurring {
  amount: number;
  recurring?: number;
  product_symbol?: string;
  robo_id?: string;
  portfolio_id?: number;
  v?: number;
}

/**
 * Get List payment Recurring
 * @param param
 */
// TODO: consider convert to getPaymentMehtodRaw
export function getPaymentRecurring(
  param: ParamPaymentRecurring
): Promise<AxiosResponse<BibitApiSuccessResponse<PaymentMethod[]>>> {
  return http.get(list.getPaymentMethod, { ...param });
}

/**
 * Reactivate recurring
 */
export const postRecurringReactivate = (recurring_id: number) => {
  return http.post(endPoints.recurringReactivate, { recurring_id });
};

/**
 * Reactivate recurring native
 */
export const postRecurringReactivateNative = (recurring_id: number) => {
  return new Promise((resolve, reject) => {
    return Parent.postData(
      'postRecurringReactivateNative',
      {
        fn: 'postRecurringReactivateNative',
        data: recurring_id,
        timeout: 0,
        check_expiration: true,
      },
      (error, data) => {
        // This block below somehow not run due to our current postmessage
        // architecture. The alternative is asking our native devs to
        // return error object as key in returned data
        // TODO: restructure post message so this error argument is useful

        if (error) {
          return reject(error);
        }

        if (data?.error) {
          /**
           * check error object type
           */
          const errorIsObject = typeof data?.error === 'object';

          /**
           * normalize error data to object
           */
          const errorData = errorIsObject
            ? data?.error
            : { message: data?.error };

          return reject({
            response: {
              data: {
                errorData,
                message: 'Upss! Penjadwalan gagal dibuat. Mohon coba kembali',
              },
            },
          });
        }

        // We're mapping this to unify service response data structure,
        // as if it is from axios
        return resolve({
          data: {
            data,
            message: 'Success',
          },
        });
      }
    );
  });
};

/**
 * Get product max buy
 */
export function getProductMaxBuy(): Promise<
  AxiosResponse<BibitApiSuccessResponse<ProductMaxBuy>>
> {
  return http.get(endPoints.getProductMaxBuy);
}

/**
 * Get promo list & user autodebet tracking
 */
export function getRecurringPromo(): Promise<
  AxiosResponse<BibitApiSuccessResponse<PromoRecurring[]>>
> {
  return http.get(endPoints.getRecurringPromo);
}

export function postCreateRecurringChallenge(
  recurring_id: number,
  promo_id: number
) {
  return http.post(endPoints.createRecurringChallenge, {
    recurring_id,
    promo_id,
  });
}

export function patchResetRecurring(recurring_id: number) {
  return http.patch(endPoints.recurringReset(recurring_id));
}
