import { endpoints } from './endpoints';
import http from 'core/http';
import { AxiosResponse } from 'axios';
import * as Parent from 'core/Parent';
import { BibitApiAxiosResponse } from 'services';
import { openThirdPartyWebView } from 'utils/thirdPartyWebview';

export interface BuyGiftCardAPIPayload {
  category: string; // '1';
  template: string; // '2';
  amount: number; // 500000;
  message: string; // 'Test';
  payment_method: string; // 'gopay';
  sender: string; // 'Sender Name';
}

export interface BuyGiftCardAPIResponse {
  message: string; // 'Your order submitted';
  data: {
    invoice_number: string; // 'PIGC282647HCQTA';
    payment_method: string; // 'gopay';
    payment_status: number; // 0;
    ordertime: string; // '2022-11-03T02:33:20.000Z';
    expiredtime: string; // '2022-11-03T02:38:20.000Z';
    amount: number; // 500000;
    category: number; // 1;
    template: number; // 2;
    sender: string; // 'Sender Name';
    message: string; // 'Test';
    status: string; // 'pending';
    created: string; // '2022-11-03T02:33:20.000Z';
    payment_detail: {
      status_code: string; // '201';
      status_message: string; // 'GoPay transaction is created';
      transaction_id: string; // '0b8dc1b7-34e4-45cc-8926-2b342af729bb';
      order_id: string; // 'PIGC282647HCQTA';
      merchant_id: string; // 'G616288261';
      gross_amount: string; // '500000.00';
      currency: string; // 'IDR';
      payment_type: string; // 'gopay';
      transaction_time: string; // '2022-11-03T02:33:20.000Z';
      transaction_status: string; // 'pending';
      fraud_status: string; // 'accept';
      actions: [
        {
          name: string; // 'generate-qr-code';
          method: string; // 'GET';
          url: string; // 'https://api.sandbox.midtrans.com/v2/gopay/0b8dc1b7-34e4-45cc-8926-2b342af729bb/qr-code';
        },
        {
          name: string; // 'deeplink-redirect';
          method: string; // 'GET';
          url: string; // 'https://simulator.sandbox.midtrans.com/gopay/partner/app/payment-pin?id=fc98e640-d395-4e7e-9dc9-c5024160d0b1';
        },
        {
          name: string; // 'get-status';
          method: string; // 'GET';
          url: string; // 'https://api.sandbox.midtrans.com/v2/0b8dc1b7-34e4-45cc-8926-2b342af729bb/status';
        },
        {
          name: string; // 'cancel';
          method: string; // 'POST';
          url: string; // 'https://api.sandbox.midtrans.com/v2/0b8dc1b7-34e4-45cc-8926-2b342af729bb/cancel';
        }
      ];
    };
  };
}

interface GiftCardStats {
  total_almost_expired: number;
  total_received: number;
  total_sent: number;
}

/**
 * GET: fetch list data received-giftcard
 */
export const getReceivedGiftCards = (params?: {
  cursor?: string;
  limit?: string;
}) => {
  return http.get(endpoints.giftCard, params);
};

/**
 * GET: fetch list data send-giftcard
 */
export const getSendGiftCards = (params?: {
  cursor?: string;
  limit?: string;
}) => {
  return http.get(endpoints.giftCardSend, params);
};

/**
 * Ask native container to request buy gifcard to Bibit API
 * @param {Object} requestData - buy gift card request data
 * @returns {Promise<any>}
 */
export const postBuyGiftCardNative = (requestData: BuyGiftCardAPIPayload) => {
  return new Promise((resolve, reject) => {
    return Parent.postData(
      'postBuyGiftCardNative',
      {
        fn: 'postBuyGiftCardNative',
        data: requestData,
        timeout: 0,
        check_expiration: true,
      },
      (error, data) => {
        // This block below somehow not run due to our current postmessage
        // architecture. The alternative is asking our native devs to
        // return error object as key in returned data
        // TODO: restructure post message so this error argument is useful
        if (error) {
          return reject(error);
        }

        if (data?.error) {
          /**
           * check error object type
           */
          const errorIsObject = typeof data?.error === 'object';

          /**
           * normalize error data to object
           */
          const errorData = errorIsObject
            ? data?.error
            : { message: data?.error };

          return reject({
            response: {
              data: errorData,
            },
          });
        }

        // We're mapping this to unify service response data structure,
        // as if it is from axios
        return resolve({
          data: {
            data,
            message: 'Success',
          },
        });
      }
    );
  });
};

/**
 * Ask native container to request buy gifcard with Jago SNAP to Bibit API
 * @param {Object} requestData - buy gift card request data
 * @returns {Promise<any>}
 */
export const postBuyGiftCardJagoSNAP = (requestData: BuyGiftCardAPIPayload) => {
  return new Promise((resolve, reject) => {
    return http
      .post(endpoints.giftCardBuy, requestData)
      .then(async (res) => {
        const { authentication_webview_url } = res?.data?.data?.payment_detail;
        const redirect_url = [`.*giftcard.*`];

        const webviewRes = await openThirdPartyWebView({
          url: authentication_webview_url,
          exit_url: redirect_url,
        });

        // We're mapping this to unify service response data structure,
        // as if it is from axios
        return resolve({
          data: {
            data: {
              ...res?.data?.data,
              ...webviewRes,
            },
            message: 'Success',
          },
        });
      })
      .catch((err) => reject(err));
  });
};

/**
 * Submit buy giftcard
 *
 * - Payment method with linkaja will be redirect into `/giftcard-linkaja-verifications`
 */
export const postBuyGiftCard = (
  payload: BuyGiftCardAPIPayload
): Promise<AxiosResponse<BuyGiftCardAPIResponse>> => {
  return http.post(endpoints.giftCardBuy, payload);
};

/**
 * Submit buy giftcard V2
 *
 * - Payment method with linkaja will be redirect into `/giftcard/linkaja/verifications`
 */
export const postBuyGiftCardV2 = (
  payload: BuyGiftCardAPIPayload
): Promise<AxiosResponse<BuyGiftCardAPIResponse>> => {
  return http.post(`${endpoints.giftCardBuy}?v=2`, payload);
};

/**
 *  get git card notification counter
 */
export const getGiftCardStats = (): BibitApiAxiosResponse<GiftCardStats> =>
  http.get(endpoints.giftCardStats);
