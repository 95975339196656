import storage from 'core/Storage';
import { useQuery } from 'react-query';
import * as Parent from 'core/Parent';
import { isJSON } from 'utils/validator';
import { OVERRIDE_REMOTE_CONFIG_STORAGE } from './constants/common';
import {
  defaultRemoteConfigValue,
  RemoteConfig,
} from './constants/defaultRemoteConfigValue';

/**
 * Get remote config value from firebase server
 * @see https://firebase.google.com/docs/remote-config
 */
export const getRemoteConfigValues = () => {
  return new Promise<RemoteConfig>((resolve) => {
    return Parent.postData(
      'getRemoteConfigValue',
      {
        fn: 'getRemoteConfigValue',
        data: defaultRemoteConfigValue,
        timeout: 2000,
      },
      (error, data) => {
        if (error || !data) {
          return resolve(defaultRemoteConfigValue);
        }

        return resolve(data);
      }
    );
  });
};

/**
 * Remote Config Hooks
 */
export const useRemoteConfigQuery = () => {
  const query = useQuery(['remoteConfig'], getRemoteConfigValues);

  return query;
};

/**
 * Get data override remote config from local storage
 */
export const useOverrideRemoteConfigQuery = (enabled?: boolean) => {
  const { data: overrideRemoteConfigDataStorage, ...query } = useQuery(
    OVERRIDE_REMOTE_CONFIG_STORAGE,
    async () => await storage.get(OVERRIDE_REMOTE_CONFIG_STORAGE),
    {
      enabled,
    }
  );

  // check JSON data from storage
  const overrideRemoteConfigData: Partial<RemoteConfig> =
    overrideRemoteConfigDataStorage && isJSON(overrideRemoteConfigDataStorage)
      ? JSON.parse(overrideRemoteConfigDataStorage)
      : overrideRemoteConfigDataStorage;

  // make sure return data is object
  const overrideRemoteConfig =
    typeof overrideRemoteConfigData === 'object'
      ? overrideRemoteConfigData
      : {};

  return {
    ...query,
    data: overrideRemoteConfig as Partial<RemoteConfig>,
  };
};
