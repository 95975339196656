import LazyModal from 'features/common/LazyModal';
import React from 'react';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

const OnboardingInvestmentTypeModalLazy = React.lazy(
  () => import('./OnboardingInvestmentTypeModal')
);

const OnboardingInvestmentTypeModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'choose_portfolio_type';

  return (
    <LazyModal
      component={OnboardingInvestmentTypeModalLazy}
      visible={visible}
    />
  );
};

export default OnboardingInvestmentTypeModal;
