import React, { lazy } from 'react';
import LazyLoad from 'features/common/lazyload';

const SharedPortfolioDetailLazy = lazy(() => import('./SharedPortfolioDetail'));

const SharedPortfolioDetail: React.FC<React.PropsWithChildren<unknown>> = (props: any) => (
  <LazyLoad animationLoading component={SharedPortfolioDetailLazy} {...props} />
);

export default SharedPortfolioDetail;
