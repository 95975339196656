import React from 'react';
import ChooseOnboardingIconModal from './ChooseOnboardingIconModal';
import CreateOnboardingPortfolioModal from './CreateOnboardingPortfolioModal';
import EmergencyFundOptionModal from './EmergencyFundOptionModal';
import OnboardingInvestmentTypeModal from './OnboardingInvestmentTypeModal';
import OnboardingRoboConfigModal from './OnboardingRoboConfigModal';
import PortfolioOptionOnboardingModal from './PortfolioOptionOnboardingModal';
import SafestProductModal from './SafestProductModal';
import StartInvestmentOptionModal from './StartInvestmentOptionModal';
import SyariahOptionModal from './SyariahOptionModal';
import {
  BestProductListModal,
  ExploreProductOnboardingModal,
} from './ProductListModal';

/**
 * Onboarding Modals
 * @see https://www.figma.com/file/fGDTzHZUKZwbktD95ptg8V/Revamp-Onboarding?node-id=3680%3A357298
 */
const OnboardingModalFlow: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <StartInvestmentOptionModal />
      <SyariahOptionModal />
      <SafestProductModal />
      <BestProductListModal />
      <EmergencyFundOptionModal />
      <PortfolioOptionOnboardingModal />
      <ChooseOnboardingIconModal />
      <CreateOnboardingPortfolioModal />
      <OnboardingInvestmentTypeModal />
      <OnboardingRoboConfigModal />
      <ExploreProductOnboardingModal />
    </>
  );
};

export default OnboardingModalFlow;
