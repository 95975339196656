import React, { useCallback, useRef, useState } from 'react';
import { CallbackModalProps } from './CallbackModal/CallbackModal';

interface AppModalGlobalState {
  appOnlyModalVisible: boolean;
  continueRegisModalOpen: boolean;
  cashoutSurveyModalVisible: boolean;
  userDeactiveModalOpen: boolean;
  notBuyableProductModalOpen: boolean;
  callbackModal: CallbackModalProps;
}

interface ToggleAppOnlyModalProps {
  action?: 'close' | 'acknowledge';
  onClose?: () => void;
  onAcknowledge?: () => void;
}

interface AppModalGlobalFunctions {
  toggleAppOnlyModal: (params?: ToggleAppOnlyModalProps) => void;
  toggleContinueRegisModal: () => void;
  toggleCashoutSurveyModal: () => void;
  toggleUserDeactiveModal: () => void;
  toggleNotBuyableProductModal: () => void;
  toggleCallbackModal: (data: CallbackModalProps) => void;
}

const AppModalGlobalStateContext = React.createContext<
  AppModalGlobalState | undefined
>(undefined);

export const AppModalGlobalFunctionContext = React.createContext<
  AppModalGlobalFunctions | undefined
>(undefined);

const AppModalGlobalContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [appOnlyModalVisible, setAppOnlyModalVisibility] = useState(false);
  const [continueRegisModalOpen, setContinueRegisModalOpen] = useState(false);
  const [cashoutSurveyModalVisible, setCashoutSurveyModalVisibility] =
    useState(false);
  const [userDeactiveModalOpen, setUserDeactiveModalOpen] = useState(false);
  const [notBuyableProductModalOpen, setNotBuyableProductModalOpen] =
    useState(false);
  const [callbackModal, setCallbackModal] = useState<CallbackModalProps>({
    visible: false,
  });

  /**
   * To prevent an infinite loop in the useEffect of the ArtJakartaPage.tsx component,
   * the onCloseModalFn function was changed from using useState to useRef in order to make it stable.
   * The useRef hook ensures that the function reference remains constant across renders.
   * By doing so, any changes to the function won't cause the useEffect to trigger repeatedly,
   * thus avoiding the infinite loop.
   */
  const onCloseModalFn = useRef<() => void>();
  const onAcknowledgeFn = useRef<() => void>();

  const toggleAppOnlyModal = useCallback(
    ({
      action = 'close',
      onClose,
      onAcknowledge,
    }: ToggleAppOnlyModalProps = {}) => {
      if (onClose) onCloseModalFn.current = onClose;
      if (onAcknowledge) onAcknowledgeFn.current = onAcknowledge;

      setAppOnlyModalVisibility((prevState) => {
        if (prevState) {
          if (action === 'close') {
            onCloseModalFn.current?.();
          } else if (action === 'acknowledge') {
            if (typeof onAcknowledgeFn.current === 'function') {
              onAcknowledgeFn.current();
            } else {
              onCloseModalFn.current?.();
            }
          }

          onCloseModalFn.current = undefined;
          onAcknowledgeFn.current = undefined;
        }

        return !prevState;
      });
    },
    []
  );

  const toggleCallbackModal = (data: CallbackModalProps) => {
    setCallbackModal(data);
  };

  const toggleContinueRegisModal = () => {
    setContinueRegisModalOpen((prevState) => !prevState);
  };

  const toggleCashoutSurveyModal = () => {
    setCashoutSurveyModalVisibility(!cashoutSurveyModalVisible);
  };

  const toggleUserDeactiveModal = () => {
    setUserDeactiveModalOpen((prevState) => !prevState);
  };

  const toggleNotBuyableProductModal = () => {
    setNotBuyableProductModalOpen((prevState) => !prevState);
  };

  const state = {
    appOnlyModalVisible,
    continueRegisModalOpen,
    cashoutSurveyModalVisible,
    userDeactiveModalOpen,
    notBuyableProductModalOpen,
    callbackModal,
  };

  const func = {
    toggleAppOnlyModal,
    toggleContinueRegisModal,
    toggleCashoutSurveyModal,
    toggleUserDeactiveModal,
    toggleNotBuyableProductModal,
    toggleCallbackModal,
  };

  return (
    <AppModalGlobalStateContext.Provider value={state}>
      <AppModalGlobalFunctionContext.Provider value={func}>
        {children}
      </AppModalGlobalFunctionContext.Provider>
    </AppModalGlobalStateContext.Provider>
  );
};

export default AppModalGlobalContextProvider;

export const useAppModalGlobalStateContext = () => {
  const context = React.useContext(AppModalGlobalStateContext);
  if (context === undefined) {
    throw new Error(
      'useAppModalStateGlobalContext must be used within AppModalGlobalContextProvider'
    );
  }
  return context;
};

export const useAppModalGlobalFunctions = () => {
  const context = React.useContext(AppModalGlobalFunctionContext);
  if (context === undefined) {
    throw new Error(
      'useAppModalGlobalFunctions must be used within AppModalGlobalContextProvider'
    );
  }
  return context;
};
