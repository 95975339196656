import React, { useCallback, useState } from 'react';
import { PortoRecurring, PromoRecurring } from 'services/recurring.type.d';
import { ReksaDana } from 'entities/reksadana.reducer';
import { ChoosenPayment } from 'features/recurring/types/recurring.type.d';

interface RecurringContextState {
  chosenPorto?: PortoRecurring;

  chosenReksaDana?: ReksaDana;

  investmentValue?: number;

  chosenDate: number[];

  chosenPaymentMethod: ChoosenPayment;

  frequencyRecurring?: string;

  chosenDay?: string;

  finishRecurring: string;

  expiredDate?: Date;

  stepPage: number;

  executeDate: string;

  loadingOverviewRecurring: boolean;

  promoRecurring?: PromoRecurring;

  createChallengeFailed: boolean;

  convenienceFee?: number;

  recurringErrorScheduler?: boolean;
}

interface RecurringContextFunction {
  setChosenPorto: (data: PortoRecurring) => void;

  setChosenReksaDana: (data?: ReksaDana) => void;

  setInvestmentValue: (num?: number) => void;

  setChosenDate: (num: number[]) => void;

  setChosenPaymentMethod: (dataPayment: ChoosenPayment) => void;

  setFrequencyRecurring: (freq: string) => void;

  setChosenDay: (day: string) => void;

  setFinishRecurring: (val: string) => void;

  setExpiredDate: (date?: Date) => void;

  setStepPage: (step: number) => void;

  setExecuteDate: (exec: string) => void;

  setLoadingOverviewRecurring: (load: boolean) => void;

  handleResetRecurringContext: () => void;

  setPromoRecurring: (promoData: PromoRecurring) => void;

  clearPromoRecurring: () => void;

  setCreateChallengeFailed: (failed: boolean) => void;

  setConvenienceFee: (convenienceFee: number | undefined) => void;

  setRecurringErrorScheduler: (haveScheduler: boolean) => void;
}

// Context State
export const State = React.createContext<RecurringContextState | undefined>(
  undefined
);

// Context Function
const Func = React.createContext<RecurringContextFunction | undefined>(
  undefined
);

/**
 * Provider Context
 */
const RecurringContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [chosenPorto, setChosenPorto] = useState<PortoRecurring | undefined>();
  const [chosenReksaDana, setChosenReksaDana] = useState<
    ReksaDana | undefined
  >();

  const [investmentValue, setInvestmentValue] = useState<number | undefined>();

  /** Chosen date Schedule Recurring if use choose Bulanan */
  const [chosenDate, setChosenDate] = useState([1]);

  /** Payment chosen by user */
  const [chosenPaymentMethod, setChosenPaymentMethod] =
    useState<ChoosenPayment>({ icon: '', key: '', name: '' }); //default payment method

  /** Scedule Type Recurring */
  const [frequencyRecurring, setFrequencyRecurring] = useState('Bulanan');

  /** Chosen Day for Schedule Recurring */
  const [chosenDay, setChosenDay] = useState('Senin');

  /** Determine when recurring Expired */
  const [finishRecurring, setFinishRecurring] = useState('Selamanya');

  /** Date Month of expired recurring */
  const [expiredDate, setExpiredDate] = useState<Date | undefined>();

  /** Step recurring */
  const [stepPage, setStepPage] = useState(1);

  /** Get result data for execute date */
  const [executeDate, setExecuteDate] = useState<string>('');

  /** Loading overview  */
  const [loadingOverviewRecurring, setLoadingOverviewRecurring] =
    useState<boolean>(false);

  /** Get promo recurring data / challenge */
  const [promoRecurring, setPromoRecurring] = useState<
    PromoRecurring | undefined
  >();

  /** Define of create recurring challenge status
   * if status failed, we need to show create challenge modal in step 3 recurring
   */
  const [createChallengeFailed, setCreateChallengeFailed] =
    useState<boolean>(false);

  const [convenienceFee, setConvenienceFee] = useState<number | undefined>(
    undefined
  );

  /**
   * Define visibility of error modal recurring because portfolio already
   * have scheduler. Except for Jago (for Jago adding by query params)
   *
   * @see handleExecuteOrderBuy function in PaymentMethodModal.tsx
   */
  const [recurringErrorScheduler, setRecurringErrorScheduler] =
    useState<boolean>(false);

  /** Reset all states in Context */
  const handleResetRecurringContext = useCallback(() => {
    setChosenDate([1]);
    setChosenDay('Senin');
    setExpiredDate(undefined);
    setChosenPaymentMethod({ icon: '', key: '', name: '' });
    setChosenPorto(undefined);
    setChosenReksaDana(undefined);
    setFinishRecurring('Selamanya');
    setFrequencyRecurring('Bulanan');
    setInvestmentValue(undefined);
    setStepPage(1);
    setExecuteDate('');
    setLoadingOverviewRecurring(false);
    setPromoRecurring(undefined);
    setConvenienceFee(undefined);
    setRecurringErrorScheduler(false);
  }, []);

  const clearPromoRecurring = useCallback(() => {
    setPromoRecurring(undefined);
  }, []);

  return (
    <State.Provider
      value={{
        chosenPorto,
        chosenReksaDana,
        investmentValue,
        chosenDate,
        chosenDay,
        expiredDate,
        finishRecurring,
        chosenPaymentMethod,
        frequencyRecurring,
        stepPage,
        executeDate,
        loadingOverviewRecurring,
        promoRecurring,
        createChallengeFailed,
        convenienceFee,
        recurringErrorScheduler,
      }}
    >
      <Func.Provider
        value={{
          setChosenDate,
          setChosenDay,
          setExpiredDate,
          setChosenPaymentMethod,
          setChosenPorto,
          setChosenReksaDana,
          setFinishRecurring,
          setFrequencyRecurring,
          setInvestmentValue,
          setStepPage,
          setExecuteDate,
          setLoadingOverviewRecurring,
          handleResetRecurringContext,
          setPromoRecurring,
          clearPromoRecurring,
          setCreateChallengeFailed,
          setConvenienceFee,
          setRecurringErrorScheduler,
        }}
      >
        {children}
      </Func.Provider>
    </State.Provider>
  );
};

export default RecurringContextProvider;

export const useRecurringContextState = () => {
  const ctx = React.useContext(State);
  if (!ctx) {
    throw new Error('useRecurringContextState only in page IndexRecurring');
  }
  return ctx;
};

export const useRecurringContextFunction = () => {
  const ctx = React.useContext(Func);
  if (!ctx) {
    throw new Error('useRecurringContextFunction only in page IndexRecurring');
  }
  return ctx;
};
