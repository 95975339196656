import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';

/**
 * Custom hook to check if user is foreigner or not.
 */
const useForeigner = (): boolean => {
  const { isLogin } = useAuth();
  const { data } = useProfileData(isLogin);
  const dataProfile = data?.data.data;

  const sid = dataProfile?.sinvest?.sid ?? '';
  /**
   * User with Indonesian nationality always start with 'IDD
   */
  const isForeigner = sid.toUpperCase().substring(0, 3) !== 'IDD';

  return isForeigner;
};

export default useForeigner;
