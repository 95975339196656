import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { isEmptyObject, _showErrorToast } from 'utils';
import { postRegisterEmail } from 'services/auth';
import queryClient from 'network/queryClient';
import { GET_PROFILE_QUERY_KEY } from 'hooks/useProfileData';

const defaultState = {
  is_fetching: false,
  err: '',
  err_form: '',
  step: 0,
  email: '',
};

const [reset, resetError, incrementStep, request, failure, success] = [
  'RESET_CORRECTEMAIL',
  'RESET_ERROR_CORRECTEMAIL',
  'INCREMENT_CORRECTEMAIL_STEP',
  'LOAD_CORRECTEMAIL_REQUEST',
  'LOAD_CORRECTEMAIL_FAILURE',
  'LOAD_CORRECTEMAIL_SUCCESS',
].map(createAction);

const [changeNewEmail] = ['CHANGE_CORRECTEMAIL_NEWEMAIL'].map(createAction);

const [changePin] = ['CHANGE_CORRECTEMAIL_PIN'].map(createAction);

const reducer = createReducer(
  {
    [changeNewEmail]: (state, payload) => {
      const { email } = payload;
      return update(state, {
        email: { $set: email },
      });
    },
    [changePin]: (state, payload) => {
      const { pin } = payload;
      return update(state, {
        pin: { $set: pin },
      });
    },
    [reset]: (state) => {
      const { is_fetching, err, step } = defaultState;
      return update(state, {
        is_fetching: { $set: is_fetching },
        err: { $set: err },
        step: { $set: step },
        email: { $set: '' },
      });
    },
    [resetError]: (state) => {
      return update(state, {
        err: { $set: '' },
      });
    },
    [incrementStep]: (state) => {
      const { step } = state;
      return update(state, {
        step: { $set: step + 1 },
      });
    },
    [request]: (state) => {
      return update(state, {
        is_fetching: { $set: true },
        err: { $set: '' },
      });
    },
    [failure]: (state, payload) => {
      const { err, err_form } = payload;
      return update(state, {
        is_fetching: { $set: false },
        err: { $set: err },
        err_form: { $set: err_form },
      });
    },
    [success]: (state) => {
      return update(state, {
        is_fetching: { $set: false },
      });
    },
  },
  defaultState
);

export { reset, resetError, changeNewEmail };

export function postUpdateEmail() {
  return (dispatch, getState) => {
    const {
      correctEmail: { email },
    } = getState();

    const toBody = {
      email,
    };

    if (!email)
      return _showErrorToast('Data Email Tidak Boleh Kosong', {
        position: 'relative',
      });

    dispatch(request());

    postRegisterEmail(toBody)
      .then((payload) => {
        if (isEmptyObject(payload)) {
          return dispatch(failure({ err: 'Something is wrong' }));
        }
        dispatch(success());
        queryClient.refetchQueries(GET_PROFILE_QUERY_KEY);
        dispatch(incrementStep());
      })
      .catch((err) => {
        const errForm = err?.response?.data?.errors
          ? err?.response?.data?.errors
          : null;
        const errType = err?.response?.data?.type || '';
        const errMsgGeneral =
          err?.response?.data?.errors?.email?.msg ||
          'Terjadi kesalahan! periksa kembali data yang Kamu masukkan.';

        /** Error message for email that already registered */
        if (!!errType) {
          return dispatch(
            failure({
              err: err?.response?.data?.message,
              err_form: errForm,
            })
          );
        }

        /** General Error Message */
        return dispatch(failure({ err: errMsgGeneral, err_form: errForm }));
      });
  };
}

export default reducer;
