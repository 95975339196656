import React from 'react';
import ReksaDanaGenericCard from './ReksaDanaGenericCard';
import ReksaDanaAUMCard from './ReksaDanaAUMCard';
import ReksaDanaExpenseRatioCard from './ReksaDanaExpenseRatioCard';
import ReksaDanaMaxDrawdownCard from './ReksaDanaMaxDrawdownCard';
import ReksaDanaReturnCard from './ReksaDanaReturnCard';
import ReksaDanaMinimumAmountCard from './ReksaDanaMinimumAmountCard';
import { ReksaDana } from 'entities/reksadana.reducer';
import BenchmarkIndexCard from './BenchmarkIndexCard';
import ReksaDanaAverageYieldCard from './ReksaDanaAverageYieldCard';

interface ReksaDanaCardProps {
  fundData: ReksaDana;
  category?: string;
  isComparing?: boolean;
  showLink?: boolean;
  selected?: boolean;
  sortBy?: string;
  sortPeriod?: string;
  kebabIconConfig?: { show: boolean; onClick: (fundsymbol: string) => void };
  onClick: (roboCategory: string, symbol: string) => void;
  showProductType?: boolean;
  dropdownElement?: any;
  selectedIcon?: boolean;
  rightContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  containerClassname?: string;
  onCustomNavigateMutualFundDetail?: (symbol: string) => void;
}

const ReksaDanaCard: React.FC<React.PropsWithChildren<ReksaDanaCardProps>> = (
  props
) => {
  const { sortBy } = props;
  if (props.category && props.category === 'benchmark') {
    return <BenchmarkIndexCard {...props} />;
  }

  switch (sortBy) {
    case '2':
      // Total AUM
      return <ReksaDanaAUMCard {...props} />;
    case '3':
      return <ReksaDanaExpenseRatioCard {...props} />;
    case '4':
      return <ReksaDanaMaxDrawdownCard {...props} />;
    case '5':
      return <ReksaDanaReturnCard {...props} />;
    case '6':
      return <ReksaDanaMinimumAmountCard {...props} />;
    case '9':
      return <ReksaDanaAverageYieldCard {...props} />;
    default:
      return <ReksaDanaGenericCard {...props} />;
  }
};

export default ReksaDanaCard;
