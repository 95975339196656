import { useShariaProfile } from 'hooks';
import React, { FC, PropsWithChildren } from 'react';
import { Models } from 'rmc-tabs';
import { Tabs } from 'antd-mobile';
import styled from 'styled-components';
import './BestTabs.css';

const WrapperChildren = styled.div`
  overflow: auto;
`;

const tabsContent = [
  {
    id: '1',
    title: 'Pasar Uang',
    type: 'moneymarket',
  },
  {
    id: '2',
    title: 'Obligasi',
    type: 'debt',
  },
  {
    id: '3',
    title: 'Saham',
    type: 'equity',
  },
  {
    id: '4',
    title: 'Syariah',
    type: 'syariah',
  },
];

interface Props {
  onChangeTab?: (data: Models.TabData) => void;
  height?: string;
  heightDesktop?: string;
}

/**
 * Handling custom List of product to make Tab like in `/categories/best/${category}`
 */
const CommonBestTab: FC<PropsWithChildren<Props>> = ({
  children,
  onChangeTab,
}) => {
  const { isShariaPreferenceActive } = useShariaProfile();

  const filteredTabs = tabsContent.filter((d) => {
    // if user NON-syariah get all data
    if (!isShariaPreferenceActive) return true;

    // if syariah, then get data `type` non-syariah
    return d.type !== 'syariah';
  });

  return (
    <div className='common-best-tab'>
      <Tabs
        tabs={filteredTabs}
        tabBarPosition='top'
        tabBarTextStyle={{
          paddingTop: '12px',
          paddingBottom: '12px',
          fontSize: '14px',
          lineHeight: '20px',
          color: '#00AB6B',
          cursor: 'pointer',
          fontWeight: 'normal',
        }}
        tabBarUnderlineStyle={{ borderColor: '#00AB6B' }}
        tabBarActiveTextColor='#00AB6B'
        tabBarInactiveTextColor='#333333'
        tabBarBackgroundColor='var(--surface)'
        onChange={onChangeTab}
      >
        <WrapperChildren>{children}</WrapperChildren>
      </Tabs>
    </div>
  );
};

export default CommonBestTab;
