import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';

import { Text } from '@bibitid/uikit-v1';
import { ReactComponent as Menu } from 'assets/images/shared-portfolio/menu.svg';
import Crown from 'assets/images/shared-portfolio/crown/Crown';

import { numberToGeneralValueFormat } from 'utils/stringHelper';
import { MemberCardData } from '../../types';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';
import { User } from 'react-feather';

type Props = {
  member?: MemberCardData;
  onClick: (event: SyntheticEvent<HTMLDivElement>) => void;
};

const SharedPortfolioMemberCard: React.FC<React.PropsWithChildren<Props>> = ({
  member,
  onClick,
}) => {
  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  /**
   * Bibit Email Verification Status
   */
  const userId: number = profileDetail?.user?.id ?? 0;

  const getMemberInitial = (fullname: string) => {
    return (
      (fullname.split(' ')?.[0] || '').substring(0, 1).toLocaleUpperCase() +
      (fullname.split(' ')?.[1] || '').substring(0, 1).toLocaleUpperCase()
    );
  };

  const userIcon = () => {
    if (member?.is_admin) {
      return <Crown />;
    }
    if (member?.full_name) {
      return (
        <Text type='caption1' color='primary'>
          {getMemberInitial(member?.full_name || '')}
        </Text>
      );
    }
    return <User style={{ padding: 5 }} />;
  };
  return (
    <div className='shared-porto-member-card'>
      <div className='member-card-top'>
        <div
          className={classNames({
            'member-card-top-admin': member?.is_admin,
            'member-card-top-left': true,
          })}
        >
          {userIcon()}
        </div>
        <div className='member-card-top-right'>
          <div>
            <Text type='body2'>{member?.full_name || '-'}</Text>
            {(member?.status === 'pending' || member?.status === 'expire') && (
              <Text type='caption1'>
                {member?.phone}{' '}
                {member?.status === 'expire'
                  ? ' | Expired'
                  : ' | Menunggu Konfirmasi'}
              </Text>
            )}
          </div>
          {userId !== member?.user_id && (
            <div
              data-memberid={member?.member_id}
              data-status={member?.status}
              data-invitationid={member?.invitation_id}
              onClick={onClick}
            >
              <Menu />
            </div>
          )}
        </div>
      </div>
      {member?.status === 'accept' && (
        <>
          <div className='member-card-separator' />
          <div className='member-card-bottom'>
            <Text type='caption1' color='secondary'>
              <span>
                {numberToGeneralValueFormat(member?.percentage, {
                  mantissa: 2,
                })}
                %
              </span>{' '}
              Kontribusi
            </Text>
            <Text type='caption1'>
              Rp
              {numberToGeneralValueFormat(member?.portfolio?.total_porto || 0)}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};

export default SharedPortfolioMemberCard;
