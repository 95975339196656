/**
 * add all variant value options here
 */
export const remoteConfigValueOptions = {
  example: ['preview_1', 'preview_2', 'preview_3', 'preview_4'],
  ROF_abtest_safest_param: ['0', '1', '2'],
  native_bibit_business_registration: [
    '{"enabled":true, "minSupportedVersion": "3.51.2"}',
    '{"enabled":true, "minSupportedVersion": "4.43.0"}',
    '{"enabled":false, "minSupportedVersion": "3.51.2"}',
    '{"enabled":false, "minSupportedVersion": "4.43.0"}',
  ],
  update_app_reminder: [''],
} as const;

type RemoteConfigValueOptions = typeof remoteConfigValueOptions;

const getValue = <Keys extends keyof RemoteConfigValueOptions>(
  key: Keys,
  options?: {
    /** get value from local storage */
    override?: boolean;
    /** select default remote config value based on index of options */
    defaultValue?: number;
  }
) => {
  const selectedKey = remoteConfigValueOptions[key];

  const value = selectedKey[
    options?.defaultValue || 0
  ] as (typeof selectedKey)[number];

  if (options?.override) {
    return {
      overrideValue: value,
    };
  }

  return value;
};

/**
 * Default remote config value.
 * Make sure to set property value enum type based on readonly array, we need this for devtools
 *
 * @example
 * ```js
 *  const exp1 = ['1','2','3']
 *  const defaultRemoteConfigValue = {
 *    exp1: getValue('exp1')
 *  }
 * ```
 */
export const defaultRemoteConfigValue = {
  example: getValue('example'),
  ROF_abtest_safest_param: getValue('ROF_abtest_safest_param', {
    defaultValue: 0,
  }),
  native_bibit_business_registration: getValue(
    'native_bibit_business_registration'
  ),
  update_app_reminder: getValue('update_app_reminder'),
};

export type RemoteConfig = typeof defaultRemoteConfigValue;

export type RemoteConfigKey = keyof RemoteConfigValueOptions;
