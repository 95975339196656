interface FundManagementOptions {
  id: number | undefined;
  name: string;
}

interface RedemptionOptions {
  id: number | undefined;
  name: string;
}

interface TopCompanyOptions {
  id?: number;
  name: string;
}

export const fundManagementOptions: Array<FundManagementOptions> = [
  {
    id: 0,
    name: 'Konvensional',
  },
  {
    id: 1,
    name: 'Syariah',
  },
  {
    id: undefined,
    name: 'Semua',
  },
];

export const redemptionOptions: Array<RedemptionOptions> = [
  {
    id: 1,
    name: 'Pencairan Instan',
  },
  {
    id: undefined,
    name: 'Semua',
  },
];

export const topCompanyOptions: Array<TopCompanyOptions> = [
  {
    id: 1,
    name: 'Top Reksa Dana',
  },
  {
    id: undefined,
    name: 'Semua',
  },
];

export const RDTypeGroup: any = {
  moneymarket: 'type',
  debt: 'type',
  equity: 'type',
  syariah: 'type',
  others: 'type',

  instant: 'collection',
  indexfund: 'collection',
  usd: 'collection',
  minimum: 'collection',

  im: 'mi',
};

export const RDTypeTranslate: any = {
  moneymarket: 'rdpu',
  fixincome: 'rdo',
  equity: 'rds',
};

export const SortByEnum: any = {
  2: 'Total AUM',
  5: 'Return',
  3: 'Expense Ratio',
  4: 'Max Drawdown',
  6: 'Minimum Pembelian',
  9: 'Average Yield',
};
