import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useSelector } from 'react-redux';
import { TRootReducers } from 'store/root';

const ChangeBankWarningModal = React.lazy(
  () => import('./ChangeBankWarningModal')
);

const LazyChangeBankWarningModal = (props: any) => {
  const visible = useSelector(
    (state: TRootReducers) => state.changeBankPage.warningVisible
  );

  return (
    <LazyModal
      component={ChangeBankWarningModal}
      visible={visible}
      {...props}
    />
  );
};

export default LazyChangeBankWarningModal;
