import React from 'react';
import classNames from 'classnames';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import getSafely from 'utils/safely';
import { hasFundDataAdjusted } from 'utils/newRD';
import stringHelper from 'utils/stringHelper';
import { format, parseISO } from 'utils/date';
import { ReksaDana } from 'entities/reksadana.reducer';
import PercentageFormat from '../components/PercentageFormat';

interface CardProps {
  fundData: ReksaDana;
  isComparing?: boolean;
  sortPeriod?: string;
  selected?: boolean;
  onClick: (roboCategory: string, symbol: string) => void;
}

const ReksaDanaReturnCard: React.FC<React.PropsWithChildren<CardProps>> = ({
  fundData,
  isComparing,
  sortPeriod,
  selected,
  onClick,
}) => {
  if (!sortPeriod) return null;

  const { name, nav, investment_manager } = fundData;
  const date = getSafely(['date'], nav, undefined);

  /** Determine is mutual-fund international currency */
  const internationalFund =
    getSafely(['currency_exchange', 'currency'], fundData) === 'USD';

  const getChangesValue =
    hasFundDataAdjusted(fundData, 'changesvalue', sortPeriod) || 0;
  const simplereturn =
    hasFundDataAdjusted(fundData, 'simplereturn', sortPeriod) || 0;

  const changesvalue =
    getChangesValue === 0
      ? '-'
      : stringHelper.numberToPointChangeFormat(getChangesValue, {
          mantissa: internationalFund ? 4 : 2,
        });

  const handleClick = () => {
    const { robocategory, symbol } = fundData;
    onClick(robocategory, symbol);
  };

  return (
    <div
      className={classNames({
        'bit-filter-result-row': true,
        'bit-filter-return': true,
        'card-selected': selected,
        'card-reksa-shake': isComparing,
      })}
    >
      <div onClick={handleClick}>
        <div className='bit-filter-reksa'>
          <div className='bit-filter-reksa-pic'>
            <InvestmentCompanyLogo
              type='investment-manager'
              size='square'
              ojkCode={getSafely(['ojkCode'], investment_manager, '')}
            />
          </div>
          <div>
            <h3>{name}</h3>
            <p>
              {!!date ? 'Updated ' + format(parseISO(date), 'd MMM yy') : '-'}
            </p>
          </div>
        </div>
        <div
          className={classNames('bit-filter-right-result', {
            'minus-result': simplereturn < 0,
            null_result: simplereturn === 0,
          })}
        >
          <p>{changesvalue}</p>
          <div className='bit-font-12'>
            {simplereturn === undefined ? (
              '-'
            ) : (
              <PercentageFormat
                value={simplereturn}
                format={{ mantissa: internationalFund ? 4 : 2 }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReksaDanaReturnCard;
