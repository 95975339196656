import LazyLoad from 'features/common/lazyload';
import React from 'react';

const SBNSyncLazy = React.lazy(() => import('./SBNSyncRoute'));

const SBNSync = (props: any) => {
  return <LazyLoad component={SBNSyncLazy} animationLoading {...props} />;
};

export default SBNSync;
