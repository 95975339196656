import * as React from 'react';
import useTheme from 'hooks/useTheme';

/**
 * HOC for using ThemeContext
 * @param WrappedComponent - class components that needs HOC Wrapping
 *
 * TODO: We will remove it when there are no more class components using this hoc
 */
const withTheme = (WrappedComponent: any) => (props: any) => {
  const { theme, setTheme } = useTheme();

  const finalProps = {
    ...props,
    theme,
    setTheme
  };

  return <WrappedComponent {...finalProps} />;
};

export default withTheme;
