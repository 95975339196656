import React from 'react';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import getSafely from 'utils/safely';
import classNames from 'classnames';
import { ReksaDana } from 'entities/reksadana.reducer';
import { numberToPercentagePointChangeFormat } from 'utils/stringHelper';

interface CardProps {
  fundData: ReksaDana;
  isComparing?: boolean;
  selected?: boolean;
  onClick: (roboCategory: string, symbol: string) => void;
}

const ReksaDanaAverageYieldCard: React.FC<
  React.PropsWithChildren<CardProps>
> = ({ fundData, isComparing, selected, onClick }) => {
  const { name, type, avg_yield, investment_manager } = fundData;

  const averageYield: number = getSafely(['percentage'], avg_yield);
  const averageYieldText = numberToPercentagePointChangeFormat(averageYield, {
    forceSign: false,
  });

  const handleClick = () => {
    const { robocategory, symbol } = fundData;
    onClick(robocategory, symbol);
  };

  return (
    <div
      className={classNames({
        'bit-filter-result-row': true,
        'bit-filter-expanse': true,
        'card-selected': selected,
        'card-reksa-shake': isComparing,
      })}
    >
      <div onClick={handleClick}>
        <div className='bit-filter-reksa'>
          <div className='bit-filter-reksa-pic'>
            <InvestmentCompanyLogo
              type='investment-manager'
              size='square'
              ojkCode={getSafely(['ojkCode'], investment_manager, '')}
            />
          </div>
          <div>
            <h3>{name}</h3>
            <p>{type}</p>
          </div>
        </div>
        <div
          className={classNames('bit-filter-right-result no-min-width', {
            null_result: averageYieldText === '-',
          })}
        >
          <p>{averageYieldText}</p>
        </div>
      </div>
    </div>
  );
};

export default ReksaDanaAverageYieldCard;
