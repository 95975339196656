import React, { Suspense } from 'react';
import ChunkLoadErrorBoundary from 'features/common/errors/ChunkLoadErrorBoundary';
import Loading from 'features/common/loader/Loading';

/**
 * LazyLoadWrapper Props
 */
type Props = {
  /**
   * custom fallback component on suspends
   */
  loadingComponent?: React.ReactNode;
};

/**
 * React Lazy Wrapper for Pages / Components
 */
const LazyLoadWrapper: React.FC<React.PropsWithChildren<Props>> = ({ children, loadingComponent }) => (
  <ChunkLoadErrorBoundary>
    <Suspense fallback={loadingComponent || <Loading />}>{children}</Suspense>
  </ChunkLoadErrorBoundary>
);

export default LazyLoadWrapper;
