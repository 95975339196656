import * as React from 'react';
import Light from './organization-icon-modal.svg';
import Dark from './organization-icon-modal-dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: Light,
  dark: Dark,
  default: Light,
};

export const OrganizationIcon: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default OrganizationIcon;
