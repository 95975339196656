import React, { useState, useCallback } from 'react';

export const ModalDataRenewalContext = React.createContext<any>({});

const ModalDataRenewalProvider: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const [modalState, setModalState] = useState({
    visibility: true,
    type: '',
    payload: {},
    notifications: {},
  });

  const resetModal = useCallback(() => {
    setModalState({
      visibility: true,
      type: '',
      payload: {},
      notifications: {},
    });
  }, []);

  return (
    <ModalDataRenewalContext.Provider
      value={{ modalState, setModalState, resetModal }}
    >
      {children}
    </ModalDataRenewalContext.Provider>
  );
};

export const useModalRenewalContext = () => {
  const context = React.useContext(ModalDataRenewalContext);
  if (context === undefined) {
    throw new Error(
      'useModalRenewalContext must be used within ModalDataRenewalProvider'
    );
  }
  return context;
};

export default ModalDataRenewalProvider;

interface Props {
  children: React.ReactNode;
}
