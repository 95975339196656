import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

import {
  handleResponseErrorCase,
  handleResponseGeneral,
} from 'utils/http-response';
import * as Aws from 'core/Aws';
import { openErrorModal } from '../../features/errors/errorModal.reducer';
import getSafely from '../../utils/safely';

const defaultState = {
  is_fetching_upload: false,
  is_fetching: false,
  err: '',
  err_upload: '',
  images: [],
  files: [],
  redirect: 0,
  output: '',
};

const [
  removeImage,
  changeOutput,
  changeImage,
  changeImageFile,
  request,
  failure,
  success,
  resetRedirect,
] = [
  'REMOVE_BANKACCOUNTSELFIE_IMAGE',
  'CHANGE_BANKACCOUNTSELFIE_OUTPUT',
  'CHANGE_BANKACCOUNTSELFIE_IMAGE',
  'CHANGE_BANKACCOUNTSELFIE_IMAGEFILE',
  'LOAD_BANKACCOUNTSELFIE_REQUEST',
  'LOAD_BANKACCOUNTSELFIE_FAILURE',
  'LOAD_BANKACCOUNTSELFIE_SUCCES',
  'RESET_BANKACCOUNTSELFIE_STATUS',
].map(createAction);

const [requestUpload, failureUpload, successUpload] = [
  'UPLOAD_BANKACCOUNTSELFIE_REQUEST',
  'UPLOAD_BANKACCOUNTSELFIE_FAILURE',
  'UPLOAD_BANKACCOUNTSELFIE_SUCCESS',
].map(createAction);

const reducer1 = {
  [removeImage]: (state) => {
    return update(state, {
      images: { $set: [] },
      files: { $set: [] },
    });
  },
  [changeImageFile]: (state, payload) => {
    const { key, value } = payload;
    const { url } = value;
    return update(state, {
      images: {
        [key]: { $set: url },
      },
      files: {
        [key]: { $set: value },
      },
    });
  },
  [changeImage]: (state, payload) => {
    const { key, value } = payload;
    return update(state, {
      images: {
        [key]: { $set: value },
      },
    });
  },
  [changeOutput]: (state, payload) => {
    const { url } = payload;
    return update(state, {
      output: { $set: url },
    });
  },
  [request]: (state) => {
    return update(state, {
      is_fetching: { $set: true },
      err: { $set: null },
      output: { $set: '' },
    });
  },
  [failure]: (state, payload) => {
    const { err } = payload;
    return update(state, {
      is_fetching: { $set: false },
      err: { $set: err },
    });
  },
  [success]: (state, payload) => {
    const { output } = payload;
    return update(state, {
      is_fetching: { $set: false },
      redirect: { $set: 1 },
      output: { $set: output },
    });
  },
  [resetRedirect]: (state) => {
    return update(state, {
      redirect: { $set: 0 },
    });
  },
};

const reducer2 = {
  [requestUpload]: (state) => {
    return update(state, {
      is_fetching_upload: { $set: true },
      err_upload: { $set: null },
    });
  },
  [failureUpload]: (state, payload) => {
    const { err } = payload;
    return update(state, {
      is_fetching_upload: { $set: false },
      err_upload: { $set: err },
    });
  },
  [successUpload]: (state) => {
    return update(state, {
      is_fetching_upload: { $set: false },
    });
  },
};

const mergeReducer = Object.assign(reducer1, reducer2);

const reducer = createReducer(mergeReducer, defaultState);

export {
  resetRedirect,
  removeImage,
  changeImage,
  changeImageFile,
  changeOutput,
};

export function postImage() {
  return (dispatch, getState) => {
    const {
      // entities: {
      // 	user: {
      // 		profile: {
      // 			id: id_user,
      // 		}
      // 	}
      // },
      changeBankAccount: {
        form: { id: id_user },
      },
      bankaccountselfie: { images },
    } = getState();
    const token = getSafely(
      ['changeBankAccount', 'form', 'token'],
      getState(),
      null
    );

    let image = images[0];

    const ext = Aws.getExtFromBase64(image);
    let default_filename = 'bankaccountselfie' + ext;
    let filename = Aws.formatUploadFileName(default_filename);
    let path_filename = 'bankaccount/' + id_user + '/' + filename;
    dispatch(requestUpload());
    const dataImageP = Aws.createBase64UploadPromise(
      path_filename,
      image,
      true,
      {
        uploadfor: 'bank_selfie',
        code: id_user,
        token,
      }
    );

    dataImageP.then((response) => {
      dispatch(successUpload());
      dispatch(request());

      const { data } = handleResponseGeneral(response);

      const { url } = data;

      dispatch(
        success({
          output: url,
        })
      );
    });

    handleResponseErrorCase(dataImageP, (errObj) => {
      const { err, err_form } = errObj;
      const errorModalObj = {
        type: 'BANKACCOUNT_PROCESSED',
        message: err,
      };
      dispatch(openErrorModal(errorModalObj));
      return dispatch(failureUpload({ err, err_form }));
    });
  };
}

export default reducer;
