import React, { useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';
import Puller from 'features/common/Puller';
import { Text } from '@bibitid/uikit-v1';
import SortAndCompareButton from '../SortAndCompareButton';
import PaddingHorizontal from 'features/common/PaddingHorizontal';
import ReksaDanaCard from '../cards/ReksaDanaCard';
import { WhiteSpace } from '@bibitid/uikit';
import { useSelector, useDispatch } from 'react-redux';
import getSafely from 'utils/safely';
import { hasFundDataAdjusted } from 'utils/newRD';
import Storage from 'core/Storage';
import { ReksaDana } from 'entities/reksadana.reducer';
import {
  compareResetState,
  compareRemoveProduct,
  compareAddProduct,
} from 'features/compare/compare.reducer';
import {
  discoverPageFilterSetType,
  discoverPageFilterIsSyariah,
  discoverPageSetSortBy,
  getFilteredProduct,
  discoverPageReset,
  discoverPageSetPage,
  discoverPageSetSize,
  discoverPageSetMeta,
  discoverPageFilterMaxBuy,
  discoverPageSetIM,
  discoverPageSetCurrency,
  discoverPageSetInstantRedemption,
  discoverPageSetIsTopProduct,
} from 'features/discover/discoverPage.reducer';
import { useHistory, useParams } from 'react-router-dom';

import Analytics from 'utils/Analytics';
import AnimatedInstantLogo from 'assets/images/bank-jago/instant-redemption/animated-instant-logo/animated-instant-logo';
import InstantLogo from 'assets/images/bank-jago/instant-redemption/instant-logo/InstantLogo';
import { IntroInstantRedemptionModal } from 'features/bankjago/components/modals';
import './CategorizedReksaDanaList.css';
import { useFlag } from 'utils/feature-flag';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';
import { useTopUpGlobalContext } from 'features/topup-modal/TopupGlobalContext';
import { isNew } from 'utils/newRD';
import { useUserInInstitution } from 'features/institution/hooks';
import { useTheme, useToggle } from 'hooks';
import InstantLogoOriginal from 'assets/images/bank-jago/instant-redemption/instant-logo-original/InstantLogoOriginal';
import TopIcon from './assets/top-icon.svg';
import { BenchmarkTitle } from './CategorizedReksaDanaList.styled';
import ProductNotFound from 'assets/images/explore/product-not-found/ProductNotFound';
import BibitPlusCategoryBanner, {
  CategoryBannerProps,
} from 'features/category/component/banners/BibitPlusCategoryBanner';
import TopCompanyInfoModal from 'features/common/modals/TopCompanyInfoModal/TopCompanyInfoModal';
import Show from 'features/common/components/Show/Show';
import CommonBestTab from 'features/explore/pages/ExploreBestPage/Tabs/CommonBestTab';

export interface ReksaDanaCardListProps {
  funds: ReksaDana[];
  showLink?: boolean;
  selected?: string | string[];
  loading?: boolean;
  loadMoreLoading: boolean;
  sortBy?: string;
  sortPeriod?: string;
  isSorting?: boolean;
  isComparing?: boolean;
  onCardClick: (roboCategory: string, symbol: string) => void;
  category?: string;
  handleShowInstantModal?: React.MouseEventHandler<HTMLDivElement> | undefined;
  handleShowTopCompanyModal?: () => void;
  ableToSelectProduct?: boolean;
  onCustomNavigateMutualFundDetail?: (symbol: string) => void;
  showLoading?: boolean;
  toggleIRModal?: () => void;
  /**
   * default: true
   */
  showTopProductRibbon?: boolean;
}

interface SortAndCompareButtonParams {
  type: string;
  im?: string;
  category?: string;
}

export const ReksaDanaCardList: React.FC<
  React.PropsWithChildren<ReksaDanaCardListProps>
> = ({
  funds,
  showLink,
  selected,
  loading,
  loadMoreLoading,
  sortBy,
  sortPeriod,
  isSorting,
  isComparing,
  category,
  onCardClick,
  handleShowInstantModal,
  handleShowTopCompanyModal,
  ableToSelectProduct,
  onCustomNavigateMutualFundDetail,
  showLoading = true,
  showTopProductRibbon = true,
}) => {
  const instantRedemptionActiveByFF: boolean = useFlag(
    'web_jago_instant_redemption'
  );

  const { theme } = useTheme();

  const [firstTimeVisitInstant, setFirstTimeVisitInstant] = useState(false);

  const institution = useUserInInstitution();

  const isMoneyMarketPage = category === 'moneymarket';
  const isInstantPage = category === 'instant';

  const findFirstInstantRedemptionItem = funds?.find(
    (item) => item?.is_instant_redemption
  );

  const isSelected = (symbol: string) => {
    if (typeof selected === 'string') return selected === symbol;
    return Array.isArray(selected) ? selected.includes(symbol) : false;
  };

  /** Show Empty state when sorting */
  const isShowEmptyState = !loading && funds?.length === 0;

  const undefinedDataArray: ReksaDana[] = [];

  const showProductType = ['syariah', 'minimum', 'im', 'indexfund'].includes(
    category || ''
  );

  /** Show or hide tooltip on moneymarket or instant page */
  useEffect(() => {
    // Guard if instant redemption isn't active by feature flag
    if (!instantRedemptionActiveByFF) return;

    if (isMoneyMarketPage || isInstantPage) {
      Storage.getObject('hasUserVisitedInstantRedemption').then(
        (hasVisited) => {
          if (hasVisited) {
            setFirstTimeVisitInstant(false);
          } else {
            setFirstTimeVisitInstant(true);
            Storage.setObject('hasUserVisitedInstantRedemption', 'true');
          }
        }
      );
    }
  }, [isMoneyMarketPage, isInstantPage, instantRedemptionActiveByFF]);

  // Return nothing
  if (!Array.isArray(funds)) {
    return null;
  }

  // Category benchmark
  if (category === 'benchmark') {
    const equityBenchmark = funds.filter(
      (item) => item.index_type && item.index_type === 'Saham'
    );
    const bondBenchmark = funds.filter(
      (item) => item.index_type && item.index_type === 'Obligasi'
    );
    return (
      <PaddingHorizontal>
        {Array.isArray(funds) && funds.length > 0 && (
          <>
            {Array.isArray(funds) && equityBenchmark.length > 0 && (
              <>
                {/*For Benchmark Saham*/}
                <BenchmarkTitle>Benchmark Saham</BenchmarkTitle>
                {Array.isArray(funds) &&
                  equityBenchmark.map((item) => {
                    return (
                      <React.Fragment key={item.symbol}>
                        <ReksaDanaCard
                          category={category}
                          isComparing={isComparing}
                          showLink={showLink}
                          fundData={item}
                          selected={isSelected(item.symbol)}
                          sortBy={isSorting ? sortBy : undefined}
                          sortPeriod={sortPeriod}
                          onClick={onCardClick}
                        />
                        <WhiteSpace className='bit-uikit-custom' />
                      </React.Fragment>
                    );
                  })}
              </>
            )}
            {Array.isArray(funds) && bondBenchmark.length > 0 && (
              <>
                {/* For benchmark Obligasi */}
                <BenchmarkTitle>Benchmark Obligasi</BenchmarkTitle>
                {Array.isArray(funds) &&
                  bondBenchmark.map((item) => (
                    <React.Fragment key={item.symbol}>
                      <ReksaDanaCard
                        category={category}
                        isComparing={isComparing}
                        showLink={showLink}
                        fundData={item}
                        selected={isSelected(item.symbol)}
                        sortBy={isSorting ? sortBy : undefined}
                        sortPeriod={sortPeriod}
                        onClick={onCardClick}
                      />
                      <WhiteSpace className='bit-uikit-custom' />
                    </React.Fragment>
                  ))}
              </>
            )}
          </>
        )}
        {!!loading && showLoading && (
          <div className='boxloader'>
            <div className='spinner' />
          </div>
        )}
        {!!isComparing && <div style={{ paddingBottom: 230 }} />}
      </PaddingHorizontal>
    );
  }

  // All Categories except 'benchmark'
  return (
    <PaddingHorizontal>
      {/* Empty State */}
      {isShowEmptyState && (
        <div className='empty-state-category-page'>
          <ProductNotFound />
          <Text type='body2' className='margin-bottom-8 margin-top-16'>
            Tidak Ada Produk
          </Text>
          <Text
            type='body1'
            color='secondary'
            className='empty-state-category-page-subtitle'
          >
            {isSorting
              ? 'Produk yang kamu cari tidak ditemukan.'
              : 'Tidak ada produk yang tersedia dalam kategori ini.'}
          </Text>
        </div>
      )}

      {/* Card list */}
      {!loading &&
        Array.isArray(funds) &&
        funds.map((item, index) => {
          let undefinedDataExist = false;
          /** Tooltip is only shown for the first instant redemption item  */
          const firstInstantRedemptionItem = instantRedemptionActiveByFF
            ? findFirstInstantRedemptionItem?.id === item?.id
            : false;

          /** Extra margin top for tooltip */
          const extraMarginTopForTooltip =
            firstInstantRedemptionItem && firstTimeVisitInstant && index === 0;

          /** Flag to show or hide tooltip on moneymarket or instant page */
          const showInstantTooltip =
            (isMoneyMarketPage || isInstantPage) &&
            firstInstantRedemptionItem &&
            firstTimeVisitInstant;

          const isInstantRedemptionItem = item?.is_instant_redemption;

          /** Right Content of ReksaDana Card contains:
           * Instant Redemption Tooltip
           * Instant Logo
           */
          const RightContentElement = (e?: any) => {
            const showTopCompanyLabel =
              !!item?.is_top_product && !!showTopProductRibbon;

            const handleClickTopCompanyRibbon = () => {
              Analytics.logEventAction({
                eventName: 'search_action',
                parameter: {
                  action: 'company',
                  context: 'search.general',
                  trigger: 'click',
                  data: {
                    company_id: item.id,
                  },
                },
              });
              !!handleShowTopCompanyModal && handleShowTopCompanyModal();
            };

            const showRightInstantLogo =
              isInstantRedemptionItem &&
              !selected &&
              !ableToSelectProduct &&
              !institution;
            if (!showRightInstantLogo && !isNew(item) && showTopCompanyLabel)
              return (
                <div
                  className='top-label-rd-wrapper'
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className='top-label-rd'
                    onClick={() => handleClickTopCompanyRibbon()}
                  >
                    <img src={TopIcon} alt='top' />
                    <span>Top</span>
                  </div>
                </div>
              );

            // Render Instant Redemption Content
            return (
              <div
                className='porto-card-right-side'
                style={{ marginLeft: 6, display: 'flex' }}
                onClick={(e) => e.stopPropagation()}
              >
                {/* New Logo */}
                {isNew(item) && (
                  <span
                    className={classNames('new-label-rd', {
                      'margin-right-6': showRightInstantLogo,
                    })}
                  >
                    New
                  </span>
                )}

                <Show when={!!showRightInstantLogo}>
                  <div className='relative'>
                    <Show when={showInstantTooltip}>
                      <div
                        className={classNames('tooltip-pencairan-instan', {
                          'top-rd': showTopCompanyLabel,
                        })}
                      >
                        <Text color={'white'} type='caption1'>
                          Cairkan produk secara instan
                        </Text>
                      </div>
                    </Show>
                    <div
                      className=''
                      onClick={handleShowInstantModal}
                      style={{ height: 20 }}
                    >
                      {/* FTUE use animated logo, if not FTUE use static instant logo */}
                      {firstTimeVisitInstant ? (
                        <AnimatedInstantLogo
                          alt='instant'
                          data-testid={`instant-logo-${item.id}`}
                        />
                      ) : (
                        <InstantLogo alt='instant' />
                      )}
                    </div>
                  </div>
                </Show>

                {/* Top Company Logo */}
                {showTopCompanyLabel && (
                  <div
                    className='top-label-rd-wrapper'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className='top-label-rd'
                      onClick={handleClickTopCompanyRibbon}
                    >
                      <img src={TopIcon} alt='top' />
                      <span>Top</span>
                    </div>
                  </div>
                )}
              </div>
            );
          };

          /**
           * Footer Content of ReksaDana Card:
           * Instant redemption footer
           * @returns Element | null
           */
          const FooterContentElement = () => {
            // Guard if instant redemption isn't active by feature flag
            if (!instantRedemptionActiveByFF) return null;

            const showFooterInstantContent =
              isInstantRedemptionItem &&
              (selected || ableToSelectProduct) &&
              !institution;

            if (showFooterInstantContent) {
              return (
                <div
                  className={
                    theme === 'light'
                      ? 'instant-redemption-footer-card'
                      : 'instant-redemption-footer-card dark'
                  }
                >
                  <InstantLogoOriginal alt='instant' />
                  <Text
                    className='greenval'
                    type='caption1'
                    style={{ marginLeft: '8px' }}
                  >
                    Pencairan Instan
                  </Text>
                </div>
              );
            }

            return null;
          };

          // Checking sorting if data value is undefined, move it to the last of the list
          if (isSorting) {
            switch (sortBy) {
              // Expense Ratio
              case '3':
                const { expenseratio } = item;
                const expenseratioNumber: number =
                  getSafely(['percentage'], expenseratio, 0) || 0;
                if (expenseratioNumber === 0) {
                  undefinedDataArray.push(item);
                  undefinedDataExist = true;
                }
                break;
              //Max Draw Down
              case '4':
                const { maxdrawdown } = item;
                const maxDrawdownNumber: number = getSafely(
                  ['sort_value'],
                  item,
                  0
                )
                  ? getSafely(['sort_value'], item, 0) || 0
                  : getSafely([`${sortPeriod}`], maxdrawdown, 0);
                if (maxDrawdownNumber === 0) {
                  undefinedDataArray.push(item);
                  undefinedDataExist = true;
                }
                break;
              // Return
              case '5':
                if (!sortPeriod) return null;
                const changesValue: number =
                  hasFundDataAdjusted(item, 'changesvalue', sortPeriod) || 0;
                const simplereturn: number =
                  hasFundDataAdjusted(item, 'simplereturn', sortPeriod) || 0;
                if (changesValue === 0 && simplereturn === 0) {
                  undefinedDataArray.push(item);
                  undefinedDataExist = true;
                }
                break;
            }
          }

          return (
            <React.Fragment key={`fragment-wrap-${item.symbol}`}>
              {!undefinedDataExist && (
                <React.Fragment key={item.symbol}>
                  <ReksaDanaCard
                    showProductType={showProductType}
                    category={category}
                    isComparing={isComparing}
                    showLink={showLink}
                    fundData={item}
                    selected={isSelected(item.symbol)}
                    selectedIcon={isSelected(item.symbol)}
                    sortBy={isSorting ? sortBy : undefined}
                    sortPeriod={sortPeriod}
                    onClick={onCardClick}
                    rightContent={<RightContentElement />}
                    footerContent={<FooterContentElement />}
                    containerClassname={classNames({
                      'margin-top-30': extraMarginTopForTooltip,
                      'with-footer': FooterContentElement() !== null,
                    })}
                    onCustomNavigateMutualFundDetail={
                      onCustomNavigateMutualFundDetail
                    }
                  />
                  <WhiteSpace className='bit-uikit-custom' />
                </React.Fragment>
              )}

              {/* If it's the last data, return list of data with undefined value */}
              {index + 1 === funds.length &&
                Array.isArray(undefinedDataArray) &&
                undefinedDataArray.map((undefinedItem) => {
                  return (
                    <React.Fragment key={undefinedItem.symbol}>
                      <ReksaDanaCard
                        showProductType={showProductType}
                        category={category}
                        isComparing={isComparing}
                        showLink={showLink}
                        fundData={undefinedItem}
                        selected={isSelected(undefinedItem.symbol)}
                        selectedIcon={isSelected(undefinedItem.symbol)}
                        sortBy={isSorting ? sortBy : undefined}
                        sortPeriod={sortPeriod}
                        onClick={onCardClick}
                        rightContent={<RightContentElement />}
                        footerContent={<FooterContentElement />}
                        containerClassname={classNames({
                          'margin-top-30': extraMarginTopForTooltip,
                        })}
                        onCustomNavigateMutualFundDetail={
                          onCustomNavigateMutualFundDetail
                        }
                      />
                      <WhiteSpace className='bit-uikit-custom' />
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          );
        })}
      {(loading || loadMoreLoading) && showLoading && (
        <div className='boxloader'>
          <div className='spinner' />
        </div>
      )}
      {!!isComparing && <div className='profilling-select-list-padding' />}
    </PaddingHorizontal>
  );
};

export interface CategorizedReksaDanaListProps {
  listWrapperClassName?: string;
  setCustomCurrency?: string;
  compareShouldShowBuyButton?: boolean;
  showLink?: boolean;
  imName?: string | null;
  showBanner?: boolean;
  showSortAndCompare?: boolean;
  category: string;
  selected?: string | string[];
  onCardClick: (roboCategory: string, symbol: string, additional: any) => void;
  onConfirmCompare?: () => void;
  ableToSelectProduct?: boolean;
  onCustomNavigateMutualFundDetail?: (symbol: string) => void;
  showPullToRefresh?: boolean;
  /**
   * If this true, instant modal will be shown by query
   *
   * If false, it will be shown by local state
   *
   * Default value = true
   */
  enableInstantModalByQuery?: boolean;
}

const CategorizedReksaDanaList: React.FC<
  React.PropsWithChildren<CategorizedReksaDanaListProps>
> = ({
  listWrapperClassName = '',
  compareShouldShowBuyButton = false,
  setCustomCurrency,
  showBanner = false,
  showSortAndCompare = false,
  showLink,
  imName,
  category,
  selected,
  onCardClick,
  onConfirmCompare,
  ableToSelectProduct,
  onCustomNavigateMutualFundDetail,
  enableInstantModalByQuery = true,
  showPullToRefresh = true,
}) => {
  const firstRender = useRef(true);
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const {
    location: { state },
  }: any = history;

  /**
   * notes: when `category` is `best`, the UI is Tab Version. and default would be `moneymarket`
   *
   * The tabs is: Pasar Uang | Obligasi | Saham | Syariah
   */
  const [currentCategoryTab, setCurrentCategoryTab] = useState('moneymarket');

  /** Instant Redemption FF */
  const instantRedemptionActiveByFF: boolean = useFlag(
    'web_jago_instant_redemption'
  );

  const { maxBuy } = useTopUpGlobalContext();

  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  // Redux states needed
  const loading = useSelector<any, any>((state) => {
    return getSafely(['discoverPage', 'loading'], state, false);
  });
  const loadMoreLoading = useSelector<any, any>((state) => {
    return getSafely(['discoverPage', 'loadMoreLoading'], state, false);
  });
  const categoryHasMore: boolean = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'hasMore'], state)
  );
  const categoryPage: number = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'page'], state)
  );
  /**
   * @TODO change string type to SortByType
   * services/reksadana.type
   */
  const sortBy: string = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'sortBy'], state)
  );
  const sortPeriod: string = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'sortPeriod'], state)
  );
  const isSorting: boolean = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'sortTerapkan'], state)
  );
  const syariahUser = profileDetail?.preference?.syariah ?? 0;
  const comparedProducts: string[] = useSelector<any, any>((state) =>
    getSafely(['compare', 'productCompare'], state, [])
  );
  const funds: ReksaDana[] = useSelector<any, any>((state) => {
    return getSafely(['discoverPage', 'result'], state, []);
  });

  // Local States
  const [isComparing, setListIsComparing] = useState(false);
  const { active: showIRModal, toggleActive: toggleIRModal } = useToggle();
  const { active: showTopCompanyModal, toggleActive: toggleTopCompanyModal } =
    useToggle();

  const { type, category: profilingCategory } =
    useParams<SortAndCompareButtonParams>();

  const categoryType = type || profilingCategory;
  const dynamicCategory = categoryType === 'im' ? imName : categoryType;

  const handlePullToRefresh = () => {
    dispatch(discoverPageSetPage(1));
    dispatch(discoverPageSetSize(20));
    dispatch(getFilteredProduct());
  };

  const handleInitializeList = useCallback(() => {
    const isSyariah = syariahUser === 1;

    const categoryInitialize =
      category === 'best' ? currentCategoryTab : category;

    if (category === 'best') {
      dispatch(discoverPageSetIsTopProduct(true));
    }

    switch (categoryInitialize) {
      case 'equity':
        dispatch(discoverPageFilterSetType([1]));
        break;
      case 'debt':
      case 'fixincome':
        dispatch(discoverPageFilterSetType([2]));
        break;
      case 'moneymarket':
        dispatch(discoverPageFilterSetType([3]));
        break;
      case 'benchmark':
        dispatch(discoverPageFilterSetType([8, 9]));
        break;
      case 'others':
        dispatch(discoverPageFilterSetType([0]));
        break;
      case 'minimum':
        dispatch(discoverPageSetMeta('minimum'));
        break;
      case 'indexfund':
        dispatch(discoverPageSetMeta('indexfund'));
        break;
      case 'usd':
        dispatch(discoverPageSetCurrency('usd'));
        break;
      case 'im':
        if (!!imName) {
          dispatch(discoverPageSetIM(imName));
        }
        break;
      case 'best':
        dispatch(discoverPageSetIsTopProduct(true));
        dispatch(discoverPageFilterSetType([]));
        break;
      default:
        dispatch(discoverPageFilterSetType([]));
        break;
    }

    /**
     * set filter max_buy
     */
    if (!!maxBuy) dispatch(discoverPageFilterMaxBuy(maxBuy));

    // Used in AllRoboRecommendationList.js
    if (setCustomCurrency) {
      dispatch(discoverPageSetCurrency(setCustomCurrency));
    }

    // Set syariah constantly true for syariah catgory,
    // else look for preference for other category
    dispatch(
      discoverPageFilterIsSyariah(
        categoryInitialize === 'syariah' ? true : isSyariah
      )
    );

    // Set is_instant_redemption param to API
    // Used for '/categories/instant' page
    if (categoryInitialize === 'instant') {
      dispatch(discoverPageSetInstantRedemption(true));
    }

    dispatch(getFilteredProduct());
  }, [
    syariahUser,
    category,
    currentCategoryTab,
    maxBuy,
    dispatch,
    setCustomCurrency,
    imName,
  ]);

  const handleToggleCompareModal = () => {
    setListIsComparing(!isComparing);
  };

  const handleLoadMoreCategory = () => {
    if (!loading && !loadMoreLoading) {
      dispatch(discoverPageSetPage(categoryPage + 1));
    }
  };

  /** Show Introduction of Instant Redemption modal */
  const handleShowInstantModal = (
    event: React.SyntheticEvent<HTMLDivElement, Event>
  ) => {
    // Guard if instant redemption isn't active by feature flag
    if (!instantRedemptionActiveByFF) return;

    event.stopPropagation();

    toggleIRModal();
  };

  const handleCloseInstantModal = () => {
    toggleIRModal();
  };

  /** Show Top Company info modal */
  const handleShowTopCompanyModal = () => {
    toggleTopCompanyModal();
  };

  const handleCloseTopCompanyModal = () => {
    toggleTopCompanyModal();
  };

  useEffect(() => {
    handleInitializeList();
    dispatch(compareResetState());

    return () => {
      dispatch(discoverPageReset());
      return;
    };
  }, [dispatch, handleInitializeList]);

  useEffect(() => {
    // When it's on first render, do nothing to prevent consume multiple endpoints
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      dispatch(getFilteredProduct(undefined, undefined, true));
    }
  }, [categoryPage, dispatch]);

  useEffect(
    /** Set sortBy, default = '2' */
    function handleSortBy() {
      dispatch(discoverPageSetSortBy(sortBy ? sortBy : '2'));
    },
    [dispatch, sortBy]
  );

  /** Navigate analytics */
  useEffect(() => {
    if (isSorting) {
      const data = {
        group: state,
        [state]: dynamicCategory,
        ...(!!sortBy ? { sort_by: sortBy } : {}),
        ...(!!sortPeriod ? { sort_periode: sortPeriod } : {}),
      };
      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'search',
          view: 'filter_result',
          data: data,
        },
      });
    }
  }, [state, dynamicCategory, sortPeriod, sortBy, isSorting]);

  const handleCardClick = (category: string, symbol: string) => {
    const index = funds.findIndex((item) => item.symbol === symbol);
    const fundObject: ReksaDana | null = index > -1 ? funds[index] : null;

    if (!isComparing) {
      onCardClick(category, symbol, { fundObject, sortBy });
      return;
    }

    // Remove from comparison if selected again, for toggling effect
    if (Array.isArray(comparedProducts) && comparedProducts.includes(symbol)) {
      dispatch(compareRemoveProduct(symbol));
      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'compare_company',
          trigger: 'click',
          data: {
            company_id: fundObject?.id,
            group: state,
            [state]: dynamicCategory,
            status: 'deselect',
          },
          context: 'search.group',
        },
      });
      return;
    }

    // Prevent addition if already 3 symbol selected
    if (Array.isArray(comparedProducts) && comparedProducts.length >= 3) {
      return;
    }
    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'compare_company',
        trigger: 'click',
        data: {
          company_id: fundObject?.id,
          group: state,
          [state]: dynamicCategory,
          status: 'select',
        },
        context: 'search.group',
      },
    });
    dispatch(compareAddProduct(symbol));
    return;
  };

  return (
    <>
      {/* Category Banner */}
      {!!showBanner && (
        <BibitPlusCategoryBanner
          category={category as CategoryBannerProps['category']}
        />
      )}

      <div />

      {/* Filter and ReksaDana List With Pull To Refresh */}
      <Puller
        className={classNames({
          'puller-box': true,
          fullheight: true,
        })}
        loading={loading}
        onRefresh={handlePullToRefresh}
        disabled={!showPullToRefresh}
      >
        {/* Filter and Bandingkan */}
        {!!showSortAndCompare && (
          <SortAndCompareButton
            compareShouldShowBuyButton={compareShouldShowBuyButton}
            onReset={handleInitializeList}
            onToggleCompareModal={handleToggleCompareModal}
            onConfirmCompare={onConfirmCompare}
          />
        )}
        {/* ReksaDana Card List */}
        <Show when={category !== 'best'}>
          <div
            className={classNames(
              'bit-reksa-cards-wrap bibit-plus-reksa-cards',
              listWrapperClassName
            )}
          >
            <ReksaDanaCardList
              category={category}
              showLink={showLink}
              funds={funds}
              sortBy={sortBy}
              sortPeriod={sortPeriod}
              loading={loading}
              loadMoreLoading={loadMoreLoading}
              isSorting={isSorting}
              onCardClick={handleCardClick}
              isComparing={isComparing}
              selected={isComparing ? comparedProducts : selected}
              handleShowInstantModal={handleShowInstantModal}
              handleShowTopCompanyModal={handleShowTopCompanyModal}
              ableToSelectProduct={ableToSelectProduct}
              onCustomNavigateMutualFundDetail={
                onCustomNavigateMutualFundDetail
              }
            />
          </div>
        </Show>

        <Show when={category === 'best'}>
          <div
            className={classNames(
              'bit-reksa-cards-wrap bibit-plus-reksa-cards',
              listWrapperClassName
            )}
          >
            <CommonBestTab
              onChangeTab={(data) => {
                setCurrentCategoryTab(data.type);
              }}
            >
              <div className='padding-top-20' />
              <ReksaDanaCardList
                category={currentCategoryTab}
                showLink={showLink}
                funds={funds}
                sortBy={sortBy}
                sortPeriod={sortPeriod}
                loading={loading}
                loadMoreLoading={loadMoreLoading}
                isSorting={isSorting}
                onCardClick={handleCardClick}
                isComparing={isComparing}
                selected={isComparing ? comparedProducts : selected}
                handleShowInstantModal={handleShowInstantModal}
                handleShowTopCompanyModal={handleShowTopCompanyModal}
                ableToSelectProduct={ableToSelectProduct}
                onCustomNavigateMutualFundDetail={
                  onCustomNavigateMutualFundDetail
                }
                showTopProductRibbon={false}
              />
            </CommonBestTab>
          </div>
        </Show>
      </Puller>
      {/* Pencairan Instan Modal */}
      <IntroInstantRedemptionModal
        showModal={showIRModal}
        onClose={handleCloseInstantModal}
      />
      {/* Top Company Info Modal */}
      <TopCompanyInfoModal
        onClose={handleCloseTopCompanyModal}
        visible={showTopCompanyModal}
      />
      {/* Infinite Scroll */}
      {Array.isArray(funds) && funds.length > 0 && categoryHasMore && (
        <Waypoint onEnter={handleLoadMoreCategory} />
      )}
    </>
  );
};

export default CategorizedReksaDanaList;
