import React, { useState, useEffect } from 'react';
import { X } from 'react-feather';
import { Text } from '@bibitid/uikit-v1';
import styled from 'styled-components';

const Container = styled.div<{ left: number }>`
  display: flex;
  align-items: center !important;
  position: absolute;
  white-space: nowrap;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  background-color: var(--green-default);
  display: flex;
  align-items: center;
  overflow: visible !important;
  z-index: 3;
  left: ${(props) => props.left}px;
  transform: translateY(32px);

  svg {
    margin-left: 12px;
    cursor: pointer;
  }
`;

interface ITooltip {
  marginLockIcon: number;
  onClose?: () => void;
}

const Tooltip: React.FC<ITooltip> = ({ marginLockIcon, onClose }) => {
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    const center = (window.innerWidth - 275) / 2;
    const maxCenter = center + 260;

    if (marginLockIcon > center && marginLockIcon < maxCenter) {
      setMargin(center);
    } else {
      setMargin(marginLockIcon);
    }
  }, [marginLockIcon]);

  return (
    <Container left={margin}>
      <Text type={'body12r'} color='#fff'>
        Tap untuk sembunyikan nominal portofolio
      </Text>
      <X color='#fff' strokeWidth={3} size={14} onClick={onClose} />
    </Container>
  );
};

export default Tooltip;
