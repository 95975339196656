import * as React from 'react';
import IconLight from './light/filter.svg';
import IconDark from './dark/filter.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: IconLight,
  dark: IconDark,
  default: IconLight,
};

export const FilterIcon: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default FilterIcon;
