import React from 'react';
import classNames from 'classnames';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import { ReksaDana } from 'entities/reksadana.reducer';
import {
  numberToPercentagePointChangeFormat,
  numberToPointChangeFormat,
} from 'utils/stringHelper';
import getSafely from 'utils/safely';
import { hasFundDataAdjusted } from 'utils/newRD';
import { format, parseISO } from 'utils/date';

interface Props {
  fundData: ReksaDana;
  category?: string;
  isComparing?: boolean;
  showLink?: boolean;
  selected?: boolean;
  sortBy?: string;
  sortPeriod?: string;
  onClick: (roboCategory: string, symbol: string) => void;
}

const BenchmarkIndexCard: React.FC<React.PropsWithChildren<Props>> = ({
  fundData,
  onClick,
  selected,
}) => {
  const handleClick = () => {
    const { symbol } = fundData;
    onClick('benchmark', symbol);
  };
  const pointChanges = hasFundDataAdjusted(fundData, 'changesvalue', '1y') ?? 0;
  const navScrapDate = getSafely(['nav', 'date'], fundData);
  const navDateObject =
    typeof navScrapDate === 'string' ? parseISO(navScrapDate) : new Date();
  const navDate = format(navDateObject, 'd MMM yy');

  return (
    <div className='bit-filter-result-row'>
      <div
        data-symbol={fundData.symbol}
        onClick={handleClick}
        className={classNames({
          'card-selected': selected,
        })}
      >
        <div className='bit-filter-reksa'>
          <div className='bit-filter-reksa-pic'>
            <InvestmentCompanyLogo type='benchmark' ojkCode={fundData.symbol} />
          </div>
          <div>
            <h3 className='overflow-hidden'>{fundData.name}</h3>
            <p>Updated {navDate}</p>
          </div>
        </div>
        <div className='bit-filter-right-result'>
          <div
            className={classNames({
              'minus-result': pointChanges < 0,
              'plus-result': pointChanges > 0,
            })}
          >
            <p>{numberToPointChangeFormat(pointChanges)}</p>
            <p className='bit-font-12'>
              (
              {numberToPercentagePointChangeFormat(
                hasFundDataAdjusted(fundData, 'simplereturn', '1y')
              )}
              )
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenchmarkIndexCard;
