import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useSyariahDialog } from './useSyariahDialog';

const ChangeToSyariahDialog = React.lazy(() => import('./SyariahDialog'));

const LazyChangeToSyariahDialog = (props: any) => {
  const { visible } = useSyariahDialog();

  return (
    <LazyModal component={ChangeToSyariahDialog} visible={visible} {...props} />
  );
};

export default LazyChangeToSyariahDialog;
