import { parseISO as fnsParseISO, isValid } from 'date-fns';

export { fromUnixTime } from 'date-fns';

/**
 * Parse the given string in ISO 8601 format and return an instance of Date.
 *
 * Function accepts complete ISO 8601 formats as well as partial implementations. ISO 8601: http://en.wikipedia.org/wiki/ISO_8601
 *
 * If the argument isn't a string, the function cannot parse the string or the values are invalid, it returns Invalid Date.
 *
 * @param {string} date The value to convert
 * @param {Object=} options date-fns parseISO function options
 * Name                  | Type         | Description
 * ----------------------------------------------------
 * additionalDigits      | (0 | 1 | 2	) | the additional number of digits in the extended year format
 * (optional, default=2) |              |
 * @returns {Date} Date object, default to current `new Date()` object
 */
function parseISO(
  date?: string | number | null,
  options: {
    additionalDigits?: 0 | 1 | 2 | undefined;
  } = {}
) {
  if (typeof date === 'string' && isValid(new Date(date))) {
    return fnsParseISO(date, options);
  }

  // Special handling for ISO9075 strings. This will address safari issue with Date parsing
  if (
    typeof date === 'string' &&
    date.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)
  ) {
    const parsedDateString = date.split(/[^0-9]/);
    return new Date(
      parseInt(parsedDateString[0]),
      parseInt(parsedDateString[1]) - 1,
      parseInt(parsedDateString[2]),
      parseInt(parsedDateString[3]),
      parseInt(parsedDateString[4]),
      parseInt(parsedDateString[5])
    );
  }

  // Return invalid date if date parameter format is invalid
  return new Date(NaN);
}

export { parseISO };

export { parseISO as default };
