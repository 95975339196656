import React, { useEffect } from 'react';
import rqClient from 'network/queryClient';

/**
 * HOC for resetting status Bar
 * @param WrappedComponent - component that need to be wrapped
 */
const withoutTabBar = (WrappedComponent: any) => (props: any) => {
  useEffect(() => {
    rqClient.setQueryData('showTabBar', {
      visible: false,
    });

    return () => {
      rqClient.setQueryData('showTabBar', {
        visible: true,
      });
    };
  }, []);

  return <WrappedComponent {...props} />;
};

export default withoutTabBar;
