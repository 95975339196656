import compareColumn from 'assets/images/portofolio-asset/compare-column.svg';
import whiteFilter from 'assets/images/portofolio-asset/white-filter.svg';
import classNames from 'classnames';
import { SortingComponent } from 'features/category/component/molecules';
import {
  discoverPageReset,
  discoverPageSetPage,
  discoverPageSetSize,
  discoverPageSetSort,
  discoverPageSetSortBy,
  discoverPageSetSortPeriod,
  discoverPageSortTerapkan,
  getFavoritedProducts,
  getFilteredProduct,
  discoverPageSetInstantRedemption,
  discoverPageSetSortOther,
} from 'features/discover/discoverPage.reducer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getSafely from 'utils/safely';
import PaddingHorizontal from 'features/common/PaddingHorizontal';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Analytics from 'utils/Analytics';
import {
  compareResetState,
  compareRemoveProduct,
} from 'features/compare/compare.reducer';
import { getReksadanaBySymbol, ReksaDana } from 'entities/reksadana.reducer';
import { ReduxRootState } from 'store/root';
import 'features/common/reksadana/SortAndCompareButton.css';
import { useQueryParams } from 'utils/routeHelper';
import ModalCompareReksaDana from './components/ModalCompareReksaDana';
import ModalSortReksaDana from './components/ModalSortReksaDana';

interface Props {
  compareShouldShowBuyButton?: boolean;
  onReset: Function;
  onConfirmCompare?: () => void;
  onToggleCompareModal?: Function;
  isFilterHidden?: boolean;
  isWatchlistPage?: boolean;
  showInstantTooltip?: boolean;
  onApply?: (params: onApplyParams) => void;
  onSortDirectionChange?: (direction: string) => void;
}
interface SortAndCompareButtonParams {
  type: string;
  im?: string;
  category?: string;
}

interface onApplyParams {
  is_instant_redemption: boolean;
  sort?: string;
  sort_period?: string;
  sort_by?: string;
}

const SortAndCompareButton: React.FC<React.PropsWithChildren<Props>> = ({
  compareShouldShowBuyButton,
  onReset,
  onConfirmCompare,
  onToggleCompareModal,
  isFilterHidden = false,
  isWatchlistPage = false,
  onApply,
  onSortDirectionChange,
}) => {
  // Since we don't want to search funds right away after selecting,
  // we save the selected method and period in state, and execute dispatch
  // after user has click Terapkan
  const [sortSelected, setSortSelected] = React.useState<boolean>(false);
  const [compareSelected, setCompareSelected] = React.useState<boolean>(false);
  const [periodVisible, setPeriodVisible] = React.useState<boolean>(true);
  const [selectedSortBy, setSelectedSortBy] = React.useState<
    string | undefined
  >('5');
  // Sorting by descending or descending
  const [selectedAscendOrDescend, setSelectedAscendOrDescend] = React.useState<
    string | undefined
  >('desc');
  const [selectedSortPeriod, setSelectedSortPeriod] = React.useState<
    string | undefined
  >('1d');
  const [selectedOtherFilter, setSelectedOtherFilter] = React.useState<
    string | null
  >(null);

  const currentSortBy = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'sortBy'], state)
  );

  const currentsortPeriod = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'sortPeriod'], state)
  );
  const currentSortOther = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'sortOther'], state)
  );
  const sortTerapkan = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'sortTerapkan'], state)
  );

  const sortAscDesc = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'sort'], state)
  );

  const sortLoading = useSelector<any, any>((state) =>
    getSafely(['discoverPage', 'loading'], state)
  );

  const comparedProducts: ReksaDana[] = useSelector<any, any>(
    (state: ReduxRootState) => {
      return getSafely(['compare', 'productCompare'], state, []).map(
        (symbol: string) => getReksadanaBySymbol(state, symbol)
      );
    }
  );

  const dispatch = useDispatch<any>();
  const history = useHistory();
  const {
    location: { state },
  }: any = history;

  const { url } = useRouteMatch();
  const { type, category: profilingCategory } =
    useParams<SortAndCompareButtonParams>();
  let query = useQueryParams();

  const categoryType = type || profilingCategory;
  const dynamicCategory =
    categoryType === 'im' ? query.get('im') : categoryType;

  /** Determine Terapkan button is disabled or not */
  const isTerapkanButtonDisabled: boolean =
    !selectedSortBy && !selectedSortPeriod && !selectedOtherFilter;

  const handleOpenSortModal = () => {
    if (compareSelected) {
      return;
    }

    if (!!sortTerapkan) {
      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'sort_change',
          trigger: 'click',
          data: {
            group: state,
            [state]: dynamicCategory,
          },
          context: 'search.filter_result',
        },
      });
    } else {
      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'filter',
          trigger: 'click',
          data: {
            group: state,
            [state]: dynamicCategory,
          },
          context: 'search.filter',
        },
      });
    }
    return setSortSelected(true);
  };
  const handleCloseSortModal = () => setSortSelected(false);

  const handleToggleCompareModal = () => {
    setCompareSelected(!compareSelected);
    if (onToggleCompareModal) {
      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'compare_initiate',
          trigger: 'click',
          data: {
            group: state,
            [state]: dynamicCategory,
          },
          context: 'search.group',
        },
      });
      onToggleCompareModal();
    }
  };

  /** Handle Sort by / Urutkan berdasarkan */
  const handleSelectSortMethod = (event: React.MouseEvent<HTMLDivElement>) => {
    const method = event.currentTarget.dataset.id;
    const sortBy = event.currentTarget.dataset.defaultsortby;
    const periodVisibility = !!(method === '5' || method === '4');
    const defaultSortPeriod = method === '5' ? '1d' : '1y';

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort_by',
        trigger: 'click',
        data: {
          sort_by: method,
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.result',
      },
    });

    if (method === selectedSortBy) {
      setPeriodVisible(false);
      setSelectedSortBy(undefined);
      setSelectedSortPeriod(undefined);
    } else {
      setPeriodVisible(periodVisibility);
      setSelectedSortBy(method);
      // When click the sort method, set the sort ascending or descending from `sortBy`
      setSelectedAscendOrDescend(sortBy);
      setSelectedSortPeriod(defaultSortPeriod);
    }
  };

  /** Select Period Filter */
  const handleSelectPeriod = (event: React.MouseEvent<HTMLDivElement>) => {
    const periode = event.currentTarget.dataset.id;
    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort_period',
        trigger: 'click',
        data: {
          periode: periode,
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.result',
      },
    });
    if (periode === selectedSortPeriod) {
      setSelectedSortPeriod(undefined);
    } else {
      setSelectedSortPeriod(periode);
    }
  };

  /** Terapkan Button Function */
  const handleImplementSort = () => {
    // Set local state to Redux
    dispatch(discoverPageSetSortBy(selectedSortBy));
    dispatch(discoverPageSetSortPeriod(selectedSortPeriod));
    dispatch(discoverPageSortTerapkan());
    dispatch(discoverPageSetSortOther(selectedOtherFilter));

    // Filter Pencairan Instan
    dispatch(
      discoverPageSetInstantRedemption(
        selectedOtherFilter === 'Pencairan Instan'
      )
    );

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort',
        trigger: 'click',
        data: {
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.filter_result',
      },
    });

    dispatch(discoverPageSetSort(selectedAscendOrDescend));

    if (!!onApply) {
      const onApplyParams = {
        is_instant_redemption: selectedOtherFilter === 'Pencairan Instan',
        ...(selectedSortBy ? { sort_by: selectedSortBy } : {}),
        ...(selectedSortPeriod ? { sort_period: selectedSortPeriod } : {}),
        ...(selectedAscendOrDescend ? { sort: selectedAscendOrDescend } : {}),
      };
      onApply(onApplyParams);
    }

    handleLoadData();
  };

  /** Select Filter Lainnya */
  const handleOtherFilter = (event: React.MouseEvent<HTMLDivElement>) => {
    const selectedEventFilter = event.currentTarget.dataset.id;

    if (selectedEventFilter === selectedOtherFilter) {
      setSelectedOtherFilter(null);
    } else if (selectedEventFilter) {
      setSelectedOtherFilter(selectedEventFilter);
    }

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort_by',
        trigger: 'click',
        data: {
          group: state,
          [state]: dynamicCategory,
          sort_by: selectedSortBy,
        },
        context: 'search.filter_result',
      },
    });
  };

  const handleLoadData = () => {
    // Get the products
    dispatch(discoverPageSetPage(1));
    dispatch(discoverPageSetSize(20));
    // Watchlist and category use different function
    if (isWatchlistPage) {
      dispatch(getFavoritedProducts());
    } else {
      dispatch(getFilteredProduct());
    }
    handleCloseSortModal();
  };

  const handleSortDirectionChange = () => {
    if (sortLoading) return;
    const sortDirection: string = sortAscDesc === 'desc' ? 'asc' : 'desc';
    dispatch(discoverPageSetSort(sortDirection));
    if (!!onSortDirectionChange) {
      onSortDirectionChange(sortDirection);
    }
    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort_order',
        trigger: 'click',
        data: {
          order: sortDirection,
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.filter_result',
      },
    });
    return handleLoadData();
  };

  const handleResetSort = () => {
    setSelectedSortBy('5');
    setSelectedSortPeriod('1d');
    setPeriodVisible(true);
    dispatch(discoverPageReset());
    onReset();
    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort_cancel',
        trigger: 'click',
        data: {
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.filter_result',
      },
    });
  };

  const handleCancelCompare = () => {
    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'compare_cancel',
        trigger: 'click',
        data: {
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.group',
      },
    });
    handleToggleCompareModal();
    dispatch(compareResetState());
  };

  const handleRemoveComparedProduct = (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>
  ) => {
    const symbol = getSafely(['currentTarget', 'dataset', 'symbol'], event);
    const id = getSafely(['currentTarget', 'dataset', 'id'], event);
    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'compare_remove',
        trigger: 'click',
        data: {
          company_id: id,
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.group',
      },
    });
    dispatch(compareRemoveProduct(symbol));
  };

  const handleConfirmCompare = () => {
    const symbolsCompared = comparedProducts
      .map((fund) => fund.symbol)
      .join(',');
    const idsCompared = comparedProducts.map((fund) => fund.id).join(',');

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'compare_result',
        trigger: 'click',
        data: {
          company_id: idsCompared,
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.group',
      },
    });

    const historyObj = {
      pathname: '/compare',
      search: `symbols=${symbolsCompared}`,
      state: {
        lastUrl: url,
      },
    };

    // prevent user back to compare page again when create new comparation product from select product flow
    if (history.location.pathname === '/compare') {
      history.replace(historyObj);
    } else {
      history.push(historyObj);
    }

    // callback function when user click "Bandingkan"
    onConfirmCompare && onConfirmCompare();
  };

  useEffect(() => {
    if (sortSelected) {
      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'search',
          view: 'filter',
          data: {
            group: state,
            [state]: dynamicCategory,
          },
        },
      });
    }
  }, [state, dynamicCategory, sortSelected]);

  useEffect(() => {
    if (compareSelected) {
      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'search',
          view: 'compare',
          data: {
            group: state,
            [state]: dynamicCategory,
          },
        },
      });
    }
  }, [state, dynamicCategory, compareSelected]);

  useEffect(() => {
    /** Set Default Pencairan Instan filter if user is on /instant page */
    if (categoryType === 'instant') {
      setSelectedOtherFilter('Pencairan Instan');
    }
  }, [categoryType]);

  if (sortTerapkan) {
    return (
      <PaddingHorizontal>
        <SortingComponent
          sortBy={currentSortBy}
          sortPeriod={currentsortPeriod}
          sortOther={currentSortOther}
          sortTerapkan={sortTerapkan}
          sortAscDesc={sortAscDesc}
          onClickAscDesc={handleSortDirectionChange}
          modalOpen={handleOpenSortModal}
          discoverPageReset={handleResetSort}
          loadDataProduct={handleLoadData}
          categoryType={categoryType}
        />
        {/* Sort modal */}
        <ModalSortReksaDana
          sortSelected={sortSelected}
          closeSortModal={handleCloseSortModal}
          selectedSortBy={selectedSortBy}
          selectedSortPeriod={selectedSortPeriod}
          periodVisible={periodVisible}
          selectSortMethod={handleSelectSortMethod}
          selectPeriod={handleSelectPeriod}
          implementSort={handleImplementSort}
          selectedOtherFilter={selectedOtherFilter}
          handleOtherFilter={handleOtherFilter}
          isTerapkanButtonDisabled={isTerapkanButtonDisabled}
          categoryType={categoryType}
        />
      </PaddingHorizontal>
    );
  }

  // !sortTerapkan
  return (
    <React.Fragment>
      <div
        data-testid='filter-container'
        className={classNames({
          'bit-compare-menus': true,
          'padding-bottom-20': true,
          'visibility-hidden': isFilterHidden,
        })}
      >
        {/* Filter button */}
        <div
          className={classNames({
            'bit-compare-menus-opt': true,
            selected: sortSelected,
          })}
          onClick={handleOpenSortModal}
        >
          <div>
            <img width='16' height='16' src={whiteFilter} alt='Urutkan' />
          </div>
          <span>Filter</span>
        </div>

        {/* Compare button */}
        <div
          className={classNames({
            'bit-compare-menus-opt': true,
            selected: compareSelected,
          })}
          onClick={handleToggleCompareModal}
        >
          <div>
            <img width='16' height='16' src={compareColumn} alt='Bandingkan' />
          </div>
          <span>Bandingkan</span>
        </div>
      </div>

      {/* Compare modal */}
      <ModalCompareReksaDana
        compareSelected={compareSelected}
        cancelCompare={handleCancelCompare}
        comparedProducts={comparedProducts}
        removeCompareProduct={handleRemoveComparedProduct}
        confirmCompare={handleConfirmCompare}
      />

      {/* Sort Modal */}
      <ModalSortReksaDana
        sortSelected={sortSelected}
        closeSortModal={handleCloseSortModal}
        selectedSortBy={selectedSortBy}
        selectedSortPeriod={selectedSortPeriod}
        periodVisible={periodVisible}
        selectSortMethod={handleSelectSortMethod}
        selectPeriod={handleSelectPeriod}
        implementSort={handleImplementSort}
        selectedOtherFilter={selectedOtherFilter}
        handleOtherFilter={handleOtherFilter}
        isTerapkanButtonDisabled={isTerapkanButtonDisabled}
        categoryType={categoryType}
      />
    </React.Fragment>
  );
};

export default SortAndCompareButton;
