import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import getSafely from 'utils/safely';
import { stringContainsOnlyDigits } from 'utils/validator';

const defaultState = {
  is_fetching_upload: false,
  is_fetching: false,
  upload_err: '',
  err: '',
  upload_done: false,
  done: false,
  form: {
    email: '',
    education: -1,
    earningsperyear: -1,
    earningsource: -1,
    bank: '-',
    bankAccountNumber: '',
    bankAccountName: '',
    flagIdentity: 0,
    fileIdentity: '',
    flagSignature: 0,
    fileSignature: '',
    fileSignatureData: [],
  },
  err_form: null,
};

const [uploadrequest, uploadfailure, uploadsuccess] = [
  'LOAD_REGISTERACCOUNTFORM_UPLOADREQUEST',
  'LOAD_REGISTERACCOUNTFORM_UPLOADFAILURE',
  'LOAD_REGISTERACCOUNTFORM_UPLOADSUCCESS',
].map(createAction);

const [request, failure, success, change, remove, removeError] = [
  'LOAD_REGISTERACCOUNTFORM_REQUEST',
  'LOAD_REGISTERACCOUNTFORM_FAILURE',
  'LOAD_REGISTERACCOUNTFORM_SUCCESS',
  'CHANGE_REGISTERACCOUNTFORM_FORM',
  'REMOVE_REGISTERACCOUNTFORM_FORM',
  'REMOVE_REGISTERACCOUNTFORM_ERROR_FORM',
].map(createAction);

const reducer = createReducer(
  {
    [uploadrequest]: (state) => {
      return update(state, {
        is_fetching_upload: { $set: true },
        upload_done: { $set: false },
        upload_err: { $set: '' },
      });
    },
    [uploadfailure]: (state, payload) => {
      const { err } = payload;
      return update(state, {
        is_fetching_upload: { $set: false },
        upload_err: { $set: err },
      });
    },
    [uploadsuccess]: (state) => {
      return update(state, {
        is_fetching_upload: { $set: false },
        upload_done: { $set: true },
      });
    },
    [request]: (state) => {
      return update(state, {
        is_fetching: { $set: true },
        err: { $set: '' },
        err_form: { $set: null },
      });
    },
    [failure]: (state, payload) => {
      const { err, err_form } = payload;
      return update(state, {
        is_fetching: { $set: false },
        err: { $set: err },
        err_form: { $set: err_form },
      });
    },
    [success]: (state) => {
      const toUpdate = {
        is_fetching: { $set: false },
        done: { $set: true },
        err_form: { $set: null },
        err: { $set: '' },
      };
      return update(state, toUpdate);
    },
    [change]: (state, payload) => {
      const { key, value } = payload;
      let errForm = { ...state.err_form };

      if (key === 'bankAccountNumber') {
        //key in backend is different than our variable
        delete errForm['bank_account_number'];
      }

      delete errForm[key];
      const toUpdate = {
        form: {
          [key]: { $set: value },
        },
        err_form: {
          $set: errForm,
        },
      };
      return update(state, toUpdate);
    },
    [remove]: (state, payload) => {
      const { value } = payload;
      const toUpdate = {
        form: {
          fileIdentity: { $splice: [[value, 1]] },
        },
      };
      return update(state, toUpdate);
    },
    [removeError]: (state, payload) => {
      let errForm = { ...state.err_form };
      const { key } = payload;
      delete errForm[key];
      const toUpdate = {
        err_form: {
          $set: errForm,
        },
      };
      return update(state, toUpdate);
    },
  },
  defaultState
);

export { change, remove, removeError };

export function checkIfAccountNumberValid(dispatch, state) {
  const bankAccountNumber = getSafely(
    ['registerAccountForm', 'form', 'bankAccountNumber'],
    state
  );
  const err_form = getSafely(['registerAccountForm', 'err_form'], state, {});
  let isValid = stringContainsOnlyDigits(bankAccountNumber);
  if (!isValid) {
    dispatch(
      failure({
        err: 'Nomor rekening tidak valid',
        err_form: {
          ...err_form,
          bankAccountNumber: {
            param: 'bankAccountNumber',
            msg: 'Nomor rekening bank kamu harus berupa angka.',
          },
        },
      })
    );
  }
  return isValid;
}

export default reducer;
