import compareVersion from 'compare-versions';
import {
  linkAjaPaymentMethodNames,
  LINKAJA_GIFTCARD_ANDROID_VERSION_V2,
  LINKAJA_GIFTCARD_IOS_VERSION_V2,
} from '../constants';

type BuyGiftCardVersionType = 'v1' | 'v2';

export const getVersionLinkAjaBuyGcV2 = () => {
  const systemName = window?.systemName || window?.document?.systemName;

  if (systemName?.toLowerCase() === 'android') {
    return LINKAJA_GIFTCARD_ANDROID_VERSION_V2;
  }

  if (systemName?.toLowerCase() === 'ios') {
    return LINKAJA_GIFTCARD_IOS_VERSION_V2;
  }

  return '';
};

/**
 * Linkaja buy giftcard version will be return `v2` when
 * - android has minimum version 3.44.0
 * - ios has minimum version 4.38.0
 */
export const linkAjaBuyGiftCardVersion = (): BuyGiftCardVersionType => {
  const version = window?.VERSION || window?.document?.VERSION;
  const systemName = window?.systemName || window?.document?.systemName;

  // when buying from browser we use `buy giftcard v1`
  if (!version || !systemName) {
    return 'v1';
  }

  const linkAjaBuyGiftCardAndroidVersion1 =
    systemName.toLowerCase() === 'android' &&
    compareVersion.compare(version, LINKAJA_GIFTCARD_ANDROID_VERSION_V2, '<');

  const linkAjaBuyGiftCardIosVersion1 =
    systemName.toLowerCase() === 'ios' &&
    compareVersion.compare(version, LINKAJA_GIFTCARD_IOS_VERSION_V2, '<');

  const isLinkAjaBuyGiftCardVersion1 =
    linkAjaBuyGiftCardAndroidVersion1 || linkAjaBuyGiftCardIosVersion1;

  if (isLinkAjaBuyGiftCardVersion1) {
    return 'v1';
  }

  return 'v2';
};

/**
 * Buy giftcard version to request api `/gift-card/buy` with query params v2 or not
 */
export const buyGiftCardVersion = (
  paymentMethod: string
): BuyGiftCardVersionType => {
  if (linkAjaPaymentMethodNames.includes(paymentMethod)) {
    return linkAjaBuyGiftCardVersion();
  }

  return 'v1';
};
