import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import {
  getBankAccounts,
  isNonVerifiedBank,
} from 'entities/bankAccounts.reducer';
import { getUserLivenessQuotas, postSetDefaultBank } from 'services/user';
import { openToastMessage } from 'features/common/toast/toast.reducer';
import { AppThunk } from 'utils/redux';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import { BankUser } from 'entities/bankAccounts.reducer';

export interface ChangeBankState {
  pinErrorMessage: string;
  pinInput: string;
  step: number;
  loading: boolean;
  accounts: string[];
  allAccounts: BankUser[];
  processAccounts: BankUser[];
  usedAccount: string;
  warningVisible: boolean;
  successVisible: boolean;
  loadingChangeBank: boolean;
  disabledLivenessTest: boolean;
  usedBankId: number;
}

const defaultState: ChangeBankState = {
  pinErrorMessage: '',
  pinInput: '',
  step: 1,
  loading: false,
  accounts: [],
  processAccounts: [],
  allAccounts: [],
  usedAccount: '',
  warningVisible: false,
  successVisible: false,
  loadingChangeBank: false,
  disabledLivenessTest: false,
  usedBankId: 0,
};

export const changeBankPageAccountsLoading = createAction(
  'CHANGE_BANK_PAGE_ACCOUNTS_LOADING'
);
export const changeBankPageAccountsError = createAction(
  'CHANGE_BANK_PAGE_ACCOUNTS_ERROR'
);
export const changeBankPageAccountsLoaded = createAction<BankUser[]>(
  'CHANGE_BANK_PAGE_ACCOUNTS_LOADED'
);
export const changeBankPageChangeDefaultAccount = createAction<any>(
  'CHANGE_BANK_PAGE_CHANGE_DEFAULT_ACCOUNT'
);
export const changeBankWarningVisible = createAction<boolean>(
  'CHANGE_BANK_WARNING_VISIBLE'
);

export const changeBankPinInputChange = createAction<string>(
  'CHANGE_BANK_PIN_INPUT_CHANGE'
);
export const changeBankPinError = createAction<string>('CHANGE_BANK_PIN_ERROR');
export const changeBankPinErrorReset = createAction(
  'CHANGE_BANK_PIN_ERROR_RESET'
);
export const setToggleChangeBankDefault = createAction<boolean>(
  'CHANGE_BANK_SET_TOGGLE_LOADING_BANK_DEAULT'
);

export const setDisabledLivenessTest = createAction<boolean>(
  'SET_DISABLED_LIVENESS_TEST'
);

export const setDataAllBanks = createAction<BankUser[]>('SET_ALL_BANK_DATA');

const changeBankPage = createReducer<typeof defaultState>({}, defaultState);

changeBankPage.on(changeBankPageAccountsLoading, (state) => {
  return update(state, {
    loading: { $set: true },
  });
});

changeBankPage.on(changeBankPageAccountsError, (state) => {
  return update(state, {
    loading: { $set: false },
  });
});

changeBankPage.on(setToggleChangeBankDefault, (state, payload) => {
  return update(state, {
    loadingChangeBank: { $set: payload },
  });
});

changeBankPage.on(changeBankPageAccountsLoaded, (state, payload) => {
  // Filter to check which bank account is set as default
  const usedBankAccount = payload.find((item) => item.default);

  if (!payload || !usedBankAccount)
    return update(state, {
      loading: { $set: false },
      processAccounts: {
        $set: payload.filter((data) => isNonVerifiedBank(data)),
      },
    });

  return update(state, {
    loading: { $set: false },
    accounts: {
      // filter the bank account if bank_status is 1
      // bank_status = 1 Verified
      // bank_status = 0 on Process
      // bank_status = 2 amend process
      // bank_status = 6 on Export S-Inveset process
      $set: payload
        .filter((data) => data.bank_status === 1)
        .map((item) => `${item.bank_id}#${item.user_bank_account_number}`),
    },
    processAccounts: {
      $set: payload.filter((data) => {
        // all Non-RDN banks
        const nonRDN = !(data.is_rdn === 1 && data.is_deletable === 0);

        // all banks that is on process
        const nonVerified = isNonVerifiedBank(data);

        return nonRDN && nonVerified;
      }),
    },
    usedAccount: {
      $set: `${usedBankAccount.bank_id}#${usedBankAccount.user_bank_account_number}`,
    },
    usedBankId: {
      $set: usedBankAccount.bank_id,
    },
  });
});

changeBankPage.on(changeBankPageChangeDefaultAccount, (state, payload) => {
  const { bankId, accountNumber }: any = payload;

  return update(state, {
    usedAccount: { $set: `${bankId}#${accountNumber}` },
    usedBankId: { $set: bankId },
  });
});

changeBankPage.on(changeBankWarningVisible, (state, payload) => {
  return update(state, {
    warningVisible: { $set: payload },
  });
});

changeBankPage.on(changeBankPinInputChange, (state, payload) => {
  return update(state, {
    pinInput: { $set: payload },
  });
});

changeBankPage.on(changeBankPinError, (state, payload) => {
  return update(state, {
    pinErrorMessage: { $set: payload },
  });
});

changeBankPage.on(changeBankPinErrorReset, (state) => {
  return update(state, {
    pinErrorMessage: { $set: defaultState.pinErrorMessage },
  });
});

changeBankPage.on(setDisabledLivenessTest, (state, payload) => {
  return update(state, {
    disabledLivenessTest: { $set: payload },
  });
});

changeBankPage.on(setDataAllBanks, (state, payload) => {
  return update(state, {
    allAccounts: { $set: payload },
  });
});

export const userLivenessQuotas = (): AppThunk<void> => (dispatch) => {
  getUserLivenessQuotas()
    .then((response) => {
      dispatch(setDisabledLivenessTest(response.data?.data?.remain === 0));
    })
    .catch(() => {});
};

/**
 * Get bank account list
 */
export const pageGetBankAccounts =
  ({ unmask = 0 } = {}): AppThunk<void> =>
  (dispatch) => {
    dispatch(changeBankPageAccountsLoading());
    dispatch(userLivenessQuotas());
    dispatch(getBankAccounts({ unmask }))
      .then((response) => {
        dispatch(changeBankPageAccountsLoaded(response));
        dispatch(setDataAllBanks(response));
      })
      .catch((error) => {
        dispatch(changeBankPageAccountsError());
        if (error && error.response) {
          const { data } = error.response;
          const message = data?.message || 'Something is wrong';
          dispatch(openErrorModal({ type: 'FAILED_AND_RELOAD', message }));
        } else {
          dispatch(openErrorModal({ type: 'FAILED_AND_RELOAD' }));
        }
      });
  };

/**
 * Save default bank choice
 * @param {Number} slotNumber - Array index number of the bank chosen by user as default
 * @param {String} bankId - Id of a bank
 * @param {String} accountNumber - as known as nomor rekening`
 */
export const saveDefaultBank =
  (
    slotNumber: number,
    {
      bankId,
      accountNumber,
      unmask = 0,
    }: {
      bankId: string | undefined;
      accountNumber: string | undefined;
      unmask?: number;
    },
    updateProfileDataCache: (newData: any) => void
  ): AppThunk<void> =>
  (dispatch) => {
    dispatch(setToggleChangeBankDefault(true));
    return postSetDefaultBank(slotNumber)
      .then(() => {
        dispatch(setToggleChangeBankDefault(false));
        dispatch(getBankAccounts({ unmask }));
        // after change from api, then change in local state
        dispatch(
          changeBankPageChangeDefaultAccount({
            bankId: bankId ? parseInt(bankId) : 0,
            accountNumber,
          })
        );

        dispatch(
          openToastMessage(
            'Berhasil mengubah bank pencairan utama',
            'blacksuccess',
            true,
            '',
            '',
            true
          )
        );

        // update cache data profile
        updateProfileDataCache({
          user: {
            bank: bankId,
            bank_account_number: accountNumber,
          },
        });
      })
      .catch(() => {
        dispatch(setToggleChangeBankDefault(false));
        dispatch(
          openToastMessage(
            'Gagal mengubah bank pencairan utama',
            'error',
            true,
            '',
            '',
            true
          )
        );
        return;
      });
  };

export default changeBankPage;
