import { AxiosResponse } from 'axios';
import http from 'core/http';
import endpoints from './endpoints';
import {
  DetailPortoByCategorySymbolResponse,
  PostSwitchPortoPayload,
} from '../types';

/**
 * POST: Execute switch porto
 * @param {object} data
 */
export const postSwitchPorto = ({
  from_category_id,
  symbol,
  ...rest
}: PostSwitchPortoPayload) => {
  return http.post(
    endpoints.portfoliosCategoryMutualfundsSwitch(from_category_id, symbol),
    rest
  );
};

/**
 * GET: detail porto by category and symbol
 * @param {string} categoryId
 * @param {string} fundId - RD***
 */
export const getDetailPortoByCategorySymbol = (
  categoryId: string,
  fundId: string
): Promise<AxiosResponse<DetailPortoByCategorySymbolResponse>> => {
  return http.get(endpoints.portfolioCategorySymbol(categoryId, fundId));
};
