import LazyModal from 'features/common/LazyModal';
import React from 'react';
import { useSelector } from 'react-redux';
import { TRootReducers } from 'store/root';

const LinkAjaErrorOrderCreation = React.lazy(
  () => import('./LinkAjaOrderCreationError')
);

/**
 * Lazying import LinkAjaError
 */
const LazyLinkAjaErrorOrderCreation: React.FC<React.PropsWithChildren<unknown>> = ({ ...rest }) => {
  const visible = useSelector(
    (state: TRootReducers) => state?.errorModal?.showLinkAjaError
  );

  return (
    <LazyModal
      component={LinkAjaErrorOrderCreation}
      visible={visible}
      {...rest}
    />
  );
};

export default LazyLinkAjaErrorOrderCreation;
