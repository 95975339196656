import { AxiosResponse } from 'axios';
import http from 'core/http';
import {
  PremiumTransactionStats,
  PremiumTransactionDetail,
  PremiumStatsAPIResponse,
  PremiumDetailsAPIResponse,
  ExitPremiumReasonsAPIResponse,
  PremiumStatsAPIPayload,
  PremiumFeedbackOptions,
  PremiumFeedbackQuotas,
} from '../types';
import { endpoints } from './index';

export interface ReadPremiumNotificationAPIPayload {
  id: number | string;
  is_show_modal: number;
  is_read: number;
}

export interface PremiumTransactionsStatsAPIResponse {
  message: string;
  data: Array<PremiumTransactionStats>;
}

export interface PremiumTransactionDetailAPIResponse {
  message: string;
  data: PremiumTransactionDetail;
}

export interface ApprovePremiumTransactionAPIPayload {
  wstrx_code: string;
}

export interface ApprovePremiumTransactionAPIResponse {
  message: string;
}

export interface CancelPremiumTransactionAPIPayload {
  wstrx_code: string;
}

export interface CancelPremiumTransactionAPIResponse {
  message: string;
}

export interface PremiumFeedbackOptionsAPIResponse {
  message: string;
  data: Array<PremiumFeedbackOptions>;
}

export interface PremiumFeedbackQuotasAPIResponse {
  message: string;
  data: PremiumFeedbackQuotas;
}

export interface SubmitPremiumFeedbackAPIPayload {
  rating: number; // 0 - 5
  feedbacks: Array<{
    id: number;
    feedback_detail?: string;
  }>;
}

export interface UpdatePremiumSettingAPIPayload {
  premium_setting_exclusive_invitation: 1 | 0;
  premium_setting_exclusive_promotion: 1 | 0;
  premium_setting_theme: 1 | 0;
  premium_setting_weekly_market_recap: 1 | 0;
  premium_setting_new_product_information: 1 | 0;
  premium_setting_discussion_preference: 1 | 0;
}

/**
 * Mark notification as read
 */
export const postReadPremiumNotification = (
  payload: ReadPremiumNotificationAPIPayload
) => {
  return http.post(endpoints.readPremiumNotification(payload.id), {
    is_show_modal: payload.is_show_modal,
    is_read: payload.is_read,
  });
};

/**
 * Patch Premium stats
 */
export const patchPremiumStats = (payload: PremiumStatsAPIPayload) => {
  return http.patch(endpoints.premiumStats, payload);
};

/**
 * Fetch Premium stats
 */
export const fetchPremiumStats = (): Promise<
  AxiosResponse<PremiumStatsAPIResponse>
> => {
  return http.get(endpoints.premiumStats);
};

/**
 * Fetch Premium details
 */
export const fetchPremiumDetails = (): Promise<
  AxiosResponse<PremiumDetailsAPIResponse>
> => {
  return http.get(endpoints.fetchPremiumDetails);
};

/**
 * List pending premium transactions
 */
export const getPremiumTransactionStats = (): Promise<
  AxiosResponse<PremiumTransactionsStatsAPIResponse>
> => {
  return http.get(endpoints.getPremiumTransactionStats);
};

/**
 * Premium transaction detail
 */
export const getPremiumTransactionDetail = (
  wstrx_code: string
): Promise<AxiosResponse<PremiumTransactionDetailAPIResponse>> => {
  return http.get(endpoints.getPremiumTransactionDetail(wstrx_code));
};

/**
 * Approve pending premium transaction
 */
export const postApprovePremiumTransaction = (
  payload: ApprovePremiumTransactionAPIPayload
): Promise<AxiosResponse<ApprovePremiumTransactionAPIResponse>> => {
  return http.post(endpoints.approvePremiumTransaction, payload);
};

/**
 * Cancel pending premium transaction
 */
export const postCancelPremiumTransaction = (
  payload: CancelPremiumTransactionAPIPayload
): Promise<AxiosResponse<CancelPremiumTransactionAPIResponse>> => {
  return http.post(endpoints.cancelPremiumTransaction, payload);
};

/**
 * Fetch bibit premium exit reasons
 */
export const fetchExitPremiumReasons = (): Promise<
  AxiosResponse<ExitPremiumReasonsAPIResponse>
> => {
  return http.get(endpoints.exitPremiumReasons);
};

/**
 * Fetch premium feedback options
 */
export const getPremiumFeedbackOptions = (): Promise<
  AxiosResponse<PremiumFeedbackOptionsAPIResponse>
> => {
  return http.get(endpoints.getPremiumFeedbackOptions);
};

/**
 * Fetch premium feedback quotas
 */
export const getPremiumFeedbackQuotas = (): Promise<
  AxiosResponse<PremiumFeedbackQuotasAPIResponse>
> => {
  return http.get(endpoints.getPremiumFeedbackQuotas);
};

/**
 * Submit premium feedback
 */
export const postSubmitPremiumFeedback = (
  payload: SubmitPremiumFeedbackAPIPayload
) => {
  return http.post(endpoints.submitPremiumFeedback, payload);
};

/**
 * Update premium setting
 */
export const patchUpdatePremiumSetting = (
  payload: UpdatePremiumSettingAPIPayload
) => {
  return http.patch(endpoints.updatePremiumSetting, payload);
};
