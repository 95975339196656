import {
  applyMiddleware,
  compose,
  createStore,
  AnyAction,
  Middleware,
} from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import rootReducer, { TRootReducers } from './root';
import { createLogger } from 'redux-logger';
import { getEnv } from 'core/env';

const initialState = {};
const enhancers = [];
const { ReducerLogging, NodeEnv } = getEnv();

const middleware: Middleware[] = [thunk];

if (NodeEnv === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  // Logging event on console tab
  if (ReducerLogging) {
    middleware.push(
      createLogger({
        collapsed: (_0, _1, logEntry) => {
          if (logEntry) {
            return !logEntry.error;
          }
          return false;
        },
      })
    );
  }

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

export type AppDispatch = ThunkDispatch<TRootReducers, any, AnyAction>;

export default store;
