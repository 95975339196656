import { getAccessToken } from 'core/refresh-token';
import { apiInstance } from 'core/http';
import deviceSystem from 'core/DeviceInfo/deviceSystem';
import { getCurrentInstitution } from 'features/institution/utils';
import Storage from 'core/Storage';
import { removeCurrentInstitution } from 'features/institution/utils';
import Analytics from 'utils/Analytics';
import Crisp from 'core/Crisp/utils';
import { getEnv } from 'core/env';

const { PublicUrl } = getEnv();

/**
 * Set default header value to axios default header
 */
export const initialHeaderHttp = async () => {
  // 1). get token
  // 2). get os
  const [token, os, institutionId] = await Promise.all([
    getAccessToken(),
    deviceSystem(),
    getCurrentInstitution(),
  ]);

  if (token) {
    // set token header default
    apiInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  // set platform
  apiInstance.defaults.headers.common['x-platform'] = os;

  //set institution id
  if (institutionId) {
    apiInstance.defaults.headers.common['x-institution-id'] =
      institutionId?.institution_id ?? '';
  }

  // set appsflyer header
  if (!!window.appsflyer) {
    Object.entries(window.appsflyer).forEach(([key, value]) => {
      // Ignore X-Platform to avoid double value sent to API
      if (key !== 'X-Platform' && value) {
        // set to axios
        apiInstance.defaults.headers.common[key] = value;
      }
    });
  }

  return token;
};

/**
 * Clear local user session
 *
 * This function will:
 * 1. Remove access token from local storage
 * 2. Remove roboid from local storage, so users registration process can be reset
 * 3. Tell our analytics to stop recording this user
 * 4. Unlink user from current crisp session
 * 5. Finally, fully redirect user to home page
 */
export async function clearLocalSessionAsync(redirect = '/') {
  await Storage.removeTokenHandler();

  // clear institution
  removeCurrentInstitution();
  await Storage.removeObject('rb');

  Analytics.clearRecordUser();

  Crisp.destroyCurrentSession();

  // run redirecting (refresh browser)
  const homeRoute = PublicUrl + redirect;
  return window.location.replace(homeRoute);
}
