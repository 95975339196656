import { createBrowserHistory, LocationDescriptorObject } from 'history';
import { History as HistoryType } from 'history';
import { getEnv } from 'core/env';

const env = getEnv();

/**
 * This remove slash char at the end of string
 */
function removeTrailingSlash(url = '') {
  if (typeof url !== 'string')
    throw new Error('Parameter passed must be string');
  return url.replace(/\/$/, '');
}

/**
 * Get basename used by current history object
 */
function getHistoryBasename(publicUrl: string) {
  const publicUrlLastSegment =
    '/' + (publicUrl && publicUrl.substring(publicUrl.lastIndexOf('/') + 1)) ||
    '';
  return removeTrailingSlash(publicUrlLastSegment);
}

/**
 *
 * @param h History instance
 * @description it will goBack when history length more than 2 otherwise go to HOME
 */
export const goBack = (h: HistoryType) => {
  if (h.length > 2) {
    return h.goBack();
  }
  h.replace('/');
};

type GoToHistory<HistoryLocationState = unknown> =
  | LocationDescriptorObject<HistoryLocationState> & {
      replace?: boolean;
    };

/**
 *
 * @param {Object} obj
 * {
 * 	pathname: '/here-path-name' -> this for the pathname url
 * 	state: {
 * 		foo: 'bar' -> this for the state you want to pass to the next route
 * 	}
 * }
 */
export const goTo = <HistoryLocationState = unknown>({
  replace,
  ...rest
}: GoToHistory<HistoryLocationState>) => {
  const pathname = rest?.pathname || '/';
  const split = pathname.split('?');
  const splittedPathName = split[0];
  const getQueryParams = split[1];
  const params = getQueryParams
    ? { pathname: splittedPathName, search: getQueryParams }
    : {};

  const fn = replace
    ? () => history.replace({ ...rest, ...params })
    : () => history.push({ ...rest, ...params });
  if (rest && rest.pathname) fn();
};

const basename = getHistoryBasename(env.PublicUrl);

const history = createBrowserHistory({
  basename,
});
export default history;
