import React from 'react';
import './ErrorCard.css';

interface Props {
  /** Message error that shows up when Error occur */
  message: string;
  /**
   * @deprecated  - Please use props onReload
   * Handling reload button
   *
   * */
  handleReload?: () => void;

  /**
   * Handling reload button
   */
  onReload?: () => void;
}

const ErrorCard: React.FC<React.PropsWithChildren<Props>> = ({ message, onReload, handleReload }) => {
  return (
    <span className='error-msg'>
      {message}
      <button className='btn-refetch' onClick={handleReload ?? onReload}>
        Reload
      </button>
    </span>
  );
};

export default ErrorCard;
