import { addDays } from 'date-fns';

/** When leads created_at already more than 3 days  */
export const checkIfLeadsExpired = (leadsCreatedAt: string) => {
  if (!!!leadsCreatedAt) return false;

  const today = new Date();
  const expiredDate = addDays(new Date(leadsCreatedAt), 3);

  return expiredDate < today;
};
