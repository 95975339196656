import React, { FC, MouseEvent, useEffect } from 'react';
import { Modal, ButtonNew } from '@bibitid/uikit-v1';
import classNames from 'classnames';
import checklistGreen from 'assets/images/checklist-green.svg';
import emptyTransparent from 'assets/images/empty.svg';
import Analytics from 'utils/Analytics';

import styles from './ModalSortReksaDana.module.css';
import { getAnalyticData } from '../utils/filterAnalytic';
interface Props {
  sortSelected: boolean;
  closeSortModal: () => void;
  selectedSortBy?: string;
  selectedSortPeriod?: string;
  selectedSortOrder?: string;
  periodVisible: boolean;
  im?: string;
  selectSortMethod: (e: MouseEvent<HTMLDivElement>) => void;
  selectPeriod: (e: MouseEvent<HTMLDivElement>) => void;
  implementSort: () => void;
  resetFilter?: () => void;
  selectedOtherFilter?: string | null;
  handleOtherFilter?: (event: MouseEvent<HTMLDivElement>) => void;
  isTerapkanButtonDisabled?: boolean;
  isResetButtonDisabled?: boolean;
  categoryType?: string;
  orderVisible?: boolean;
  selectOrder?: (e: MouseEvent<HTMLDivElement>) => void;
}

const ModalSortReksaDana: FC<React.PropsWithChildren<Props>> = ({
  sortSelected,
  closeSortModal,
  selectedSortBy,
  selectedSortPeriod,
  periodVisible,
  im,
  selectSortMethod,
  selectPeriod,
  implementSort,
  isTerapkanButtonDisabled,
  isResetButtonDisabled,
  categoryType,
  orderVisible = true,
  selectOrder,
  selectedSortOrder,
  resetFilter = () => {},
}) => {
  // for anaytic purpose
  const urlPath = window.location.pathname;
  const isRoboPage = urlPath.includes('/profiling-select/');

  const handleCloseModal = () => {
    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'close_modal',
        trigger: 'click',
        context: 'search.filter',
        data: {
          screen: 'filter',
        },
      },
    });

    closeSortModal();
  };

  useEffect(() => {
    if (!sortSelected) return;

    const data: any = getAnalyticData(isRoboPage, categoryType, im);

    Analytics.logEventNavigation({
      eventName: 'navigate',
      parameter: {
        page: 'search',
        view: 'filter',
        data,
      },
    });
  }, [sortSelected, isRoboPage, categoryType, im]);

  return (
    <Modal
      visible={sortSelected}
      onClose={handleCloseModal}
      className={styles['bit-sort-modal']}
    >
      <Modal.Header
        title='Urutkan Berdasarkan'
        showClose={true}
        showBackButton={false}
      />
      <Modal.Body>
        <div className={classNames(styles['bit-new-sorting'], 'nomargin')}>
          <div
            className={classNames(
              styles['bit-sorting-items'],
              styles['bit-sorting']
            )}
          >
            <div
              data-id='2'
              data-defaultsortby='desc'
              className={classNames({
                [styles['bit-filter-selected']]:
                  selectedSortBy === '2' || selectedSortBy === '8',
              })}
              onClick={selectSortMethod}
            >
              Total AUM
            </div>
            <div
              data-id='5'
              data-defaultsortby='desc'
              className={classNames({
                [styles['bit-filter-selected']]: selectedSortBy === '5',
              })}
              onClick={selectSortMethod}
            >
              Return
            </div>
            <div
              data-id='3'
              data-defaultsortby='desc'
              className={classNames({
                [styles['bit-filter-selected']]: selectedSortBy === '3',
              })}
              onClick={selectSortMethod}
            >
              Expense Ratio
            </div>
            <div
              data-id='4'
              data-defaultsortby='desc'
              className={classNames({
                [styles['bit-filter-selected']]: selectedSortBy === '4',
              })}
              onClick={selectSortMethod}
            >
              Max Drawdown
            </div>
            <div
              data-id='6'
              data-defaultsortby='desc'
              className={classNames({
                [styles['bit-filter-selected']]: selectedSortBy === '6',
              })}
              onClick={selectSortMethod}
            >
              Minimum Pembelian
            </div>
            {categoryType &&
            // 0 = others, 1 = equity from MutualFundCategories.tsx
            !['equity', 'others', 'usd', '0', '1'].includes(categoryType) ? (
              <div
                data-id='9'
                data-defaultsortby='desc'
                className={classNames({
                  [styles['bit-filter-selected']]: selectedSortBy === '9',
                })}
                onClick={selectSortMethod}
              >
                Average Yield
              </div>
            ) : null}
          </div>
        </div>
        {orderVisible && (
          <React.Fragment>
            <div className={styles['bit-new-sorting-title']}>Urutkan dari</div>
            <div className={classNames(styles['bit-new-sorting'], 'nomargin')}>
              <div
                className={classNames(
                  styles['bit-sorting-items'],
                  styles['bit-order']
                )}
              >
                <div data-id='desc' onClick={selectOrder}>
                  <input
                    type='radio'
                    readOnly
                    checked={selectedSortOrder === 'desc'}
                  />
                  <img
                    src={
                      selectedSortOrder === 'desc'
                        ? checklistGreen
                        : emptyTransparent
                    }
                    className={classNames({
                      [styles['bit-filter-checked']]:
                        selectedSortOrder === 'desc',
                    })}
                    alt='checklist green'
                  />
                  Tertinggi
                </div>
                <div data-id='asc' onClick={selectOrder}>
                  <input
                    type='radio'
                    readOnly
                    checked={selectedSortOrder === 'asc'}
                  />
                  <img
                    src={
                      selectedSortOrder === 'asc'
                        ? checklistGreen
                        : emptyTransparent
                    }
                    className={classNames({
                      [styles['bit-filter-checked']]:
                        selectedSortOrder === 'asc',
                    })}
                    alt='checklist green'
                  />
                  Terendah
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {!!periodVisible && (
          <React.Fragment>
            <div className={styles['bit-new-sorting-title']}>Periode</div>
            <div className={classNames(styles['bit-new-sorting'], 'nomargin')}>
              <div
                className={classNames(
                  styles['bit-sorting-items'],
                  styles['bit-periode']
                )}
              >
                <div
                  data-id='5y'
                  className={classNames({
                    [styles['bit-filter-selected']]:
                      selectedSortPeriod === '5y',
                  })}
                  onClick={selectPeriod}
                >
                  5 Year
                </div>
                <div
                  data-id='3y'
                  className={classNames({
                    [styles['bit-filter-selected']]:
                      selectedSortPeriod === '3y',
                  })}
                  onClick={selectPeriod}
                >
                  3 Year
                </div>
                <div
                  data-id='1y'
                  className={classNames({
                    [styles['bit-filter-selected']]:
                      selectedSortPeriod === '1y',
                  })}
                  onClick={selectPeriod}
                >
                  1 Year
                </div>
                {selectedSortBy === '5' && (
                  <React.Fragment>
                    <div
                      data-id='3m'
                      className={classNames({
                        [styles['bit-filter-selected']]:
                          selectedSortPeriod === '3m',
                      })}
                      onClick={selectPeriod}
                    >
                      3 Month
                    </div>
                    <div
                      data-id='1m'
                      className={classNames({
                        [styles['bit-filter-selected']]:
                          selectedSortPeriod === '1m',
                      })}
                      onClick={selectPeriod}
                    >
                      1 Month
                    </div>
                    <div
                      data-id='1d'
                      className={classNames({
                        [styles['bit-filter-selected']]:
                          selectedSortPeriod === '1d',
                      })}
                      onClick={selectPeriod}
                    >
                      1 Day
                    </div>
                    <div
                      data-id='ytd'
                      className={classNames('ytd', {
                        [styles['bit-filter-selected']]:
                          selectedSortPeriod === 'ytd',
                      })}
                      onClick={selectPeriod}
                    >
                      Year to Date
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
      {/* styles[''] */}
      <Modal.Footer
        primaryButton={
          <ButtonNew
            content='Reset'
            onClick={resetFilter}
            disabled={isResetButtonDisabled}
            variant='secondary'
            colorScheme='green'
            testId='modal-sort-btn-test'
          />
        }
        secondaryButton={
          <ButtonNew
            content='Terapkan'
            onClick={implementSort}
            disabled={isTerapkanButtonDisabled}
            variant='primary'
            testId='modal-sort-btn-test'
          />
        }
      />
    </Modal>
  );
};

export default ModalSortReksaDana;
