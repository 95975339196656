import queryClient from 'network/queryClient';
import { fetchRoboRecommendationById } from 'services/robo';
import { useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';
import * as Sentry from '@sentry/browser';
import { addMultipleReksadanaEntry } from 'entities/reksadana.reducer';
import { successrobo } from 'entities/user.reducer';
import { goTo } from 'utils/History';
import Storage from 'core/Storage';
import Analytics from 'utils/Analytics';
import { getUnixTime, parseISO } from 'utils/date';
import isEmpty from 'utils/isEmpty';
import { USER_LOGGED_IN_STATUS } from 'constants/common';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import { AxiosResponse } from 'axios';
import { ProfileDataResponse } from 'features/profile/types';

const useRestoreInitialUser = () => {
  const dispatch = useDispatch<any>();

  const { setUserLoggedInStatus } = useAuth();

  // TEMPORARILY: refactor as is
  // TODO: refactor the states into much simpler one
  // Notes: after further reading, this function is to check whether use has robo and/or pin
  // Notes: there's some files dependant to this state
  // Notes: userLoggedInStatus stores either <string>'hasNoRoboId' or <string>'hasNoPin'
  const checkUserLoggedInStatus = (data: any) => {
    // data must not an empty object
    if (!isEmpty(data)) {
      const pin = data?.pin || 0;
      const hasPin = pin === 1;
      const hasRoboId = Boolean(data?.roboid);
      if (!hasPin) {
        setUserLoggedInStatus(USER_LOGGED_IN_STATUS.hasNoPin);
      } else if (!hasRoboId) {
        setUserLoggedInStatus(USER_LOGGED_IN_STATUS.hasNoRoboId);
      } else {
        setUserLoggedInStatus('');
      }
    }
  };

  // Temporary, still don't know the best apporach to refactor this
  const restoreRobo = (roboid: string) => {
    queryClient.fetchQuery('roboCheck', () => {
      fetchRoboRecommendationById(roboid)
        .then((res) => {
          const payload = res?.data?.data;
          const { roboid } = payload;
          const recommendation = payload?.result?.recommendation || {};
          const { equity, fixincome, moneymarket } = recommendation;

          if (!!!roboid) {
            setTimeout(
              () =>
                goTo({
                  pathname: '/profiling',
                  search: '?back=false',
                }),
              100
            );
          }

          dispatch(addMultipleReksadanaEntry(equity?.items || []));
          dispatch(addMultipleReksadanaEntry(fixincome?.items || []));
          dispatch(addMultipleReksadanaEntry(moneymarket?.items || []));
          dispatch(successrobo({ robo: payload }));
        })
        .catch((err: any) => {
          const errorStatus = err?.response?.status;

          if (errorStatus !== 503) {
            const errorModalObj = {
              type: 'FAILED_PROFILE',
              message:
                'Gagal mengambil profil data. Silakan coba beberapa saat lagi',
            };

            dispatch(openErrorModal(errorModalObj));
          }
        });
    });
  };

  const restoreInitialUser = async (
    res: AxiosResponse<ProfileDataResponse>
  ) => {
    try {
      const data = res?.data?.data || {};

      // Vars
      const roboid = data?.roboid;
      const userData = data?.user || {};
      const id = userData?.id;
      const email = userData?.email;

      // transactions
      const hasRecurring = data?.is_have_recurring || false;
      const hasTx = data?.is_have_transaction || false;
      const registerDate = data?.user?.approval_date || '';
      const formattedRegisterDate = getUnixTime(parseISO(registerDate));

      /**
       *  will check if user has pin or robo id. Will force user to
       *  set pin or profile risk questionaire if there is no pin nor robo id
       */
      checkUserLoggedInStatus(data);

      // remove hiddenCompleteYourProfile data
      Storage.removeObject('hiddenCompleteYourProfile');

      /**
       * Handle Robo
       */
      if (roboid) {
        Storage.setObject('rb', roboid);
        restoreRobo(roboid);
      } else {
        Storage.setObject('rb', '');
      }

      /**
       * Monitoring and Trackers
       */
      Sentry.setUser({
        id: String(id) || undefined,
        email: email || '',
        robo: roboid || '',
      });

      Analytics.recordUser({
        id: `${id}`,
        Name: userData?.fullname, // String
        Email: userData?.email, // Email address of the user
        Phone: `+${userData?.phone}`, // Phone (with the country code)
        Gender: userData?.gender === 1 ? 'M' : 'F',
        DOB: new Date(userData?.birth_date),
        HAVE_REMINDER: hasRecurring,
        HAVE_TRANSACTION: hasTx,
        FINISH_REGISTER_DATE: formattedRegisterDate,
      });
    } catch (error) {
      const message = (error as Error).message;
      const errorAttr = {
        message,
        type: '',
      };

      return dispatch(openErrorModal(errorAttr));
    }
  };

  return { restoreInitialUser, restoreRobo, checkUserLoggedInStatus };
};

export default useRestoreInitialUser;
