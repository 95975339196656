import { AxiosResponse } from 'axios';

import { indexedDB } from './indexedDb/indexedDb';

export const injectApiInterceptors = async (res: AxiosResponse) => {
  const requestUrl = `[${res.config.method}]${res.config.url}`;

  const value: any = await indexedDB.get(requestUrl);

  await indexedDB.add({
    requestUrl: requestUrl,
    id: requestUrl,
    response: res,
    customResponse: value?.customResponse ? value.customResponse : res,
    isCustomed: false,
    isReplaced: !!value?.isReplaced,
  });

  if (value?.isReplaced) {
    return JSON.parse(value?.customResponse);
  }

  return res;
};
