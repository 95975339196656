import { dispatch } from 'store/redux';
import { openErrorModal } from 'features/errors/errorModal.reducer';

export function showUploadLimitModal() {
  return dispatch(
    openErrorModal({
      title: 'Melebihi batas jumlah upload.',
      message:
        'Jumlah permintaan upload melebihi batas, silakan hubungi Tim Support Bibit untuk bantuan.',
    })
  );
}
