import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import FlagProvider, {
  UnleashClient,
  useFlag as useFlagUnleash,
} from '@unleash/proxy-client-react';
import defaultFlagKeys, { FlagKeys, FlagKeysObject } from './flagKeys';
import { useAuth, useProfileData } from 'hooks';
import * as Sentry from '@sentry/browser';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import Storage from 'core/Storage';
import { config, OVERRIDE_FLAG_KEY_STORAGE } from './constants';
import { getOverrideFlagQuery, useOverrideFlagQuery } from './resolvers';
import { showDevtools } from 'devtools/constants/common';
import { Toast } from '@bibitid/uikit';

/**
 * Init Unleash Client
 */
const client = new UnleashClient(config);

export const FeatureFlagContext = createContext<{
  /**
   * Current Flag data.
   * Combine static data `defaultFlagKeys` and from override flag data local storage
   */
  flagKeys: FlagKeysObject;
  /**
   * Refetch Override Flags Data Storage
   */
  refetchOverrideFlagsDataStorage: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<string, unknown>>;
  /**
   * Set override flag to local storage
   */
  setOverrideFlags: (key: FlagKeys, overrideValue: boolean) => Promise<void>;
  removeOverrideFlag: (key: FlagKeys) => Promise<void>;
  removeAllOverrideFlags: () => Promise<void>;
}>({
  flagKeys: defaultFlagKeys,
  refetchOverrideFlagsDataStorage: () => new Promise(() => null),
  setOverrideFlags: () => new Promise(() => null),
  removeOverrideFlag: () => new Promise(() => null),
  removeAllOverrideFlags: () => new Promise(() => null),
});

/**
 * Abstraction for our feature flag provider
 */
const FeatureFlagProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isLogin } = useAuth();
  const { data: dataProfile, isFetched } = useProfileData(isLogin);

  /**
   * Get data override flags from local storage
   */
  const {
    data: overrideFlags,
    refetch: refetchOverrideFlagsDataStorage,
    isFetching: overrideFlagIsFetching,
  } = useOverrideFlagQuery(showDevtools);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  /** Profile user id */
  const userId = profileDetail?.user?.id ?? 0;

  /**
   * Eligible to start unleash client when:
   * - user is not logged in or
   * - user is logged in and done fetch user data profile
   */
  const eligibleToStart = !isLogin || (isLogin && isFetched);

  /**
   * Function to handle unleash context before start.
   * - When user is logged in, update unleash context first with user id data for identifier then start unleash
   * - When user is not logged in, start unleash immediately and use `sessionId` for identifier
   */
  const updateAndStartUnleash = useCallback(async () => {
    try {
      if (userId) {
        // Attach user id to feature flag config, this allows feature flag to specifically show things to specific users
        await client.updateContext({ userId: `${userId}` });
      }
      await client.start();

      if (showDevtools) {
        // set feature flag
        refetchOverrideFlagsDataStorage();
      }
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag('domain', 'featureFlag');
        Sentry.captureException(error);
      });
    }
  }, [userId, refetchOverrideFlagsDataStorage]);

  const handleSetOverrideFlags = async (
    key: FlagKeys,
    overrideValue: boolean
  ) => {
    if (overrideFlagIsFetching) return;

    const valueText = !!overrideValue ? 'ON (FORCE)' : 'OFF (FORCE)';
    const newOverrideFlags = overrideFlags || {};

    newOverrideFlags[key] = { overrideValue };

    try {
      await Storage.set(
        OVERRIDE_FLAG_KEY_STORAGE,
        JSON.stringify(newOverrideFlags)
      );
      await refetchOverrideFlagsDataStorage();
      Toast.show({
        content: `Success change ${key} value to ${valueText}`,
        position: 'bottom',
      });
    } catch (err) {}
  };

  const handleRemoveOverideFlag = async (key: FlagKeys) => {
    if (overrideFlagIsFetching) return;
    const newOverrideFlags = overrideFlags || {};

    delete newOverrideFlags[key];

    try {
      await Storage.set(
        OVERRIDE_FLAG_KEY_STORAGE,
        JSON.stringify(newOverrideFlags)
      );
      await refetchOverrideFlagsDataStorage();
      Toast.show({
        content: `Success change ${key} value to server flag`,
        position: 'bottom',
      });
    } catch (err) {}
  };

  const handleRemoveAllOverrideFlags = async () => {
    if (overrideFlagIsFetching) return;

    try {
      await Storage.remove(OVERRIDE_FLAG_KEY_STORAGE);
      await refetchOverrideFlagsDataStorage();
      Toast.show({
        content: `Success reset to feature flag server`,
        position: 'bottom',
      });
    } catch (err) {}
  };

  useEffect(() => {
    if (eligibleToStart) {
      // start unleash client when eligible
      updateAndStartUnleash();
    }
  }, [eligibleToStart, updateAndStartUnleash]);

  return (
    <FeatureFlagContext.Provider
      value={{
        flagKeys: {
          ...defaultFlagKeys,
          ...overrideFlags,
        },
        refetchOverrideFlagsDataStorage,
        setOverrideFlags: handleSetOverrideFlags,
        removeOverrideFlag: handleRemoveOverideFlag,
        removeAllOverrideFlags: handleRemoveAllOverrideFlags,
      }}
    >
      <FlagProvider unleashClient={client} startClient={false}>
        {children}
      </FlagProvider>
    </FeatureFlagContext.Provider>
  );
};

/**
 * Feature flag custom hooks
 * @param {FlagKeys} key flag key
 */
export const useFlag = (key: FlagKeys): boolean => {
  const { flagKeys } = useContext(FeatureFlagContext);
  const unleashValue = useFlagUnleash(key);

  /**
   * flag value from unleash
   */
  /**
   * default flag value
   */
  const defaultValue = flagKeys[key];

  // return default value when flag has object value
  if (typeof defaultValue === 'object') {
    return defaultValue.overrideValue;
  }

  return unleashValue;
};

/**
 * Get flag
 */
export const getFlag = (key: FlagKeys) => {
  const flagKeys = getOverrideFlagQuery();
  /**
   * flag value from unleash
   */
  const unleashValue = client.isEnabled(key);

  /**
   * default flag value
   */
  const defaultValue = flagKeys[key];

  // return default value when flag has object value
  if (typeof defaultValue === 'object') {
    return defaultValue.overrideValue;
  }
  return unleashValue;
};

export default FeatureFlagProvider;
