import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { RouteApp, TabBar } from './components';
import rqClient from 'network/queryClient';
import styles from './MainRoute.module.css';
import ScrollRestoration from 'routes/ScrollRestoration';

/**
 * Combine all routes and tabbar
 */
const MainRoute: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation();

  const history = useHistory();

  const transitionPageCss =
    history.action === 'POP' ? 'animation-left-right' : 'animation-right-left';

  /**
   * Transform route pathname from /this/is/path/name --> this-is-path-name
   */
  const pathNameDashes = location.pathname
    ?.split('/')
    .filter((n) => n)
    ?.join('-');

  /** React query data for showing TabBar */
  const { data: showTabBarData } = useQuery('showTabBar', () => {
    return rqClient.getQueryData<{ visible?: boolean }>('showTabBar');
  });

  /**
   * This is temporary, all will be replaced with react query later on.
   * Determine to use which state according to props passed on route level.
   * Check LazyRouteApp.
   */
  const showTabbar = !!showTabBarData ? showTabBarData?.visible : true;

  return (
    <div
      className={classNames(
        transitionPageCss,
        `${styles['bibit-main-app']} outerwrap bibit-plus ${pathNameDashes}`,
        {
          'has-tab': showTabbar,
        }
      )}
    >
      <ScrollRestoration />
      <RouteApp />
      <TabBar />
    </div>
  );
};

export default MainRoute;
