import { useQueryClient } from 'react-query';
import { PortfolioData } from 'features/portfolio/types';

/**
 * A custom hook to determine whether the user is a first-time buyer (FTB) or not.
 * Used to prefill the input amount of top-up behavior.
 */
const useFirstTimeBuyer = (): boolean => {
  const queryClient = useQueryClient();

  /**
   * Previous portfolio data. Used to prevent double hitting '/portfolio' endpoint.
   */
  const portfolioData = queryClient.getQueryData(
    'Portfolio List'
  ) as PortfolioData;

  /**
   * The mutual fund market value. If the value is 0, it means the user is a first-time buyer.
   */
  const mutualFundMarketValue = portfolioData?.mutual_fund_market_value;

  /**
   * - If the value is 0, return 'true' for first-time buyer.
   * - Otherwise, return 'false'.
   */
  return !mutualFundMarketValue;
};

export default useFirstTimeBuyer;
