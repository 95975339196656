export const SERVICE_FOR_NATIVE_ONLY =
  'Hanya tersedia di Aplikasi Android dan iOS';

export const USER_LOGGED_IN_STATUS = {
  hasNoPin: 'hasNoPin',
  hasNoRoboId: 'hasNoRoboId',
};
/**
 * Maximum amount for user can create recurring (`1.000.000.000` in Rupiah)
 */
export const MAX_TRANSACTION_AMOUNT = 1000000000; // 1bio
/**
 * Maximum amount for using RDN payment.
 * - This variable is only to display wording 'RDN stockbit' in autodebet banner from top up flow if amount less/equal.
 * - For list payment RDN channel has provided by API depends on amount.
 *
 * Note: Because this value exceeds the maximum safe integer value that can be represented
 * accurately in JavaScript, so we could represent the large integer as a string instead of a number.
 * keep in mind that we may need to convert the string to a number.
 */
export const MAX_TRANSACTION_AMOUNT_RDN = '100000000000000000000';

// Export Link WA Admin Bibit
export const LINK_NUMBER = 'https://wa.me/622150864230';

export const MAX_FIELDS_OTP = 4;

export const BANK_JAGO_ID = 72;

export const BANK_JAGO_SYARIAH_ID = 146;

export const BANK_BCA_ID = 31;

/**
 * Pie chart color of robo composition based on sequence
 * green [#00ab6b] - moneymarket
 * blue [#2478b4] - fixincome
 * purple [#9343c8] - equity
 */
export const ROBO_CHART_COLOR = [
  'var(--green-default)',
  'var(--cobalt-default)',
  'var(--violet-default)',
];

export const ROBO_PROFILE_DESCRIPTION = {
  moderate: {
    title_id: 'Moderat',
    title_en: 'Moderate',
    description:
      'Portofolio ini mempunyai toleransi terhadap risiko yang tidak terlalu tinggi dimana return yang diharapkan diatas suku bunga deposito denan fluktuasi nilai pasar yang moderat untuk mencapai tujuan investasi kamu.',
  },
  aggressive: {
    title_id: 'Agresif',
    title_en: 'Aggressive',
    description:
      'Portofolio ini mempunyai toleransi terhadap risiko yang tinggi supaya dapat memperoleh return tertinggi guna meraih tujuan investasi kamu.',
  },
  conservative: {
    title_id: 'Konservatif',
    title_en: 'Conservative',
    description:
      'Portofolio ini mempunyai toleransi terhadap risiko yang cenderung rendah dimana return yang diharapkan minimal setara dengan suku bunga deposito dengan fluktuasi nilai pasar yang minimal untuk mencapai tujuan investasi kamu.',
  },
};

/**
 * Default color bank, when data is empty
 */

export const DEFAULT_COLOR_BANK = '#0A5FA5';

/**
 * Bibit Plus exact name
 */
export const BIBIT_PLUS_NAME = 'Bibit Plus';

// Links App Galery
export const ANDROID_LINK_APPSTORE =
  'https://play.google.com/store/apps/details?id=com.bibit.bibitid';
export const IOS_LINK_APPSTORE = 'https://apps.apple.com/app/id1445856964';
export const HUAWEI_LINK_APPSTORE =
  'https://appgallery.huawei.com/app/C103011423';

export const MANUAL_PAYMENT_TITLE = 'Transfer dari Semua Bank';
export const MANUAL_PAYMENT_DESCRIPTION =
  'Rekening tujuan BCA. Biaya transfer antar bank berlaku.';
