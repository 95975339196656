import {
  getRoboRecommendations,
  saveRoboScoreUpdate,
} from 'entities/robo.reducer';
import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import Analytics from 'utils/Analytics';
import history from 'utils/History';
import {
  fetchGoalTemplate,
  postCreateGoal,
  postCreateGoalPreview,
  postPortfolioCategoryDelete,
  postApplyGoalByCategoryId,
  postPreviewGoalByCategoryId,
  postUpdateGoal,
} from '../../../../services/goalSettings';
import { handleResponseGeneral } from 'utils/http-response';
import getSafely from '../../../../utils/safely';
import { openErrorModal } from '../../../errors/errorModal.reducer';
import {
  getPortfolioCategoriesDetailData,
  getPortfolioCategoryData,
  getPortfolioGoalStatus,
  getPortfolioGoalDetail,
  getGoalProgressChart,
  portfolioPageLoading,
} from '../../../portfolio/portfolioPage.reducer';
import { format, addYears } from 'utils/date';
import queryClient from 'network/queryClient';
import { postPortfolioChangeIcon } from 'services/portfolio';

const now = new Date();
const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);

const defaultState = {
  chooseIconVisible: false,
  createRoboPortoVisible: false,
  createNonRoboPortoVisible: false,
  editPortfolioVisible: false,
  deletePortfolioVisible: false,
  confirmationVisible: false,
  createPensionFundGoalVisible: false,

  editRoboScore: {
    roboid: '',
    editRoboScoreModalVisible: false,
  },

  editedCategoryId: null,
  templateLoading: false,
  templates: [],
  chosenTemplate: null,
  newPortoName: null,
  newPortoTargetAmount: null,
  newPortoDateTarget: nextMonth,

  createPortoFormErrors: null,
  previewLoading: false,
  createLoading: false,
  newGoalPreview: null,
  totalPorto: 0,
  recurringAmount: 0,
  deleteGoalLoading: false,
  pensionAge: 65, // default pension age
};

// Actions
const [
  newCreateRoboPortoVisible,
  createNonRoboPortoVisible,
  goalSettingsReset,
  setEditRoboScoreModalVisible,
  newGoalChooseIconVisible,
  newGoalCreatePortoVisible,
  newGoalEditPortoVisible,
  newGoalConfirmationVisible,
  goalSettingsTemplateLoading,
  goalSettingsTemplateLoaded,
  goalSettingsPreviewLoading,
  goalSettingsPreviewLoaded,
  createGoalLoading,
  createGoalErrorReset,
  goalSettingChooseIcon,
  newGoalTypePortoName,
  newGoalTypePortoAmount,
  newGoalTypePortoDate,
  newGoalTypePortoScore,
  createNewGoalFormError,
  toggleDeleteConfirmationModal,
  deleteConfirmationModalSetVisible,
  deleteConfirmationModalVisible,
  deleteGoalIsLoading,
  previewGoalChangeRecurringAmount,
  createPensionFundGoalVisible,
  pensionAge,
] = [
  'NEW_CREATE_ROBO_PORTO_VISIBLE',
  'CREATE_NON_ROBO_PORTO_VISIBLE',
  'GOAL_SETTINGS_RESET',
  'SET_EDIT_ROBO_SCORE_MODAL_VISIBLE',
  'NEW_GOAL_CHOOSE_ICON_VISIBLE',
  'NEW_GOAL_CREATE_PORTO_VISIBLE',
  'NEW_GOAL_EDIT_PORTO_VISIBLE',
  'NEW_GOAL_CONFIRMATION_VISIBLE',
  'GOAL_SETTINGS_TEMPLATE_LOADING',
  'GOAL_SETTINGS_TEMPLATE_LOADED',
  'GOAL_SETTINGS_PREVIEW_LOADING',
  'GOAL_SETTINGS_PREVIEW_LOADED',
  'CREATE_GOAL_LOADING',
  'CREATE_GOAL_ERROR_RESET',
  'GOAL_SETTING_CHOOSE_ICON',
  'NEW_GOAL_TYPE_PORTO_NAME',
  'NEW_GOAL_TYPE_PORTO_AMOUNT',
  'NEW_GOAL_TYPE_PORTO_DATE',
  'NEW_GOAL_TYPE_PORTO_SCORE',
  'CREATE_NEW_GOAL_FORM_ERROR',
  'TOGGLE_DELETE_CONFIRMATION_MODAL',
  'DELETE_CONFIRMATION_MODAL_SET_VISIBLE',
  'DELETE_CONFIRMATION_MODAL_VISIBLE',
  'DELETE_GOAL_IS_LOADING',
  'PREVIEW_GOAL_CHANGE_RECURRING_AMOUNT',
  'CREATE_PENSION_FUND_GOAL_VISIBLE',
  'GOAL_PENSION_AGE',
].map(createAction);

// Reducer
const goalSettings = createReducer(
  {
    [newCreateRoboPortoVisible]: (state, payload) => {
      return update(state, {
        createRoboPortoVisible: { $set: payload },
      });
    },
    [createNonRoboPortoVisible]: (state, payload) => {
      return update(state, {
        createNonRoboPortoVisible: { $set: payload },
      });
    },
    [goalSettingsReset]: (state) => {
      return update(state, { $set: defaultState });
    },
    [setEditRoboScoreModalVisible]: (state, payload) => {
      return update(state, {
        editRoboScore: {
          // keep roboid if payload.roboid is not present
          roboid: {
            $set: payload.roboid || state.editRoboScore.roboid,
          },
          editRoboScoreModalVisible: { $set: payload.visible },
        },
      });
    },
    [newGoalChooseIconVisible]: (state, payload) => {
      return update(state, {
        chooseIconVisible: { $set: payload },
      });
    },
    [newGoalCreatePortoVisible]: (state, payload) => {
      const newState = {
        editPortfolioVisible: { $set: payload.visible },
      };
      if (payload.visible === false && payload.keep_id !== true) {
        newState.editedCategoryId = { $set: null }; // if porto edit set to false, make sure editedCategoryId to be reset
      }

      if (payload.defaultName) {
        newState.newPortoName = { $set: payload.defaultName };
      }
      return update(state, newState);
    },
    [newGoalEditPortoVisible]: (state, payload) => {
      const newState = {
        editedCategoryId: { $set: payload.categoryId },
        editPortfolioVisible: {
          // Sebelumnya buat dana pensiun di pisah
          // Sekarang edit goal dan dana pensiun di samain
          $set: !!payload.visible,
        },
        chosenTemplate: { $set: payload.templateId },
        newPortoName: { $set: payload.portoName },
        newPortoTargetAmount: { $set: payload.targetAmount },
        newPortoDateTarget: { $set: payload.dateTarget },
        totalPorto: { $set: payload.totalPorto },
      };
      return update(state, newState);
    },
    [newGoalConfirmationVisible]: (state, payload) => {
      return update(state, {
        confirmationVisible: { $set: payload },
      });
    },
    [goalSettingsTemplateLoading]: (state, payload) => {
      return update(state, {
        templateLoading: { $set: payload },
      });
    },
    [goalSettingsTemplateLoaded]: (state, payload) => {
      return update(state, {
        templateLoading: { $set: false },
        templates: { $set: payload },
      });
    },
    [goalSettingChooseIcon]: (state, payload) => {
      return update(state, {
        chosenTemplate: { $set: payload },
      });
    },
    [newGoalTypePortoName]: (state, payload) => {
      return update(state, {
        newPortoName: { $set: payload },
      });
    },
    [newGoalTypePortoAmount]: (state, payload) => {
      return update(state, {
        newPortoTargetAmount: { $set: payload },
      });
    },
    [newGoalTypePortoDate]: (state, payload) => {
      return update(state, {
        newPortoDateTarget: { $set: payload },
      });
    },
    [newGoalTypePortoScore]: (state, payload) => {
      return update(state, {
        newPortoScore: { $set: payload },
      });
    },
    [goalSettingsPreviewLoading]: (state) => {
      return update(state, {
        previewLoading: { $set: true },
        createPortoFormErrors: { $set: defaultState.createPortoFormErrors },
      });
    },
    [goalSettingsPreviewLoaded]: (state, payload) => {
      return update(state, {
        previewLoading: { $set: false },
        newGoalPreview: { $set: payload },
      });
    },
    [createNewGoalFormError]: (state, payload) => {
      return update(state, {
        createPortoFormErrors: { $set: payload },
      });
    },
    [createGoalLoading]: (state, payload) => {
      return update(state, {
        createLoading: { $set: payload },
      });
    },
    [createGoalErrorReset]: (state, payload) => {
      return update(state, {
        createPortoFormErrors: { $set: null },
      });
    },
    [toggleDeleteConfirmationModal]: (state, payload) => {
      return update(state, {
        deletePortfolioVisible: { $set: !state.deletePortfolioVisible },
        editPortfolioVisible: { $set: !state.editPortfolioVisible },
      });
    },
    [deleteConfirmationModalSetVisible]: (state, payload) => {
      return update(state, {
        deletePortfolioVisible: { $set: payload.visible },
        editedCategoryId: { $set: payload.categoryId },
      });
    },
    [deleteConfirmationModalVisible]: (state, payload) => {
      return update(state, {
        deletePortfolioVisible: { $set: payload },
      });
    },
    [deleteGoalIsLoading]: (state, payload) => {
      return update(state, {
        deleteGoalLoading: { $set: payload },
      });
    },
    [createPensionFundGoalVisible]: (state, payload) => {
      return update(state, {
        createPensionFundGoalVisible: { $set: payload },
      });
    },
    [previewGoalChangeRecurringAmount]: (state, payload) => {
      return update(state, {
        newGoalPreview: {
          $set: payload,
        },
        previewLoading: { $set: false },
      });
    },
    [pensionAge]: (state, payload) => {
      return update(state, {
        pensionAge: { $set: payload },
      });
    },
  },
  defaultState
);

export {
  goalSettingsReset,
  newGoalChooseIconVisible,
  newGoalCreatePortoVisible,
  newCreateRoboPortoVisible,
  createNonRoboPortoVisible,
  newGoalEditPortoVisible,
  newGoalConfirmationVisible,
  goalSettingChooseIcon,
  newGoalTypePortoName,
  newGoalTypePortoAmount,
  newGoalTypePortoDate,
  newGoalTypePortoScore,
  toggleDeleteConfirmationModal,
  deleteConfirmationModalSetVisible,
  createPensionFundGoalVisible,
  previewGoalChangeRecurringAmount,
  createNewGoalFormError,
  createGoalErrorReset,
  setEditRoboScoreModalVisible,
  goalSettingsPreviewLoaded,
  goalSettingsPreviewLoading,
};

export function loadGoalTemplate() {
  return async (dispatch) => {
    dispatch(goalSettingsTemplateLoading(true));
    try {
      const fetchResponse = await fetchGoalTemplate();
      const parsedResponse = handleResponseGeneral(fetchResponse);
      const parsedData = getSafely(['data'], parsedResponse);

      dispatch(goalSettingsTemplateLoaded(parsedData));
      return parsedData;
    } catch (error) {
      if (error.response) {
        const parsedResponse = handleResponseGeneral(error.response);

        if (parsedResponse) {
          // Open modal if it is not form error
          const errorModalObj = {
            type: parsedResponse?.type,
            message: parsedResponse?.message,
          };
          dispatch(openErrorModal(errorModalObj));
        }
      }

      dispatch(goalSettingsTemplateLoading(false));
    }
  };
}

export function previewGoalByCategoryId(categoryId, goalData) {
  return async (dispatch) => {
    try {
      const previewResult = await postPreviewGoalByCategoryId(
        categoryId,
        goalData
      );
      const recurringAmount = getSafely(['data', 'data'], previewResult);
      dispatch(previewGoalChangeRecurringAmount(recurringAmount));

      // Reset goal error message
      dispatch(createGoalErrorReset());
    } catch (error) {
      if (error.response) {
        const parsedResponse = handleResponseGeneral(error.response);

        if (getSafely(['errors'], parsedResponse)) {
          return dispatch(createNewGoalFormError(parsedResponse.errors));
        }

        if (parsedResponse) {
          // Open modal if it is not form error
          const errorModalObj = {
            type: parsedResponse?.type,
            message: parsedResponse?.message,
          };
          return dispatch(openErrorModal(errorModalObj));
        }
      }
    }
  };
}

/** Preview goal edited porto data */
export function createGoalSeePreview(showPreviewModal = false) {
  return async (dispatch, getState) => {
    try {
      const chosenTemplate = getSafely(
        ['goalSettings', 'chosenTemplate'],
        getState()
      );
      const newPortoTargetAmount = getSafely(
        ['goalSettings', 'newPortoTargetAmount'],
        getState()
      );
      const newPortoDateTarget = getSafely(
        ['goalSettings', 'newPortoDateTarget'],
        getState()
      );

      const categoryIdEdit = getSafely(
        ['goalSettings', 'editedCategoryId'],
        getState()
      );

      const targetDate = format(new Date(newPortoDateTarget), 'yyyy-MM-dd');
      const previewData = {
        portfolio_template: chosenTemplate,
        target_amount: newPortoTargetAmount,
        target_date: targetDate,
        is_robo: 1,
      };

      Analytics.logEventAction({
        eventName: 'portfolio_action',
        parameter: {
          action: 'new_porto_type',
          context: 'portfolio.new_robo_type',
          trigger: 'click',
          data: {
            portofolio_type: 'robo',
          },
        },
      });

      /** Kalau ada categoryIdEdit, Kirim key: category_id untuk Preview Porto */

      if (!!categoryIdEdit) {
        dispatch(previewGoalByCategoryId(categoryIdEdit, previewData));
      }

      const fetchResponse = await postCreateGoalPreview(previewData);

      const parsedResponse = handleResponseGeneral(fetchResponse);
      const parsedData = getSafely(['data'], parsedResponse);

      if (showPreviewModal) {
        // Hide last modal
        dispatch(newGoalCreatePortoVisible({ visible: false }));

        // Show preview modal
        dispatch(newGoalConfirmationVisible(true));
      }
      return parsedData;
    } catch (error) {
      if (error.response) {
        const parsedResponse = handleResponseGeneral(error.response);
        if (getSafely(['errors'], parsedResponse)) {
          return dispatch(createNewGoalFormError(parsedResponse.errors));
        }

        if (parsedResponse) {
          // Open modal if it is not form error
          const errorModalObj = {
            type: parsedResponse?.type,
            message: parsedResponse?.message,
          };
          return dispatch(openErrorModal(errorModalObj));
        }
      }
    }
  };
}

/**
 * passing product symbol to automatically open topup modal
 * on first goal set/created
 */
export function createNewGoalNonRobo(productData) {
  return async (dispatch, getState) => {
    const chosenTemplate = getSafely(
      ['goalSettings', 'chosenTemplate'],
      getState()
    );
    const newPortoTargetAmount = getSafely(
      ['goalSettings', 'newPortoTargetAmount'],
      getState()
    );
    const newPortoDateTarget = getSafely(
      ['goalSettings', 'newPortoDateTarget'],
      getState()
    );
    const newPortoName = getSafely(
      ['goalSettings', 'newPortoName'],
      getState()
    );

    dispatch(createGoalLoading(true));

    const targetDate = format(new Date(newPortoDateTarget), 'yyyy-MM-dd');
    const previewData = {
      portfolio_name: newPortoName,
      portfolio_template: chosenTemplate,
      target_amount: newPortoTargetAmount,
      target_date: targetDate,
      is_robo: 0,
    };

    Analytics.logEventAction({
      eventName: 'portfolio_action',
      parameter: {
        action: 'new_porto_type',
        context: 'portfolio.new_robo_type',
        trigger: 'click',
        data: {
          portofolio_type: 'non_robo',
        },
      },
    });

    try {
      const fetchResponse = await postCreateGoal(previewData);
      const parsedResponse = handleResponseGeneral(fetchResponse);
      const parsedData = getSafely(['data'], parsedResponse);

      dispatch(createGoalLoading(false));

      const newCategoryId = getSafely(['portfolio', 'id'], parsedData, '');

      if (history) {
        // set loading first
        dispatch(portfolioPageLoading());

        history.push({
          pathname: `/portfolio/${newCategoryId}/details`,
          state: {
            open_topup: true,
            product: productData,
          },
        });
      }
    } catch (error) {
      dispatch(createGoalLoading(false));

      if (error.response) {
        const parsedResponse = handleResponseGeneral(error.response);

        if (parsedResponse) {
          // Open modal if it is not form error
          const errorModalObj = {
            type: parsedResponse?.type,
            message: parsedResponse?.message,
          };
          return dispatch(openErrorModal(errorModalObj));
        }
      }
    }

    dispatch(newGoalConfirmationVisible(false));

    dispatch(createGoalErrorReset());

    return;
  };
}

export function createNewGoal(history) {
  return async (dispatch, getState) => {
    try {
      const chosenTemplate = getSafely(
        ['goalSettings', 'chosenTemplate'],
        getState()
      );
      const newPortoTargetAmount = getSafely(
        ['goalSettings', 'newPortoTargetAmount'],
        getState()
      );
      const newPortoDateTarget = getSafely(
        ['goalSettings', 'newPortoDateTarget'],
        getState()
      );
      const newPortoName = getSafely(
        ['goalSettings', 'newPortoName'],
        getState()
      );
      const newPortoScore = getSafely(
        ['goalSettings', 'newPortoScore'],
        getState()
      );

      dispatch(createGoalLoading(true));

      const targetDate = format(new Date(newPortoDateTarget), 'yyyy-MM-dd');
      const previewData = {
        portfolio_name: newPortoName,
        portfolio_template: chosenTemplate,
        target_amount: newPortoTargetAmount,
        target_date: targetDate,
        is_robo: 1,
      };

      const fetchResponse = await postCreateGoal(previewData);
      const parsedResponse = handleResponseGeneral(fetchResponse);
      const parsedData = getSafely(['data'], parsedResponse);

      const roboid = getSafely(['portfolio', 'robocode'], parsedData, '');
      const newCategoryId = getSafely(['portfolio', 'id'], parsedData, '');

      await dispatch(saveRoboScoreUpdate({ score: newPortoScore, roboid }));

      if (history) {
        // set loading first
        dispatch(portfolioPageLoading());

        history.push({
          pathname: `/portfolio/${newCategoryId}/details`,
          state: {
            open_topup: true,
          },
        });
      }

      dispatch(createGoalLoading(false));

      dispatch(newGoalConfirmationVisible(false));

      dispatch(createGoalErrorReset());

      return;
    } catch (error) {
      dispatch(createGoalLoading(false));

      if (error.response) {
        const parsedResponse = handleResponseGeneral(error.response);

        if (parsedResponse) {
          // Open modal if it is not form error
          const errorModalObj = {
            type: parsedResponse?.type,
            message: parsedResponse?.message,
          };
          return dispatch(openErrorModal(errorModalObj));
        }
      }
    }
  };
}

/**
 * Create new goal based on given portfolio category id
 * @param {Object} goalData - Data needed for goal setting creation
 * @param {String} goalData.targetAmount - Goal setting creation target amount
 * @param {String} goalData.categoryId - Portfolio category id
 * @param {String} goalData.targetDate - Goal setting creation target date
 * @param {String} goalData.portfolioName - portfolio name
 */
export function createNewGoalByCategoryId(goalData) {
  return async (dispatch) => {
    try {
      dispatch(createGoalLoading(true));

      const previewData = {
        target_amount: goalData.targetAmount,
        target_date: goalData.targetDate,
      };

      if (goalData.targetDate) previewData.target_date = goalData.targetDate;

      const portfolioData = {
        portfolio_name: goalData.portfolioName,
        portfolio_template: 2,
      };

      await postPortfolioChangeIcon(goalData.categoryId, portfolioData);

      await postApplyGoalByCategoryId(goalData.categoryId, previewData);

      dispatch(createGoalLoading(false));
      dispatch(createPensionFundGoalVisible(false));
      dispatch(createGoalErrorReset());

      return;
    } catch (error) {
      dispatch(createGoalLoading(false));

      if (error.response) {
        const parsedResponse = handleResponseGeneral(error.response);

        if (getSafely(['errors'], parsedResponse)) {
          return dispatch(createNewGoalFormError(parsedResponse.errors));
        }

        if (parsedResponse) {
          // Open modal if it is not form error
          const errorModalObj = {
            type: parsedResponse?.type,
            message: parsedResponse?.message,
          };
          return dispatch(openErrorModal(errorModalObj));
        }
      }
    }
  };
}

/**
 *
 * @param {boolean} needApplyToGoalSetting
 * @param {Object} editGoal
 * @returns
 */
export function editGoal(needApplyToGoalSetting) {
  return async (dispatch, getState) => {
    try {
      const editedCategoryId = getSafely(
        ['goalSettings', 'editedCategoryId'],
        getState()
      );
      const newPortoTargetAmount = getSafely(
        ['goalSettings', 'newPortoTargetAmount'],
        getState()
      );
      const newPortoDateTarget = getSafely(
        ['goalSettings', 'newPortoDateTarget'],
        getState()
      );
      const newPortoName = getSafely(
        ['goalSettings', 'newPortoName'],
        getState()
      );
      const chosenTemplate = getSafely(
        ['goalSettings', 'chosenTemplate'],
        getState()
      );

      const targetDate = format(new Date(newPortoDateTarget), 'yyyy-MM-dd');

      dispatch(createGoalLoading(true));

      const editGoal = {
        portfolio_name: String(newPortoName),
        category_id: String(editedCategoryId),
        target_amount: String(newPortoTargetAmount),
        target_date: targetDate,
        portfolio_template: chosenTemplate,
      };

      const postDataUpdatePorto = !needApplyToGoalSetting
        ? postUpdateGoal(editGoal)
        : postApplyGoalByCategoryId(editedCategoryId, {
            target_amount: newPortoTargetAmount,
            target_date: targetDate,
          });

      if (needApplyToGoalSetting) {
        await postPortfolioChangeIcon(editedCategoryId, {
          portfolio_name: newPortoName,
          portfolio_template: chosenTemplate,
        });
      }

      // Update goal || Apply goal (when there is no goal setting)
      const fetchResponse = await postDataUpdatePorto;

      dispatch(createGoalLoading(false));
      const parsedResponse = handleResponseGeneral(fetchResponse);
      const parsedData = getSafely(['data'], parsedResponse);

      // invalidate contribution data for PortfolioTopCardShared
      queryClient.invalidateQueries([
        'Portfolio Detail',
        `${editedCategoryId}`,
      ]);
      queryClient.invalidateQueries([
        'Portfolio Ontrack',
        `${editedCategoryId}`,
      ]);
      queryClient.invalidateQueries([
        'Goal Detail Portfolio',
        `${editedCategoryId}`,
      ]);
      queryClient.invalidateQueries([
        'Portfolio Goal Progress Chart',
        `${editedCategoryId}`,
      ]);

      // Reset modal after edit is successful
      dispatch(goalSettingsReset());

      const roboid = getSafely(['portfolio', 'robocode'], parsedData, '');

      if (roboid)
        dispatch(setEditRoboScoreModalVisible({ roboid, visible: true }));

      // Fetch new portfolio detail data on complete
      dispatch(getPortfolioCategoriesDetailData(editedCategoryId));
      dispatch(getPortfolioGoalStatus(editedCategoryId));
      dispatch(getPortfolioGoalDetail(editedCategoryId));
      dispatch(getGoalProgressChart(editedCategoryId));

      return;
    } catch (error) {
      dispatch(createGoalLoading(false));
      if (error.response) {
        const parsedResponse = handleResponseGeneral(error.response);
        if (getSafely(['errors'], parsedResponse)) {
          return dispatch(createNewGoalFormError(parsedResponse.errors));
        }

        if (parsedResponse) {
          // Open modal if it is not form error
          const errorModalObj = {
            type: parsedResponse?.type,
            message: parsedResponse?.message,
          };
          return dispatch(openErrorModal(errorModalObj));
        }
      }
    }
  };
}

export function editPensionAge(value) {
  return (dispatch) => {
    dispatch(pensionAge(value));
  };
}

export function deleteGoal(history, categoryId, postDeleteOption, callback) {
  return async (dispatch, getState) => {
    try {
      let editedCategoryId = categoryId;

      const deleteGoalLoading = getSafely(
        ['goalSettings', 'deleteGoalLoading'],
        getState()
      );
      if (deleteGoalLoading) {
        return;
      }

      dispatch(deleteGoalIsLoading(true));

      // If categoryId param not passed, look inside goalSettings modal state
      if (!categoryId) {
        editedCategoryId = getSafely(
          ['goalSettings', 'editedCategoryId'],
          getState(),
          categoryId
        );
      }

      const editGoalData = {
        categoryid: editedCategoryId,
        ...postDeleteOption,
      };

      const deleteResponse = await postPortfolioCategoryDelete(editGoalData);

      // Refetch porto after delete successfull
      queryClient.invalidateQueries(['Portfolio List']);

      // Reset modal after edit is successful
      dispatch(goalSettingsReset());

      dispatch(deleteConfirmationModalVisible(false));
      dispatch(deleteGoalIsLoading(false));

      // Fetch new portfolio list on complete
      dispatch(getPortfolioCategoryData());

      // Fetch new robo recommendations
      dispatch(getRoboRecommendations());

      if (history) {
        history.goBack();
      }

      callback();

      return deleteResponse;
    } catch (error) {
      dispatch(deleteConfirmationModalVisible(false));
      dispatch(deleteGoalIsLoading(false));
      if (error.response) {
        const parsedResponse = handleResponseGeneral(error.response);
        // Open modal if it is not form error
        const errorModalObj = {
          type: parsedResponse?.type,
          message: parsedResponse?.message,
        };
        return dispatch(openErrorModal(errorModalObj));
      }
    }
  };
}

// If pension fund is open through portfolio
// TENTUKAN GOAL DANA PENSIUN
export function openPensionFundEdit(
  isPensionFund,
  goalSettingInfo,
  profileDetail
) {
  const birthDate = profileDetail?.user?.birth_date ?? new Date();

  return (dispatch) => {
    // Default goal target value
    const dateTargetValue =
      goalSettingInfo && goalSettingInfo.targetdate
        ? new Date(goalSettingInfo.targetdate)
        : defaultState.newPortoDateTarget;

    // Default goal target value for Dana Pension (default)
    const pensionDateDefaultValue =
      goalSettingInfo && goalSettingInfo.targetdate
        ? new Date(goalSettingInfo.targetdate)
        : new Date(addYears(new Date(birthDate), 65));

    // Determined which date to use
    const dateTargetFinal = isPensionFund
      ? pensionDateDefaultValue
      : dateTargetValue;

    dispatch(newGoalTypePortoDate(dateTargetFinal));
  };
}

export default goalSettings;
