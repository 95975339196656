import { ConfigRetry } from './type';

/**
 * Wait to the next line of code
 *
 * @param {number} duration
 */
const waitUntil = (duration: number = 0) => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

/**
 * predefine config of `retry`
 */
const defaultConfig: ConfigRetry = {
  maxRetry: 3,
  delay: 0,
  expectResult: null,
  forceReturn: false,
};

/**
 * Helper function that Retry some Function
 *
 */
const retry = async <RETURN_TYPE>(
  fn: () => Promise<RETURN_TYPE>,
  config?: ConfigRetry<RETURN_TYPE>
): Promise<RETURN_TYPE> => {
  // preparing default config
  const { delay, maxRetry, expectResult, forceReturn } = {
    ...defaultConfig,
    ...config,
  };

  /** tell us is able to retry function or not */
  const ableToRetry = (maxRetry ?? 0) > 1;

  /** handling retry function */
  const retryFunc = async () => {
    await waitUntil(delay);

    // re call `retry` and decrease maxRetry config
    return await retry(fn, {
      delay,
      maxRetry: maxRetry! - 1,
      expectResult,
      forceReturn,
    });
  };

  try {
    const result = await fn();
    const done = expectResult ? expectResult(result) : true;

    // `fn` already meet expectation
    if (done) {
      // just return the actual result
      return result;
    }

    if (ableToRetry) {
      return await retryFunc();
    }

    if (forceReturn) {
      return result;
    }

    // if code reach this line mean `maxRetry` is exceed so we just throw error
    throw new Error('to_many_try');
  } catch (error) {
    const err = error as any;

    const errorToManyTry = err?.message === 'to_many_try';

    // if not `errorToManyTry` and able to retry
    if (!errorToManyTry && ableToRetry) {
      // then we recall function
      return await retryFunc();
    }

    throw err;
  }
};

export default retry;
