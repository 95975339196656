import React, { FC, PropsWithChildren } from 'react';

interface Props {
  /** IF false the children wont be rendered */
  when: boolean;
}

/**
 * Conditional render component
 */
const Show: FC<PropsWithChildren<Props>> = ({ children, when }) => {
  if (!when) return null;

  // Actually we need `fragment` :9
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default Show;
