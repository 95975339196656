import LazyModal from 'features/common/LazyModal';
import React from 'react';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

const EmergencyFundOptionModalLazy = React.lazy(
  () => import('./EmergencyFundOptionModal')
);

const EmergencyFundOptionModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'emergency_option';

  return (
    <LazyModal component={EmergencyFundOptionModalLazy} visible={visible} />
  );
};

export default EmergencyFundOptionModal;
