import Storage from 'core/Storage';

/**
 * @deprecated tolong jangan pake ini lagi, cukup pake get javascript biasa
 */
const _getDataResponse = (resp: any) => {
  if (resp && resp.data) return resp.data;
  return null;
};

/**
 * @deprecated Gw ga recommend ini dipake lagi karena sepertinya harusnya step ini ditulis
 * per fitur
 */
export function handleResponseRobo(payload: any) {
  const resp = _getDataResponse(payload);
  if (!resp) return null;

  const { data } = _getDataResponse(payload);

  const { roboid } = data;

  /* set roboid to localstorage */
  Storage.setObject('rb', roboid);

  return {
    ...data,
  };
}

/**
 * @deprecated just dont use it AGAIN ;)
 */
function _getFormErrors(payload: any) {
  if (payload?.errors) return payload.errors;
  return null;
}

/**
 * @deprecated tolong jangan ada yang pake ini lagi, agak salah patternnya
 * karena telat ngebind catch. catch() harus dibind dari awal untuk mencegah
 * `UncaughtException`
 */
export function handleResponseErrorCase(p: any, cb: any) {
  return p.catch((error: any) => {
    // Error
    if (error && error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { data, status } = error.response;
      const message = data?.message || 'Something is wrong';
      const err_form = _getFormErrors(data);
      return cb({ loading: false, err: message, raw: data, err_form, status });
    } else if (error && error.data && error.status) {
      const { message: _message } = error.data;
      const message = _message ? _message : 'Something is wrong';
      const err_form = _getFormErrors(error.data);
      return cb({
        loading: false,
        err: message,
        raw: error.data,
        err_form,
        status: error.status,
      });
    } else if (error && error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return cb({
        loading: false,
        err_form: {},
        raw: { type: 'no_response' },
        err: 'Koneksi kamu sedang tidak stabil',
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      return cb({
        loading: false,
        err: error && error.message ? error.message : 'Something went wrong',
        err_form: {},
        raw: { type: 'something_went_wrong' },
      });
    }
  });
}

/**
 * @deprecated - please use standard JS for accessing data from response API.
 *
 * General response handler from API
 */
export function handleResponseGeneral(payload: any) {
  const resp = _getDataResponse(payload);
  if (!resp) return null;
  return {
    ...resp,
  };
}
