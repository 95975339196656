import http from 'core/http';
import { getRefreshId } from './utils';
import storage from 'core/Storage';
import type { RestAPICore } from 'core/http/http.type';
import type { RefreshTokenData } from './refresh-token.type';

/**
 * request for get access token from Core API using `refresh_token` in the body
 */
export const postRefreshToken = async () => {
  // - get device info
  // - refreshId
  // - and refresh token
  const [refreshId, refreshToken] = await Promise.all([
    getRefreshId(),
    storage.get('rftoken'),
  ]);

  if (!refreshToken) {
    throw new Error('no refresh token.');
  }

  // perform request
  return http.post<RestAPICore<RefreshTokenData>>('/auth/token', {
    refresh_token: refreshToken,
    refresh_id: refreshId,
  });
};
