import getSafely from './safely';

/**
 *
 * @param reksadana reksadana instance
 * @description check if a reksadana instance is new or not based on simplereturn and created date
 */
export const isNew = (reksadana: any) => {
  if (!reksadana) return false;
  if (!!!reksadana?.simplereturn) return true;
  return (
    reksadana?.simplereturn?.['1d'] === 0 &&
    (!!!reksadana?.simplereturn?.['1m'] ||
      reksadana?.simplereturn?.['1m'] === 0)
  );
};

/**
 * Check if data had adjusted parameter or not
 *
 * if data had parameter adjusted return the adjusted value by the period of the type
 * @param {*} fundData
 * @param {string} type
 * @param {string} period
 * @returns {number | undefined}
 */
export function hasFundDataAdjusted(
  fundData: any,
  type: string,
  period: string | number
) {
  if (
    fundData?.is_has_dividend &&
    getSafely([`${type}_adjusted`, period], fundData, undefined)
  ) {
    return getSafely([`${type}_adjusted`, period], fundData, undefined);
  }
  return getSafely([type, period], fundData, undefined);
}
