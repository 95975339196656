import axios from 'axios';
import http from 'core/http';
import api from './network';
import type {
  UploadConfigS3,
  ResponseFileUpload,
  UnauthorizedToken,
  ResponseTriggerOCR,
} from './s3.type.d';
import endpoints from 'features/institution/utils/s3/networks';
import { getCurrentInstitution } from 'features/institution/utils';

/**
 * Helper upload object to s3
 *
 * @param file - Object File that we want to upload
 * @param config
 *
 * @see {@link https://www.notion.so/stockbit/Bucket-upload-download-and-trigger-OCR-444d389c4ba24f08a86ef8578fe263a1}
 *
 */
export const uploadToS3 = async (
  file: File,
  config: UploadConfigS3,
  unauthorized?: UnauthorizedToken
) => {
  try {
    const queryParam = {
      auth_type: unauthorized?.auth_type ?? 1,
      ...(unauthorized?.verification_token
        ? { verification_token: unauthorized.verification_token }
        : {}),
    };

    const institution = getCurrentInstitution();
    const isInstitution = !!institution;

    // if it's institution account, then use institution endpoint
    const uploadURL = isInstitution
      ? endpoints.institutionUploadRequestToS3
      : api.signUploadS3;

    // @ Request S3 Upload-signed URL to Backend
    const uploadedFile = await http.post<ResponseFileUpload>(
      uploadURL,
      {
        type: config.uploadType,
        name: config.fileName,
        content_length: file.size,
      },
      queryParam
    );

    const data = uploadedFile.data.data;

    // @ PUT to generated signed URL s3
    await axios.put(data.signed_url, file, {
      headers: {
        'Content-Type': data.content_type,
      },
    });

    return uploadedFile;
  } catch (error) {
    /**
     * Possible Error types:
     *
     * `error?.response?.data?.type`
     *
     *  - unauthorized: Request actor tidak dikenali
     *  - file_type_not_found: Tipe file tidak valid
     *  - content_type_not_valid: Content type tidak valid
     *  - file_size_exceeds: Ukuran file melebihi batas maksimal
     *  - generating_url_failed: Gagal generate upload URL
     *
     *  @info also see possible error from {@link downloadFileS3}
     *
     */
    throw error;
  }
};

/**
 * Trigger Optical Character Recognition (OCR) for a certain image type (ktp/identity)
 *
 * @param {string} fileURL
 */
export const triggerOCR = async (fileUrl: string) => {
  try {
    const response = await http.post<ResponseTriggerOCR>(api.triggerOCR, {
      file_url: fileUrl,
    });

    return response;
  } catch (error) {
    /**
     * Possible Error types:
     *
     *  - trigger_ocr_failed: Gagal lakukan permintaan OCR karena kepemilikan file tidak valid atau tipe file bukan KTP
     */
    throw error;
  }
};
