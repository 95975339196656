import React, { ReactNode } from 'react';
import { ButtonNew } from '@bibitid/uikit-v1';
import styled from 'styled-components';

const ModalNeutralButtonWrapper = styled(ButtonNew)`
  border: 1px solid var(--modal-border);
`;

export interface ModalNeutralButtonProps {
  className?: string;
  content?: ReactNode;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  variant?: 'neutral';
  colorScheme?: 'green' | 'red';
  full?: boolean;
  inline?: boolean;
  loading?: boolean;
  rounded?: boolean;
  testId?: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler;
}
/**
 * Indicate the loading state of a component or page and Their appearance, alignment, and sizing can be easily customized with ClassName props classes.
 */
const ModalNeutralButton: React.FC<
  React.PropsWithChildren<ModalNeutralButtonProps>
> = ({
  className = '',
  content = '',
  variant = 'neutral',
  colorScheme = 'green',
  full = true,
  inline = false,
  loading = false,
  disabled = false,
  testId = 'modal-neutral-btn-test',
  rounded = false,
  onClick,
}) => {
  return (
    <ModalNeutralButtonWrapper
      className={className}
      content={content}
      variant={variant}
      colorScheme={colorScheme}
      full={full}
      inline={inline}
      loading={loading}
      disabled={disabled}
      testId={testId}
      rounded={rounded}
      onClick={onClick}
    />
  );
};

export default ModalNeutralButton;
