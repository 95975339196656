import LogoMacan from 'assets/images/investor-type/IconInvestorAgresifMacan.svg';
import LogoKelinci from 'assets/images/investor-type/IconInvestorKonservatifKelinci.svg';
import LogoRusa from 'assets/images/investor-type/IconInvestorModeratRusa.svg';
import LogoUnset from 'assets/images/investor-type/IconInvestorUnset.svg';

import LogoMacanDark from 'assets/images/investor-type/dark/IconInvestorAgresifMacan.svg';
import LogoKelinciDark from 'assets/images/investor-type/dark/IconInvestorKonservatifKelinci.svg';
import LogoRusaDark from 'assets/images/investor-type/dark/IconInvestorModeratRusa.svg';
import LogoUnsetDark from 'assets/images/investor-type/dark/IconInvestorUnset.svg';

import LogoMacanModal from 'assets/images/investor-type/modal/light/IconInvestorAgresifMacan.svg';
import LogoKelinciModal from 'assets/images/investor-type/modal/light/IconInvestorKonservatifKelinci.svg';
import LogoRusaModal from 'assets/images/investor-type/modal/light/IconInvestorModeratRusa.svg';
import LogoUnsetModal from 'assets/images/investor-type/modal/light/IconInvestorUnset.svg';

import LogoMacanDarkModal from 'assets/images/investor-type/modal/dark/IconInvestorAgresifMacan.svg';
import LogoKelinciDarkModal from 'assets/images/investor-type/modal/dark/IconInvestorKonservatifKelinci.svg';
import LogoRusaDarkModal from 'assets/images/investor-type/modal/dark/IconInvestorModeratRusa.svg';
import LogoUnsetDarkModal from 'assets/images/investor-type/modal/dark/IconInvestorUnset.svg';

import { useFetchRoboRecommendation } from 'features/robo/hook/roboHook';
import { useAuth, useProfileData, useTheme } from 'hooks';

/**
 * Custom hook to use profile risk data
 */
const useProfileRisk = () => {
  const { isLogin } = useAuth();
  const { data: profileData } = useProfileData(isLogin);

  const { theme } = useTheme();

  const profileDetail = profileData?.data?.data;

  const fullname = profileDetail?.user?.fullname;

  const roboid = profileDetail?.roboid;

  const fetchRoboRecommendationEnabled = !!roboid && !!fullname;

  const {
    data: roboCheckData,
    isLoading: roboRecommendationLoading,
    isFetching: roboRecommendationFetching,
  } = useFetchRoboRecommendation({ roboid }, fetchRoboRecommendationEnabled);

  const roboCheckDetail = roboCheckData?.data?.data;

  const score: number = roboCheckDetail?.result?.score;
  const noProfileRisk = typeof score === 'undefined';

  const getInvestorCategoryImage = (): string => {
    if (noProfileRisk) {
      return theme === 'dark' ? LogoUnsetDark : LogoUnset;
    }
    if (score > 4 && score <= 7) {
      return theme === 'dark' ? LogoRusaDark : LogoRusa;
    }
    if (score <= 4) {
      return theme === 'dark' ? LogoKelinciDark : LogoKelinci;
    }

    return theme === 'dark' ? LogoMacanDark : LogoMacan;
  };

  const getInvestorCategoryImageModal = (): string => {
    if (noProfileRisk) {
      return theme === 'dark' ? LogoUnsetDarkModal : LogoUnsetModal;
    }
    if (score > 4 && score <= 7) {
      return theme === 'dark' ? LogoRusaDarkModal : LogoRusaModal;
    }
    if (score <= 4) {
      return theme === 'dark' ? LogoKelinciDarkModal : LogoKelinciModal;
    }

    return theme === 'dark' ? LogoMacanDarkModal : LogoMacanModal;
  };

  const getInvestorCategoryText = (): string => {
    if (noProfileRisk) {
      return 'Isi Data Profil Risiko Kamu';
    }
    if (score > 4 && score <= 7) {
      return 'Investor Moderat';
    }
    if (score <= 4) {
      return 'Investor Konservatif';
    }
    return 'Investor Agresif';
  };

  return {
    getInvestorCategoryText,
    getInvestorCategoryImage,
    getInvestorCategoryImageModal,
    roboRecommendationLoading,
    roboRecommendationFetching,
    fullname,
    score,
    noProfileRisk,
  };
};

export default useProfileRisk;
