import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';

/**
 * hook helper to get referral data
 */
const useShariaProfile = () => {
  const { isLogin } = useAuth();
  const { data } = useProfileData(isLogin);
  const dataProfile = data?.data.data;

  const isShariaPreferenceActive: boolean =
    dataProfile?.preference?.syariah === 1 || false;

  return {
    isShariaPreferenceActive,
  };
};

export default useShariaProfile;
