import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import useAuth from 'hooks/useAuth';

import {
  IProfilingGlobalState,
  IProfilingGlobalFunction,
} from './RoboProfilingGlobal';
import { useProfileData } from 'hooks';

const RoboProfilingGlobalState = createContext<
  IProfilingGlobalState | undefined
>(undefined);

const RoboProfilingGlobalFunction = createContext<
  IProfilingGlobalFunction | undefined
>(undefined);

export const RoboProfilingGlobalProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [isConfettiVisible, setConfettiVisible] = useState<boolean>(true);
  const [isProfilingFinished, setProfilingFinished] = useState<boolean>(true);
  const [roboTemp, setRoboTemp] = useState<string | undefined>();
  const { isLogin: isLoggedIn } = useAuth();
  const { data: dataUser } = useProfileData(isLoggedIn);

  const handleConfettiVisible = (val: boolean) => {
    return setConfettiVisible(val);
  };

  const handleProfilingFinished = () => {
    return setProfilingFinished(true);
  };

  const contextState = { isConfettiVisible, isProfilingFinished, roboTemp };
  const contextFunction = {
    handleConfettiVisible,
    handleProfilingFinished,
    setRoboTemp,
  };

  /**
   * Handle profiling after register when input robo question not finished yet
   */
  const handleProfilingState = useCallback(async () => {
    const roboId = dataUser?.data?.data?.roboid;

    /* Determine user has finish profiling
     * if roboid undefined we sure the user not finish profiling
     */
    setProfilingFinished(!!roboId);
  }, [dataUser]);

  useEffect(() => {
    handleProfilingState();
  }, [handleProfilingState]);

  return (
    <RoboProfilingGlobalState.Provider value={contextState}>
      <RoboProfilingGlobalFunction.Provider value={contextFunction}>
        {children}
      </RoboProfilingGlobalFunction.Provider>
    </RoboProfilingGlobalState.Provider>
  );
};

export const useRoboProfilingGlobalState = () => {
  const context = useContext(RoboProfilingGlobalState);

  if (context === undefined) {
    throw new Error(
      'useRoboProfilingGlobal must be used within a RoboProfilingGlobalProvider'
    );
  }

  return context;
};

export const useRoboProfilingGlobalFunction = () => {
  const context = useContext(RoboProfilingGlobalFunction);

  if (context === undefined) {
    throw new Error(
      'useRoboProfilingGlobalFunction must be used within a RoboProfilingGlobalProvider'
    );
  }

  return context;
};
