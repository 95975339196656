import jwtDecode from 'jwt-decode';

/**
 * README!
 *
 * please use only boolean result validator
 */

/**
 *
 * @param {string} str
 * @returns {boolean}
 */
export function isJSON(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Checking isWebview with no Promise based on window.isWebview || window.document.isWebview
 */
export const isInsideNativeContainer = () =>
  window.isWebview || window.document.isWebview || false;

/**
 * Check if the string is a normal integer
 * @param {string} str - The string you want to check
 */
export const stringContainsOnlyDigits = (str: string) => /^[0-9]*$/.test(str);

export default Object.assign(
  {},
  {
    isJSON,
    stringContainsOnlyDigits,
  }
);

/**
 * General email validation.
 *
 * Rules:
 *  - Using alphabet or numeric in name.
 *  - Special characters in between the name is permitted (e.g user_name@gmail.com).
 *  - Number only characters before @ is permitted (e.g 1@gmail.com)
 *  - No underscore `_`, hyphen `-`, or dot `.` right before `@` (e.g user_@gmail.com).
 *
 */
export function validateEmail(email: string) {
  let testedEmail = String(email).toLowerCase();

  // general validation (to prevent unknown error e.g when users input their home address instead)
  let regex1 =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex1.test(testedEmail)) return false;

  // advanced validation
  let regex2 =
    /^(?!.+[.]{2})(?!.+[-]{2})(?:[A-Za-z0-9][._-]{0,})*[A-Za-z0-9]@(?:[A-Za-z0-9-]\.?)+\.[a-z]{2,6}$/;

  return regex2.test(testedEmail);
}

/**
 * Check if the date is a valid date
 * @param {string} date - The date you want to check
 * @returns boolean
 */
export function isValidDate(date: string) {
  const splittedDate = date.split('-');
  if (Array.isArray(splittedDate) && splittedDate.length === 3) {
    const d = new Date(date);
    let [year, month, day] = splittedDate;
    // need to reduce month value by 1 to accommodate new Date formats the month
    let newMonth = Number(month);
    --newMonth;
    if (
      d.getFullYear() === Number(year) &&
      d.getMonth() === Number(newMonth) &&
      d.getDate() === Number(day)
    ) {
      return true;
    }
    return false;
  }
  return false;
}

/**
 * Check is `string` valid url
 *
 * @param {string} url
 * @returns {boolean}
 *
 */
export const isValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * check JWT is valid
 *
 * @param {string} token
 * @returns
 */
export const jwtIsValid = (token: string) => {
  try {
    const jwt = jwtDecode(token);
    return !!jwt;
  } catch {
    return false;
  }
};
