import Storage from 'core/Storage';

export const checkIsAfterPinLogin = async () => {
  try {
    const isAfterPinLogin = await Storage.get('isAfterPinLogin');
    if (!isAfterPinLogin) return false;
    return true;
  } catch (error) {
    return false;
  }
};

export const checkIsNeedPinAfterLogin = async () => {
  try {
    const isNeedPinAfterLogin = await Storage.get('needPinAfterLogin');
    if (!isNeedPinAfterLogin) return false;
    return true;
  } catch (error) {
    return false;
  }
};

export const isErrorPinBlocked = (err: any) => {
  const { type } = err || {};
  const isErrorPinBlocked = type === 'pin_blocked';
  return isErrorPinBlocked;
};
