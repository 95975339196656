import React from 'react';

export interface CountDownReSentStateType {
  timer: number;
  setTimer: (timer: number) => void;
}

export const CountDownReSentStateContext =
  React.createContext<CountDownReSentStateType>({
    timer: 0,
    setTimer: () => {},
  });

const CountDownReSentContext: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [timer, setTimer] = React.useState(0);

  return (
    <CountDownReSentStateContext.Provider value={{ timer: timer, setTimer }}>
      {children}
    </CountDownReSentStateContext.Provider>
  );
};

export default CountDownReSentContext;
