import React from 'react';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import getSafely from 'utils/safely';
import classNames from 'classnames';
import { ReksaDana } from 'entities/reksadana.reducer';

interface CardProps {
  fundData: ReksaDana;
  isComparing?: boolean;
  selected?: boolean;
  onClick: (roboCategory: string, symbol: string) => void;
}

const ReksaDanaExpenseRatioCard: React.FC<
  React.PropsWithChildren<CardProps>
> = ({ fundData, isComparing, selected, onClick }) => {
  const { name, type, expenseratio, investment_manager } = fundData;

  const _expenseratio: number = getSafely(['percentage'], expenseratio, 0) || 0;
  const expenseratioText =
    _expenseratio === 0 ? '-' : Number(_expenseratio * 100).toFixed(2) + '%';

  const handleClick = () => {
    const { robocategory, symbol } = fundData;
    onClick(robocategory, symbol);
  };

  return (
    <div
      className={classNames({
        'bit-filter-result-row': true,
        'bit-filter-expanse': true,
        'card-selected': selected,
        'card-reksa-shake': isComparing,
      })}
    >
      <div onClick={handleClick}>
        <div className='bit-filter-reksa'>
          <div className='bit-filter-reksa-pic'>
            <InvestmentCompanyLogo
              type='investment-manager'
              size='square'
              ojkCode={getSafely(['ojkCode'], investment_manager, '')}
            />
          </div>
          <div>
            <h3>{name}</h3>
            <p>{type}</p>
          </div>
        </div>
        <div
          className={classNames('bit-filter-right-result no-min-width', {
            null_result: _expenseratio === 0,
          })}
        >
          <p>{expenseratioText}</p>
        </div>
      </div>
    </div>
  );
};

export default ReksaDanaExpenseRatioCard;
