import * as Parent from 'core/Parent';
import { isInsideNativeContainer } from 'utils/validator';
import { PREMIUM_STATUS_BAR_COLOR } from '../constants';
import { StatusBarApp, StatusBarColor } from '../types';
import { randomString } from 'utils/stringHelper';

/**
 * Get data for handle old version app and new version app for status bar color
 *
 * - old version: { color: 'light' or 'emerald' }
 * - new version: { backgroundColor: '#codehex', foregroundColor: '#codehex' }
 */
const dataAppStatusBarColor = (color: StatusBarApp) => {
  const { backgroundColor, foregroundColor } = color;

  /**
   * Color post data will be used on old version app for feature premium.
   * We used default color `light` for handling old version app,
   * because in new version app we used dynamic color using code hex
   */
  const colorPostData: StatusBarColor =
    backgroundColor === PREMIUM_STATUS_BAR_COLOR.backgroundColor
      ? 'emerald'
      : 'light';

  return {
    color: colorPostData,
    backgroundColor,
    foregroundColor,
  };
};

/** Set app status bar color using post message `setAppStatusBarColor` */
export const setAppStatusBarColor = (color: StatusBarApp) => {
  const isNative = isInsideNativeContainer();

  if (!isNative) {
    return;
  }

  const { backgroundColor, foregroundColor } = color;

  const genId = randomString(5);

  Parent.postData(
    `setAppStatusBarColor_${genId}`,
    {
      fn: 'setAppStatusBarColor',
      data: dataAppStatusBarColor({
        backgroundColor,
        foregroundColor,
      }),
      timeout: 0,
    },
    (err, data) => {}
  );
};
