import styled from 'styled-components';
import { Text } from '@bibitid/uikit';

export const Container = styled.div`
  text-align: center;
`;

export const BibitPlusImage = styled.div`
  margin: 0 auto 20px;
`;

export const BibitPlusDescription = styled(Text)`
  margin-top: 10px;
  color: var(--text-primary);
`;
