import { AxiosResponse } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { getDetailUserProfile } from 'services/user';
import Storage from 'core/Storage';
import {
  UpdateProfileDataContent,
  ProfileDataResponse,
} from 'features/profile/types';
import { useCallback } from 'react';

interface OptionsTypes {
  [key: string]: any;
}

export const GET_PROFILE_QUERY_KEY = 'getDetailUserProfile';

export const useProfileData = (isLogin: boolean, opts: OptionsTypes = {}) => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isFetching,
    isFetched,
    isLoading,
    refetch,
    isRefetchError,
  } = useQuery(GET_PROFILE_QUERY_KEY, () => getDetailUserProfile(), {
    enabled: isLogin || false,
    staleTime: Infinity,
    ...opts,
  });

  const updateProfileDataCache = useCallback(
    (newData: UpdateProfileDataContent, shouldRefetchProfile = true) => {
      // update cache data profile to the new value
      queryClient.setQueryData<AxiosResponse<ProfileDataResponse> | undefined>(
        GET_PROFILE_QUERY_KEY,
        (oldData) => {
          if (!oldData) {
            return data;
          }

          const updatedData = {
            ...oldData,
            data: {
              ...oldData.data,
              data: {
                ...oldData.data.data,
                ...newData,
                config: {
                  ...oldData.data.data?.config,
                  ...newData?.config,
                },
                preference: {
                  ...oldData.data.data?.preference,
                  ...newData?.preference,
                },
                restriction: {
                  ...oldData.data.data?.restriction,
                  ...newData?.restriction,
                },
                sinvest: {
                  ...oldData.data.data?.sinvest,
                  ...newData?.sinvest,
                },
                user: {
                  ...oldData.data.data?.user,
                  ...newData?.user,
                },
                institutions: {
                  ...oldData.data.data?.institutions,
                  ...newData?.institutions,
                },
              },
            },
          };

          return updatedData;
        }
      );

      // refetch data profile
      if (shouldRefetchProfile) {
        refetch();
      }
    },
    [data, queryClient, refetch]
  );

  const pullRefreshInitialUser = () => {
    if (isLogin) {
      refetch().then((res) => {
        const data = res?.data?.data || {};
        const { roboid } = data;

        /**
         * Handle Robo
         */
        if (roboid) {
          Storage.setObject('rb', roboid);
        } else {
          Storage.setObject('rb', '');
        }
      });
    }
  };

  return {
    data: data as AxiosResponse<ProfileDataResponse>,
    error,
    isLoading,
    isFetching,
    isFetched,
    refetch,
    pullRefreshInitialUser,
    isRefetchError,
    updateProfileDataCache,
  };
};

export default useProfileData;
