import { getEnv } from 'core/env';
import {
  numberToGeneralValueFormat,
  nominalNumberFormatter,
} from 'utils/stringHelper';

const {
  JagoCashbackAmount,
  JagoCashbackAmountSyariah,
  JagoCashbackBannerPeriod,
  JagoCashbackBlogUrl,
  JagoCashbackBlogUrlSyariah,
  JagoReferralAmount,
  JagoReferralPeriod,
  JagoCampaignBannerPeriod,
  JagoCashbackReferralBlogUrl,
  JagoRdnCashbackAmount,
  JagoRdnCashbackBannerPeriod,
} = getEnv();

export const JAGO_CASHBACK_AMOUNT = numberToGeneralValueFormat(
  Number(JagoCashbackAmount || '0')
);

export const JAGO_CASHBACK_AMOUNT_SHORTEN = nominalNumberFormatter(
  Number(JagoCashbackAmount || '0'),
  false,
  true
);

export const JAGO_CASHBACK_AMOUNT_SHORTEN_EN = nominalNumberFormatter(
  Number(JagoCashbackAmount || '0'),
  false,
  false
);

export const JAGO_CASHBACK_AMOUNT_SHORTEN_RB = nominalNumberFormatter(
  Number(JagoCashbackAmount || '0'),
  false,
  true
);

export const JAGO_CASHBACK_AMOUNT_SYARIAH = numberToGeneralValueFormat(
  Number(JagoCashbackAmountSyariah || '0')
);

export const JAGO_CASHBACK_AMOUNT_SYARIAH_SHORTEN = nominalNumberFormatter(
  Number(JagoCashbackAmountSyariah || '0'),
  false,
  true
);

export const JAGO_CASHBACK_AMOUNT_SYARIAH_SHORTEN_EN = nominalNumberFormatter(
  Number(JagoCashbackAmountSyariah || '0'),
  false,
  false
);

export const JAGO_CASHBACK_AMOUNT_SYARIAH_SHORTEN_RB = nominalNumberFormatter(
  Number(JagoCashbackAmountSyariah || '0'),
  false,
  true
);

export const JAGO_REFERRAL_AMOUNT = numberToGeneralValueFormat(
  Number(JagoReferralAmount || '0')
);

export const JAGO_CASHBACK_BANNER_PERIODE = JagoCashbackBannerPeriod || '';

/**
 * start date jago cashback periode
 */
export const JAGO_CASHBACK_START = JagoCashbackBannerPeriod
  ? (JagoCashbackBannerPeriod.split(',')[0] || '').split('-').join(' ')
  : '';

/**
 * end date jago cashback periode
 */
export const JAGO_CASHBACK_END = JagoCashbackBannerPeriod
  ? (JagoCashbackBannerPeriod.split(',')[1] || '').split('-').join(' ')
  : '';

/** Blog url of Jago Cashback in registration page, currently used in '/jago-tnc' page */
export const JAGO_CASHBACK_BLOG_URL =
  JagoCashbackBlogUrl || 'https://blog.bibit.id/';

/** Blog url of Jago Syariah Cashback in registration page, currently used in '/jago-tnc' page */
export const JAGO_CASHBACK_BLOG_URL_SYARIAH =
  JagoCashbackBlogUrlSyariah || 'https://blog.bibit.id/';

/** Blog url of Jago cashback referral, currently used in '/referral' page  */
export const JAGO_CASHBACK_REFERRAL_BLOG_URL =
  JagoCashbackReferralBlogUrl || 'https://blog.bibit.id/';

/**
 * start date of campaign periode.
 */
export const JAGO_CAMPAIGN_START = JagoCampaignBannerPeriod
  ? (JagoCampaignBannerPeriod.split(',')[0] || '').split('-').join(' ')
  : '';

/**
 * start date of campaign periode.
 */
export const JAGO_CAMPAIGN_END = JagoCampaignBannerPeriod
  ? (JagoCampaignBannerPeriod.split(',')[1] || '').split('-').join(' ')
  : '';

/**
 * start date jago referral periode
 */
export const JAGO_REFERRAL_START = JagoReferralPeriod
  ? (JagoReferralPeriod.split(',')[0] || '').split('-').join(' ')
  : '';

/**
 * end date jago referral periode
 */
export const JAGO_REFERRAL_END = JagoReferralPeriod
  ? (JagoReferralPeriod.split(',')[1] || '').split('-').join(' ')
  : '';

export const JAGO_RDN_CASHBACK_AMOUNT = numberToGeneralValueFormat(
  Number(JagoRdnCashbackAmount || '0')
);

/**
 * start date of jago RDN cashback periode
 */
export const JAGO_RDN_CASHBACK_BANNER_START = JagoRdnCashbackBannerPeriod
  ? (JagoRdnCashbackBannerPeriod.split(',')[0] || '').split('-').join(' ')
  : '';

/**
 * end date of jago RDN cashback periode.
 */
export const JAGO_RDN_CASHBACK_BANNER_END = JagoRdnCashbackBannerPeriod
  ? (JagoRdnCashbackBannerPeriod.split(',')[1] || '').split('-').join(' ')
  : '';

/**
 * Jago activation error modal content
 */
const JAGO_ERROR_ACTIVATION_DEFAULT_TITLE = 'Belum Bisa Mengaktifkan Bank Jago';
// Error type registered
export const JAGO_ERROR_ACTIVATION_REGISTERED_TITLE =
  'Belum Bisa Buka Akun Bank Jago';
export const JAGO_ERROR_ACTIVATION_REGISTERED_DESC =
  'Untuk saat ini kamu belum bisa membuka akun Bank Jago dari Bibit, untuk selengkapnya silakan hubungi tim support Bibit. Hubungi Support';
// Error type overseas
export const JAGO_ERROR_ACTIVATION_OVERSEAS_TITLE =
  JAGO_ERROR_ACTIVATION_DEFAULT_TITLE;
export const JAGO_ERROR_ACTIVATION_OVERSEAS_DESC =
  'Kamu harus menggunakan nomor handphone Indonesia (+62) untuk aktivasi Bank Jago.';
// Error type foreign
export const JAGO_ERROR_ACTIVATION_FOREIGN_TITLE =
  JAGO_ERROR_ACTIVATION_DEFAULT_TITLE;
export const JAGO_ERROR_ACTIVATION_FOREIGN_DESC =
  'Saat ini aktivasi Bank Jago hanya dapat dilakukan oleh warga negara Indonesia yang sudah memiliki KTP.';
// Error type inactive
export const JAGO_ERROR_ACTIVATION_INACTIVE_TITLE =
  JAGO_ERROR_ACTIVATION_DEFAULT_TITLE;
export const JAGO_ERROR_ACTIVATION_INACTIVE_DESC =
  'Hubungi Tanya Jago di aplikasi Bank Jago untuk mengaktifkan akun.';
// Error type verification
export const JAGO_ERROR_ACTIVATION_VERIFICATION_TITLE =
  'Akun Kamu Dalam Verifikasi Bank Jago';
export const JAGO_ERROR_ACTIVATION_VERIFICATION_DESC =
  'Silakan hubungkan kembali setelah akun rekening Bank Jago kamu terverifikasi.';
