import styled, { css } from 'styled-components';
import { IconWrapperStyleProps } from './IconWrapper';

export const Wrapper = styled.div<IconWrapperStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $size }) =>
    $size &&
    css`
      width: ${$size};
      min-width: ${$size};
      height: ${$size};
      min-height: ${$size};
    `}
  ${({ $borderRadius }) =>
    $borderRadius &&
    css`
      border-radius: ${$borderRadius};
    `}
`;
