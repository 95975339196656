/** Endpoints api for Portfolio features */
const endpoints = {
  portfolioCategoryID: (categoryId: string) =>
    `/portfolio/category/${categoryId}`,
  portfolio: '/portfolio',
  portfolioAllocationSummary: `/portfolio/allocations/summary`,
  portfolioAllocation: (type: string) => `/portfolio/allocations/${type}`,
  portfolioAllocationDetail: (symbol: string) =>
    `/portfolios/allocations/${symbol}`,
  portfolioGoalOnTrack: (portfolioId: string) =>
    `/portfolio/goal/${portfolioId}/ontrack`,
  portfolioGoalDetail: (portfolioId: string) =>
    `/portfolio/goal/${portfolioId}/detail`,
  portfolioGoalChart: (portfolioId: string) =>
    `/portfolio/goal/${portfolioId}/chart`,
  recurringListByPorto: (portoId: string) => `/recurring/portfolio/${portoId}`,
  switchProductAvailable: `/portfolio/products/switch`,
  patchRearrangePortfolio: '/portfolio/sorts',
  portfolioCouponHistories: '/portfolio/coupon-histories',
};

export default endpoints;
