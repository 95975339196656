import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  ApprovePremiumTransactionAPIPayload,
  CancelPremiumTransactionAPIPayload,
  fetchExitPremiumReasons,
  getPremiumFeedbackOptions,
  getPremiumFeedbackQuotas,
  patchUpdatePremiumSetting,
  postSubmitPremiumFeedback,
  SubmitPremiumFeedbackAPIPayload,
  UpdatePremiumSettingAPIPayload,
} from './services';
import { services } from '.';

interface OptionsTypes {
  [key: string]: any;
}

const {
  getPremiumTransactionStats,
  getPremiumTransactionDetail,
  postApprovePremiumTransaction,
  postCancelPremiumTransaction,
  fetchPremiumStats,
  patchPremiumStats,
  fetchPremiumDetails,
  postReadPremiumNotification,
} = services;

export const GET_PREMIUM_TRANSACTIONS_QUERY_KEY = 'Get Premium Transactions';
export const GET_PREMIUM_TRANS_DETAIL_QUERY_KEY =
  'Get Premium Transaction Detail';

/**
 * GET to /premium/orders/stats
 */
export const useGetPremiumTransactions = (
  enabled: boolean = true,
  refetchOnMount: boolean | 'always' = true
) => {
  return useQuery(
    [GET_PREMIUM_TRANSACTIONS_QUERY_KEY],
    () => getPremiumTransactionStats(),
    {
      enabled,
      refetchOnMount,
    }
  );
};

/**
 * GET to /premium/orders/:wstrx_code/detail
 */
export const useGetPremiumTransactionDetail = (
  wstrx_code = '',
  enabled: boolean = true,
  opts: OptionsTypes = {}
) => {
  return useQuery(
    [GET_PREMIUM_TRANS_DETAIL_QUERY_KEY, wstrx_code],
    () => getPremiumTransactionDetail(wstrx_code),
    {
      enabled,
      ...opts,
    }
  );
};

/**
 * POST to /premium/order/approve
 */
export const usePostApprovePremiumTransaction = () => {
  return useMutation((param: ApprovePremiumTransactionAPIPayload) =>
    postApprovePremiumTransaction(param)
  );
};

/**
 * POST to /premium/order/cancel
 */
export const usePostCancelPremiumTransaction = () => {
  return useMutation((param: CancelPremiumTransactionAPIPayload) =>
    postCancelPremiumTransaction(param)
  );
};

/**
 * POST to /notifications/:id/read
 */
export const usePostReadPremiumNotification = (
  shouldRefetch: boolean = false
) => {
  const queryClient = useQueryClient();

  return useMutation(postReadPremiumNotification, {
    onSuccess: () => {
      if (shouldRefetch) {
        queryClient.invalidateQueries('notificationsProviderData');
        queryClient.invalidateQueries('Premium Stats');
      }
    },
  });
};

/**
 * GET to /premium/stats
 */

export const useFetchPremiumStats = (
  enabled: boolean = true,
  refetchOnMount: boolean | 'always' = true
) => {
  /**
   * Status:
   *   0: In-eligible,
   *   1: Almost Eligible,
   *   2: Eligible,
   *   3: Eligible but in Grace Period
   */
  return useQuery(['Premium Stats'], () => fetchPremiumStats(), {
    enabled,
    refetchOnMount,
  });
};

/**
 * GET to /premium
 */
export const useFetchPremiumDetails = (
  enabled: boolean = true,
  refetchOnMount: boolean | 'always' = true
) => {
  return useQuery(['Premium Details'], () => fetchPremiumDetails(), {
    enabled,
    refetchOnMount,
  });
};

/**
 * PATCH to /premium/stats
 */
export const usePatchPremiumStats = (refetchStats: boolean = false) => {
  const queryClient = useQueryClient();

  return useMutation(patchPremiumStats, {
    onSuccess: () => {
      if (refetchStats) {
        queryClient.invalidateQueries('Premium Stats');
      }
    },
  });
};

/**
 * GET to /premiums/exit-reasons
 */
export const useExitPremiumReasons = (
  enabled: boolean = true,
  refetchOnMount: boolean | 'always' = true
) => {
  return useQuery(['Exit Premium Reasons'], () => fetchExitPremiumReasons(), {
    enabled,
    refetchOnMount,
  });
};

/**
 * GET to /premiums/feedbacks/options
 */
export const useGetPremiumFeedbackOptions = (enabled: boolean = true) => {
  return useQuery(
    ['Premium Feedback Options'],
    () => getPremiumFeedbackOptions(),
    {
      enabled,
      staleTime: 60_000, // one minute to refetch when recall
    }
  );
};

/**
 * GET to /premiums/feedbacks/quotas
 */
export const useGetPremiumFeedbackQuotas = (enabled: boolean = true) => {
  return useQuery(
    ['Premium Feedback Quotas'],
    () => getPremiumFeedbackQuotas(),
    {
      enabled,
      staleTime: 0,
    }
  );
};

/**
 * POST to /premiums/feedbacks
 */
export const usePostSubmitPremiumFeedback = () => {
  return useMutation((param: SubmitPremiumFeedbackAPIPayload) =>
    postSubmitPremiumFeedback(param)
  );
};

/**
 * PATCH to /premium/settings
 */
export const usePatchPremiumSetting = () => {
  return useMutation((param: UpdatePremiumSettingAPIPayload) =>
    patchUpdatePremiumSetting(param)
  );
};
