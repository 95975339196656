import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useAppModalGlobalStateContext } from 'features/common/modals/AppModalGlobalContext';
import { useLocation } from 'react-router-dom';

const CallbackModal = React.lazy(() => import('./CallbackModal'));

const IndexAppCallbackModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { callbackModal } = useAppModalGlobalStateContext();
  const location = useLocation();

  const modalData: { visible: boolean } = React.useMemo(() => {
    const isMatchRoute =
      callbackModal?.pathname === location?.pathname && callbackModal?.visible;

    return {
      visible: isMatchRoute,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, callbackModal]);

  return <LazyModal component={CallbackModal} visible={modalData?.visible} />;
};

export default IndexAppCallbackModal;
