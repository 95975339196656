import React from 'react';
import noImage from 'assets/images/topup-modal-asset/no-image.svg';
import { getEnv } from 'core/env';
import { useTheme } from 'hooks';
import { useFlag } from 'utils/feature-flag';

const { PublicUrl } = getEnv();

interface NewPortfolioIconProps {
  /** Template portfolio id
   *
   * Required to show the icon from BE
   */
  templateId: number | string;

  /** Icon size in 'px' */
  iconSize?: number;

  /** Outer circle size in 'px' */
  circleSize?: number;

  /** Color of icon (it is respective to folder name) */
  iconColor?: 'green' | 'white' | 'line' | 'color';

  /* Background color of the portofolio icon is different between inside and outside modal */
  isInsideModal?: boolean;

  /* Is use small type portofolio icon */
  small?: boolean;

  partner?: boolean;

  portofolioName?: string;
}

/**
 * Dynamic Portfolio Icon based on template portfolio id
 */
const NewPortfolioIcon: React.FC<
  React.PropsWithChildren<NewPortfolioIconProps>
> = ({
  templateId,
  iconSize = 32,
  circleSize = 52,
  iconColor = 'green',
  isInsideModal = true,
  small = false,
  partner = false,
  portofolioName,
}) => {
  const { theme } = useTheme();
  const darkmodeIsActiveByFF = useFlag('web_bibit_darkmode');

  const getIconPartner = () => {
    if (!portofolioName) return;
    /** Formatted portofolioName to lowercase */
    const portofolioNameFormatted = portofolioName
      .replace(/\s+/g, '')
      .toLowerCase();

    const portfolioIconColor = `${PublicUrl}/assets/images/portfolio/old/color/${portofolioNameFormatted}.svg`;
    const portfolioIconGreen = `${PublicUrl}/assets/images/portfolio/old/color/${portofolioNameFormatted}.svg`;
    const portfolioIconLine = `${PublicUrl}/assets/images/portfolio/old/color/${portofolioNameFormatted}.svg`;

    const portfolioIconPartner = {
      green: portfolioIconGreen,
      line: portfolioIconLine,
      color: portfolioIconColor,
      white: portfolioIconGreen,
    };

    return portfolioIconPartner[iconColor];
  };

  /**
   * Get dynamically from folder portfolio/icon/
   * @see https://www.figma.com/file/fGDTzHZUKZwbktD95ptg8V/Revamp-Onboarding-1?node-id=9689%3A456290
   * */
  const getIconSrc = () => {
    if (darkmodeIsActiveByFF && theme === 'dark') {
      return `${PublicUrl}/assets/images/portfolio/icon/green/dark/${templateId}.svg`;
    }
    if (darkmodeIsActiveByFF) {
      return `${PublicUrl}/assets/images/portfolio/icon/green/${templateId}.svg`;
    }
    return `${PublicUrl}/assets/images/portfolio/old/icon/${iconColor}/${templateId}.svg`;
  };

  const getSmallIconSrc = () => {
    if (darkmodeIsActiveByFF && theme === 'dark') {
      return `${PublicUrl}/assets/images/portfolio/small/dark/${templateId}.svg`;
    }
    if (darkmodeIsActiveByFF) {
      return `${PublicUrl}/assets/images/portfolio/small/${templateId}.svg`;
    }
    return `${PublicUrl}/assets/images/portfolio/old/small/${templateId}.svg`;
  };

  const getBackgroundColor = () => {
    if (theme === 'dark' && iconColor === 'green' && !isInsideModal) {
      return 'var(--green-high)';
    }
    if (theme === 'dark' && iconColor === 'green' && isInsideModal) {
      return '#1E4336';
    }

    switch (iconColor) {
      case 'green':
        return 'var(--green-high)';
      case 'white':
        return '#00AB6B'; // green50
      default:
        return '#E5F7F0';
    }
  };

  const handleErrorImage = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = noImage;
  };

  if (partner) {
    return (
      <img
        style={{
          width: `${circleSize}px`,
          height: `${circleSize}px`,
        }}
        src={getIconPartner()}
        alt='Portfolio'
        onError={handleErrorImage}
      />
    );
  }

  if (small) {
    return (
      <img
        src={getSmallIconSrc()}
        alt='portfolio template'
        onError={handleErrorImage}
        style={{
          width: `${circleSize}px`,
          height: `${circleSize}px`,
        }}
      />
    );
  }

  return (
    <div
      className='portfolio-icon-wrapper border-radius-rounded flex vertical-center horizontal-center'
      style={{
        backgroundColor: getBackgroundColor(),
        width: `${circleSize}px`,
        height: `${circleSize}px`,
        borderRadius: '50%',
      }}
    >
      <img
        src={getIconSrc()}
        alt='portfolio template'
        onError={handleErrorImage}
        style={{
          width: `${iconSize}px`,
          height: `${iconSize}px`,
          borderRadius: 0,
          margin: 'auto',
        }}
      />
    </div>
  );
};

export default NewPortfolioIcon;
