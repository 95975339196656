import Storage from 'core/Storage';
import { DEFAULT_STATUS_BAR_COLOR, STATUS_BAR_COLOR_KEY } from '../constants';
import { StatusBarApp } from '../types';
import { setAppStatusBarColor } from './setAppStatusBarColor';

/**
 * When user premium opening crisp, status bar will be set into default color
 */
export const setStatusBarWhenOpeningCrisp = async () => {
  /** Latest status bar color before opening crsip */
  const statusBarColorFromStorage: StatusBarApp = await Storage.getObject(
    STATUS_BAR_COLOR_KEY
  );

  if (
    statusBarColorFromStorage?.backgroundColor !==
    DEFAULT_STATUS_BAR_COLOR().backgroundColor
  ) {
    setAppStatusBarColor({
      backgroundColor: DEFAULT_STATUS_BAR_COLOR().backgroundColor,
      foregroundColor: DEFAULT_STATUS_BAR_COLOR().foregroundColor,
    });
  }
};

/**
 * When user premium closing crisp, status bar will be set into latest color in local storage
 */
export const setStatusBarWhenClosingCrisp = async () => {
  /** Latest status bar color before opening crsip */
  const statusBarColorFromStorage: StatusBarApp = await Storage.getObject(
    STATUS_BAR_COLOR_KEY
  );

  const backgroundColor = statusBarColorFromStorage?.backgroundColor || '';
  const foregroundColor = statusBarColorFromStorage?.foregroundColor || '';

  if (backgroundColor !== '#ffffff') {
    setAppStatusBarColor({ backgroundColor, foregroundColor });
  }
};
