import list from './../services';
import http from 'core/http';
import { checkFeatureVersion } from 'utils/feature-version';

/**
 * @deprecated this should not be used, aim to use usePreferenceMutation instead
 * @see {@link usePreferenceMutation} `react-query` hooks for editing preference
 */
export const postUserUpdatePreference = (data) => {
  return new Promise((resolve, reject) => {
    return http
      .post(list.userPreferenceUpdate, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

/**
 * GET: User detail profile
 */
export const getDetailUserProfile = () => {
  return http.get(list.userProfile);
};

/**
 * POST: update profile with no process,
 * look 'updateProfile' for more detail process
 * @param {object} toSend
 */
export const postUserProfileUpdate = (toSend) => {
  return http.post(list.userProfileUpdate, toSend);
};

/**
 * POST: Verify token change bank
 * @param {object} toBody
 */
export const postVerifyChangeBank = (toBody) => {
  return http.post(list.userChangeBankVerify, toBody);
};

/**
 * POST: execute change bank
 * @param {object} toBody
 */
export const postUpdateChangeBank = (toBody) => {
  return http.post(list.userChangeBankUpdate, toBody);
};

/**
 * POST: execute change bank liveness
 * @param {object} toBody
 */
export const postUpdateChangeBankLiveness = async (toBody) => {
  const isVersionSupported = await checkFeatureVersion(
    'Amend Bank Improvement'
  );

  const apiLink = isVersionSupported
    ? list.userChangeBankUpdateLiveness + '?v=2'
    : list.userChangeBankUpdateLiveness;

  return http.post(apiLink, toBody);
};

/**
 * POST: Request change bank account
 */
export const postRequestChangeBank = () => {
  return http.post(list.userChangeBankRequest);
};

/**
 * POST: remove search
 * @param {object} data
 */
export const postRemoveSearch = (data) => {
  return http.post(list.userSearchRemove, data);
};

/**
 * GET: get search history
 * @param {object} param
 * @returns {Promise<import('axios').AxiosResponse<import('services').BibitApiSuccessResponse<ProductHistorySearch[]>>>}
 */
export const getSearchHistory = (param) => {
  return http.get(list.userSearchHistory, param);
};

/**
 * POST: post search history user
 * @param {object} data
 */
export const postSearchHistoryReksa = (data) => {
  return http.post(list.userSearchHistory, data);
};

/**
 * GET: get list notification
 */
export const getNotificationList = () => {
  return http.get(list.notificationList);
};

/**
 * GET: read notification
 * @param {string} id
 */
export const getListNotificationRead = (id) => {
  return http.get(list.notificationRead(id));
};

/**
 * POST: upload edd file
 * @param {obejct} toBody
 */
export const postUploadEDD = (toBody) => {
  return http.post(list.userEddUpload, toBody);
};

/**
 * POST: validate ktp
 * @param {string} identity
 */
export const postValidateKTP = (identity) => {
  return http.post(list.userKtpValidate, { ktp: identity });
};

/**
 * POST: Array index number of the bank chosen by user as default
 * @param {number} slotNumber
 */
export const postSetDefaultBank = (slotNumber) => {
  return http.post(list.setDefaultBank, { bank_slot: slotNumber });
};

/**
 * POST: Deactive bank by slot number
 *
 * Make sure before call this, you need to pass pinVerify
 * @param {number} slotNumber
 * @param {string} securekey
 */
export const postDeactiveBank = (slotNumber) => {
  return http.post(list.deactiveBank, { bank_slot: slotNumber });
};

/**
 * GET: List Bank User
 *
 * @returns {import('./user.type').ResponseAllUserBank}
 */
export const getBankListUser = ({ unmask = 0 } = {}) => {
  return http.get(list.getBankListUser(unmask));
};

/**
 * POST: Check NIK
 */
export const postCheckNIK = (identity) => {
  return http.post(list.checkNIK, { identity });
};

/**
 * GET: Check OCR
 */
export const getCheckOCR = (jobId) => {
  return http.get(list.checkOcr(jobId));
};

/**
 * POST: Check Email
 */
export const postCheckEmail = (email) => {
  return http.post(list.checkEmail, { email });
};

/**
 * GET: Liveness quota
 * @returns {import('./user.type').ResposeQuotaLiveness}
 */
export const getUserLivenessQuotas = () => {
  return http.get(list.userLivenessQuotas);
};
