import React from 'react';

interface Props {
  className?: string;
}

const Loading: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  return (
    <div
      className={`satu-box priority-layer ${className}`}
      data-testid='loading-screen'
    >
      <div className='satu'>
        <div className='dua'>
          <div className='tiga' />
          <b className='b1' />
          <b className='b2' />
        </div>
      </div>
    </div>
  );
};

export default Loading;
