import { combineReducers } from 'redux';
import reksadana from './reksadana.reducer';
import custodian from './custodian.reducer';
import investmentManager from './investmentManager.reducer';
import user from './user.reducer';
import orders from './orders.reducer';
import portfolio from './portfolio.reducer';
import bankAccounts from './bankAccounts.reducer';
import common from './common.reducer';
import robo from './robo.reducer';

export default combineReducers({
  reksadana,
  custodian,
  investmentManager,
  user,
  orders,
  portfolio,
  bankAccounts,
  common,
  robo,
});
