import { set, remove, get } from './basicHandler';
import { setObject, removeObject } from './objectHandler';
import type { TokenProperties } from './storage.type';
import { differenceInMinutes, fromUnixTime } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { JwtAcToken } from 'core/refresh-token/refresh-token.type';

/**
 * get access token in storage `actoken`
 *
 * just token not with `string` Bearer
 */
export const getAccessToken = async () => {
  return get('actoken');
};

/**
 * set token properties to local storage
 * - ac
 * - actoken
 * - rftoken
 * - acexpire
 * - rfexpire
 */
export const setTokenToLocalStorage = async (token: TokenProperties) => {
  // construct data for old token
  const oldToken = {
    type: 'Bearer',
    access: token.access,
  };

  let jwtDecoded: JwtAcToken | undefined;

  try {
    jwtDecoded = jwtDecode<JwtAcToken>(token.access_token);
  } catch (error) {}

  if (!jwtDecoded) {
    new Error('access token invalid');
    return;
  }

  /**
   * Difference time between server time and Local time
   *
   * `iat` is when token is created in Server Time, so we can use `iat` as current server time
   */
  const diffTime = differenceInMinutes(
    fromUnixTime(jwtDecoded.iat), // --> server time
    new Date() // --> local time
  );

  // store the diff in local storage
  localStorage?.setItem('offside_timezone', String(diffTime));

  // set token properties into local Storage
  await Promise.all([
    setObject('ac', oldToken),
    set('actoken', token.access_token),
    set('rftoken', token.refresh_token),
    set('acexpire', token.access_token_expired_at),
    set('rfexpire', token.refresh_token_expired_at),
  ]);
};

/**
 * Common Interface to handle remove token from Client
 *
 * @returns {Promise<boolean>} Signal if 'removeTokenHandler' is done
 */
export function removeTokenHandler(): Promise<boolean> {
  const collection = [
    remove('actoken'),
    remove('rftoken'),
    remove('acexpire'),
    remove('rfexpire'),
    removeObject('ac'),
  ];

  return Promise.all(collection).then(() => Promise.resolve(true));
}

/**
 * offside between client and server in minutes
 */
export const getOffsideTimezone = () => {
  const offside = localStorage.getItem('offside_timezone');

  if (!offside) return 0;

  return Number(offside);
};
