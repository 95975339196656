import list from './index';
import { _showErrorToast } from './../utils';
import { handleResponseGeneral } from 'utils/http-response';
import http from 'core/http';
import { getCurrentInstitution } from 'features/institution/utils';

export const handleCommonError = (err, reject, shouldShow) => {
  const { message } = err || {};
  if (shouldShow) _showErrorToast(message || err);
  return reject(err);
};

export const getMI = ({ page, limit }) => {
  return new Promise((resolve, reject) => {
    const param = {
      page,
      limit,
    };
    http
      .get(list.getManagerInvestment, param)
      .then((res) => resolve(handleResponseGeneral(res)))
      .catch((er) => handleCommonError(er, reject, false));
  });
};

/**
 * GET: fetch data by MI
 * @param {object} data
 */
export const getProductByMI = async (data) => {
  const institution = getCurrentInstitution();

  if (!!institution?.institution_id) {
    data.currency = 'idr';
    // We remove syariah params in bibit institution
    const { syariah, ...resParams } = data;
    data = resParams;
  }

  return http.get(list.getManagerInvestment, data);
};

/**
 * GET: get simulation reksa by symbol
 * @param {string} symbol
 */
export const getSimulationBySymbol = (symbol) => {
  return http.get(list.simulationSymbol(symbol));
};

/**
 *
 * @param {string} symbol
 * @param {number} page
 * @param {number} limit
 * @returns {import("./reksadana.type").ResponseSwitchableProduct}
 */
export const getSwitchableProduct = (symbol, page = 1, limit = 10) => {
  return http.get(list.productSwitchable(symbol), { page, limit });
};

/**
 *
 * @param {string} symbol
 * @param {number} page
 * @param {number} limit
 * @returns {import('./reksadana.type').ResponseDividendHistoryProduct}
 */
export const getHistoryDividendProduct = (symbol, page = 1, limit = 10) => {
  return http.get(list.productDividend(symbol), { page, limit });
};
