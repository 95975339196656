import { useCallback, useEffect, useState } from 'react';

/**
 * @description This hook is used to get the window.scrollY position
 * @param {number} threshold This parameter is used to determine the limit when the scrollPosition value will be reversed,
 * because if the scrollPosition is still below the threshold value, the scrollPosition will be set to 0. And the default threshold is 100
 * @returns window.scrollY position
 * @example const { scrollPosition } = useFormHandleWindowScroll(300);
 */

const useFormHandleWindowScroll = (threshold: number = 100) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [lastScrollPosition, setLastScrollPosition] = useState<number>(0);

  const handleWindowScroll = useCallback(() => {
    return setScrollPosition(
      window?.scrollY >= threshold ? window?.scrollY : 0
    );
  }, [threshold]);

  const handleTouchMove = useCallback(() => {
    setIsScrolling(true);
  }, []);

  const handleTouchEnd = useCallback(() => {
    setIsScrolling(false);
  }, []);

  const handleTouchStart = useCallback(() => {
    return setLastScrollPosition(
      window?.scrollY >= threshold ? window.scrollY : 0
    );
  }, [threshold]);

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.addEventListener('touchstart', handleTouchStart);
    };
  }, [handleTouchStart]);

  useEffect(() => {
    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.addEventListener('touchmove', handleTouchMove);
    };
  }, [handleTouchMove]);

  useEffect(() => {
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.addEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchEnd]);

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, [handleWindowScroll]);

  return {
    lastScrollPosition,
    isScrolling,
    scrollPosition,
  };
};

export default useFormHandleWindowScroll;
