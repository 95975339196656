import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Info } from 'react-feather';
import { connect, ConnectedProps } from 'react-redux';
import { TRootReducers } from 'store/root';
import { Text } from '@bibitid/uikit-v1';

const mapStateToProps = (state: TRootReducers) => ({
  visible: state.toast.toastTop.visibleToast,
  theme: state.toast.toastTop.theme,
  msg: state.toast.toastTop.textMessage,
});
const connector = connect(mapStateToProps);

type reduxProps = ConnectedProps<typeof connector>;
type P = reduxProps;

/**
 * @todo need refactor this toast
 */
const ToastTop: React.FC<React.PropsWithChildren<P>> = ({
  visible,
  theme,
  msg,
}) => {
  return ReactDOM.createPortal(
    <div
      className={classNames(`wrapper-toast-top ${theme}`, {
        open: visible,
      })}
    >
      <div>
        <Info
          color={
            theme === 'green' ? 'var(--green-default)' : 'var(--red-default)'
          }
        />
        <Text color='greenLow' htmlTag='span'>
          {msg}
        </Text>
      </div>
    </div>,
    document.body
  );
};

export default connector(memo(ToastTop));
