import React from 'react';
import { Modal } from '@bibitid/uikit-v1';
import ModalIlustration from './assets/ModalIlustration';
import { Wrapper } from './SetupPinInfoModal.styled';

export interface Props {
  visibility: boolean;
  toggleModal: () => void;
}

const SetupPinInfoModal: React.FC<React.PropsWithChildren<Props>> = ({
  visibility,
  toggleModal,
}) => {
  return (
    <Modal visible={visibility} onClose={toggleModal}>
      <Modal.Header showClose={true} showBackButton={false} />
      <Modal.Body>
        <Wrapper>
          <ModalIlustration />
          <h3>Buat PIN Kamu</h3>
          <p>
            Buat 6 Digit PIN untuk digunakan pada saat masuk ke dalam aplikasi
            Bibit. PIN ini bersifat personal dan hanya kamu yang boleh tau.
          </p>
        </Wrapper>
      </Modal.Body>
      <Modal.Footer primaryButton={'Oke'} onPrimaryClick={toggleModal} />
    </Modal>
  );
};

export default SetupPinInfoModal;
