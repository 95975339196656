import React, {
  FC,
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

type Step = 'information' | 'choose' | undefined;

type TriggeredFrom = 'referral' | 'giftcard' | 'voucher' | undefined;

interface CreatePortfolioModalContextInterface {
  createPortfolioRoboModalVisible: boolean;
  step: Step;
  templateId?: string;
  triggeredFrom: TriggeredFrom;
  giftcardCode?: string;
  voucherAmount?: number;
  voucherCode?: string;
  voucherData?: any;
  referralBalance?: number;

  setCreatePortfolioRoboModalVisible: Dispatch<SetStateAction<boolean>>;
  setStep: Dispatch<SetStateAction<Step>>;
  setTemplateId: Dispatch<SetStateAction<string | undefined>>;
  setTriggeredFrom: Dispatch<SetStateAction<TriggeredFrom>>;
  setGiftcardCode: Dispatch<SetStateAction<string | undefined>>;
  setVoucherAmount: Dispatch<SetStateAction<number | undefined>>;
  setVoucherCode: Dispatch<SetStateAction<string | undefined>>;
  setVoucherData: Dispatch<SetStateAction<any>>;
  setReferralBalance: Dispatch<SetStateAction<number | undefined>>;
  closeModal: () => void;
  resetCreatePortfolioRoboModal: () => void;
}
const CreatePortfolioModalContext = createContext<
  CreatePortfolioModalContextInterface | undefined
>(undefined);

const CreateProtfolioModalProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [step, setStep] = useState<Step>();
  const [
    createPortfolioRoboModalVisible,
    setCreatePortfolioRoboModalVisible,
  ] = useState(false);
  const [templateId, setTemplateId] = useState<string>();
  const [triggeredFrom, setTriggeredFrom] = useState<TriggeredFrom>();
  const [giftcardCode, setGiftcardCode] = useState<string>();
  const [voucherAmount, setVoucherAmount] = useState<number>();
  const [voucherCode, setVoucherCode] = useState<string>();
  const [voucherData, setVoucherData] = useState<any>();
  const [referralBalance, setReferralBalance] = useState<number>();

  const closeModal = () => {
    setCreatePortfolioRoboModalVisible(false);
    setStep(undefined);
  };

  const resetCreatePortfolioRoboModal = () => {
    setStep(undefined);
    setTemplateId(undefined);
    setTriggeredFrom(undefined);
    setGiftcardCode(undefined);
    setReferralBalance(undefined);
  };

  return (
    <CreatePortfolioModalContext.Provider
      value={{
        step,
        createPortfolioRoboModalVisible,
        templateId,
        triggeredFrom,
        giftcardCode,
        setStep,
        voucherAmount,
        voucherCode,
        voucherData,
        referralBalance,

        setCreatePortfolioRoboModalVisible,
        closeModal,
        setTriggeredFrom,
        setTemplateId,
        setGiftcardCode,
        setVoucherAmount,
        setVoucherCode,
        setVoucherData,
        resetCreatePortfolioRoboModal,
        setReferralBalance,
      }}
    >
      {children}
    </CreatePortfolioModalContext.Provider>
  );
};

const useCreatePortoModal = () => {
  const context = useContext(CreatePortfolioModalContext);
  if (context === undefined) {
    throw new Error(
      'useCreatePortoModal must be used within a CreateProtfolioModalProvider'
    );
  }
  return context;
};

export default CreateProtfolioModalProvider;

export { useCreatePortoModal };
