import React, { useState, useEffect, SyntheticEvent } from 'react';
import { PortfolioTemplateInterface } from 'features/home/components/Modals/onboarding-modal-flow/types';
import classNames from 'classnames';
import { usePortfolioTemplate } from 'features/home/components/Modals/onboarding-modal-flow/hooks';
import NewPortfolioIcon from 'features/common/icons/NewPortfolioIcon';

import styles from './ChooseInvestmentTarget.module.css';

interface ChooseInvestmentTargetProps {
  /** determine replace last of list with Porto Bibit Pension Type */
  usePension?: boolean;

  /** default selected template id */
  defaultTemplateId?: string;

  /** handle callback when item selected */
  onItemSelected?: (templateId: string | undefined) => void;
}

const ChooseInvestmentTarget: React.FC<
  React.PropsWithChildren<ChooseInvestmentTargetProps>
> = ({ usePension = true, defaultTemplateId, onItemSelected = () => {} }) => {
  const [templateId, setTemplateId] = useState<string | undefined>(
    defaultTemplateId
  );
  const [portfolioTemplates, setPortfolioTemplates] = useState<
    PortfolioTemplateInterface[]
  >([]);

  const { data: portfolioTemplateData, isLoading } = usePortfolioTemplate(true);

  useEffect(() => {
    const templates: PortfolioTemplateInterface[] = [
      ...(portfolioTemplateData?.data?.data ?? []),
    ];

    // Replace Lainnya to Pensiun object
    if (usePension) {
      const lainnyaIndex = templates.findIndex(
        (template) => template.name === 'Lainnya'
      );

      templates.splice(lainnyaIndex, 1, {
        id: 2,
        name: 'Pensiun',
        images: [],
        placeholder: 'kursi',
      });
    }

    setPortfolioTemplates(templates);
  }, [portfolioTemplateData, usePension]);

  /** Contains 12 indexes -> to iterate 12 skeleton circle */
  const twelveIndexes = Array.from(Array(12).keys());

  /** Choose Icon -> Set portfolio template ID */
  const handleChooseIcon = (event: SyntheticEvent<HTMLDivElement>) => {
    const id = event.currentTarget.dataset.id;
    setTemplateId(id);
    onItemSelected(id);
  };

  return (
    <div>
      {isLoading && (
        <div className={classNames(styles['bit-target-container'], 'shimmer')}>
          {twelveIndexes.map((index) => {
            return (
              <div key={index} className={styles['bit-target-item']}>
                <div className='bit-porto-goal-circle' />
                <p>&nbsp;</p>
              </div>
            );
          })}
        </div>
      )}

      {/* Portfolio Templates */}
      {!isLoading && (
        <div className={styles['bit-target-container']}>
          {portfolioTemplates &&
            portfolioTemplates.map((template: any) => (
              <div
                key={template?.id}
                data-id={template?.id}
                data-templateid={template?.id}
                data-name={template?.name}
                onClick={handleChooseIcon}
                className={styles['bit-target-item']}
              >
                <div
                  className={classNames(styles['bit-porto-goal-item-circle'], {
                    [styles['bit-porto-goal-item-circle-selected']]:
                      String(templateId) === String(template?.id),
                  })}
                >
                  <NewPortfolioIcon
                    templateId={template?.id}
                    circleSize={52}
                    iconSize={28}
                  />
                </div>
                <p>{template?.name}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ChooseInvestmentTarget;
