// End point api for feature recurring
export const endPoints = {
  recurring: '/recurring',
  recurringAdd: '/recurring/add',
  recurringOverview: '/recurring/overview',
  recurringAvailablePorto: '/recurring/available/portfolio',
  recurringChange: (id: string) => `/recurring/change/${id}`,
  recurringDelete: (target: number) => `/recurring/delete/${target}`,
  recurringDetail: (recurringId: string) => `/recurring/${recurringId}`,
  recurringListByPorto: (portoId: string) => `/recurring/portfolio/${portoId}`,
  recurringReset: (recurringId: number) =>
    `recurring/challenges/${recurringId}/reset`,
  recurringPortfolio: '/recurring/portfolio',
  recurringReactivate: '/recurring/reactivate',
  getProductMaxBuy: '/payment/product/max-buy',
  getRecurringPromo: '/promos',
  createRecurringChallenge: 'recurring/challenges',
};
