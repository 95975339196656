import React from 'react';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import getSafely from 'utils/safely';
import { numberToAveragedLetter } from 'utils/stringHelper';
import classNames from 'classnames';
import { ReksaDana } from 'entities/reksadana.reducer';

interface CardProps {
  fundData: ReksaDana;
  isComparing?: boolean;
  selected?: boolean;
  onClick: (roboCategory: string, symbol: string) => void;
}

const ReksaDanaAUMCard: React.FC<React.PropsWithChildren<CardProps>> = ({
  fundData,
  selected,
  isComparing,
  onClick,
}) => {
  const { name, type, investment_manager } = fundData;
  const value: number = getSafely(['sort_value'], fundData, 0)
    ? getSafely(['sort_value'], fundData, 0)
    : getSafely(['aum', 'value'], fundData, 0);
  let _value: string = numberToAveragedLetter(value, true, { totalLength: 3 });

  const handleClick = () => {
    const { robocategory, symbol } = fundData;
    onClick(robocategory, symbol);
  };

  return (
    <div
      className={classNames({
        'bit-filter-result-row': true,
        'bit-filter-aum': true,
        'card-selected': selected,
        'card-reksa-shake': isComparing,
      })}
    >
      <div onClick={handleClick}>
        <div className='bit-filter-reksa'>
          <div className='bit-filter-reksa-pic'>
            <InvestmentCompanyLogo
              type='investment-manager'
              size='square'
              ojkCode={getSafely(['ojkCode'], investment_manager, '')}
            />
          </div>
          <div>
            <h3>{name}</h3>
            <p>{type}</p>
          </div>
        </div>
        <div className='bit-filter-right-result no-min-width'>
          <p>{_value}</p>
        </div>
      </div>
    </div>
  );
};

export default ReksaDanaAUMCard;
