import LazyModal from 'features/common/LazyModal';
import React, { lazy } from 'react';
import { Props } from './JagoFailedBalanceModal';

const Modal = lazy(() => import('./JagoFailedBalanceModal'));

const JagoFailedBalanceModal = (props: Props) => {
  return <LazyModal component={Modal} visible={props.showModal} {...props} />;
};

export default JagoFailedBalanceModal;
