import http from 'core/http';
import endpoints from './endpoints';
import { endpoints as institutionEndpoints } from 'features/institution/networks/endpoints';
import { CapturePaymentPayload } from '../types';

/**
 *
 * @param code invoiceid or order number
 */
export const postCancelOrder = (code: string) => {
  return http.post('/order/cancel', {
    code,
  });
};

/**
 * @param code wstrx_code
 */
export const postPremiumSubmitOrderConfirmation = (
  wstrx_code: string,
  trans_code: string
) => {
  return http.post(endpoints.premiumSubmitOrderConfirmation, {
    wstrx_code,
    trans_code,
  });
};

/**
 * @param code wstrx_code
 */
export const postInstitutionSubmitOrderConfirmation = (wstrx_code: string) => {
  return http.post(institutionEndpoints.confirmPaymentReceipt(wstrx_code));
};

/**
 * Capture Payment invoice id for SNAP Jago
 * @param body
 * @returns
 */
export const capturePaymentSNAPJago = ({
  orderId,
  ...rest
}: CapturePaymentPayload) => {
  return http.post(endpoints.capturePayment(orderId), rest);
};
