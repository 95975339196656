import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ThemeProvider as Provider } from '@bibitid/uikit-v1';
import { mappedColors } from './mapped-color';
import storage from 'core/Storage';
import { isInsideNativeContainer } from 'utils/validator';

export type ThemeTypes = 'light' | 'dark';

// Temporary THeme

type themeContextTypes = {
  theme: ThemeTypes;
  setTheme: (val: ThemeTypes) => void;
};
const noop = () => {};

export const ThemeContext = React.createContext<themeContextTypes>({
  theme: 'light',
  setTheme: noop,
});

/**
 * Wrapper ThemeProvider from UIKIT-V1
 *
 * TODO: might be a better coding pattern for this
 * ? please advice if you have any improvement from the uikit-v1 side
 */
const ThemeProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeTypes>('light');

  const handleSetTheme = useCallback((themeVal: ThemeTypes) => {
    // set to local state
    setTheme(themeVal);

    const inNative = isInsideNativeContainer();

    // set to local storage current theme user
    window.localStorage.setItem('bibit-theme', String(themeVal));

    if (inNative) {
      // set to Native Storage
      storage.set('bibit-theme', String(themeVal));
    }

    // picked color in mapped colors
    const color = mappedColors[themeVal];

    // check if there's any style-tag exist
    let styleTag = document.getElementById('bibit-theme-variables');

    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.setAttribute('id', 'bibit-theme-variables');
      styleTag.setAttribute('type', 'text/css');
    }

    // inject theme colors
    styleTag.innerHTML = `
        :root {
            --modal-surface: ${color.modal.surface};
            --modal-surface-gray: ${color.modal.surfaceGray};
            --modal-highlight: ${color.modal.highlight};
            --modal-border: ${color.modal.border};
            --modal-divider-thin: ${color.modal.dividerThin};
            --modal-divider-thick: ${color.modal.dividerThick};

            --modal-green-low: ${color.colors.modalGreenLow};
            --modal-green-medium: ${color.colors.modalGreenMedium};
            --modal-green-high: ${color.colors.modalGreenHigh};
            --modal-green-default: ${color.colors.modalGreenDefault};

            --modal-teal-low: ${color.colors.modalTealLow};
            --modal-teal-medium: ${color.colors.modalTealMedium};
            --modal-teal-high: ${color.colors.modalTealHigh};
            --modal-teal-default: ${color.colors.modalTealDefault};

            --modal-olive-low: ${color.colors.modalOliveLow};
            --modal-olive-medium: ${color.colors.modalOliveMedium};
            --modal-olive-high: ${color.colors.modalOliveHigh};
            --modal-olive-default: ${color.colors.modalOliveDefault};

            --modal-cobalt-low: ${color.colors.modalCobaltLow};
            --modal-cobalt-medium: ${color.colors.modalCobaltMedium};
            --modal-cobalt-high: ${color.colors.modalCobaltHigh};
            --modal-cobalt-default: ${color.colors.modalCobaltDefault};

            --modal-periwinkle-low: ${color.colors.modalPeriwinkleLow};
            --modal-periwinkle-medium: ${color.colors.modalPeriwinkleMedium};
            --modal-periwinkle-high: ${color.colors.modalPeriwinkleHigh};
            --modal-periwinkle-default: ${color.colors.modalPeriwinkleDefault};

            --modal-violet-low: ${color.colors.modalVioletLow};
            --modal-violet-medium: ${color.colors.modalVioletMedium};
            --modal-violet-high: ${color.colors.modalVioletHigh};
            --modal-violet-default: ${color.colors.modalVioletDefault};

            --modal-magenta-low: ${color.colors.modalMagentaLow};
            --modal-magenta-medium: ${color.colors.modalMagentaMedium};
            --modal-magenta-high: ${color.colors.modalMagentaHigh};
            --modal-magenta-default: ${color.colors.modalMagentaDefault};

            --modal-peach-low: ${color.colors.modalPeachLow};
            --modal-peach-medium: ${color.colors.modalPeachMedium};
            --modal-peach-high: ${color.colors.modalPeachHigh};
            --modal-peach-default: ${color.colors.modalPeachDefault};

            --modal-red-low: ${color.colors.modalRedLow};
            --modal-red-medium: ${color.colors.modalRedMedium};
            --modal-red-high: ${color.colors.modalRedHigh};
            --modal-red-default: ${color.colors.modalRedDefault};

            --modal-blue-low: ${color.colors.modalBlueLow};
            --modal-blue-medium: ${color.colors.modalBlueMedium};
            --modal-blue-high: ${color.colors.modalBlueHigh};
            --modal-blue-default: ${color.colors.modalBlueDefault};

            --modal-navy-low: ${color.colors.modalNavyLow};
            --modal-navy-medium: ${color.colors.modalNavyMedium};
            --modal-navy-high: ${color.colors.modalNavyHigh};
            --modal-navy-default: ${color.colors.modalNavyDefault};

            --modal-brown-low: ${color.colors.modalBrownLow};
            --modal-brown-medium: ${color.colors.modalBrownMedium};
            --modal-brown-high: ${color.colors.modalBrownHigh};
            --modal-brown-default: ${color.colors.modalBrownDefault};

            --modal-orange-low: ${color.colors.modalOrangeLow};
            --modal-orange-medium: ${color.colors.modalOrangeMedium};
            --modal-orange-high: ${color.colors.modalOrangeHigh};
            --modal-orange-default: ${color.colors.modalOrangeDefault};

            --modal-yellow-low: ${color.colors.modalYellowLow};
            --modal-yellow-medium: ${color.colors.modalYellowMedium};
            --modal-yellow-high: ${color.colors.modalYellowHigh};
            --modal-yellow-default: ${color.colors.modalYellowDefault};

            --modal-gold-low: ${color.colors.modalGoldLow};
            --modal-gold-medium: ${color.colors.modalGoldMedium};
            --modal-gold-high: ${color.colors.modalGoldHigh};
            --modal-gold-default: ${color.colors.modalGoldDefault};

            --surface: ${color.surface};
            --surface-gray: ${color.surfaceGray};
            --highlight: ${color.highlight};
            --border: ${color.border};
            --divider-thin: ${color.dividerThin};
            --divider-thick: ${color.dividerThick};

            --text-primary: ${color.textPrimary};
            --text-secondary: ${color.textSecondary};
            --text-tertiary: ${color.textTertiary};
            --text-white: ${color.textWhite};

            --inactive: ${color.inactive};
            --inactive-button: ${color.inactiveButton};
            --icon-default: ${color.iconDefault};

            --green-default: ${color.colors.greenDefault};
            --green-low: ${color.colors.greenLow};
            --green-medium: ${color.colors.greenMedium};
            --green-high: ${color.colors.greenHigh};

            --red-default: ${color.colors.redDefault};
            --red-low: ${color.colors.redLow};
            --red-medium: ${color.colors.redMedium};
            --red-high: ${color.colors.redHigh};

            --blue-default: ${color.colors.blueDefault};
            --blue-low: ${color.colors.blueLow};
            --blue-medium: ${color.colors.blueMedium};
            --blue-high: ${color.colors.blueHigh};

            --navy-default: ${color.colors.navyDefault};
            --navy-low: ${color.colors.navyLow};
            --navy-medium: ${color.colors.navyMedium};
            --navy-high: ${color.colors.navyHigh};

            --brown-default: ${color.colors.brownDefault};
            --brown-low: ${color.colors.brownLow};
            --brown-medium: ${color.colors.brownMedium};
            --brown-high: ${color.colors.brownHigh};

            --orange-default: ${color.colors.orangeDefault};
            --orange-low: ${color.colors.orangeLow};
            --orange-medium: ${color.colors.orangeMedium};
            --orange-high: ${color.colors.orangeHigh};

            --yellow-default: ${color.colors.yellowDefault};
            --yellow-low: ${color.colors.yellowLow};
            --yellow-medium: ${color.colors.yellowMedium};
            --yellow-high: ${color.colors.yellowHigh};

            --gold-default: ${color.colors.goldDefault};
            --gold-low: ${color.colors.goldLow};
            --gold-medium: ${color.colors.goldMedium};
            --gold-high: ${color.colors.goldHigh};

            --teal-default: ${color.colors.tealDefault};
            --teal-low: ${color.colors.tealLow};
            --teal-medium: ${color.colors.tealMedium};
            --teal-high: ${color.colors.tealHigh};

            --olive-default: ${color.colors.oliveDefault};
            --olive-low: ${color.colors.oliveLow};
            --olive-medium: ${color.colors.oliveMedium};
            --olive-high: ${color.colors.oliveHigh};

            --cobalt-default: ${color.colors.cobaltDefault};
            --cobalt-low: ${color.colors.cobaltLow};
            --cobalt-medium: ${color.colors.cobaltMedium};
            --cobalt-high: ${color.colors.cobaltHigh};

            --periwinkle-default: ${color.colors.periwinkleDefault};
            --periwinkle-low: ${color.colors.periwinkleLow};
            --periwinkle-medium: ${color.colors.periwinkleMedium};
            --periwinkle-high: ${color.colors.periwinkleHigh};

            --violet-default: ${color.colors.violetDefault};
            --violet-low: ${color.colors.violetLow};
            --violet-medium: ${color.colors.violetMedium};
            --violet-high: ${color.colors.violetHigh};

            --magenta-default: ${color.colors.magentaDefault};
            --magenta-low: ${color.colors.magentaLow};
            --magenta-medium: ${color.colors.magentaMedium};
            --magenta-high: ${color.colors.magentaHigh};

            --peach-default: ${color.colors.peachDefault};
            --peach-low: ${color.colors.peachLow};
            --peach-medium: ${color.colors.peachMedium};
            --peach-high: ${color.colors.peachHigh};

            --fr-background: ${color.colors.FRBackground};
            --fr-skeleton: ${color.colors.FRSkeleton};


            --jago-default: ${color.colors.jagoDefault};
            --jago-low: ${color.colors.jagoLow};
            --jago-medium: ${color.colors.jagoMedium};
            --jago-high: ${color.colors.jagoHigh};

            --modal-jago-default: ${color.colors.modalJagoDefault};
            --modal-jago-low: ${color.colors.modalJagoLow};
            --modal-jago-medium: ${color.colors.modalJagoMedium};
            --modal-jago-high: ${color.colors.modalJagoHigh};

            --jago-syariah-default: ${color.colors.jagoSyariahDefault};
            --jago-syariah-low: ${color.colors.jagoSyariahLow};
            --jago-syariah-medium: ${color.colors.jagoSyariahMedium};
            --jago-syariah-high: ${color.colors.jagoSyariahHigh};

            --modal-jago-syariah-default: ${color.colors.modalJagoSyariahDefault};
            --modal-jago-syariah-low: ${color.colors.modalJagoSyariahLow};
            --modal-jago-syariah-medium: ${color.colors.modalJagoSyariahMedium};
            --modal-jago-syariah-high: ${color.colors.modalJagoSyariahHigh};

            --gopay-default: ${color.colors.gopayDefault};
            --gopay-low: ${color.colors.gopayLow};
            --gopay-medium: ${color.colors.gopayMedium};
            --gopay-high: ${color.colors.gopayHigh};

            --modal-gopay-default: ${color.colors.modalGopayDefault};
            --modal-gopay-low: ${color.colors.modalGopayLow};
            --modal-gopay-medium: ${color.colors.modalGopayMedium};
            --modal-gopay-high: ${color.colors.modalGopayHigh};

            --blibli-default: ${color.colors.blibliDefault};
            --blibli-low: ${color.colors.blibliLow};
            --blibli-medium: ${color.colors.blibliMedium};
            --blibli-high: ${color.colors.blibliHigh};

            --modal-blibli-default: ${color.colors.modalBlibliDefault};
            --modal-blibli-low: ${color.colors.modalBlibliLow};
            --modal-blibli-medium: ${color.colors.modalBlibliMedium};
            --modal-blibli-high: ${color.colors.modalBlibliHigh};

            --primary-skeleton: ${color.primarySkeleton};
            --gradient-skeleton: ${color.gradientSkeleton};
           
        }
  `;
    const headTag = document.getElementsByTagName('head')[0];

    // @ set css to variable modal
    headTag.appendChild(styleTag);

    // add to global document atttribute
    document.documentElement.setAttribute('data-theme', themeVal);
  }, []);

  // sync between latest theme mode by get value from localStorage
  useEffect(() => {
    const darkMode = window.localStorage.getItem('bibit-theme');
    if (!darkMode) return handleSetTheme('light');

    handleSetTheme(darkMode as ThemeTypes);
  }, [handleSetTheme]);

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        setTheme: handleSetTheme,
      }}
    >
      <Provider theme={theme}>
        {/* <GlobalStyle /> */}
        {children}
      </Provider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
