import { combineReducers, Action } from 'redux';
import bankPicker from '../features/common/bankPicker.reducer';
import registerAccountForm from '../features/registration/registerAccountForm.reducer';
import revRegisterAccountForm from '../features/registration/revRegisterAccountForm.reducer';
import reksadanaPage from '../features/reksadana/reksadanaPage.reducer';
import sellModal from '../features/sell/sellModal.reducer';
import orderDetail from '../features/orders/IndexOrderDetail/orderDetail.reducer';
import portfolioPage from '../features/portfolio/portfolioPage.reducer';
import goalSettings from '../features/common/modals/PortfolioGoalSettingsModals/goalSettings.reducer';
import entities from '../entities';
import discoverPage from '../features/discover/discoverPage.reducer';
import errorModal from '../features/errors/errorModal.reducer';
import toast from '../features/common/toast/toast.reducer';
import settingIndex from '../features/profile/setting/SettingIndex.reducer';
import settingBankAccount from 'features/profile/setting/pages/SettingBankAccount/IndividualSettingBankAccount/SettingBankAccount.reducer';
import changeBankAccount from '../features/profile/setting/ChangeBankAccount.reducer';
import correctEmail from '../features/profile/setting/CorrectEmail.reducer';
import edd from '../features/edd/edd.reducer';
import ktp from '../features/ktp/ktp.reducer';
import bankaccount from '../features/bankaccount/bankaccount.reducer';
import bankaccountselfie from '../features/bankaccount/bankaccountselfie.reducer';
import recurring from '../features/recurring/recurring.reducer';
import changeBankPage from '../features/changebank/changeBankPage.reducer';
import compare from 'features/compare/compare.reducer';
import { ReksaDanaEntitiesState, ReksaDana } from 'entities/reksadana.reducer';
import login from 'features/login/login.reducer';
import { ThunkAction } from 'redux-thunk';
import gopay from 'features/gopay/gopay.reducer';
import { TPortfolioItem } from 'features/productswitch/types';
import { InvestmentManager } from 'features/discover/types';
import { SortByType } from 'services/reksadana.type';

export interface ReduxRootState {
  loginForm: {
    phone: string;
    code: string;
    is_fetching: boolean;
    err: string;
    done: boolean;
    err_form?: {
      msg: string;
    };
    otpMethod: string;
  };
  entities: {
    reksadana: ReksaDanaEntitiesState;
    user: {
      profile: {
        preference: {
          syariah: boolean;
        };
        birth_date: any;
      };
    };
    portfolio: {
      items: {
        byId: {
          [symbol: string]: TPortfolioItem;
        };
      };
    };
  };
  discoverPage: {
    loading: boolean;
    result: ReksaDana[];
    hasMore: boolean;
    page: number;
    sortBy: SortByType;
    sort: string;
    sortPeriod: string;
    sortTerapkan: boolean;
    investmentManagers: InvestmentManager[];
  };
  compare: {
    productCompare: string[];
  };
  recurring: {
    modalRestrictRecurring: false;
  };
  portfolioPage: {
    pageCategoryId: string;
    templateId: number;
    categoryTitle: string;
    isGoal: boolean;
    isRobo: boolean;
  };
}

const rootReducers = combineReducers({
  settingIndex,
  changeBankAccount,
  correctEmail,
  settingBankAccount,
  entities,
  bankPicker,
  registerAccountForm,
  revRegisterAccountForm,
  reksadanaPage,
  sellModal,
  orderDetail,
  portfolioPage,
  goalSettings,
  discoverPage,
  errorModal,
  edd,
  ktp,
  bankaccount,
  bankaccountselfie,
  recurring,
  toast,
  changeBankPage,
  compare,
  gopay,
  /**
   * Login flow state
   */
  login,
});

export default rootReducers;

export type TRootReducers = ReturnType<typeof rootReducers>;

/**
 * Typing for Bibit UI redux thunk
 * @see https://redux.js.org/recipes/usage-with-typescript#usage-with-redux-thunk
 */
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  TRootReducers,
  unknown,
  Action<string>
>;
