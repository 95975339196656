import React, { useEffect } from 'react';
import { SkeletonLoading } from 'features/common/components';
import Modal from 'features/common/components/Modal';
import useProfileRisk from 'features/profile/hooks/useProfileRisk';
import styles from 'features/institution/components/BibitInstitutionSwitchAccount/BibitInstitutionSwithAccount.module.css';
import { Text } from '@bibitid/uikit';
import CheckImage from 'assets/images/login/check.svg';
import OrganizationIcon from 'assets/images/bibit-institusi/OrganizationIcon/OrganizationIcon';
import { useAuth, useProfileData } from 'hooks';
import { InstitutionInformation } from 'features/profile/types';
import { useStorageCurrentInstutiton } from 'features/institution/hooks';
import {
  removeCurrentInstitution,
  setCurrentInstitution,
} from 'features/institution/utils';
import queryClient from 'network/queryClient';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openToastMessage } from 'features/common/toast/toast.reducer';
import { setIsRoboReducer } from 'features/portfolio/portfolioPage.reducer';
import Analytics from 'utils/Analytics';
import useTheme from 'hooks/useTheme';
import classNames from 'classnames';
import { Plus } from 'react-feather';
import { useFetchInstitutionStats } from 'features/institution/networks/service';
import { checkIfLeadsExpired } from 'features/institution/utils/checkIfLeadsExpired';
import { useFlag } from 'utils/feature-flag';

type BibitInstitutionSwitchAccountModalProps = {
  visible: boolean;
  onClose: () => void;
  onSwitch?: () => void;
};

/**
 * Modal when user want to switch account from profile or long press the profile in tab bar.
 */
const BibitInstitutionSwitchAccountModal = ({
  visible,
  onClose,
  onSwitch,
}: BibitInstitutionSwitchAccountModalProps) => {
  const {
    getInvestorCategoryImageModal,
    roboRecommendationFetching,
    roboRecommendationLoading,
  } = useProfileRisk();

  const { isLogin: isLoggedIn } = useAuth();

  // get user profile
  const { data: userData } = useProfileData(isLoggedIn);
  const profileDetail = userData?.data?.data;

  const userHasVerified = userData?.data?.data?.user?.status === 4;
  const emailHasVerified = userData?.data?.data?.validemail === 1;

  // get `institution account only` data
  const institutionAccOnlyFF = useFlag('web_institution_without_individual');
  const isIndividualDummyAccount = profileDetail?.user?.status === 5;
  const isRegisteredAsInstiAccountOnly =
    !!profileDetail?.preference?.is_registered_as_institutional_account_only;
  const isInstitutionAccountOnly =
    isIndividualDummyAccount && isRegisteredAsInstiAccountOnly;

  const { data: currentInstitution } = useStorageCurrentInstutiton();
  const history = useHistory();
  const institutions = userData?.data?.data?.institutions;
  const user = userData?.data?.data?.user;
  const dispatch = useDispatch<any>();
  const { theme } = useTheme();

  const leadsData = userData?.data.data?.institution_lead;
  const leadsCreatedAt = leadsData?.lead_created_at || '';

  const { data: statsResponse } = useFetchInstitutionStats();
  const statsData = statsResponse?.data?.data;
  const registerStatus = statsData?.status;

  const leadsHasExpired = checkIfLeadsExpired(leadsCreatedAt);

  /**
   * Show register button when:
   * - user & email has been verified
   * - and user doesn't input institution leads or form leads expired
   * - and register status institution is undefined or 0
   */
  const showRegisterButton =
    userHasVerified &&
    emailHasVerified &&
    (!leadsData || leadsHasExpired) &&
    !registerStatus;

  /**
   * Show toggle switch into individual account when user have individual account
   */
  const showSwitchIntoIndividualAccount = institutionAccOnlyFF
    ? !isInstitutionAccountOnly
    : true;

  const showSkeletonLoading =
    roboRecommendationFetching || roboRecommendationLoading;

  useEffect(() => {
    if (visible) {
      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'profile',
          view: 'account_options',
        },
      });
    }
  }, [visible]);

  const onSwitchProfile = (institutionId?: number, switchFunc?: () => void) => {
    // handling not able to click if profile is the same with current profile
    if (currentInstitution?.institution_id === institutionId) {
      return;
    }

    Analytics.logEventAction({
      eventName: 'institution_profile_action',
      parameter: {
        action: 'choose_account',
        context: 'profile.account_options',
        trigger: 'click',
        data: {
          account_type: institutionId ? 'institution' : 'individual',
        },
      },
    });

    // reset isRobo flag in reducer
    dispatch(setIsRoboReducer(false));

    if (institutionId) {
      setCurrentInstitution(institutionId);
    } else {
      removeCurrentInstitution();
    }

    if (typeof switchFunc === 'function') {
      switchFunc();
    }

    queryClient.invalidateQueries(['Product Offering List']);

    history.push('/');

    setTimeout(() => {
      dispatch(
        openToastMessage(
          institutionId
            ? 'Beralih ke akun bisnis.'
            : 'Beralih ke akun pribadi.',
          'blacksuccess',
          true,
          '',
          '',
          true,
          () => handleTooltipOnClose(institutionId)
        )
      );
    }, 800);
  };

  const handleTooltipOnClose = (institutionId?: number) => {
    Analytics.logEventAction({
      eventName: 'institution_profile_action',
      parameter: {
        action: 'close_toaster_switch_account',
        context: institutionId ? 'institution.index' : 'home.index',
        trigger: 'click',
        data: {
          account_type: institutionId ? 'institution' : 'individual',
        },
      },
    });
  };

  const handleOnClose = () => {
    Analytics.logEventAction({
      eventName: 'institution_profile_action',
      parameter: {
        action: 'close',
        context: 'profile.account_options',
        trigger: 'click',
        data: {
          view: 'account_options',
        },
      },
    });
    onClose && onClose();
  };

  const handleClickRegister = () => {
    history.push('/institution/registration/contact');
  };

  return (
    <Modal
      showClose={true}
      visible={visible}
      title='Switch Akun'
      onClose={handleOnClose}
    >
      <div className={styles['modal-switch-account-wrapper']}>
        {showSwitchIntoIndividualAccount && (
          <div
            className={styles['user-profile-wrapper']}
            key={user?.id}
            onClick={() => onSwitchProfile(undefined, onSwitch)}
          >
            {showSkeletonLoading ? (
              <SkeletonLoading
                width={52}
                height={54}
                className='border-radius-rounded margin-right-15'
              />
            ) : (
              <div
                className={classNames(styles['user-profile-image'], {
                  [styles['user-profile-image-dark']]: theme === 'dark',
                })}
              >
                <img
                  src={getInvestorCategoryImageModal()}
                  alt='risk investor category'
                />
              </div>
            )}

            <div className={styles['user-profile-detail']}>
              <Text
                htmlTag='p'
                type='body2'
                className={styles['text-title-user-profile-detail']}
              >
                {user?.fullname}
              </Text>
              <Text
                htmlTag='p'
                type='caption1'
                className={styles['text-user-profile-detail']}
              >
                Akun Pribadi
              </Text>
            </div>
            {!currentInstitution?.institution_id && (
              <div className={styles['user-profile-active-badge']}>
                <img src={CheckImage} alt='Active account' />
              </div>
            )}
          </div>
        )}
        {Array.isArray(institutions) &&
          institutions.map((institution: InstitutionInformation) => {
            return (
              <div
                className={styles['user-profile-wrapper']}
                key={institution.id}
                onClick={() => onSwitchProfile(institution.id, onSwitch)}
              >
                {showSkeletonLoading ? (
                  <SkeletonLoading
                    width={52}
                    height={54}
                    className='border-radius-rounded margin-right-15'
                  />
                ) : (
                  <div className={styles['user-profile-image']}>
                    <OrganizationIcon alt='risk investor category' />
                  </div>
                )}

                <div className={styles['user-profile-detail']}>
                  <Text
                    htmlTag='p'
                    type='body2'
                    className={styles['text-title-user-profile-detail']}
                  >
                    {institution.name}
                  </Text>
                  <Text
                    htmlTag='p'
                    type='caption1'
                    className={styles['text-user-profile-detail']}
                  >
                    Akun Bisnis
                  </Text>
                </div>
                {currentInstitution?.institution_id === institution.id && (
                  <div className={styles['user-profile-active-badge']}>
                    <img src={CheckImage} alt='Active account' />
                  </div>
                )}
              </div>
            );
          })}

        {/* if there's any registration going on, don't show this button  */}
        {showRegisterButton && (
          <div
            className={styles['add-new-institution']}
            onClick={handleClickRegister}
          >
            <div>
              <Plus size={22} color='#00AB6B' strokeWidth={2} />
            </div>
            <Text htmlTag='p' type='body2' color='green50'>
              Tambah Perusahaan Baru
            </Text>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BibitInstitutionSwitchAccountModal;
