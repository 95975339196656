/**
 * List of RestAPI related to s3
 */
const listApi = {
  signUploadS3: '/tools/files/uploads',
  signDownloadS3: '/tools/files/signs',
  triggerOCR: '/tools/files/ocr',
};

export default listApi;
