import compareVersions from 'compare-versions';

const getCurrentVersion = () => window?.VERSION || window?.document?.VERSION;

const MINIMUM_ANDROID_VERSION = '3.45.0';

const MINIMUM_IOS_VERSION = '4.39.0';

export const nativeSupportInstitution = () => {
  const currentVersion = getCurrentVersion();

  if (!currentVersion) return false;

  const systemName = window?.systemName || window?.document?.systemName;

  if (systemName === 'ios') {
    return compareVersions.compare(currentVersion, MINIMUM_IOS_VERSION, '>=');
  }
  return compareVersions.compare(currentVersion, MINIMUM_ANDROID_VERSION, '>=');
};
