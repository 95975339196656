import React, {
  SyntheticEvent,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';
import { CurrentUserPreference } from 'features/profile/types';
import { usePreferenceMutation } from 'features/profile/network';
import { TTooltipKey } from '../constants/indes';

interface IAmountBlurredContextState {
  isAmountBlurred: boolean;
  userPreference: CurrentUserPreference;
  setAmountBlurred: React.Dispatch<React.SetStateAction<boolean>>;
  handleBlurredAmount: (event: SyntheticEvent<any>) => void;
  hideTooltip: (key: TTooltipKey) => void;
  isShowAmount: boolean;
  isHideAmount: boolean;
}

export const AmountBlurredContextState = createContext<
  IAmountBlurredContextState | undefined
>(undefined);

export const AmountBlurredProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [isAmountBlurred, setAmountBlurred] = useState(false);
  const [onFadeAmount, setOnFadeAmount] = useState(false);

  const { mutateAsync: preferenceMutationAsync } = usePreferenceMutation();

  // Get auth context from hook
  const { isLogin: isLoggedIn } = useAuth();
  const { data: dataProfile, isLoading: isLoadingProfileData } =
    useProfileData(isLoggedIn);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  const userPreference = profileDetail?.preference ?? {};

  useEffect(() => {
    const statusBlurred = userPreference?.portoBlurredStatus ?? 0;
    setAmountBlurred(statusBlurred === 0 ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProfileData]);

  useEffect(() => {
    if (onFadeAmount) {
      setTimeout(() => {
        setOnFadeAmount(false);
      }, 500);
    }
  }, [onFadeAmount]);

  /**
   * Change Preference to Blurred Portfolio Nominal
   */
  const handleChangeStatusPortoBlurred = () => {
    const newPreference = userPreference?.portoBlurredStatus === 1 ? 0 : 1;

    // Toggle or Post update preference to API
    preferenceMutationAsync({ portoBlurredStatus: newPreference });
  };

  const hideTooltip = (key: TTooltipKey) => {
    preferenceMutationAsync({
      show_hide_amount_tooltip: {
        ...userPreference?.show_hide_amount_tooltip,
        [key]: 0,
      },
    });
  };

  const handleBlurredAmount = (event: SyntheticEvent<any>) => {
    event?.stopPropagation();

    setOnFadeAmount(true);

    handleChangeStatusPortoBlurred();

    setAmountBlurred(!isAmountBlurred);
  };

  const contextState = {
    isAmountBlurred,
    userPreference,
    setAmountBlurred,
    handleBlurredAmount,
    hideTooltip,
    isShowAmount: onFadeAmount && !isAmountBlurred,
    isHideAmount: onFadeAmount && isAmountBlurred,
  };

  return (
    <AmountBlurredContextState.Provider value={contextState}>
      {children}
    </AmountBlurredContextState.Provider>
  );
};

export const useAmountBlurred = () => {
  const context = useContext(AmountBlurredContextState);

  if (context === undefined) {
    throw new Error(
      'useAmountBlurred must be used within a AmountBlurredProvider'
    );
  }

  return context;
};
