import React from 'react';
import LazyModal from 'features/common/LazyModal';

const SbnInfoModal = React.lazy(() => import('./SbnInfoModal'));

interface Props {
  visibleModal: boolean;
  onClose?: () => void;
}

const SbnInfoModalLazy: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const visible = props.visibleModal;
  return <LazyModal visible={visible} component={SbnInfoModal} {...props} />;
};

export default SbnInfoModalLazy;
