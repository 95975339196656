/** API Endpoints for Bibit Premium */
const endpoints = {
  premiumStats: '/premiums/stats',
  fetchPremiumDetails: '/premiums',
  readPremiumNotification: (id: string | number) => `/notifications/${id}/read`,
  getPremiumTransactionStats: '/premiums/orders/stats',
  getPremiumTransactionDetail: (wstrx_code: string) =>
    `/premiums/orders/${wstrx_code}`,
  approvePremiumTransaction: '/premiums/orders/approve',
  cancelPremiumTransaction: '/premiums/orders/cancel',
  exitPremiumReasons: '/premiums/exit-reasons',
  getPremiumFeedbackOptions: '/premiums/feedbacks/options',
  getPremiumFeedbackQuotas: '/premiums/feedbacks/quotas',
  submitPremiumFeedback: '/premiums/feedbacks',
  updatePremiumSetting: '/premiums/setting',
};

export default endpoints;
