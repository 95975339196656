import { randomString } from 'utils/stringHelper';
import { postData } from '../Parent';
import { isInsideNativeContainer } from 'utils/validator';

export const postDataSetAppBottomBarColor = (
  backgroundColor: string,
  foreGround: string
) => {
  const nativeMobile = isInsideNativeContainer();
  if (!nativeMobile) return; // do nothing in NON-Native

  const id = randomString(5);
  postData(`setAppBottomBarColor_${id}`, {
    fn: 'setAppBottomBarColor',
    data: {
      backgroundColor: backgroundColor,
      foregroundColor: foreGround,
    },
  });
};
