import type { ThemeColor } from './mapped-color.type';

const darkColor: ThemeColor = {
  // Text colors
  textPrimary: '#cbcbcb',
  textSecondary: '#858585',
  textTertiary: '#636363',
  textWhite: '#ffffff',

  // Surface Colors
  surface: '#121212',
  surfaceGray: '#0d0d0d',
  highlight: '#1F1F1F',

  // Border
  border: '#212121',

  // Divider
  dividerThin: '#1a1a1a',
  dividerThick: '#0d0d0d',

  //Skeleton Color
  primarySkeleton: '#2d2d2d',
  gradientSkeleton: '#313131',

  // Different color when under modal and dark mode
  modal: {
    surface: '#242424',
    surfaceGray: '#2B2B2B',
    highlight: '#2B2B2B',
    border: '#343232',
    dividerThin: '#2E2E2E',
    dividerThick: '#1F1F1F',
  },

  // Icon Color
  iconDefault: '#7D7D7D',

  // Semantic Color
  inactive: '#4d4d4d',
  inactiveButton: '#4d4d4d',

  // Bibit Color
  colors: {
    greenDefault: '#00AB6B',
    greenHigh: '#1e4336',
    greenMedium: '#163228',
    greenLow: '#152e24',
    tealDefault: '#00b897',
    tealHigh: '#1c3f3a',
    tealMedium: '#142e29',
    tealLow: '#132a26',
    oliveDefault: '#96b342',
    oliveHigh: '#2e3518',
    oliveMedium: '#212711',
    oliveLow: '#1f2410',
    peachDefault: '#dd7874',
    peachHigh: '#492a27',
    peachMedium: '#321d1a',
    peachLow: '#321d1b',
    redDefault: '#ee6363',
    redHigh: '#492727',
    redMedium: '#341D1D',
    redLow: '#321b1b',
    periwinkleDefault: '#8f86f9',
    periwinkleHigh: '#333050',
    periwinkleMedium: '#26243d',
    periwinkleLow: '#232136',
    violetDefault: '#9e7bdb',
    violetHigh: '#352a46',
    violetMedium: '#2a2339',
    violetLow: '#282136',
    magentaDefault: '#d157bd',
    magentaHigh: '#43233d',
    magentaMedium: '#351d31',
    magentaLow: '#2e192b',
    cobaltDefault: '#2798e7',
    cobaltHigh: '#243947',
    cobaltMedium: '#1e2d39',
    cobaltLow: '#182935',
    blueDefault: '#15A4C1',
    blueHigh: '#244247',
    blueMedium: '#1c3236',
    blueLow: '#182c2f',
    navyDefault: '#5e8ded',
    navyHigh: '#243147',
    navyMedium: '#1b2637',
    navyLow: '#192333',
    brownDefault: '#d59d58',
    brownHigh: '#493627',
    brownMedium: '#382d1e',
    brownLow: '#32271b',
    orangeDefault: '#ffab44',
    orangeHigh: '#433523',
    orangeMedium: '#352a1c',
    orangeLow: '#2f2519',
    yellowDefault: '#d5b158',
    yellowHigh: '#3c3420',
    yellowMedium: '#352e1d',
    yellowLow: '#2f2819',
    goldDefault: '#BF9B30',
    goldHigh: '#3B331C',
    goldMedium: '#312A17',
    goldLow: '#292414',
    //Modal Color
    modalGreenDefault: '#00AB6B',
    modalGreenHigh: '#152E24',
    modalGreenMedium: '#163228',
    modalGreenLow: '#1E4336',
    modalTealDefault: '#00b897',
    modalTealHigh: '#132a26',
    modalTealMedium: '#142e29',
    modalTealLow: '#1C3F3A',
    modalOliveDefault: '#96b342',
    modalOliveHigh: '#1f2410',
    modalOliveMedium: '#212711',
    modalOliveLow: '#2E3518',
    modalPeachDefault: '#dd7874',
    modalPeachHigh: '#321d1b',
    modalPeachMedium: '#321d1a',
    modalPeachLow: '#492A27',
    modalRedDefault: '#ee6363',
    modalRedHigh: '#321b1b',
    modalRedMedium: '#4d4d4d',
    modalRedLow: '#492727',
    modalPeriwinkleDefault: '#8f86f9',
    modalPeriwinkleHigh: '#232136',
    modalPeriwinkleMedium: '#26243d',
    modalPeriwinkleLow: '#333050',
    modalVioletDefault: '#9e7bdb',
    modalVioletHigh: '#282136',
    modalVioletMedium: '#2a2339',
    modalVioletLow: '#352A46',
    modalMagentaDefault: '#d157bd',
    modalMagentaHigh: '#2e192b',
    modalMagentaMedium: '#351d31',
    modalMagentaLow: '#43233D',
    modalCobaltDefault: '#2798e7',
    modalCobaltHigh: '#182935',
    modalCobaltMedium: '#1e2d39',
    modalCobaltLow: '#243947',
    modalBlueDefault: '#15a4c1',
    modalBlueHigh: '#182c2f',
    modalBlueMedium: '#1c3236',
    modalBlueLow: '#244247',
    modalNavyDefault: '#5e8ded',
    modalNavyHigh: '#192333',
    modalNavyMedium: '#1b2637',
    modalNavyLow: '#243147',
    modalBrownDefault: '#d59d58',
    modalBrownHigh: '#32271b',
    modalBrownMedium: '#382d1e',
    modalBrownLow: '#493627',
    modalOrangeDefault: '#ffab44',
    modalOrangeHigh: '#2f2519',
    modalOrangeMedium: '#352a1c',
    modalOrangeLow: '#433523',
    modalYellowDefault: '#d5b158',
    modalYellowHigh: '#2f2819',
    modalYellowMedium: '#352e1d',
    modalYellowLow: '#3C3420',
    modalGoldDefault: '#BF9B30',
    modalGoldHigh: '#292414',
    modalGoldMedium: '#312A17',
    modalGoldLow: '#3B331C',
    //FR Background
    FRBackground: '#1F4D6D',
    FRSkeleton: '#1A3F5B',
    //Jago Color
    jagoDefault: '#FDAF27',
    jagoHigh: '#543F1B',
    jagoMedium: '#453313',
    jagoLow: '#352A15',
    //Jago Modal Color
    modalJagoDefault: '#FDAF27',
    modalJagoHigh: '#352A15',
    modalJagoMedium: '#453313',
    modalJagoLow: '#543F1B',
    //Jago Syariah Color,
    jagoSyariahDefault: '#72307D',
    jagoSyariahHigh: '#3D2A3F',
    jagoSyariahMedium: '#2E2030',
    jagoSyariahLow: '#2A182C',
    //Jago Syariah Modal Color,
    modalJagoSyariahDefault: '#72307D',
    modalJagoSyariahHigh: '#2A182C',
    modalJagoSyariahMedium: '#2E2030',
    modalJagoSyariahLow: '#3D2A3F',
    //Gopay Color
    gopayDefault: '#02B1D7',
    gopayHigh: '#0C3944',
    gopayMedium: '#0F2C39',
    gopayLow: '#0F292F',
    //Gopay Modal Color
    modalGopayDefault: '#02B1D7',
    modalGopayHigh: '#0F292F',
    modalGopayMedium: '#0F2C39',
    modalGopayLow: '#0C3944',
    //Blibli Color
    blibliDefault: '#0098DB',
    blibliHigh: '#2E3518',
    blibliMedium: '#0F2C39',
    blibliLow: '#0F2630',
    //Blibli Modal Color
    modalBlibliDefault: '#0098DB',
    modalBlibliHigh: '0F2630',
    modalBlibliMedium: '#0F2C39',
    modalBlibliLow: '#2E3518',
  },
};

const lightColor: ThemeColor = {
  // Text colors
  textPrimary: '#333',
  textSecondary: '#858585',
  textTertiary: '#9E9E9E',
  textWhite: '#ffffff',

  // Artboard Color
  surface: '#ffffff', // checked
  surfaceGray: '#f7f7f7', // checked
  highlight: '#f7f7f7',

  border: '#ededed', // chekced

  dividerThin: '#f1f1f1', // checked
  dividerThick: '#f7f7f7', // checked

  primarySkeleton: '#e8e8e8',
  gradientSkeleton: '#ededed',

  modal: {
    surface: '#ffffff', // checked
    surfaceGray: '#f7f7f7', // C
    highlight: '#f7f7f7',
    border: '#ededed', // C
    dividerThin: '#f1f1f1', // C
    dividerThick: '#f7f7f7', // C
  },

  // Icon Color
  iconDefault: '#A6A6A6', // C

  // Semantic Color
  inactive: '#CBCBCB', // C
  inactiveButton: '#B3E6D3', // C

  // Bibit Color
  colors: {
    greenDefault: '#00AB6B', // C
    greenHigh: '#CCEEE1', // C
    greenMedium: '#E1F4ED', // C
    greenLow: '#EBF8F3', // C
    tealDefault: '#00B897', // C
    tealHigh: '#CCF1EA', // C
    tealMedium: '#D6F4EE', // C
    tealLow: '#EBF9F7', // C
    oliveDefault: '#94B041', // C
    oliveHigh: '#E4EBCF', // C
    oliveMedium: '#ECF2DE', // C
    oliveLow: '#F2F6E8', //
    peachDefault: '#d96a65',
    peachHigh: '#f7e1e0',
    peachMedium: '#f9e7e7',
    peachLow: '#fbf0f0',
    redDefault: '#ee4a49',
    redHigh: '#fbdfdf',
    redMedium: '#FCE5E5',
    redLow: '#fdeded',
    periwinkleDefault: '#8277f8',
    periwinkleHigh: '#e0ddfd',
    periwinkleMedium: '#e6e4fd',
    periwinkleLow: '#f2f1fe',
    violetDefault: '#9343c8',
    violetHigh: '#e9d9f4',
    violetMedium: '#eee2f8',
    violetLow: '#f4ecfa',
    magentaDefault: '#991e85',
    magentaHigh: '#ebd2e7',
    magentaMedium: '#f1dfee',
    magentaLow: '#f5e8f3',
    cobaltDefault: '#2479b4',
    cobaltHigh: '#d3e4f0',
    cobaltMedium: '#dceaf4',
    cobaltLow: '#edf4f9',
    blueDefault: '#41A1D8',
    blueHigh: '#d5ebf6',
    blueMedium: '#e1f0f9',
    blueLow: '#eff7fc',
    navyDefault: '#3b5997',
    navyHigh: '#ccd6e7',
    navyMedium: '#e0e5f0',
    navyLow: '#ebeef5',
    brownDefault: '#a97c50',
    brownHigh: '#e9ded3',
    brownMedium: '#f4ede7',
    brownLow: '#f6f1ec',
    orangeDefault: '#f5931b',
    orangeHigh: '#fde7cd',
    orangeMedium: '#fdecd7',
    orangeLow: '#fef4e8',
    yellowDefault: '#ffc330',
    yellowHigh: '#ffecbd',
    yellowMedium: '#fff1d1',
    yellowLow: '#fff4da',
    goldDefault: '#BF9B30',
    goldHigh: '#F0E3BD',
    goldMedium: '#F6EED5',
    goldLow: '#FAF3E1',
    //Modal Color
    modalGreenDefault: '#00AB6B',
    modalGreenHigh: '#cceee1',
    modalGreenMedium: '#e1f4ed',
    modalGreenLow: '#ebf8f3',
    modalTealDefault: '#00b897',
    modalTealHigh: '#ccf1ea',
    modalTealMedium: '#d6f4ee',
    modalTealLow: '#ebf9f7',
    modalOliveDefault: '#94b041',
    modalOliveHigh: '#e4ebcf',
    modalOliveMedium: '#ecf2de',
    modalOliveLow: '#f2f6e8',
    modalPeachDefault: '#d96a65',
    modalPeachHigh: '#f7e1e0',
    modalPeachMedium: '#f9e7e7',
    modalPeachLow: '#fbf0f0',
    modalRedDefault: '#ee4a49',
    modalRedHigh: '#fbdfdf',
    modalRedMedium: '#b3e6d3',
    modalRedLow: '#fdeded',
    modalPeriwinkleDefault: '#8277f8',
    modalPeriwinkleHigh: '#e0ddfd',
    modalPeriwinkleMedium: '#e6e4fd',
    modalPeriwinkleLow: '#f2f1fe',
    modalVioletDefault: '#9343c8',
    modalVioletHigh: '#e9d9f4',
    modalVioletMedium: '#eee2f8',
    modalVioletLow: '#f4ecfa',
    modalMagentaDefault: '#991e85',
    modalMagentaHigh: '#ebd2e7',
    modalMagentaMedium: '#f1dfee',
    modalMagentaLow: '#f5e8f3',
    modalCobaltDefault: '#2479b4',
    modalCobaltHigh: '#d3e4f0',
    modalCobaltMedium: '#dceaf4',
    modalCobaltLow: '#edf4f9',
    modalBlueDefault: '#41a1d8',
    modalBlueHigh: '#d5ebf6',
    modalBlueMedium: '#e1f0f9',
    modalBlueLow: '#eff7fc',
    modalNavyDefault: '#3b5997',
    modalNavyHigh: '#ccd6e7',
    modalNavyMedium: '#e0e5f0',
    modalNavyLow: '#ebeef5',
    modalBrownDefault: '#a97c50',
    modalBrownHigh: '#e9ded3',
    modalBrownMedium: '#f4ede7',
    modalBrownLow: '#f6f1ec',
    modalOrangeDefault: '#f5931b',
    modalOrangeHigh: '#fde7cd',
    modalOrangeMedium: '#fdecd7',
    modalOrangeLow: '#fef4e8',
    modalYellowDefault: '#ffc330',
    modalYellowHigh: '#ffecbd',
    modalYellowMedium: '#fff1d1',
    modalYellowLow: '#fff4da',
    modalGoldDefault: '#BF9B30',
    modalGoldHigh: '#F0E3BD',
    modalGoldMedium: '#F6EED5',
    modalGoldLow: '#FAF3E1',
    //FR Background
    FRBackground: '#55A5DC',
    FRSkeleton: '#3895D6',

    //Jago Color
    jagoDefault: '#FDAF27',
    jagoHigh: '#FFE8C1',
    jagoMedium: '#FFF0D3',
    jagoLow: '#FFF5E5',
    //Jago Modal Color
    modalJagoDefault: '#FDAF27',
    modalJagoHigh: '#FFE8C1',
    modalJagoMedium: '#FFF0D3',
    modalJagoLow: '#FFF5E5',
    //Jago Syariah Color,
    jagoSyariahDefault: '#722B79',
    jagoSyariahHigh: '#FDD4FF',
    jagoSyariahMedium: '#FDE5FE',
    jagoSyariahLow: '#F6EAF6',
    //Jago Syariah Modal Color,
    modalJagoSyariahDefault: '#722B79',
    modalJagoSyariahHigh: '#FDD4FF',
    modalJagoSyariahMedium: '#FDE5FE',
    modalJagoSyariahLow: '#F6EAF6',
    //Gopay Color
    gopayDefault: '#00AED6',
    gopayHigh: '#CCEAF8',
    gopayMedium: '#DEF4FE',
    gopayLow: '#E9F8FF',
    //Gopay Modal Color
    modalGopayDefault: '#00AED6',
    modalGopayHigh: '#CCEAF8',
    modalGopayMedium: '#DEF4FE',
    modalGopayLow: '#E9F8FF',
    //Blibli Color
    blibliDefault: '#0095DA',
    blibliHigh: '#C5E7F8',
    blibliMedium: '#D6EEFA',
    blibliLow: '#E0F2FB',
    //Blibli Modal Color
    modalBlibliDefault: '#0095DA',
    modalBlibliHigh: '#C5E7F8',
    modalBlibliMedium: '#D6EEFA',
    modalBlibliLow: '#E0F2FB',
  },
};

export const mappedColors = {
  dark: darkColor,
  light: lightColor,
} as const;
