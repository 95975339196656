import LazyModal from 'features/common/LazyModal';
import React from 'react';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

const StartInvestmentOptionModalLazy = React.lazy(
  () => import('./StartInvestmentOptionModal')
);

const StartInvestmentOptionModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'investment_option';

  return (
    <LazyModal component={StartInvestmentOptionModalLazy} visible={visible} />
  );
};

export default StartInvestmentOptionModal;
