import list from './index';
import {
  CountryResponse,
  ProvinceResponse,
  CityResponse,
  OccupationResponse,
  OccupationSectorResponse,
} from './tools.type';
import http from 'core/http';

/**
 * GET: List Bank available
 */
export const getListBank = () => {
  return http.get(list.bankList);
};

/**
 * GET: List country
 */
export const getListCountry = (): CountryResponse => {
  return http.get(list.countryList);
};

/**
 * GET: List Province by Country ID
 */
export const getListProvince = (country_id: number): ProvinceResponse => {
  return http.get(list.provinceList, { country_id });
};

/**
 * GET: List City By Province
 */
export const getListCity = (province_id: number): CityResponse => {
  return http.get(list.cityList, { province_id });
};

/**
 * GET: List religion
 */
export const getListReligion = () => {
  return http.get(list.religionList);
};

/**
 * GET: List City by Country
 * @param {string} selectedCountry
 * @param {string} value
 */
export const getListCityByCountry = ({
  page = 1,
  limit = 10,
  selectedCountry,
  value,
}: {
  page: number;
  selectedCountry: string;
  limit?: number;
  value?: string;
}): CityResponse => {
  return http.get(`${list.cityListByCountry(selectedCountry)}`, {
    keyword: value,
    page,
    limit,
  });
};

export const getListOccupation = (): OccupationResponse => {
  return http.get(list.occupationList);
};

export const getListOccupationSector = (): OccupationSectorResponse => {
  return http.get(list.occupationSectorList);
};
