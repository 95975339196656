import React, { FC } from 'react';
import {
  InformationCreatingRoboModal,
  ChooseInvestmentTargetModal,
} from './lazy';

const CreatePortfolioRoboModal: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <InformationCreatingRoboModal />
      <ChooseInvestmentTargetModal />
    </>
  );
};

export default CreatePortfolioRoboModal;
