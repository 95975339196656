import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import getSafely from 'utils/safely';
import {
  handleResponseErrorCase,
  handleResponseGeneral,
} from 'utils/http-response';
import {
  addReksadanaEntry,
  addReksadanaProspectus,
  addReksadanaFFS,
} from '../../entities/reksadana.reducer';
import { addInvestmentManagerEntry } from '../../entities/investmentManager.reducer';
import { addCustodianEntry } from '../../entities/custodian.reducer';
import {
  fetchProductById,
  getProductFFS,
  getProductProspectus,
} from 'services/product';
import { getSimulationBySymbol } from 'services/reksadana';
import { getProductHasTransaction } from 'services/transaction';

const defaultState = {
  loading: true,
  availableVoucherLoading: false,
  availableVouchers: [],
  fundId: null,
  err: null,
  chartPeriod: '1Y', // 1M, 3M, 1Y, 3Y, 5Y
  historicalNAVData: [],
  hasTransaction: false,
};

const [
  reksadanaPageLoading,
  reksadanaPageLoaded,
  reksadanaPageChangePeriod,
  reksadanaPageReset,
  reksadanaPageVoucherLoading,
  reksadanaPageSetAvailableVouchers,
  reksadanaPageSaveHistoricalNav,
  reksadanaPageSetHasTrasaction,
  reksadanaPageError,
] = [
  'REKSADANA_PAGE_LOADING',
  'REKSADANA_PAGE_GET_SUCCESS',
  'REKSADANA_PAGE_CHANGE_PERIOD',
  'REKSADANA_PAGE_RESET',
  'REKSADANA_PAGE_VOUCHER_LOADING',
  'REKSADANA_PAGE_SET_AVAILABLE_VOUCHERS',
  'REKSADANA_PAGE_SAVE_HISTORICAL_NAV',
  'REKSADANA_PAGE_SET_HAS_TRANSACTION',
  'REKSADANA_PAGE_GET_FAILED',
].map(createAction);

const reksadana = createReducer(
  {
    // Adding a mutual fund data to the fund data catalog
    [reksadanaPageLoading]: (state, payload) => {
      return update(state, {
        loading: { $set: payload.loading },
        err: { $set: defaultState.err },
      });
    },
    [reksadanaPageLoaded]: (state, payload) => {
      return update(state, {
        loading: { $set: false },
        fundId: { $set: payload.symbol },
        err: { $set: defaultState.err },
      });
    },
    [reksadanaPageChangePeriod]: (state, payload) => {
      return update(state, {
        chartPeriod: { $set: payload },
      });
    },
    [reksadanaPageReset]: (state, payload) => {
      return update(state, {
        loading: { $set: defaultState.loading },
        fundId: { $set: defaultState.fundId },
        err: { $set: defaultState.err },
        chartPeriod: { $set: defaultState.chartPeriod },
        availableVouchers: { $set: defaultState.availableVouchers },
      });
    },
    [reksadanaPageVoucherLoading]: (state, payload) => {
      return update(state, {
        availableVoucherLoading: { $set: payload },
      });
    },
    [reksadanaPageSetAvailableVouchers]: (state, payload) => {
      return update(state, {
        availableVouchers: { $set: payload },
      });
    },
    [reksadanaPageSaveHistoricalNav]: (state, payload) => {
      return update(state, {
        historicalNAVData: { $set: payload },
      });
    },
    [reksadanaPageSetHasTrasaction]: (state, payload) => {
      return update(state, {
        hasTransaction: { $set: payload },
      });
    },
    [reksadanaPageError]: (state, payload) => {
      return update(state, {
        loading: { $set: false },
        err: { $set: payload.err },
      });
    },
  },
  defaultState
);

// Selector
export const getReksadanaPageDataById = (state, fundId) =>
  getSafely(['entities', 'reksadana', 'byId', fundId], state);

export const getProductVoucherDetails = (state) => {
  return state.reksadanaPage.availableVouchers.map(
    (item) => state.entities.vouchers.byCode[item]
  );
};

// Exported actions
export { reksadanaPageLoading, reksadanaPageReset, reksadanaPageChangePeriod };

/**
 * Getting reksadana page data
 * @param {String} reksadanaId
 * @param {boolean} choosenHistory // flag for choosen product to seek history transaction user
 */
export function getReksadanaPageData(reksadanaId) {
  return (dispatch) => {
    dispatch(reksadanaPageLoading({ loading: true }));
    return fetchProductById(reksadanaId)
      .then((res) => {
        const productData = getSafely(['data', 'data'], res);
        dispatch(addReksadanaEntry(productData));
        if (productData.investment_manager) {
          dispatch(addInvestmentManagerEntry(productData.investment_manager));
        }
        if (productData.custodian_bank) {
          dispatch(addCustodianEntry(productData.custodian_bank));
        }

        return dispatch(reksadanaPageLoaded(productData));
      })
      .catch((err) => {
        const { type = '' } = err?.response?.data ?? {};

        return dispatch(
          reksadanaPageError({
            err: type,
          })
        );
      });
  };
}

export function getReksadanaProspectusFFS(reksadanaId) {
  return (dispatch) => {
    const getProspectusPromise = getProductProspectus(reksadanaId);
    const getFFSPromise = getProductFFS(reksadanaId);
    return Promise.all([getProspectusPromise, getFFSPromise])
      .then((responses) => {
        const [prospectusResponse, ffsResponse] = responses;
        const prospectus = prospectusResponse.data.data;
        const ffs = ffsResponse.data.data;

        dispatch(
          addReksadanaProspectus({
            symbol: reksadanaId,
            prospectus,
          })
        );
        dispatch(
          addReksadanaFFS({
            symbol: reksadanaId,
            factsheets: ffs,
          })
        );
      })
      .catch(() => {});
  };
}

/**
 * Get historical nav of a mutual fund symbol by period
 * @param {String} symbol
 * @param {String} period
 */
export function getMutualFundHistoricalNav(symbol) {
  return (dispatch) => {
    // Getting the simulation data of 10 year immediately
    const req = getSimulationBySymbol(symbol).then((response) => {
      const { data } = handleResponseGeneral(response);
      dispatch(reksadanaPageSaveHistoricalNav(data));
    });

    return handleResponseErrorCase(req, (err) => {});
  };
}

export function getCheckHasTransaction(symbol) {
  return (dispatch) => {
    getProductHasTransaction(symbol)
      .then((res) => {
        const data = getSafely(['data', 'data'], res);

        const haveTrx =
          typeof data?.is_have_transaction === 'undefined'
            ? data
            : data?.is_have_transaction;

        dispatch(reksadanaPageSetHasTrasaction(!!haveTrx));
      })
      .catch(() => {});
  };
}

export function getDataHistoryReksa(symbol) {
  return (dispatch) => {};
}

export default reksadana;
