import React, { useEffect, useState, useCallback } from 'react';
import SplashScreen from 'features/common/BibitLoader/BibitLoader';
import { injectingWindowBrowser } from '../utils';
import { startPredefinedEvent } from 'core/Parser/Stackfunction.constant';
import {
  postData,
  postDataPromise,
  basePostMessage,
} from 'core/Parser/comm/Sender.v3';
import {
  start as receiver3Start,
  removeStart as receiver3Stop,
} from 'core/Parser/comm/Receiver.v3';
import retry from 'utils/retry-func';

interface ContextState {
  postData: typeof postData;
  postDataPromise: typeof postDataPromise;
  basePostMessage: typeof basePostMessage;
}

// Exported react context
export const PostMessageContext = React.createContext<ContextState | undefined>(
  undefined
);

/**
 * - Post Message Context Provider Component
 * @returns - returns context post message context provider with postData value
 */
const PostMessageProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  /**
   * Tell us where reactapp running on
   */
  const [runningOn, setRunningOn] = useState<'web' | 'android' | 'ios'>();

  const checkAppIsReady = useCallback(async () => {
    // Check In Android First through `window.NativeInjection`. if exist we sure that ReactApp is Running on Android
    const androidDevice = !!window?.NativeInjection;
    if (androidDevice) {
      // Find variable android through Javascript Interface
      // See more: https://developer.android.com/guide/webapps/webview#BindingJavaScript
      const androidVariable = window.NativeInjection?.injectVariable();

      // Injection Variable to our window
      injectingWindowBrowser(androidVariable);

      // Dealing with local state
      setRunningOn('android');
      return;
    }

    // Check in IOS. through window.systemName
    // in IOS all variable would injected by Native smoothly
    const iosDevice =
      (window.document?.systemName || window.systemName) === 'ios';

    if (iosDevice) {
      // check PostMessage in IOS is ready
      const iosPostMessageReady = async () =>
        !!window.webkit?.messageHandlers.postMessageListener;

      try {
        // makesure window.webkit?.messageHandlers.postMessageListener; is available
        // so we searching retry 5 time when is not exist
        await retry(iosPostMessageReady, {
          delay: 100,
          maxRetry: 5,
          expectResult: (iosPostMessageReady) => iosPostMessageReady === true,
        });

        // Dealing with local state
        setRunningOn('ios');

        return;
      } catch (error) {
        // TODO: ??
      }
      return;
    }

    setRunningOn('web');
  }, []);

  // Check app is ready or not
  useEffect(() => {
    checkAppIsReady();
  }, [checkAppIsReady]);

  // Listen to windowEventListner
  useEffect(() => {
    if (!runningOn || runningOn === 'web') return;

    // Do thing when in Native
    receiver3Start();
    startPredefinedEvent(postData, basePostMessage);

    return () => {
      receiver3Stop();
    };
  }, [runningOn]);

  return (
    <PostMessageContext.Provider
      value={{
        postData,
        postDataPromise,
        basePostMessage,
      }}
    >
      {/* If runningOn having value, then the Postmessage is ready */}
      {!runningOn ? <SplashScreen /> : children}
    </PostMessageContext.Provider>
  );
};

export default PostMessageProvider;
