import React, { lazy } from 'react';
import { useQuery } from 'react-query';
import http from 'core/http';

import Babyborn1Card from 'assets/images/giftcard/babyborn/babyborn-1-card.svg';
import Babyborn2Card from 'assets/images/giftcard/babyborn/babyborn-2-card.svg';
import Babyborn3Card from 'assets/images/giftcard/babyborn/babyborn-3-card.svg';
import Babyborn5Card from 'assets/images/giftcard/babyborn/babyborn-5-card.svg';
import Birthday1Card from 'assets/images/giftcard/birthday/birthday-1-card.svg';
import Birthday2Card from 'assets/images/giftcard/birthday/birthday-2-card.svg';
import Birthday3Card from 'assets/images/giftcard/birthday/birthday-3-card.svg';
import Congrat1Card from 'assets/images/giftcard/congratulations/congrat-1-card.svg';
import Congrat2Card from 'assets/images/giftcard/congratulations/congrat-2-card.svg';
import Congrat3Card from 'assets/images/giftcard/congratulations/congrat-3-card.svg';
import Congrat4Card from 'assets/images/giftcard/congratulations/congrat-4-card.svg';
import Graduation1Card from 'assets/images/giftcard/graduation/graduation-1-card.svg';
import Graduation2Card from 'assets/images/giftcard/graduation/graduation-2-card.svg';
import Graduation3Card from 'assets/images/giftcard/graduation/graduation-3-card.svg';
import Thankyou1Card from 'assets/images/giftcard/thankyou/thankyou-1-card.svg';
import Thankyou2Card from 'assets/images/giftcard/thankyou/thankyou-2-card.svg';
import Thankyou3Card from 'assets/images/giftcard/thankyou/thankyou-3-card.svg';
import Wedding1Card from 'assets/images/giftcard/wedding/wedding-1-card.svg';
import Wedding2Card from 'assets/images/giftcard/wedding/wedding-2-card.svg';
import Wedding3Card from 'assets/images/giftcard/wedding/wedding-3-card.svg';
import Wedding4Card from 'assets/images/giftcard/wedding/wedding-4-card.svg';
import Christmas1Card from 'assets/images/giftcard/christmas/christmas-1-card.svg';
import Christmas2Card from 'assets/images/giftcard/christmas/christmas-2-card.svg';
import Christmas3Card from 'assets/images/giftcard/christmas/christmas-3-card.svg';
import NewYear1Card from 'assets/images/giftcard/new-year/new-year-1-card.svg';
import NewYear2Card from 'assets/images/giftcard/new-year/new-year-2-card.svg';
import NewYear3Card from 'assets/images/giftcard/new-year/new-year-3-card.svg';
import Imlek1Card from 'assets/images/giftcard/imlek/imlek-1-card.svg';
import Imlek2Card from 'assets/images/giftcard/imlek/imlek-2-card.svg';
import Imlek3Card from 'assets/images/giftcard/imlek/imlek-3-card.svg';
import Imlek4Card from 'assets/images/giftcard/imlek/imlek-4-card.svg';
import Imlek5Card from 'assets/images/giftcard/imlek/imlek-5-card.svg';
import Valentine1Card from 'assets/images/giftcard/valentine/valentine-1-card.svg';
import Valentine2Card from 'assets/images/giftcard/valentine/valentine-2-card.svg';
import Valentine3Card from 'assets/images/giftcard/valentine/valentine-3-card.svg';
import Valentine4Card from 'assets/images/giftcard/valentine/valentine-4-card.svg';
import IdulFitri1Card from 'assets/images/giftcard/idul-fitri/idul-fitri-1-card.svg';
import IdulFitri2Card from 'assets/images/giftcard/idul-fitri/idul-fitri-2-card.svg';
import IdulFitri3Card from 'assets/images/giftcard/idul-fitri/idul-fitri-3-card.svg';
import IdulFitri4Card from 'assets/images/giftcard/idul-fitri/idul-fitri-4-card.svg';
import IdulFitri5Card from 'assets/images/giftcard/idul-fitri/idul-fitri-5-card.svg';
import IdulFitri6Card from 'assets/images/giftcard/idul-fitri/idul-fitri-6-card.svg';
import IdulFitri7Card from 'assets/images/giftcard/idul-fitri/idul-fitri-7-card.svg';
import IdulFitri8Card from 'assets/images/giftcard/idul-fitri/idul-fitri-8-card.svg';
import Anniversary1Card from 'assets/images/giftcard/anniversary/anniversary-1-card.svg';
import Anniversary2Card from 'assets/images/giftcard/anniversary/anniversary-2-card.svg';
import Anniversary3Card from 'assets/images/giftcard/anniversary/anniversary-3-card.svg';
import list from 'services/index';

import LazyLoad from 'features/common/lazyload';
import Storage from 'core/Storage';
import { _showErrorToast } from 'utils';
import get from 'utils/safely';
import {
  handleResponseErrorCase,
  handleResponseGeneral,
} from 'utils/http-response';
import IsEmpty from 'utils/isEmpty';
import { dispatch } from 'store/redux';
import { isJSON } from 'utils/validator';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import { isLinkAjaApplinkSupported } from 'features/linkAja/LinkAjaUtils';
import { useFlag } from 'utils/feature-flag';
import {
  postBuyGiftCardNative,
  postBuyGiftCard,
  postBuyGiftCardV2,
  postBuyGiftCardJagoSNAP,
} from 'features/giftcard/networks/services';
import { buyGiftCardVersion } from 'features/giftcard/utils/BuyGiftCardVersionUtils';
import { useUserInInstitution } from 'features/institution/hooks';

/** Key buy gift card state in localStorage */
const BUY_GIFT_CARD_STORAGE_KEY = 'buyGiftCardRestore';

const TemplateBirthday1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Birthday-1')
);
const TemplateBirthday1 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateBirthday1Component}
    {...props}
  />
);

const TemplateBirthday2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Birthday-2')
);
const TemplateBirthday2 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateBirthday2Component}
    {...props}
  />
);

const TemplateBirthday3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Birthday-3')
);
const TemplateBirthday3 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateBirthday3Component}
    {...props}
  />
);

const TemplateWedding1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Wedding-1')
);
const TemplateWedding1 = (props) => (
  <LazyLoad animationLoading component={TemplateWedding1Component} {...props} />
);

const TemplateWedding2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Wedding-2')
);
const TemplateWedding2 = (props) => (
  <LazyLoad animationLoading component={TemplateWedding2Component} {...props} />
);

const TemplateWedding3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Wedding-3')
);
const TemplateWedding3 = (props) => (
  <LazyLoad animationLoading component={TemplateWedding3Component} {...props} />
);

const TemplateWedding4Component = lazy(() =>
  import('../components/GiftCardsTemplates/Wedding-4')
);
const TemplateWedding4 = (props) => (
  <LazyLoad animationLoading component={TemplateWedding4Component} {...props} />
);

const TemplateGraduation1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Graduation-1')
);
const TemplateGraduation1 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateGraduation1Component}
    {...props}
  />
);

const TemplateGraduation2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Graduation-2')
);
const TemplateGraduation2 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateGraduation2Component}
    {...props}
  />
);

const TemplateGraduation3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Graduation-3')
);
const TemplateGraduation3 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateGraduation3Component}
    {...props}
  />
);

const TemplateBabyBorn1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Babyborn-1')
);
const TemplateBabyBorn1 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateBabyBorn1Component}
    {...props}
  />
);

const TemplateBabyBorn2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Babyborn-2')
);
const TemplateBabyBorn2 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateBabyBorn2Component}
    {...props}
  />
);

const TemplateBabyBorn3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Babyborn-3')
);
const TemplateBabyBorn3 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateBabyBorn3Component}
    {...props}
  />
);

const TemplateBabyBorn5Component = lazy(() =>
  import('../components/GiftCardsTemplates/Babyborn-5')
);
const TemplateBabyBorn5 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateBabyBorn5Component}
    {...props}
  />
);

const TemplateThankyou1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Thankyou-1')
);
const TemplateThankyou1 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateThankyou1Component}
    {...props}
  />
);

const TemplateThankyou2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Thankyou-2')
);
const TemplateThankyou2 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateThankyou2Component}
    {...props}
  />
);

const TemplateThankyou3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Thankyou-3')
);
const TemplateThankyou3 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateThankyou3Component}
    {...props}
  />
);

const TemplateCongratulation1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Congratulation-1')
);
const TemplateCongratulation1 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateCongratulation1Component}
    {...props}
  />
);

const TemplateCongratulation2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Congratulation-2')
);
const TemplateCongratulation2 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateCongratulation2Component}
    {...props}
  />
);

const TemplateCongratulation3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Congratulation-3')
);
const TemplateCongratulation3 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateCongratulation3Component}
    {...props}
  />
);

const TemplateCongratulation4Component = lazy(() =>
  import('../components/GiftCardsTemplates/Congratulation-4')
);
const TemplateCongratulation4 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateCongratulation4Component}
    {...props}
  />
);

const TemplateChristmas1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Christmas-1')
);
const TemplateChristmas1 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateChristmas1Component}
    {...props}
  />
);

const TemplateChristmas2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Christmas-2')
);
const TemplateChristmas2 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateChristmas2Component}
    {...props}
  />
);

const TemplateChristmas3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Christmas-3')
);
const TemplateChristmas3 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateChristmas3Component}
    {...props}
  />
);

const TemplateNewYear1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Newyear-1')
);
const TemplateNewYear1 = (props) => (
  <LazyLoad animationLoading component={TemplateNewYear1Component} {...props} />
);

const TemplateNewYear2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Newyear-2')
);
const TemplateNewYear2 = (props) => (
  <LazyLoad animationLoading component={TemplateNewYear2Component} {...props} />
);

const TemplateNewYear3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Newyear-3')
);
const TemplateNewYear3 = (props) => (
  <LazyLoad animationLoading component={TemplateNewYear3Component} {...props} />
);

const TemplateImlek1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Imlek-1')
);
const TemplateImlek1 = (props) => (
  <LazyLoad animationLoading component={TemplateImlek1Component} {...props} />
);

const TemplateImlek2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Imlek-2')
);
const TemplateImlek2 = (props) => (
  <LazyLoad animationLoading component={TemplateImlek2Component} {...props} />
);

const TemplateImlek3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Imlek-3')
);
const TemplateImlek3 = (props) => (
  <LazyLoad animationLoading component={TemplateImlek3Component} {...props} />
);

const TemplateImlek4Component = lazy(() =>
  import('../components/GiftCardsTemplates/Imlek-4')
);
const TemplateImlek4 = (props) => (
  <LazyLoad animationLoading component={TemplateImlek4Component} {...props} />
);

const TemplateImlek5Component = lazy(() =>
  import('../components/GiftCardsTemplates/Imlek-5')
);
const TemplateImlek5 = (props) => (
  <LazyLoad animationLoading component={TemplateImlek5Component} {...props} />
);

const TemplateIdulfitri1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Idulfitri-1')
);
const TemplateIdulfitri1 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateIdulfitri1Component}
    {...props}
  />
);

const TemplateIdulfitri2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Idulfitri-2')
);
const TemplateIdulfitri2 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateIdulfitri2Component}
    {...props}
  />
);
const TemplateIdulfitri3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Idulfitri-3')
);
const TemplateIdulfitri3 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateIdulfitri3Component}
    {...props}
  />
);
const TemplateIdulfitri4Component = lazy(() =>
  import('../components/GiftCardsTemplates/Idulfitri-4')
);
const TemplateIdulfitri4 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateIdulfitri4Component}
    {...props}
  />
);

const TemplateValentine1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Valentine-1')
);
const TemplateValentine1 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateValentine1Component}
    {...props}
  />
);

const TemplateValentine2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Valentine-2')
);
const TemplateValentine2 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateValentine2Component}
    {...props}
  />
);

const TemplateValentine3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Valentine-3')
);
const TemplateValentine3 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateValentine3Component}
    {...props}
  />
);

const TemplateValentine4Component = lazy(() =>
  import('../components/GiftCardsTemplates/Valentine-4')
);
const TemplateValentine4 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateValentine4Component}
    {...props}
  />
);

const TemplateIdulfitri5Component = lazy(() =>
  import('../components/GiftCardsTemplates/Idulfitri-5')
);
const TemplateIdulfitri5 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateIdulfitri5Component}
    {...props}
  />
);

const TemplateIdulfitri6Component = lazy(() =>
  import('../components/GiftCardsTemplates/Idulfitri-6')
);
const TemplateIdulfitri6 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateIdulfitri6Component}
    {...props}
  />
);
const TemplateIdulfitri7Component = lazy(() =>
  import('../components/GiftCardsTemplates/Idulfitri-7')
);
const TemplateIdulfitri7 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateIdulfitri7Component}
    {...props}
  />
);
const TemplateIdulfitri8Component = lazy(() =>
  import('../components/GiftCardsTemplates/Idulfitri-8')
);
const TemplateIdulfitri8 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateIdulfitri8Component}
    {...props}
  />
);

const TemplateAnniversary1Component = lazy(() =>
  import('../components/GiftCardsTemplates/Anniversary-1')
);
const TemplateAnniversary1 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateAnniversary1Component}
    {...props}
  />
);

const TemplateAnniversary2Component = lazy(() =>
  import('../components/GiftCardsTemplates/Anniversary-2')
);
const TemplateAnniversary2 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateAnniversary2Component}
    {...props}
  />
);
const TemplateAnniversary3Component = lazy(() =>
  import('../components/GiftCardsTemplates/Anniversary-3')
);
const TemplateAnniversary3 = (props) => (
  <LazyLoad
    animationLoading
    component={TemplateAnniversary3Component}
    {...props}
  />
);

export const mapCategoryGiftcard = {
  1: 'Birthday',
  2: 'Wedding',
  3: 'Graduation',
  4: 'Baby Born',
  5: 'Thank You',
  6: 'Congratulations',
  7: 'Christmas',
  8: 'New Year',
  9: 'Imlek',
  10: 'Valentine',
  11: 'Idul Fitri',
  12: 'Anniversary',
};

const mapImageTemplate = {
  birthday: [
    {
      id: '1',
      order: 1,
      name: 'Birthday 1',
      path: Birthday1Card,
      template: TemplateBirthday1,
    },
    {
      id: '2',
      order: 2,
      name: 'Birthday 2',
      path: Birthday2Card,
      template: TemplateBirthday2,
    },
    {
      id: '3',
      order: 3,
      name: 'Birthday 3',
      path: Birthday3Card,
      template: TemplateBirthday3,
    },
  ],
  wedding: [
    {
      id: '1',
      order: 1,
      name: 'Wedding 1',
      path: Wedding1Card,
      template: TemplateWedding1,
    },
    {
      id: '2',
      order: 2,
      name: 'Wedding 2',
      path: Wedding2Card,
      template: TemplateWedding2,
    },
    {
      id: '3',
      order: 3,
      name: 'Wedding 3',
      path: Wedding3Card,
      template: TemplateWedding3,
    },
    {
      id: '4',
      order: 4,
      name: 'Wedding 4',
      path: Wedding4Card,
      template: TemplateWedding4,
    },
  ],
  graduation: [
    {
      id: '1',
      order: 1,
      name: 'graduation 1',
      path: Graduation1Card,
      template: TemplateGraduation1,
    },
    {
      id: '2',
      order: 2,
      name: 'graduation 2',
      path: Graduation2Card,
      template: TemplateGraduation2,
    },
    {
      id: '3',
      order: 3,
      name: 'graduation 3',
      path: Graduation3Card,
      template: TemplateGraduation3,
    },
  ],
  babyborn: [
    {
      id: '1',
      order: 1,
      name: 'baby born 1',
      path: Babyborn1Card,
      template: TemplateBabyBorn1,
    },
    {
      id: '2',
      order: 2,
      name: 'baby born 2',
      path: Babyborn2Card,
      template: TemplateBabyBorn2,
    },
    {
      id: '3',
      order: 3,
      name: 'baby born 3',
      path: Babyborn3Card,
      template: TemplateBabyBorn3,
    },
    {
      id: '4',
      order: 4,
      name: 'baby born 4',
      path: Babyborn5Card,
      template: TemplateBabyBorn5,
    },
  ],
  thankyou: [
    {
      id: '1',
      order: 1,
      name: 'thankyou 1',
      path: Thankyou1Card,
      template: TemplateThankyou1,
    },
    {
      id: '2',
      order: 2,
      name: 'thankyou 2',
      path: Thankyou2Card,
      template: TemplateThankyou2,
    },
    {
      id: '3',
      order: 3,
      name: 'thankyou 3',
      path: Thankyou3Card,
      template: TemplateThankyou3,
    },
  ],
  congratulations: [
    {
      id: '1',
      order: 1,
      name: 'congratulation 1',
      path: Congrat1Card,
      template: TemplateCongratulation1,
    },
    {
      id: '2',
      order: 2,
      name: 'congratulation 2',
      path: Congrat2Card,
      template: TemplateCongratulation2,
    },
    {
      id: '3',
      order: 3,
      name: 'congratulation 3',
      path: Congrat3Card,
      template: TemplateCongratulation3,
    },
    {
      id: '4',
      order: 4,
      name: 'congratulation 4',
      path: Congrat4Card,
      template: TemplateCongratulation4,
    },
  ],
  christmas: [
    {
      id: '1',
      order: 1,
      name: 'christmas 1',
      path: Christmas1Card,
      template: TemplateChristmas1,
    },
    {
      id: '2',
      order: 2,
      name: 'christmas 2',
      path: Christmas2Card,
      template: TemplateChristmas2,
    },
    {
      id: '3',
      order: 3,
      name: 'christmas 3',
      path: Christmas3Card,
      template: TemplateChristmas3,
    },
  ],
  newyear: [
    {
      id: '1',
      order: 1,
      name: 'new year 1',
      path: NewYear1Card,
      template: TemplateNewYear1,
    },
    {
      id: '2',
      order: 2,
      name: 'new year 2',
      path: NewYear2Card,
      template: TemplateNewYear2,
    },
    {
      id: '3',
      order: 3,
      name: 'new year 3',
      path: NewYear3Card,
      template: TemplateNewYear3,
    },
  ],
  imlek: [
    {
      id: '1',
      order: 1,
      name: 'imlek 1',
      path: Imlek1Card,
      template: TemplateImlek1,
    },
    {
      id: '2',
      order: 2,
      name: 'imlek 2',
      path: Imlek2Card,
      template: TemplateImlek2,
    },
    {
      id: '3',
      order: 3,
      name: 'imlek 3',
      path: Imlek3Card,
      template: TemplateImlek3,
    },
    {
      id: '4',
      order: 4,
      name: 'imlek 4',
      path: Imlek4Card,
      template: TemplateImlek4,
      status: 'SHOW',
    },
    {
      id: '5',
      order: 5,
      name: 'imlek 5',
      path: Imlek5Card,
      template: TemplateImlek5,
      status: 'HIDE',
    },
  ],
  valentine: [
    {
      id: '1',
      order: 1,
      name: 'valentine 1',
      path: Valentine1Card,
      template: TemplateValentine1,
    },
    {
      id: '2',
      order: 2,
      name: 'valentine 2',
      path: Valentine2Card,
      template: TemplateValentine2,
    },
    {
      id: '3',
      order: 3,
      name: 'valentine 3',
      path: Valentine3Card,
      template: TemplateValentine3,
    },
    {
      id: '4',
      order: 4,
      name: 'valentine 4',
      path: Valentine4Card,
      template: TemplateValentine4,
      status: 'HIDE',
    },
  ],
  idulfitri: [
    {
      id: '1',
      order: 5,
      name: 'idulfitri 1',
      path: IdulFitri1Card,
      template: TemplateIdulfitri1,
      status: 'HIDE',
    },
    {
      id: '2',
      order: 6,
      name: 'idulfitri 2',
      path: IdulFitri2Card,
      template: TemplateIdulfitri2,
      status: 'HIDE',
    },
    {
      id: '3',
      order: 7,
      name: 'idulfitri 3',
      path: IdulFitri3Card,
      template: TemplateIdulfitri3,
      status: 'HIDE',
    },
    {
      id: '4',
      order: 8,
      name: 'idulfitri 4',
      path: IdulFitri4Card,
      template: TemplateIdulfitri4,
      status: 'HIDE',
    },
    {
      id: '5',
      order: 1,
      name: 'idulfitri 5',
      path: IdulFitri5Card,
      template: TemplateIdulfitri5,
    },
    {
      id: '6',
      order: 2,
      name: 'idulfitri 6',
      path: IdulFitri6Card,
      template: TemplateIdulfitri6,
    },
    {
      id: '7',
      order: 3,
      name: 'idulfitri 7',
      path: IdulFitri7Card,
      template: TemplateIdulfitri7,
    },
    {
      id: '8',
      order: 4,
      name: 'idulfitri 8',
      path: IdulFitri8Card,
      template: TemplateIdulfitri8,
    },
  ],
  anniversary: [
    {
      id: '1',
      order: 1,
      name: 'anniversary 1',
      path: Anniversary1Card,
      template: TemplateAnniversary1,
    },
    {
      id: '2',
      order: 2,
      name: 'anniversary 2',
      path: Anniversary2Card,
      template: TemplateAnniversary2,
    },
    {
      id: '3',
      order: 3,
      name: 'anniversary 3',
      path: Anniversary3Card,
      template: TemplateAnniversary3,
    },
  ],
};

const removeSpace = (str) => str && str.replace(/\s+/g, '');

const lowerCasing = (str) => str && str.toLowerCase();

const transformText = (str) => str && lowerCasing(removeSpace(str));

export function getLabelCategoryByIndex(idx, removeSpacing = true) {
  const label = !!removeSpacing
    ? transformText(mapCategoryGiftcard[idx])
    : mapCategoryGiftcard[idx];
  return !!mapCategoryGiftcard[idx] ? label : '';
}

export function getTemplateImagesByCategory(indexCategory, sort = true) {
  const labelCategory = getLabelCategoryByIndex(indexCategory);
  const images = mapImageTemplate[labelCategory] || [];
  const sortedImages = sort ? images.sort((a, b) => a.order - b.order) : images;
  return sortedImages;
}

export function getTemplateById(
  categoryIdx,
  templateId,
  includeTemplate = false
) {
  const images = getTemplateImagesByCategory(categoryIdx);
  // eslint-disable-next-line
  let template = { ...images.find((e) => e.id == templateId) }; //prevent object mutation

  // delete the template to prevent jsx being passed
  if (includeTemplate !== true) delete template.template;

  return template;
}

export function getSendGiftCards() {
  return new Promise((resolve, reject) => {
    const req = http
      .get(list.giftcardSendList)
      .then((res) => resolve(handleResponseGeneral(res).data));

    handleResponseErrorCase(req, (er) => {
      const { err } = er;
      const message = err;

      reject({ message });
    });
  });
}

export function getReceivedGiftCards() {
  return new Promise((resolve, reject) => {
    const req = http
      .get(list.giftcardSendReceived)
      .then((res) => resolve(handleResponseGeneral(res).data));

    handleResponseErrorCase(req, (er) => {
      const { err } = er;
      const message = err;

      reject({ message });
    });
  });
}

/**
 * POST: validate payment channel limit giftcard
 * @param {string} channel - gopay | jago | linkaja | shopeepay
 * @param {number} amount
 */
export const postValidateGiftCardPaymentChannel = (channel, amount) => {
  return http.post(list.giftCardValidatePaymentChannel(channel), { amount });
};

/** Handle request service validate payment channel limit buy gift card */
export const validateGiftCardPaymentChannel = async (channel, amount) => {
  const req = postValidateGiftCardPaymentChannel(channel, amount);

  return await req
    .then(() => {
      return {
        status: 'SUCCESS',
        data: null,
      };
    })
    .catch((error) => {
      const errorData = get(['response', 'data', 'data'], error);

      return {
        status: 'ERROR',
        data: errorData,
      };
    });
};

/** Handle request buy gift card with ask to native or not */
const handleRequestBuyGiftCard = (paymentMethod) => {
  const version = buyGiftCardVersion(paymentMethod);

  if (paymentMethod === 'jago') {
    return postBuyGiftCardNative;
  }

  if (paymentMethod === 'jago_snap') {
    return postBuyGiftCardJagoSNAP;
  }

  if (version === 'v2') {
    return postBuyGiftCardV2;
  }

  return postBuyGiftCard;
};

/** Handle request service buy gift card */
export const buyGiftCard = async ({
  category,
  template,
  amount,
  convenience_fee,
  message,
  payment_method = 'gopay',
  sender,
} = {}) => {
  const body = {
    category,
    template,
    amount,
    convenience_fee,
    message,
    payment_method,
    sender,
  };

  const requestBuyGiftCard = handleRequestBuyGiftCard(payment_method);

  return requestBuyGiftCard(body)
    .then((res) => {
      const response = handleResponseGeneral(res).data;

      return {
        status: 'SUCCESS',
        data: response,
      };
    })
    .catch((err) => {
      const errBodySender = get(['response', 'data', 'errors', 'sender'], err);

      // Jika errornya bukan body::sender, artinya ada error (Maintenance) Gopay ATAU memang error API system lainnya
      // Dengan ini kita hanya membuka Error Unexpected JIKA error BUKAN dari body::sender
      if (IsEmpty(errBodySender)) {
        dispatch(
          openErrorModal({
            type: 'GIFTCARD_FAILED',
            message:
              err?.response?.data?.message ??
              'Maaf, pembuatan gift card untuk saat ini tidak dapat dilakukan. Silakan coba beberapa saat lagi.',
          })
        );
      }

      return get(['response', 'data'], err, {});
    });
};

export function getGiftCardDetail({
  code,
  recaptchaType,
  recaptchaToken,
  showToast,
}) {
  return new Promise((resolve, reject) => {
    const body = {
      recaptcha_type: recaptchaType,
      recaptcha_token: recaptchaToken,
    };

    const req = http
      .post(list.giftcardDetail(code), body)
      .then((res) => resolve(handleResponseGeneral(res).data));

    handleResponseErrorCase(req, (er) => {
      const { raw, err } = er;
      const recaptchaType = get(['data', 'recaptcha_type'], raw);
      const errorType = get(['type'], raw);
      const message = err;
      if (!!showToast)
        _showErrorToast(message || 'error', { position: 'relative' });
      reject({
        recaptchaType,
        errorType,
        message,
      });
    });
  });
}

export function claimGiftCard({ token, code }) {
  return new Promise((resolve) => {
    const body = {
      token,
      code,
    };

    const req = http
      .post(list.giftcardClaim, body)
      .then((res) => resolve(handleResponseGeneral(res).data));

    handleResponseErrorCase(req, (er) => {
      const { err } = er;
      const message = err;
      _showErrorToast(message || 'error', { position: 'relative' });
    });
  });
}

/**
 * GET: Gift Card Payment Method
 * @param query - with object amount
 */
export const getGiftCardPaymentMethod = (query) => {
  return http.get(list.getPaymentMethod, query);
};

/**
 * Fetching Gift Card Payment Method
 * @param amount
 * @param enabled
 */
export const useFetchGiftCardPaymentMethod = ({
  amount = 0,
  enabled = false,
}) => {
  const { supportedByVersion } = isLinkAjaApplinkSupported();
  const linkajaApplinkFF = useFlag('web_linkaja_applink');
  const snapJagoFF = useFlag('web_jago_snap');
  const institution = useUserInInstitution();

  const query = {
    giftcard: 1,
    instant_payment: 1,
    amount: !!amount ? amount : 0,
    ...(supportedByVersion && linkajaApplinkFF ? { v: 2 } : {}),
    ...(snapJagoFF ? { is_snap: 1 } : {}),
  };

  return useQuery(
    ['Get Gift Card Payment Method', query],
    () => getGiftCardPaymentMethod(query),
    {
      enabled: enabled && !institution,
      refetchOnWindowFocus: 'always',
    }
  );
};

/**
 * Saving latest state buy gift card into localStorage
 * so we can restore latest state buy gift card
 *
 * Promise::true -> success saving
 *
 * Promise::false -> failed saving
 */
export const saveBuyGiftCardProperties = async (data) => {
  const dataStringfy = JSON.stringify(data);
  try {
    await Storage.set(BUY_GIFT_CARD_STORAGE_KEY, dataStringfy);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Latest buy gift card properties from local Storage
 *
 * Promise::buyGiftCardProperties -> the value available
 *
 * Promise::false -> the value not available
 */
export const getLatestBuyGiftCardProperties = async () => {
  try {
    const dataString = await Storage.get(BUY_GIFT_CARD_STORAGE_KEY);
    const isValidJSON = isJSON(dataString);

    if (!isValidJSON) return false;

    const buyGiftCardProperties = JSON.parse(dataString);

    if (IsEmpty(buyGiftCardProperties)) return false;

    return buyGiftCardProperties;
  } catch (error) {
    return false;
  }
};

/**
 * remove Latest buy gift card Properties in LocalStorage
 *
 * Promise::true --> success remvoe
 *
 * Promise::false --> failed remove
 */
export const removeLatestBuyGiftCardProperties = async () => {
  try {
    await Storage.remove(BUY_GIFT_CARD_STORAGE_KEY);
    return true;
  } catch (error) {
    return false;
  }
};
