import * as React from 'react';
import Light from './light.svg';
import Dark from './dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: Light,
  dark: Dark,
  default: Light,
};

export const InstantLogo: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  return (
    <ThemedImage imageMap={themeImageMapping} alt={'instant-logo'} {...props} />
  );
};

export default InstantLogo;
