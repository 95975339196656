import clevertap from 'clevertap-web-sdk';
import { getEnv } from 'core/env';

const { ClevertapProjectId, ShowAnalyticsLog } = getEnv();
export const initClevertap = () => {
  clevertap.spa = true;
  clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
  clevertap.privacy.push({ useIP: false }); // Set the flag to true, if the user agrees to share their IP data
  clevertap.init(ClevertapProjectId as string, 'sg1'); // Replace with values applicable to you. Refer below
  clevertap.setLogLevel(ShowAnalyticsLog ? 3 : 0);
};
