import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { isEmptyObject } from './../../utils';
import {
  handleResponseErrorCase,
  handleResponseGeneral,
} from 'utils/http-response';
import { openErrorModal } from '../errors/errorModal.reducer';
import getSafely from '../../utils/safely';
import {
  getBankAccountDetail,
  getBankSlotID,
} from '../../entities/bankAccounts.reducer';
import { postOrderSellPreview } from 'services/transaction';
import {
  postRoboCalculateSell,
  postRoboSellAllPreview,
  postSellAllPreview,
} from 'services/robo';
import {
  isNativeSupportJagoInstantRedemption,
  isJagoInstantRedemptionAvailable,
} from 'features/bankjago';

const defaultState = {
  visible: false,
  firstTimeVisible: true,
  products: [],
  soldProducts: [],
  roboCategoryId: '',
  roboId: '',
  type: 'single',
  sellAmount: null,
  totalSellAmount: null,
  bankCharge: 0,
  totalCashback: 0,
  cashbackChance: 3,
  /** @type number | null */
  singleSellEstimate: null,
  productSellAmount: [],
  sellAll: false,
  roboSellAll: false,
  showConfirmation: false,
  showFundBankSourceInfo: false,
  fromFundBankModal: false,
  bankInfoModalTrigger: 'singlesell',
  sellAgree: false,
  confirmPinOpened: false,
  differentSellEstimateWarning: false,
  sellWarningVisible: false,
  calculationLoading: false,
  cashbackChanceInfoOpen: false,
  /** @type any */
  bank: {},
  changeBankModalVisible: false,
  changeBankModalTargetProduct: '',
  changeBankModalSelectedBank: '',
  changeBankRedeemSource: '',
  changeBankRedeemSourceIcon: '',
  changeBankRedeemSourceId: null,
  shareDividend: 0,
  triggeredFromModal: false,
  unavailableInstantRedemptionItem: false,
  exitLoadVisible: false,
  showPortoBanner: false,
  todayRedemption: 0,
  emptyBankModalVisible: false,
  abortController: null,
  /**
   * warning message when product being sell is in on going challenge,
   * handle show hide warning modal from here
   */
  challengeWarningMessage: '',
  isBackFromInputSellModal: false,
};

const [
  sellWarningSetVisible,
  sellModalSetVisible,
  setSellBankDefault,
  sellModalSetData,
  sellModalOpened,
  singleSellModalOpened,
  singleSellModalClosed,
  sellModalConfirmationOpened,
  sellModalConfirmationClosed,
  sellModalAmountClosed,
  sellModalAmountNext,
  sellModalClosed,
  sellModalConfirmationShowBankInfo,
  sellModalReset,
  sellModalChangeSliderVal,
  sellModalConfirmPinOpened,
  sellModalConfirmPinClosed,
  sellModalConfirmPinBack,
  sellModalConfirmPinChangeInputVal,
  sellModalChangeAmount,
  sellModalSetCalculateResultSingle,
  sellModalSetCalculateResult,
  sellModalChangeEstimate,
  sellPinConfirmationErrorReset,
  sellModalToggleRoboWarning,
  sellRoboToggleAgree,
  sellRoboToggleSellAll,
  sellModalCalculationLoading,
  sellModalCashbackChanceInfoOpen,
  sellModalOpenChangeBankModal,
  sellModalCloseChangeBankModal,
  sellModalChangeBankChannel,
  sellModalSetFirstTimeVisible,
  sellModalConfirmationBack,
  setTriggeredFromModal,
  setUnavailableInstantRedemptionItem,
  setExitLoadVisible,
  setEmptyBankModalVisible,
  setChangeBankModalTargetProduct,
  setChangeBank,
  setAbortController,
  setWarningChallengeMessage,
  sellModalBack,
  resetSellModalBack,
] = [
  'SELL_WARNING_SET_VISIBLE',
  'SELL_MODAL_SET_VISIBLE',
  'SET_SELL_BANK_DEFAULT',
  'SELL_MODAL_SET_DATA',
  'SELL_MODAL_OPENED',
  'SINGLE_SELL_MODAL_OPENED',
  'SINGLE_SELL_MODAL_CLOSED',
  'SELL_MODAL_CONFIRMATION_OPENED',
  'SELL_MODAL_CONFIRMATION_CLOSED',
  'SELL_MODAL_AMOUNT_CLOSED',
  'SELL_MODAL_AMOUNT_NEXT',
  'SELL_MODAL_CLOSED',
  'SELL_MODAL_CONFIRMATION_SHOW_BANK_INFO',
  'SELL_MODAL_RESET',
  'SELL_MODAL_CHANGE_SLIDER_VAL',
  'SELL_MODAL_CONFIRM_PIN_OPENED',
  'SELL_MODAL_CONFIRM_PIN_CLOSED',
  'SELL_MODAL_CONFIRM_PIN_BACK',
  'SELL_MODAL_CONFIRM_PIN_CHANGE_INPUT_VAL',
  'SELL_MODAL_CHANGE_AMOUNT',
  'SELL_MODAL_SET_CALCULATE_RESULT_SINGLE',
  'SELL_MODAL_SET_CALCULATE_RESULT',
  'SELL_PIN_CONFIRMATION_SET_ERROR',
  'SELL_PIN_CONFIRMATION_ERROR_RESET',
  'SELL_MODAL_TOGGLE_ROBO_WARNING',
  'SELL_ROBO_TOGGLE_AGREE',
  'SELL_ROBO_TOGGLE_SELL_ALL',
  'SELL_MODAL_CALCULATION_LOADING',
  'SELL_MODAL_CASHBACK_CHANCE_INFO_OPEN',
  'SELL_MODAL_OPEN_CHANGE_BANK_MODAL',
  'SELL_MODAL_CLOSE_CHANGE_BANK_MODAL',
  'SELL_MODAL_CHANGE_BANK_CHANNEL',
  'SELL_MODAL_SET_FIRST_TIME_VISIBLE',
  'SELL_MODAL_CONFIRMATION_BACK',
  'SET_TRIGGERED_FROM_MODAL',
  'SET_UNAVAILABLE_INSTANT_REDEMPTION_ITEMS',
  'SET_EXIT_LOAD_VISIBLE',
  'SET_NEO_WARNING_MODAL_VISIBLE',
  'SET_EMPTY_BANK_MODAL_VISIBLE',
  'SET_CHANGE_BANK_MODAL_TARGET_PRODUCT',
  'SET_CHANGE_BANK',
  'SET_ABORT_CONTROLLER',
  'SET_WARNING_CHALLENGE_MESSAGE',
  'SELL_MODAL_BACK',
  'RESET_SELL_MODAL_BACK',
].map(createAction);

export {
  sellWarningSetVisible,
  sellModalSetVisible,
  setSellBankDefault,
  sellModalSetData,
  sellModalOpened,
  singleSellModalOpened,
  singleSellModalClosed,
  sellModalConfirmationOpened,
  sellModalConfirmationClosed,
  sellModalAmountClosed,
  sellModalAmountNext,
  sellModalClosed,
  sellModalConfirmationShowBankInfo,
  sellModalReset,
  sellModalChangeSliderVal,
  sellModalConfirmPinOpened,
  sellModalConfirmPinClosed,
  sellModalConfirmPinBack,
  sellModalConfirmPinChangeInputVal,
  sellModalChangeAmount,
  sellModalSetCalculateResultSingle,
  sellModalSetCalculateResult,
  sellModalChangeEstimate,
  sellPinConfirmationErrorReset,
  sellModalToggleRoboWarning,
  sellRoboToggleAgree,
  sellRoboToggleSellAll,
  sellModalCashbackChanceInfoOpen,
  sellModalOpenChangeBankModal,
  sellModalCloseChangeBankModal,
  sellModalChangeBankChannel,
  sellModalSetFirstTimeVisible,
  sellModalConfirmationBack,
  setTriggeredFromModal,
  setUnavailableInstantRedemptionItem,
  sellModalCalculationLoading,
  setExitLoadVisible,
  setEmptyBankModalVisible,
  setChangeBankModalTargetProduct,
  setChangeBank,
  setAbortController,
  setWarningChallengeMessage,
  sellModalBack,
  resetSellModalBack,
};

const sellModal = createReducer(
  {
    [sellWarningSetVisible]: (state, payload) => {
      return update(state, {
        sellWarningVisible: { $set: payload },
      });
    },
    [sellModalSetVisible]: (state, payload) => {
      return update(state, {
        visible: { $set: payload },
        isBackFromInputSellModal: { $set: false },
      });
    },
    [setSellBankDefault]: (state, payload) => {
      let productUserBankAccountMapping = defaultState.bank;
      if (payload.type === 'single') {
        productUserBankAccountMapping = {
          [payload.products[0]]: payload.userDefaultBankIdentifier,
        };
      } else {
        payload.products.forEach((product) => {
          productUserBankAccountMapping = {
            ...productUserBankAccountMapping,
            [product]: payload.userDefaultBankIdentifier,
          };
        });
      }

      return update(state, {
        bank: { $set: productUserBankAccountMapping },
      });
    },
    [sellModalSetData]: (state, payload) => {
      return update(state, {
        type: { $set: payload.type },
        products: { $set: payload.products },
        roboCategoryId: { $set: payload.roboCategoryId },
        roboId: { $set: payload.roboId },
        triggeredFromModal: { $set: payload.triggeredFromModal },
        showPortoBanner: { $set: payload.showPortoBanner },
      });
    },
    [sellModalOpened]: (state, payload) => {
      return update(state, {
        visible: { $set: true },
        isBackFromInputSellModal: { $set: false },
      });
    },
    [singleSellModalOpened]: (state, payload) => {
      return update(state, {
        visible: { $set: true },
        isBackFromInputSellModal: { $set: false },
      });
    },
    [singleSellModalClosed]: (state, payload) => {
      return update(state, {
        visible: { $set: false },
        isBackFromInputSellModal: { $set: true },
      });
    },
    [sellModalConfirmationOpened]: (state, payload) => {
      return update(state, {
        showConfirmation: { $set: true },
      });
    },
    [sellModalConfirmationClosed]: (state, payload) => {
      return update(state, {
        showConfirmation: { $set: false },
      });
    },
    [sellModalAmountClosed]: (state, payload) => {
      return update(state, {
        visible: { $set: false },
      });
    },
    [sellModalAmountNext]: (state, payload) => {
      return update(state, {
        visible: { $set: false },
        showConfirmation: { $set: true },
      });
    },
    [sellModalConfirmationBack]: (state, _) => {
      return update(state, {
        visible: { $set: true },
        showConfirmation: { $set: false },
        firstTimeVisible: { $set: false },
        bank: { $set: state.type === 'robo' ? {} : state.bank },
        sellAgree: { $set: state.type === 'robo' ? false : state.sellAgree },
      });
    },
    [sellModalClosed]: (state) => {
      return update(state, {
        visible: { $set: false },
        showConfirmation: { $set: false },
        sellAgree: { $set: false },
        changeBankModalVisible: { $set: false },
        firstTimeVisible: { $set: true },
        triggeredFromModal: { $set: false },
        emptyBankModalVisible: { $set: false },
        bank: { $set: {} },
        isBackFromInputSellModal: { $set: false },
      });
    },
    [sellModalConfirmationShowBankInfo]: (state, payload) => {
      const newState = {
        showFundBankSourceInfo: { $set: !state.showFundBankSourceInfo },
      };
      if (payload) {
        // On open
        newState.bankInfoModalTrigger = { $set: payload };
        if (payload === 'singlesell') {
          newState.visible = { $set: !state.visible };
        }

        if (payload === 'confirmation') {
          newState.showConfirmation = { $set: !state.showConfirmation };
        }
      } else {
        // On close
        if (state.bankInfoModalTrigger === 'singlesell') {
          newState.visible = { $set: !state.visible };
        }

        if (state.bankInfoModalTrigger === 'confirmation') {
          newState.showConfirmation = { $set: !state.showConfirmation };
        }
      }
      return update(state, newState);
    },
    [sellModalReset]: (state, payload) => {
      return update(state, {
        $merge: { ...defaultState, cashbackChance: state.cashbackChance },
      });
    },
    [sellModalConfirmPinOpened]: (state) => {
      return update(state, {
        confirmPinOpened: { $set: true },
        // @ close the modal relate to modal sell
        visible: { $set: false },
        showConfirmation: { $set: false },
        sellAgree: { $set: false },
        changeBankModalVisible: { $set: false },
        triggeredFromModal: { $set: false },
        emptyBankModalVisible: { $set: false },
      });
    },
    [sellModalConfirmPinClosed]: (state, payload) => {
      return update(state, {
        confirmPinOpened: { $set: false },
      });
    },
    [sellModalConfirmPinBack]: (state) => {
      return update(state, {
        visible: { $set: true },
        confirmPinOpened: { $set: false },
        firstTimeVisible: { $set: false },
        bank: { $set: state.type === 'robo' ? {} : state.bank }, // if type sell is Robo, then we need to reset Bank
      });
    },

    [sellModalChangeSliderVal]: (state, payload) => {
      return update(state, {
        sellAmount: { $set: payload.sellAmount },
        sellAll: { $set: payload.sellAll },
      });
    },
    [sellModalChangeAmount]: (state, payload) => {
      return update(state, {
        sellAmount: { $set: payload },
      });
    },
    [sellModalSetCalculateResultSingle]: (state, payload) => {
      const soldProducts = payload.group_products.reduce(
        (accumulated, currVal) => {
          currVal.products.forEach((item) => {
            item.category = currVal.category;
            return accumulated.push(item);
          });
          return accumulated;
        },
        []
      );
      const shareDividend = getSafely(['is_share_dividend'], payload, 0);
      return update(state, {
        products: {
          $set: soldProducts.map((item) => item.product_detail.symbol),
        },
        productSellAmount: {
          $set: soldProducts.map((item) => item.units),
        },
        productSingleAllPreview: {
          $set: soldProducts,
        },
        totalSellAmount: { $set: payload.allocated },
        totalCashback: { $set: payload.cashback },
        bankCharge: { $set: payload.bank_charge },
        cashbackChance: { $set: payload.cashbackChance },
        shareDividend: { $set: shareDividend },
      });
    },
    [sellModalSetCalculateResult]: (state, payload) => {
      const soldProducts = payload.items.reduce((accumulated, currVal) => {
        currVal.items.forEach((item) => {
          item.category = currVal.category;
          return accumulated.push(item);
        });
        return accumulated;
      }, []);
      const shareDividend = getSafely(['is_share_dividend'], payload, 0);

      return update(state, {
        products: {
          $set: soldProducts.map((item) => item.product.symbol),
        },
        productSellAmount: {
          $set: soldProducts.map((item) => item.units_sell),
        },
        soldProducts: { $set: soldProducts },
        totalSellAmount: { $set: payload.allocated },
        totalCashback: { $set: payload.cashback },
        bankCharge: { $set: payload.bank_charge },
        cashbackChance: { $set: payload.cashbackChance },
        shareDividend: { $set: shareDividend },
        todayRedemption: { $set: payload.today_redemption },
      });
    },
    [sellModalChangeEstimate]: (state, payload) => {
      return update(state, {
        singleSellEstimate: { $set: payload },
      });
    },
    [sellModalToggleRoboWarning]: (state, payload) => {
      return update(state, {
        differentSellEstimateWarning: {
          $set: !state.differentSellEstimateWarning,
        },
        showConfirmation: { $set: !state.showConfirmation },
      });
    },
    [sellRoboToggleAgree]: (state, payload) => {
      return update(state, {
        sellAgree: { $set: !state.sellAgree },
      });
    },
    [sellRoboToggleSellAll]: (state, payload) => {
      return update(state, {
        roboSellAll: {
          $set: typeof payload === 'boolean' ? payload : !state.roboSellAll,
        },
      });
    },
    [sellModalCalculationLoading]: (state, payload) => {
      return update(state, {
        calculationLoading: { $set: payload },
      });
    },
    [sellModalCashbackChanceInfoOpen]: (state, payload) => {
      return update(state, {
        cashbackChanceInfoOpen: { $set: payload },
      });
    },
    [sellModalChangeBankChannel]: (state, payload) => {
      return update(state, {
        bank: {
          $set: {
            ...state.bank,
            [state.changeBankModalTargetProduct]: payload,
          },
        },
        changeBankModalSelectedBank: { $set: payload },
      });
    },
    [sellModalOpenChangeBankModal]: (state, payload) => {
      return update(state, {
        showFundBankSourceInfo: { $set: false },
        changeBankModalVisible: { $set: true },
        changeBankModalTargetProduct: { $set: payload.symbol },
        changeBankModalSelectedBank: { $set: payload.userBankId },
        changeBankRedeemSource: { $set: payload.sourceBank },
        changeBankRedeemSourceIcon: { $set: payload.sourceBankIcon },
        changeBankRedeemSourceId: { $set: payload.sourceBankId },
        fromFundBankModal: { $set: payload?.fromFundBankModal },
      });
    },
    [sellModalCloseChangeBankModal]: (state, payload) => {
      return update(state, {
        showFundBankSourceInfo: {
          $set: state?.fromFundBankModal ? true : false,
        },
        changeBankModalVisible: { $set: false },
        changeBankModalTargetProduct: {
          $set: defaultState.changeBankModalTargetProduct,
        },
        changeBankModalSelectedBank: {
          $set: defaultState.changeBankModalSelectedBank,
        },
        changeBankRedeemSource: { $set: defaultState.changeBankRedeemSource },
        changeBankRedeemSourceIcon: {
          $set: defaultState.changeBankRedeemSourceIcon,
        },
        fromFundBankModal: { $set: false },
      });
    },
    [sellModalSetFirstTimeVisible]: (state, payload) => {
      return update(state, {
        firstTimeVisible: { $set: payload },
      });
    },
    [setTriggeredFromModal]: (state, payload) => {
      return update(state, {
        triggeredFromModal: { $set: payload },
      });
    },
    [setUnavailableInstantRedemptionItem]: (state, payload) => {
      return update(state, {
        unavailableInstantRedemptionItem: { $set: payload },
      });
    },
    [setExitLoadVisible]: (state, payload) => {
      return update(state, {
        exitLoadVisible: { $set: payload },
      });
    },
    [setEmptyBankModalVisible]: (state, payload) => {
      return update(state, {
        emptyBankModalVisible: { $set: payload },
      });
    },
    [setChangeBankModalTargetProduct]: (state, payload) => {
      return update(state, {
        changeBankModalTargetProduct: { $set: payload },
      });
    },
    [setChangeBank]: (state, payload) => {
      return update(state, {
        bank: { $set: payload },
      });
    },
    [setAbortController]: (state, payload) => {
      return update(state, {
        abortController: { $set: payload },
      });
    },
    [setWarningChallengeMessage]: (state, payload) => {
      return update(state, {
        challengeWarningMessage: { $set: payload },
      });
    },
    [sellModalBack]: (state) => {
      return update(state, {
        visible: { $set: false },
        showConfirmation: { $set: false },
        sellAgree: { $set: false },
        changeBankModalVisible: { $set: false },
        firstTimeVisible: { $set: false },
        triggeredFromModal: { $set: false },
        emptyBankModalVisible: { $set: false },
      });
    },
    [resetSellModalBack]: (state, payload) => {
      return update(state, {
        isBackFromInputSellModal: { $set: false },
      });
    },
  },
  defaultState
);

// Selectors
/**
 * Get available unit for each portfolio item
 * @param {Object} state - Redux state object
 */
export const getSellPortfolioData = (state) => {
  if (state.sellModal.type === 'single') {
    return {
      ...state.sellModal.products.map(
        (item) => state.entities.portfolio.items.byId[item]
      )[0],
      ...state.entities.reksadana.byId[state.sellModal.products[0]],
    };
  }

  return state.sellModal.products.map(
    (item) => state.entities.portfolio.items.byId[item]
  );
};

export const getSellProductInfo = (state) => {
  return state.sellModal.products.map(
    (item) => state.entities.reksadana.byId[item]
  );
};

/**
 * Remap existing product to user's bank channel into specific object structure required by API. Our API can only know from the bank account slot number
 * @param {Object || null} productSellBankChannel - Object contained mapping between product and user's receiving bank
 * @param {Object} reduxStateTree - Redux state tree object
 */
export const mapBankChannelToSlot = (
  productSellBankChannel,
  reduxStateTree
) => {
  const newMappedProductBankObject = Object.keys(productSellBankChannel).reduce(
    (constructedObject, product) => {
      return {
        ...constructedObject,
        [product]: getBankSlotID(
          productSellBankChannel[product],
          reduxStateTree
        ),
      };
    },
    {}
  );

  return newMappedProductBankObject;
};

// Actions

/**
 * Check Exit Load Products
 * If exist, show exit load modal and return boolean
 *
 * @returns boolean
 */
const checkExitLoadProducts = () => {
  return (dispatch, getState) => {
    dispatch(sellModalCalculationLoading(false));

    const selectedRoboProducts = getSellProductInfo(getState());

    const productWithExitLoad = selectedRoboProducts.filter(
      (product) => product?.exitload?.length > 0
    );
    if (productWithExitLoad.length > 0) {
      dispatch(sellModalSetVisible(false));
      dispatch(setExitLoadVisible(true));
      return true;
    }

    return false;
  };
};

/**
 * Calculate sell order amount
 * @param object { needToCheckExitLoad }
 * needToCheckExitLoad = To give permission to check exit load products
 * jagoLinkingStatus = ex: 'ACTIVE' | ''
 * forceCalculate = handle force confirm sell when challenge is on going
 * @returns void or string ('EXCESS_LIMIT')
 */
export function calculateAmountProportion(
  {
    needToCheckExitLoad,
    profileDetail,
    jagoLinkingStatus,
    instantRedemptionActiveByFF,
    forceCalculate,
  } = {
    needToCheckExitLoad: false,
    profileDetail: undefined,
    jagoLinkingStatus: null,
    instantRedemptionActiveByFF: null,
    forceCalculate: false,
  }
) {
  return (dispatch, getState) => {
    const sellAmount = getSafely(['sellModal', 'sellAmount'], getState());
    const roboId = getSafely(['sellModal', 'roboId'], getState());
    const products = getSafely(['sellModal', 'products'], getState());
    const type = getSafely(['sellModal', 'type'], getState());
    const sellRpAmount = getSafely(
      ['sellModal', 'singleSellEstimate'],
      getState()
    );
    const roboCategoryId = getSafely(
      ['sellModal', 'roboCategoryId'],
      getState()
    );
    const calculationLoading = getSafely(
      ['sellModal', 'calculationLoading'],
      getState()
    );
    const bank = getSafely(['sellModal', 'bank'], getState());

    const unavailableInstantRedemptionItem = getSafely(
      ['sellModal', 'unavailableInstantRedemptionItem'],
      getState()
    );

    // Map user's bank and product bank account for selling
    const productSellBankChannelBySlot = isEmptyObject(bank)
      ? bank
      : mapBankChannelToSlot(bank, getState());

    if (calculationLoading) {
      return Promise.resolve();
    }

    dispatch(sellModalCalculationLoading(true));

    if (type === 'single') {
      // Checking exit load products
      if (needToCheckExitLoad) {
        const isExitLoad = dispatch(checkExitLoadProducts());

        if (isExitLoad) return;
      }

      const sellPreviewRequiredData = {
        symbol: products[0],
        amount: sellRpAmount,
        type,
        categoryid: roboCategoryId,
        /**
         * handle force confirm sell when challenge is on going
         */
        is_force: forceCalculate,
      };
      if (!isEmptyObject(bank)) {
        sellPreviewRequiredData.bank = productSellBankChannelBySlot;
      }
      const req = postOrderSellPreview(sellPreviewRequiredData).then(
        (response) => {
          const { data: calculateResult } = handleResponseGeneral(response);
          const isDividenBeingShared = calculateResult?.is_share_dividend === 1;

          const isLinkingStatusJagoActive = jagoLinkingStatus === 'ACTIVE';

          /** Check if user withdraw instantly with Jago
           * BUT excess limit (EXCESS_LIMIT)  */
          if (isLinkingStatusJagoActive && instantRedemptionActiveByFF) {
            const productDetail =
              calculateResult?.items?.[0]?.items?.[0]?.product;

            /** Is bank jago set as withdrawal bank (bank pencairan) */

            const userBank = getBankAccountDetail(
              getState()?.sellModal?.bank[products[0]],
              getState()
            );
            const isBankJagoWithdrawalBank =
              isJagoInstantRedemptionAvailable(userBank);

            /** @type number */
            const instantRedemptionDailyLimit =
              profileDetail?.config?.jago_instant_redemption?.daily_limit;

            const isInstantRedemptionProduct =
              productDetail?.is_instant_redemption;

            const todayRedemption = calculateResult?.today_redemption;

            const isWithdrawalInstantRedemption =
              isBankJagoWithdrawalBank &&
              isInstantRedemptionProduct &&
              !unavailableInstantRedemptionItem &&
              isLinkingStatusJagoActive &&
              isNativeSupportJagoInstantRedemption();

            // Example
            // todayRedemption = 30juta
            // sellRpAmount = 25juta
            // instantRedemptionDailyLimit = 50juta
            // 25juta > 50juta - 30 juta (true)
            // sellRpAmount >= instantRedemptionDailyLimit - todayRedemption

            // Example 2
            // todayRedemption = 0
            // sellRpAmount = 50juta
            // instantRedemptionDailyLimit = 50juta
            // 50juta > 50juta - 0 (false)

            const isWithdrawalExcessLimit =
              isWithdrawalInstantRedemption &&
              sellRpAmount > instantRedemptionDailyLimit - todayRedemption;

            //
            /**
             * Return EXCESS_LIMIT to show ExcessLimitContent
             * in RejectedInstantRedemptionMOdal
             */
            if (isWithdrawalExcessLimit) return 'EXCESS_LIMIT';
          }

          dispatch(sellModalCalculationLoading(false));
          dispatch(setExitLoadVisible(false));

          const unitAllocated = Number(calculateResult?.allocated).toFixed(0);

          // if dividen is being distributed
          if (isDividenBeingShared && Number(unitAllocated ?? 0) <= 0) {
            return dispatch(
              openErrorModal({
                message:
                  'Saat ini penjualan tidak dapat dilakukan pada produk ini karena sedang ada pembagian dividen.',
              })
            );
          }

          if (
            (Array.isArray(calculateResult.items) &&
              calculateResult.items.length === 0) ||
            Number(unitAllocated ?? 0) <= 0
          ) {
            const errorModalObj = {
              type: 'MIN_SELL_NOT_MET',
            };
            dispatch(sellModalAmountClosed());
            dispatch(sellModalReset());
            return dispatch(openErrorModal(errorModalObj));
          }

          dispatch(
            sellModalSetCalculateResult({
              ...calculateResult,
            })
          );
          dispatch(sellModalAmountNext());
        }
      );

      return handleResponseErrorCase(req, (err) => {
        console.error(err);

        dispatch(sellModalCalculationLoading(false));

        const errorModalObj = {
          type: err.raw ? err.raw.type : 'SYSTEM_ERROR',
          message: err.err,
        };

        if (errorModalObj.type === 'promo_sell_verification') {
          return dispatch(
            setWarningChallengeMessage(
              'Nabung Rutin challenge kamu akan dibatalkan jika kamu klik Lanjut Jual reksa dana kamu di portofolio ini.'
            )
          );
        }

        dispatch(sellModalAmountClosed());
        dispatch(sellModalReset());
        return dispatch(openErrorModal(errorModalObj));
      });
    }

    if (type === 'robo') {
      const abortController = new AbortController();
      const signal = abortController?.signal;
      if (!!abortController) {
        dispatch(setAbortController(abortController));
      }

      const req = postRoboCalculateSell(
        roboId,
        {
          amount: sellAmount,
          bank: productSellBankChannelBySlot,
          /**
           * handle force confirm sell when challenge is on going
           */
          is_force: forceCalculate,
        },
        signal
      ).then((response) => {
        const { data: calculateResult } = handleResponseGeneral(response);

        dispatch(sellModalCalculationLoading(false));

        if (
          Array.isArray(calculateResult.items) &&
          calculateResult.items.length === 0
        ) {
          const errorModalObj = {
            type: 'MIN_SELL_NOT_MET',
          };
          dispatch(sellModalAmountClosed());
          // Reset sell modal amount
          dispatch(sellModalChangeAmount(null));
          return dispatch(openErrorModal(errorModalObj));
        }

        dispatch(
          sellModalSetCalculateResult({
            ...calculateResult,
          })
        );

        // Checking exit load products
        if (needToCheckExitLoad) {
          const isExitLoad = dispatch(checkExitLoadProducts());

          if (isExitLoad) return;
        }

        dispatch(setExitLoadVisible(false));

        dispatch(sellModalAmountNext());
      });

      handleResponseErrorCase(req, (err) => {
        console.error(err);

        dispatch(sellModalCalculationLoading(false));

        const errorModalObj = {
          type: err.raw ? err.raw.type : 'SYSTEM_ERROR',
          message: err.err,
        };

        if (errorModalObj.type === 'promo_sell_verification') {
          return dispatch(
            setWarningChallengeMessage(
              'Nabung Rutin challenge kamu akan dibatalkan jika kamu klik Lanjut Jual reksa dana kamu di portofolio ini.'
            )
          );
        }

        dispatch(sellModalAmountClosed());
        dispatch(sellModalReset());

        // If request is cancelled, do nothing
        if (err.err === 'canceled') return;

        return dispatch(openErrorModal(errorModalObj));
      });
    }

    if (type === 'robo-all') {
      const req = postRoboSellAllPreview({
        shared_porto_id: roboCategoryId,
        bank: productSellBankChannelBySlot,
        /**
         * handle force confirm sell when challenge is on going
         */
        is_force: forceCalculate,
      }).then((response) => {
        const { data: calculateResult } = handleResponseGeneral(response);

        dispatch(sellModalCalculationLoading(false));

        if (
          Array.isArray(calculateResult.items) &&
          calculateResult.items.length === 0
        ) {
          const errorModalObj = {
            type: 'MIN_SELL_NOT_MET',
          };
          dispatch(sellModalAmountClosed());
          return dispatch(openErrorModal(errorModalObj));
        }

        dispatch(
          sellModalSetCalculateResult({
            ...calculateResult,
          })
        );

        // Checking exit load products
        if (needToCheckExitLoad) {
          const isExitLoad = dispatch(checkExitLoadProducts());

          if (isExitLoad) return;
        }

        dispatch(setExitLoadVisible(false));

        dispatch(sellModalAmountNext());
      });

      handleResponseErrorCase(req, (err) => {
        console.error(err);

        dispatch(sellModalCalculationLoading(false));

        const errorModalObj = {
          type: err.raw ? err.raw.type : 'SYSTEM_ERROR',
          message: err.err,
        };

        if (errorModalObj.type === 'promo_sell_verification') {
          return dispatch(
            setWarningChallengeMessage(
              'Nabung Rutin challenge kamu akan dibatalkan jika kamu klik Lanjut Jual reksa dana kamu di portofolio ini.'
            )
          );
        }

        dispatch(sellModalAmountClosed());
        dispatch(sellModalReset());
        return dispatch(openErrorModal(errorModalObj));
      });
    }
    if (type === 'single-all') {
      const req = postSellAllPreview({
        shared_porto_id: roboCategoryId,
        bank: productSellBankChannelBySlot,
        /**
         * handle force confirm sell when challenge is on going
         */
        is_force: forceCalculate,
      }).then((response) => {
        const { data: calculateResult } = handleResponseGeneral(response);

        dispatch(sellModalCalculationLoading(false));

        dispatch(
          sellModalSetCalculateResultSingle({
            ...calculateResult,
          })
        );

        // Checking exit load products
        if (needToCheckExitLoad) {
          const isExitLoad = dispatch(checkExitLoadProducts());

          if (isExitLoad) return;
        }

        dispatch(setExitLoadVisible(false));

        dispatch(sellModalAmountNext());
      });

      handleResponseErrorCase(req, (err) => {
        console.error(err);

        dispatch(sellModalCalculationLoading(false));

        const errorModalObj = {
          type: 'SYSTEM_ERROR',
          message: err.err,
        };

        if (errorModalObj.type === 'promo_sell_verification') {
          return dispatch(
            setWarningChallengeMessage(
              'Nabung Rutin challenge kamu akan dibatalkan jika kamu klik Lanjut Jual reksa dana kamu di portofolio ini.'
            )
          );
        }

        dispatch(sellModalAmountClosed());
        dispatch(sellModalReset());
        return dispatch(openErrorModal(errorModalObj));
      });
    }
  };
}

// Thunks
/**
 * Submit sell order
 */
export function submitSell() {
  return (dispatch) => {
    // If not pin confirmed yet, open pin confirmation modal and validate pin
    return dispatch(sellModalConfirmPinOpened());
  };
}

export default sellModal;
