/**
 * Bibit general text component
 *
 * @see https://www.figma.com/file/78odFKlig3QJnzJBblUgTN/Bibit---Design-System
 */
import * as React from 'react';
import classNames from 'classnames';
import colorObject, { BibitColor } from 'features/common/components/Color';

import style from './Text.module.css';

export type TextTypes =
  | 'headline2'
  | 'headline1'
  | 'title1'
  | 'title2'
  | 'subtitle1'
  | 'subtitle2'
  | 'subtitle3'
  | 'body1'
  | 'body2'
  | 'button1'
  | 'button2'
  | 'button3'
  | 'caption1'
  | 'caption2'
  | 'overline1'
  | 'overline2';

interface TextProps {
  /**
   * Text type. Default to body1
   */
  type?: TextTypes;

  /**
   * Text colour. You can only use color exported from our design system
   * color object
   *
   * @see src/features/common/components/Color/index.ts
   */
  color?: BibitColor;

  /**
   * Custom html tag used if needed
   */
  htmlTag?: keyof JSX.IntrinsicElements;

  /**
   * Additional className you want to add to the html tag
   */
  className?: string;

  /**
   * callback function on text click
   */
  onClick?: () => void;

  testid?: string;
}

/**
 * Bibit's common text. This generalise styling for every text in Bibit app.
 *
 * Default tag will be `<div />`
 */
const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  type = 'body1',
  color,
  htmlTag = 'div',
  className,
  onClick = () => null,
  testid,
}) => {
  /**
   * Basically react does not support expression for defining tags, so we
   * need to define the variable again
   *
   * @see https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime
   */
  const CustomTag = htmlTag;

  const usedColor = color ? colorObject[color] : '';

  return (
    <CustomTag
      className={classNames(style[type], className)}
      style={{ color: usedColor }}
      onClick={onClick}
      data-testid={testid}
    >
      {children}
    </CustomTag>
  );
};

export default Text;
