import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;

  h3 {
    color: var(--text-primary);
    margin-top: 24px;
    margin-bottom: 12px;
  }

  p {
    color: var(--text-primary);
  }
`;
