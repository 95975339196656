import Text from './Text';

export default Text;

export { Text };

export const size = {
  headline: 24,
  title: 20,
  subtitle: 16,
  body: 14,
  button1: 13,
  button2: 14,
  caption: 12,
  overline: 10,
};

export const weight = {
  thin: 300,
  regular: 400,
  bold: 700,
  extrabold: 800,
  black: 900,
};
