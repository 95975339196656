import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

const defaultState = {
  productCompare: [],
  productPeriod: '1y',
  showBuyButton: true,
};

const [
  compareAddProduct,
  compareRemoveProduct,
  compareChangeChartPeriod,
  compareShowBuyButton,
  compareResetState,
] = [
  'COMPARE_ADD_PRODUCT',
  'COMPARE_REMOVE_PRODUCT',
  'COMPARE_CHANGE_CHART_PERIOD',
  'COMPARE_SHOW_BUY_BUTTON',
  'COMPARE_RESET_STATE',
].map(createAction);

export {
  compareAddProduct,
  compareRemoveProduct,
  compareChangeChartPeriod,
  compareResetState,
  compareShowBuyButton,
};

const compareReducer = createReducer(
  {
    [compareShowBuyButton]: (state, payload) => {
      return update(state, {
        showBuyButton: { $set: payload },
      });
    },
    [compareAddProduct]: (state, payload) => {
      return update(state, {
        productCompare: { $push: [payload] },
      });
    },
    [compareRemoveProduct]: (state, payload) => {
      let idx = state.productCompare.indexOf(payload);
      return update(state, {
        productCompare: { $splice: [[idx, 1]] },
      });
    },
    [compareChangeChartPeriod]: (state, payload) => {
      return update(state, {
        productPeriod: { $set: payload },
      });
    },
    [compareResetState]: (state, payload) => {
      return update(state, {
        productCompare: { $set: [] },
        productPeriod: { $set: '1Y' },
        showBuyButton: { $set: defaultState.showBuyButton },
      });
    },
  },
  defaultState
);

export default compareReducer;
