/** Endpoints api for feature Bibit Plus */
const endpoints = {
  getVerificationStatus: `/user/upgrade/stats`,
  postUpgradeBibitPlus: `/user/upgrade`,
  /**
   * @see https://www.notion.so/stockbit/Bibit-Plus-Registration-using-RDN-BCA-d971052122db42598102e86d50701f8d
   */
  bcaBankList: '/user/bca/banks',
  initiateBibitPlus: '/user/upgrade/start',
  updateUserSelectionRdn: '/user/upgrade/rdn',
  submitRdn: '/user/upgrade/bank',
};

export default endpoints;
