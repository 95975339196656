import React from 'react';
import PinScreen from './components/PinScreen';
import PinExceed from './components/PinExceed';
import { Props } from './types';
import './pin-form.css';
import useProfileData from 'hooks/useProfileData';
import useAuth from 'hooks/useAuth';

/**
 * Component Pin Form that allow developer to use in their usecase as generic as posible
 */
const PinForm: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { isLogin } = useAuth();
  const { data } = useProfileData(isLogin);

  /**
   * tell us is the user get banned
   */
  const userSuspended = !!data?.data?.data?.suspend;

  /**
   * when forceRenderPinForm is true. the `userSuspended` value should be ignored and we just render `<PinScreen {...props} />`
   */
  const forceRenderPinForm = props.forceRenderPinForm;

  if (userSuspended && !forceRenderPinForm) {
    return <PinExceed {...props} />;
  }

  return <PinScreen {...props} />;
};
export default PinForm;
