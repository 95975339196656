import React, { createContext, useContext, useState } from 'react';
import { ReferralGlobalState, ReferralGlobalFunction } from './provider.type';

export const ReferralGlobalContextState = createContext<
  ReferralGlobalState | undefined
>(undefined);

export const ReferralGlobalContextFunction = createContext<
  ReferralGlobalFunction | undefined
>(undefined);

export const ReferralGlobalProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [confettiReminded, setConfettiReminded] = useState<boolean>(false);
  const [newReferralCode, setNewReferraCode] = useState<string | undefined>(
    undefined
  );

  const contextState = {
    confettiReminded,
    newReferralCode,
  };

  const contextFunction = {
    setConfettiReminded,
    setNewReferraCode,
  };

  return (
    <ReferralGlobalContextState.Provider value={contextState}>
      <ReferralGlobalContextFunction.Provider value={contextFunction}>
        {children}
      </ReferralGlobalContextFunction.Provider>
    </ReferralGlobalContextState.Provider>
  );
};

export const useReferralGlobalState = () => {
  const context = useContext(ReferralGlobalContextState);

  if (context === undefined) {
    throw new Error(
      'useReferralGlobal must be used within a ReferralGlobalProvider'
    );
  }

  return context;
};

export const useReferralGlobalFunction = () => {
  const context = useContext(ReferralGlobalContextFunction);

  if (context === undefined) {
    throw new Error(
      'useReferralGlobalFunction must be used within a ReferralGlobalProvider'
    );
  }

  return context;
};
