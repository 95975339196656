import * as React from 'react';
import Light from './light.svg';
import LightOld from './light-old.svg';
import Dark from './dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';
import { useFlag } from 'utils/feature-flag';

export const IconSyariah: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  const ff_darkmode = useFlag('web_bibit_darkmode');

  const themeImageMapping = {
    light: ff_darkmode ? Light : LightOld,
    dark: Dark,
    default: Light,
  };

  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default IconSyariah;
