import { useQuery } from 'react-query';
import { getSettingPreference } from 'services/pengkinian-data';

const useSettingPreference = (enabled: boolean = true) => {
  return useQuery(['setting_preference'], () => getSettingPreference(), {
    enabled,
    refetchOnMount: true,
    staleTime: 0,
    select: (res) => {
      // Normalize data from API before going to store
      return res?.data?.data;
    },
  });
};

export const getIsRenewal = (preferenceData: Record<string, any>) => {
  // Loop through preference setting
  // data example: [{a: 'value a'}, {b: 'value b'}]
  // have to loop through each value and its keys
  return (
    preferenceData.filter((el: any) => {
      const keys = Object.keys(el);
      const filtered = keys.filter((key) => {
        return key === 'is_request_amend';
      });
      return filtered.length > 0;
    })[0]?.['is_request_amend'] || 0
  );
};

export default useSettingPreference;
