import { Text, Skeleton } from '@bibitid/uikit-v1';
import React from 'react';
import styles from './AssetAllocation.module.css';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { numberToGeneralValueFormat } from 'utils/stringHelper';
import useBibitPlusUpgradeStatus from 'features/bibitplus/hooks/useBibitPlusUpgradeStatus';
import BibitPlusUpgradeModal from 'features/portfolio/components/BibitPlusUpgradeModal/BibitPlusUpgradeModal';
import { useToggle } from 'hooks';
import Analytics from 'utils/Analytics';

const AssetAllocation = ({
  value,
  name,
  link,
  isLoading,
}: {
  value: number;
  name: string;
  link?: string;
  isLoading?: boolean;
}) => {
  const { upgradeStatus } = useBibitPlusUpgradeStatus();
  const { active: isModalOpen, toggleActive: toggleModal } = useToggle();
  const handleOnClickAnalytics = () => {
    Analytics.logEventAction({
      eventName: 'porto_allocation_action',
      parameter: {
        action: 'asset_allocation_type',
        context: 'portfolio.index',
        trigger: 'click',
        data: {
          asset: name,
        },
      },
    });
  };

  const allocation = (
    <>
      <div
        className={classnames(styles['circle'], {
          [styles['moneymarket']]: name === 'Pasar Uang',
          [styles['fixincome']]: name === 'Obligasi',
          [styles['equity']]: name === 'Saham',
          [styles['campuran']]: name === 'Campuran',
          [styles['cash']]: name === 'Cash',
          [styles['loader']]: isLoading,
        })}
      >
        {!isLoading && (
          <Text type='body2'>
            {numberToGeneralValueFormat((value || 0) * 100)}%
          </Text>
        )}
      </div>
      {isLoading ? (
        <Skeleton
          width={54}
          height={12}
          variant='ellipse'
          animation='static'
          backgroundColor='greenHigh'
        />
      ) : (
        <Text type='caption1' className={styles['allocation-name']}>
          {name}
        </Text>
      )}
    </>
  );

  if (name === 'Cash' && !upgradeStatus.finished) {
    return (
      <>
        <div onClick={toggleModal} className={styles['asset-allocation']}>
          {allocation}
        </div>
        <BibitPlusUpgradeModal
          isOpen={isModalOpen}
          onClose={toggleModal}
          source='cash'
        />
      </>
    );
  }

  if (link) {
    return (
      <Link
        to={link}
        className={styles['asset-allocation']}
        onClick={handleOnClickAnalytics}
      >
        {allocation}
      </Link>
    );
  }

  return <div className={styles['asset-allocation--dark']}>{allocation}</div>;
};

export default AssetAllocation;
