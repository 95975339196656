import LazyModal from 'features/common/LazyModal';
import React, { lazy } from 'react';
import { Props } from './BankJagoTncModal';

const Modal = lazy(() => import('./BankJagoTncModal'));

const BankJagoTncModal = (props: Props) => {
  return <LazyModal component={Modal} visible={props.showModal} {...props} />;
};

export default BankJagoTncModal;
