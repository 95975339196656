import LazyModal from 'features/common/LazyModal';
import React from 'react';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

const OnboardingRoboConfigModalLazy = React.lazy(
  () => import('./OnboardingRoboConfigModal')
);

const OnboardingRoboConfigModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'robo_configuration';

  return (
    <LazyModal component={OnboardingRoboConfigModalLazy} visible={visible} />
  );
};

export default OnboardingRoboConfigModal;
