import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useModalUpdateReminder } from './provider/ModalUpdateReminderProvider';

const ModalUpdateReminder = React.lazy(
  () => import('./GlobalModalUpdateReminder')
);

const LazyModalUpdateReminder = () => {
  const { visible } = useModalUpdateReminder();
  return <LazyModal component={ModalUpdateReminder} visible={visible} />;
};

export default LazyModalUpdateReminder;
