import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import getSafely from 'utils/safely';
import { handleResponseGeneral } from 'utils/http-response';
import { getBankListUser } from 'services/user';
import { Dispatch } from 'redux';
import { TRootReducers } from 'store/root';
import { InstitutionBank } from 'features/institution/components/modals/sell-confirmation/network/resolver.type';

export interface BankUser {
  /**
   * Bank Status:
   * - 1: Verified
   * - 0: On Process
   * - 2: Amend Process
   * - 6: Export S-Invest process
   */
  bank_status: number;
  bank_id: number;
  user_bank_account_number: string;
  user_bank_account_name: string;
  bank_name: string;
  bank_color: string;
  bank_slot: number;
  bank_key: string;
  bank_currency: string;
  default: number;
  is_deletable: number;
  is_rdn?: number;
  is_sbn: number;
  bank_icon_url: string;
  bank_verified_at: string;
  /**
   * Partner ID:
   * - 1: Bibit
   * - 2: Link Aja
   * - 3: Shopee
   * - 4: JAGO
   * - 5: Blibli
   */
  partner_id: number;
}

interface DefaultStateBankAccounts {
  byId: {
    [bankIndentity: string]: BankUser;
  };
  allIds: string[];
}

const defaultState: DefaultStateBankAccounts = {
  byId: {},
  allIds: [],
};

const addMultipleBankAccountEntry = createAction<BankUser[]>(
  'ADD_MULTIPLE_BANK_ACCOUNT_ENTRY'
);

//REDUCER
const bankAccountReducer = createReducer<typeof defaultState>({}, defaultState);

bankAccountReducer.on(
  addMultipleBankAccountEntry,
  (state, payload: BankUser[]) => {
    /**
     * Filter all eligible bank accounts:
     * - Regular Banks -> bank_status = 1 (verified)
     * - Non SBN banks -> is_sbn = 0
     * - RDN banks -> is_rdn = 1 & is_deletable = 0 (active/inactive and cannot be deleted)
     */
    const bankAccountStatusActive = payload?.filter((bank) => {
      const activeBanks = bank?.bank_status === 1 && bank?.is_rdn === 0;
      const nonSbn = bank?.is_sbn === 0;
      const rdnBanks = bank.is_rdn === 1 && bank?.is_deletable === 0;

      return nonSbn && (activeBanks || rdnBanks);
    });

    const bankAccountEntries = bankAccountStatusActive?.reduce(
      (data, currValue) => ({
        ...data,
        [`${currValue.bank_id}#${currValue.user_bank_account_number}`]:
          currValue,
      }),
      {}
    );

    return update(state, {
      byId: {
        $set: {
          ...bankAccountEntries,
        },
      },
      allIds: { $set: Object.keys(bankAccountEntries) },
    });
  }
);

export { addMultipleBankAccountEntry };

// SELECTOR
/**
 * Get multiple bank account detailed data by passing
 * @param {Array<String>} arrayOfBankAccount - Array of bank account id
 * @param {Object}        state              - Redux state tree
 */
export const getMultipleBankAccountDetails = (
  arrayOfBankAccount: string[],
  state: TRootReducers
) => {
  return arrayOfBankAccount
    .map((item) => getSafely(['entities', 'bankAccounts', 'byId', item], state))
    ?.filter((item) => (item?.bank_id ?? 0) > 0);
};

/**
 * Get the object containing user bank account detail using specified identifier with format 'bank_id#user_bank_number'
 * @param {String} bankIdentifier - String formatted with 'bank_id#user_bank_number' format
 * @param {Object} state - Redux state tree
 */
export const getBankAccountDetail = (
  bankIdentifier: string,
  state: TRootReducers
) => getSafely(['entities', 'bankAccounts', 'byId', bankIdentifier], state);

/**
 * Get bank bank_slot by bankIdentifier
 * @param {*} bankIdentifier
 * @param {*} state
 */
export const getBankSlotID = (bankIdentifier: string, state: TRootReducers) => {
  return getSafely(
    ['entities', 'bankAccounts', 'byId', bankIdentifier, 'bank_slot'],
    state
  );
};

/**
 * Get all fetched user bank account data inside redux
 * @param {Object} state - Redux state tree
 */
export const getAllBankAccountDetails = (state: TRootReducers) => {
  const arrayOfBankAccountIds = getSafely(
    ['entities', 'bankAccounts', 'allIds'],
    state
  );
  if (Array.isArray(arrayOfBankAccountIds)) {
    return arrayOfBankAccountIds.map((item) =>
      getSafely(['entities', 'bankAccounts', 'byId', item], state)
    );
  }
  return [];
};

// THUNKS
/**
 * Get all available bank account
 * @return Promise<Array<BankData>> - Return berupa Promise yang meresolve array of user data banks
 */
export function getBankAccounts({ unmask = 0 } = {}) {
  return (dispatch: Dispatch) => {
    return getBankListUser({ unmask })
      .then((rawResponse) => {
        const response = handleResponseGeneral(rawResponse);
        dispatch(addMultipleBankAccountEntry(response.data));
        return response.data;
      })
      .catch(() => {});
  };
}

// Utils

/**
 * Check wether current bank status is still in verification process.
 */
export function isNonVerifiedBank(bank?: BankUser | InstitutionBank) {
  return (
    bank?.bank_status === 0 ||
    bank?.bank_status === 2 ||
    bank?.bank_status === 6
  );
}

export default bankAccountReducer;
