/**
 * services/auth.js
 * API Service for authentification, usually for validate token in backend like
 * change passwod, email, phone, dll. Also for login register, but not refactor yet.
 */

import list from '.';
import { BibitApiSuccessResponse } from 'services';
import { AxiosResponse } from 'axios';
import { VerifyTokenNewPhone } from './auth.type';
import http from 'core/http';
import * as Parent from 'core/Parent';
import queryClient from 'network/queryClient';

export function checkKTP(ktp: string, token: string) {
  return http.post(list.ktpValidate, { ktp, token });
}

/**
 *
 * @param {
 * pin: number,
 * type: string,
 * token: string
 * } tobody
 */
export function checkPIN(tobody: any) {
  return http.post(list.pinCheck, tobody);
}

/**
 *
 * @param {
 * code: number
 * phone: number
 * via?: string // optional
 * } tobody
 */
export function reqOTP(tobody: any) {
  return http.post(list.otpRequest, tobody);
}

/**
 *
 * @param none;
 * POST: request otp for change pin
 *
 */
export function reqOtpChangePin(via: string) {
  return http.post(list.otpRequestChangePin, { via });
}

export function reqForgetPhone(email: string) {
  return http.post(list.reqForgetPhone, { email });
}

/**
 *
 * @param {string} token
 */
export function verifyForgetPhone(
  token: string
): Promise<AxiosResponse<BibitApiSuccessResponse<VerifyTokenNewPhone>>> {
  return http.post(list.verifyForgetPhone, { token });
}

/**
 *
 * @param {
 * token,
 * identity,
 * pin,
 * phonecode,
 * phonenumber,
 * otp
 * } tobody
 */
export function updateNewPhone(tobody: any) {
  return http.post(list.phoneChangeUpdate, tobody);
}

/**
 * POST: Verify change email
 * @param {object} toBody
 */
export function postChangeEmailVerification(toBody: any) {
  return http.post(list.emailChangeVerify, toBody);
}

/**
 * POST: Request change email
 */
export function postRequestChangeEmail() {
  return http.post(list.emailChangeRequest);
}

/**
 * POST: Execute change phone
 * @param {object} toBody
 */
export function postChangePhone(toBody: any) {
  return http.post(list.changePhoneInSetting, toBody);
}

/**
 * POST: Verification token when change phone
 * @param {object} toBody
 */
export function postChangePhoneVerification(toBody: any) {
  return http.post(list.phoneChangeVerify, toBody);
}

/**
 * POST: Request change phone
 */
export function postChangePhoneRequest() {
  return http.post(list.phoneChangeRequest);
}

//http.post(list.logout)
/**
 * POST: Execute logout user
 */
export function postLogout() {
  return http.post(list.logout);
}

/**
 * POST: Login with OTP
 * @param {object} data
 */
export function postLoginOTP(data: any) {
  return http.post(list.loginOtp, data);
}

export interface LoginMethod {
  color?: string;
  icon?: string;
  id: number;
  method: string;
  name: string;
  status: number;
}

/**
 * GET: method login
 * @param page
 */
export const getLoginMethod = (
  page:
    | 'login'
    | 'login-resend'
    | 'register'
    | 'register-resend'
    | 'forgot-pin'
    | 'forgot-pin-resend',
  phoneCode: string
): Promise<AxiosResponse<BibitApiSuccessResponse<LoginMethod[]>>> => {
  return http.get(list.loginMethod(page, phoneCode));
};

/**
 * POST: execute register OTP
 * @param {object} toSend
 */
export function postRegisterOTP(toSend: any) {
  return http.post(list.registerOtp, toSend);
}

/**
 * POST: execute register Bank
 * @param {object} toSend
 */
export function postRegisterBank(toSend: any) {
  return http.post(list.registerBank, toSend);
}

/**
 * POST: register phone
 * @param {object} toSend
 */
export function postRegisterPhone(toSend: any) {
  return http.post(list.registerPhone, toSend);
}

/**
 * POST: register email
 * @param {object} toBody
 */
export function postRegisterEmail(toBody: any) {
  return http.post(list.registerEmailChange, toBody);
}

/**
 * POST: validate otp
 * @param {object} toSend
 */
export function postValidateOTP(toSend: any) {
  return http.post(list.otpValidate, toSend);
}

export const getClipboardOtp = (pathname: string) => {
  return new Promise<string | undefined>(() => {
    // remove previous otp cache
    queryClient.removeQueries({
      queryKey: ['OTP Clipboard'],
      exact: true,
    });
    return Parent.postData(`requestOtpFromClipboard`, {
      fn: 'requestOtpFromClipboard',
      data: {
        current_path: pathname,
      },
    });
  });
};
