import React, { useCallback, useState } from 'react';
import {
  OpenTopUpModalProperties,
  TopUpFlowGlobalState,
  TopUpFlowStep,
  SelectedGopayPromo,
  TriggeredFromType,
  TransactionType,
} from 'features/topup-modal/topupmodal.type';
import {
  useRecurringTopUpContextFunction,
  useRecurringTopUpContextState,
} from 'features/topup-modal/RecurringTopUpContext';
import {
  removeLatestTopUpProperties,
  saveTopUpProperties,
} from 'features/topup-modal/restoreTopUp';
import { getLatestTopUpProperties } from 'features/topup-modal/restoreTopUp';
import { useAppModalGlobalFunctions } from 'features/common/modals/AppModalGlobalContext';
import { useVerificationDoneStatus } from 'features/common/modals/ContinueRegistrationModal/useVerificationDoneStatus';
import { useUserDeactivateStatus } from 'features/common/modals/UserDeactiveModal/useUserDeactivateStatus';
import useFirstTimeBuyer from './hook/useFirstTimeBuyer';
import { Voucher } from 'features/voucher/types/voucher';

/**
 * Types that define collection that interacts with this topup modal
 */
interface TopUpModalFunctions {
  openTopUpModal: (payload: OpenTopUpModalProperties) => void;
  goToStep: (step: TopUpFlowStep) => void;
  setTriggeredFrom: (from: TriggeredFromType) => void;
  resetTopUpFlow: () => void;
  closeTopUpFlow: () => void;
  setUseCashback: (cahsback: boolean) => void;
  setSelectedPaymentMethod: (paymentMethod: string) => void;
  setAmount: (amount: number | undefined) => void;
  setConvenienceFee: (convenienceFee: number | undefined) => void;
  setHasConvenienceFeeFreeQuota: (
    hasConvenienceFeeFreeQuota: boolean | undefined
  ) => void;
  setStampDutyFee: (stampDutyFee: number | undefined) => void;
  setTotalAmount: (totalAmount: number | undefined) => void;
  setDetailModalTrigerredFrom: (
    detailModalTrigerredFrom: TopUpFlowStep | undefined
  ) => void;
  /**
   * set true if stamp_duty_fee exists for order preview and for fetching
   */
  setWithStampDuty: (withStampDuty: boolean) => void;
  setVoucherCode: (amount: string) => void;
  setVoucherData: (voucher?: Voucher) => void;
  paymentPrefix: () => string;
  setPortfolioId: (portofolioId: number | string) => void;
  setRoboId: (roboid: string) => void;
  setTransactionType: (type: 'single' | 'robo') => void;
  setTermsAgreed: (agreement: boolean) => void;
  setProducts: (symbols: string[]) => void;
  setGiftcardCode: (code: string) => void;
  setRecommendationCategory: (category: string) => void;
  setCurrency: (currency: 'IDR' | 'USD') => void;
  setMinBuy: (min: number) => void;
  setMaxBuy: (max: number) => void;
  setselectedGopayPromo: (promotionDetail: any) => void;
  /** Persisting latest state Top Up Flow into localStorage,
   * so we can restore the data whener user back to our app
   *
   */
  persistTopUpState: () => void;
  /**
   * Pull data latest State Top Up Flow from localStorage so we can restore latest up to where user in Top Up Flow
   */
  restoreOpenTopUp: () => void;
  /**
   * Determine whether to show USD products or not on `ExploreProductModal`
   * Used for gitfcard
   */
  setShowUSDSections: (enable: boolean) => void;

  setRedirectSaveTopup: (hasRedirect: boolean) => void;
}

interface HandleSetAmountInterface {
  minBuy: number;
  currency?: 'USD' | 'IDR';
  amount: number | undefined;
  giftcardCode: string;
  voucherData?: Voucher;
}

export const DEFAULT_PAYMENT_METHOD = 'rdn_bca';

/** Initial state properties Context Global Top Up  */
const TopUpGlobalContext = React.createContext<
  TopUpFlowGlobalState | undefined
>(undefined);

/** Initial state function Context Global Top Up */
const OpenTopUpModalContext = React.createContext<
  TopUpModalFunctions | undefined
>(undefined);

/**
 * Global Context Provider for Feature Top UP
 *
 */
const TopupGlobalContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  // TODO: Convert this to react reducer
  const [topUpVisible, setTopUpVisible] = useState(false);

  const [step, setStep] = useState<TopUpFlowStep>('input');

  const [triggeredFrom, setTriggeredFrom] =
    useState<TriggeredFromType>('default');

  const [transactionType, setTransactionType] =
    useState<TransactionType>('single');
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [convenienceFee, setConvenienceFee] = useState<number | undefined>(
    undefined
  );
  const [hasConvenienceFeeFreeQuota, setHasConvenienceFeeFreeQuota] = useState<
    boolean | undefined
  >(undefined);
  const [maxBuy, setMaxBuy] = useState<number | undefined>(undefined);
  const [stampDutyFee, setStampDutyFee] = useState<number | undefined>(
    undefined
  );
  const [totalAmount, setTotalAmount] = useState<number | undefined>(undefined);
  const [showAutocomplete, setShowAutocomplete] = useState(true);
  const [showPortfolioSelection, setShowPortfolioSelection] = useState(true);
  const [showPortfolioRoboOnly, setShowPortfolioRoboOnly] = useState(false);
  const [showProductSelection, setShowProductSelection] = useState(true);
  const [showRiskProfile, setShowRiskProfile] = useState(false);
  const [showRoboItem, setShowRoboItem] = useState(false);
  const [useCashback, setUseCashback] = useState<boolean>(false);
  const [products, setProducts] = useState<string[]>([]);
  const [productType, setProductType] = useState<string | undefined>();
  const [minBuy, setMinBuy] = useState<number>(10000);
  const [minCashbackAmount, setMinCashbackAmount] = useState<number>(10000);
  const [portfolioId, setPortfolioId] = useState<number | string | undefined>(
    undefined
  );
  const [roboId, setRoboId] = useState<string | undefined>(undefined);
  const [listOfPayments, setListOfPayments] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    DEFAULT_PAYMENT_METHOD
  );
  const [withStampDuty, setWithStampDuty] = useState<boolean>(false);

  const [voucherCode, setVoucherCode] = useState<string>('');

  const [giftcardCode, setGiftcardCode] = useState<string>('');

  const [cashbackEnabled, setCashbackEnabled] = useState(true);

  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [disableAmountInput, setDisableAmountInput] = useState<boolean>(false);

  const [currency, setCurrency] = useState<'IDR' | 'USD'>('IDR');

  const [recommendationCategory, setRecommendationCategory] =
    useState<string>('');
  const [voucherData, setVoucherData] = useState<any>(undefined);

  const [selectedGopayPromo, setselectedGopayPromo] =
    useState<SelectedGopayPromo | null>(null);

  const [showUSDSections, setShowUSDSections] = useState<boolean>(false);

  const [enableChangePortfolio, setEnableChangePortfolio] = useState(true);

  const [enableAddToCartButton, setEnableAddToCartButton] = useState(true);

  const [redirectSaveTopUp, setRedirectSaveTopup] = useState<boolean>(false);
  const [detailModalTrigerredFrom, setDetailModalTrigerredFrom] = useState<
    TopUpFlowStep | undefined
  >(undefined);
  const [withBackButton, setWithBackButton] = useState<boolean>(false);
  const [disableChangeProduct, setDisableChangeProduct] = useState(false);
  const [limitProducts, setLimitProducts] = useState<any[]>();

  const { createRecurring } = useRecurringTopUpContextState();
  const { setCreateRecurring } = useRecurringTopUpContextFunction();
  const { toggleContinueRegisModal, toggleUserDeactiveModal } =
    useAppModalGlobalFunctions();
  const isDoneVerification = useVerificationDoneStatus();
  const userHasRequestDeactivateAccount = useUserDeactivateStatus();

  const firstTimeBuyer = useFirstTimeBuyer();

  const resetTopUpFlow = () => {
    setTopUpVisible(false);
    goToStep('input');
    setTriggeredFrom('default');
    setTransactionType('single');
    setShowAutocomplete(true);
    setShowPortfolioSelection(true);
    setShowProductSelection(true);
    setShowRoboItem(false);
    setUseCashback(false);
    setProducts([]);
    setMinBuy(10000);
    setMaxBuy(undefined);
    setMinCashbackAmount(10000);
    setPortfolioId(undefined);
    setRoboId(undefined);
    setListOfPayments([]);
    setSelectedPaymentMethod(DEFAULT_PAYMENT_METHOD);
    setVoucherCode('');
    setAmount(undefined);
    setStampDutyFee(undefined);
    setTotalAmount(undefined);
    setCashbackEnabled(true);
    setTermsAgreed(false);
    setGiftcardCode('');
    setRecommendationCategory('');
    setDisableAmountInput(false);
    setVoucherData(undefined);
    setselectedGopayPromo(null);
    setEnableChangePortfolio(true);
    setEnableAddToCartButton(true);
    setProductType(undefined);
    setRedirectSaveTopup(false);
    setConvenienceFee(undefined);
    setHasConvenienceFeeFreeQuota(undefined);

    //Remove latest State in localStorage
    removeLatestTopUpProperties();

    // set default topup with recurring
    setCreateRecurring(false);
    setDisableChangeProduct(false);
    setLimitProducts(undefined);
  };

  /**
   * saving latest data state to localStorage
   *
   */
  const persistTopUpState = () => {
    if (!topUpVisible) return;
    // Saving latest state of top up flow, so we can restore the state when user back into our app
    saveTopUpProperties({
      step,
      triggeredFrom,
      products,
      productType,
      minBuy,
      maxBuy,
      transactionType,
      showAutocomplete,
      showPortfolioSelection,
      showProductSelection,
      showRoboItem,
      portfolioId,
      roboId,
      currency,
      voucherCode,
      selectedGopayPromo,
      cashbackEnabled,
      disableAmountInput,
      amount,
      convenienceFee,
      hasConvenienceFeeFreeQuota,
      totalAmount,
      stampDutyFee,
      withStampDuty,
      giftcardCode,
      voucherData,
      selectedPaymentMethod,
      termsAgreed,
      /**
       * set persist recurring always false if step is 'confirmation'
       */
      createRecurring: step !== 'confirmation' ? createRecurring : false,
      enableChangePortfolio,
      enableAddToCartButton,
      disableChangeProduct,
    });
  };

  const handleSetAmount = useCallback(
    ({
      currency,
      minBuy,
      amount,
      giftcardCode,
      voucherData,
    }: HandleSetAmountInterface) => {
      // amount from param
      if (!!amount) {
        return setAmount(amount);
      }

      /** new_user flow and IDR currency */
      const firstTimeBuyIDRMutualFund: boolean =
        firstTimeBuyer && currency === 'IDR';

      /** Reset amount for new_user flow with minbuy more than equal to 100.000 */
      const newUserResetAmountFlag: boolean =
        firstTimeBuyIDRMutualFund && minBuy >= 100000;

      const doNotPrefillAmount =
        newUserResetAmountFlag || !!giftcardCode || !!voucherData;

      // Let the amount empty
      if (doNotPrefillAmount) {
        return;
      }

      // Pre-fill data to 100.000 for new user flow with minbuy lower than 100.000
      if (firstTimeBuyIDRMutualFund && minBuy < 100000) {
        return setAmount(100000);
      }
    },
    [firstTimeBuyer]
  );

  const openTopUpModal = useCallback(
    ({
      step,
      triggeredFrom = 'default',
      products = [],
      productType = undefined,
      minBuy,
      maxBuy,
      minCashbackAmount = 10000,
      transactionType,
      showAutocomplete = true,
      showPortfolioSelection = false,
      showProductSelection = false,
      showRiskProfile = false,
      showRoboItem = false,
      portfolioId,
      roboId = undefined,
      currency = 'IDR',
      voucherCode = '',
      selectedGopayPromo = null,
      cashbackEnabled = true,
      disableAmountInput = false,
      amount = undefined,
      convenienceFee = undefined,
      hasConvenienceFeeFreeQuota = undefined,
      stampDutyFee = undefined,
      totalAmount = undefined,
      withStampDuty = false,
      giftcardCode = '',
      voucherData = undefined,
      createRecurring = false,
      showPortfolioRoboOnly = false,
      showUSDSections = true,
      enableChangePortfolio = true,
      enableAddToCartButton = true,
      withBackButton = false,
      disableChangeProduct = false,
      limitProducts,
    }: OpenTopUpModalProperties) => {
      if (userHasRequestDeactivateAccount) {
        return toggleUserDeactiveModal();
      }

      if (!isDoneVerification) {
        return toggleContinueRegisModal();
      }

      setTopUpVisible(true);

      goToStep(step);

      setTriggeredFrom(triggeredFrom);

      setWithBackButton(withBackButton && !!triggeredFrom);

      setTransactionType(transactionType);

      setShowAutocomplete(showAutocomplete);

      setShowPortfolioSelection(showPortfolioSelection);

      setShowProductSelection(showProductSelection);

      setShowRoboItem(showRoboItem);

      setShowRiskProfile(showRiskProfile);

      setProducts(products);

      setProductType(productType);

      setMinBuy(minBuy);

      setMinCashbackAmount(minCashbackAmount);

      setPortfolioId(portfolioId);

      setRoboId(roboId);

      setCurrency(currency);

      setVoucherCode(voucherCode);

      setselectedGopayPromo(selectedGopayPromo);

      setCashbackEnabled(cashbackEnabled);

      setDisableAmountInput(disableAmountInput);

      // Pre-fill amount globally
      handleSetAmount({ minBuy, currency, amount, giftcardCode, voucherData });

      setMaxBuy(maxBuy);

      setStampDutyFee(stampDutyFee);

      setTotalAmount(totalAmount);

      setConvenienceFee(convenienceFee);

      setHasConvenienceFeeFreeQuota(hasConvenienceFeeFreeQuota);

      setGiftcardCode(giftcardCode);

      setVoucherData(voucherData);

      setCreateRecurring(createRecurring);

      setShowPortfolioRoboOnly(showPortfolioRoboOnly);

      setShowUSDSections(showUSDSections);

      setEnableChangePortfolio(enableChangePortfolio);

      setEnableAddToCartButton(enableAddToCartButton);

      setWithStampDuty(withStampDuty);

      setDisableChangeProduct(disableChangeProduct);

      setLimitProducts(limitProducts);
    },
    [
      userHasRequestDeactivateAccount,
      isDoneVerification,
      handleSetAmount,
      setCreateRecurring,
      toggleUserDeactiveModal,
      toggleContinueRegisModal,
    ]
  );

  const restoreOpenTopUp = useCallback(async () => {
    const dataTopUp = await getLatestTopUpProperties();
    if (!dataTopUp) return;
    const {
      step,
      triggeredFrom,
      products,
      productType,
      minBuy,
      maxBuy,
      minCashbackAmount,
      transactionType,
      showAutocomplete,
      showPortfolioSelection,
      showProductSelection,
      showRiskProfile,
      showRoboItem,
      portfolioId,
      roboId,
      currency,
      voucherCode,
      selectedGopayPromo,
      cashbackEnabled,
      disableAmountInput,
      amount,
      convenienceFee,
      hasConvenienceFeeFreeQuota,
      stampDutyFee,
      totalAmount,
      giftcardCode,
      voucherData,
      selectedPaymentMethod,
      termsAgreed,
      createRecurring,
      enableChangePortfolio,
      enableAddToCartButton,
      withStampDuty,
      disableChangeProduct,
    } = dataTopUp;

    openTopUpModal({
      step,
      triggeredFrom,
      products,
      productType,
      minBuy,
      maxBuy,
      minCashbackAmount,
      transactionType,
      showAutocomplete,
      showPortfolioSelection,
      showProductSelection,
      showRiskProfile,
      showRoboItem,
      portfolioId,
      roboId,
      currency,
      voucherCode,
      selectedGopayPromo,
      cashbackEnabled,
      disableAmountInput,
      amount,
      convenienceFee,
      hasConvenienceFeeFreeQuota,
      stampDutyFee,
      totalAmount,
      giftcardCode,
      voucherData,
      createRecurring,
      enableChangePortfolio,
      enableAddToCartButton,
      withStampDuty,
      disableChangeProduct,
    });
    setSelectedPaymentMethod(selectedPaymentMethod ?? DEFAULT_PAYMENT_METHOD);
    setTermsAgreed(termsAgreed ?? false);
    setConvenienceFee(undefined);
    setHasConvenienceFeeFreeQuota(undefined);
  }, [openTopUpModal]);

  /**
   * go to the defined step
   * @param nextStep
   */
  const goToStep = (nextStep: TopUpFlowStep) => {
    setStep(nextStep);
  };

  const closeTopUpFlow = () => {
    setTopUpVisible(false);
  };

  const paymentPrefix = () => {
    const isInternationalCurrency = () => !!currency && currency !== 'IDR';
    const thePrefix = isInternationalCurrency() ? '$' : 'Rp';
    return thePrefix;
  };

  return (
    <TopUpGlobalContext.Provider
      value={{
        topUpVisible,
        products,
        productType,
        minBuy,
        maxBuy,
        minCashbackAmount,
        step,
        triggeredFrom,
        transactionType,
        portfolioId,
        showAutocomplete,
        showPortfolioSelection,
        showProductSelection,
        showRiskProfile,
        cashbackEnabled,
        showRoboItem,
        listOfPayments,
        useCashback,
        selectedPaymentMethod,
        amount,
        convenienceFee,
        hasConvenienceFeeFreeQuota,
        stampDutyFee,
        totalAmount,
        voucherCode,
        selectedGopayPromo,
        currency,
        roboId,
        termsAgreed,
        giftcardCode,
        recommendationCategory,
        disableAmountInput,
        voucherData,
        showPortfolioRoboOnly,
        showUSDSections,
        enableChangePortfolio,
        enableAddToCartButton,
        withStampDuty,
        redirectSaveTopUp,
        detailModalTrigerredFrom,
        withBackButton,
        disableChangeProduct,
        limitProducts,
      }}
    >
      <OpenTopUpModalContext.Provider
        value={{
          openTopUpModal,
          resetTopUpFlow,
          closeTopUpFlow,
          goToStep,
          setTriggeredFrom,
          setUseCashback,
          setSelectedPaymentMethod,
          setAmount,
          setConvenienceFee,
          setHasConvenienceFeeFreeQuota,
          setMaxBuy,
          setStampDutyFee,
          setTotalAmount,
          setVoucherCode,
          setVoucherData,
          paymentPrefix,
          setPortfolioId,
          setRoboId,
          setTransactionType,
          setTermsAgreed,
          setProducts,
          setGiftcardCode,
          setRecommendationCategory,
          setCurrency,
          setMinBuy,
          persistTopUpState,
          restoreOpenTopUp,
          setselectedGopayPromo,
          setShowUSDSections,
          setWithStampDuty,
          setRedirectSaveTopup,
          setDetailModalTrigerredFrom,
        }}
      >
        {children}
      </OpenTopUpModalContext.Provider>
    </TopUpGlobalContext.Provider>
  );
};

export default TopupGlobalContextProvider;

export const useTopUpGlobalContext = () => {
  const context = React.useContext(TopUpGlobalContext);
  if (context === undefined) {
    throw new Error(
      'useTopUpGlobalContext must be used within a TopUpGlobalContextProvider'
    );
  }
  return context;
};

export const useTopModalFunctions = () => {
  const context = React.useContext(OpenTopUpModalContext);

  if (context === undefined) {
    throw new Error(
      'useTopUpGlobalContext must be used within a TopUpGlobalContextProvider'
    );
  }

  return context;
};
