import type { CallbackType, ExcludeKeyType, StackFunctionType } from './types';

export interface Mapper {
  /**
   * Set 'callback' mapped with key
   *
   * @param {string} key  task/key name
   * @param {CallbakcType} cb callback to store
   */
  set: (key: string, cb: CallbackType) => void;

  /**
   * Get 'callback' using key
   *
   * @param {string} name task/key name
   * @returns {CallbackType} callback target
   */
  get: (key: string) => CallbackType;

  /**
   * Check hash is available
   */
  isHashAvailable: (key: string) => boolean;

  /**
   * Remove 'callback' using 'key'
   *
   * @param {string} name - task/key name
   */
  remove: (key: string) => void;

  /**
   * Set 'callback' using key, and set exclude key to prevent from being removed
   *
   * @param {string} name task/key name
   * @param {function} cb callback to store
   */
  register: (key: string, cb: CallbackType) => void;

  /**
   * Set key to exclude key from being removed
   *
   * @param {string} name task/key name
   */
  setExclude: (key: string) => void;

  /**
   * Get key excluded key
   *
   * @param {string} name task/key name
   * @returns {string} excluded key
   */
  getExclude: (key: string) => string | undefined;

  /**
   * get all pooled callback function in property `hashCollection`
   */
  getAllHashFunction: () => Partial<{
    [index: string]: CallbackType;
  }>;
}

/**
 * CallbackMapper (Key-Value storage for callback-mapper)
 *
 * - when postMessage to Native is executing it will register callback
 * - when onMessage called from native it will find by `id` as `key` in this hashCollection Object then call the function.
 */
class CallbackMapper implements Mapper {
  /**
   * excludeKey
   * key of callback in stackFunction that won't remove even after onMessage is received and success to executed callback in stackFunction
   * @type {ExcludeKeyType}
   */
  excludeKey: ExcludeKeyType = {};

  /**
   * hashCollection
   * collection hash of callback
   * @type {StackFunctionType}
   */
  hashCollection: StackFunctionType = {};

  /**
   * Set 'callback' mapped with key
   *
   * @param {string} name  task/key name
   * @param {CallbakcType} cb callback to store
   */
  set(key: string, cb: CallbackType): void {
    this.hashCollection[key] = cb;
  }

  /**
   * Check hashcollection is available
   * @param key
   * @returns
   */
  isHashAvailable(key: string): boolean {
    return this.hashCollection[key] ? true : false;
  }

  /**
   * Get 'callback' using key
   *
   * @param {string} name task/key name
   * @returns {CallbackType} callback target
   */
  get(key: string): CallbackType {
    const cb: CallbackType | undefined = this.hashCollection[key];

    if (typeof cb === 'undefined') return () => {};

    return cb;
  }

  /**
   * Remove 'callback' using 'key'
   *
   * @param {string} name task/key name
   */
  remove(key: string): void {
    if (!this.isExclude(key)) delete this.hashCollection[key];
  }

  /**
   * Set 'callback' using key, and set exclude key to prevent from being removed
   *
   * @param {string} name task/key name
   * @param {function} cb callback to store
   */
  register(key: string, cb: CallbackType): void {
    this.set(key, cb);

    this.setExclude(key);
  }

  /**
   * Check if name is excluded
   *
   * @param {string} name task/key name
   * @returns {boolean} is excluded(True) or not(False)
   */
  isExclude(key: string): boolean {
    const value = this.excludeKey[key];

    return value ? true : false;
  }

  /**
   * Set key to exclude key from being removed
   *
   * @param {string} name task/key name
   */
  setExclude(key: string): void {
    this.excludeKey[key] = key;
  }

  /**
   * Get key excluded key
   *
   * @param {string} name task/key name
   * @returns {string} excluded key
   */
  getExclude(key: string): string | undefined {
    return this.excludeKey[key];
  }

  /**
   * get all pooled callback function in property `hashCollection`
   */
  getAllHashFunction() {
    return this.hashCollection;
  }
}

/**
 * Shared instance object
 *
 * @type {Mapper}
 */
let instanceCallbackMapper: Mapper;

/**
 * Get shared instance object
 * by using singleton-pattern to prevent mismatched registered key in-memory
 *
 * @returns {Mapper} shared instance of CallbackMapper
 */
export function getInstance(): Mapper {
  if (instanceCallbackMapper) return instanceCallbackMapper;

  instanceCallbackMapper = new CallbackMapper();

  return instanceCallbackMapper;
}

export default CallbackMapper;
