import React, { useState, useCallback, useEffect } from 'react';
import { getInvitation } from '../../../networks';
import { SharedPortfolioInvitation } from '../../../types';
import Storage from 'core/Storage';

type SharedInvitationStep =
  | 'invitation'
  | 'invitation_confirmation'
  | undefined;

interface SharedInvitationState {
  sharedInvitationStep: SharedInvitationStep;
  /*
   * List of invitations that user has
   */
  invitation: SharedPortfolioInvitation[];
  invitationLoading: boolean;
}

interface SharedInvitationFunction {
  resetSharedInvitationStep: () => void;
  getSharedPortfolioInvitation: () => void;
  setInvitationLoading: (value: boolean) => void;
  setSharedInvitationStep: (step: SharedInvitationStep) => void;
}

/** Initial state properties Shared Invitation Context  */
const SharedInvitationStateContext = React.createContext<
  SharedInvitationState | undefined
>(undefined);

/** Initial Funcition properties Shared Invitation Context  */
const SharedInvitationFunctionContext = React.createContext<
  SharedInvitationFunction | undefined
>(undefined);

const SharedInvitationContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [sharedInvitationStep, setSharedInvitationStep] =
    useState<SharedInvitationStep>(undefined);
  const [invitation, setInvitation] = useState<SharedPortfolioInvitation[]>([]);

  const [invitationLoading, setInvitationLoading] = useState(false);

  const resetSharedInvitationStep = () => {
    setSharedInvitationStep(undefined);
  };

  const getSharedPortfolioInvitation = useCallback(async () => {
    if (document.visibilityState !== 'visible') return;

    const alreadyShowInvitation = await Storage.getObject(
      'alreadyShowInvitation'
    );

    try {
      setInvitationLoading(true);
      const res = await getInvitation();
      setInvitationLoading(false);

      const invitationData = res?.data?.data || [];
      setInvitation(invitationData);

      if (!alreadyShowInvitation && invitationData.length > 0) {
        // show invitation modal
        setSharedInvitationStep('invitation');
        Storage.setObject('alreadyShowInvitation', 'true');
      }
    } catch (error) {
      setInvitationLoading(false);
    }
  }, []);

  const removeStorageShowInvitation = () => {
    Storage.removeObject('alreadyShowInvitation');
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', removeStorageShowInvitation);
    return () => {
      document.removeEventListener(
        'visibilitychange',
        removeStorageShowInvitation
      );
    };
  }, []);

  useEffect(() => {
    removeStorageShowInvitation();
  }, []);

  return (
    <SharedInvitationStateContext.Provider
      value={{
        sharedInvitationStep,
        invitation,
        invitationLoading,
      }}
    >
      <SharedInvitationFunctionContext.Provider
        value={{
          setSharedInvitationStep,
          resetSharedInvitationStep,
          getSharedPortfolioInvitation,
          setInvitationLoading,
        }}
      >
        {children}
      </SharedInvitationFunctionContext.Provider>
    </SharedInvitationStateContext.Provider>
  );
};

export default SharedInvitationContextProvider;

export const useSharedInvitationStateContext = () => {
  const context = React.useContext(SharedInvitationStateContext);
  if (context === undefined) {
    throw new Error(
      'useSharedInvitationStateContext must be used within SharedInvitationContextProvider'
    );
  }
  return context;
};

export const useSharedInvitationFunctionContext = () => {
  const context = React.useContext(SharedInvitationFunctionContext);
  if (context === undefined) {
    throw new Error(
      'useSharedInvitationFunctionContext must be used within SharedInvitationContextProvider'
    );
  }
  return context;
};
