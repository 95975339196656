import React, { useEffect } from 'react';
import './BibitLoader.css';
import Loading from '../loader/Loading';
import { COLOR_BAR_ALL_SURFACE } from 'core/statusbar/constants';
import { postDataSetAppBottomBarColor } from 'core/statusbarbottom';
import { useStatusBarFunction } from 'core/statusbar/context/StatusBarContext';

const BibitLoader: React.FC<React.PropsWithChildren<unknown>> = () => {

  const { onSetStatusBarColor } = useStatusBarFunction();

  /**
   * Sync top-bottom navbar
   */
  useEffect(() => {
    const colorBar = COLOR_BAR_ALL_SURFACE();

    // @ set color bottom
    postDataSetAppBottomBarColor(
      colorBar.bgColor,
      colorBar.foregroundColor
    );

    // @ set color status bar
    onSetStatusBarColor({
      backgroundColor: colorBar.bgColor,
      foregroundColor: colorBar.foregroundColor,
    });
  }, [onSetStatusBarColor]);

  return (
    <div className='bibit-loader' data-testid='splashscreen'>
      <Loading />
    </div>
  );
};

export default BibitLoader;
