import { matchPath } from 'react-router-dom';

/**
 * Determine should tabbar item active or not
 *
 * @param pathName - path of current Location
 * @param routes - List of routes that want to flag active
 */
export const tabbarActive = (pathName: string, routes: string[]) => {
  const match = matchPath(pathName, {
    path: routes,
    exact: true,
  });

  return !!match;
};
