import {
  useContextEarlyRedemptionStateFunction,
  StepEarlyRedemption,
} from '../contexts';
import { SbnEarlyRedempInfo } from '../contexts/type';

export interface OpenEarlyRedeem {
  step: StepEarlyRedemption;
  /** productid or serie id sbn product */
  productId: string;
  /** amount of user want to early redeem */
  amount?: number;
  /** Redemption info SBN */
  redeemInfo: SbnEarlyRedempInfo;
  /** Order code from API */
  orderCode: string;
}

export const useOpenEarlyRedemption = () => {
  const {
    setStep,
    setAmount,
    setProductId,
    setEarlyRedeemInfo,
    setOrderCode,
  } = useContextEarlyRedemptionStateFunction();

  const openRedeem = (data: OpenEarlyRedeem) => {
    setStep('input_amount');
    setAmount(data.amount);
    setProductId(data.productId);
    setEarlyRedeemInfo(data.redeemInfo);
    setOrderCode(data.orderCode);
  };

  return openRedeem;
};

export const useResetEarlyRedemption = () => {
  const {
    setStep,
    setAmount,
    setProductId,
    setEarlyRedeemInfo,
    setOrderCodeRedeem,
    setRedeemBankAt,
  } = useContextEarlyRedemptionStateFunction();

  const reset = () => {
    setStep(undefined);
    setAmount(undefined);
    setProductId(undefined);
    setEarlyRedeemInfo(undefined);
    setOrderCodeRedeem(undefined);
    setRedeemBankAt(undefined);
  };

  return reset;
};
