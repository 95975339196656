import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

const defaultState = {
  featureMatrix: {},
  loadingBiometric: false,
  loadingDialog: { visible: false, message: '' },
};

const [
  setFeatureMatrix,
  setLoadingBiometric,
  genericLoadingDialog,
  resetGenericLoadingDialog,
] = [
  'COMMON/GENERIC_FEATURE_MATRIX_ADD',
  'COMMON/BIOMETRIC_LOADING',
  'COMMON/GENERIC_LOADING_DIALOG',
  'COMMON/RESET_GENERIC_LOADING_DIALOG',
].map(createAction);

const common = createReducer(
  {
    [setFeatureMatrix]: (state, payload) => {
      const { key, value } = payload || '';
      return update(state, {
        featureMatrix: {
          [key]: {
            $set: value,
          },
        },
      });
    },
    [setLoadingBiometric]: (state, payload) => {
      return update(state, {
        loadingBiometric: { $set: payload },
      });
    },
    /**
     *
     * @param {*} state
     * @param { visible: boolean;  message string } payload
     * @returns
     */
    [genericLoadingDialog]: (state, payload) => {
      return update(state, {
        loadingDialog: {
          $set: payload,
        },
      });
    },
    [resetGenericLoadingDialog]: (state) => {
      return update(state, {
        loadingDialog: {
          $set: { visible: false, message: '' },
        },
      });
    },
  },
  defaultState
);

export {
  setFeatureMatrix,
  setLoadingBiometric,
  genericLoadingDialog,
  resetGenericLoadingDialog,
};

export default common;
