import React, { useState, createContext, useCallback } from 'react';

interface Value {
  entryPoint: string;
  handleSetEntryPoint: (val: string) => void;
}

export const GlobalRecurringContext = createContext<Value | undefined>(
  undefined
);

/**
 * For now, its just store entry point of where recurring page is accessed from,
 *  which later can be used to go back there
 */
const GlobalRecurringProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [entryPoint, setEntryPoint] = useState('');

  const handleSetEntryPoint = useCallback((value: any) => {
    setEntryPoint(value);
  }, []);

  const contextValue = { entryPoint, handleSetEntryPoint };

  return (
    <GlobalRecurringContext.Provider value={contextValue}>
      {children}
    </GlobalRecurringContext.Provider>
  );
};

export const useGlobalRecurringContext = () => {
  const context = React.useContext(GlobalRecurringContext);
  if (context === undefined) {
    throw new Error(
      'useGlobalRecurringContext must be used within GlobalRecurringProvider'
    );
  }
  return context;
};

export default GlobalRecurringProvider;
