import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

const defaultState = {
  visible: false,
  showLinkAjaError: false,
  errorType: '',
  title: '',
  message: '',
  description: '',
  buttonConfig: {
    text: '',
    onClick: () => {},
    closeModalOnClick: false,
  },
  /**
   * @type function | null
   * A custom function to close modal of IndexErrorModal
   * */
  onCloseModal: null,
};

const [
  openErrorModal,
  openLinkAjaErrorModal,
  closeErrorModal,
  resetErrorModalState,
  closeLinkAjaErrorModal,
] = [
  'OPEN_ERROR_MODAL',
  'OPEN_LINKAJA_ERROR_MODAL',
  'CLOSE_ERROR_MODAL',
  'RESET_ERROR_MODAL',
  'CLOSE_LINKAJA_ERROR_MODAL',
].map(createAction);

const errorModal = createReducer(
  {
    [openErrorModal]: (state, payload) => {
      // Make sure the message payload type is string
      const message =
        typeof payload.message === 'string'
          ? payload.message
          : 'Terjadi kesalahan. Mohon coba kembali.';

      return update(state, {
        visible: { $set: true },
        errorType: { $set: String(payload.type).toUpperCase() },
        title: { $set: payload.title },
        message: { $set: message },
        description: { $set: payload.description },
        buttonConfig: { $set: payload.buttonConfig },
        onCloseModal: { $set: payload.onCloseModal },
      });
    },
    [openLinkAjaErrorModal]: (state, payload) => {
      return update(state, {
        showLinkAjaError: { $set: true },
      });
    },
    [closeErrorModal]: (state) => {
      return update(state, {
        visible: { $set: false },
      });
    },
    [resetErrorModalState]: (state) => {
      return update(state, {
        $merge: { ...defaultState },
      });
    },
    [closeLinkAjaErrorModal]: (state) => {
      return update(state, {
        showLinkAjaError: { $set: false },
      });
    },
  },
  defaultState
);

export {
  openErrorModal,
  closeErrorModal,
  resetErrorModalState,
  openLinkAjaErrorModal,
  closeLinkAjaErrorModal,
};

export default errorModal;
