import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, matchPath, useLocation } from 'react-router-dom';
import { IGNORE_WHITELIST_URL, WHITELIST_URL } from '../../constants';
import useProfileData from 'hooks/useProfileData';
import useAuth from 'hooks/useAuth';
import PinChallengePage from '../PinChallengePage';
import { checkIsAfterPinLogin, checkIsNeedPinAfterLogin } from 'utils/auth';
import Analytics from 'utils/Analytics';
import { useStatusBarFunction } from 'core/statusbar/context/StatusBarContext';
import { postDataSetAppBottomBarColor } from 'core/statusbarbottom';
import {
  COLOR_BAR_ALL_SURFACE,
  COLOR_BAR_DEFAULT,
} from 'core/statusbar/constants';
import Storage from 'core/Storage';
import { useFlag } from 'utils/feature-flag';
import { Loading } from 'features/common';

/** Context State */
interface State {
  /** tell us is user  */
  hasInputPin: boolean;
}

/** Context Function for updater */
interface Function {
  setHasInputPin: (passed: boolean) => void;
}

const StateContext = React.createContext<State | undefined>(undefined);

const FunctionContext = React.createContext<Function | undefined>(undefined);

/**
 * Simple context provider to handling feature PIN Challenge.
 *
 * also validating should show PIN Challenge or not before render children component
 */
const PinProtection: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const institutionAccOnlyFF = useFlag('web_institution_without_individual');

  const { onSetStatusBarColor } = useStatusBarFunction();

  /** one of three parameter that considered to show pin form or not
   *
   * also passing this value to children with Context
   */
  const [hasInputPin, setHasInputPin] = useState(false);
  const [pageIsReady, setPageIsReady] = useState(false);
  /**
   * tell us is user from login with pin
   */
  const [userLoginWithPin, setUserLoginWithPin] = useState(false);

  /**
   * tell us is user from login without pin and need to input pin
   */
  const [needPinAfterLogin, setNeedPinAfterLogin] = useState(false);

  const history = useHistory();

  /** Check is user from login-with-pin */
  useEffect(() => {
    const checkIsNeedPin = async () => {
      /**
       * user already login with pin
       */
      const isAfterPinLogin = await checkIsAfterPinLogin();

      /**
       * user login without pin
       */
      const isNeedPinAfterLogin = await checkIsNeedPinAfterLogin();

      const splashScreenTime = window?.splashScreenTime;
      const currentTime = new Date().getTime();

      const compareTime = currentTime - (splashScreenTime || 3001);

      if (compareTime > 3000) {
        setPageIsReady(true);

        // Remove html initial splash-screen
        document.getElementById('bibit-splash-screen')?.remove();
      } else {
        setTimeout(() => {
          setPageIsReady(true);

          // Remove html initial splash-screen
          document.getElementById('bibit-splash-screen')?.remove();
        }, 3000 - compareTime);
      }

      setUserLoginWithPin(isAfterPinLogin);
      setNeedPinAfterLogin(isNeedPinAfterLogin);
    };

    checkIsNeedPin();
  }, []);

  const location = useLocation();

  /** matching current Pathname and WHITELIST_URL */
  const match = matchPath(location.pathname, {
    path: WHITELIST_URL,
    exact: true,
  });

  const ignoreWhitelist = IGNORE_WHITELIST_URL[match?.path || ''] || [];
  const shouldIgnore = ignoreWhitelist.includes(match?.url || '');

  const pathnameGetWhitelist = shouldIgnore ? false : match?.isExact === true;

  // Get auth context from hook
  const { isLogin: isLoggedIn } = useAuth();

  // Get profile data from hook
  const { data: dataProfile } = useProfileData(isLoggedIn);
  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  // tell us is pin user Active
  const pinUserActive = !!profileDetail?.preference?.pinChallengeStatus;

  // tell us user already set pin
  const userHasPin = !!profileDetail?.pin;

  // tell us user active status 4, mean user verify KYC
  const userStatusVerify = profileDetail?.active === 4;

  /**
   * tell us is the user get banned
   */
  const userSuspended = !!profileDetail?.suspend;

  const userHasInstitutions =
    !!profileDetail?.institutions && profileDetail?.institutions?.length > 0;

  // get `institution account only` data
  const isIndividualDummyAccount = profileDetail?.user?.status === 5;
  const isRegisteredAsInstiAccountOnly =
    !!profileDetail?.preference?.is_registered_as_institutional_account_only;
  const isInstitutionAccountOnly =
    userHasInstitutions &&
    isIndividualDummyAccount &&
    isRegisteredAsInstiAccountOnly;

  /**
   * Show pin challenge for setup pin when
   * - user is institution account only
   * - current pathname is not in List of Whitelist
   * - user doesn't has pin
   */
  const showPinSetupForInstitutionAccOnly =
    institutionAccOnlyFF &&
    isInstitutionAccountOnly &&
    !pathnameGetWhitelist &&
    !userHasPin;

  /**
   * we should show PIN Challenge when:
   *
   * case 1 (individual or institution)
   *  - pin user active
   *  - user not input pin yet
   *  - current pathname is not in List of Whitelist
   *  - User not from login with pin
   *
   * case 2 (individual or institution)
   *  - user after login without pin
   *  - and current pathname is not in List of Whitelist
   *
   * case 3 (institution account only)
   * - `showPinSetupForInstitutionAccOnly`
   */
  const showPinChallenge =
    (pinUserActive &&
      !hasInputPin &&
      !pathnameGetWhitelist &&
      !userLoginWithPin) ||
    (needPinAfterLogin &&
      userHasPin &&
      userStatusVerify &&
      !userSuspended &&
      !pathnameGetWhitelist) ||
    showPinSetupForInstitutionAccOnly;

  /**
   * changing top and bottom bar
   */
  useEffect(() => {
    if (showPinChallenge) {
      const colorBar = COLOR_BAR_ALL_SURFACE();

      // @ set color bottom
      postDataSetAppBottomBarColor(colorBar.bgColor, colorBar.foregroundColor);

      // @ set color status bar
      onSetStatusBarColor({
        backgroundColor: colorBar.bgColor,
        foregroundColor: colorBar.foregroundColor,
      });
    }
  }, [showPinChallenge, onSetStatusBarColor]);

  /**
   * handling when user success input pin
   */
  const handleSuccessPin = useCallback(async () => {
    setHasInputPin(true);

    /**
     * when user is need pin after login
     * remove storage "needPinAfterLogin" after user success pin challenge
     */
    if (needPinAfterLogin) {
      try {
        await Storage.remove('needPinAfterLogin');
        setNeedPinAfterLogin(false);
      } catch (_) {}
    }

    const colorBar = COLOR_BAR_DEFAULT();

    // @ set color bottom
    postDataSetAppBottomBarColor(
      colorBar.bottom.bgColor,
      colorBar.bottom.foregroundColor
    );

    // @ set color status bar
    onSetStatusBarColor({
      backgroundColor: colorBar.top.bgColor,
      foregroundColor: colorBar.top.foregroundColor,
    });

    // This analytics is used to give signal for native apps
    // To run timer app function to determine when user is idle
    // Native will force refresh webview and give query params idle=1
    Analytics.logEventAction({
      eventName: 'challenge_pin_action',
      parameter: {
        action: 'success_challenge_pin',
        context: 'pin.challenge_pin',
        trigger: 'api',
      },
    });

    // put location state `hasInputPin` to be true
    // so the PIN Challenge in Level Route will not show due hasInputPin is true
    history.replace({
      ...history.location,
      state: { ...(history?.location?.state || {}), hasInputPin: true }, // include current location state
      // Remove hash param (ex: '#idle')
      hash: '',
    });
  }, [history, onSetStatusBarColor, needPinAfterLogin]);

  const handleForgetPin = async () => {
    return history.push('/profile/settings/pin');
  };

  if (!pageIsReady || (isLoggedIn && !profileDetail)) {
    return <Loading />;
  }

  if (showPinChallenge) {
    return (
      <div className='router'>
        <PinChallengePage
          needBiometric
          showForgotPin
          showSupport
          onSuccessPin={handleSuccessPin}
          onClickFogetPin={handleForgetPin}
        />
      </div>
    );
  }

  return (
    <StateContext.Provider value={{ hasInputPin }}>
      <FunctionContext.Provider
        value={{
          setHasInputPin,
        }}
      >
        {children}
      </FunctionContext.Provider>
    </StateContext.Provider>
  );
};

export default PinProtection;

export const useContextPinChallengeState = () => {
  const ctx = useContext(StateContext);
  if (!ctx) {
    throw new Error('PinChllenge StateContext out of bound');
  }

  return ctx;
};

export const useContextPinChallengeFunction = () => {
  const ctx = useContext(FunctionContext);
  if (!ctx) {
    throw new Error('PinChllenge FunctionContext out of bound');
  }

  return ctx;
};
