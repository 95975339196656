import React from 'react';
import LazyLoad from 'features/common/lazyload';

const Home = React.lazy(() => import('./Home'));

const HomeLazy = (props: any) => {
  return <LazyLoad animationLoading component={Home} {...props} />;
};

export default HomeLazy;
