import * as React from 'react';
import Light from './recommended.svg';
import Dark from './recommended-dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: Light,
  dark: Dark,
  default: Light,
};

export const RecommededIcon: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default RecommededIcon;
