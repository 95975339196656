import { useJagoCheckDifferentLoggedIn } from 'features/bankjago/contexts/JagoCheckDifferentLoggedInProvider';
import LazyModal from 'features/common/LazyModal';
import React, { lazy } from 'react';

const Modal = lazy(() => import('./BankJagoDifferentLoginModal'));

const BankJagoDifferentLoginModal = () => {
  const { isDifferentLoggedIn } = useJagoCheckDifferentLoggedIn();
  return <LazyModal component={Modal} visible={isDifferentLoggedIn} />;
};

export default BankJagoDifferentLoginModal;
