import React from 'react';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import getSafely from 'utils/safely';
import {
  numberToGeneralValueFormat,
  determineCurrencyLetter,
} from 'utils/stringHelper';
import classNames from 'classnames';
import { ReksaDana } from 'entities/reksadana.reducer';

interface CardProps {
  fundData: ReksaDana;
  isComparing?: boolean;
  selected?: boolean;
  onClick: (roboCategory: string, symbol: string) => void;
}

const ReksaDanaMinimumAmountCard: React.FC<
  React.PropsWithChildren<CardProps>
> = ({ fundData, selected, isComparing, onClick }) => {
  const { name, type, investment_manager } = fundData;
  const minbuy: number = getSafely(['minbuy'], fundData, 0) as number;
  let _minbuy = numberToGeneralValueFormat(minbuy);
  const currencyUsed = getSafely(
    ['currency_exchange', 'currency'],
    fundData,
    'IDR'
  );
  const displayedCurrency =
    determineCurrencyLetter(currencyUsed) === '$' ? '$' : 'Rp ';

  const handleClick = () => {
    const { robocategory, symbol } = fundData;
    onClick(robocategory, symbol);
  };

  return (
    <div
      className={classNames({
        'bit-filter-result-row': true,
        'bit-filter-minimum': true,
        'card-selected': selected,
        'card-reksa-shake': isComparing,
      })}
    >
      <div onClick={handleClick}>
        <div className='bit-filter-reksa'>
          <div className='bit-filter-reksa-pic'>
            <InvestmentCompanyLogo
              type='investment-manager'
              size='square'
              ojkCode={getSafely(['ojkCode'], investment_manager, '')}
            />
          </div>
          <div>
            <h3>{name}</h3>
            <p>{type}</p>
          </div>
        </div>
        <div className='bit-filter-right-result'>
          <p>
            {displayedCurrency}
            {_minbuy}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReksaDanaMinimumAmountCard;
