import { HttpResponse } from 'core/http/http.type';
import { useStorageCurrentInstutiton } from 'features/institution/hooks';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  fetchGoalChartProgress,
  fetchGoalOnTrackStatus,
  postGoalRecalculateAmount,
} from 'services/goalSettings';
import {
  getPortfolioByCategory,
  getPortfolioCategory2,
  fetchGoalDetail,
} from 'services/portfolio';
import { Portfolio } from '../types';

export interface PortfolioCategoryData {
  /** for robo type portfolio
   * 0 = Single Portfolio,
   * 1 = Robo Portfolio
   */
  robo_type: number;

  /** for paramenter instrument group
   * 0 = Reksadana,
   * 1 = SBN,
   * 2 = Saham
   */
  instrument_group: number;

  /** for company symbole
   * ex: `RD01`
   */
  company_symbol?: any; // ex: 'RD01'
}

/**
 * @deprecated Please use useFetchPortfolioCategoryId
 * in 'features/portfolio/networks/resolvers.ts'
 *
 * Fetching Detail Data Portfolio By ID (portfolioid ATAU categoryid SAMA AJA!!)
 * @param portfolioID
 * @param enabled default true
 */
export const usePortfolioDataByID = (
  portfolioId: number | string,
  enabled: boolean = true
) => {
  const { data, isFetching } = useStorageCurrentInstutiton();
  const inInstitutionAccount = !!data?.institution_id;

  const eligibleToFetch =
    !!portfolioId && enabled && !inInstitutionAccount && !isFetching;
  return useQuery(
    ['Portfolio Detail', portfolioId],
    () => getPortfolioByCategory(portfolioId),
    {
      enabled: eligibleToFetch,
    }
  );
};

/**
 * Fetching List Portfolio User
 * @param enabled
 */
export const useFetchPortfolioCategory = (
  enabled: boolean = true,
  portfolioCategoryData: PortfolioCategoryData
) => {
  return useQuery(
    ['List Portfolio Category', portfolioCategoryData.company_symbol],
    () =>
      getPortfolioCategory2(portfolioCategoryData) as Promise<
        HttpResponse<Portfolio[]>
      >,
    {
      enabled,
    }
  );
};

/**
 * Fetching Portfolio OnTrack Status
 * @param portfolioId
 * @param enabled
 */
export const useFetchPortfolioOnTrack = (
  portfolioId: string,
  enabled: boolean = true
) => {
  return useQuery(
    ['Portfolio Ontrack', portfolioId],
    () => fetchGoalOnTrackStatus(portfolioId),
    {
      enabled,
    }
  );
};

/**
 * Fetching Portfolio Chart Progress
 * @param portfolioId
 * @param enabled
 */
export const useFetchGoalChart = (
  portfolioId: string,
  enabled: boolean = true
) => {
  return useQuery(
    ['Portfolio Chart', portfolioId],
    () => fetchGoalChartProgress(portfolioId),
    {
      enabled,
    }
  );
};

/**
 * Fetching portfolio Goal Detail
 * enable = true
 */
export const useFetchGoalDetail = (
  categoryId: string,
  enable: boolean = true
) => {
  return useQuery(
    ['Goal Detail Portfolio', categoryId],
    () => fetchGoalDetail(categoryId),
    {
      enabled: enable,
    }
  );
};

/**
 * Recalculate Portfolio Goal
 * enable = true
 */
export const useRecalculateGoalAmount = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (param: { payment?: string; portfolio: string; schedule_value?: number }) =>
      postGoalRecalculateAmount(param.portfolio, param),
    {
      onSuccess: (data, item) => {
        if (data?.data?.data) {
          queryClient.setQueryData(
            ['Goal Detail Portfolio', item.portfolio],
            data
          );
        }
      },
    }
  );
};
