import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchDataPortfolio } from 'services/portfolio';
import { fetchProductById } from 'services/product';
import { format } from 'date-fns';
import useHomeOnboardingContext from '../../hooks/useHomeOnboardingContext';
import { useTopModalFunctions } from 'features/topup-modal/TopupGlobalContext';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import { useDispatch } from 'react-redux';
import { postCreateGoal } from 'services/goalSettings';
import { postUserUpdatePreference } from 'features/profile/network';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';

type OnCreatePortfolioParamType = {
  /** Product Symbol (Ex: 'RD3595') */
  selectedProductSymbol: string;

  /** Set state of product symbol to reset */
  setSelectedProductSymbol: any;

  /** Custom close modal */
  onCloseModal?: () => void | undefined;
};

/** Hook that relates to portfolio creation flow  */
const usePortfolioFlow = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const { openTopUpModal } = useTopModalFunctions();

  const { isLogin: userIsLoggedIn } = useAuth();

  /** get profile data from useProfileData hooks */
  const { data: profileDataRQ } = useProfileData(userIsLoggedIn);

  const profileData = profileDataRQ?.data?.data;

  const hide_robo_section = !!profileData?.preference?.hide_robo_section;

  const show_onboarding_hero_section = !!profileData?.preference
    ?.show_onboarding_hero_section;

  const is_follow_onboarding_recommendation = !!profileData?.preference
    ?.is_follow_onboarding_recommendation;

  const {
    handleCloseHomeOnboardingModal,
    portfolioTypeName,
    onboardingPortoDateTarget,
    onboardingPortoName,
    portfolioTemplateId,
    onboardingPortoTargetAmount,
    portfolioTypeId,
    isFollowOnboardingRecommendation,
    setOnboardingModalVisible,
    setOnboardingModalStep,
    setPortfolioTypeName,
    setPortfolioTypeId,
    setTriggeredFromModalFlow,
    setPortfolioTemplateId,
  } = useHomeOnboardingContext();

  const [showMutualFundDetailById, setShowMutualFundDetailById] = useState<
    string | null
  >(null);

  const [isCreatingGoalLoading, setIsCreatingGoalLoading] = useState(false);

  /**
   * Create portfolio creation flow for single type transaction
   * 1. get product detail
   * 2. create portfolio
   * 3. push history to /portfolio/{id}/details
   * 4. open Topup Modal
   * 5. close Onboarding flow modal
   */
  const onCreatePortfolio = async ({
    selectedProductSymbol,
    setSelectedProductSymbol,
    onCloseModal = undefined,
  }: OnCreatePortfolioParamType) => {
    // Create portfolio and redirect to portfolio detail
    setIsCreatingGoalLoading(true);
    try {
      // Fetch product detail to get minBuy
      let productDetail;
      if (selectedProductSymbol) {
        const res = await fetchProductById(selectedProductSymbol);
        productDetail = res?.data?.data;
      }

      const isBibitTabungan = portfolioTypeName === 'Bibit Tabungan';

      /** getting portfolio id */
      const portfolioId = isBibitTabungan
        ? await getPortfolioIdBibitTabungan()
        : await getPortfolioIdAndCreatePortfolio();

      /** If product has minbuy lower than 100.000 and onboarding flow -> pre-fill amount to 100k */
      const prefillAmount =
        productDetail?.minbuy < 100000 && show_onboarding_hero_section
          ? 100000
          : undefined;

      // Close onboarding modal
      handleCloseHomeOnboardingModal();

      // Custom close modal
      if (!!onCloseModal) {
        onCloseModal();
      }

      // Loading finished
      setIsCreatingGoalLoading(false);

      // Reset selected symbol
      setSelectedProductSymbol(undefined);

      // Push to portfolio detail page
      history.push({
        pathname: `/portfolio/${portfolioId}/details`,
      });

      // Open top up modal
      openTopUpModal({
        step: 'input',
        transactionType: 'single',
        showAutocomplete: true,
        showProductSelection: true,
        products: [selectedProductSymbol || ''],
        minBuy: productDetail?.minbuy || 0,
        portfolioId,
        currency: productDetail?.currency_exchange?.currency ?? 'IDR',
        enableAddToCartButton: false,
        amount: prefillAmount,
        triggeredFrom: show_onboarding_hero_section ? 'new_user' : undefined,
      });

      // Update user preference
      handleUserUpdatePreference();
    } catch (error) {
      console.error(error);
      const errorObj: any = error;
      const errorData = {
        message: errorObj?.response?.data?.message,
      };
      dispatch(openErrorModal(errorData));
      setIsCreatingGoalLoading(false);
    }
  };

  /** Update user preference after creating portfolio */
  const handleUserUpdatePreference = async () => {
    const isBibitPensiun = portfolioTypeName === 'Bibit Pensiun';

    /** Update hide robo section to zero when user create a Bibit Pensiun portfolio */
    const updateHideRoboSection = isBibitPensiun && !hide_robo_section;

    const updateShowOnboardingHeroSection = show_onboarding_hero_section;

    const updateIsFollowOnboardingRecommendation =
      !is_follow_onboarding_recommendation && isFollowOnboardingRecommendation;

    const needToUpdatePreference =
      updateHideRoboSection ||
      updateShowOnboardingHeroSection ||
      updateIsFollowOnboardingRecommendation;

    if (needToUpdatePreference) {
      const params = {
        ...(updateHideRoboSection ? { hide_robo_section: 1 } : {}),
        ...(updateIsFollowOnboardingRecommendation
          ? { is_follow_onboarding_recommendation: 1 }
          : {}),
        ...(updateShowOnboardingHeroSection
          ? { show_onboarding_hero_section: 0 }
          : {}),
      };

      await postUserUpdatePreference(params);
    }
  };

  /**
   * - get portolio list of user from api
   * - find porfolio which type name is Bibit Tabungan
   * @returns portfolio id which type name is Bibit Tabungan
   */
  const getPortfolioIdBibitTabungan = async (): Promise<number> => {
    const porfolioDataResponse = await fetchDataPortfolio();
    const portfolioList = porfolioDataResponse?.data?.data?.result ?? [];
    const portfolioTabungan = portfolioList.find(
      (porto) => porto.type?.name === 'Bibit Tabungan'
    );
    return portfolioTabungan?.id ?? 0;
  };

  /** create portfolio to api and return portfolio id of result */
  const getPortfolioIdAndCreatePortfolio = async (): Promise<number> => {
    const targetDate = format(
      new Date(onboardingPortoDateTarget),
      'yyyy-MM-dd'
    );

    const previewData = {
      portfolio_name: onboardingPortoName,
      portfolio_template: portfolioTemplateId,
      target_amount: onboardingPortoTargetAmount,
      target_date: targetDate,
      portfolio_type: portfolioTypeId,
      is_robo: 0,
    };

    // post api to create portfolio
    const { data: createdGoalData } = await postCreateGoal(previewData);

    // Get portfolioId
    const portfolioId = createdGoalData?.data?.portfolio?.id;

    return portfolioId;
  };

  const roboRecommendationCreationFlow = () => {
    // Open ChooseOnboardingIconModal
    setOnboardingModalVisible(true);
    setOnboardingModalStep('choose_icon');

    // Bibit Goals type
    setPortfolioTypeName('Bibit Goals');

    // Portfolio type id 2 for Bibit Goals
    setPortfolioTypeId('2');

    // Set a robo_recommendation modal flow
    setTriggeredFromModalFlow('robo_recommendation');
  };

  /** Triggered from OnboardingRoboHomeCard
   * Robo + Bibit Pension flow
   */
  const roboPensionCreationFlow = () => {
    setOnboardingModalVisible(true);
    setOnboardingModalStep('create_portfolio');
    setPortfolioTemplateId('2');
    setPortfolioTypeId('3'); // portfolio type id 3 for bibit pension
    setPortfolioTypeName('Bibit Pensiun');
    setTriggeredFromModalFlow('robo_create_pension');
  };

  const onCustomNavigateMutualFundDetail = (symbol: string) => {
    if (symbol) {
      setShowMutualFundDetailById(symbol);
    }
  };

  const onCloseMutualFundDetailModal = () => {
    setShowMutualFundDetailById(null);
  };

  return {
    showMutualFundDetailById,
    isCreatingGoalLoading,
    onCustomNavigateMutualFundDetail,
    onCloseMutualFundDetailModal,
    onCreatePortfolio,
    roboRecommendationCreationFlow,
    roboPensionCreationFlow,
  };
};

export default usePortfolioFlow;
