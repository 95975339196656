import { showDevtools } from 'devtools/constants/common';
import LazyLoadWrapper from 'features/common/lazyload/LazyLoadWrapper';
import React, { lazy } from 'react';

const Component = lazy(() => import('./Devtools'));

const DevTools = () => {
  if (!showDevtools) return null;

  return (
    <LazyLoadWrapper loadingComponent={<div />}>
      <Component />
    </LazyLoadWrapper>
  );
};

export default DevTools;
