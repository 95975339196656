import { useQuery, useMutation } from 'react-query';
import { getStatusRegisSBN, postRegisSbn } from 'features/sbn/network/endpoint';
import { getBankListUser } from 'services/user';

export const useFetchStatusRegisSBN = (enabled = true) => {
  return useQuery(['Status Regis SBN'], getStatusRegisSBN, {
    enabled,
  });
};

export const useFetchAllUserBank = (enabled = true) => {
  return useQuery(['All User Bank'], () => getBankListUser(), {
    enabled,
    staleTime: 0,
  });
};

export const usePostRegisterSbn = (securitiesid: string) => {
  return useMutation(['register_sbn', securitiesid], postRegisSbn);
};
