import { getPushNotificationStats } from 'features/notification-center/networks/services';
import { useMutation, useQuery } from 'react-query';
import {
  updateDotProfileInstitutionNotifStats,
  updateTooltipsInstitutionNotifStats,
} from 'features/institution/networks/service';
import {
  getInstitutionProfileNotification,
  setInstitutionProfileNotification,
} from 'features/institution/utils';
import { InstitutionProfileNotification } from '../types/institution.type';

export const INSTITUTION_PROFILE_NOTIF_KEY = 'institution_profile_notif';

/**
 * Get institution dot_notification from storage or api
 */
export const useStorageInstitutionProfileNotification = (enabled = true) => {
  return useQuery(
    [INSTITUTION_PROFILE_NOTIF_KEY],
    () => getInstitutionProfileNotification(),
    {
      enabled: enabled,
      staleTime: Infinity,
      onSuccess: (dataStorage) => {
        // set data form api when storage data is empty
        if (dataStorage === null) {
          getPushNotificationStats().then((resNotif) => {
            /**
             * Green dot profile data from api
             */
            const greenDotProfile =
              resNotif?.data?.data?.dot_notifications?.profile ?? 0;

            /**
             * Step tooltip data from api
             */
            const stepTooltip =
              resNotif?.data?.data?.tooltips?.switch_institution_account ?? 2;

            // set storage
            setInstitutionProfileNotification({
              dot_notifications: {
                profile: Number(greenDotProfile),
              },
              tooltips: {
                switch_institution_account: Number(stepTooltip),
              },
            });
          });
        }
      },
      retry: false,
    }
  );
};

export const useUpdateInstitutionProfileNotification = (
  defaultData?: InstitutionProfileNotification
) => {
  /**
   * default 0 for hide green dot
   */
  const defaultGreenDot = defaultData?.dot_notifications?.profile ?? 0;

  /**
   * default 2 for hide tooltip
   */
  const defaultTooltipSwitchAccount =
    defaultData?.tooltips?.switch_institution_account ?? 2;

  return useMutation(
    ['institution_profile_notification_update'],
    async (params: { profile?: number; tooltipSwitchAccount?: 1 | 2 }) => {
      // update storage
      setInstitutionProfileNotification({
        dot_notifications: {
          profile:
            params?.profile !== undefined
              ? Number(params.profile)
              : Number(defaultGreenDot),
        },
        tooltips: {
          switch_institution_account:
            params?.tooltipSwitchAccount !== undefined
              ? Number(params.tooltipSwitchAccount)
              : Number(defaultTooltipSwitchAccount),
        },
      });

      // request update api green dot profile
      if (params?.profile !== undefined) {
        updateDotProfileInstitutionNotifStats({
          dot_notifications: {
            profile: Number(params.profile),
          },
        });
      }

      // request update api tooltip switch account
      if (params?.tooltipSwitchAccount !== undefined) {
        updateTooltipsInstitutionNotifStats({
          tooltips: {
            switch_institution_account: Number(params.tooltipSwitchAccount),
          },
        });
      }
    }
  );
};
