import React from 'react';
import classNames from 'classnames';
import finish from 'assets/images/portofolio-asset/flagFinish.svg';
import portoThumb from 'assets/images/portofolio-asset/porto-thumb.svg';
import { RefreshCw } from 'react-feather';
import { useParams } from 'react-router-dom';
import { dispatch } from 'store/redux';
import { useFetchPortfolioGoalDetail } from 'features/portfolio/networks';
import { useRecalculateGoalAmount } from 'features/portfolio/hook/portfolioHook';
import { setLoadingRecalculatePortoGoal } from 'features/portfolio/portfolioPage.reducer';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import { useTheme } from 'hooks';

type Props = {
  isGoalExpired: boolean;
  onTrack: number;
  progress: number;
  handleTrack?: () => void;
  recalculate?: boolean;
};

const PortfolioTrackButton: React.FC<React.PropsWithChildren<Props>> =
  React.memo(
    ({
      isGoalExpired,
      onTrack,
      progress,
      recalculate,
      handleTrack = () => {},
    }) => {
      const { categoryId } = useParams<{ categoryId: string }>();
      const portoAchieved = onTrack >= 3 || progress >= 100;
      const portoGoalExpired = isGoalExpired && progress < 100;
      const { theme } = useTheme();

      const { data: portfolioGoalDetailData } = useFetchPortfolioGoalDetail(
        categoryId,
        !!categoryId
      );
      const portfolioGoalDetail = portfolioGoalDetailData?.data?.data;

      const paymentMethod = portfolioGoalDetail?.recurring?.payment ?? '';
      const scheduleValue = portfolioGoalDetail?.recurring?.date ?? 0;

      const { mutateAsync: recalculateGoalAmount } = useRecalculateGoalAmount();

      const handleRecalculate = async () => {
        /**
         * We need to set loading everytime 'Recalculate' button clicked
         * to set reviewAmount in PortfolioGoalRecurringInfo
         *
         * @see src/features/portfolio/pages/PortfolioGoalPage/components/PortfolioGoalRecurringInfo
         *
         * TODO: remove this after we don't need redux
         */
        dispatch(setLoadingRecalculatePortoGoal(true));

        try {
          await recalculateGoalAmount({
            portfolio: categoryId,
            payment: paymentMethod,
            schedule_value: scheduleValue,
          });
        } catch (err) {
          const errorType = (err as any)?.raw
            ? (err as any)?.raw?.type
            : (err as any)?.message;
          const errorMessage = (err as any)?.error || (err as any)?.message;

          const errorModalObj = {
            type: errorType,
            message: errorMessage,
          };

          // Set error modal
          dispatch(openErrorModal(errorModalObj));
        } finally {
          dispatch(setLoadingRecalculatePortoGoal(false));
        }
      };

      const checkTrackStatus = () => {
        if (onTrack < 1 || portoGoalExpired) return 'Off Track';
        if (portoAchieved) return 'Achieved';

        return 'On Track';
      };

      const imgSrc =
        onTrack >= 3 || (progress >= 100 && !portoGoalExpired)
          ? finish
          : portoThumb;

      return (
        <div className='bit-track-porto bit-bg-transparent'>
          <div
            className={classNames(
              'bit-track-porto-btn',
              { 'bit-track-porto-btn--dark': theme === 'dark' },
              {
                /**
                 * This conditional only momentary for phase 1.
                 * Because there is no current design for porto expired
                 */
                offtrack: onTrack < 1 || portoGoalExpired,
                achieved:
                  !portoGoalExpired && (onTrack >= 3 || progress >= 100),
              }
            )}
            onClick={handleTrack}
          >
            <div>
              <img src={imgSrc} alt={checkTrackStatus()} />
            </div>
            <p>{checkTrackStatus()}</p>
          </div>
          {!Boolean(onTrack) && recalculate && (
            <div className='bit-porto-recalculate' onClick={handleRecalculate}>
              <RefreshCw height='14' />
              <p>recalculate</p>
            </div>
          )}
        </div>
      );
    }
  );

export default PortfolioTrackButton;
