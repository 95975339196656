import * as React from 'react';
import compareVersion from 'compare-versions';
import { getInfo } from 'core/DeviceInfo';
import { useHistory } from 'react-router';

const JagoUpdateOSModalVisibilityProvider = React.createContext(false);
const JagoRegistrationRedirectProvider = React.createContext<Function>(
  () => {}
);
const JagoCloseUpdateOSModalProvider = React.createContext<Function>(() => {});

const JagoUpdateOSModalProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const history = useHistory();
  /**
   * These states will be needed to prevent users from iOS 12 or lower from registering
   */
  const [userShouldUpdateOS, setShouldUpdateOS] = React.useState(false);
  const [updateOSModalVisible, setUpdateOSModalVisible] = React.useState(false);

  /**
   * Check if user is using iOS 12 or lower, then mark registration as disabled for
   * these devices. Update OS modal will be shown instead
   */
  React.useEffect(() => {
    const checkOSVersion = async () => {
      const deviceInfo = await getInfo();
      const version = deviceInfo?.SYSTEM_VERSION;
      const systemName = deviceInfo?.SYSTEM_NAME;
      if (
        !!version &&
        !!systemName &&
        systemName.toLowerCase() === 'ios' &&
        compareVersion.compare(version, '13.0.0', '<')
      ) {
        setShouldUpdateOS(true);
      }
    };
    checkOSVersion();
  }, [userShouldUpdateOS]);

  /**
   * Redirect users to start Bank Jago regis flow, but check first if user
   * is using iOS 12 or lower. Will show modal to direct users to update their
   * devices if they have iOS 12 or lower
   */
  const proceedUserToJagoRegistration = () => {
    if (userShouldUpdateOS) {
      return setUpdateOSModalVisible(true);
    }
    return history.push('/jago-tnc');
  };

  /**
   * Close update OS info modal
   */
  const closeUpdateOSModal = () => {
    setUpdateOSModalVisible(false);
  };

  return (
    <JagoUpdateOSModalVisibilityProvider.Provider value={updateOSModalVisible}>
      <JagoRegistrationRedirectProvider.Provider
        value={proceedUserToJagoRegistration}
      >
        <JagoCloseUpdateOSModalProvider.Provider value={closeUpdateOSModal}>
          {children}
        </JagoCloseUpdateOSModalProvider.Provider>
      </JagoRegistrationRedirectProvider.Provider>
    </JagoUpdateOSModalVisibilityProvider.Provider>
  );
};

const getErrorPrefix = (
  updateOSModalVisible: boolean,
  proceedUserToJagoRegistration: Function,
  closeUpdateOSModal: Function
) => {
  let mapErrorPrefix = [];
  let textErrorPrefix = '';
  if (updateOSModalVisible === undefined) {
    mapErrorPrefix.push('updateOSModalVisible');
  }
  if (proceedUserToJagoRegistration === undefined) {
    mapErrorPrefix.push('proceedUserToJagoRegistration');
  }
  if (closeUpdateOSModal === undefined) {
    mapErrorPrefix.push('closeUpdateOSModal');
  }

  if (mapErrorPrefix.length > 0) {
    textErrorPrefix = `${mapErrorPrefix.toString()} is undefined, `;
  }

  return textErrorPrefix;
};

export const useJagoRegistrationValidation = () => {
  const updateOSModalVisible = React.useContext(
    JagoUpdateOSModalVisibilityProvider
  );
  const proceedUserToJagoRegistration = React.useContext(
    JagoRegistrationRedirectProvider
  );
  const closeUpdateOSModal = React.useContext(JagoCloseUpdateOSModalProvider);

  if (
    updateOSModalVisible === undefined ||
    proceedUserToJagoRegistration === undefined ||
    closeUpdateOSModal === undefined
  ) {
    const errorPrefix = getErrorPrefix(
      updateOSModalVisible,
      proceedUserToJagoRegistration,
      closeUpdateOSModal
    );
    throw new Error(
      `${errorPrefix}useJagoRegistrationValidation must be used inside JagoUpdateOSModalProvider`
    );
  }

  return {
    updateOSModalVisible,
    proceedUserToJagoRegistration,
    closeUpdateOSModal,
  };
};

export default JagoUpdateOSModalProvider;
