import React from 'react';
import LazyModal from 'features/common/LazyModal';

import { useSharedInvitationStateContext } from '../contexts';
import '../../SharedPortfolioModal.css';

const ShareInvitationModal = React.lazy(() => import('./ShareInvitationModal'));

const ShareInvitationConfirmationModal = React.lazy(
  () => import('./ShareInvitationConfirmationModal')
);

const ShareInvitationModals = (props: any) => {
  const { sharedInvitationStep } = useSharedInvitationStateContext();

  return (
    <>
      <LazyModal
        component={ShareInvitationModal}
        visible={sharedInvitationStep === 'invitation'}
        {...props}
      />
      <LazyModal
        component={ShareInvitationConfirmationModal}
        visible={sharedInvitationStep === 'invitation_confirmation'}
        {...props}
      />
    </>
  );
};

export default ShareInvitationModals;
