import React, { useEffect, useState } from 'react';
import { Modal, Text, Radio } from '@bibitid/uikit-v1';
import { Waypoint } from 'react-waypoint';
import Analytics from 'utils/Analytics';

import Spinner from 'features/common/Spinner';
import { useGetRedemptionBanks } from 'features/common/reksadana/networks/resolver';
import useTheme from 'hooks/useTheme';

import style from './ChooseCustodianBankModal.module.css';
import DefaultBankLogo from 'assets/images/bank/default-bank-logo.svg';
import DefaultBankLogoDark from 'assets/images/bank/default-bank-logo-dark.svg';
import { getAnalyticData } from 'features/common/reksadana/utils/filterAnalytic';

export interface Props {
  bankId: number | undefined;
  visibility: boolean;
  isRoboPage: boolean;
  categoryType?: string;
  im?: string;
  onClose: () => void;
  onClickBack: () => void;
  onSubmit: (id: number | undefined) => void;
}

const ChooseCustodianBankModal: React.FC<Props> = ({
  bankId,
  visibility,
  isRoboPage,
  categoryType,
  im,
  onClose,
  onClickBack,
  onSubmit,
}) => {
  const {
    data: dataRedemptionBanks,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetRedemptionBanks(undefined, undefined, visibility);

  const analyticData: any = getAnalyticData(isRoboPage, categoryType, im);

  const [optionSelected, setOptionSelected] = useState(bankId);
  const [selectedBankName, setSelectedBankName] = useState('');
  const { theme } = useTheme();
  const resetSelectedBank = () => {
    setOptionSelected(bankId);
  };

  const handleClickOption = (event: React.SyntheticEvent<HTMLDivElement>) => {
    const id = Number(event.currentTarget.dataset?.id);
    const name = String(event.currentTarget.dataset?.name);

    setSelectedBankName(name);

    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'choose_bank',
        context: 'filter_sort.filter_modal_bank',
        trigger: 'click',
        data: {
          ...analyticData,
          bank: name,
        },
      },
    });

    setOptionSelected(id);
  };

  const handleLoadMore = () => {
    if (!hasNextPage) return;

    // fetch next page
    fetchNextPage();
  };

  const handleCloseModal = () => {
    resetSelectedBank();
    onClose();
  };

  const handleSubmit = () => {
    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'apply_bank',
        context: 'filter_sort.filter_modal_bank',
        trigger: 'click',
        data: {
          ...analyticData,
          bank: selectedBankName,
        },
      },
    });
    onSubmit(optionSelected);
  };

  const redemptionBanksPages = dataRedemptionBanks?.pages ?? [];

  const shoudRenderWayPoint = hasNextPage && !isLoading && !isFetchingNextPage;

  useEffect(() => {
    setOptionSelected(bankId);
  }, [bankId]);

  useEffect(() => {
    if (!visibility) return;

    const data: any = getAnalyticData(isRoboPage, categoryType, im);

    Analytics.logEventNavigation({
      eventName: 'navigate',
      parameter: {
        page: 'filter_sort',
        view: 'filter_modal_bank',
        data,
      },
    });
  }, [visibility, isRoboPage, categoryType, im]);

  return (
    <Modal
      className={style['bit-choose-bank']}
      visible={visibility}
      onClose={handleCloseModal}
    >
      <Modal.Header
        showClose={true}
        showBackButton={false}
        title='Pilih Bank Penampung'
      />
      <Modal.Body>
        <div className={style['bit-choose-rd__content']}>
          <div className={style['bank-options']}>
            {redemptionBanksPages?.map((page, index) => (
              <React.Fragment key={index}>
                {page.data?.data?.map((item) => {
                  return (
                    <div
                      key={`bank-option-${item.id}`}
                      className={style['bank-options__card']}
                      data-id={item.id}
                      data-name={item.name}
                      onClick={handleClickOption}
                    >
                      <Radio
                        checked={item.id === optionSelected}
                        value={'any'}
                        onChange={undefined}
                      >
                        <div
                          className={style['bank-options__card__content_left']}
                        >
                          {theme === 'light' && (
                            <img
                              src={item?.icon_url ?? DefaultBankLogo}
                              alt={item.name}
                              onError={(
                                e: React.SyntheticEvent<HTMLImageElement, Event>
                              ) =>
                                ((e.target as HTMLImageElement).src =
                                  DefaultBankLogo)
                              }
                            />
                          )}
                          {theme === 'dark' && (
                            <img
                              src={item?.icon_url ?? DefaultBankLogoDark}
                              alt={item.name}
                              onError={(
                                e: React.SyntheticEvent<HTMLImageElement, Event>
                              ) =>
                                ((e.target as HTMLImageElement).src =
                                  DefaultBankLogoDark)
                              }
                            />
                          )}

                          <Text htmlTag='span'>{item.name}</Text>
                        </div>
                      </Radio>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}

            {isFetchingNextPage && (
              <div className='load-more'>
                <Spinner />
              </div>
            )}

            {shoudRenderWayPoint && (
              <div className='padding-top-5'>
                <Waypoint
                  key={redemptionBanksPages?.length ?? 0}
                  bottomOffset='-35%'
                  onEnter={handleLoadMore}
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer primaryButton={'Pilih'} onPrimaryClick={handleSubmit} />
    </Modal>
  );
};

export default ChooseCustodianBankModal;
