import styled from 'styled-components';

export const BenchmarkTitle = styled.div`
  color: var(--text-primary);
  margin-bottom: 12px;
  font-weight: 700;
  @media (min-width: 1030px) {
    padding: 0 16px;
    margin-top: 20px;
    margin-bottom: 14px;
  }
`;
