import { useContext } from 'react';
import { JagoStateContext } from 'features/bankjago/contexts/JagoProvider';

/** Hook to use bank jago state in context */
const useJagoContextState = () => {
  const context = useContext(JagoStateContext);
  if (context === undefined) {
    throw new Error(
      'useBankJagoStateContext must be used within a JagoProvider'
    );
  }
  return context;
};

export default useJagoContextState;
