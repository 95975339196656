import { getEnv } from 'core/env';
import { IConfig } from 'unleash-proxy-client';

const { UnleashClientKey, UnleashEnv, UnleashProxyUrl } = getEnv();

export const OVERRIDE_FLAG_KEY_STORAGE = 'overrideFlags';

/**
 * Unleash Configuration
 */
export const config: IConfig = {
  url: UnleashProxyUrl || 'https://unleash.lab.bibit.id/proxy',
  clientKey: UnleashClientKey || '123',
  refreshInterval: 43200, // fetch after 12 hours of usage
  appName: UnleashEnv || 'staging',
  disableMetrics: true,
};
