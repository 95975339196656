import useJagoContextState from 'features/bankjago/hooks/useJagoContextState';
import LazyModal from 'features/common/LazyModal';
import React, { lazy } from 'react';

const Modal = lazy(() => import('./JagoConnectPromptModal'));

const JagoConnectPromptModal = () => {
  const { jagoModalConnectVisible } = useJagoContextState();
  return <LazyModal component={Modal} visible={jagoModalConnectVisible} />;
};

export default JagoConnectPromptModal;
