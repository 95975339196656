import LazyModal from 'features/common/LazyModal';
import React from 'react';
import { useSharedPortfolioContext } from '../../contexts';

const SharedPortfolioModal = React.lazy(() => import('./SharedPortfolioModal'));

const SharedPortfolioModalLazy: React.FC<React.PropsWithChildren<unknown>> = ({ ...rest }) => {
  const { activeModal } = useSharedPortfolioContext();

  /**
   * Determine visible by activeModal
   *
   * if activeModal undefined we sure visible is false otherwise true
   */
  const visible = !!activeModal;

  return (
    <LazyModal visible={visible} component={SharedPortfolioModal} {...rest} />
  );
};

export default SharedPortfolioModalLazy;
