import { useQuery } from 'react-query';
import {
  ProductItem,
  BestType,
} from 'features/home/components/Modals/onboarding-modal-flow/types';
import { getBestProductByCategory } from './services';
import { AxiosResponse } from 'axios';
import { BibitApiSuccessResponse } from 'services';

/**
 * fetch best reksadana by category
 * @param type type of category
 * @param enable enable for fecthing
 */
export const useFetchBestProductByCategory = (
  type: BestType,
  enable: boolean,
  syariah?: 0 | 1
) => {
  /**
   * If type is moneymarket, apply sort_by instant
   * to show instant product(s) on top of the list
   */
  const sort_by = type === 'moneymarket' ? 'instant' : null;

  return useQuery(
    ['BestProductByCategory', { type, syariah }],
    () =>
      getBestProductByCategory({
        category: type,
        sort_by,
        syariah: syariah,
      }),
    {
      enabled: enable, // only fetch when enable
      staleTime: 1 * 60 * 1000, // one minute to refetch when recall
      select: (data: AxiosResponse<BibitApiSuccessResponse<ProductItem[]>>) =>
        data?.data?.data, // force return data from {BibitApiSuccessResponse}
    }
  );
};
