import { RDTypeGroup, RDTypeTranslate } from '../constants';

export const getAnalyticData = (
  isRoboPage: boolean,
  categoryType?: string,
  im?: string
) => {
  const data: any = {
    case: isRoboPage ? 'robo' : 'search',
  };
  if (!isRoboPage) {
    const group = RDTypeGroup[categoryType || ''];
    data.group = group;
    data[group] = categoryType === 'im' ? im : categoryType;
  }
  if (isRoboPage) {
    data.rd_type = RDTypeTranslate[categoryType || ''];
  }

  return data;
};
