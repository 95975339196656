import React, { memo } from 'react';
import { SeriTypeSBN } from 'features/sbn/type.d';
import SBNLogoORI from 'assets/images/sbn/sbn-logo-ori.svg';
import SBNLogoSBR from 'assets/images/sbn/sbn-logo-sbr.svg';
import SBNLogoSukuk from 'assets/images/sbn/sbn-logo-sukuk.svg';
import SBNLogoSukukTabungan from 'assets/images/sbn/sukuk-tabungan.svg';
import SBNLogoFR from 'assets/images/sbn/sbn-logo-fr.svg';

interface Props {
  type?: SeriTypeSBN;
  className?: string;
}

const SBNLogo: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  className,
}) => {
  switch (type) {
    case 'ORI':
      return <img src={SBNLogoORI} alt='sbn ori' className={className} />;
    case 'SBR':
      return <img src={SBNLogoSBR} alt='sbn sbr' className={className} />;
    case 'SR':
      return <img src={SBNLogoSukuk} alt='sbn logo' className={className} />;
    case 'ST':
      return (
        <img src={SBNLogoSukukTabungan} alt='sbn logo' className={className} />
      );
    case 'FR':
      return <img src={SBNLogoFR} alt='sbn fr logo' className={className} />;
    default:
      return <img src={SBNLogoORI} alt='sbn ori' className={className} />;
  }
};

export default memo(SBNLogo);
