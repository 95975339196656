import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

import { apiInstance } from 'core/http';
import { isEmptyObject } from 'utils';
import { handleResponseErrorCase } from 'utils/http-response';
import * as Aws from 'core/Aws';
import Storage from 'core/Storage';
import { stringContainsOnlyDigits } from 'utils/validator';
import { differenceInYears, format } from 'utils/date';

import { changeOutput as changeImageEDD } from 'features/edd/edd.reducer';
import { changeOutput as changeImageKTP } from 'features/ktp/ktp.reducer';

import {
  mapInvalidErrorMsg,
  MIN_AGE_USER,
} from 'features/register-account/constants';
import { postSubmitRegister } from 'features/registration/networks/services';
import { getEnv } from 'core/env';

const { AwsBibitBucket } = getEnv();

const defaultState = {
  is_fetching_upload: false,
  is_fetching: false,
  upload_err: '',
  err: '',
  err_try: 0,
  upload_done: false,
  done: false,
  form: {
    email: '',
    education: 0,
    earningsperyear: 0,
    earningsource: 0,
    earningsourceoption: '',
    bank: '1',
    bankBranch: '',
    bankAccountNumber: '',
    bankAccountName: '',
    flagIdentity: 0,
    fileIdentity: '',
    flagSignature: 0,
    fileSignature: '',
    fileSignatureData: [],
  },
  err_form: null,
};

const [uploadrequest, uploadfailure, uploadsuccess] = [
  'LOAD_REVREGISTERACCOUNTFORM_UPLOADREQUEST',
  'LOAD_REVREGISTERACCOUNTFORM_UPLOADFAILURE',
  'LOAD_REVREGISTERACCOUNTFORM_UPLOADSUCCESS',
].map(createAction);

const [request, failure, success, change, remove, changedefault] = [
  'LOAD_REVREGISTERACCOUNTFORM_REQUEST',
  'LOAD_REVREGISTERACCOUNTFORM_FAILURE',
  'LOAD_REVREGISTERACCOUNTFORM_SUCCESS',
  'CHANGE_REVREGISTERACCOUNTFORM_FORM',
  'REMOVE_REVREGISTERACCOUNTFORM_FORM',
  'CHANGEDEFAULT_REVREGISTERACCOUNTFORM_FORM',
].map(createAction);

const reducer = createReducer(
  {
    [uploadrequest]: (state) => {
      return update(state, {
        is_fetching_upload: { $set: true },
        upload_done: { $set: false },
        upload_err: { $set: '' },
      });
    },
    [uploadfailure]: (state, payload) => {
      const { err } = payload;
      return update(state, {
        is_fetching_upload: { $set: false },
        upload_err: { $set: err },
      });
    },
    [uploadsuccess]: (state) => {
      return update(state, {
        is_fetching_upload: { $set: false },
        upload_done: { $set: true },
      });
    },
    [request]: (state) => {
      return update(state, {
        is_fetching: { $set: true },
        err: { $set: '' },
        err_form: { $set: null },
      });
    },
    [failure]: (state, payload) => {
      const { err, err_form } = payload;
      const { err_try } = state;
      return update(state, {
        is_fetching: { $set: false },
        err: { $set: err },
        err_try: { $set: err_try + 1 },
        err_form: { $set: err_form },
      });
    },
    [success]: (state) => {
      const toUpdate = {
        is_fetching: { $set: false },
        done: { $set: true },
        err_form: { $set: null },
        err: { $set: '' },
      };
      return update(state, toUpdate);
    },
    [change]: (state, payload) => {
      const { key, value } = payload;

      const toUpdate = {
        form: {
          [key]: { $set: value },
        },
      };

      return update(state, toUpdate);
    },
    [changedefault]: (state, payload) => {
      const { batchChange } = payload;

      const toUpdate = {
        form: {},
      };

      if (typeof batchChange.map === 'function')
        batchChange.map(
          (item) => (toUpdate['form'][item.key] = { $set: item.value })
        );
      else return state;

      return update(state, toUpdate);
    },
    [remove]: (state, payload) => {
      const { value } = payload;

      const toUpdate = {
        form: {
          fileIdentity: { $splice: [[value, 1]] },
        },
      };
      return update(state, toUpdate);
    },
  },
  defaultState
);

export { change, remove, changedefault };

export function setDefaultValue(profileDetail) {
  return (dispatch, getState) => {
    let {
      ktp: { output: _file_identity },
      edd: { output: _file_edd },
    } = getState();

    const {
      phone,
      email,
      education,
      income,
      income_source,
      income_source_other,
      bank,
      bank_branch,
      bank_account_name,
      bank_account_number,
      file_identity,
      file_signature,
      file_edd,
      identity,
      fullname,
      birth_date,
    } = profileDetail?.user;

    dispatch(
      changeImageKTP({
        url: _file_identity ? _file_identity : file_identity,
      })
    );

    dispatch(
      changeImageEDD({
        url: _file_edd ? _file_edd : file_edd,
      })
    );

    /*
      email: '',
      education: 0,
      earningsperyear: 0,
      earningsource: 0,
      bank: '002',
      bankBranch: '',
      bankAccountNumber: '',
      bankAccountName: '',
      flagIdentity: 0,
      fileIdentity:'',
      flagSignature: 0,
      fileSignature: '',
      fileSignatureData: []
    */

    const batchChange = [
      {
        key: 'phone',
        value: phone,
      },
      {
        key: 'email',
        value: email,
      },
      {
        key: 'education', // option
        value: education,
      },
      {
        key: 'earningsperyear', // option
        value: income,
      },
      {
        key: 'earningsource', // option
        value: income_source,
      },
      {
        key: 'earningsourceother', // option
        value: income_source_other,
      },
      {
        key: 'earningsourceoption', // option
        value: income_source_other,
      },
      {
        key: 'bank', // option
        value: bank, // should convert to code
      },
      {
        key: 'bankBranch',
        value: bank_branch,
      },
      {
        key: 'bankAccountName',
        value: bank_account_name,
      },
      {
        key: 'bankAccountNumber',
        value: bank_account_number,
      },
      {
        key: 'fileIdentity',
        value: '',
      },
      {
        key: 'fileSignature',
        value: '',
      },
      {
        key: 'oldFileIdentity',
        value: file_identity,
      },
      {
        key: 'oldFileSignature',
        value: file_signature,
      },
      {
        key: 'identity',
        value: identity,
      },
      {
        key: 'fullname',
        value: fullname,
      },
      {
        key: 'birthdate',
        value: birth_date ? new Date(birth_date) : undefined,
      },
    ];

    dispatch(changedefault({ batchChange }));
  };
}

export function postRevRegisterAccountAsync2({
  updateProfileDataCache,
  profileDetail,
}) {
  return (dispatch, getState) => {
    const {
      revRegisterAccountForm: {
        form: {
          email,
          education: _education,
          earningsperyear: _income,
          earningsource: _income_source,
          earningsourceother: income_source_other,
          bank: _bank,
          // bankBranch: bank_branch,
          bankAccountName: bank_account_name,
          bankAccountNumber: bank_account_number,
          // fileIdentity: file_identity,
          fileSignature: file_signature,
          // oldFileIdentity,
          oldFileSignature,
          identity,
          fullname: full_name,
          birthdate: birth_date,
        },
        err_form,
      },
      ktp: { output: file_identity },
      edd: { output: file_edd },
    } = getState();

    if (!bank_account_number) {
      return dispatch(
        failure({
          err: 'Nomor rekening harus diisi',
          err_form: {
            ...err_form,
            bank_account_number: {
              param: 'bank_account_number',
              msg: 'Harap masukkan nomor rekening dari Bank kamu.',
            },
          },
        })
      );
    } else if (!_education) {
      return dispatch(
        failure({
          err: 'Pendidikan terakhir harus diisi',
          err_form: {
            ...err_form,
            education: {
              param: 'education',
              msg: 'Harap masukkan pendidikan terakhir',
            },
          },
        })
      );
    } else if (!_income) {
      return dispatch(
        failure({
          err: 'Pendapatan per tahun harus diisi',
          err_form: {
            ...err_form,
            earningsperyear: {
              param: 'earningsperyear',
              msg: 'Harap masukkan pendapatan per tahun',
            },
          },
        })
      );
    } else if (!_income_source) {
      return dispatch(
        failure({
          err: 'Sumber penghasilan harus diisi',
          err_form: {
            ...err_form,
            earningsource: {
              param: 'earningsource',
              msg: 'Harap masukkan sumber penghasilan',
            },
          },
        })
      );
    } else if (_income_source === 10 && !income_source_other) {
      return dispatch(
        failure({
          err: 'Sumber penghasilan lain harus diisi',
          err_form: {
            ...err_form,
            earningsource_other: {
              param: 'earningsource_other',
              msg: 'Harap masukkan sumber penghasilan lainnya',
            },
          },
        })
      );
    } else if (!stringContainsOnlyDigits(bank_account_number)) {
      return dispatch(
        failure({
          err: 'Nomor rekening tidak valid',
          err_form: {
            ...err_form,
            bank_account_number: {
              param: 'bank_account_number',
              msg: 'Nomor rekening bank kamu harus berupa angka.',
            },
          },
        })
      );
    } else if (bank_account_number.length < 5) {
      return dispatch(
        failure({
          err: 'Nomor rekening tidak valid',
          err_form: {
            ...err_form,
            bank_account_number: {
              param: 'bank_account_number',
              msg: 'Panjang Nomor Rekening yang kamu masukkan kurang dari 5',
            },
          },
        })
      );
    } else if (!bank_account_name) {
      return dispatch(
        failure({
          err: 'Nama pemilik rekening harus diisi',
          err_form: {
            ...err_form,
            bank_account_name: {
              param: 'bank_account_name',
              msg: 'Harap masukkan nama pemilik rekening dari Bank kamu.',
            },
          },
        })
      );
    } else if (!identity) {
      return dispatch(
        failure({
          err: 'NIK harus diisi',
          err_form: {
            ...err_form,
            identity: {
              param: 'identity',
              msg: 'Harap masukkan NIK kamu.',
            },
          },
        })
      );
    } else if (!full_name) {
      return dispatch(
        failure({
          err: 'Nama lengkap harus diisi',
          err_form: {
            ...err_form,
            full_name: {
              param: 'full_name',
              msg: 'Harap masukkan nama lengkap kamu.',
            },
          },
        })
      );
    } else if (!file_signature) {
      return dispatch(
        failure({
          err: 'Tanda tangan harus diisi',
          err_form: {
            ...err_form,
            file_signature: {
              param: 'file_signature',
              msg: 'Harap tanda tangan sesuai KTP kamu.',
            },
          },
        })
      );
    } else if (
      birth_date &&
      differenceInYears(new Date(), new Date(birth_date)) < MIN_AGE_USER
    ) {
      return dispatch(
        failure({
          err: `Tanggal lahir tidak valid`,
          err_form: {
            ...err_form,
            birth_date: {
              param: 'birth_date',
              msg: mapInvalidErrorMsg.birth_date_min_age(MIN_AGE_USER),
            },
          },
        })
      );
    }

    let dataSignatureP;

    // let bypassEktp = false;
    let bypassSignature = false;

    let name_signature = '';

    const id_user = profileDetail?.user?.id ?? '';

    // if(!file_identity) {
    // 	bypassEktp = true;
    // }

    if (file_signature) {
      let fileName = 'signature.jpeg';
      name_signature = Aws.formatUploadFileName(fileName);
      name_signature = 'registrations/' + id_user + '/' + name_signature;
      dataSignatureP = Aws.createBase64UploadPromise(
        name_signature,
        file_signature,
        false,
        {
          uploadfor: 'signature',
          code: id_user,
        }
      );
    } else {
      bypassSignature = true;
      dataSignatureP = Promise.resolve({
        url: oldFileSignature,
      });
    }

    /*
      response from aws redirect still error , need to deploy server hafid done
    */
    dispatch(uploadrequest());

    dataSignatureP.then((responseSigature) => {
      if (!responseSigature) {
        const err = 'Failed to upload file';
        return dispatch(uploadfailure({ err }));
      }

      dispatch(uploadsuccess());
      dispatch(request());

      const temp_base = AwsBibitBucket;
      const temp_signature = !bypassSignature
        ? temp_base + '/' + name_signature
        : oldFileSignature;

      const payload = {
        email,
        education: _education,
        income: _income,
        income_source: _income_source,
        income_source_other,
        bank: _bank,
        // bank_branch,
        bank_account_name,
        bank_account_number,
        file_identity,
        file_signature: temp_signature,
        file_edd,
        identity,
        full_name,
        birth_date: birth_date ? format(birth_date, 'yyyy-MM-dd') : undefined,
      };

      if (income_source_other)
        payload['income_source_other'] = income_source_other;

      const req = postSubmitRegister(payload).then(async (payload) => {
        if (isEmptyObject(payload)) {
          return dispatch(failure({ err: 'Something is wrong' }));
        }

        const token = payload?.data?.data?.token;
        if (token) {
          // set token header default
          apiInstance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${token.access_token}`;

          // Set access token to localstorage
          await Storage.setTokenToLocalStorage(token).then(() => {
            // update cache data profile
            updateProfileDataCache(payload?.data?.data);
          });
        }

        return dispatch(success());
      });

      handleResponseErrorCase(req, (errObj) => {
        const {
          err, // message from error case
          err_form, // error for form , usually object
        } = errObj;
        return dispatch(failure({ err, err_form }));
      });
    });

    handleResponseErrorCase(dataSignatureP, (errObj) => {
      const {
        err, // message from error case
      } = errObj;
      return dispatch(uploadfailure({ err }));
    });
  };
}

export default reducer;
