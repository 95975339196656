import { AxiosResponse } from 'axios';
import http from 'core/http';
import {
  GetProductsFilterParams,
  ProductFiltered,
  RedemptionBank,
} from '../types';
import endpoints from './endpoints';

interface GetRedemptionBanksAPIResponse {
  message: string;
  data: Array<RedemptionBank>;
}

interface GetSpecificRedemptionBankAPIResponse {
  message: string;
  data: RedemptionBank;
}

interface GetProductsFilterAPIResponse {
  message: string;
  data: Array<ProductFiltered>;
  meta: {
    total_rows: number;
  };
}

/**
 * Get redemption banks `/products/banks/redemptions`
 */
export const getRedemptionBanks = (
  page = 1,
  limit = 10
): Promise<AxiosResponse<GetRedemptionBanksAPIResponse>> => {
  return http.get(endpoints.fetchRedemptionBanks, {
    page,
    limit,
  });
};

/**
 * Get specific redemption bank `/products/banks/redemptions/:bankId`
 */
export const getSpecificRedemptionBank = (
  bankId = 0
): Promise<AxiosResponse<GetSpecificRedemptionBankAPIResponse>> => {
  return http.get(endpoints.fetchSpecificRedemptionBank(bankId));
};

/**
 * Get products filter `/products/filter`
 */
export const getProductsFilter = (
  params: GetProductsFilterParams
): Promise<AxiosResponse<GetProductsFilterAPIResponse>> => {
  return http.get(endpoints.fetchProductsFilter, params);
};
