import { Location } from 'history';
import { useEffect, useRef } from 'react';
import { matchPath, useHistory } from 'react-router-dom';

const whitelistURL = [
  '/',
  '/home',
  '/portfolio',
  '/orders',
  '/transactions/order/all',
  '/profile',
  '/explore',
];

let savedScrollPositions: Record<
  string,
  {
    position: number;
    isWindow: boolean;
  }
> = {};

const ScrollRestoration = () => {
  const history = useHistory<{
    preserveScrollPosition?: boolean;
  }>();
  let prevLocation = useRef(history.location);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const previousPathname = generateUrl(prevLocation.current);
      const nextPathname = generateUrl(location);

      if (action === 'PUSH') {
        const scrollableContainer = document.getElementById(
          'scrollable-container'
        );
        if (!!scrollableContainer) {
          savedScrollPositions[previousPathname] = {
            position: scrollableContainer.scrollTop || window.scrollY,
            isWindow: !!window.scrollY,
          };
        }

        /** When PUSH, we expect the position always at the top
         * So if somehow the URL has saved position, we want to remove it.
         */
        if (nextPathname in savedScrollPositions) {
          delete savedScrollPositions[nextPathname];
        }
      } else if (action === 'POP') {
        const state =
          typeof location.state === 'object' && location.state !== null
            ? location.state
            : {};
        if ('preserveScrollPosition' in state) {
          delete location.state.preserveScrollPosition;
        }
      }

      if (action !== 'POP') {
        const currentURL = location.pathname;
        const match = matchPath(currentURL, {
          path: whitelistURL,
          exact: true,
        });

        if (!!match) {
          savedScrollPositions = {};
        }
      }

      prevLocation.current = location;
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    const { preserveScrollPosition = true } = history.location.state ?? {};
    const scrollableContainer = document.getElementById('scrollable-container');

    var elements = document.querySelectorAll("[id='scrollable-container']");

    if (elements.length > 1) {
      console.warn(
        'WARNING: THIS PAGE HAS MULTIPLE SCROLLABLE-CONTAINER. SCROLL RESTORATION MIGHT NOT WORKING AS EXPECTED!!!'
      );
    }

    const isModalPresent = document.querySelectorAll(
      "[data-testid='modal-wrapper']"
    );
    if (isModalPresent.length > 1) {
      return;
    }

    /**
     * This is needed because of iOS bug.
     * when user scrolls and change to another chip/tab quickly before the scrolling ends,
     * the content will be blank in the new tab.
     */
    const overflowValue = scrollableContainer?.style.overflow;
    if (!!scrollableContainer) scrollableContainer.style.overflow = 'hidden';

    const url = generateUrl(history.location);

    if (preserveScrollPosition) {
      const savedPosition = savedScrollPositions[url ?? ''];
      if (!!savedPosition) {
        if (savedPosition.isWindow) {
          scrollToPosition(window, savedPosition.position);
        } else {
          scrollToPosition(scrollableContainer, savedPosition.position);
        }

        // If the scroll position has been restored
        // We probably want to remove the saved scroll position
        // So that we can prevent it to restore multiple times
        if (url in savedScrollPositions) {
          delete savedScrollPositions[url];
        }
      } else {
        scrollToPosition(window, 0);
        scrollToPosition(scrollableContainer, 0);
      }
    } else {
      if (!!scrollableContainer) {
        scrollToPosition(window, 0);
        scrollToPosition(scrollableContainer, 0);
      }
    }

    if (!!scrollableContainer)
      scrollableContainer.style.overflow = overflowValue ?? '';
  }, [history, history.location]);

  return null;
};

const scrollToPosition = (
  element: Window | HTMLElement | null,
  position: number
) => {
  if (typeof element?.scrollTo === 'function') {
    element.scrollTo(0, position);
  }
};

const generateUrl = (
  location: Location<{
    preserveScrollPosition?: boolean | undefined;
  }>
) => {
  const pathname = location.pathname ?? '';
  const queryParams = decodeURI(location.search ?? '');
  const url = pathname + queryParams;

  return url;
};

export default ScrollRestoration;
