import retryFunc from 'utils/retry-func';
import {
  getOrderDetailByID,
  getInstitutionOrderDetailById,
  getInstitutionOrderDetailRdById,
} from 'services/transaction';
import { useQuery, UseQueryOptions } from 'react-query';
import { useParams } from 'react-router-dom';

/**
 * Retry request when instant payment payment with
 * - Gopay
 * - Jago
 *
 * and the status is `wait_payment`
 */
export const retryGetOrderDetail = (code: string) => {
  return retryFunc(
    () => {
      return getOrderDetailByID(code);
    },
    {
      delay: 3000,
      maxRetry: 2,
      forceReturn: true,
      expectResult: (res) => {
        const responseData = res.data?.data;
        const paymentMethod = responseData?.payment?.payment_method;
        const instantPayment = ['jago', 'jago_snap', 'gopay'];

        const paymentShouldRetry = instantPayment.includes(paymentMethod);
        if (!paymentShouldRetry) return true;

        const confirmationStatuses =
          responseData?.portfolios?.reduce((prev: any, porto: any) => {
            const itemStatuses = porto.items
              .filter(() => {
                return true;
              })
              .map((item: any) => item.status);
            return prev.concat(itemStatuses);
          }, []) || [];

        const statusShouldRetry = confirmationStatuses.includes('wait_payment');
        if (!statusShouldRetry) return true;

        return false;
      },
    }
  );
};

export const retryInstitutionGetOrderDetail = (code: string) => {
  return retryFunc(() => getInstitutionOrderDetailById(code), {
    delay: 3000,
    maxRetry: 2,
    forceReturn: true,
    expectResult: (res) => {
      const responseData = res.data?.data;
      const paymentMethod = responseData?.payment?.payment_method;
      const instantPayment = ['jago', 'gopay'];

      const paymentShouldRetry = instantPayment.includes(paymentMethod);
      if (!paymentShouldRetry) return true;

      const confirmationStatuses =
        responseData?.portfolios?.reduce((prev: any, porto: any) => {
          const itemStatuses = porto.items
            .filter(() => {
              return true;
            })
            .map((item: any) => item.status);
          return prev.concat(itemStatuses);
        }, []) || [];

      const statusShouldRetry = confirmationStatuses.includes('wait_payment');
      if (!statusShouldRetry) return true;

      return false;
    },
  });
};

export const retryInstitutionGetOrderDetailRd = (code: string) => {
  return retryFunc(() => getInstitutionOrderDetailRdById(code), {
    delay: 3000,
    maxRetry: 2,
    forceReturn: true,
    expectResult: (res) => {
      const responseData = res.data?.data;
      const paymentMethod = responseData?.payment?.payment_method;
      const instantPayment = ['jago', 'gopay'];

      const paymentShouldRetry = instantPayment.includes(paymentMethod);
      if (!paymentShouldRetry) return true;

      const confirmationStatuses =
        responseData?.portfolios?.reduce((prev: any, porto: any) => {
          const itemStatuses = porto.items
            .filter(() => {
              return true;
            })
            .map((item: any) => item.status);
          return prev.concat(itemStatuses);
        }, []) || [];

      const statusShouldRetry = confirmationStatuses.includes('wait_payment');
      if (!statusShouldRetry) return true;

      return false;
    },
  });
};

/**
 * Fetching data detail order
 * @param invoiceid
 * @param config - UseQueryOptions
 */
export const useFetchDetailOrder = (
  invoiceid?: string,
  config?: UseQueryOptions
) => {
  const { orderId } = useParams<{ orderId: string }>();

  return useQuery(
    ['order_detail', invoiceid],
    () => retryGetOrderDetail(invoiceid ?? orderId),
    {
      enabled: config?.enabled,
    }
  );
};

export const useFetchDetailOrderOld = () => {
  const { data, isLoading, isFetched } = useFetchDetailOrder();
  const { orderId } = useParams<{ orderId: string }>();

  const dataResp = data?.data.data;
  const orderOld = { ...dataResp, code: orderId, trans_code: dataResp?.code };

  return {
    order: orderOld,
    isLoading,
    isFetched,
  };
};
