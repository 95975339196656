import * as Analytics from 'utils/Analytics';

interface UploadLogParams {
  /**
   * Log info's name
   *
   * ex: `error_upload_ktp`
   */
  name: string;

  /**
   * Passed in related context where the upload error is occured.
   *
   * `format`: {function-name}.{file-name}
   *
   * `ex`: `handleUploadToS3.FileAtachmentList`
   */
  context: string;

  /**
   * Bibit user's id
   */
  userId?: number;

  /**
   * Additional (error) data
   */
  data: any;
}

/**
 * Upload utility event's logger
 */
export const uploadLogInfo = ({
  name,
  context,
  userId,
  data,
}: UploadLogParams) => {
  // ignore error network logs
  if (data?.code === 'ERR_NETWORK') {
    return;
  }

  Analytics.generalLogInfo(name, {
    context: context,
    id: userId,
    data,
  });

  Analytics.logEventDebug({
    eventName: name,
    parameter: {
      trigger: 'call',
      context: context,
      data,
    },
  });
};
