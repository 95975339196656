import React, { useCallback, useEffect, useState } from 'react';
import PinForm from 'features/common/PinForm';
import { useDispatch } from 'react-redux';
import { pinVerify, requestPermission } from 'services/pin';
import { runBiometric, isBiometricActive } from 'utils/biometric';
import { setLoadingBiometric } from 'entities/common.reducer';
import { Props as PinProps } from 'features/common/PinForm/types';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';
import { useHistory, useLocation } from 'react-router-dom';
import Analytics from 'utils/Analytics';
import { useNativeIdleTimerToggle } from 'hooks';
import { findScopeByPathname } from 'core/token/UserAccessScope';
import { useQuery } from 'react-query';
import withoutTabBar from 'withoutTabBar';
import PinSetupPage from '../../components/PinSetupPage';
import { useFlag } from 'utils/feature-flag';
import usePinSetupInstitution from 'features/institution/hooks/usePinSetupInstitution';

interface Props extends PinProps {
  /** set true if this Pin Challange Page want to validate with Biometric */
  needBiometric?: boolean;
  /** Call back when success validate PIN challenge with PIN or Biometric */
  onSuccessPin?: (token?: string, pin?: string) => void;
  /** call back when fail validate PIN */
  onErrorPin?: () => void;
}

/**
 * Pin Challenge Page that show in level App and Route
 */
const PinChallengePage: React.FC<React.PropsWithChildren<Props>> = ({
  needBiometric,
  onSuccessPin,
  onErrorPin,
  showForgotPin,
  showSupport,
  renderOnBack,
  onBack,
  onClickFogetPin,
}) => {
  const { data: userKickOut } = useQuery('user_kickedout');

  const dispatch = useDispatch<any>();

  const history = useHistory();

  const location = useLocation();

  const idleHash = location.hash.includes('idle');

  /** login flag */
  const { isLogin } = useAuth();

  /** update profile data */
  const { updateProfileDataCache, data } = useProfileData(isLogin);
  const userDetail = data?.data?.data;

  // get instition data
  const institutionAccOnlyFF = useFlag('web_institution_without_individual');
  const { showPinSetupInstitution } = usePinSetupInstitution();

  const userid = userDetail?.user.id;
  const userSuspended = !!userDetail?.suspend;

  // MARK: - Local State
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shouldShowBiometric, setShouldShowBiometric] = useState(false);

  /**
   * Show pin setup when user need to input pin challenge but user doesn't have pin
   */
  const showPinSetup = institutionAccOnlyFF && showPinSetupInstitution;

  // validating should show biometric or not
  useEffect(() => {
    if (needBiometric && userid) {
      const checkBiometric = async () => {
        const active = await isBiometricActive(userid);
        setShouldShowBiometric(active);
      };
      checkBiometric();
    }
  }, [needBiometric, userid]);

  useEffect(() => {
    Analytics.logEventNavigation({
      eventName: 'navigate',
      parameter: {
        page: 'pin',
        view: 'challenge_pin',
      },
    });
  }, []);

  const handleChallengeBiometric = useCallback(async () => {
    if (!shouldShowBiometric) return;

    const dataNative = (await runBiometric()) as any;
    // From Native will call setLoadingBiometric(true), so we need to OFF loading in React Level
    dispatch(setLoadingBiometric(false));

    const biometricError = dataNative?.error;
    if (!biometricError) {
      onSuccessPin && onSuccessPin();
      return;
    }

    if (biometricError !== 'error_message_no_show') {
      setErrorMessage(biometricError);
      return;
    }
  }, [dispatch, onSuccessPin, shouldShowBiometric]);

  // handling submit biometric
  useEffect(() => {
    if (!shouldShowBiometric || userSuspended || !!idleHash) return;

    // execute challenge biometric
    handleChallengeBiometric();
  }, [shouldShowBiometric, userSuspended, handleChallengeBiometric, idleHash]);

  const pathname = location.pathname;
  const userDefinedAccess = findScopeByPathname(pathname);

  const requestScope = async (pinval: string) => {
    await requestPermission({
      pin: pinval,
      scope: userDefinedAccess?.scope?.join(' ') ?? '',
    });
  };

  const handleSubmitPin = async (pinval: string) => {
    let token;
    try {
      setLoadingSubmit(true);
      if (userDefinedAccess) {
        await requestScope(pinval);
      }

      if (!userDefinedAccess) {
        token = await pinVerify(pinval, updateProfileDataCache);
      }

      onSuccessPin && onSuccessPin(token, pinval);
    } catch (error) {
      const err: any = error;
      onErrorPin && onErrorPin();

      const errorMessage =
        err?.data?.message ?? 'Terjadi Kesalahan. Coba Kembali.';

      setErrorMessage(errorMessage);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleTapPin = () => {
    // reset error message
    setErrorMessage('');
  };

  const handleClickForgetPin = () => {
    /** TODO: Perlu koordinasi ke Imdad. Analytics nya perlu ada perubahan */
    // Analytics.logEventAction({
    //   eventName,
    //   parameter: {
    //     action: 'forget_pin',
    //     context: 'pin.challenge_pin',
    //     trigger: 'click',
    //     data: {
    //       redirect_from: '',
    //     },
    //   },
    // });

    if (onClickFogetPin) {
      return onClickFogetPin();
    }
    return history.push('/profile/settings/pin');
  };

  useNativeIdleTimerToggle();

  useEffect(() => {
    // set loading to false when user auto logout
    if (userKickOut) {
      setLoadingSubmit(false);
    }
  }, [userKickOut]);

  /**
   * After setup pin successful, there will be auto request pin challenge using pin on pin setup page.
   * After that, show children component
   */
  if (showPinSetup) {
    return (
      <PinSetupPage
        loadingVerifyPin={loadingSubmit}
        errorVerifyPin={errorMessage}
        onReqVerifyPinAfterSubmit={handleSubmitPin}
      />
    );
  }

  return (
    <PinForm
      title='Verifikasi PIN'
      loading={loadingSubmit}
      showForgotPin={showForgotPin}
      onClickFogetPin={handleClickForgetPin}
      errorMessage={errorMessage}
      onSubmitPin={handleSubmitPin}
      biometric={shouldShowBiometric}
      onChallengeBiometric={handleChallengeBiometric}
      onTapPin={handleTapPin}
      showSupport={showSupport}
      renderOnBack={renderOnBack}
      onBack={onBack}
    />
  );
};

export default withoutTabBar(PinChallengePage);
