import React from 'react';
import { useSelector } from 'react-redux';
import { TRootReducers } from 'store/root';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import { ReactComponent as Loading } from 'assets/images/loading.svg';

import './LoadingDialog.css';

/**
 * Loading Dialog, hit by nativeApp in Stackfuntion.constant.tsx `showLoadingDialog`
 *
 */
const LoadingDialog = () => {
  const loadingDialog = useSelector(
    (state: TRootReducers) => state?.entities?.common?.loadingDialog
  );

  const visible = loadingDialog?.visible;
  const message = loadingDialog?.message;

  // Don't let this component render HTML unless the modal is ready
  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className='loading-dialog-mask'>
      <div className={cx('loading-dialog', !message && 'no-message')}>
        <div className='loading-icon'>
          <Loading />
        </div>
        {message && <h4 className='bit-nomargin-bottom'>{message}</h4>}
      </div>
    </div>,
    document.body
  );
};

export default LoadingDialog;
