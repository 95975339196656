import { useParams } from 'react-router-dom';
import {
  getDetailProductSBN,
  getSbnEarlyRedemptionById,
} from 'features/sbn/network/endpoint';
import { useQuery } from 'react-query';

export const useParamSBNDetail = () => {
  const param = useParams<{ sbnid: string }>();

  return {
    sbnid: param.sbnid,
  };
};

export const useFetchDetailProductSBN = (id: string, enabled = true) => {
  return useQuery(['Detail SBN Product', id], () => getDetailProductSBN(id), {
    enabled,
    refetchOnMount: 'always',
  });
};

export const useFetchEarlyRedemptionInfo = (
  productId: string,
  enabled = true
) => {
  return useQuery(
    ['Early Redemption Info', productId],
    () => getSbnEarlyRedemptionById(productId),
    {
      enabled,
    }
  );
};
