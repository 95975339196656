import React, { ReactNode, ErrorInfo } from 'react';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import getSafely from 'utils/safely';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface ErrorBoundaryProps {
  children: ReactNode;
  dispatch: Dispatch;
}

interface State {
  hasError: boolean;
}

/**
 * Error boundary specialized for handling ChunkLoadError, use this
 * to wrap lay loaded components
 * TODO: This error handling is redundant with RootErrorBoundaries. Deprecate this?
 */
class ChunkLoadErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  State
> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /**
   * Chunk or component load errors are usually coming from bad networks.
   * For the solutions, we will ask users to check their networks or retry
   * loading our app.
   */
  componentDidCatch(error: Error, _: ErrorInfo) {
    const { dispatch } = this.props;

    const isErrorFetchChunk = getSafely(['name'], error) === 'ChunkLoadError';

    if (isErrorFetchChunk) {
      return dispatch(
        openErrorModal({
          type: 'FAILED_AND_RELOAD',
          message: 'Silakan coba beberapa saat lagi',
        })
      );
    }

    return dispatch(
      openErrorModal({
        type: 'COMPONENT_REQUEST_FAILED',
        message: 'Silakan coba beberapa saat lagi.',
      })
    );
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

export default connect()(ChunkLoadErrorBoundary);
