import React from 'react';
import classNames from 'classnames';
import SortArrow from 'assets/images/sorting-arrow.svg';
import { X } from 'react-feather';
import { getSortingChoosen, showSortPeriod, getSortingPeriod } from '../helper';

interface Props {
  sortBy: string;
  sortPeriod: string;
  sortTerapkan: string;
  sortOther?: string;
  onClickAscDesc: () => void;
  modalOpen: () => void;
  sortAscDesc: string;
  discoverPageReset: () => void;
  loadDataProduct: () => void;
  selectedOtherFilter?: string | null;
  categoryType?: string;
}

const SortingComponent: React.FC<React.PropsWithChildren<Props>> = ({
  sortBy,
  sortPeriod,
  sortOther,
  sortTerapkan,
  onClickAscDesc,
  modalOpen,
  sortAscDesc,
  discoverPageReset,
  loadDataProduct,
  categoryType,
}) => {
  /**
   * Show Other filter element
   * Will hide if it's on instant page
   * */
  const showSortOther: boolean = !!sortOther && categoryType !== 'instant';

  // Render nothing
  if (!sortTerapkan) return null;

  // Render Filter
  return (
    <div className='bit-sorting-row'>
      <div className='sorting-items' onClick={modalOpen}>
        {/* Sort Filter by */}
        {sortBy && <div>{getSortingChoosen(sortBy)}</div>}
        {/* Sort Period */}
        {sortPeriod && showSortPeriod(sortBy) && (
          <div>{getSortingPeriod(sortPeriod)}</div>
        )}
        {/* Other Filter (Lainnya) */}
        {showSortOther && <div>{sortOther}</div>}
        <span
          className='bit-close-filter-sorting-components'
          onClick={(e) => {
            e.stopPropagation();
            discoverPageReset();
            loadDataProduct();
          }}
        >
          <X color='#fff' height='12' width='12' />
        </span>
        {/* White space */}
        <div className='sorting-components-white-space' />
      </div>
      {/* Sorting by Ascending or descending */}
      {sortBy && sortPeriod && (
        <div className='bit-right-filter' onClick={onClickAscDesc}>
          {sortAscDesc === 'desc' ? 'Tertinggi' : 'Terendah'}{' '}
          <img
            className={classNames(
              { 'bit-sort-arr-low': sortAscDesc === 'asc' },
              { 'bit-sort-arr-high': sortAscDesc === 'desc' }
            )}
            width='12'
            height='12'
            src={SortArrow}
            alt='Sort Arrow'
          />
        </div>
      )}
    </div>
  );
};

export default SortingComponent;
