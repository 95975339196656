import React, { useEffect, useRef } from 'react';
import { Wrapper } from './IconWrapper.styled';

export type BackgroundColorInModal = { default: string; inModal: string };
export interface IconWrapperStyleProps {
  $size?: string;
  $borderRadius?: string;
}
interface IconWrapperProps extends IconWrapperStyleProps {
  className?: string;
  backgroundColor?: string | BackgroundColorInModal;
}

const IconWrapper: React.FC<React.PropsWithChildren<IconWrapperProps>> = ({
  children,
  className,
  backgroundColor,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const childElement = ref.current;
    const backgroundColorIsString = typeof backgroundColor === 'string';
    if (!childElement) return;

    if (backgroundColorIsString) {
      childElement.style.backgroundColor = backgroundColor;
      return;
    }

    const parentElement = childElement.closest('.modal-content');
    if (!parentElement || !(childElement instanceof HTMLDivElement)) {
      if (backgroundColor?.default) {
        childElement.style.backgroundColor = backgroundColor?.default;
        return;
      }
      return;
    }

    if (backgroundColor?.inModal) {
      childElement.style.backgroundColor = backgroundColor?.inModal;
    }
  }, [backgroundColor]);

  return (
    <Wrapper ref={ref} {...props} className={className}>
      {children}
    </Wrapper>
  );
};

export default IconWrapper;
