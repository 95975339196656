import { JwtAcToken } from 'core/refresh-token/refresh-token.type';
import jwtDecode from 'jwt-decode';
import { generalLogError } from 'utils/Analytics';
import { DEFAULT_BASE_API_URL, MOCK_API } from './constant';
import { AxiosHTTPError } from './http.type';
import { checkTokenExpiration } from 'core/refresh-token';
import { getEnv } from 'core/env';

const { ApiUrl, UrlMock, DevTools } = getEnv();

const getBaseAPIUrl = () => {
  // overidden by mock api
  if (UrlMock === 1) {
    return MOCK_API;
  }

  // overidden by native base api url
  if (DevTools && window.BASE_API_URL) {
    return window.BASE_API_URL;
  }

  // env url
  if (ApiUrl) {
    return ApiUrl;
  }

  // default url
  return DEFAULT_BASE_API_URL;
};

/**
 * if we activate mock api we will using MOCK_API
 * otherwise using REACT_APP_API_URL
 *
 * default would be DEFAULT_BASE_API_URL
 */
export const BASE_URL = getBaseAPIUrl();

/**
 * Create query param from object
 *
 * ex: { hello: 'world', make: "right" } => hello=world&make=right
 * @param obj
 * @returns {string}
 */
export const serializeParam = (obj: Record<string, any> = {}): string => {
  const str: string[] = [];

  Object.keys(obj)
    .sort()
    .forEach((key) => {
      if (Array.isArray(obj[key])) {
        // handle 2 query params for same key
        // ex: { hello: ['world','test']} => hello=world&hello=test
        obj[key].map((value: any) =>
          str.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        );
        return;
      }
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    });

  return str.join('&');
};

/**
 * Helper logger to sentry when `/auth/token` is error
 */
export const forceLogoutSentryLogger = (errObj: AxiosHTTPError) => {
  /** path url api */
  const pathName = errObj?.config?.url;

  /** http response status code */
  const httpStatus = errObj?.response?.status;

  /** error message type generic from response API  */
  const errType = errObj?.response?.data?.type;

  // @ determine should log to sentry or not
  // - pathName should `/auth/token`
  // - httpStatus should `422`
  // - if errType user_access_not_found just do nothing
  if (
    pathName !== '/auth/token' ||
    httpStatus !== 422 ||
    errType === 'user_access_not_found'
  )
    return; // do nothing

  const actoken = errObj?.config?.headers?.Authorization as string | undefined;

  let dataBodyReq: Record<string, any> | undefined;

  let decodedJwt: JwtAcToken | undefined;
  let decodeJwtRefresh: JwtAcToken | undefined;

  // decoding JWT
  try {
    dataBodyReq = JSON.parse(errObj?.config?.data || '{}');

    const refreshToken = dataBodyReq?.refresh_token as string | undefined;

    // @ check expiration refresh_token
    const rfTokenExpired = checkTokenExpiration(refreshToken);

    // do nothing when refresh token is expired
    if (rfTokenExpired) return;

    if (actoken) {
      decodedJwt = jwtDecode<JwtAcToken>(actoken);
    }

    if (refreshToken) {
      decodeJwtRefresh = jwtDecode<JwtAcToken>(refreshToken);
    }
  } catch (error) {}

  // send to sentry
  generalLogError(
    'Force Logout 422',
    {
      desc: 'user throwing to login page becase response status /auth/token is 422',
      userid: decodedJwt?.user?.id,
      apk_version: window.VERSION || window.document.VERSION,
      header_token: errObj?.config?.headers?.Authorization ? true : false,
      device_id: dataBodyReq?.device_id,
      refresh_id: dataBodyReq?.refresh_id,
      expired_at: decodedJwt?.exp,
      rf_token_expired: decodeJwtRefresh?.exp,
      rf_issued_at: decodeJwtRefresh?.iat,
      rf_jwt_id: decodeJwtRefresh?.jti,
      api_error_type: errType,
    },
    // tag user id for Sentry
    String(decodedJwt?.user?.id)
  );
};
