import list from './index';
import http from 'core/http';

export const getNotificationData = () => {
  return http.get(list.notifications, {
    type: 2,
    is_read: 0,
    limit: 1,
    cursor: '',
  });
};
