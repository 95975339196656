import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useAppModalGlobalStateContext } from 'features/common/modals/AppModalGlobalContext';

const CashoutSurveyModal = React.lazy(() => import('./CashoutSurveyModal'));

const IndexCashoutSurveyModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { cashoutSurveyModalVisible } = useAppModalGlobalStateContext();

  return (
    <LazyModal
      component={CashoutSurveyModal}
      visible={cashoutSurveyModalVisible}
    />
  );
};

export default IndexCashoutSurveyModal;
