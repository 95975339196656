import React, { FC } from 'react';
import Modal from 'features/common/components/Modal';
import { Text, Flex, Button } from '@bibitid/uikit';
import { Plus } from 'react-feather';
import { useCreatePortoModal } from 'features/portfolio/modals/CreatePortfolioRoboModal/context/CreateProtfolioModalProvider';
import DontHavePortoRoboIcon from 'assets/images/portfolio/dont-have-porto-robo.svg';
import styles from './InformationCreatingRoboModal.module.css';

const InformationCreatingRoboModal: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    createPortfolioRoboModalVisible,
    step,
    closeModal,
    setStep,
    resetCreatePortfolioRoboModal,
  } = useCreatePortoModal();
  const visible = createPortfolioRoboModalVisible && step === 'information';

  const handleNextStep = () => {
    setStep('choose');
  };

  const handleCloseModal = () => {
    closeModal();
    resetCreatePortfolioRoboModal();
  };

  return (
    <Modal
      visible={visible}
      title=' '
      showBackButton={false}
      showClose={true}
      titlePosition='center'
      onClose={handleCloseModal}
    >
      <Flex direction='column' justify='center'>
        <div className={styles['spot-hero']}>
          <img src={DontHavePortoRoboIcon} alt='dont have porto robo' />
        </div>
        <Text type='subtitle2' className={styles['content-text']}>
          Kamu Belum Punya Portofolio Robo
        </Text>
        <Text type='subtitle1' className={styles['content-text']}>
          Voucher ini hanya dapat digunakan untuk pembelian di portofolio robo.
        </Text>
        <Button className={styles['button-create']} onClick={handleNextStep}>
          <Plus size={16} style={{ marginRight: 4 }} /> Buat Portofolio Robo
        </Button>
      </Flex>
    </Modal>
  );
};

export default InformationCreatingRoboModal;
