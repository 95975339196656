import React from 'react';
import styles from './NotFound404.module.css';
import ContentBox from '../ContentBox';
import tabBarHoc from 'withoutTabBar';
import { ChevronLeft } from 'react-feather';
import { getEnv } from 'core/env';
import classNames from 'classnames';
import { Text, TopBar } from '@bibitid/uikit-v1';
import PageNotFound from 'assets/images/page-not-found/PageNotFound';

interface Props {
  title?: string;
  desc?: string;
  image?: string;
  navbarLeftContent?: React.ReactNode;
  buttonRedirect?: React.ReactNode;
  buttonRedirectPosition?: 'middle' | 'bottom';
}

/**
 * Our app back button element
 */
const BackButton404 = () => {
  const handleClick = () => {
    const { PublicUrl } = getEnv();
    window.location.assign(PublicUrl || '/');
  };

  return (
    <div className='backBigger' onClick={handleClick} data-testid='back-btn'>
      <ChevronLeft color={'var(--icon-default)'} size={20} />
    </div>
  );
};

export const NotFound404: React.FC<React.PropsWithChildren<Props>> = ({
  title = 'Halaman Tidak Ditemukan',
  desc = 'Maaf, halaman yang kamu cari tidak dapat ditemukan di sistem kami.',
  image = <PageNotFound />,
  navbarLeftContent = <BackButton404 />,
  buttonRedirect,
  buttonRedirectPosition,
}) => {
  return (
    <div className='content-wrapper'>
      <TopBar leftContent={navbarLeftContent} />
      <ContentBox>
        <div className={styles['not-found-container']}>
          <div className={styles['not-found']}>
            {typeof image === 'string' ? (
              <img src={image} alt='not valid' />
            ) : (
              image
            )}
            <Text htmlTag='h3' type='heading16b'>
              {title}
            </Text>
            <Text type='body14r' color='secondary'>
              {desc}
            </Text>
          </div>
          {buttonRedirect && (
            <div
              className={classNames({
                [styles['button-redirect']]: true,
                [styles['button-redirect-bottom']]:
                  buttonRedirectPosition === 'bottom',
              })}
            >
              {buttonRedirect}
            </div>
          )}
        </div>
      </ContentBox>
    </div>
  );
};

export default tabBarHoc(NotFound404);
