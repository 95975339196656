import list from './index';
import http from 'core/http';

/**
 * @typedef {Object} ProfilingQuestionOption Option for answering profiling questions
 * @prop {string | number} value Value that represents answer for the field
 * @prop {string} name Human readable answer
 */

/**
 * @typedef {Object} ProfilingQuestion Represents profiling question seen by user
 * @prop {string} image Main / hero image of the question
 * @prop {string} name Identifier for this question
 * @prop {string} title Human readable question seen by users
 * @prop {string=} description Helpful info for users to fill the answer of this question
 * @prop {string} placeholder Placeholder for this question input
 * @prop {'date'|'select'|'input'|'currency'} type Input type for answering this question
 * @prop {ProfilingQuestionOption[]=} options Option for answering this question, available if the input type is 'select'
 */

/**
 * @typedef {Object} RoboProfilingQuestionTemplate Template for risk profiling activity. This will decide fund packaging for robo
 * @prop {string} version Profiling template version
 * @prop {string} name String that identifies this template
 * @prop {Array<ProfilingQuestion>} question List of robo profiling question
 */

/**
 * Fetch all robo recommendations for the user
 */
export function fetchAllRobo() {
  return http.get(list.roboList);
}

/**
 * Fetch all robo recommendations for the user
 */
export function fetchScoringMap() {
  return http.get(list.roboScoring);
}

/**
 * Fetch robo recommendations for the user by roboid
 * @param {String} roboid - Robo id
 */
export function fetchRoboRecommendationById(roboid, is_sharia = 0) {
  return http.get(list.roboCheck, {
    roboid,
    ...(is_sharia === 1 ? { is_sharia: 1 } : {}),
  });
}

/**
 * Update robo profile score
 * @param {String} roboid                    - Robo id
 * @param {Object} updateData                - Update score data
 * @param {String | Number} updateData.score - Robo risk score
 */
export function postRoboScoreUpdateById(roboid, updateData) {
  return http.post(list.roboScoreUpdateById(roboid), updateData);
}

/**
 * Update robo recommendation item
 * @param {String} roboid                    - Robo id
 * @param {Object} updateData                - Update score data
 */
export function postRoboRecommendationUpdate(roboid, updateData) {
  return http.post(list.roboUpdate(roboid), updateData);
}

/**
 * GET: Robo template
 *
 * Fetch risk score profiling questions template from our system
 * @returns {import("services").BibitApiAxiosResponse<RoboProfilingQuestionTemplate>}
 */
export function getRoboTemplate() {
  return http.get(list.roboTemplate);
}

/**
 * POST: robo register
 * @param {object} form
 */
export function postRoboRegister(form) {
  return http.post(list.roboRegister, form);
}

/**
 * POST: robo register
 * @param {object} form
 */
export function postRoboPreview(form) {
  return http.post(list.roboPreview, form);
}

/**
 * POST: calculate robo when top up
 * @param {string} roboId
 * @param {object} data
 */
export function postRoboCalculateTopUp(roboId, data) {
  return http.post(list.roboCalculateTopup(roboId), data);
}

/**
 * POST: Calculate robo when sell
 * @param {string} roboId
 * @param {object} data
 * @package {} signal -> token cancel
 */
export function postRoboCalculateSell(roboId, data, signal) {
  return http.post(list.roboCalculateSellById(roboId), data, '', {
    signal,
  });
}

/**
 * Post: Preview Robo Profiling Score without saving to database
 * @param {object} params
 * @returns
 */
export function postRoboProfilingScorePreview(params) {
  return http.post(list.roboPreview, params);
}

/**
 * POST: Calculate robo when sell All
 * @param {string} roboId
 * @param {object} data
 */
export function postRoboSellAllPreview(data) {
  return http.post(
    list.sharedPortfolioRoboSellAllPreview(data.shared_porto_id),
    data
  );
}

/**
 * POST: Calculate single porto when sell All
 * @param {string} roboId
 * @param {object} data
 */
export function postSellAllPreview(data) {
  return http.post(
    list.sharedPortfolioSellAllPreview(data.shared_porto_id),
    data
  );
}
