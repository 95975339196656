import React from 'react';

const Spinner: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className='boxloader' data-testid='spinner-loading'>
      {' '}
      <div className='spinner' />{' '}
    </div>
  );
};

export default React.memo(Spinner);
