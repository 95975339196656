import axios from 'axios';
import DeviceInfo from 'core/DeviceInfo';
import getSafely from 'utils/safely';
import history from 'utils/History';
import { Toast } from '@bibitid/uikit';
import {
  setStatusBarWhenClosingCrisp,
  setStatusBarWhenOpeningCrisp,
} from 'core/statusbar/utils/StatusBarCrisp';
import rqClient from 'network/queryClient';
import { postData as postDatav3 } from 'core/Parent';

type $CrispType = { push: Function; get: Function; onClose?: Function };
type OnSupportErrorType = (onHide: Function) => void;
type SessionCrispType = {
  email?: string;
  fullname?: string;
  phone?: string;
  CRISP_TOKEN_ID?: string;
};

const ax: ReturnType<typeof axios.create> = axios.create();
const intervalcheck: number = 0;

/**
 * Check if crisp global object is ready
 *
 * @param {Function} cb Function to be called when crisp object is ready
 * @param {number} timeout Interval time to check
 */
function crispReady(cb: Function, timeout: number = 500): void {
  const { $crisp } = window;
  if ($crisp && timeout < 1) {
    return cb($crisp);
  }
  setTimeout(() => {
    if ($crisp) {
      cb($crisp);
    } else {
      crispReady(cb, timeout);
    }
  }, timeout);
}

/**
 * Set new session crisp
 *
 * @param {SessionCrispType} session
 */
function setNewSession(session: SessionCrispType): void {
  const { email, fullname, phone } = session;

  crispReady(($crisp: $CrispType) => {
    DeviceInfo.getInfo().then((devinfo: Partial<{ [key: string]: string }>) => {
      try {
        if (devinfo) {
          let output = Object.keys(devinfo).map((name) => {
            let _a = devinfo[name] ? devinfo[name] : '';
            const keyLowerCase = name && name.toLowerCase();
            return [keyLowerCase, String(_a)];
          });
          $crisp.push(['set', 'session:data', [output]]);
        }
      } catch (err) {
        console.error(err);
      }
    });

    if (!!email) $crisp.push(['set', 'user:email', email]);
    if (!!phone) $crisp.push(['set', 'user:phone', phone]);
    if (!!fullname) $crisp.push(['set', 'user:nickname', fullname]);
  });
}

/**
 * Set new session and inject CRISP_TOKEN_ID
 *
 * @param {SessionCrispType} session session crisp
 */
function createNewSession(session: SessionCrispType) {
  setNewSession(session);
}

/**
 * Add tag `premium_user` for premium user
 */
export const premiumUserTagSegment = () => {
  crispReady(($crisp: $CrispType) => {
    $crisp.push(['set', 'session:segments', [['premium_user']]]);
  });
};

/**
 * Reset current season
 */
function destroyCurrentSession() {
  crispReady(($crisp: $CrispType) => {
    $crisp.push(['do', 'session:reset']);
  }, intervalcheck);
}

/**
 * Init listener
 */
function addListener() {
  crispReady(($crisp: $CrispType) => {
    $crisp.push([
      'on',
      'session:loaded',
      function () {
        $crisp.push(['do', 'chat:hide']);
        let sessionId = $crisp.get('session:identifier');
        if (!sessionId) {
          $crisp.push(['do', 'chat:open']);
        } else {
          $crisp.push(['do', 'chat:close']);
          rqClient.setQueryDefaults('crispSessionIsLoaded', {
            staleTime: Infinity,
            cacheTime: Infinity,
          });
          rqClient.setQueryData('crispSessionIsLoaded', true);
        }

        crispReady(($crisp: $CrispType) => {
          $crisp.push(['do', 'chat:hide']);
        });
      },
    ]);
    $crisp.push([
      'on',
      'chat:opened',
      function () {
        // Tell native that crisp is being opened
        postDatav3('setThirdPartyModalStatus', {
          fn: 'setThirdPartyModalStatus',
          data: { isOpen: true },
          timeout: 0,
        });

        $crisp.push(['do', 'chat:show']);
      },
    ]);
    $crisp.push([
      'on',
      'chat:closed',
      function () {
        $crisp.push(['do', 'chat:hide']);

        // Tell native that crisp will be closed
        postDatav3('setThirdPartyModalStatus', {
          fn: 'setThirdPartyModalStatus',
          data: { isOpen: false },
          timeout: 0,
        });

        if ($crisp.onClose) {
          $crisp.onClose();
          //reset crisp onclose function
          $crisp.onClose = undefined;
        }
      },
    ]);
    $crisp.push([
      'on',
      'helpdesk:queried',
      function () {
        let links = document.links,
          i,
          length;
        for (i = 0, length = links.length; i < length; i++) {
          links[i].target === '_blank' && links[i].removeAttribute('target');
        }
      },
    ]);
  }, intervalcheck);
}

/**
 * Remove listener crisp
 */
function unbindListener() {
  crispReady(($crisp: $CrispType) => {
    $crisp.push([
      'off',
      'helpdesk:queried',
      function () {
        var links = document.links,
          i,
          length;
        for (i = 0, length = links.length; i < length; i++) {
          links[i].target === '_blank' && links[i].removeAttribute('target');
        }
      },
    ]);
  }, intervalcheck);
}

/**
 * Check network if its available to access crisp
 *
 * @param {Function} fnSuccess Callback success to next request
 * @param {Function} fnError Callback handling error
 */
function healtyCheckSocketCrisp(fnSuccess: Function, fnError: Function) {
  function onHide() {
    Toast.clear();
  }

  /* start loading */
  Toast.show({
    content: 'Harap tunggu',
    duration: 0,
    icon: 'loading',
    afterClose: onHide,
  });

  ax.head(`https://status.crisp.chat/`)
    .then((response) => {
      onHide();
      if (typeof fnSuccess == 'function') {
        // set status bar into light when crisp open
        setStatusBarWhenOpeningCrisp();

        return fnSuccess(response);
      }
    })
    .catch((err) => {
      console.error(err);
      if (typeof fnError == 'function') return fnError(onHide);
      else return onHide();
    });
}

/**
 * Get crisp global object safely
 */
function getCrispObject() {
  const defaultObject: $CrispType = {
    push: () => {},
    get: () => {},
  };

  const $crisp = getSafely(['$crisp'], window, defaultObject);

  return $crisp;
}

/**
 * Common interface to open live chatbox crisp
 *
 * @param {OnSupportErrorType} handleError Handle error function if network is in trouble
 */
function showLiveSupport(
  handleError?: OnSupportErrorType,
  settings?: {
    onClose?: Function;
  }
) {
  let onError: OnSupportErrorType = (onHide: Function) => {
    history.push('bantuan');
    onHide();
  };

  if (typeof handleError === 'function') onError = handleError;

  const $crisp: $CrispType = getCrispObject();

  // handle close crisp with calling param onclose and setStatusBarWhenClosingCrisp
  if (settings?.onClose) {
    const handleCloseCrisp = () => {
      setStatusBarWhenClosingCrisp();
      settings.onClose?.();
    };

    $crisp.onClose = handleCloseCrisp;
  } else {
    // handle close crisp with calling setStatusBarWhenClosingCrisp
    $crisp.onClose = setStatusBarWhenClosingCrisp;
  }

  healtyCheckSocketCrisp(() => $crisp.push(['do', 'chat:toggle']), onError);
}

const hideLiveSupport = () => {
  const { $crisp } = window;

  // check if crisp is visible / opened
  if ($crisp && $crisp.is('chat:opened')) {
    // close crisp chat
    $crisp.push(['do', 'chat:close']);
    // hide crisp small button
    $crisp.push(['do', 'chat:hide']);
    return;
  }
};

const utils = {
  crispReady,
  setNewSession,
  destroyCurrentSession,
  createNewSession,
  addListener,
  unbindListener,
  showLiveSupport,
  hideLiveSupport,
};

export default utils;
