import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ChevronLeft } from 'react-feather';
import ContentBox from 'features/common/ContentBox';
import AppNavbar from 'features/common/navbar/AppNavbar';
import tabBarHoc from 'withoutTabBar';
import { useParams, useHistory } from 'react-router-dom';
import './IndexReksadanaProduct.css';
import { goBack } from 'utils/History';
import { useFlag } from 'utils/feature-flag';
import { useQueryParams } from 'utils/routeHelper';
import useNavbarColorTransition from 'core/statusbar/hooks/useNavbarColorTransition';
import { StatusBarApp } from 'core/statusbar/types';
import {
  useStatusBarFunction,
  useStatusBarState,
} from 'core/statusbar/context/StatusBarContext';
import {
  DEFAULT_STATUS_BAR_COLOR,
  PORTFOLIO_PAGE_STATUS_BAR_COLOR,
} from 'core/statusbar/constants';
import { ReksaDanaCardList } from 'features/common/reksadana/lists/CategorizedReksaDanaList';
import Analytics from 'utils/Analytics';
import classNames from 'classnames';
import Puller from 'features/common/Puller';
import { useTopUpGlobalContext } from 'features/topup-modal/TopupGlobalContext';
import { ReksaDana } from 'entities/reksadana.reducer';
import SortAndCompareButton from './components/SortAndCompareButton';
import { IntroInstantRedemptionModal } from 'features/bankjago/components/modals';
import {
  useReksadanaProductFunctionContext,
  useReksadanaProductStateContext,
} from './context/ReksadanaProductContext';
import { Waypoint } from 'react-waypoint';
import { onApplyParams } from './types';
import { AxiosHTTPError } from 'core/http/http.type';
import Show from 'features/common/components/Show/Show';
import ErrorCard from 'features/common/ErrorCard';
import NotFound404 from 'features/common/NotFound404/NotFound404';
import BibitPlusCategoryBanner, {
  CategoryBannerProps,
} from 'features/category/component/banners/BibitPlusCategoryBanner';
import { useTheme, useToggle } from 'hooks';
import TopCompanyInfoModal from 'features/common/modals/TopCompanyInfoModal/TopCompanyInfoModal';

interface SortAndCompareButtonParams {
  type: string;
  im?: string;
  category?: string;
}

interface IndexReksadanaProductProps extends PropsWithChildren<unknown> {
  /** probably dont need */
  pageType: string;
  showHeader?: boolean;
  showBanner?: boolean;
  showPullToRefresh?: boolean;
  removePullerBox?: boolean;
  showSortAndCompare?: boolean;
  showCompareButton?: boolean;
  onCardClick?: (roboCategory: string, symbol: string, additional: any) => void;
  onConfirmCompare?: () => void;
  category?: string;
  currency?: string;
  showLink?: boolean;
  listWrapperClassName?: string;
  classNamesPuller?: string;
  selected?: string | string[];
  fixedParamsFromProps?: Record<string, string | number | undefined>;
  /**
   * If this true, instant modal will be shown by query
   *
   * If false, it will be shown by local state
   *
   * Default value = true
   */
  enableInstantModalByQuery?: boolean;

  /**
   * Enabled to set statusbar or not when rendering this component
   */
  enabledSetStatusBarColor?: boolean;

  ableToSelectProduct?: boolean;
  onCustomNavigateMutualFundDetail?: (symbol: string) => void;

  // Clean query params on mount
  cleanQueryParams?: boolean;
  bottomHeight?: number;
  /**
   * If this is true:
   * - network will call is_top_product = true
   * - hide 'top product' filter section
   * - hide 'top' ribbon in product
   */
  showOnlyTopProduct?: boolean;

  inModal?: boolean;

  classNameBox?: string;
}

const truncateText = (txt: string) => {
  if (!txt) return '';

  const sp = txt.split(' ');
  if (sp.length <= 1) return ''; // guard

  if (txt.toUpperCase().startsWith('PT')) {
    return sp[1];
  }

  return sp[0];
};

/**
 * Decides what title will be rendered
 */
const decideTitle = (category: string, imName: string | null) => {
  switch (category) {
    case 'equity':
      return 'Reksa Dana Saham';
    case 'debt':
      return 'Reksa Dana Obligasi';
    case 'syariah':
      return 'Reksa Dana Syariah';
    case 'moneymarket':
      return 'Reksa Dana Pasar Uang';
    case 'instant':
      return 'Pencairan Instan';
    case 'others':
      return 'Reksa Dana Lainnya';
    case 'indexfund':
      return 'Index Fund';
    case 'usd':
      return 'Reksa Dana USD';
    case 'minimum':
      return 'Minimal 10rb';
    case 'im':
      return !!imName ? truncateText(imName || '') : 'Reksa Dana';
    // For custom category collections
    default:
      return 'Reksa Dana';
  }
};

/**
 * Display mutual fund to user, based on category
 */
const ReksadanaProductList: React.FC<IndexReksadanaProductProps> = ({
  pageType,
  showHeader = true,
  showBanner = true,
  onCardClick,
  onConfirmCompare,
  showSortAndCompare = true,
  category = '',
  currency,
  showLink,
  listWrapperClassName,
  classNamesPuller,
  selected,
  fixedParamsFromProps,
  enableInstantModalByQuery = true,
  showOnlyTopProduct = false,
  enabledSetStatusBarColor = true,
  ableToSelectProduct,
  onCustomNavigateMutualFundDetail,
  cleanQueryParams = false,
  bottomHeight,
  inModal,
  showPullToRefresh = true,
  removePullerBox,
  classNameBox,
  showCompareButton = true,
}) => {
  /**
   *
   * USE STATES
   *
   */

  const [isComparing, setListIsComparing] = useState(false);

  /**
   *

   *
   */

  const { statusBarColor } = useStatusBarState();
  const { onSetStatusBarColor } = useStatusBarFunction();
  const { changeColorByScroll } = useNavbarColorTransition();
  const { theme } = useTheme();
  const { active: showIRModal, toggleActive: toggleIRModal } = useToggle();
  const { active: showTopCompanyModal, toggleActive: toggleTopCompanyModal } =
    useToggle();

  const instantRedemptionActiveByFF: boolean = useFlag(
    'web_jago_instant_redemption'
  );

  const history = useHistory();

  const query = useQueryParams();

  const { maxBuy } = useTopUpGlobalContext();

  const { type, category: profilingCategory } =
    useParams<SortAndCompareButtonParams>();

  const {
    filterSortBy,
    filterSortPeriod,
    filterIsSorting,
    productQuery,
    filterIsInstantRedemption,
    comparedProducts,
    numberOfActiveSort,
  } = useReksadanaProductStateContext();

  const {
    setFilterType,
    setFilterCurrency,
    setFilterSortOrder,
    setComparedProducts,
    setFilterFixedParams,
    buildQueryParams,
    resetAllState,
    setFilterFixedParamsShown,
  } = useReksadanaProductFunctionContext();

  /**
   *
   * CONSTS
   *
   */

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
    error: errorProduct,
  } = productQuery;

  const errMsg = (errorProduct as AxiosHTTPError)?.response?.data?.message;

  const {
    location: { state },
  }: any = history;

  const categoryType = type || profilingCategory;

  const imName = query.get('im');
  const dynamicCategory = categoryType === 'im' ? imName : categoryType;

  /** Is categories instant page */
  const isCategoriesInstantPage =
    history.location.pathname === '/categories/instant';

  /**
   * @TODO change string type to SortByType
   * services/reksadana.type
   */

  /**
   *
   * FUNCTIONS
   *
   */

  /**
   * Go to last visited page
   */
  const handleClickBack = () => {
    goBack(history);
  };

  /**
   * Listen when content box scrolled
   */
  const listenPageScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!enabledSetStatusBarColor) return;

    const { backgroundColor, foregroundColor } = decideColor(category);

    changeColorByScroll(
      [
        {
          from: 0,
          to: 150,
          backgroundColor,
          foregroundColor,
        },
      ],
      event
    );
  };

  const handlePullToRefresh = () => {
    refetch();
  };

  const handleInitializeList = useCallback(() => {
    let fixedParams = {};

    switch (category) {
      case 'equity':
        setFilterType([1]);
        break;
      case 'debt':
      case 'fixincome':
        setFilterType([2]);
        break;
      case 'moneymarket':
        setFilterType([3]);
        break;
      case 'benchmark':
        setFilterType([8, 9]);
        break;
      case 'others':
        setFilterType([0]);
        break;
      case 'minimum':
        fixedParams = {
          meta: 'minimum',
        };
        break;
      case 'indexfund':
        fixedParams = {
          meta: 'indexfund',
        };
        break;
      case 'usd':
        setFilterCurrency('usd');
        break;
      case 'im':
        if (!!imName) {
          setFilterFixedParamsShown({
            im: imName,
          });
        }
        break;
      case 'instant':
        fixedParams = {
          is_instant_redemption: 1,
        };
        break;
      case 'syariah':
        fixedParams = {
          syariah: 1,
        };
        break;
      case 'best':
        fixedParams = {
          is_top_product: 1,
        };
        break;
      default:
        setFilterType([]);
        break;
    }
    /**
     * set filter is_top_product
     */
    if (!!showOnlyTopProduct)
      fixedParams = { ...fixedParams, is_top_product: 1 };

    /**
     * set filter max_buy
     */
    if (!!maxBuy) fixedParams = { ...fixedParams, max_buy: maxBuy };

    // set custom currency
    if (currency) setFilterCurrency(currency);

    // set extra params from props
    if (fixedParamsFromProps)
      fixedParams = { ...fixedParams, ...fixedParamsFromProps };

    setFilterFixedParams(fixedParams);
  }, [
    showOnlyTopProduct,
    category,
    maxBuy,
    currency,
    setFilterCurrency,
    fixedParamsFromProps,
    setFilterFixedParams,
    setFilterType,
    imName,
    setFilterFixedParamsShown,
  ]);

  const handleToggleCompareModal = (val?: boolean) => {
    if (typeof val !== 'undefined') {
      setListIsComparing(val);
      return;
    }
    setListIsComparing(!isComparing);
  };

  /** Show Introduction of Instant Redemption modal */
  const handleShowInstantModal = (
    event: React.SyntheticEvent<HTMLDivElement, Event>
  ) => {
    // Guard if instant redemption isn't active by feature flag
    if (!instantRedemptionActiveByFF) return;

    event.stopPropagation();
    toggleIRModal();
  };

  const handleCloseInstantModal = () => {
    toggleIRModal();
  };

  /** Show Top Company info modal */
  const handleShowTopCompanyModal = () => {
    toggleTopCompanyModal();
  };

  const handleCloseTopCompanyModal = () => {
    toggleTopCompanyModal();
  };

  /**
   * Handle reksadana card click
   * @param category
   * @param symbol
   * @returns
   */
  const handleCardClickInside = (category: string, symbol: string) => {
    const index = data?.allData.findIndex((item) => item.symbol === symbol);
    const fundObject: ReksaDana | null =
      index > -1 ? data?.allData[index] : null;

    // Is not comparing
    if (!isComparing) {
      // For other pages than category
      if (onCardClick) {
        onCardClick(category, symbol, {
          fundObject,
          sortBy: String(filterSortBy),
        });
        return;
      }

      // Push to ReksadanaPage
      history.push(`/reksadana/${symbol}`);

      if (filterIsSorting) {
        Analytics.logEventAction({
          eventName: 'search_action',
          parameter: {
            action: 'sort_company',
            trigger: 'click',
            data: {
              company_id: fundObject?.id,
              group: state,
              [state]: dynamicCategory,
            },
            context: 'home.group',
          },
        });
      } else {
        Analytics.logEventAction({
          eventName: 'search_action',
          parameter: {
            action: 'company',
            trigger: 'click',
            data: {
              company_id: fundObject?.id,
              search: !!Number(filterSortBy),
              group: state,
              [state]: dynamicCategory,
            },
            context: 'home.group',
          },
        });
      }
      return;
    }

    // If is comparing
    // Remove from comparison if selected again, for toggling effect
    if (Array.isArray(comparedProducts) && comparedProducts.includes(symbol)) {
      setComparedProducts(comparedProducts.filter((item) => item !== symbol));

      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'compare_company',
          trigger: 'click',
          data: {
            company_id: fundObject?.id,
            group: state,
            [state]: dynamicCategory,
            status: 'deselect',
          },
          context: 'search.group',
        },
      });
      return;
    }

    // Prevent addition if already 3 symbol selected
    if (Array.isArray(comparedProducts) && comparedProducts.length >= 3) {
      return;
    }

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'compare_company',
        trigger: 'click',
        data: {
          company_id: fundObject?.id,
          group: state,
          [state]: dynamicCategory,
          status: 'select',
        },
        context: 'search.group',
      },
    });

    return setComparedProducts([...comparedProducts, symbol]);
  };

  /**
   * handle apply filter & sort and add query string to url
   * @param params selected filter parameters
   */
  const handleApply = (params: onApplyParams) => {
    history.replace({
      search: buildQueryParams(params),
    });
  };

  /**
   * handle change sort direction
   * @param direction sort direction
   */
  const handleDirectionChange = (direction: string) => {
    history.replace({
      search: buildQueryParams({
        is_instant_redemption: filterIsInstantRedemption,
        sort: direction,
        sort_by: String(filterSortBy),
        sort_period: filterSortPeriod,
      }),
    });
    setFilterSortOrder(direction);
  };

  /**
   *
   * USE EFFECTS
   *
   */

  const decideColor = (category: string) => {
    let statusBarColorDecided: StatusBarApp;

    switch (category) {
      case 'moneymarket':
        statusBarColorDecided = {
          backgroundColor:
            theme === 'light'
              ? PORTFOLIO_PAGE_STATUS_BAR_COLOR.backgroundColor
              : '#163228',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'debt':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#dceaf4' : '#1e2d39',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'equity':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#ecdff7' : '#2a2339',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'others':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#e1f0f9' : '#1c3236',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'syariah':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#ECF2DE' : '#212711',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'indexfund':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#f4ede7' : '#382d1e',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'usd':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#e0e5f0' : '#1b2637',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'minimum':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#f1dfee' : '#351d31',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'instant':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#fff1d1' : '#352e1d',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      default:
        statusBarColorDecided = {
          backgroundColor: DEFAULT_STATUS_BAR_COLOR().backgroundColor,
          foregroundColor: DEFAULT_STATUS_BAR_COLOR().foregroundColor,
        };
        return statusBarColorDecided;
    }
  };

  useEffect(() => {
    if (!enabledSetStatusBarColor) return;

    const { backgroundColor, foregroundColor } = decideColor(category);

    onSetStatusBarColor({ backgroundColor, foregroundColor });

    return () => {
      document.body.style.removeProperty('overflow');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSetStatusBarColor, category, enabledSetStatusBarColor, theme]);

  useEffect(() => {
    // If instant redemption FF is being turned off, user cannot access /categories/instant page
    // This block will force user to other page
    if (!instantRedemptionActiveByFF && isCategoriesInstantPage) {
      history.replace('/categories/others');
    }
  }, [instantRedemptionActiveByFF, history, isCategoriesInstantPage]);

  /** Initalize state on page open */
  useEffect(() => {
    resetAllState();
    handleInitializeList();
  }, [handleInitializeList, resetAllState]);

  const disableClean = query.get('disable-clean');

  /** clean query params */
  useEffect(() => {
    if (Boolean(disableClean)) return;
    if (!cleanQueryParams) return;

    history.replace({
      search: '',
    });
  }, [cleanQueryParams, history, category, disableClean]);

  /** Navigate analytics */
  useEffect(() => {
    if (filterIsSorting) {
      const data = {
        group: state,
        [state]: dynamicCategory,
        ...(!!filterSortBy ? { sort_by: filterSortBy } : {}),
        ...(!!filterSortPeriod ? { sort_periode: filterSortPeriod } : {}),
      };

      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'search',
          view: 'filter_result',
          data: data,
        },
      });
    }
  }, [state, dynamicCategory, filterSortBy, filterSortPeriod, filterIsSorting]);

  const dataEmpty = (data?.allData || []).length <= 0;

  if (!isLoading && !!errMsg) {
    return (
      <NotFound404
        title='Produk Tidak Ditemukan'
        desc='Maaf produk yang kamu cari tidak ditemukan di sistem kami'
      />
    );
  }

  return (
    <div
      className={classNames(
        'content-wrapper get-started-page get-started-page-reksadana',
        {
          'mutualfund-categories--dark': theme === 'dark',
          [classNameBox ?? '']: !!classNameBox,
        }
      )}
    >
      {showHeader && (
        <div
          className='content-header categories-page'
          style={{ backgroundColor: statusBarColor.backgroundColor }}
        >
          <AppNavbar
            style={{ backgroundColor: 'transparent' }}
            title={decideTitle(category, imName)}
            leftContent={
              <div className='backBigger' onClick={handleClickBack}>
                <ChevronLeft color='var(--icon-default)' size={20} />
              </div>
            }
          />
        </div>
      )}

      <ContentBox
        headerHeight={51}
        bottomHeight={bottomHeight}
        onScroll={listenPageScroll}
        className={classNames({ 'content-box-inmodal': inModal })}
        id='scrollable-container'
      >
        {/* Category Banner */}

        {showBanner && (
          <BibitPlusCategoryBanner
            category={category as CategoryBannerProps['category']}
          />
        )}

        {/* Filter and ReksaDana List With Pull To Refresh */}
        <Puller
          className={classNames({
            'puller-box': isLoading || !removePullerBox || dataEmpty,
            fullheight: true,
            [classNamesPuller ?? '']: !!classNamesPuller,
          })}
          onRefresh={handlePullToRefresh}
          disabled={!showPullToRefresh}
          removePullerBox={removePullerBox}
        >
          <SortAndCompareButton
            onApply={handleApply}
            onSortDirectionChange={handleDirectionChange}
            onToggleCompareModal={handleToggleCompareModal}
            onConfirmCompare={onConfirmCompare}
            showOnlyTopProduct={showOnlyTopProduct}
            productListCategory={category}
            inModal={inModal}
            isFilterHidden={!showSortAndCompare}
            showCompareButton={showCompareButton}
          />

          <Show when={!isLoading && !!errMsg}>
            <ErrorCard
              message={errMsg ?? 'Terjadi kesalahan. Coba kembali'}
              onReload={handlePullToRefresh}
            />
          </Show>

          {/* ReksaDana Card List */}
          <div
            className={classNames(
              'bit-reksa-cards-wrap',
              'paddingBottom50',
              listWrapperClassName,
              {
                'bit-reksa-compare-maximum-padding compare-modal-padding-bottom':
                  isComparing,
              }
            )}
          >
            {!isLoading && (
              <ReksaDanaCardList
                loading={isLoading}
                category={category}
                funds={data?.allData}
                sortBy={filterIsSorting ? String(filterSortBy) : ''}
                sortPeriod={filterSortPeriod}
                loadMoreLoading={isFetchingNextPage}
                isSorting={filterIsSorting && numberOfActiveSort > 0}
                onCardClick={handleCardClickInside}
                isComparing={isComparing}
                selected={isComparing ? comparedProducts : selected}
                handleShowInstantModal={handleShowInstantModal}
                handleShowTopCompanyModal={handleShowTopCompanyModal}
                showTopProductRibbon={!showOnlyTopProduct}
                showLoading={false}
                showLink={showLink}
                ableToSelectProduct={ableToSelectProduct}
                onCustomNavigateMutualFundDetail={
                  onCustomNavigateMutualFundDetail
                }
              />
            )}
            {(isLoading || isFetchingNextPage) && (
              <div className='boxloader'>
                <div className='spinner' />
              </div>
            )}
          </div>
        </Puller>

        {/* Pencairan Instan Modal */}
        <IntroInstantRedemptionModal
          showModal={showIRModal}
          onClose={handleCloseInstantModal}
        />

        {/* Top Company Info Modal */}
        <TopCompanyInfoModal
          onClose={handleCloseTopCompanyModal}
          visible={showTopCompanyModal}
        />

        {/* Infinite Scroll */}
        {Array.isArray(data?.allData) &&
          data?.allData.length > 0 &&
          hasNextPage && <Waypoint onEnter={() => fetchNextPage()} />}
      </ContentBox>
    </div>
  );
};

const ReksadanaProductListWithTabBarHOC = tabBarHoc(ReksadanaProductList);

export const ReksadanaProductListWithoutTabBarHOC = ReksadanaProductList;

export default ReksadanaProductListWithTabBarHOC;
