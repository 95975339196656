import * as Parent from 'core/Parent';
import { randomString } from './stringHelper';
import Analytics from './Analytics';
import * as Sentry from '@sentry/browser';
import { checkFeatureVersion } from './feature-version';

type OpenThirdPartyWebviewRequestBody = {
  /**
   * entry url (only whitelisted url)
   */
  url: string;
  /**
   * exit url to tell native when to close third party webview and back/redurect to bibit webview. Use regex to be more flexible
   * @example
   * exit_url: '.*orders/123/detail.*'
   */
  exit_url: string[];
  /**
   * exit trigger types:
   * - redirect: native will redirect to bibit page based on `exit_url`
   * - back: native only close third party webview and back to latest bibit page
   */
  exit_trigger?: 'redirect' | 'back';
  /**
   * additional info to tell native that there is a method type
   */
  method?: 'get' | 'post';
};

type OpenThirdPartyWebviewResponse = {
  data?: {
    data?: {
      /**
       * complete exit url (with query string, etc)
       */
      exit_url: string;
      /**
       * flag to inform that the process in third party webview is completed / dropped
       */
      isProcessFinished: boolean;
    };
    message: string;
  };
};

type OpenThirdPartyWebviewErrorType =
  | 'app_version_not_supported'
  | 'post_message_error'
  | 'native_callback_error';

export type OpenThirdPartyWebviewError = {
  response: {
    data: {
      message: string;
      type: OpenThirdPartyWebviewErrorType;
    };
  };
};

export const openThirdPartyWebView = ({
  url,
  exit_url,
  exit_trigger = 'back',
  method = 'get',
}: OpenThirdPartyWebviewRequestBody) => {
  return new Promise<OpenThirdPartyWebviewResponse>(async (resolve, reject) => {
    Analytics.logEventAction({
      eventName: 'third_party_webview_action',
      parameter: {
        action: 'request_open_third_party',
        context: 'third_party_webview',
        trigger: 'post_message',
        data: {
          url,
          exit_url,
          exit_trigger,
          method,
        },
      },
    });

    const appSupported = await checkFeatureVersion('Open Third Party Webview');

    if (!appSupported) {
      return reject({
        response: {
          data: {
            message:
              'App version not supported. Please update to latest version',
            type: 'app_version_not_supported',
          },
        },
      });
    }

    return Parent.postData(
      `openThirdPartyWebView_${randomString()}`,
      {
        fn: 'openThirdPartyWebView',
        data: {
          url,
          exit_url,
          exit_trigger,
          method,
        },
        timeout: 0,
      },
      (error, data) => {
        Analytics.logEventAction({
          eventName: 'third_party_webview_action',
          parameter: {
            action: 'callback_open_third_party',
            context: 'third_party_webview',
            trigger: 'post_message',
            data: {
              url,
              exit_url,
              exit_trigger,
              method,
            },
          },
        });

        // post message error
        if (error) {
          return reject({
            response: {
              data: {
                message: error,
                type: 'post_message_error',
              },
            },
          });
        }

        /**
         * Native callback error when:
         * - not whitelisted webview url
         * - something wrong with post message from native side
         */
        if (data?.error) {
          Sentry.withScope((scope: any) => {
            scope.setLevel('info');
            scope.setTag('domain', 'third_party_webview');
            Sentry.captureException(
              new Error(`openThirdPartyWebView Error: ${data?.error}`)
            );
          });

          return reject({
            response: {
              data: {
                message: data?.error,
                type: 'native_callback_error',
              },
            },
          });
        }

        return resolve({
          data: {
            data: {
              isProcessFinished: data?.isProcessFinished,
              exit_url: data?.data,
            },
            message: 'Successfully exit third party webview',
          },
        });
      }
    );
  });
};
