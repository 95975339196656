export const flagKeys = {
  web_catatan_cuan: false,
  web_jago_linking: false,
  web_jago_coregist: false,
  web_sbn: false,
  web_jago_instant_redemption: false,
  web_linkaja_applink: false,
  web_institution_frbonds: false,
  web_institution_without_individual: false,
  web_bibit_darkmode: false,
  web_new_instant_redemption: false,
  web_bibit_junior: false,
  web_bibit_sso: false,
  web_idle_timeout: false,
  web_bibit_premium_bisnis_banner: false,
  web_bibit_appcheck: false,
  web_bibit_sbn_skip_tnc: false,
  web_bibit_quick_auth: false,
  web_bibit_convenience_fee_animation: false,
  web_jago_snap: false,
  web_art_jakarta_filter: false,
  web_art_jakarta_filter_premium: false,
};

export type FlagKeys = keyof typeof flagKeys;

export type FlagKeyValue = boolean | { overrideValue: boolean };

export type FlagKeysObject = Record<FlagKeys, FlagKeyValue>;

export default flagKeys as FlagKeysObject;
