import { Text, Skeleton } from '@bibitid/uikit-v1';
import React from 'react';
import styles from './ProfitCard.module.css';
import classNames from 'classnames';
import { TrendingUp, Percent, ArrowRight } from 'react-feather';
import { useTheme } from 'hooks';
import { useAmountBlurred } from 'features/common/AmountBlurred/provider/AmountBlurredProvider';
import { FadeAmountBlurred } from 'features/common/AmountBlurred/components';
interface ProfitCardProps {
  value: number;
  stringValue: string | React.ReactNode;
  name: 'Keuntungan' | 'Imbal Hasil';
  isLoading: boolean;
  type: string;
  product: string;
}

const ProfitCard: React.FC<React.PropsWithChildren<ProfitCardProps>> = ({
  value,
  stringValue,
  name,
  isLoading,
  type,
  product,
}) => {
  const { theme } = useTheme();
  const { handleBlurredAmount } = useAmountBlurred();

  const renderTotalProfit = () => {
    if (isLoading) {
      switch (product) {
        case 'money-market':
          return (
            <Skeleton
              width={80}
              height={16}
              animation='static'
              variant='ellipse'
              backgroundColor='greenHigh'
            />
          );
        case 'obligasi':
          return (
            <Skeleton
              width={80}
              height={16}
              animation='static'
              variant='ellipse'
              backgroundColor='cobaltHigh'
            />
          );
        case 'stock':
          return (
            <Skeleton
              width={80}
              height={16}
              animation='static'
              variant='ellipse'
              backgroundColor='violetHigh'
            />
          );
        case 'mix':
          return (
            <Skeleton
              width={80}
              height={16}
              animation='static'
              variant='ellipse'
              backgroundColor='blueHigh'
            />
          );
        default:
          return (
            <Skeleton
              width={80}
              height={16}
              animation='static'
              variant='ellipse'
              backgroundColor='brownHigh'
            />
          );
      }
    }

    return (
      <FadeAmountBlurred dotAmount={4} onClick={handleBlurredAmount}>
        <div
          className={classNames(styles['portfolio-price-change'], {
            [styles['portfolio-price-green']]: value > 0,
            [styles['portfolio-price-red']]: value < 0,
          })}
        >
          {value !== 0 && <ArrowRight size={14} />}
          <Text className={styles['value']}>{stringValue}</Text>
        </div>
      </FadeAmountBlurred>
    );
  };

  return (
    <div
      className={classNames(styles['profit-card'], styles[type], {
        [styles['profit-card--dark']]: theme === 'dark',
      })}
    >
      <div className={classNames(styles['circle'])}>
        {name === 'Keuntungan' && <TrendingUp size={16} color='#00AB6B' />}
        {name === 'Imbal Hasil' && <Percent size={16} color='#00AB6B' />}
      </div>
      <div>
        <Text type='caption1' className={styles['name']}>
          {name}
        </Text>
        {name === 'Keuntungan' ? (
          <div className={styles['profit']}>{renderTotalProfit()}</div>
        ) : (
          <div
            className={classNames(styles['portfolio-price-change'], {
              [styles['portfolio-price-green']]: value > 0,
              [styles['portfolio-price-red']]: value < 0,
              [`${styles['loader']} animated-background`]: isLoading,
              [styles['money-market']]: product === 'money-market',
              [styles['obligasi']]: product === 'obligasi',
              [styles['stock']]: product === 'stock',
              [styles['mix']]: product === 'mix',
              [styles['cash']]: product === 'cash',
            })}
          >
            {value !== 0 && <ArrowRight size={14} />}
            <Text className={styles['value']}>{stringValue}</Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfitCard;
