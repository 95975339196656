import { useContext } from 'react';
import { HomeOnboardingContext } from '../contexts/HomeOnboardingProvider';

const useHomeOnboardingContext = () => {
  const context = useContext(HomeOnboardingContext);
  if (context === undefined) {
    throw new Error(
      'useHomeOnboardingContext must be used within a HomeOnboardingProvider'
    );
  }
  return context;
};

export default useHomeOnboardingContext;
