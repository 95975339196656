import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { openToastTop } from 'features/common/toast/toast.reducer';
import { clearLocalSessionAsync } from './utils';
import Storage from 'core/Storage';
import { postLogout } from 'services/auth';
import * as Sentry from '@sentry/browser';
import SplashScreen from 'features/common/BibitLoader/BibitLoader';
import { initialHeaderHttp } from './utils';
import { generalLogError } from 'utils/Analytics';
import AutoLogout from 'features/autologout-modal';
import IndexErrorModal from 'features/errors/LazyErrorModal';
import { useLocation } from 'react-router-dom';
import {
  getCurrentInstitution,
  removeCurrentInstitution,
  removeInstitutionProfileNotification,
  setCurrentInstitution,
} from 'features/institution/utils';
import { isInsideNativeContainer } from 'utils/validator';
import { nativeSupportInstitution } from 'features/institution/utils/version';
import history from 'utils/History';
import { institutionBlackListRoute } from 'features/institution/constants';
import { isNumber } from 'utils/number';
import { DEFAULT_STATUS_BAR_COLOR } from 'core/statusbar/constants';
import { setAppStatusBarColor } from 'core/statusbar/utils/setAppStatusBarColor';
import { clevertapLogoutUser } from 'core/clevertap/analytics';
import useAutoSwitchInstitutionAccOnly from './hooks/useAutoSwitchInstitutionAccOnly';
import { useProfileData } from 'hooks';
import { getEnv } from 'core/env';
import { indexedDB } from 'core/http/indexedDb/indexedDb';

const { DevTools } = getEnv();

interface LogoutParams {
  redirect?: string;
  data?: {
    onBeforeLogout?: () => void;
    onSuccessLogout?: () => void;
    onErrorLogout?: () => void;
  };
}

export interface AuthContextType {
  /** Determine if user has successfully login or not */
  isLogin: boolean;

  /** Loading state for logging out user */
  isLoggingOut: boolean;

  /** Determine if user logged in status with `hasNoPin` or `hasNoRoboId`  */
  userLoggedInStatus: string;

  /** Set user login or not */
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;

  /** Set user logged in status is `hasNoPin` or `hasNoRoboId` */
  setUserLoggedInStatus: React.Dispatch<React.SetStateAction<string>>;

  /** a function to log out user */
  logout: (
    redirect?: LogoutParams['redirect'],
    data?: LogoutParams['data']
  ) => void;

  /** Actual Access Token */
  accessToken: string;

  /** Token Handler Promise has Settled */
  loginPromiseHasSettled: boolean;
}

// Constants
export const LOGOUT_QUERY_KEY = 'postLogout';

export const defaultAuthContextValue = {
  isLogin: false,
  isLoggingOut: false,
  userLoggedInStatus: '',
  setIsLogin: () => {},
  setUserLoggedInStatus: () => {},
  logout: () => {},
  accessToken: '',
  loginPromiseHasSettled: false,
};

export const AuthContext = React.createContext<AuthContextType>(
  defaultAuthContextValue
);

const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isLogin, setIsLogin] = useState(false);
  const [userLoggedInStatus, setUserLoggedInStatus] = useState('');

  const location = useLocation<{ institution_id: string }>();

  // determine token is Ready in our app
  const [tokenIsReady, setTokenIsReady] = useState(false);

  const dispatch = useDispatch<any>();

  // profile data
  const { data: profileData } = useProfileData(isLogin);
  const profileDetail = profileData?.data?.data;

  // get `institution account only` data
  const isIndividualDummyAccount = profileDetail?.user?.status === 5;
  const isRegisteredAsInstiAccountOnly =
    !!profileDetail?.preference?.is_registered_as_institutional_account_only;
  const isInstitutionAccountOnly =
    isIndividualDummyAccount && isRegisteredAsInstiAccountOnly;

  const switchAccountSupportVersion = useCallback(
    (institutionId: string | null) => {
      if (institutionId === '0') {
        // switch to individual
        removeCurrentInstitution();
        return;
      }

      if (isNumber(institutionId) && Number(institutionId) > 0) {
        // switch to institution
        setCurrentInstitution(Number(institutionId));
      }
    },
    []
  );

  /**
   * Switch account to Individual or Institution
   * Only in Native
   */
  const switchAccount = useCallback(() => {
    const isWebview = isInsideNativeContainer();

    const url = location.pathname;
    if (url.startsWith('/linkto')) return;

    if (!isWebview) return; // do nothing

    const query = new URLSearchParams(location.search);

    const institution = getCurrentInstitution();

    const userInIstitution = !!institution?.institution_id;

    const institutionId = query.get('institution_id');

    // when institution id = 0, we need to check user profile data to get status user is insti only or not
    //
    // Do nothing when
    // - institutionId not exist
    // - or institutionId is 0 but data profile not exist or user is institution only
    if (
      !institutionId ||
      (institutionId === '0' && (!profileDetail || isInstitutionAccountOnly))
    ) {
      return; // do nothing
    }

    if (nativeSupportInstitution()) {
      return switchAccountSupportVersion(institutionId);
    }

    // When user not Update yet ==> see in bellow

    // If user in institution and not carry institution_id
    if (userInIstitution && institutionId === '0') {
      // switch to individual
      removeCurrentInstitution();
      return;
    }

    // If user in institution and carry institution_id
    if (
      !userInIstitution &&
      isNumber(institutionId) &&
      Number(institutionId) > 0
    ) {
      // switch to institution
      setCurrentInstitution(Number(institutionId));
      return;
    }
  }, [
    isInstitutionAccountOnly,
    location.pathname,
    location.search,
    profileDetail,
    switchAccountSupportVersion,
  ]);

  // Auth Swtich Account
  useEffect(() => {
    switchAccount();
  }, [switchAccount]);

  useAutoSwitchInstitutionAccOnly({ isLogin });

  /**
   * Handling redirect to `/404` page in certain page when user in `institution account`
   */
  const institutionMiddleware = useCallback(() => {
    const institution = getCurrentInstitution();
    const userInInstitution = !!institution?.institution_id;
    if (!userInInstitution) return;

    institutionBlackListRoute.forEach((path) => {
      if (location.pathname.startsWith(path)) {
        history.replace('/institution/404');
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    institutionMiddleware();
  }, [institutionMiddleware]);

  // Logout mutation
  const { mutateAsync: logoutAsyncMutation, isLoading: isLoggingOut } =
    useMutation(postLogout);

  const logout = (
    redirect: LogoutParams['redirect'] = '/',
    data: LogoutParams['data'] = undefined
  ) => {
    data?.onBeforeLogout?.();

    logoutAsyncMutation()
      .then(async () => {
        if (DevTools) {
          // Clear the IndexedDB database to avoid data errors when QA or developers change accounts for testing
          await indexedDB.clear();
        }

        // remove jago account data
        Storage.removeObject('jagoAccount');

        // remove hiddenCompleteYourProfile data
        Storage.removeObject('hiddenCompleteYourProfile');

        // Set Flag Manually Log out
        Storage.setObject('hasManuallyLogout', true);

        // remove kyc data
        Storage.removeObject('kyc');

        // remove registration active step
        Storage.removeObject('registration-step-active');

        // remove profilings data
        //TODO: can probably improve to store profiling step and data in BE, -
        //TODO: so it doesn't pollute local storage
        Storage.removeObject('form_profiling');
        Storage.removeObject('form_profiling_step');

        Storage.removeObject('stockbitMaintenance');

        Storage.removeObject('has_seen_new_instant_redemption');
        Storage.removeObject('has_seen_best_reksadana_page');

        Storage.removeObject('sso_register_data');
        Storage.removeObject('sso_login_data');

        Storage.removeObject('userDeniedBiometric');

        // remove institution notif stats storage
        removeInstitutionProfileNotification();

        clevertapLogoutUser();

        // reset status bar into default color
        setAppStatusBarColor({
          backgroundColor: DEFAULT_STATUS_BAR_COLOR().backgroundColor,
          foregroundColor: DEFAULT_STATUS_BAR_COLOR().foregroundColor,
        });

        await clearLocalSessionAsync(redirect);

        return data?.onSuccessLogout?.();
      })
      .catch((error) => {
        dispatch(openToastTop('Logout aplikasi gagal', 'red'));
        Sentry.withScope((scope) => {
          scope.setTag('domain', 'logoutUser');
          Sentry.captureException(error);
        });

        return data?.onErrorLogout?.();
      });
  };
  const [accessToken, setAccessToken] = useState('');
  const [loginPromiseHasSettled, setLoginPromiseHasSettled] = useState(false);

  // checking token on local storage for set status user is login or not
  useEffect(() => {
    initialHeaderHttp()
      .then((token) => {
        if (!token) {
          setIsLogin(false);
          return;
        }
        setAccessToken(token);
        setIsLogin(true);
      })
      .catch((err) => {
        // just log to sentry
        generalLogError('Failed to initiate Header HTTP', {
          error_instance: err,
        });
      })
      .finally(() => {
        setLoginPromiseHasSettled(true);
        setTokenIsReady(true);
      });
  }, []);

  const AuthProviderValue = {
    isLogin,
    isLoggingOut,
    userLoggedInStatus,
    logout,
    setIsLogin,
    setUserLoggedInStatus,
    accessToken,
    loginPromiseHasSettled,
  };

  return (
    <AuthContext.Provider value={AuthProviderValue}>
      {tokenIsReady ? children : <SplashScreen />}
      <AutoLogout />
      <IndexErrorModal />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
