import { mappedColors } from 'core/theme/mapped-color';
import { StatusBarForegroundColor, StatusBarApp } from '../types';
import { getTheme } from 'utils/theme';

/** Key status bar color on local storage */
export const STATUS_BAR_COLOR_KEY = 'statusBarColorKey';

/** Key status bar color tmp on local storage */
export const STATUS_BAR_COLOR_TEMP_KEY = 'tempStatusBarColorKey';

/** Status Bar Foreground Colors */
export const DARK_FOREGROUND_COLOR: StatusBarForegroundColor = 'dark';
export const LIGHT_FOREGROUND_COLOR: StatusBarForegroundColor = 'light';

/** Status Bar Color Constants */
export const DEFAULT_STATUS_BAR_COLOR = (): StatusBarApp => {
  const theme = getTheme();

  const color = mappedColors[theme];
  return {
    backgroundColor: color.surface,
    foregroundColor: theme === 'dark' ? 'light' : 'dark',
  };
};

/**
 * it can be used in Top and Bottom bar with surface color only
 */
export const COLOR_BAR_ALL_SURFACE = (theme?: 'light' | 'dark') => {
  if (!theme) {
    theme = getTheme();
  }

  const color = mappedColors[theme];

  return {
    bgColor: color.surface,
    foregroundColor: theme === 'dark' ? 'light' : ('dark' as 'dark' | 'light'),
  };
};

/**
 * it can be used in Top and Bottom bar
 */
export const COLOR_BAR_DEFAULT = (theme?: 'light' | 'dark') => {
  if (!theme) {
    theme = getTheme();
  }
  const color = mappedColors[theme];

  return {
    top: {
      bgColor: color.surface,
      foregroundColor:
        theme === 'dark' ? 'light' : ('dark' as 'dark' | 'light'),
    },
    bottom: {
      bgColor: color.modal.surface,
      foregroundColor:
        theme === 'dark' ? 'light' : ('dark' as 'dark' | 'light'),
    },
  };
};

export const PREMIUM_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#124a35',
  foregroundColor: LIGHT_FOREGROUND_COLOR,
};
export const PREMIUM_STATUS_BAR_COLOR_OLD: StatusBarApp = {
  backgroundColor: '#32544d',
  foregroundColor: LIGHT_FOREGROUND_COLOR,
};

export const PORTFOLIO_BOND_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#DCEAF4',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const PORTFOLIO_NEW_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#E1F4ED',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const PORTFOLIO_MIX_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#E1F0F9',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const PORTFOLIO_PAGE_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#E1F4ED',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const JAGO_COREGIST_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#E8F7F1',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const PORTFOLIO_CASH_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#F4EDE7',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const PORTFOLIO_EQUITY_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#ECDFF7',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const PROFILING_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#E6F7F0',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const JAGO_LINKING_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#FEEABE',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const SBN_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#FEF8F7',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const SBN_INVESTMENT_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#F2F1FE',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const RDN_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#EFF7FC',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const REFERRAL_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#E1F4ED',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const ELLIGILE_TO_ONBOARD_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#F2FBF8',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const CATEGORIES_INSTANT_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#FFF4DA',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const USER_TUTORIAL_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#EBF8F3',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const SBN_SEKUNDER_PRODUCT_DETAIL_NEW_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#55a5dc',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const SBN_PRODUCT_DETAIL_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#FBF0F0',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const RDN_MONEYMARKET_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#EBF8F3',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const RDN_BOND_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#EDF4F9',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const RDN_STOCK_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#F4ECFA',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const COMMUNITY_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#E4F6EF',
  foregroundColor: DARK_FOREGROUND_COLOR,
};

export const RDN_BANK_PICKER_STATUS_BAR_COLOR: StatusBarApp = {
  backgroundColor: '#ebf8f3',
  foregroundColor: DARK_FOREGROUND_COLOR,
};
