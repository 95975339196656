import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getNotificationData } from 'services/notifications';
import { isInsideNativeContainer } from 'utils/validator';
import { useModalUpdateReminder } from 'features/common/modals/UpdateReminder/provider/ModalUpdateReminderProvider';
import useAuth from 'hooks/useAuth';

export const InAppNotificationsContext = React.createContext<any>(null);

const InAppNotificationsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { visible, isDoneCollectData } = useModalUpdateReminder();

  // Get auth context from hook
  const { isLogin: isLoggedIn } = useAuth();

  const [notificationsData, setNotif] = useState<any>({
    // data key from API response,
    data: null,
    // notifications data from API response
    notifications: null,
  });

  /**
   * check for update.
   * - waiting for collecting data
   * - if there's update available, then ModalUpdateReminder is visible
   */
  const hasAppUpdate = isDoneCollectData && visible;

  /**
   * if in native, check for update first then check if user logged in
   * if outside native, just check if user logged in
   */
  const isEnableFetching = isInsideNativeContainer()
    ? !hasAppUpdate && isLoggedIn
    : isLoggedIn;

  const { isLoading, error, data, isFetching, refetch } = useQuery(
    'notificationsProviderData',
    () => getNotificationData(),
    {
      enabled: isEnableFetching,
    }
  );

  useEffect(() => {
    if (!isLoading && !error && !isFetching) {
      setNotif({
        data: data,
        notifications: data?.data.data.notifications,
      });
    }
  }, [data, error, isFetching, isLoading]);

  const valueToPass = {
    data: notificationsData.data,
    notifications: notificationsData.notifications,
    error: error,
    isFetching: isFetching,
    isLoading: isLoading,
    refetch: refetch,
  };

  return (
    <InAppNotificationsContext.Provider value={valueToPass}>
      {children}
    </InAppNotificationsContext.Provider>
  );
};

export default InAppNotificationsProvider;
