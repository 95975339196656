import React, { useEffect } from 'react';
import styles from './TopCompanyInfoModal.module.css';
import { Text, Modal } from '@bibitid/uikit-v1';
import TopCompanyIcon from './assets/icon.svg';
import Analytics from 'utils/Analytics';

interface TopCompanyInfoModalProps {
  visible: boolean;
  onClose: () => void;
}

// Modal for showing Top Company info
const TopCompanyInfoModal: React.FC<TopCompanyInfoModalProps> = ({
  visible = false,
  onClose,
}) => {
  useEffect(() => {
    if (visible) {
      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'search',
          view: 'top_mf_modal',
        },
      });
    }
  }, [visible]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      className={styles['info-modal']}
    >
      <Modal.Header
        icon={<img src={TopCompanyIcon} alt='top-icon' />}
        showBackButton={false}
        showClose={true}
      />
      <Modal.Body>
        <div className={styles['info-modal-content']}>
          <Text htmlTag='h2' type='heading16b'>
            Top Reksa Dana
          </Text>
          <Text>
            Pilihan reksa dana berkualitas yang dikurasi per 3 bulan oleh Bibit
            berdasarkan AUM, return, drawdown, expense ratio, serta reputasi
            Manajer Investasi.
          </Text>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TopCompanyInfoModal;
