import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import getSafely from 'utils/safely';
import {
  fetchProductById,
  getProductProspectus,
  getProductFFS,
} from 'services/product';
import { Dispatch } from 'redux';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import { ReduxRootState } from 'store/root';
import { addInvestmentManagerEntry } from './investmentManager.reducer';
import { addCustodianEntry } from './custodian.reducer';

/**
 * Static typings for ReksaDana data
 */
export interface BankLabel {
  id: number;
  name: string;
  color: string;
}

type ReksaDanaExecutiveName = string;
export interface ReksaDanaExecutiveData {
  [title: string]: ReksaDanaExecutiveName[];
}

export type ReksaDanaExecutive = ReksaDanaExecutiveData | {};

export interface ReksaDanaFeeData {
  value: number;
  type: string;
  currency?: string;
  max_value?: number;
}

export interface ReksaDanaFeeDetail {
  subscription: ReksaDanaFeeData;
  redemption: ReksaDanaFeeData;
  transfer?: ReksaDanaFeeData;
}
interface ReksaDanaFFSData {
  date: string;
  file: string;
  name: string;
}
interface ReksaDanaProspectusData {
  file: string;
  name: string;
}
type ReksaDanaFFSList = ReksaDanaFFSData[];
type ReksaDanaProspectusList = ReksaDanaProspectusData[];
export interface ReksaDanaFFSProspectusList {
  factsheets?: ReksaDanaFFSList;
  prospectus?: ReksaDanaProspectusList;
}

interface ReksaDanaAssetAllocationData {
  name: string;
  percentage: number;
}
export type ReksaDanaAssetAllocationList = ReksaDanaAssetAllocationData[];

export interface ReksaDanaHoldingData {
  symbol: undefined | string;
  name: string;
  annualDividend: 0;
  date: string;
  product_type?: string;
  company_id?: number;
  clickable?: number;
  serie_id?: string;
}
export type ReksaDanaHoldingList = ReksaDanaHoldingData[];

interface ReksaDanaBankAccountData {
  bank: string;
  id: number;
  account: string;
  name: string;
}
type ReksaDanaBankAccountList = ReksaDanaBankAccountData[];

export interface ReksaDanaExitLoadData {
  operator: string;
  period: string;
  period_type: 'M' | 'Y';
  period_value: number;
  period_2_type: 'M' | 'Y';
  period_2_value: number;
  value: number;
  value_type: string;
}
export type ReksaDanaExitLoadList = ReksaDanaExitLoadData[];

export interface ReksaDanaCurrencyData {
  /** The currency mutual fund use */
  currency: string;
  /** Currency exchange rate, if converted to IDR */
  exchange_rate: number;
}

export interface ReksaDanaInvestmentManagerData {
  name: string;
  ojkCode?: string;
  ojk_code?: string;
  telepon: string;
  fax: string;
  email: string;
  url: string;
}

export interface ReksaDanaCustodianBankData {
  name: string;
  ojkCode?: string;
}

export interface ReksaDanaAUMData {
  value: number;
  date: string;
}

interface ReksaDanaNAVData {
  date: '2020-07-03';
  value: 1489.43;
  min: 1390.296;
  max: 1489.43;
}

interface ReksaDanaShareholderData {}
type ReksaDanaShareholderList = ReksaDanaShareholderData[];

export interface ReksaDanaExpenseRatioData {
  percentage: number;
}

export interface ReksaDanaAverageYieldData extends ReksaDanaExpenseRatioData {
  date?: string;
}

export interface ReksaDanaMaxDrawdownData {
  '1y': number;
  '3y': number;
  '5y': number;
  '10y': number;
  all: number;
}

export type ReksaDanaCAGRData = ReksaDanaMaxDrawdownData;

export interface ReksaDanaSimpleReturnData {
  '1d': number;
  '1m': number;
  '3m': number;
  ytd: number;
  '1y': number;
  '3y': number;
  '5y': number;
  '10y': number;
  all: number;
}

export type ReksaDanaNAVChangesData = ReksaDanaSimpleReturnData;

export type ReksaDanaAUMChangesData = ReksaDanaSimpleReturnData;

export interface ReksaDana {
  id: number;
  symbol: string;
  name: string;
  type: string;
  profile: string;
  tradeable: number | boolean;
  notbuyable: number | boolean;
  robocategory: string;
  currency_exchange: ReksaDanaCurrencyData;
  bank_redeem: BankLabel;
  minbuy: number;
  max_buy: number;
  minsell: number;
  executive: ReksaDanaExecutive;
  fee?: ReksaDanaFeeDetail;
  files?: ReksaDanaFFSProspectusList;
  asset?: ReksaDanaAssetAllocationList;
  holding: ReksaDanaHoldingList;
  bank: ReksaDanaBankAccountList;
  exitload: ReksaDanaExitLoadList;
  investment_manager?: ReksaDanaInvestmentManagerData;
  custodian_bank?: ReksaDanaCustodianBankData;
  currency: string;
  sharia: boolean;
  etf: boolean;
  index: boolean;
  aum: ReksaDanaAUMData;
  nav: ReksaDanaNAVData;
  shareholders: ReksaDanaShareholderList;
  riskprofile: string;
  expenseratio: ReksaDanaExpenseRatioData;
  avg_yield: ReksaDanaAverageYieldData;
  maxdrawdown: ReksaDanaMaxDrawdownData;
  maxdrawdown_adjusted: ReksaDanaMaxDrawdownData;
  cagr: ReksaDanaCAGRData;
  simplereturn: ReksaDanaSimpleReturnData;
  simplereturn_adjusted?: ReksaDanaSimpleReturnData;
  changesvalue: ReksaDanaNAVChangesData;
  changesvalue_adjusted?: ReksaDanaNAVChangesData;
  changesvalueaum: ReksaDanaAUMChangesData;
  index_type?: string;
  is_instant_redemption?: boolean;
  is_top_product?: number;
  is_has_dividend?: boolean;
}

// Initial State
export interface ReksaDanaEntitiesState {
  byId: {
    [symbol: string]: ReksaDana;
  };
  allIds: string[];
}

interface ReksaDanaProspectusUpdatePayload {
  symbol: string;
  prospectus: ReksaDanaProspectusList;
}

interface ReksaDanaFFSUpdatePayload {
  symbol: string;
  factsheets: ReksaDanaFFSList;
}

const defaultState: ReksaDanaEntitiesState = {
  byId: {},
  allIds: [],
};

// Actions
const addReksadanaEntry = createAction<ReksaDana>(
  'REKSADANA/ADD_REKSADANA_ENTRY'
);
const addMultipleReksadanaEntry = createAction<ReksaDana[]>(
  'REKSADANA/ADD_MULTIPLE_REKSADANA_ENTRY'
);
const addReksadanaProspectus = createAction<ReksaDanaProspectusUpdatePayload>(
  'REKSADANA/ADD_REKSADANA_PROSPECTUS'
);
const addReksadanaFFS = createAction<ReksaDanaFFSUpdatePayload>(
  'REKSADANA/ADD_REKSADANA_FFS'
);

// Reducer
const reksadana = createReducer<typeof defaultState>({}, defaultState);

// Cache mutual fund data to redux
reksadana.on(addReksadanaEntry, (state, payload: ReksaDana) => {
  const { byId } = state;
  const target = byId[payload.symbol];
  let toBody = {};
  if (target) {
    toBody = update(target, { $merge: payload });
  } else {
    toBody = payload;
  }
  return update(state, {
    byId: {
      $set: {
        ...state.byId,
        [payload.symbol]: {
          ...toBody,
        },
      },
    },
  });
});

// Adding multiple mutual fund data
reksadana.on(addMultipleReksadanaEntry, (state, payload: ReksaDana[]) => {
  const reksadanaEntries = payload
    .filter((item) => !!item)
    .reduce(
      (data: ReksaDanaEntitiesState['byId'] | {}, currValue: ReksaDana) => {
        let toBody = {};

        const { symbol = '' } = currValue || {};

        const target = state.byId[symbol];

        if (target) {
          toBody = update(target, { $merge: currValue });
        } else {
          toBody = currValue;
        }

        return {
          ...data,
          [symbol]: { ...toBody },
        };
      },
      {}
    );
  return update(state, {
    byId: {
      $set: {
        ...state.byId,
        ...reksadanaEntries,
      },
    },
  });
});

// Adding prospectus data to selected mutual fund
reksadana.on(
  addReksadanaProspectus,
  (state, payload: ReksaDanaProspectusUpdatePayload) => {
    return update(state, {
      byId: (byId) =>
        update(byId || {}, {
          [payload.symbol]: (data) =>
            update(data || {}, {
              files: (files) =>
                update(files || {}, {
                  prospectus: (prospectus) =>
                    update(prospectus || [], { $set: payload.prospectus }),
                }),
            }),
        }),
    });
  }
);

// Adding fund fact sheet data to selected mutual fund
reksadana.on(addReksadanaFFS, (state, payload: ReksaDanaFFSUpdatePayload) => {
  return update(state, {
    byId: (byId) =>
      update(byId || {}, {
        [payload.symbol]: (data) =>
          update(data || {}, {
            files: (files) =>
              update(files || {}, {
                factsheets: (factsheets) =>
                  update(factsheets || [], { $set: payload.factsheets }),
              }),
          }),
      }),
  });
});

export {
  addReksadanaEntry,
  addMultipleReksadanaEntry,
  addReksadanaProspectus,
  addReksadanaFFS,
};

/**
 * Get mutual fund data from cached redux state by giving symbol ID
 */
export const getReksadanaBySymbol = (state: ReduxRootState, symbol: string) => {
  return state.entities.reksadana.byId[symbol];
};

/**
 * Add new reksadana entry if not exist
 * @param {string} fundId Mutual fund symbol id. ex: RD123
 */
export function getReksadanaEntry(fundId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await fetchProductById(fundId);
      let productData: ReksaDana | null | undefined = getSafely(
        ['data', 'data'],
        response
      );

      if (productData) {
        dispatch(addReksadanaEntry(productData));
      }

      if (productData && productData.investment_manager) {
        dispatch(addInvestmentManagerEntry(productData.investment_manager));
      }
      if (productData && productData.custodian_bank) {
        dispatch(addCustodianEntry(productData.custodian_bank));
      }

      return;
    } catch (err) {
      const error: any = err;
      dispatch(openErrorModal(getSafely(['message'], error)));
      return;
    }
  };
}

/**
 * Get mutual fund prospectus and fund fact sheet data
 * @param {string} fundId Mutual fund symbol id. ex: RD123
 */
export function getReksadanaProspectusFFS(fundId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const responses = await Promise.all([
        getProductProspectus(fundId),
        getProductFFS(fundId),
      ]);

      const [prospectusResponse, ffsResponse] = responses;
      const prospectus = getSafely(['data', 'data'], prospectusResponse);
      const ffs = getSafely(['data', 'data'], ffsResponse);

      dispatch(
        addReksadanaProspectus({
          symbol: fundId,
          prospectus,
        })
      );
      dispatch(
        addReksadanaFFS({
          symbol: fundId,
          factsheets: ffs,
        })
      );

      return;
    } catch (err) {
      const error: any = err;
      dispatch(openErrorModal(getSafely(['message'], error)));
      return;
    }
  };
}

export default reksadana;
