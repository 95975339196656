import React from 'react';
import PaddingHorizontal from 'features/common/PaddingHorizontal';
import { Text } from '@bibitid/uikit-v1';
import IconBenchmark from 'assets/images/explore/ProductCategoryIcons/IconBenchmark/IconBenchmark';
import styles from './BibitPlusCategoryBanner.module.css';
import IconPasarUang from 'assets/images/explore/ProductCategoryIcons/IconPasarUang/IconPasarUang';
import IconObligasi from 'assets/images/explore/ProductCategoryIcons/IconObligasi/IconObligasi';
import IconReksadanaSaham from 'assets/images/explore/ProductCategoryIcons/IconReksadanaSaham/IconReksadanaSaham';
import IconSyariah from 'assets/images/explore/ProductCategoryIcons/IconSyariah/IconSyariah';
import IconLainnya from 'assets/images/explore/ProductCategoryIcons/IconLainnya/IconLainnya';
import IconIndexFund from 'assets/images/explore/ProductCategoryIcons/IconIndexFund/IconIndexFund';
import IconUSD from 'assets/images/explore/ProductCategoryIcons/IconUSD/IconUSD';
import IconMinimum from 'assets/images/explore/ProductCategoryIcons/IconMinimum/IconMinimum';
import IconInstant from 'assets/images/explore/ProductCategoryIcons/IconInstant/IconInstant';
import IconWrapper from 'utils/IconWrapper/IconWrapper';
import IconBestReksadana from 'features/explore/pages/ExploreBestPage/assets/IconBestSquare';

export interface CategoryBannerProps {
  category:
    | 'equity'
    | 'debt'
    | 'syariah'
    | 'moneymarket'
    | 'minimum'
    | 'indexfund'
    | 'im'
    | 'benchmark'
    | 'usd'
    | 'others'
    | 'instant'
    | 'best';
}

const banners = {
  equity: {
    image: <IconReksadanaSaham />,
    title: 'Saham',
    description:
      'Return tertinggi untuk yang berani ambil risiko investasi jangka panjang.',
    backgroundColor: {
      default: 'var(--violet-low)',
      inModal: 'var(--modal-violet-low)',
    },
  },
  debt: {
    image: <IconObligasi />,
    title: 'Obligasi',
    description: 'Return yang stabil dengan risiko yang moderat.',
    backgroundColor: {
      default: 'var(--cobalt-low)',
      inModal: 'var(--modal-cobalt-low)',
    },
  },
  syariah: {
    image: <IconSyariah />,
    title: 'Syariah',
    description:
      'Reksa dana terbaik untuk yang ingin mengikuti prinsip syariah Islam.',
    backgroundColor: {
      default: 'var(--olive-low)',
      inModal: 'var(--modal-olive-low)',
    },
  },
  moneymarket: {
    image: <IconPasarUang />,
    title: 'Pasar Uang',
    description: 'Risiko rendah dengan return yang lebih tinggi dari deposito.',
    backgroundColor: {
      default: 'var(--green-low)',
      inModal: 'var(--modal-green-low)',
    },
  },
  minimum: {
    image: <IconMinimum />,
    title: 'Minimal 10rb',
    description: 'Investasi dengan modal minimum Rp10,000.',
    backgroundColor: {
      default: 'var(--magenta-low)',
      inModal: 'var(--modal-magenta-low)',
    },
  },
  indexfund: {
    image: <IconIndexFund />,
    title: 'Index Fund',
    description: 'Reksa dana yang portofolionya mengacu pada indeks tertentu.',
    backgroundColor: {
      default: 'var(--brown-low)',
      inModal: 'var(--modal-brown-low)',
    },
  },
  im: null,
  benchmark: {
    image: <IconBenchmark />,
    title: 'Benchmark',
    description:
      'Bandingkan performa reksadana dengan performa instrumen investasi lainnya',
    backgroundColor: {
      default: 'var(--peach-low)',
      inModal: 'var(--modal-peach-low)',
    },
  },
  usd: {
    image: <IconUSD />,
    title: 'Reksa Dana USD',
    description: 'Transaksi reksa dana dengan mata uang US Dollar.',
    backgroundColor: {
      default: 'var(--navy-low)',
      inModal: 'var(--modal-navy-low)',
    },
  },
  others: {
    image: <IconLainnya />,
    title: 'Lainnya',
    description:
      'Reksa dana campuran dari beberapa efek sekaligus untuk investor yang mencari alternatif.',
    backgroundColor: {
      default: 'var(--blue-low)',
      inModal: 'var(--modal-blue-low)',
    },
  },
  instant: {
    image: <IconInstant />,
    title: 'Pencairan Instan',
    description:
      'Tidak butuh waktu lama, pencairan dana sekilat transfer uang.',
    backgroundColor: {
      default: 'var(--yellow-low)',
      inModal: 'var(--modal-yellow-low)',
    },
  },
  best: {
    image: <IconBestReksadana />,
    title: 'Top Reksa Dana',
    description: 'Pilihan reksa dana berkualitas yang dikurasi oleh Bibit.',
    backgroundColor: {
      default: 'var(--gold-medium)',
      inModal: 'var(--modal-gold-low)',
    },
  },
};

const BibitPlusCategoryBanner: React.FC<
  React.PropsWithChildren<CategoryBannerProps>
> = ({ category }) => {
  if (category === 'im') {
    return null;
  }
  return (
    <PaddingHorizontal>
      <div className={styles['wrapper']}>
        <IconWrapper
          className={styles['icon']}
          $size='72px'
          $borderRadius='4px'
          backgroundColor={banners[category]?.backgroundColor}
        >
          {banners[category]?.image}
        </IconWrapper>
        <div className={styles['category-details']}>
          <Text type='body2'>{banners[category]?.title}</Text>
          <Text type='caption1' htmlTag='p'>
            {banners[category]?.description}
          </Text>
        </div>
      </div>
    </PaddingHorizontal>
  );
};

export default BibitPlusCategoryBanner;
