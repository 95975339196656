import React from 'react';
import { Modal } from '@bibitid/uikit-v1';
import BitCompleteImg from 'assets/images/modal-asset/bit-complete-profile.svg';
import { useCompleteYourProfileModal } from './provider/CompleteYourProfileModalProvider';
import { useLocation } from 'react-router-dom';
import CreatePinInfoModal from './components/modals/CreatePinInfoModal';

const CompleteYourProfileModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const location = useLocation();

  const { showCompleteProfileModal, toggleCompleteProfileModal } =
    useCompleteYourProfileModal();

  /**
   * When last step registration is setup pin, we will show `CreatePinInfoModal`
   */
  if (location.pathname === '/setup-pin') {
    return (
      <CreatePinInfoModal
        visibility={showCompleteProfileModal}
        toggleModal={toggleCompleteProfileModal}
      />
    );
  }

  return (
    <Modal
      className='complete-your-profile'
      visible={showCompleteProfileModal}
      onClose={toggleCompleteProfileModal}
    >
      <Modal.Header showClose={false} showBackButton={false} />
      <Modal.Body>
        <div className='bit-text-center'>
          <img src={BitCompleteImg} alt='bit complete' />
          <br />
          <h3>Yuk Lengkapi Profil Kamu!</h3>
          <p>
            Segera lengkapi profil agar dapat berinvestasi dengan cepat dan
            mudah.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton='Oke, Lanjutkan'
        onPrimaryClick={toggleCompleteProfileModal}
      />
    </Modal>
  );
};

export default CompleteYourProfileModal;
