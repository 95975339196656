import { ThemeTypes } from 'core/theme/ThemeProvider';

/**
 * Adding suffix `-dark` when user dark mode is active in filename image
 * ex: https://assets.bibit.id/images/payment/rdn-wallet.svg --> https://assets.bibit.id/images/payment/rdn-wallet-dark.svg
 *
 */
export function linkModify(link: string, currentTheme: ThemeTypes) {
  switch (currentTheme) {
    case 'light':
      return link;
    case 'dark':
      // assuming `link` would be `https://assets.bibit.id/images/payment/rdn-wallet.svg`
      const sp = link.split('/');
      const fileName = sp[sp.length - 1]; // it could be `rdn-wallet.svg`

      const spFileName = fileName.split('.');
      spFileName[0] = `${spFileName[0]}-dark`;
      const newFileName = spFileName.join('.');
      sp[sp.length - 1] = newFileName;
      return sp.join('/');
    default:
      return link;
  }
}
