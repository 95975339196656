import { propertyToUrl } from 'query-string-params';
import list from './index';
import getSafely from 'utils/safely';
import { handleResponseGeneral } from 'utils/http-response';
import Analytics from 'utils/Analytics';
import http from 'core/http';
import { getCurrentInstitution } from 'features/institution/utils';

const removeValueFromObjectIfEmpty = (obj) => {
  return Object.keys(obj).reduce((acc, curr) => {
    if (obj[curr] !== null && obj[curr] !== undefined)
      // number 0 and negative should be valid, just want to check if not null and if not undefined
      acc[curr] = obj[curr];
    return acc;
  }, {});
};

const fetchAvailableSwitchProduct = ({
  symbol,
  categoryId,
  isRobo,
  isShariaUser,
}) => {
  const filteredKeys = removeValueFromObjectIfEmpty({
    symbol,
    category_id: categoryId,
    is_robo: isRobo,
    is_sharia: isShariaUser,
  });
  const params = propertyToUrl(filteredKeys);
  return http
    .get(`${list.switchProductAvailable}?${params}`)
    .then((resp) => handleResponseGeneral(resp));
};

const postSwitchProduct = (
  { fromSymbol, targetSymbol, unit, amount, categoryId, isRobo },
  securekey
) => {
  return http
    .post(
      `${list.switchProduct}?v=2`,
      {
        from: fromSymbol,
        to: targetSymbol,
        unit,
        amount,
        category_id: categoryId,
        is_robo: isRobo,
      },
      {},
      {
        headers: {
          'x-secure-key': securekey,
        },
      }
    )
    .then((resp) => handleResponseGeneral(resp));
};

/**
 * Delete attribute key from object if attribute value is nullish
 */

export const normalizeProductQueryParams = (param) => {
  const temp = {};
  Object.entries(param).forEach(([key, value]) => {
    if (String(value)) temp[key] = value;
  });
  return temp;
};

/**
 * GET filter product by param
 * @param {object} param
 * @returns {Promise<import('axios').AxiosResponse<import('services').BibitApiSuccessResponse<import('entities/reksadana.reducer').ReksaDana[]>>>}
 */
const filterProduct = async (
  param,
  cancelToken = undefined,
  overrideInstitution = false
) => {
  const currentInstitution = getCurrentInstitution();

  if (!!currentInstitution?.institution_id && !overrideInstitution) {
    param.currency = 'idr';
    // remove syariah key because we need to show all if in bibit-institution
    const { syariah, ...resParam } = param;
    param = resParam;
  }

  return http
    .get(list.productsFilter, normalizeProductQueryParams(param), {
      cancelToken,
    })
    .then(async (res) => {
      let rawData = getSafely(['data', 'data'], res);

      // Use crypto-js decryption (prod)
      // note that we're using dynamic import to optimize bundle size
      if (typeof rawData === 'string') {
        let { decryptAES } = await import('utils/crypto');
        rawData = decryptAES(rawData);
      }

      /**
       * Send appsflyer data of search keywords and search result ids
       */
      if (param.name) {
        const companyIds = rawData.map((company) => company.id);
        Analytics.logEventThirdParty({
          eventName: 'af_search',
          data: {
            af_search_string: param.name,
            af_content_list: companyIds,
          },
        });
      }

      return {
        ...res, //retrieve all axios properties
        data: {
          // replace "data" with modify data
          message: getSafely(['data', 'message'], res),
          data: rawData,
        },
      };
    });
};

/**
 * Get a mutual fund product NAVchart data
 * @param {string} symbol Mutual fund ID
 * @param {Object} options Payload for chart data request
 * @param {string} options.period Chart period
 */
export function getProductNAVChartData(symbol, options) {
  return http.get(list.productsChart(symbol), options).then(async (res) => {
    let rawData = getSafely(['data', 'data'], res);

    // Use crypto-js decryption (prod)
    if (typeof rawData === 'string') {
      let { decryptAES } = await import('utils/crypto');
      rawData = decryptAES(rawData);
    }

    return {
      ...res,
      data: {
        message: getSafely(['data', 'message'], res),
        data: rawData,
      },
    };
  });
}

/**
 * Get a mutual fund product AUM chart data
 * @param {string} symbol Mutual fund ID
 * @param {Object} options Payload for chart data request
 * @param {string} options.period Chart period
 */
export function getProductAUMChartData(symbol, options) {
  return http.get(list.productsChartAum(symbol), options).then(async (res) => {
    let rawData = getSafely(['data', 'data'], res);

    // Use crypto-js decryption (prod)
    if (typeof rawData === 'string') {
      let { decryptAES } = await import('utils/crypto');
      rawData = decryptAES(rawData);
    }

    return {
      ...res,
      data: {
        message: getSafely(['data', 'message'], res),
        data: rawData,
      },
    };
  });
}

/**
 * GET detail product by ID
 * @param {string} idSymbol
 */
const fetchProductById = (idSymbol) => {
  return http.get(list.products(idSymbol)).then(async (res) => {
    let rawData = getSafely(['data', 'data'], res);

    // Use crypto-js decryption (prod)
    if (typeof rawData === 'string') {
      let { decryptAES } = await import('utils/crypto');
      rawData = decryptAES(rawData);
    }

    return {
      ...res, //retrieve all axios properties
      data: {
        // replace "data" with modify data
        message: getSafely(['data', 'message'], res),
        data: rawData,
      },
    };
  });
};

/**
 * GET: Get produc ffs by reksadaid
 * @param {string} reksadanaId
 */
const getProductFFS = (reksadanaId) => {
  return http.get(list.productsFFS(reksadanaId));
};

/**
 * GET: product prospectus
 * @param {string} reksadanaId
 */
const getProductProspectus = (reksadanaId) => {
  return http.get(list.productsProspectus(reksadanaId));
};

/**
 * GET: product prospectus
 * @param {string} reksadanaId
 */
const getProductStats = (reksadanaId) => {
  return http.get(list.productsStats(reksadanaId));
};

/**
 * POST: Watchlist / Star / Favorite a product
 * @param {string} reksadanaId
 * @param {number 0 | 1}  isFavorited
 * 0 -> Remove the product from watchlist
 * 1 -> Add the product to watchlist
 */
const postWatchlistProduct = (reksadanaId, isFavorited) => {
  return http.post(list.productsWatchlist(reksadanaId), {
    is_favorited: isFavorited,
  });
};

export {
  fetchAvailableSwitchProduct,
  postSwitchProduct,
  filterProduct,
  fetchProductById,
  getProductFFS,
  getProductProspectus,
  postWatchlistProduct,
  getProductStats,
};
