import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getCurrentInstitution,
  setCurrentInstitution,
} from 'features/institution/utils';
import { useProfileData } from 'hooks';

interface Params {
  isLogin: boolean;
}

/**
 * Side effect hook for
 * - auto switch account into institution env for user institution without individual account
 */
const useAutoSwitchInstitutionAccOnly = ({ isLogin }: Params) => {
  const location = useLocation();
  const url = location.pathname;

  // institution data
  const institution = getCurrentInstitution();
  const userInInstitution = !!institution?.institution_id;

  // profile data
  const { data: profileData } = useProfileData(isLogin);
  const profileDetail = profileData?.data?.data;
  const profileInstitutionId = profileDetail?.institutions?.[0]?.id;

  // get `institution account only` data
  const isIndividualDummyAccount = profileDetail?.user?.status === 5;
  const isRegisteredAsInstiAccountOnly =
    !!profileDetail?.preference?.is_registered_as_institutional_account_only;
  const isInstitutionAccountOnly =
    isIndividualDummyAccount && isRegisteredAsInstiAccountOnly;

  const inIgnoreUrlSwitchAccount =
    url.startsWith('/linkto') || url.startsWith('/institution/open/');

  /**
   * Switch account into Institution for user institution without individual account
   * - user in individual env
   * - and user only have institution account without individual account
   * - and user have institution id
   * - and location not included on IGNORE_URL
   */
  const switchAccountForInstitutionAccountOnly = useCallback(() => {
    if (
      userInInstitution ||
      !isInstitutionAccountOnly ||
      !profileInstitutionId ||
      inIgnoreUrlSwitchAccount
    ) {
      return;
    }

    setCurrentInstitution(profileInstitutionId);
  }, [
    isInstitutionAccountOnly,
    profileInstitutionId,
    inIgnoreUrlSwitchAccount,
    userInInstitution,
  ]);

  // Effect auto swtich account into institution for institution without individual account
  useEffect(() => {
    switchAccountForInstitutionAccountOnly();
  }, [switchAccountForInstitutionAccountOnly]);
};

export default useAutoSwitchInstitutionAccOnly;
