import React, { SyntheticEvent, PropsWithChildren } from 'react';
import DotComponent from 'features/home/components/DotComponent';
import classNames from 'classnames';
import { useAmountBlurred } from '../../provider/AmountBlurredProvider';
import { ParentPage } from '../../constants/indes';
import Show from 'features/common/components/Show/Show';

import styles from './FadeAmountBlurred.module.css';
import fadeStyles from 'features/common/AmountBlurred/components/AmountPortofolio/AmountPortofolio.module.css';

interface IAmountBlurred {
  dotAmount: number;
  alignItems?: 'right' | 'left';
  onClick?: (event: SyntheticEvent<any>) => void;
  parentPage?: ParentPage;
}

const FadeAmountBlurred: React.FC<PropsWithChildren<IAmountBlurred>> = ({
  children,
  dotAmount,
  alignItems = 'left',
  onClick,
  parentPage,
}) => {
  const { isAmountBlurred, isShowAmount, isHideAmount } = useAmountBlurred();

  const isShowBlurred = parentPage ? parentPage === 'index' : true;
  const isBlurred = parentPage
    ? parentPage === 'index' && isAmountBlurred
    : isAmountBlurred;

  return (
    <div className={styles['wrapper']} onClick={onClick}>
      <Show when={isShowBlurred}>
        <div
          className={classNames(styles['blurred'], {
            [styles['hide']]: !isAmountBlurred,
            [styles['show']]: isBlurred,
            [fadeStyles['animation-hide']]: isShowAmount,
            [fadeStyles['animation-show']]: isHideAmount,
          })}
        >
          <div
            className={classNames(styles['dot-wrapper'], {
              [styles['right']]: alignItems === 'right',
            })}
          >
            <DotComponent dotAmount={dotAmount} />
          </div>
        </div>
      </Show>
      <div
        className={classNames({
          [styles['hide']]: isBlurred,
          [styles['show']]: !isAmountBlurred,
          [fadeStyles['animation-hide']]: isHideAmount,
          [fadeStyles['animation-show']]: isShowAmount,
          [styles['align-right']]: alignItems === 'right',
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default FadeAmountBlurred;
