import { INSTITUTION_MIN_BUY_TOP_UP } from '../constants';
import { getCurrentInstitution } from './storage';

export const getInstitutionMinBuyTopUp = (minBuy: number) => {
  const institution = getCurrentInstitution();
  if (!!institution && !!institution?.institution_id) {
    if (minBuy > INSTITUTION_MIN_BUY_TOP_UP) {
      return minBuy;
    }
    return INSTITUTION_MIN_BUY_TOP_UP;
  }
  return minBuy;
};
