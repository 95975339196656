import './localStorage.polyfill';
import { set, get, reset, remove } from './basicHandler';

import { setObject, getObject, removeObject } from './objectHandler';

import {
  setTokenToLocalStorage,
  removeTokenHandler,
  getAccessToken,
  getOffsideTimezone,
} from './tokenhandler';

const storage =  {
  set,

  /**
   * Storage get
   *
   * If you set value into storage like `'100'`, you will be get different type on:
   * - android will be return string `'100'`
   * - ios will be return number `100`
   *
   * Example:
   * If you want to get isLogin value ('1' | '0') from storage, you can do this:
   * - const isLogin = await Storage.get('isLogin');
   * - const isLoginString = isLogin?.toString();
   */
  get,

  reset,
  remove,
  setObject,
  getObject,
  removeObject,
  getAccessToken,
  removeTokenHandler,
  setTokenToLocalStorage,
  getOffsideTimezone,
};


export default storage;