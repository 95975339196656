import { randomString } from './stringHelper';

/**
 * get mime type file from 8 first letter HEX file Uint8Array()
 *
 * example to get "signature hex":
 *
 * const fileArrayBuffer = fileEvent?.target?.result as ArrayBuffer;
 *
 * const uint = new Uint8Array(fileArrayBuffer);
 *
 * let bytes: string[] = [];
 *
 * uint.forEach((uintByte) => {
 *
 * 		bytes.push(uintByte.toString(16));
 *
 * });
 *
 * const hexFile = bytes.join('').toUpperCase().slice(0, 8);
 *
 * If you want to extend mime type file, see more signature: https://en.wikipedia.org/wiki/List_of_file_signatures
 * @param signature string
 */
const getRawMimetype = (signature: string) => {
  switch (signature) {
    case '89504E47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case '25504446':
      return 'application/pdf';
    case 'FFD8FFDB':
    case 'FFD8FFE0':
    case 'FFD8FFE1':
      return 'image/jpeg';
    case '3C737667':
    case '3C3F786D':
      return 'image/svg+xml';
    case 'D0CF11E0':
      return 'application/msword,application/vnd.ms-powerpoint,application/vnd.ms-excel';
    default:
      break;
  }

  if (signature.includes('504B')) {
    return 'application/zip,application/epub+zip,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  }
  return undefined;
};

/**
 * Get mimeType from fileBeenRead AND file
 *
 * @param fileBeenRead - get from callback onloaded new FileReader();
 * @param file - File
 */
export const getMimeTypeFromFile = (
  fileBeenRead: ProgressEvent<FileReader>,
  file: File
) => {
  const fileArrayBuffer = fileBeenRead.target?.result as ArrayBuffer;
  const uint = new Uint8Array(fileArrayBuffer);
  let bytes: string[] = [];
  uint.forEach((uintByte) => {
    bytes.push(uintByte.toString(16));
  });
  const hexFile = bytes.join('').toUpperCase();
  const mimeType = getRawMimetype(hexFile.slice(0, 8));
  if (
    mimeType ===
    'application/zip,application/epub+zip,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    const extFile = getExtentionFromFileName(file.name);
    switch (extFile) {
      case 'zip':
        return 'application/zip';
      case 'epub':
        return 'application/epub+zip';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      default:
        return undefined;
    }
  }

  if (
    mimeType ===
    'application/msword,application/vnd.ms-powerpoint,application/vnd.ms-excel'
  ) {
    const extFile = getExtentionFromFileName(file.name);
    switch (extFile) {
      case 'doc':
        return 'application/msword';
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'xls':
        return 'application/vnd.ms-excel';
      default:
        return undefined;
    }
  }
  return mimeType;
};

/**
 * Get filename with extention
 * {unix}_{randomString}.{extention_file}
 * @param mimeType ex: image/jpeg
 */
export const generateGeneralFileName = (mimeType: string) => {
  const extFile = getExtentionFromMimetype(mimeType);
  const fileName = `${new Date().getTime()}_${randomString(10)}`;
  return `${fileName}.${extFile}`;
};

/**
 * Get extention ex: jpg/jpeg/svg/pdf
 * @param mimeType ex: image/jpeg
 */
export const getExtentionFromMimetype = (mimeType: string) => {
  const mimeTypeSplit = mimeType.split('/');
  let extFile = mimeTypeSplit[mimeTypeSplit.length - 1];
  if (extFile === 'svg+xml') {
    extFile = 'svg';
  }
  switch (extFile) {
    case 'svg+xml':
      extFile = 'svg';
      break;
    case 'epub+zip':
      extFile = 'epub';
      break;
    case 'msword':
      extFile = 'doc';
      break;
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      extFile = 'docx';
      break;
    case 'vnd.ms-powerpoint':
      extFile = 'ppt';
      break;
    case 'vnd.ms-excel':
      extFile = 'xls';
      break;
    default:
      break;
  }
  return extFile;
};

/**
 * get extention file from file-name
 * @param file ex: hahaha.jpeg will return jpeg
 */
export const getExtentionFromFileName = (file: string) => {
  const splitFile = file.split('.');
  if (splitFile.length <= 1) return '';
  return splitFile[splitFile.length - 1];
};

/**
 * Convert buffer binary data to Base64-URL
 * @param buffer ArrayBuffer - binary data
 * @param mimeType string - ex: image/png, default = "application/octet-binary"
 */
export const arrayBufferToBase64 = (
  buffer: ArrayBuffer,
  mimeType = 'application/octet-binary'
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const blobFile = new Blob([buffer], { type: mimeType });
    let fileReader = new FileReader();
    fileReader.readAsDataURL(blobFile);
    fileReader.onloadend = (fileEvent) => {
      const base64URL = fileEvent?.target?.result as string;
      return resolve(base64URL);
    };
    fileReader.onerror = (erroEvent) => {
      return reject(erroEvent);
    };
  });
};

/**
 * Generate File from base64-string.
 *
 * Please make sure base64-string not base64-URI or base64URL
 * @param base64String
 * @param mimeType
 */
export const base64StringtoFile = (base64String: string, mimeType: string) => {
  const binary = atob(base64String);
  let arrayBin = [];
  for (var i = 0; i < binary.length; i++) {
    arrayBin.push(binary.charCodeAt(i));
  }

  const blobFile = new Blob([new Uint8Array(arrayBin)], { type: mimeType });

  const psuedoFileName = generateGeneralFileName(mimeType);

  return new File([blobFile], psuedoFileName);
};

/**
 * Convert base64 string representation into image object.
 * This function is made specially `since base64StringtoFile` function above didn't work.
 *
 * @param  {String} base64 - base64 string to be converted
 * @param  {String} fileName - file name
 * @param  {String} mimeType - base64 mimeType string to be converted
 * @return {Promise<File>} File object representation
 */
export async function base64ToFile(
  base64uri: string,
  fileName: string,
  mimeType: string
) {
  const res: Response = await fetch(base64uri);
  const blob: Blob = await res.blob();

  return new File([blob], fileName, { type: mimeType });
}
