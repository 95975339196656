import { useQuery } from 'react-query';
import useAuth from 'hooks/useAuth';
import { getFormData } from 'services/pengkinian-data';

const useRenewForm = () => {
  const { isLogin } = useAuth();
  const { status, data, isFetching, error, isLoading } = useQuery<any>(
    'renew_form',
    () => getFormData(),
    {
      enabled: isLogin,
      refetchOnMount: 'always',
      select: (res) => {
        return res?.data?.data;
      },
    }
  );

  return {
    status,
    data,
    error,
    isFetching,
    isLoading,
  };
};

export default useRenewForm;
