import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useSelector } from 'react-redux';
import { TRootReducers } from 'store/root';
import './OpenGopayPrompt.css';

const OpenGopayPrompt = React.lazy(() => import('./OpenGopayPrompt'));

const LazyOpenGopayPrompt = (props: any) => {
  const visible = useSelector(
    (state: TRootReducers) => state.gopay.openGopay.visible
  );

  return <LazyModal component={OpenGopayPrompt} visible={visible} {...props} />;
};

export default LazyOpenGopayPrompt;
