import React, { useState, Dispatch, SetStateAction, useContext } from 'react';

/**
 * Currently supported chart period
 * You can edit what periods our charts supported from this array
 */
export const supportedChartPeriod = [
  '1D',
  '1M',
  '3M',
  'YTD',
  '1Y',
  '3Y',
  '5Y',
  '10Y',
  'ALL',
] as const;
export type ChartPeriod = typeof supportedChartPeriod[number];

export const rdChartPeriodOptions = {
  '1D': ['1D', 'YTD', 'ALL'],
  '1M': ['1D', '1M', 'YTD', 'ALL'],
  '3M': ['1D', '1M', '3M', 'YTD', 'ALL'],
  '1Y': ['1D', '1M', '3M', 'YTD', '1Y', 'ALL'],
  '3Y': ['1D', '1M', '3M', 'YTD', '1Y', '3Y', 'ALL'],
  '5Y': ['1D', '1M', '3M', 'YTD', '1Y', '3Y', '5Y', 'ALL'],
  '10Y': ['1D', '1M', '3M', 'YTD', '1Y', '3Y', '5Y', '10Y', 'ALL'],
};

export const rdChartPeriodOptionsKey = [
  '1D',
  '1M',
  '3M',
  '1Y',
  '3Y',
  '5Y',
  '10Y',
] as const;
export type ChartPeriodOptions = typeof rdChartPeriodOptionsKey[number];

const ChartPeriodStateContext = React.createContext<ChartPeriod | undefined>(
  undefined
);
const ChartPeriodSetStateContext = React.createContext<
  Dispatch<SetStateAction<ChartPeriod>> | undefined
>(undefined);

const ChartPeriodContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [chartPeriod, setChartPeriod] = useState<ChartPeriod>('1Y');

  return (
    <ChartPeriodStateContext.Provider value={chartPeriod}>
      <ChartPeriodSetStateContext.Provider value={setChartPeriod}>
        {children}
      </ChartPeriodSetStateContext.Provider>
    </ChartPeriodStateContext.Provider>
  );
};

/**
 * Hook containing chart period data, and method to change current chart period selected
 */
const useChartPeriodState = () => {
  const chartPeriodStateContext = useContext(ChartPeriodStateContext);
  const chartPeriodSetStateContext = useContext(ChartPeriodSetStateContext);
  if (!chartPeriodStateContext || !chartPeriodSetStateContext) {
    throw new Error(
      'useChartPeriodState must be used inside ChartPeriodContextProvider'
    );
  }
  return {
    chartPeriod: chartPeriodStateContext,
    setChartPeriod: chartPeriodSetStateContext,
  };
};

export { ChartPeriodContextProvider, useChartPeriodState };
