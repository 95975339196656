import * as React from 'react';
import classNames from 'classnames';
import pullToRefresh from 'pulltorefreshjs';
import { randomString } from 'utils/stringHelper';

interface PullerProps {
  className?: string;
  onRefresh: (() => Promise<void>) | (() => void);
  loading?: boolean;
  identifier?: string;
  disabled?: boolean;
  removePullerBox?: boolean;
}

// This just means to create random hexadecimal (0-9, a-f) string
const generateIdentifier = () => {
  const randomIdentifier = randomString(6);
  return `pull-${randomIdentifier}`;
};

const Puller: React.FC<React.PropsWithChildren<PullerProps>> = (props) => {
  const {
    className,
    onRefresh,
    children,
    identifier,
    disabled = false,
    removePullerBox,
  } = props;
  /**
   * Init main element ref
   */
  const mainElement = React.useRef<HTMLDivElement | null>(null);
  /**
   * Init Puller id
   */
  const pullerId = identifier || generateIdentifier();

  const handleShouldRefresh = React.useCallback(() => {
    /**
     * Get Parent of main element (scroll handler)
     */
    const parentElement = mainElement.current?.parentElement;
    /**
     * Get parent element scroll position
     */
    const position = parentElement?.scrollTop;

    if (position !== undefined) {
      /**
       * Pull refresh only refresh if scroll position on the top
       */
      return position <= 0;
    }
    return false;
  }, []);

  React.useEffect(() => {
    /**
     * Check main element visibility
     */
    if (
      mainElement &&
      mainElement.current &&
      mainElement.current instanceof HTMLDivElement &&
      !disabled
    ) {
      /**
       * Create pull refresh instance after get main element
       */
      pullToRefresh.init({
        mainElement: `#${pullerId}`,
        triggerElement: `#${pullerId}`,
        distThreshold: 80,
        distMax: 100,
        onRefresh: onRefresh,
        shouldPullToRefresh: handleShouldRefresh,
      });
    }
  }, [onRefresh, handleShouldRefresh, pullerId, disabled]);

  React.useEffect(() => {
    /**
     * Destroy all pull refresh inctance when unomunted
     */
    return () => {
      pullToRefresh.destroyAll();
    };
  }, [disabled]);

  return !disabled ? (
    <div
      id={pullerId}
      ref={mainElement}
      className={classNames(className, {
        'puller-box': !removePullerBox,
      })}
    >
      {children}
    </div>
  ) : (
    <div
      ref={mainElement}
      className={classNames(className, {
        'puller-box': !removePullerBox,
      })}
    >
      {children}
    </div>
  );
};

export default Puller;
