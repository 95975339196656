import { getEnv } from 'core/env';
import React, { PropsWithChildren } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const { RecaptchaV3 } = getEnv();

const GoogleReCaptchaV3Provider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  if (!RecaptchaV3) return <>{children}</>;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RecaptchaV3} language='id'>
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default GoogleReCaptchaV3Provider;
