import { useQuery } from 'react-query';
import { getCurrentInstitution } from '../utils';

export const useStorageCurrentInstutiton = (enabled = true) => {
  return useQuery(['current_institution'], () => getCurrentInstitution(), {
    enabled: enabled,
    staleTime: Infinity,
  });
};

/**
 * Determine user in account Institution or Individual
 *
 * - institution --> user in institution account
 * - individual --> user in indivitual account
 * - loading_state --> not determine yet, because still on loading
 */
export const useAccountType = (
  enabled = true
): 'institution' | 'individual' | 'loading_state' => {
  const { data, isLoading, isFetching } = useStorageCurrentInstutiton(enabled);

  if (isFetching) {
    return 'loading_state';
  }

  const userInInstitution = !!data?.institution_id && !isLoading;

  return userInInstitution ? 'institution' : 'individual';
};

/**
 * determine is User in Institution Account or NOT
 */
export const useUserInInstitution = (enabled = true) => {
  const accountType = useAccountType(enabled);

  return accountType === 'institution';
};
