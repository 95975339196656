import React, { SyntheticEvent, useRef, useState, useEffect } from 'react';
import { numberToGeneralValueFormat } from 'utils/stringHelper';
import {
  ToogleAmountBlurred,
  Tooltip,
} from 'features/common/AmountBlurred/components';
import DotComponent from 'features/home/components/DotComponent';
import { useAmountBlurred } from '../../provider/AmountBlurredProvider';
import { TTooltipKey, ParentPage } from '../../constants/indes';
import { Skeleton } from '@bibitid/uikit-v1';

import Show from 'features/common/components/Show/Show';

import classnames from 'classnames';
import styles from './AmountPortofolio.module.css';

interface IAmountPorotfolio {
  amount: number;
  isLoading: boolean;
  parentPage: ParentPage;
  handleBlurredAmount: (event: SyntheticEvent<any>) => void;
  onCloseTooltip?: () => void;
  tooltipKey: TTooltipKey;
  showTooltip: boolean;
  premium?: boolean;
  sahamPorto?: boolean;
}

const AmountPortofolio: React.FC<IAmountPorotfolio> = ({
  amount,
  isLoading,
  showTooltip,
  handleBlurredAmount,
  onCloseTooltip,
  tooltipKey,
  premium,
  parentPage,
  sahamPorto = false,
}) => {
  const amountRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [margin, setMargin] = useState(0);
  const [switchAnimation, setSwitchAnimation] = useState(false);

  const { isAmountBlurred, isShowAmount, isHideAmount } = useAmountBlurred();

  const allowedParentPage =
    parentPage === 'index' || parentPage === 'home_page';
  const isBlurred = allowedParentPage && isAmountBlurred;
  const isShowToggle = allowedParentPage && width > 0;

  useEffect(() => {
    setTimeout(() => {
      const widthAmount = amountRef.current?.getBoundingClientRect().width || 0;

      setWidth(widthAmount);

      if (widthAmount > 0 && widthAmount < 92) {
        setSwitchAnimation(true);
      }
    }, 300);
  }, [isLoading, amount]);

  useEffect(() => {
    if (isAmountBlurred) {
      setMargin(114);
    } else {
      setMargin(width + 22);
    }
  }, [isAmountBlurred, width]);

  const handleClick = (event: React.SyntheticEvent<any, Event>) => {
    if (allowedParentPage) {
      handleBlurredAmount(event);
    }
  };

  if (isLoading) {
    return (
      <Skeleton
        width={160}
        className={sahamPorto ? 'saham-porto-skeleton' : undefined}
        height={24}
        style={{
          margin: '12px 0px 8px',
          background: `${premium && '#ededed'}`,
          opacity: `${premium && '15%'}`,
          borderRadius: '20px',
        }}
        animation={premium ? 'static' : 'wave'}
      />
    );
  }

  return (
    <div className={styles['wrapper']}>
      <div onClick={handleClick} className={styles['amount-wrapper']}>
        <div
          style={{
            //@ts-ignore
            '--amountWidth': `${width}px`,
          }}
          className={classnames(styles['container'], {
            [styles['container-blurred']]: isBlurred,
            [styles['container-unblurred']]: !isAmountBlurred,
            [styles['container-show']]: isShowAmount && !switchAnimation,
            [styles['container-hide']]: isHideAmount && !switchAnimation,
            [styles['bouncy-left-to-right']]: isShowAmount && switchAnimation,
            [styles['bouncy-right-to-left']]: isHideAmount && switchAnimation,
          })}
        >
          <div
            className={classnames(styles['amount-container'], {
              [styles['hide']]: isBlurred,
              [styles['show']]: !isAmountBlurred,
              [styles['animation-hide']]: isHideAmount,
              [styles['animation-show']]: isShowAmount,
            })}
          >
            <div
              ref={amountRef}
              className={classnames(styles['nominal-wrapper'], {
                [styles['white']]: premium,
              })}
            >
              <span>Rp</span>
              {numberToGeneralValueFormat(amount, {
                mantissa: 0,
              })}
            </div>
          </div>
          <Show when={allowedParentPage}>
            <div
              className={classnames(styles['amount-container'], {
                [styles['hide']]: !isAmountBlurred,
                [styles['show']]: isBlurred,
                [styles['animation-hide']]: isShowAmount,
                [styles['animation-show']]: isHideAmount,
              })}
            >
              <DotComponent dotAmount={7} />
            </div>
          </Show>
        </div>
      </div>
      <Show when={isShowToggle}>
        <ToogleAmountBlurred
          premium={premium}
          tooltipKey={tooltipKey}
          showTooltip={showTooltip}
          handleBlurredAmount={handleClick}
        />
      </Show>
      <Show when={showTooltip && isShowToggle}>
        <Tooltip marginLockIcon={margin} onClose={onCloseTooltip} />
      </Show>
    </div>
  );
};

export default AmountPortofolio;
