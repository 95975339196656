import { BibitApiSuccessResponse } from 'services';
import { AxiosResponse } from 'axios';
import {
  IAuthLoginData,
  ITrustedDevicePayload,
  ITrustedDeviceResponse,
  IVerifyPinPayload,
  IVerifyPinResponse,
} from 'services/auth.type';
import http from 'core/http';
import endpoints from './endpoints';
import * as Parent from 'core/Parent';
import { initAppCheck } from 'core/firebase';
import { getFlag } from 'utils/feature-flag';

/**
 * POST: Login number
 * @param {Object} requestOTPPayload Request OTP payload which API can understand
 * @param {string} requestOTPPayload.code Country code
 * @param {string} requestOTPPayload.phone Local phone number without country code or 0
 * @param {string} requestOTPPayload.via OTP sending method identifier
 */
export async function postLoginNumber(
  data: any
): Promise<AxiosResponse<BibitApiSuccessResponse<IAuthLoginData>>> {
  const { getAppCheckToken } = initAppCheck('login');

  const appCheckTokenResponse = await getAppCheckToken({
    phone: `${data?.code}${data?.phone}`,
  });

  const headers = {
    headers: {
      ...(appCheckTokenResponse?.token && {
        'X-App-Check': appCheckTokenResponse?.token,
      }),
      ...(appCheckTokenResponse?.provider && {
        'X-App-Check-Provider': appCheckTokenResponse?.provider,
      }),
    },
  };

  return http.post(endpoints.loginNumber, data, {}, { ...headers });
}

/**
 * POST: Check trusted device
 * @param {object} payload
 */
export function checkTrustedDevice(
  payload: ITrustedDevicePayload
): Promise<AxiosResponse<BibitApiSuccessResponse<ITrustedDeviceResponse>>> {
  return http.post(endpoints.checkTrustedDevice, payload);
}

/**
 * POST: Verify PIN
 * @param {object} payload
 */
export function verifyPinLogin(
  payload: IVerifyPinPayload
): Promise<AxiosResponse<BibitApiSuccessResponse<IVerifyPinResponse>>> {
  return http.post(endpoints.pinVerify, payload);
}

/**
 * Post Message to trigger ON/FF of idle timer
 */
export function setNativeToggleIdleTimer(active: boolean) {
  // handle temporary terminate idle time based on feature flag variable
  const idleFeatureIsActive = getFlag('web_idle_timeout');
  let isActive = active;

  if (!idleFeatureIsActive) {
    isActive = false;
  }

  const postMessageData = {
    fn: 'setToggleIdleTimer',
    timeout: 10000,
    data: {
      isActive,
    },
  };

  return Parent.postData('setToggleIdleTimer', postMessageData);
}
