import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { isEmptyObject, _showErrorToast } from './../../../utils';
import { goTo } from 'utils/History';
import { isErrorPinBlocked } from 'utils/auth';
import Storage from 'core/Storage';
import {
  handleResponseErrorCase,
  handleResponseGeneral,
} from 'utils/http-response';
import { stringContainsOnlyDigits } from './../../../utils/validator';
import {
  postVerifyChangeBank,
  postUpdateChangeBank,
  postUpdateChangeBankLiveness,
} from 'services/user';
import { checkPIN } from 'services/auth';
import { resetGenericLoadingDialog } from 'entities/common.reducer';
import { openErrorModal } from 'features/errors/errorModal.reducer';
import { checkStringWithEmoji } from 'features/registration/utils';

const defaultState = {
  is_fetching: false,
  is_authenticated: false,
  err: '',
  err_form: {
    hash: '',
    token: '',
    pin: '',
    bank: '',
    name: '',
    file_bank_account: '',
    number: '',
  },
  step: 0,
  email: '',
  nameBank: '',
  idBank: 0,
  codeBank: '',
  form: {
    id: '',
    token: '',
    pin: '',
    bank: 0,
    number: '',
    name: '',
    file_bank_account: '',
    currency: 0,
    unique_code: '',
    ktp_url: '',
    ktp_image: null,
    ktp_url_fallback: '',
    unique_code_url: '',
    unique_code_image: null,
    unique_code_url_fallback: '',
  },
  is_fetching_pin: false,
};

const [setPin, requestPin, failurePin, successPin, resetStepPin] = [
  'SET_CHANGEBANKACCOUNT_PIN',
  'LOAD_CHANGEBANKACCOUNT_REQUESTPIN',
  'LOAD_CHANGEBANKACCOUNT_FAILUREPIN',
  'LOAD_CHANGEBANKACCOUNT_SUCCESSPIN',
  'RESET_CHANGEBANKACCOUNT_RESETSTEPPIN',
].map(createAction);

const [
  reset,
  resetErrForm,
  setBank,
  setForm,
  incrementStep,
  request,
  failure,
  success,
] = [
  'RESET_CHANGEBANKACCOUNT',
  'SET_CHANGEBANKACCOUNT_BANK',
  'SET_CHANGEBANKACCOUNT_FORM',
  'INCREMENT_CHANGEBANKACCOUNT_STEP',
  'LOAD_CHANGEBANKACCOUNT_REQUEST',
  'LOAD_CHANGEBANKACCOUNT_FAILURE',
  'LOAD_CHANGEBANKACCOUNT_SUCCESS',
  'RESET_ERROR_FORM',
].map(createAction);

const [changeNewEmail] = ['CHANGE_CHANGEBANKACCOUNT_NEWEMAIL'].map(
  createAction
);

const [changePin] = ['CHANGE_CHANGEBANKACCOUNT_PIN'].map(createAction);

const reducer = createReducer(
  {
    [resetStepPin]: (state) => {
      return update(state, {
        form: {
          step: { $set: 0 },
        },
      });
    },
    [setPin]: (state, { pin }) => {
      return update(state, {
        form: {
          pin: { $set: pin },
        },
      });
    },
    [requestPin]: (state) => {
      return update(state, {
        is_fetching_pin: { $set: true },
      });
    },
    [failurePin]: (state, payload) => {
      const { err, err_form } = payload;
      return update(state, {
        is_fetching_pin: { $set: false },
        err_form_pin: { $set: err_form },
        err_pin: { $set: err },
      });
    },
    [successPin]: (state, payload) => {
      const { id } = payload;
      let toBody = {
        is_fetching_pin: { $set: false },
        step: { $set: 1 },
        form: {
          id: { $set: id },
        },
      };
      return update(state, toBody);
    },
    [changeNewEmail]: (state, payload) => {
      const { email } = payload;
      return update(state, {
        email: { $set: email },
      });
    },
    [changePin]: (state, payload) => {
      const { pin } = payload;
      return update(state, {
        pin: { $set: pin },
      });
    },
    [reset]: (state) => {
      const { is_fetching, err, step } = defaultState;

      return update(state, {
        is_fetching: { $set: is_fetching },
        err: { $set: err },
        step: { $set: step },
      });
    },
    [setBank]: (state, payload) => {
      const { code, id, name } = payload;
      return update(state, {
        idBank: { $set: id },
        nameBank: { $set: name },
        codeBank: { $set: code },
        form: {
          bank: { $set: id },
        },
      });
    },
    [setForm]: (state, payload) => {
      const { key, value } = payload;
      return update(state, {
        form: {
          [key]: { $set: value },
        },
      });
    },
    [incrementStep]: (state) => {
      const { step } = state;
      return update(state, {
        step: { $set: step + 1 },
      });
    },
    [request]: (state) => {
      return update(state, {
        is_fetching: { $set: true },
        err: { $set: '' },
        err_form: {
          $set: {
            hash: '',
            token: '',
            pin: '',
            bank: '',
            name: '',
            file_bank_account: '',
          },
        },
      });
    },
    [failure]: (state, payload) => {
      let { err, err_form } = payload;
      return update(state, {
        is_fetching: { $set: false },
        err: { $set: err },
        err_form: { $set: err_form },
      });
    },
    [success]: (state, payload) => {
      let toUpdate = {
        is_fetching: { $set: false },
      };
      if (payload && payload.authenticated) {
        toUpdate['is_authenticated'] = { $set: true };
      }
      return update(state, toUpdate);
    },
    [resetErrForm]: (state) => {
      const { err_form } = defaultState;
      return update(state, {
        err_form: { $set: err_form },
      });
    },
  },
  defaultState
);

export {
  reset,
  changeNewEmail,
  changePin,
  setBank,
  setForm,
  failurePin,
  failure,
};

export function postVerifyCode(token) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      const toBody = {
        token: token,
      };
      const req = postVerifyChangeBank(toBody).then((payload) => {
        if (isEmptyObject(payload)) {
          return dispatch(failure({ err: 'Something is wrong' }));
        }
        dispatch(
          setForm({
            key: 'token',
            value: token,
          })
        );
        dispatch(
          setForm({
            key: 'unique_code',
            value: payload.data.data.unique_code,
          })
        );
        dispatch(
          success({
            authenticated: true,
          })
        );
        return resolve(
          payload &&
            payload.data &&
            payload.data.data &&
            payload.data.data.pin_length
        );
      });
      handleResponseErrorCase(req, (errObj) => {
        const type = errObj?.raw?.type;
        const err = errObj?.err;
        if (type === 'no_response') _showErrorToast(err);
        return dispatch(failure({ err: type }));
      });
    });
  };
}

export function postVerifyPinWithoutDedicatedCheck() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(incrementStep());
      return resolve(true);
    });
  };
}

export function postVerifyPin(updateProfileDataCache) {
  return (dispatch, getState) => {
    let {
      changeBankAccount: {
        form: { pin, token },
      },
    } = getState();
    dispatch(requestPin());
    const toSend = {
      pin,
      type: 'change_bank',
      token,
    };
    const req = checkPIN(toSend).then((response) => {
      const {
        user: { id },
      } = handleResponseGeneral(response);
      dispatch(successPin({ id }));
    });
    handleResponseErrorCase(req, (errObj) => {
      const { err, err_form, raw } = errObj;
      if (isErrorPinBlocked(raw)) {
        /** new update profile */
        updateProfileDataCache({
          suspend: 1,
        });
      }

      return dispatch(failurePin({ err, err_form }));
    });
    return req;
  };
}

/**
 * Checking form field at add bank account is valid or not
 * if not valid show the warning in form
 * if valid push to liveness verification page (LivenessVerificationPage.tsx)
 */
export function checkChangeBankAccount(history) {
  return (dispatch, getState) => {
    const {
      changeBankAccount: { form },
    } = getState();

    const { bank: _bank, number: _number, name: _name } = form;

    let toErrorForm = {};

    if (!_bank) {
      toErrorForm['bank'] = 'Pilih bank terlebih dahulu';
    }
    if (!_name || !_name?.trim().length) {
      toErrorForm['name'] = 'Nama Pemilik Rekening tidak boleh kosong.';
    }
    if (checkStringWithEmoji(_name)) {
      toErrorForm['name'] = 'Nama pemilik rekening harus berupa alfanumerik';
    }

    if (!_number) {
      toErrorForm['number'] = 'Nomor Rekening tidak boleh kosong.';
    } else if (!stringContainsOnlyDigits(_number)) {
      toErrorForm['number'] = 'Nomor rekening bank kamu harus berupa angka.';
    }

    if (_number.length < 5) {
      toErrorForm['number'] =
        'Panjang Nomor Rekening yang kamu masukkan kurang dari 5';
    }

    if (Object.keys(toErrorForm).length > 0) {
      return dispatch(
        failure({
          err_form: toErrorForm,
        })
      );
    }

    if (history) {
      dispatch(resetErrForm());
      return history.push({
        pathname: '/profile/settings/changebank/liveness-verification',
        state: { isVisible: true },
      });
    }
  };
}

export function postChangeBankAccountV2({ livenessId, imageUrl }) {
  return (dispatch, getState) => {
    const {
      changeBankAccount: { form },
    } = getState();
    const {
      bank: _bank,
      number: _number,
      name: _name,
      pin,
      token,
      currency,
      unique_code,
      ktp_url,
      unique_code_url,
    } = form;
    const toBody = {
      token,
      pin,
      bank: _bank,
      bank_account_name: _name,
      bank_account_number: _number,
      liveness_id: livenessId,
      file_prove: imageUrl,
      currency,
      file_identity: ktp_url,
      file_unique_code: unique_code_url,
      unique_code: unique_code,
    };

    let toErrorForm = {};

    if (!_bank) toErrorForm['bank'] = 'Pilih bank terlebih dahulu';
    if (!_name)
      toErrorForm['name'] = 'Nama Pemilik Rekening tidak boleh kosong.';
    if (!_number) {
      toErrorForm['number'] = 'Nomor Rekening tidak boleh kosong.';
    } else if (!stringContainsOnlyDigits(_number)) {
      toErrorForm['number'] = 'Nomor rekening bank kamu harus berupa angka.';
    }

    if (Object.keys(toErrorForm).length > 0) {
      return dispatch(
        failure({
          err_form: toErrorForm,
        })
      );
    }

    const req = postUpdateChangeBankLiveness(toBody).then((payload) => {
      if (isEmptyObject(payload)) {
        return dispatch(openErrorModal());
      }

      // reset state liveness verification
      window.history.pushState({}, window.history?.state?.key);
      dispatch(resetGenericLoadingDialog());
      goTo({
        pathname: '/profile/settings/changebank/liveness-results',
        state: { isVisible: true, status: 'success' },
      });
      return;
    });
    handleResponseErrorCase(req, (errObj) => {
      const {
        err, // message from error case
        err_form, // error for form , usually object
      } = errObj;
      dispatch(
        openErrorModal({
          message: err,
        })
      );
      dispatch(resetGenericLoadingDialog());
      return dispatch(failure({ err, err_form }));
    });
  };
}

// TODO: REMOVE THIS FUNCTION IF USER UPDATE THE ANDRIOD VERSION 3.9.1 REACH 100%
export function postChangeBankAccountV1() {
  return (dispatch, getState) => {
    const {
      changeBankAccount: { form },
      bankaccount: { output: output_bankaccount },
      bankaccountselfie: { output: output_bankaccountselfie },
    } = getState();
    const {
      bank: _bank,
      number: _number,
      name: _name,
      pin,
      token,
      currency,
    } = form;
    const toBody = {
      token,
      pin,
      bank: _bank,
      bank_account_name: _name,
      bank_account_number: _number,
      file_bank_account: output_bankaccount,
      file_prove: output_bankaccountselfie,
      currency,
    };

    dispatch(request());

    let toErrorForm = {};

    if (!_bank) toErrorForm['bank'] = 'Pilih bank terlebih dahulu';
    if (!_name)
      toErrorForm['name'] = 'Nama Pemilik Rekening tidak boleh kosong.';
    if (!_number) {
      toErrorForm['number'] = 'Nomor Rekening tidak boleh kosong.';
    } else if (!stringContainsOnlyDigits(_number)) {
      toErrorForm['number'] = 'Nomor rekening bank kamu harus berupa angka.';
    }
    if (!output_bankaccount || !output_bankaccountselfie)
      toErrorForm['file_bank_account'] =
        'Upload foto bank serta selfie anda terlebih dahulu.';

    if (Object.keys(toErrorForm).length > 0) {
      return dispatch(
        failure({
          err_form: toErrorForm,
        })
      );
    }

    const req = postUpdateChangeBank(toBody).then((payload) => {
      if (isEmptyObject(payload)) return dispatch(openErrorModal());

      dispatch(success());
      dispatch(incrementStep());
    });
    handleResponseErrorCase(req, (errObj) => {
      const {
        err, // message from error case
        err_form, // error for form , usually object
      } = errObj;
      dispatch(
        openErrorModal({
          message: err,
        })
      );
      return dispatch(failure({ err, err_form }));
    });
  };
}

export async function saveStateToStorage(value) {
  const amendBankData = await Storage.getObject('amend_bank');
  Storage.setObject('amend_bank', { ...amendBankData, ...value });
}

export { resetStepPin };
export default reducer;
