import compareVersion from 'compare-versions';

/**
 * Redirect into LinkAjaVerification page on native after buy giftcard is supported or not
 * Only support when it's on native and supported by flag (isGiftCardLinkAjaVerificationSupported)
 * @returns boolean
 */
export const isGiftCardLinkAjaVerificationSupported = (): boolean => {
  const isSupported =
    window.document.isGiftCardLinkAjaVerificationSupported ||
    window.isGiftCardLinkAjaVerificationSupported;
  return !!isSupported;
};

/**
 * Check main app version is compatible to handle linkAja applink or not.
 */
export const LINKAJA_APPLINK_IOS_VERSION = '4.28.0';
export const LINKAJA_APPLINK_ANDROID_VERSION = '3.33.0';

export const isLinkAjaApplinkSupported = () => {
  const version = window?.VERSION || window?.document?.VERSION;
  const systemName = window?.systemName || window?.document?.systemName;

  // Check device system name and set the eligible version according to it.
  const newVersion =
    !!systemName && systemName.toLowerCase() === 'ios'
      ? LINKAJA_APPLINK_IOS_VERSION
      : LINKAJA_APPLINK_ANDROID_VERSION;

  // Variable to check if current version support LinkAja Applink or not
  let supportedByVersion: boolean = false;

  /**
   * Check device system name and version.
   * Eligible to use LinkAja applink if iOS version > 4.28.0
   * and android version > 3.33.0.
   */
  if (
    !!version &&
    !!systemName &&
    (systemName.toLowerCase() === 'ios' ||
      (systemName.toLowerCase() === 'android' &&
        compareVersion.compare(version, LINKAJA_APPLINK_ANDROID_VERSION, '>=')))
  ) {
    supportedByVersion = true;
  }

  return {
    supportedByVersion,
    newVersion,
    systemName,
    version,
  };
};
