import React from 'react';
import { Redirect, RouteProps } from 'react-router';
import SentryRoute from './SentryRoute';
import useAuth from 'hooks/useAuth';
import withStatusBar from 'core/statusbar/hoc/withStatusBar';

interface Props extends RouteProps {
  component?: any;
  restricted?: boolean;
  /** Props to determine whether to reset status bar on certain routes */
  defaultStatusBar?: boolean;
  /**
   * @deprecated Use hooks withoutTabBar instead
   * Props to determine whether to show or hide tabbar
   */
  showTabBar?: boolean;
}

/**
 * `PublicRoute` Component is wrapper for `Route` Component which can be restrict route when user is logged in
 */
const PublicRoute: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { path, component: Component, restricted, ...defaultProps } = props;

  /**
   * Get User login status
   */
  const { isLogin } = useAuth();

  return (
    <SentryRoute
      {...defaultProps}
      render={(routerProps: any) => {
        return isLogin && restricted ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          <Component {...routerProps} />
        );
      }}
    />
  );
};

export default withStatusBar(PublicRoute);
