import { apiInstance } from 'core/http';
import { postRefreshToken } from './network';
import { removeRefreshId, forceLogoutUser } from './utils';
import storage from 'core/Storage';
import {
  getLoadingRfToken,
  setLoadingRfToken,
  addPendingRequest,
  clearPendingRequest,
  continuePendingRequest,
  addPendingPostMessage,
  continuePendingPostMessage,
} from './retry-request';
import axios, { AxiosRequestConfig } from 'axios';
import { REFRESH_BEFORE } from './constant';
import type { JwtAcToken, RefreshTokenData } from './refresh-token.type';
import jwtDecode from 'jwt-decode';
import * as Parent from 'core/Parent';
import { CallbackType, DataParamPostDataType } from 'core/Parser/types';
import { generalLogError } from 'utils/Analytics';
import { getUnixTime, addMinutes } from 'date-fns';

/**
 * handling refresh token in Axios Interceptors
 * @param requestResource request instance
 */
export const handleRefreshTokenAxios = async (
  requestResource: AxiosRequestConfig<any>,
  { refetch = false }: { refetch?: boolean } = {}
) => {
  const loadingRefreshToken = getLoadingRfToken();

  /**
   * pathname url from request resource
   *
   */
  const pathNameUrl = requestResource.url;

  // @ Before all

  // to avoid loop forever, if we hit 401 and pathname is `/auth/token`
  // we just throw user to logout
  if (pathNameUrl === '/auth/token') {
    try {
      const dataBody = JSON.parse(requestResource?.data || '{}');

      // log to sentry
      generalLogError('Force Logout When Refresh Token', {
        desc: 'user throwing to login page becase response status /auth/token is 401',
        apk_version: window.VERSION || window.document.VERSION,
        header_token: requestResource?.headers?.Authorization ? true : false,
        device_id: dataBody?.device_id,
        refresh_id: dataBody?.refresh_id,
      });
    } catch (error) {}

    // force user logout
    await forceLogoutUser();

    return;
  }

  // @ Begin refresh token

  if (!loadingRefreshToken) {
    // 1). set loading to be true
    setLoadingRfToken(true);

    // 2). request refresh token to BE
    postRefreshToken()
      .then(async (res) => {
        const dataToken = res.data.data?.token;

        // 3). set new token to axios
        await setNewToken(dataToken);

        // 4). continue pending request in pool
        continuePendingRequest(dataToken.access_token);

        // 5). continue pending postMessage
        continuePendingPostMessage(dataToken.access_token);
      })
      .catch(() => {
        // do nothing. catch error api would be handled in interceptors
      })
      .finally(() => {
        // set loading false
        setLoadingRfToken(false);

        // clear pool pending request
        clearPendingRequest();
      });
  }

  // @ when loading is true. we need to store request-instance in pool in Callback function

  // add request-instance to pending request pool
  const poolingRequest = new Promise((resolve) => {
    addPendingRequest((token) => {
      // attach/override new Token to header
      requestResource.headers!.Authorization = 'Bearer ' + token;

      // request resource that we want to add to pool
      if (refetch) {
        resolve(axios(requestResource));
      } else {
        resolve(requestResource);
      }
    });
  });

  return poolingRequest;
};

/**
 * Check expiry token every request interceptor called.
 *
 * if token expire, then refresh the token.
 */
export const checkTokenExpiration = (tokenJwt?: string) => {
  // 1). guard check if nullable just throw back requestConfig;
  if (!tokenJwt) return;

  // 2). decode actoken without validate jwt key
  let decodedJwt: JwtAcToken | undefined;
  try {
    decodedJwt = jwtDecode<JwtAcToken>(tokenJwt);
  } catch (error) {}

  if (!decodedJwt) return;

  /**
   * expired access token
   */
  const expiredToken = decodedJwt.exp;

  // 3). calculate current time with adding X time `REFRESH_BEFORE` until Expired

  // - get offside timezone from local storege
  const offsideTimezone = storage.getOffsideTimezone();

  // - get server time by adding server offiside timezone to local timezone
  const serverTime = addMinutes(new Date(), offsideTimezone);

  // - add `REFRESH_BEFORE - minutes` so we treat JWT expired is expired before `REFRESH_BEFORE - minutes`
  const currentTime = getUnixTime(serverTime) + REFRESH_BEFORE * 60;

  // 4). determine is token expired after we adjust currentTime with `REFRESH_BEFORE`
  const tokenExpired = Number(expiredToken) <= currentTime;

  return tokenExpired;
};

/**
 * Request refresh token in outside axios interceptors
 *
 * note that this function cannot pending upcoming request.
 * for now this function used by postMessage that underneath the hood call API in Native side,
 * Just to makesure the token always fresh.
 */
export const requestRefreshToken = async (
  id: string,
  data: DataParamPostDataType,
  cb?: CallbackType
): Promise<void> => {
  const loadingRefreshToken = getLoadingRfToken();

  // current actoken in axios header
  const acToken = apiInstance.defaults.headers?.common['Authorization'] as
    | string
    | undefined;

  // 1). check token expiration
  const tokenExpired = checkTokenExpiration(acToken);

  // if not expired we just resolved it
  if (!tokenExpired) return;

  // @ the token is expired

  // 2). request refresh token to BE
  if (!loadingRefreshToken) {
    // 3). set loading true

    setLoadingRfToken(true);

    postRefreshToken()
      .then(async (res) => {
        const dataToken = res.data.data?.token;
        const {
          access_token,
          access_token_expired_at,
          refresh_token,
          refresh_token_expired_at,
          access, // --> old token
        } = dataToken;

        // 4). Set new token to default config Axios
        apiInstance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${access_token}`;

        // 5). Remove request id
        // 6). set data token to localstorage
        await Promise.all([
          removeRefreshId(),
          storage.setTokenToLocalStorage({
            access,
            access_token_expired_at,
            access_token,
            refresh_token_expired_at,
            refresh_token,
          }),
        ]);

        continuePendingPostMessage(access_token);

        continuePendingRequest(access_token);
      })
      .catch(() => {
        // do nothing.
      })
      .finally(() => {
        // set loading false
        setLoadingRfToken(false);
      });
  }

  // @ when loading is true, we need to pending postMessage in pool

  const poolingPostMessage = new Promise<void>((resolve) => {
    addPendingPostMessage(() => {
      resolve(Parent.postData(id, data, cb));
    });
  });

  return poolingPostMessage;
};

/**
 * helper to set new token after refresh token or get new scopes
 */
export const setNewToken = async (dataToken: RefreshTokenData['token']) => {
  const {
    access_token,
    access_token_expired_at,
    refresh_token,
    refresh_token_expired_at,
    access, // --> old token
  } = dataToken;

  // Set new token to default config Axios
  apiInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${access_token}`;

  // Remove request id
  // Set data token to localstorage
  await Promise.all([
    removeRefreshId(),
    storage.setTokenToLocalStorage({
      access,
      access_token_expired_at,
      access_token,
      refresh_token_expired_at,
      refresh_token,
    }),
  ]);
};
