import {
  TopUpFlowStep,
  SelectedGopayPromo,
  TriggeredFromType,
  TransactionType,
} from 'features/topup-modal/topupmodal.type';
import Storage from 'core/Storage';
import IsEmpty from 'utils/isEmpty';
import { isJSON } from 'utils/validator';

const TOP_UP_STORAGE_KEY = 'topUpRestore';

interface RestoreOpenTopUpProperties {
  /**
   * Tell at what step we are in this topup flow
   */
  step: TopUpFlowStep;

  /**
   * Tell at where topup modal triggered from
   */
  triggeredFrom?: TriggeredFromType;

  /**
   * Transaction type, whether user will only get one mutual fund, or splitted
   * due to robo package
   */
  transactionType: TransactionType;

  /**
   * Default portfolio target, defined by component using it
   */
  portfolioId?: string | number | undefined;

  /**
   * Robo ID, necessary for calculating robo transaction
   */
  roboId?: string | undefined;

  /**
   * Show autocomplete (label under amount input, ex: 100.000, 500.000, etc.)
   */
  showAutocomplete?: boolean;

  /**
   * Show portfolio selection field
   */
  showPortfolioSelection?: boolean;

  /**
   * Show product selection field
   */
  showProductSelection?: boolean;

  /**
   * Show risk profile that user can adjust
   */
  showRiskProfile?: boolean;

  /**
   * Show robo items
   */
  showRoboItem?: boolean;

  /**
   * List of products which users will buy
   */
  products?: string[];

  /**
   * Determine selected product type
   */
  productType?: string;

  /**
   * Minimum buy for this transaction
   */
  minBuy: number;

  /**
   * Maximum buy filter (ex: used in gift card flow)
   */
  maxBuy?: number;

  /**
   * Currency symbol
   */
  currency?: 'USD' | 'IDR';

  /**
   * Determine wheter user use giftcard or not
   */
  giftcardCode?: string;

  /**
   * voucher code inputted by the user
   */
  voucherCode?: string;

  /**
   * Determine whether user is allowed to use cashback in this top up process
   */
  cashbackEnabled?: boolean;

  /**
   * Determine wheter input amount should be disabled or not
   */
  disableAmountInput?: boolean;

  /**
   * amount inputted by the user
   */
  amount?: number | undefined;

  /**
   * convenience fee by selection payment method
   */
  convenienceFee?: number | undefined;

  /**
   * convenience fee, determine user has free quota or not
   */
  hasConvenienceFeeFreeQuota?: boolean;

  /**
   * stamp duty fee get by fetching from api
   */

  stampDutyFee?: number | undefined;

  /**
   * for checking if preview and payment is using stamp duty
   */
  withStampDuty?: boolean | undefined;

  /**
   * total amount get by fetching from api (amount input by user w/o stamp duty fee)
   */
  totalAmount?: number | undefined;

  /**
   * dvoucher data when user uses voucherCode
   */
  voucherData?: any | undefined;

  termsAgreed?: boolean;

  selectedPaymentMethod?: string;

  /**
   * Selected Gopay Promo data in an object
   */
  selectedGopayPromo?: SelectedGopayPromo | null;

  /**
   * Topup Create Recurring State
   */
  createRecurring: boolean;

  /**
   * user can change portfolio or not in topup modal
   */
  enableChangePortfolio?: boolean;

  /**
   * Determine to hide or show Add To Chart button
   */
  enableAddToCartButton?: boolean;

  /**
   * Determine detail modal trigerred from what step
   */
  detailModalTrigerredFrom?: TopUpFlowStep;
  /**
   * Determine to user can change product or not
   */
  disableChangeProduct?: boolean;
}

/**
 * Saving latest state top up into localStorage
 * so we can restore top-up
 *
 * Promise::true -> success saving
 *
 * Promise::false -> failed saving
 */
export const saveTopUpProperties = async (data: RestoreOpenTopUpProperties) => {
  const dataStringfy = JSON.stringify(data);
  try {
    await Storage.set(TOP_UP_STORAGE_KEY, dataStringfy);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Latest top up properties from local Storage
 *
 * Promise::false -> the value not available
 *
 * Promise::RestoreOpenTopUpProperties -> the value available
 */
export const getLatestTopUpProperties = async () => {
  try {
    const storageData = await Storage.get(TOP_UP_STORAGE_KEY);
    const storageIsJSON = isJSON(storageData);

    let validateData;

    // If storage is JSON -> parse it
    if (storageData && storageIsJSON) {
      validateData = JSON.parse(storageData);
    }

    // In iOS, storage is already an object, we use it directly
    if (storageData && !storageIsJSON) {
      validateData = storageData;
    }

    // If storage is null -> assign empty object
    if (!storageData) {
      validateData = {};
    }

    if (IsEmpty(validateData)) return false;

    return validateData;
  } catch (error) {
    return false;
  }
};

/**
 * remove Latest Top Up Properties in LocalStorage
 *
 * Promise::true --> success remvoe
 *
 * Promise::false --> failed remove
 */
export const removeLatestTopUpProperties = async () => {
  try {
    await Storage.remove(TOP_UP_STORAGE_KEY);
    return true;
  } catch (error) {
    return false;
  }
};
