import { useContext } from 'react';
import { PostMessageContext } from 'core/Parser/context/PostMessage.context';

/**
 * - custom react hook for using postData
 * @returns - postData, postDataPromis, basePostMessage
 */
const usePostMessage = () => {
  const ctx = useContext(PostMessageContext);

  if (!ctx) {
    throw Error('Context out of bound');
  }

  const { postData, postDataPromise, basePostMessage } = ctx;

  return {
    postData,
    postDataPromise,
    basePostMessage,
  };
};

export default usePostMessage;
