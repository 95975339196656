import { useJagoContextState } from 'features/bankjago';
import { useFetchStatusRegisSBN } from 'features/sbn';
import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';
import { useMemo } from 'react';
import { BibitPlusUpgradeStatusTypes, VerificationTimeline } from '../types';
import { UseQueryOptions, useQuery } from 'react-query';
import { endpoints } from '../networks';
import { getVerificationStatus } from '../networks/services';

export const useUserUpgradeStatus = (
  { enabled }: UseQueryOptions = { enabled: true }
) => {
  const query = useQuery(
    endpoints.getVerificationStatus,
    getVerificationStatus,
    { enabled }
  );

  const userUpgradeStatusData = query?.data?.data?.data;

  // Bibit plus upgrade status
  let bibitPlusStatus: BibitPlusUpgradeStatusTypes =
    userUpgradeStatusData?.upgrade_status || 0;

  /**
   * PLEASE NOTE: 0.5 is FE state. in BE there is no status 0.5
   * 0.5 means upgrade_status is 0 but user HAS accept TNC
   * 0 means upgrade_status is 0 but user HAS NOT accept TNC.
   */
  if (bibitPlusStatus === 0 && !!userUpgradeStatusData?.has_accept_tnc) {
    bibitPlusStatus = 0.5;
  }

  return {
    userUpgradeStatus: {
      ...userUpgradeStatusData,
      upgrade_status: bibitPlusStatus,
    } as VerificationTimeline,
    ...query,
  };
};

const useBibitPlusUpgradeStatus = () => {
  const { isLogin } = useAuth();
  const {
    isAlreadyRegistered: jagoRegistered,
    linkingStatus,
    userHasBeenVerifiedBibit,
  } = useJagoContextState();
  const {
    data: profile,
    isLoading: loadingProfileData,
    isFetching: fetchingProfileData,
  } = useProfileData(isLogin);

  const {
    data: sbnStats,
    isLoading: loadingSbnStat,
    isFetching: fetchingSbnStat,
  } = useFetchStatusRegisSBN(isLogin);
  const stockbitRegistrationStatus =
    sbnStats?.data?.data?.stockbit_registration_status;
  const sbnStatus = sbnStats?.data?.data.status ?? '';
  const regisInStockbitOnVerification =
    stockbitRegistrationStatus === 'inprogress' &&
    !['rdn_rejected', 'rejected_by_ksei'].includes(sbnStatus);

  const {
    userUpgradeStatus,
    isLoading: loadingUserUpgradeStatus,
    isFetching: fetchingUserUpgradeStatus,
  } = useUserUpgradeStatus({
    enabled: isLogin,
  });

  const profileData = profile?.data?.data;

  const bankAccountName = profileData?.user?.bank_account_name ?? '';
  const bankAccountNumber = profileData?.user?.bank_account_number ?? '';
  const verificationStatus = profileData?.user?.status ?? 0;

  const userUnbanked =
    !bankAccountName && !bankAccountNumber && !jagoRegistered;

  /**
   * Conditions that make user eligible to upgrade and show banner :
   * 1. if bibit registration is still on verif (1), then :
   *    - check if registration status is on verification (1) or above
   *      @see https://www.notion.so/stockbit/user_status-e7d2e81d69284118aef874f7ce72a13d
   *    - user is banked
   * 2. if bibit registration status is succeed (4), then user is eligible
   */
  const isUserStatusEligibleToUpgrade =
    (verificationStatus > 0 && verificationStatus <= 4 && !userUnbanked) ||
    verificationStatus === 4;

  /**
   * If bank jago is rejected while user on verification,
   * user is not be able to upgrade to bibit+
   */
  const isBankJagoRejectedWhileBibitOnVerification =
    linkingStatus === 'REJECTED' && verificationStatus !== 4;

  const eligibleToUpgrade =
    isUserStatusEligibleToUpgrade &&
    !isBankJagoRejectedWhileBibitOnVerification;

  // Bibit plus upgrade status
  let bibitPlusStatus: BibitPlusUpgradeStatusTypes =
    userUpgradeStatus?.upgrade_status || 0;

  /**
   * Unfortunately, FE needs to override the status in this condition.
   * What is the condition ?
   * If bibit+ is rejected by stockbit and user revised the data,
   * API /user/upgrade/stats still return rejected status with stockbit_rejected_at.
   * Because bibit needs to wait callback from stockbit.
   * But we can get the realtime data from API /sbn/stats.
   * The property "stockbit_registration_status" will be in_progress.
   * So, if the "stockbit_registration_status" is inprogress,
   * FE will override the status REJECTED to ON_VERIFICATION.
   */
  if (bibitPlusStatus === 3 && regisInStockbitOnVerification) {
    bibitPlusStatus = 2;
  }

  const upgradeStatus = useMemo(
    () => ({
      notUpgraded: bibitPlusStatus === 0,
      preboarding: bibitPlusStatus === 0.5,
      resume: bibitPlusStatus === 1,
      waitVerification: bibitPlusStatus === 2,
      rejected: bibitPlusStatus === 3,
      finished: bibitPlusStatus === 4,
    }),
    [bibitPlusStatus]
  );

  return {
    statusNumber: bibitPlusStatus,
    upgradeStatus,
    isLoading: loadingProfileData || loadingSbnStat || loadingUserUpgradeStatus,
    isFetching:
      fetchingProfileData || fetchingSbnStat || fetchingUserUpgradeStatus,
    eligibleToUpgrade,
    userHasBeenVerifiedBibit,
    userUpgradeStatus,
    profileData,
  };
};

export default useBibitPlusUpgradeStatus;
