import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import withStatusBar from 'core/statusbar/hoc/withStatusBar';

interface Props extends RouteProps {
  component?: any;
  restricted?: boolean;
  /** Props to determine whether to reset status bar on certain routes */
  defaultStatusBar?: boolean;
}

/**
 * Sentry HOC wrapper for React Router
 */
const SentryComponent: any = Sentry.withSentryRouting(Route);

/**
 * `SentryRoute` Component is a `Route` Component wrapped with Sentry HOC
 * This is to group any transactions route with parameter
 */
const SentryRoute: React.FC<React.PropsWithChildren<Props>> = ({
  ...props
}) => {
  return <SentryComponent {...props} />;
};

export default withStatusBar(SentryRoute);
