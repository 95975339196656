import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface ReCaptchaVerifyV3Params {
  /**
   * call back success verify captcha.
   *
   * as documentation mentioning that call back need to wrap with `useCallback` for avoiding infinite rerender
   */
  onVerify: (token: string) => void;

  /**
   * call back error rendering Google ReCaptcha
   */
  onErrorRender: () => void;

  /**
   * an identifier captcha
   *
   * @default - 'giftcard'
   */
  action?: string;

  /**
   * if its false, Recaptcha Verify V3 doesn't run
   *
   * @default - true
   */
  enabled?: boolean;
}

const useGoogleCaptchaV3 = (enabled = true) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  /**
   * Execute Recaptcha Verify V3
   */
  const reCaptchaVerifyV3 = useCallback(
    async ({
      onVerify,
      onErrorRender,
      action = 'giftcard',
    }: ReCaptchaVerifyV3Params) => {
      if (!executeRecaptcha || !enabled) {
        return;
      }

      try {
        const res = await executeRecaptcha(action);
        onVerify(res);
      } catch {
        onErrorRender();
      }
    },
    [enabled, executeRecaptcha]
  );

  return { reCaptchaVerifyV3 };
};

export default useGoogleCaptchaV3;
