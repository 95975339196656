import { useQuery } from 'react-query';
import compareVersions from 'compare-versions';
import queryClient from 'network/queryClient';
import { getDeviceInfo } from 'core/DeviceInfo/deviceInfo';

import { featureVersions } from './feature-version';
import type { FeatureVersionsKey, AppVersion } from './feature-version';

/**
 * Version compare utility.
 */
export const versionCompare = (
  currentVersion: string,
  targetVersion: string,
  op: 'min' | 'max'
) => {
  if (!currentVersion || !targetVersion) return false;
  const validVersion = compareVersions.validate(targetVersion);
  if (!validVersion) return false;
  return compareVersions.compare(
    currentVersion,
    targetVersion,
    op === 'min' ? '>=' : '<='
  );
};

/**
 * Utility to check current device version eligibility.
 * @see `feature-version.ts` to add your feature configuration.
 *
 * @example
 *
 * - 1. Using Keys
 * ```
 *  const googleSsoSupported = await checkFeatureVersion('Google SSO');
 * ```
 *
 * - 2. Using Params
 * ```
 *  const featureSupported = await checkFeatureVersion({
 *    android: { min: '3.4.0' },
 *    ios: { min: '2.2.1' }
 * });
 * ```
 */
export const checkFeatureVersion = async (
  params: FeatureVersionsKey | AppVersion
) => {
  // check webview status
  const isNative = window.isWebview || window.document.isWebview;
  if (!isNative) return false;

  try {
    // get device info from queryClient (so that it can be cached)
    const deviceInfo = await queryClient.fetchQuery({
      queryKey: ['getDeviceInfo'],
      queryFn: getDeviceInfo,
    });

    if (!!deviceInfo) {
      const osName = deviceInfo.SYSTEM_NAME?.toLowerCase() || '';
      const appVersion = deviceInfo.VERSION || '';

      let versionSpecs: AppVersion;

      if (typeof params === 'object') {
        // version direct from params
        versionSpecs = params;
      } else {
        // access version specs from keys
        versionSpecs = featureVersions[params];
      }

      // verify device os ant matching version
      const verifyDeviceVersion = (os: 'android' | 'ios') => {
        const minVersion = versionSpecs[os]?.min;
        const maxVersion = versionSpecs[os]?.max;

        // both min & max version
        if (!!minVersion && !!maxVersion) {
          return (
            versionCompare(appVersion, minVersion, 'min') &&
            versionCompare(appVersion, maxVersion, 'max')
          );
        }
        // min version only
        if (!!minVersion) {
          return versionCompare(appVersion, minVersion, 'min');
        }
        // max version only
        if (!!maxVersion) {
          return versionCompare(appVersion, maxVersion, 'max');
        }

        return false;
      };

      if (osName) {
        return verifyDeviceVersion(osName as 'android' | 'ios');
      }
    }
    return false;
  } catch (_) {
    return false;
  }
};

/**
 * Custom hooks to check current device version eligibility.
 * @see `feature-version.ts` to add your feature configuration.
 *
 * @example
 *
 * - 1. Using Keys
 * ```
 *  const { data: googleSsoSupported, isLoading } = useCheckFeatureVersion('Google SSO')
 * ```
 *
 * - 2. Using Params
 * ```
 *  const { data: featureSupported } = useCheckFeatureVersion({
 *    android: { min: '3.4.0' },
 *    ios: { min: '2.2.1' }
 * })
 * ```
 */
export const useCheckFeatureVersion = (
  params: FeatureVersionsKey | AppVersion
) => {
  return useQuery(['Check Feature Version', params], () =>
    checkFeatureVersion(params)
  );
};
