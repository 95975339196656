import { getInfo } from './index';
import { isInsideNativeContainer } from 'utils/validator';
import getSafely from 'utils/safely';

/**
 * Tell us where webview run
 *
 *
 * @returns
 */
const deviceSystem = async (): Promise<
  'web' | 'ios' | 'android' | 'huawei'
> => {
  if (!isInsideNativeContainer()) return 'web';

  try {
    const deviceInfo = await getInfo();

    if (!deviceInfo) return 'web';

    const SYSTEM_NAME = getSafely(['SYSTEM_NAME'], deviceInfo);

    const MANUFACTURER = deviceInfo?.MANUFACTURER; // --> to find android huawei

    if (typeof SYSTEM_NAME !== 'string') return 'web';

    if (MANUFACTURER?.toLowerCase() === 'huawei') return 'huawei';

    /**
     * Why we need toLoweCase ?
     *
     * because android will retrieve Android
     */
    return SYSTEM_NAME.toLowerCase() as 'ios' | 'android';
  } catch (error) {
    return 'web';
  }
};

export default deviceSystem;
