import LazyModal from 'features/common/LazyModal';
import React from 'react';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';

const CreateOnboardingPortfolioModalLazy = React.lazy(
  () => import('./CreateOnboardingPortfolioModal')
);

const CreateOnboardingPortfolioModal = () => {
  const { onboardingModalStep } = useHomeOnboardingContext();

  const visible = onboardingModalStep === 'create_portfolio';

  return (
    <LazyModal
      component={CreateOnboardingPortfolioModalLazy}
      visible={visible}
    />
  );
};

export default CreateOnboardingPortfolioModal;
