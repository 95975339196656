import { JwtAcToken } from 'core/refresh-token/refresh-token.type';
import jwtDecode from 'jwt-decode';

export const getTokenScope = (tokenJwt: string) => {
  let decodedJwt: JwtAcToken | undefined;
  try {
    decodedJwt = jwtDecode<JwtAcToken>(tokenJwt);
  } catch (error) {}

  if (!decodedJwt) return;

  const scope = decodedJwt.scope;

  return scope;
};
