import useAuth from 'hooks/useAuth';
import useProfileData from 'hooks/useProfileData';

/**
 * hook helper to get referral data
 */
const useReferralData = () => {
  const { isLogin } = useAuth();
  const { data } = useProfileData(isLogin);
  const dataProfile = data?.data.data;

  const minPurchaseDownline =
    dataProfile?.config.min_purchase_referral_downline || 100000;
  const minPurchaseCashback =
    dataProfile?.config.min_purchase_with_cashback || 100000;
  const minUseCashback = dataProfile?.config.min_use_cashback || 10000;

  return {
    minPurchaseDownline,
    minPurchaseCashback,
    minUseCashback,
  };
};

export default useReferralData;
