import * as Parent from 'core/Parent';

/**
 * Get Device Info and Installed Bibit App Info
 * @deprecated use `getDeviceInfo()` instead
 * @see /core/DeviceInfo/deviceInfo.ts
 */
export function getInfo() {
  let resolve = null;
  const ms = 1500;

  const p = new Promise((res, rej) => {
    resolve = res;
  });

  const timeout = new Promise((res, rej) => {
    setTimeout(() => {
      res(null);
    }, ms);
  });

  Parent.postData('getDevInfo', { fn: 'getDevInfo' }, (err, devinfo) => {
    if (devinfo) {
      return resolve(devinfo);
    }

    resolve(null);
  });

  return Promise.race([timeout, p]);
}

const info = {
  getInfo,
};

export default info;
