import Storage from 'core/Storage';
import { useQuery } from 'react-query';
import { isJSON } from 'utils/validator';
import { OVERRIDE_FLAG_KEY_STORAGE } from './constants';
import { FlagKeysObject } from './flagKeys';
import client from 'network/queryClient';

/**
 * Get data override flags from local storage with react query hooks
 */
export const useOverrideFlagQuery = (enabled?: boolean) => {
  const { data: overrideFlagsDataStorage, ...query } = useQuery(
    OVERRIDE_FLAG_KEY_STORAGE,
    async () => await Storage.get(OVERRIDE_FLAG_KEY_STORAGE),
    {
      enabled,
    }
  );

  // check JSON data from storage
  const overrideFlagsData: Partial<FlagKeysObject> =
    overrideFlagsDataStorage && isJSON(overrideFlagsDataStorage)
      ? JSON.parse(overrideFlagsDataStorage)
      : overrideFlagsDataStorage;

  // make sure return data is object
  const overrideFlags =
    typeof overrideFlagsData === 'object' ? overrideFlagsData : {};

  return {
    ...query,
    data: overrideFlags,
  };
};

/**
 * get data override flags from react query cache
 */
export const getOverrideFlagQuery = () => {
  const flagArr = client.getQueriesData([OVERRIDE_FLAG_KEY_STORAGE]) || [];

  // return flagArr
  const overrideFlagsDataStorage = ((flagArr[0] || [])[1] as string) || '';

  // check JSON data from storage
  const overrideFlagsData: Partial<FlagKeysObject> =
    overrideFlagsDataStorage && isJSON(overrideFlagsDataStorage)
      ? JSON.parse(overrideFlagsDataStorage)
      : overrideFlagsDataStorage;

  return overrideFlagsData;
};
