import React from 'react';
import { Modal } from '@bibitid/uikit-v1';
import { Text } from 'features/common/components';
import PortoMax from 'assets/images/portfolio/product-not-available.svg';
import {
  useAppModalGlobalFunctions,
  useAppModalGlobalStateContext,
} from '../AppModalGlobalContext';

const ProductNotBuyableModal = () => {
  const { notBuyableProductModalOpen } = useAppModalGlobalStateContext();
  const { toggleNotBuyableProductModal } = useAppModalGlobalFunctions();

  return (
    <Modal
      visible={notBuyableProductModalOpen}
      onClose={toggleNotBuyableProductModal}
      className='max-porto-modal'
    >
      <Modal.Header showClose={true} showBackButton={false} />
      <Modal.Body>
        <div style={{ marginBottom: '24px', textAlign: 'center' }}>
          <img src={PortoMax} alt='Portfolio Telah Melebihi Batas' />
        </div>

        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '8px' }}>
            <Text type='subtitle2'>Produk Belum Tersedia</Text>
          </div>

          <Text>Saat ini produk sedang tidak dapat dibeli</Text>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={'Oke'}
        onPrimaryClick={toggleNotBuyableProductModal}
      />
    </Modal>
  );
};

export default ProductNotBuyableModal;
