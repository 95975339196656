import React from 'react';
import { useTopUpSbnState } from 'features/sbn/components/modals/TopUpSBNModal/contexts';
import LazyModal from 'features/common/LazyModal';

const TopUpSbnFlowLazy = React.lazy(() => import('./TopUpSBNFlow'));

const TopUpSbnModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { stepTopUpSbn } = useTopUpSbnState();

  const visible = !!stepTopUpSbn;

  return <LazyModal visible={visible} component={TopUpSbnFlowLazy} />;
};

export default TopUpSbnModal;
