/**
 * List of eligible feature version.
 * You can use this constant with utils `checkSupportedVersion`
 */
export const featureVersions = {
  'First Time Jago Payload': {
    android: { min: '3.52.0' },
    ios: { min: '4.44.0' },
  },
  'Google SSO': {
    android: { min: '3.48.0' },
    ios: { min: '4.48.0' },
  },
  'Apple SSO': {
    ios: { min: '4.48.0' },
  },
  'Cek Email Native': {
    android: { min: '3.45.0' },
    ios: { min: '4.38.0' },
  },
  'Appcheck New Response': {
    android: { min: '3.61.0' },
  },
  'Remote Config': {
    android: { min: '3.28.0' },
    ios: { min: '4.36.0' },
  },
  'Amend Bank Improvement': {
    android: { min: '3.62.0' },
    ios: { min: '4.49.0' },
  },
  'SNAP Jago': {
    android: { min: '3.65.0' },
    ios: { min: '4.51.0' },
  },
  'New Revision Type': {
    android: { min: '3.66.0' },
    ios: { min: '4.52.0' },
  },
  'Open Third Party Webview': {
    android: { min: '3.67.0' },
    ios: { min: '4.53.0' },
  },
  'In App Filter': {
    android: { min: '3.69.0' },
    ios: { min: '4.55.0' },
  },
} satisfies FeatureVersions;

type VersionSpec = {
  min?: string;
  max?: string;
};

export type AppVersion = {
  android?: VersionSpec;
  ios?: VersionSpec;
};

type FeatureVersions = {
  [key: string]: AppVersion;
};

export type FeatureVersionsKey = keyof typeof featureVersions;
