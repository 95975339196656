import React, { PureComponent } from 'react';
import classNames from 'classnames';

class PaddingHorizontal extends PureComponent {
  render() {
    return (
      <div
        {...this.props}
        className={classNames('bit-container-padding-horizontal', {
          [this.props.className]: this.props.className,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}

PaddingHorizontal.defaultProps = {
  className: '',
};

export default PaddingHorizontal;
