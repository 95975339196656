import * as React from 'react';
import styled from 'styled-components';

const BoxLoader = styled.div<SpinnerProps>`
  background: ${(props) =>
    props.isInsideModal ? 'var(--modal-surface)' : 'var(--surface)'};
  width: 100%;
  height: calc(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 1030px) {
    background: none;
    & + .bestofcontent {
      display: none;
    }
  }
`;
const SpinnerWrapper = styled.div<SpinnerProps>`
  margin: 50px;
  height: 32px;
  width: 32px;
  animation: rotate 0.8s infinite linear;
  border: 4px solid var(--green-default);
  border-right-color: ${(props) =>
    props.isInsideModal ? 'var(--modal-border)' : 'var(--border)'};
  border-top-color: ${(props) =>
    props.isInsideModal ? 'var(--modal-border)' : 'var(--border)'};
  border-radius: 50%;
  opacity: 0.8;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export interface SpinnerProps {
  className?: string;
  isInsideModal?: boolean;
}

/**
 * Indicate the loading state of a component or page and Their appearance, alignment, and sizing can be easily customized with ClassName props classes.
 */
export const Spinner: React.FC<SpinnerProps> = (props) => {
  const { className, isInsideModal = false } = props;
  return (
    <BoxLoader className={className} isInsideModal={isInsideModal}>
      <SpinnerWrapper className='spinner' isInsideModal={isInsideModal} />
    </BoxLoader>
  );
};

export default Spinner;
