import React, { Suspense } from 'react';
import ChunkLoadErrorBoundary from 'features/common/errors/ChunkLoadErrorBoundary';
import Loading from '../loader/Loading';

interface Props {
  /**
   * Component that need to lazying
   */
  component: React.ComponentType<React.PropsWithChildren<any>>;

  /**
   * custom loader component
   */
  loaderCustom?: React.ComponentType<React.PropsWithChildren<any>>;

  /**
   * what fallback you want.
   *
   * true => fallback with `feature/common/loader/Loading.tsx`
   *
   * false | undefined => <div>Loading..</div>
   */
  animationLoading?: boolean;
}

const LazyLoad: React.FC<React.PropsWithChildren<Props>> = ({
  component: Component,
  animationLoading,
  loaderCustom: LoaderCustom,
  ...rest
}) => {
  /**
   * Default Fallback when props `LoaderCustom` not given
   */
  const loadingDefault = animationLoading ? <Loading /> : <h3>Loading...</h3>;

  return (
    <ChunkLoadErrorBoundary>
      <Suspense fallback={LoaderCustom ? <LoaderCustom /> : loadingDefault}>
        <Component {...rest} />
      </Suspense>
    </ChunkLoadErrorBoundary>
  );
};

export default LazyLoad;
