import * as React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { WHITELIST_COMPLETE_PROFILE_MODAL_URL } from '../constants';

const CompleteYourProfileModalContext = React.createContext({
  toggleCompleteProfileModal: () => {},
  showCompleteProfileModal: false,
});

export const CompleteYourProfileModalProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [showCompleteProfileModal, setShowCompleteProfileModal] =
    React.useState<boolean>(false);

  const toggleCompleteProfileModal = React.useCallback(() => {
    setShowCompleteProfileModal(!showCompleteProfileModal);
  }, [showCompleteProfileModal]);

  const location = useLocation();

  /** matching current Pathname and WHITELIST_URL */
  const match = matchPath(location.pathname, {
    path: WHITELIST_COMPLETE_PROFILE_MODAL_URL,
    exact: true,
  });

  const pathnameGetWhitelist = match?.isExact === true;

  React.useEffect(() => {
    if (!pathnameGetWhitelist) {
      setShowCompleteProfileModal(false);
    }
  }, [pathnameGetWhitelist]);

  return (
    <CompleteYourProfileModalContext.Provider
      value={{
        showCompleteProfileModal,
        toggleCompleteProfileModal,
      }}
    >
      {children}
    </CompleteYourProfileModalContext.Provider>
  );
};

export const useCompleteYourProfileModal = () => {
  const { showCompleteProfileModal, toggleCompleteProfileModal } =
    React.useContext(CompleteYourProfileModalContext);

  return {
    showCompleteProfileModal,
    toggleCompleteProfileModal,
  };
};

export default CompleteYourProfileModalProvider;
