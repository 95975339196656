import React, { useState } from 'react';
import { UserRequirement } from 'services/documents';

type TFileAttachmentContext = {
  requirementDocs: UserRequirement[];
  setRequirementDocs: (data: UserRequirement[]) => void;
};

const FileAttachmentContext = React.createContext<TFileAttachmentContext>(
  undefined!
);

const FileAttachmentProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [requirementDocs, setRequirementDocs] = useState<UserRequirement[]>([]);

  return (
    <FileAttachmentContext.Provider
      value={{
        requirementDocs,
        setRequirementDocs,
      }}
    >
      {children}
    </FileAttachmentContext.Provider>
  );
};

export default FileAttachmentProvider;

export const useFileAttachmentContext = () =>
  React.useContext(FileAttachmentContext);
