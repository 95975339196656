import LazyModal from 'features/common/LazyModal';
import React, { lazy } from 'react';
import { IntroInstantRedemptionProps } from './IntroInstantRedemptionModal';

const Modal = lazy(() => import('./IntroInstantRedemptionModal'));

const IntroInstantRedemptionModal = (props: IntroInstantRedemptionProps) => {
  return <LazyModal component={Modal} visible={props.showModal} {...props} />;
};

export default IntroInstantRedemptionModal;
