import React, { forwardRef } from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

type Ref = SVGSVGElement;

const IconIdea = forwardRef<Ref, Props>(
  ({ width, height, className, color = 'currentColor' }, ref) => {
    return (
      <svg
        width={width}
        height={height}
        ref={ref}
        className={className}
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_2392_25285)'>
          <path
            d='M11.0066 7.12576C11.0074 7.65447 10.8886 8.17651 10.659 8.65277C10.4295 9.12903 10.0951 9.54718 9.68101 9.87589C9.35329 10.1218 9.12674 10.4791 9.04401 10.8804C9.03651 10.8763 9.02801 10.8745 9.01951 10.8751H6.03226C6.02035 10.8744 6.00842 10.8762 5.99726 10.8804C5.93601 10.4997 5.73278 10.1564 5.42851 9.91964C4.71238 9.375 4.2323 8.57653 4.08711 7.68861C3.94192 6.80069 4.14266 5.89089 4.64801 5.14651C4.98267 4.65487 5.4375 4.25698 5.96926 3.99064C6.50034 3.72449 7.09087 3.59921 7.68427 3.62679C8.27766 3.65437 8.85403 3.83388 9.35814 4.14814C9.86279 4.46438 10.2788 4.90373 10.567 5.4249C10.8552 5.94607 11.0062 6.53196 11.0058 7.12751L11.0075 7.12576H11.0066Z'
            stroke={color}
          />
          <path
            d='M9.04305 10.8806C9.02696 10.9663 9.01847 11.0532 9.01767 11.1404V12.2508C9.01696 12.4823 8.92518 12.7042 8.76217 12.8686C8.59928 13.0324 8.37806 13.1249 8.14705 13.1258H6.90367C6.71371 13.1199 6.53046 13.0542 6.37997 12.9381C6.22949 12.822 6.11942 12.6614 6.06542 12.4792C6.02898 12.3582 6.01796 12.231 6.03305 12.1056V11.2209C6.03407 11.1062 6.02234 10.9918 5.99805 10.8797C6.00927 10.8758 6.0212 10.8743 6.03305 10.8753H9.01767C9.02642 10.8753 9.03517 10.8762 9.04305 10.8806Z'
            stroke={color}
          />
          <path
            d='M9.39224 7.37488C9.343 7.37499 9.29422 7.36535 9.24872 7.3465C9.20322 7.32766 9.16191 7.29999 9.12717 7.26508C9.09243 7.23018 9.06495 7.18874 9.04632 7.14315C9.02769 7.09757 9.01827 7.04874 9.01862 6.9995C9.01862 6.6355 8.87424 6.2855 8.61787 6.02825C8.49114 5.90081 8.34049 5.79965 8.17457 5.73058C8.00865 5.66151 7.83072 5.62588 7.65099 5.62575C7.60164 5.62599 7.55274 5.61639 7.50713 5.59754C7.46152 5.57868 7.42012 5.55093 7.38534 5.51591C7.35057 5.48089 7.32311 5.4393 7.30457 5.39356C7.28604 5.34782 7.27679 5.29885 7.27737 5.2495C7.27783 5.15047 7.31731 5.05561 7.38726 4.9855C7.4572 4.9154 7.55196 4.87569 7.65099 4.875C8.21099 4.875 8.74912 5.09987 9.14549 5.498C9.54187 5.89612 9.765 6.436 9.765 6.9995C9.76484 7.07346 9.74294 7.14574 9.70202 7.20735C9.66111 7.26895 9.60298 7.31717 9.53487 7.346C9.48969 7.36492 9.44122 7.37474 9.39224 7.37488Z'
            fill={color}
          />
          <path
            d='M7.77256 0.875V2.37475M12.8966 3.24975L11.6042 3.99963M2.76756 2.59525L3.82281 3.65575M12.8966 10.5L11.6042 9.74925M1.10156 7.38063L2.50419 6.86787'
            stroke={color}
            stroke-linecap='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_2392_25285'>
            <rect width='14' height='14' fill='white' />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

export default IconIdea;
