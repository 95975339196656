import list from '../services';
import { isErrorPinBlocked } from 'utils/auth';
import getSafely from '../utils/safely';
import http from 'core/http';
import Storage from 'core/Storage';
import { getRefreshId } from 'core/refresh-token/utils';
import { setNewToken } from 'core/refresh-token/refresh-token';

export async function requestPermission({ pin, scope }) {
  const refreshId = await getRefreshId();

  return new Promise((resolve, reject) => {
    http
      .post(list.requestPermission, { pin, scope, refresh_id: refreshId })
      .then((resp) => {
        return setNewToken(resp.data?.data).then(() => {
          return resolve(resp.data.data);
        });
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
}

export async function pinVerify(payload, updateProfileDataCache) {
  let body = { pin: payload };
  let path = list.pinVerify;

  if (typeof payload === 'object') {
    body = payload;
    path = list.requestSecureKey;
  }

  const ssoLoginData = await Storage.getObject('sso_login_data');

  /**
   * Check if user is logged-in with sso.
   *
   * @note currently this data won't be used since API using another approach
   * to verify PIN for sso logged-in users.
   */
  if (ssoLoginData) {
    body['email'] = ssoLoginData.email;
    body['sso_provider'] = ssoLoginData.sso_provider;
    body['sso_token'] = ssoLoginData.sso_token;
  }

  return new Promise((resolve, reject) => {
    http
      .post(path, body)
      .then((resp) => {
        let secureKey = getSafely(['data', 'data', 'key'], resp);

        if (typeof payload === 'object') {
          secureKey = getSafely(['data', 'data', 'token'], resp);
        }

        return resolve(secureKey);
      })
      .catch((err) => {
        if (isErrorPinBlocked(getSafely(['response', 'data'], err))) {
          // update cache data profile
          if (updateProfileDataCache) {
            updateProfileDataCache({ suspend: 1 });
          }
        }
        return reject(err.response);
      });
  });
}

/**
 * POST: Change direct pin
 * @param {object} payload
 */
export function postDirectChangePin(payload) {
  return http.post(list.pinDirectChange + '?v=2', payload);
}

/**
 * POST: Execute change pin
 * @param {object} toBody
 */
export function postChangePin(toBody) {
  return http.post(list.pinChangeUpdate, toBody);
}

/**
 * POST: Verification token change pin
 * @param {object} toBody
 */
export function postChangePinVerify(toBody) {
  return http.post(list.pinChangeVerify, toBody);
}

/**
 * POST: Request change pin
 * @param {object} toBody
 * @param {object} params
 */
export function postChangePinRequest(toBody, params) {
  return http.post(list.pinChangeRequest, toBody, params);
}

/**
 * POST: set up pin earlier
 * @param {object} data
 */
export function postSetUpPin(data) {
  return http.post(list.pinSetup, data);
}
