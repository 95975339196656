import * as React from 'react';
import iconLight from './delete-key.svg';
import iconDark from './delete-key-dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: iconLight,
  dark: iconDark,
  default: iconLight,
};

export const IconDeleteKey: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default IconDeleteKey;
