import React from 'react';
import TopUpModalNew from 'features/topup-modal/TopUpModal';
import LinkAjaOrderCreationError from '../../errors/LazyLinkAjaErrorOrderCreation';
import SyariahDialog from './SyariahDialog';
import Toast from '../toast/Toast';
import ToastTop from '../toast/ToastTop';
import ChangeBankWarningModal from '../../changebank/component/ChangeBankWarningModal';
import PortfolioGoalSettingsModals from './PortfolioGoalSettingsModals';
import ModalResctrictRecurring from 'features/recurring/components/ModalRestrictRecurring';
import ModalUpdateReminder from 'features/common/modals/UpdateReminder';
import OpenGopayPrompt from 'features/gopay/OpenGopayPrompt';
import GojekPaymentSucess from 'features/gopay/GojekPaymentSuccess';
import LoadingDialog from '../LoadingDialog';
import AppOnlyModal from './AppOnlyModal';
import CompleteYourProfileModal from 'features/common/modals/CompleteYourProfileModal/CompleteYourProfileModal';
import {
  JagoConnectPromptModal,
  BankJagoDifferentLoginModal,
  BankJagoUpdateOS,
} from 'features/bankjago/components/modals';
import ContinueRegistrationModal from './ContinueRegistrationModal';
import { EarlyRedemptionSBN } from 'features/sbn';
import { SharedPortfolioModal } from 'features/shared-portfolio';
import CashoutSurveyModal from './CashoutSurveyModal';
import HomeOnboardingModalFlow from 'features/home/components/Modals/onboarding-modal-flow/HomeOnboardingModalFlow';
import LazyLoadWrapper from '../lazyload/LazyLoadWrapper';
import useAuth from 'hooks/useAuth';
import ProductNotBuyableModal from './ProductNotBuyableModal/ProductNotBuyableModal';
import CreatePortfolioRoboModal from 'features/portfolio/modals/CreatePortfolioRoboModal';
import 'features/sell/SellModal.css';
import CallbackModal from './CallbackModal';

const UserDeactivateModal = React.lazy(
  () => import('./UserDeactiveModal/UserDeactivateModal')
);

const SellModal = React.lazy(() => import('features/sell/SellModalFlow'));

/**
 * NOTES: think twice before put your Modal in here.
 * Forget it old approach that all global modal should put in here.
 *
 * For alternative please make your Modal as generic as possible so you can implement your modal in spesific FEATURE or PAGE.
 * Believe me "Global Modal" is sometimes just want to show modal in 2 or 3 in different page not the whole app.
 */

const AppModals = () => {
  const { isLogin } = useAuth();

  return (
    <React.Fragment>
      {/* modal show triggered by user */}
      <LazyLoadWrapper loadingComponent={<div />}>
        <SellModal />
      </LazyLoadWrapper>
      <CallbackModal />
      <LinkAjaOrderCreationError />
      <SyariahDialog />
      <ChangeBankWarningModal />
      <PortfolioGoalSettingsModals />
      <ModalResctrictRecurring />
      <TopUpModalNew />
      <OpenGopayPrompt />
      <GojekPaymentSucess />
      <AppOnlyModal />
      <BankJagoUpdateOS />
      <SharedPortfolioModal />
      <ContinueRegistrationModal />
      <EarlyRedemptionSBN />
      <JagoConnectPromptModal />
      <CashoutSurveyModal />
      <CreatePortfolioRoboModal />
      <HomeOnboardingModalFlow />
      <ProductNotBuyableModal />
      {isLogin && (
        <LazyLoadWrapper loadingComponent={<div />}>
          <UserDeactivateModal />
        </LazyLoadWrapper>
      )}

      {/* modal auto show */}
      <ModalUpdateReminder />
      <BankJagoDifferentLoginModal />
      <CompleteYourProfileModal />

      <Toast />
      <ToastTop />
      <LoadingDialog />
    </React.Fragment>
  );
};

export default AppModals;
