import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { useContextEarlyRedemptionState } from 'features/sbn/components/modals/EarlyRedemptionModal/contexts';

const EarlyRedemptionLazy = React.lazy(() => import('./EarlyRedemptionFlow'));

const EarlyRedemption: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { step } = useContextEarlyRedemptionState();

  const visible = !!step;

  return <LazyModal visible={visible} component={EarlyRedemptionLazy} />;
};

export default EarlyRedemption;
